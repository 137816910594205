import apiConfig from '../../apiConfig';

export const checkWebNotifications = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/NOTIFICATIONS/CHECK_WEB_NOTIFICATIONS',{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);


	if (response.data.Status) {
		return response.data.Data;
	}

	return [];
};
export const getAllWebNotification = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS//NOTIFICATIONS/GET_WEB_NOTIFICATIONS',{
			Page_Index: 1,
			Page_Count: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);


	if (response.data.Status) {
		return response.data.Data;
	}

	return [];
};
export const getUnreadWebNotification = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS//NOTIFICATIONS/GET_UNREAD_WEB_NOTIFICATIONS',{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);


	if (response.data.Status) {
		return response.data.Data;
	}

	return [];
};
export const getNotificationDetails = async (id , at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS//NOTIFICATIONS/GET_WEB_NOTIFICATION_DETAILS',{
            Notification_Web_Id: id
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);


	if (response.data.Status) {
		return response.data.Data;
	}

	return [];
};
export const deleteNotification = async (id , at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS//NOTIFICATIONS/DEL_WEB_NOTIFICATION',{
            Notification_Web_Id: id
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
export const deleteAllNotifications = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS//NOTIFICATIONS/DEL_ALL_WEB_NOTIFICATION',{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};
export const deleteReadedNotifications = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS//NOTIFICATIONS/DEL_READED_WEB_NOTIFICATION',{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		return response.data;
	}

	return [];
};