import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../translationHook'
import apiConfig from '../../apis/apiConfig'
import { ButtonPrimary, ButtonSecondary } from './../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Avatar from '@material-ui/core/Avatar'
import { triggerNotification, setLoading, triggerDialogForm } from '../../actions'

import SliderList from './SliderList'
import SliderForm from './SliderForm'


class Slider extends Component {

    state = {
        viewMood: false,
        editMood: false
    }

    triggerView = (viewMood, editMood) => {
        this.setState({viewMood, editMood})
    }

    handleEdit = (data) => {
        let initValues = {
            sliderStatus: data.Active_Status_Id,
            arContent: data.Portal_Slider_Content_AR,
            enContent: data.Portal_Slider_Content_EN,
            enTitle: data.Portal_Slider_Title_EN,
            arTitle: data.Portal_Slider_Title_AR,
            actionStatus: data.Portal_Slider_Action,
            actionARTitle: data.Portal_Slider_Action_Title_AR,
            actionENTitle: data.Portal_Slider_Action_Title_EN,
            actionURL: data.Portal_Slider_Action_Url,
            slider: data.Portal_Slider_Media_Url,
            fileType: data.Portal_Slider_Type
        },
        sliderId = data.Portal_Slider_Id,
        sliderName = this.props.i18n.language === 'ar' ? data.Portal_Slider_Title_AR : data.Portal_Slider_Title_EN,
        dialogTitle = `${this.props.viewMood ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${sliderName}`

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                sliderId,
                initValues
            ],
            action: this.triggerView
        })

    }

    renderEditForm = (...params) => {
        return <SliderForm sliderId={params[0]} editMood={this.state.editMood} viewMood={this.state.viewMood} initValues={params[1]} triggerView={this.triggerView} />
    }


    render() {

        return (
            <>
            <Row>
            {!this.state.viewMood && !this.state.editMood &&
                <Col xs={12} className='mb-5'>
                    <SliderForm />
                </Col>
            }

                <Col xs={12}>
                    <SliderList triggerView={this.triggerView} handleEdit={this.handleEdit} />
                </Col>
            </Row>
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(Slider))
