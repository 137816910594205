import React, { useEffect } from 'react'
import style from './SubMenu.module.scss'
import { RightArrow, LeftArrow } from '../../../SVG/Arrow'
import { useTranslation } from 'react-i18next'
import SubMenu2 from './SubMenu2'
// import { NavLink } from 'react-router-dom'
import { Link } from "@reach/router"

const SubMenu = props => {
    const { children = [], menuOpen } = props
    const { t, i18n } = useTranslation('common')

    let tranStyle
    if (i18n.language === 'en') {
        tranStyle = require('./SubMenu-ltr.module.scss')
    } else {
        tranStyle = require('./SubMenu-rtl.module.scss')
    }

    useEffect(() => {
        let arrows = document.querySelectorAll('.itemLev2')
        arrows.forEach(x => {
            if (x.children[1] && !menuOpen) {
                x.children[1].style.transform = 'rotate(0deg)'
            }
        })
    }, [menuOpen])

    const handleLev2Expand = e => {
        let item = e.target.closest('.itemLev2').nextSibling
        const lev2Items = document.querySelectorAll('.collapseLev2')
        let arrow = item.previousSibling.children.length > 0 &&
            item.previousSibling.children[1]

        // close all & open only the clicked one & change arrow direction
        lev2Items.forEach(x => {
            let xArrow = x.previousSibling.children.length > 0 &&
                x.previousSibling.children[1]
            if (!item.isEqualNode(x)) {
                x.classList.add('close-collapseLev2')
                x.classList.remove('open-collapseLev2')
                if (xArrow) {
                    xArrow.style.transform = 'rotate(0deg)'
                }
                if (x.childNodes.length > 0) {
                    let children = x.childNodes
                    children.forEach(child => {
                        if (child.classList.contains('open-collapseLev3')) {
                            child.classList.remove('open-collapseLev3')
                            child.classList.add('close-collapseLev3')
                            if (child.previousSibling.length > 0) {
                                child.previousSibling.children[1].style.transform = 'rotate(0deg)'
                            }
                        }
                    })
                }
            }
        })
        if (item.classList.contains('open-collapseLev2')) {
            item.classList.remove('open-collapseLev2')
            item.classList.add('close-collapseLev2')
            if (arrow) {
                arrow.style.transform = 'rotate(0deg)'
            }
        } else {
            item.classList.add('open-collapseLev2')
            item.classList.remove('close-collapseLev2')
            if (arrow) {
                arrow.style.transform = i18n.language === 'en' ? 'rotate(90deg)' : 'rotate(-90deg)'
            }
        }
    }

    const subMenu = (
        <ul
            className={[
                !menuOpen ? 'close-collapseLev1' : null,
                'collapseLev1 close-collapseLev1',
            ].join(' ')}
        >
            {children.map((lev2, i) => (
                <React.Fragment key={i}>
                    {!lev2.children ?
                        <Link
                            to={lev2.link}
                            className={[
                                'd-flex justify-content-between align-items-center itemLev2',
                                tranStyle.itemLev2
                            ].join(' ')}
                            onClick={e => handleLev2Expand(e)}
                        >
                            <span className={[
                                lev2.active ? style.activeLink : null,
                                'd-flex align-items-center'
                            ].join(' ')}
                            >
                                <span className={style.bulletDot}></span>
                                {lev2.name}
                            </span>
                        </Link>
                        :
                        <li
                            className={[
                                'd-flex justify-content-between align-items-center itemLev2',
                                tranStyle.itemLev2, style.itemLev2,
                                lev2.active ? style.activeParent : null
                            ].join(' ')}
                            onClick={e => handleLev2Expand(e)}
                        >
                            <span className='d-flex align-items-center'>
                                <span className={style.bulletDot}></span>
                                {lev2.name}
                            </span>

                            {lev2.children && i18n.language === 'en' ?
                                RightArrow
                                : null}
                            {lev2.children && i18n.language === 'ar' ?
                                LeftArrow
                                : null}
                        </li>
                    }

                    <SubMenu2
                        menuOpen={menuOpen}
                        children={lev2.children}
                    />
                </React.Fragment>
            ))}
        </ul>
    )

    return (
        <>
            {subMenu}
        </>
    )
}

export default SubMenu
