import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { navigate } from "@reach/router";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import { ButtonPrimary, ButtonDanger } from "../../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../../../actions";
import { formSubscriptionItems } from "final-form";
import TaskForm from "../../../../components/dashboard/TaskForm";
import Processing from "../../../requests/processing/Processing";
// import Processing from "../../requests/processing/Processing";
import style from "./ChangeStepUser.module.scss";

class ChangeStepUser extends Component {
  state = {
    usersList: [
      {
        value: 0,
        label: this.props.t("pleaseSelect"),
      },
    ],
    selectedStepUser: 0,
  };

  renderEditForm = (...params) => {
    return <TaskForm missionID={params[0]} initValues={params[1]} />;
  };

  renderViewForm = (params) => {
    console.log(params.viewMood);
    return (
      <div className={style.dashboardListContainer}>
        <Processing dataListObject={params} />
      </div>
    );
  };

  handleClear = async () => {
    // this.props.triggerDialogForm(false);
    // return null;
    this.props.currentDialogData.params[0].viewMood
      ? this.props.triggerDialogForm(true, {
          title: this.props.currentDialogData.title,
          renderForm: this.renderViewForm,
          params: this.props.currentDialogData.params,
        })
      : this.props.triggerDialogForm(true, {
          title: this.props.currentDialogData.title,
          renderForm: this.renderEditForm,
          params: this.props.currentDialogData.params,
        });
  };

  getUsersList = async () => {
    let usersArr = [],
      at = this.props.currentUser.Data.access_token;

    const response = await apiConfig.post(
      "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_SETP_USERS_LIST",
      {
        Request_Id: this.props.requestId,
        Request_Task_Id: this.props.requestTaskId,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.User_Name_AR
            : item.User_Name_EN;

        usersArr.push({ value: item.User_Id, label });
      });

      this.setState({
        usersList: [...this.state.usersList, ...usersArr],
      });
    }
  };

  //here 1/5
  onChangeStepUserSubmit = async (values, form) => {
    let at = this.props.currentUser.Data.access_token,
      apiUrl = this.props.optional
        ? "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_STEP_CHANGE_USER"
        : "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_STEP_USER_ASSIGNMENT";

    this.props.setLoading(true, "dark");

    const response = await apiConfig
      .post(
        apiUrl,
        {
          Request_Id: this.props.requestId,
          Request_Task_Id: this.props.requestTaskId,
          User_Id: values.stepUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      )
      .then((result) => {
        this.props.setLoading(false);

        this.props.triggerNotification(true, result.data);

        if (result.data.Status) {
          // this.props.triggerDialogForm(false);
          // window.location.reload()
          window.location.href.indexOf("servicies-requests") > 0
            ? window.location.assign("/servicies-requests")
            : window.location.assign("/");
        }
      })
      .catch((error) => {
        this.props.setLoading(false);
        if (error.response.data.RESULT_CODE === 500) {
          this.props.triggerNotification(true, error.response.data);
        } else {
          this.props.triggerNotification(true, error.response);
        }

        return error.response;
      });
  };

  componentDidMount() {
    this.getUsersList();
  }

  render() {
    const validate = (values) => {
      const errors = {};

      if (
        (!values.stepUserId || values.stepUserId == 0) &&
        !this.props.optional
      ) {
        errors.stepUserId = `${this.props.t("error.field_required")}`;
      }

      return errors;
    };

    return (
      <>
        <Form
          onSubmit={this.onChangeStepUserSubmit}
          initialValues={{
            stepUserId: 0,
          }}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              id="returnStepForm"
              className="row"
              onSubmit={handleSubmit}
              noValidate
            >
              <Col xs={12} md={8}>
                <Row>
                  <Col xs={12}>
                    <Select
                      label={this.props.t("selectStepUser")}
                      className="mb-2"
                      name="stepUserId"
                      required={!this.props.optional}
                      data={this.state.usersList}
                      value={this.state.selectedStepUser}
                      showError={() => {
                        let fieldStatus = form.getFieldState("stepUserId");

                        if (fieldStatus) {
                          return (
                            fieldStatus.submitFailed && fieldStatus.invalid
                          );
                        }
                      }}
                      onChange={(e) => {
                        form.change("stepUserId", e.target.value);
                        this.setState({
                          selectedStepUser: e.target.value,
                        });
                      }}
                    ></Select>
                  </Col>

                  {this.state.selectedStepUser == 0 && this.props.optional && (
                    <Col xs={12}>
                      <p>{this.props.t("changeStepUserNotice")}</p>
                    </Col>
                  )}

                  <Col className="my-5 justify-content-start d-flex" xs={12}>
                    <ButtonPrimary
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                      type="submit"
                    >
                      {this.props.t("save")}
                    </ButtonPrimary>
                    <ButtonDanger
                      variant="contained"
                      style={{
                        borderRadius: "30px",
                        padding: ".75rem 1.5rem",
                        minWidth: 90,
                      }}
                      onClick={this.handleClear}
                    >
                      {this.props.t("cancel")}
                    </ButtonDanger>
                  </Col>
                </Row>
              </Col>
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    currentUser: state.currentUser,
    currentDialogData: state.currentDialogData.Data,
  };
};

export default connect(mapStateToprops, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(ChangeStepUser));
