import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "./../UI/Buttons";
import { Form } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";

import apiConfig from "../../apis/apiConfig";
import { translationHook } from "../translationHook";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../actions";
import moment from "moment";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
  DataGrid,
  Column,
  Lookup,
  Editing,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";

class ServiceDetailForm extends Component {
  state = {
    detailTypes: [],
    usedServices: [],
    dropDownApisList: [],
    dropDownIntegrationApisList: [],
    fileTypes: [],
    serviceLookups: [],
    showForm: false,
    selectedIntegrationApi: "",
    serviceDetailsApisInputsList: [],
    serviceDetailListForInputs: [],
    serviceDetailPredefinedFields: [
      { value: 0, label: this.props.t("without") },
    ],
  };

  getDetailTypes = async () => {
    let typesArr = [];
    const response = await apiConfig.post(
      "/API/SYSTEM/DDL/GET_SERVICES_DETAILS_TYPES"
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;

        typesArr.push({ value: item.Record_Id, label });
      });

      this.setState({ detailTypes: typesArr });
    }
  };
  getusedServices = async () => {
    let usedServicesArr = [],
      url =
        "/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_VALIDATION_APIS_LIST";

    const response = await apiConfig.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Service_Detail_API_Name_AR
            : item.Service_Detail_API_Name_EN;

        usedServicesArr.push({
          value: item.Service_Detail_API_Id,
          label,
        });
      });

      this.setState({ usedServices: usedServicesArr });
    }
  };

  getdropDownApisList = async () => {
    let dropDownApisListArr = [],
      url =
        "/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_DROPDOWN_DATA_APIS_LIST";

    const response = await apiConfig.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Service_Detail_API_Name_AR
            : item.Service_Detail_API_Name_EN;

        dropDownApisListArr.push({
          value: item.Service_Detail_API_Id,
          label,
        });
      });

      this.setState({ dropDownApisList: dropDownApisListArr });
    }
  };

  getdropDownIntegrationApisList = async () => {
    let dropDownApisListArr = [],
      url =
        "/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_INTEGRATION_APIS_LIST";

    const response = await apiConfig.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Service_Detail_API_Name_AR
            : item.Service_Detail_API_Name_EN;

        dropDownApisListArr.push({
          value: item.Service_Detail_API_Id,
          label,
        });
      });

      this.setState({ dropDownIntegrationApisList: dropDownApisListArr });
    }
  };

  getFileTypes = async () => {
    let typesArr = [];
    const response = await apiConfig.post(
      "/API/SYSTEM/DDL/GET_FILES_CONTENTS_TYPES"
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;

        typesArr.push({ value: item.Record_Id, label });
      });

      this.setState({ fileTypes: typesArr });
    }
  };

  getServiceLookups = async () => {
    let lookupsArr = [];
    const response = await apiConfig.post(
      "/API/SYSTEM/DDL/GET_SERVICES_LOOKUPS"
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;

        lookupsArr.push({ value: item.Record_Id, label });
      });

      this.setState({ serviceLookups: lookupsArr });
    }
  };

  getServiceDetailsApisInputsList = async () => {
    const response = await apiConfig.post(
      "/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_APIS_INPUTS_LIST",
      {
        Service_Detail_Id: this.props.editMood ? this.props.serviceDetailId : 0,
        Service_Detail_API_Id: this.state.selectedIntegrationApi,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    if (
      response.data.Status &&
      response.data.Data &&
      response.data.Data.length > 0
    ) {
      this.setState({ serviceDetailsApisInputsList: response.data.Data });
    }
  };

  getServiceDetailsListForInputs = async () => {
    let lookupsArr = [];

    const response = await apiConfig.post(
      "/API/ADMINISTRATION/SERVICES/GET_SERVICE_DETAILS_LIST_FOR_INPUTS",
      {
        Service_Id: this.props.serviceid,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Service_Detail_Title_AR
            : item.Service_Detail_Title_EN;

        lookupsArr.push({ value: item.Service_Detail_Id, label });
      });
      this.setState({ serviceDetailListForInputs: lookupsArr });
    }
  };

  getPredefinedFieldsList = async () => {
    let dropdownPredefinedFieldsArr = [],
      url = "/API/ADMINISTRATION/SERVICES/GET_PREDEFINED_FIELDS";

    dropdownPredefinedFieldsArr.push({
      value: 0,
      label: this.props.t("without"),
    });

    const response = await apiConfig.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Service_Detail_Predefined_Field_Name_AR
            : item.Service_Detail_Predefined_Field_Name_EN;

        dropdownPredefinedFieldsArr.push({
          value: item.Service_Detail_Predefined_Field_Id,
          label,
        });
      });

      this.setState({
        serviceDetailPredefinedFields: dropdownPredefinedFieldsArr,
      });
    }
  };

  componentDidMount() {
    this.getusedServices();
    this.getdropDownApisList();
    this.getdropDownIntegrationApisList();
    this.getDetailTypes();
    this.getFileTypes();
    this.getServiceLookups();
    this.getServiceDetailsListForInputs();
    this.getPredefinedFieldsList();

    if (this.props.initValues?.usedServices) {
      this.setState({
        selectedIntegrationApi: this.props.initValues?.usedServices,
      });
    }
  }

  componentDidUpdate(prevProps, prevSate) {
    if (
      this.state.selectedIntegrationApi !== "" &&
      this.state.selectedIntegrationApi !== prevSate.selectedIntegrationApi
    ) {
      this.getServiceDetailsApisInputsList();
    }
  }

  validate = (values) => {
    const errors = {};

    if (!values.detailNameAR) {
      errors.detailNameAR = `${this.props.t("error.field_required")}`;
    }

    if (!values.detailNameEN) {
      errors.detailNameEN = `${this.props.t("error.field_required")}`;
    }

    if (!values.detailType) {
      errors.detailType = `${this.props.t("error.field_required")}`;
    }

    if (values.detailType === 10 && !values.usedServices) {
      errors.usedServices = `${this.props.t("error.field_required")}`;
    }

    if (values.detailType === 11 && !values.usedServices) {
      errors.usedServices = `${this.props.t("error.field_required")}`;
    }

    if (values.detailType === 12 && !values.usedServices) {
      errors.usedServices = `${this.props.t("error.field_required")}`;
    }

    if (values.detailType === 3 && !values.fileType) {
      errors.fileType = `${this.props.t("error.field_required")}`;
    }

    if (values.detailType === 2 && !values.serviceLookups) {
      errors.serviceLookups = `${this.props.t("error.field_required")}`;
    }

    /*if (values.detailType === 1 && !values.predefinedField) {
			errors.predefinedField = `${this.props.t("error.field_required")}`;
		}*/

    return errors;
  };

  onServiceDetailSubmit = async (values, form) => {
    let at = this.props.currentUser.Data.access_token,
      url = !this.props.editMood
        ? "/API/ADMINISTRATION/SERVICES/ADD_SERVICE_DETAIL"
        : "/API/ADMINISTRATION/SERVICES/UPD_SERVICE_DETAIL";

    if (values.detailType === 7 && !values.checkboxDefault) {
      values.checkboxDefault = 0;
    } else if (values.detailType === 7 && values.checkboxDefault) {
      values.checkboxDefault = 1;
    } else {
      values.checkboxDefault = null;
    }

    if (values.detailType === 6 && !values.allowOldDate) {
      values.allowOldDate = 0;
    } else if (values.detailType === 6 && values.allowOldDate) {
      values.allowOldDate = 1;
    } else {
      values.allowOldDate = null;
    }

    console.log(values.predefinedField);
    let bodyData = {
      Service_Detail_Type_Id: values.detailType,
      Service_Detail_API_Id: values.usedServices,
      Service_Detail_Title_AR: values.detailNameAR,
      Service_Detail_Title_EN: values.detailNameEN,
      Service_Detail_Required: values.required ? 1 : 0,
      Service_Lookup_Id: values.serviceLookups,
      Service_Detail_Predefined_Field_Id: values.predefinedField,
      Service_File_Content_Type_Id: values.fileType ? values.fileType : null,
      Service_File_Extension: values.fileExtension
        ? values.fileExtension
        : null,
      Service_Allow_Old_Date: moment(values.allowOldDate)
        .lang("en")
        .format("YYYY-MM-DD"),
      Service_ChkBox_Status: values.checkboxDefault,
      Service_Detail_Template_Code: values.templateCode,
      API_Inputs: this.state.serviceDetailsApisInputsList.map(
        ({ Service_Detail_Id, Service_Detail_API_Input_Id }) => {
          return {
            Service_Detail_API_Input_Id,
            Service_Detail_Id,
          };
        }
      ),
    };

    if (!this.props.editMood) {
      bodyData.Service_Id = this.props.serviceid;
    } else {
      bodyData.Service_Detail_Id = this.props.serviceDetailId;
    }

    this.props.setLoading(true, "dark");

    const response = await apiConfig
      .post(url, bodyData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .catch((error) => {
        return error.response;
      });

    this.props.setLoading(false);

    this.props.triggerNotification(true, response.data);

    if (response.data.Status) {
      if (this.props.editMood) {
        this.props.triggerDialogForm(false);
      }
      this.props.reloadDatagrid();
      form.restart();
      // form.change('usedServices', '')
      this.setState({
        selectedIntegrationApi: "",
      });
      this.getServiceDetailsListForInputs();
    }
  };

  render() {
    let { initValues } = this.props,
      columnAlign = "left";

    if (this.props.i18n.language === "ar") {
      columnAlign = "right";
    }

    const jsonDataSource = new CustomStore({
      key: "Service_Detail_API_Input_Id",
      load: () => {
        return this.state.serviceDetailsApisInputsList;
      },
      update: (key, values) => {
        this.setState({
          serviceDetailsApisInputsList:
            this.state.serviceDetailsApisInputsList.map((detail) => {
              if (detail.Service_Detail_API_Input_Id === key) {
                detail.Service_Detail_Id = values.Service_Detail_Id;
              }

              return detail;
            }),
        });
      },
    });

    return (
      <>
        {!this.props.editMood && !this.props.viewMood && (
          <div className="d-flex justify-content-end">
            <ButtonPrimary
              variant={!this.state.showForm ? "contained" : "outlined"}
              onClick={() =>
                this.setState({
                  showForm: !this.state.showForm,
                })
              }
            >
              {!this.state.showForm && this.props.t("addNewDetail")}
              {this.state.showForm && this.props.t("cancel")}
            </ButtonPrimary>
          </div>
        )}
        <Form
          onSubmit={this.onServiceDetailSubmit}
          initialValues={
            initValues
              ? initValues
              : {
                  detailNameAR: "",
                  detailNameEN: "",
                  detailType: "",
                  usedServices: "",
                  required: true,
                }
          }
          validate={this.validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            if (
              (values.detailType !== 1) &
                (values.detailType !== 2) &
                (values.detailType !== 4) &
                (values.detailType !== 5) &
                (values.detailType !== 6) &
                (values.detailType !== 8) &
                (values.detailType !== 9) &&
              values.predefinedField
            ) {
              delete values.predefinedField;
            }

            if (values.detailType !== 2 && values.serviceLookups) {
              delete values.serviceLookups;
            }

            if (values.detailType !== 3 && values.fileExtension) {
              delete values.fileExtension;
            }

            if (values.detailType !== 3 && values.fileType) {
              delete values.fileType;
            }

            if (values.detailType !== 6 && values.allowOldDate) {
              delete values.allowOldDate;
            }

            if (values.detailType !== 7 && values.checkboxDefault) {
              delete values.checkboxDefault;
            }

            if (values.detailType == 3 && values.templateCode) {
              delete values.templateCode;
            }

            return (
              <form
                id="serviceDetailForm"
                className={`row ${
                  !this.state.showForm &&
                  !this.props.editMood &&
                  !this.props.viewMood
                    ? "d-none"
                    : ""
                }`}
                onSubmit={handleSubmit}
                noValidate
              >
                <Col xs={12} md={6}>
                  <TextField
                    label={this.props.t("detailno")}
                    className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                    name="detailNo"
                    disabled
                  />
                </Col>
                <Col
                  xs={12}
                  md={6}
                  className={`${this.props.viewMood ? "read-only" : ""}`}
                >
                  <Switches
                    disabled={this.props.viewMood}
                    color="primary"
                    name="required"
                    data={{
                      label: `${this.props.t("required")}`,
                      value: `${this.props.t("required")}`,
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextField
                    disabled={this.props.viewMood}
                    label={this.props.t("servicedetailnameAR")}
                    className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                    required={true}
                    name="detailNameAR"
                    autoComplete="off"
                    showError={({ meta: { submitFailed, invalid } }) => {
                      return submitFailed && invalid;
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextField
                    disabled={this.props.viewMood}
                    label={this.props.t("servicedetailnameEN")}
                    className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                    required={true}
                    name="detailNameEN"
                    autoComplete="off"
                    showError={({ meta: { submitFailed, invalid } }) => {
                      return submitFailed && invalid;
                    }}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Select
                    disabled={this.props.viewMood}
                    label={this.props.t("servicedetailtype")}
                    className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                    required={true}
                    name="detailType"
                    data={this.state.detailTypes}
                    showError={() => {
                      let fieldStatus = form.getFieldState("detailType");

                      if (fieldStatus) {
                        return fieldStatus.submitFailed && fieldStatus.invalid;
                      }
                    }}
                  ></Select>
                </Col>
                {values.detailType === 10 && (
                  <Col xs={12} md={6}>
                    <Select
                      disabled={this.props.viewMood}
                      label={this.props.t("usedServices")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      required={true}
                      name="usedServices"
                      data={this.state.usedServices}
                      showError={() => {
                        let fieldStatus = form.getFieldState("usedServices");

                        if (fieldStatus) {
                          return (
                            fieldStatus.submitFailed && fieldStatus.invalid
                          );
                        }
                      }}
                    ></Select>
                  </Col>
                )}

                {values.detailType === 11 && (
                  <Col xs={12} md={6}>
                    <Select
                      disabled={this.props.viewMood}
                      label={this.props.t("usedServices")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      required={true}
                      name="usedServices"
                      data={this.state.dropDownApisList}
                      showError={() => {
                        let fieldStatus = form.getFieldState("usedServices");

                        if (fieldStatus) {
                          return (
                            fieldStatus.submitFailed && fieldStatus.invalid
                          );
                        }
                      }}
                    ></Select>
                  </Col>
                )}

                {values.detailType === 12 && (
                  <Col xs={12} md={6}>
                    <Select
                      disabled={this.props.viewMood}
                      label={this.props.t("usedServices")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      required={true}
                      name="usedServices"
                      data={this.state.dropDownIntegrationApisList}
                      value={this.state.selectedIntegrationApi}
                      onChange={(e) => {
                        console.log(e.target.value);
                        form.change("usedServices", e.target.value);
                        this.setState({
                          selectedIntegrationApi: e.target.value,
                        });
                        // this
                        // 	.getServiceDetailsApisInputsList()
                      }}
                      showError={() => {
                        let fieldStatus = form.getFieldState("usedServices");

                        if (fieldStatus) {
                          return (
                            fieldStatus.submitFailed && fieldStatus.invalid
                          );
                        }
                      }}
                    ></Select>
                  </Col>
                )}

                {values.detailType === 2 && (
                  <Col xs={12} md={6}>
                    <Select
                      disabled={this.props.viewMood}
                      label={this.props.t("dropdownListName")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      required={true}
                      name="serviceLookups"
                      data={this.state.serviceLookups}
                      showError={() => {
                        let fieldStatus = form.getFieldState("serviceLookups");

                        if (fieldStatus) {
                          return (
                            fieldStatus.submitFailed && fieldStatus.invalid
                          );
                        }
                      }}
                    ></Select>
                  </Col>
                )}

                {(values.detailType === 1 ||
                  values.detailType === 2 ||
                  values.detailType === 4 ||
                  values.detailType === 5 ||
                  values.detailType === 6 ||
                  values.detailType === 8 ||
                  values.detailType === 9) && (
                  <Col xs={12} md={6}>
                    <Select
                      disabled={this.props.viewMood}
                      label={this.props.t("predefinedField")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      required={false}
                      name="predefinedField"
                      data={this.state.serviceDetailPredefinedFields}
                      showError={() => {
                        let fieldStatus = form.getFieldState("predefinedField");

                        if (fieldStatus) {
                          return (
                            fieldStatus.submitFailed && fieldStatus.invalid
                          );
                        }
                      }}
                    ></Select>
                  </Col>
                )}

                {values.detailType === 3 && [
                  <Col xs={12} md={3}>
                    <Select
                      disabled={this.props.viewMood}
                      label={this.props.t("fileType")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      required={true}
                      name="fileType"
                      data={this.state.fileTypes}
                      showError={() => {
                        let fieldStatus = form.getFieldState("fileType");

                        if (fieldStatus) {
                          return (
                            fieldStatus.submitFailed && fieldStatus.invalid
                          );
                        }
                      }}
                    ></Select>
                  </Col>,
                  <Col xs={12} md={3}>
                    <TextField
                      disabled={this.props.viewMood}
                      label={this.props.t("fileExtension")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      name="fileExtension"
                    />
                  </Col>,
                ]}

                {values.detailType === 6 && (
                  <Col
                    xs={12}
                    md={6}
                    className={`${this.props.viewMood ? "read-only" : ""}`}
                  >
                    <Switches
                      disabled={this.props.viewMood}
                      color="secondary"
                      name="allowOldDate"
                      data={{
                        label: `${this.props.t("allowOldDate")}`,
                        value: `${this.props.t("allowOldDate")}`,
                      }}
                    />
                  </Col>
                )}

                {values.detailType === 7 && (
                  <Col
                    xs={12}
                    md={6}
                    className={`${this.props.viewMood ? "read-only" : ""}`}
                  >
                    <Switches
                      disabled={this.props.viewMood}
                      color="secondary"
                      name="checkboxDefault"
                      data={{
                        label: `${this.props.t("active")}`,
                        value: `${this.props.t("active")}`,
                      }}
                    />
                  </Col>
                )}

                {values.detailType !== 3 && (
                  <Col xs={12} md={6}>
                    <TextField
                      disabled={this.props.viewMood}
                      label={this.props.t("templateCode")}
                      className={`${
                        this.props.viewMood ? "read-only" : ""
                      } mb-2`}
                      name="templateCode"
                      autoComplete="off"
                    />
                  </Col>
                )}

                {values.detailType === 12 &&
                  this.state.selectedIntegrationApi !== "" && (
                    <>
                      <Col xs={12}>
                        <DataGrid
                          className={[
                            this.props.i18n.language === "ar"
                              ? "dx-grid-ar"
                              : "dx-grid-en",
                            "globalBox",
                          ].join(" ")}
                          id="dataGrid"
                          width={"100%"}
                          dataSource={jsonDataSource}
                          showBorders={false}
                          showColumnLines={false}
                          showRowLines={true}
                          allowColumnResizing={true}
                          columnResizingMode={"widget"}
                          allowColumnReordering={true}
                          rowAlternationEnabled={false}
                          focusedRowEnabled={true}
                          columnWidth={"auto"}
                          wordWrapEnabled={true}
                          rtlEnabled={
                            this.props.i18n.language === "ar" ? true : false
                          }
                        >
                          <Editing
                            mode="cell"
                            allowUpdating={true}
                            allowAdding={false}
                            allowDeleting={false}
                          />
                          <Column
                            allowEditing={false}
                            dataField="Service_Detail_API_Input_No"
                            sortOrder="asc"
                            caption={this.props.t("varId")}
                            alignment={"center"}
                            width={"120px"}
                          ></Column>

                          <Column
                            allowEditing={false}
                            dataField={
                              this.props.i18n.language === "ar"
                                ? "Service_Detail_API_Input_Name_AR"
                                : "Service_Detail_API_Input_Name_EN"
                            }
                            caption={this.props.t("varName")}
                            alignment={columnAlign}
                          ></Column>

                          <Column
                            allowEditing={true}
                            cssClass="outlinedColumn"
                            dataField="Service_Detail_Id"
                            caption={this.props.t("valFrom")}
                            alignment={"center"}
                          >
                            <Lookup
                              dataSource={this.state.serviceDetailListForInputs}
                              displayExpr="label"
                              valueExpr="value"
                            />
                          </Column>
                        </DataGrid>
                      </Col>
                    </>
                  )}

                <Col className="mt-4" xs={12}>
                  <Row className="align-items-baseline">
                    <Col className="mt-2 submit-btn-wrap">
                      {!this.props.viewMood && (
                        <ButtonPrimary
                          color="primary"
                          variant="contained"
                          disabled={submitting || this.props.viewMood}
                          type="submit"
                        >
                          {this.props.t("saveDetail")}
                        </ButtonPrimary>
                      )}
                    </Col>
                  </Row>
                </Col>
              </form>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(ServiceDetailForm));
