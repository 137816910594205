import apiConfig from "../../apiConfig";

export const getListDepartmentServiceTemplatesFiles = async (
	at,
	Template_Id
) => {
	const response = await apiConfig
		.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_LIST_DEPARTMENT_SERVICES_TEMPLATES_FILES",
			{
				Template_Id,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)
		.catch((error) => {
			return error.response;
		});

	return response.data.Data;
};
