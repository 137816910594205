import React, { useState , useEffect } from 'react'
import { connect } from 'react-redux';
import { Form } from 'react-final-form'
import { TextField, Checkboxes } from 'mui-rff'
import { Button, InputAdornment, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import style from './Form.module.scss'
import invisible from './../../images/login/svgs/invisible.svg'
import visible from './../../images/login/svgs/visible.svg'
import { userLogin } from '../../actions';
import { ForgetPass , ResetForgetPass } from '../../apis/system/forgetPass'
import { triggerNotification, setLoading } from '../../actions';
import Countdown from "react-countdown";
import { useSelector } from 'react-redux';
// state.currentUser
const FormElement = (props) => {

    const { t, i18n } = useTranslation(['auth', 'common'])
    const [showPassword, setShowPassword] = useState(false)
    const [forgetPass, setforgetPass] = useState(false)
    const [mobileLogin, setmobileLogin] = useState(false)
    const [changeDefaultLogin, setchangeDefaultLogin] = useState(false)
    const [optStep, setoptStep] = useState(false)
    const [countdownMins, setcountdownMins] = useState(0)
    const [otpId, setotpId] = useState(0)
    const [currentUser, setcurrentUser] = useState(props.currentUser)
    const currentUserS = useSelector(state => state.currentUser)


    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const onUserLogin = async (values) => {
        if(!forgetPass) {
            // props.userLogin(values)
            props.userLogin(values);
            // setchangeDefaultLogin
        } else {
            let forgetData = {
                Login_Name: values.username,
            };
            // forget pass flow
            if(!mobileLogin) {
                forgetData = {...forgetData, ... {User_Mail: values.mail}}
            } else {
                forgetData = {...forgetData, ... {User_Phone: values.phone}}
            }
            props.setLoading(true, 'light')

            if(optStep) {
                const resetForgetData = {
                    User_OTP_Id: otpId,
                    OTP_Value: values.code,
                    Login_Password: values.password
                }
                console.log(resetForgetData);
                const resp = await ResetForgetPass(resetForgetData).then(res => res)
                console.log(resp);
                props.setLoading(false);
                props.triggerNotification(true, resp)
                if(resp.Status) {
                    setoptStep(false)
                    setforgetPass(false)
                }
            } else {

                const res = await ForgetPass(forgetData).then(res => res)
                props.setLoading(false);
                props.triggerNotification(true, res)

                if(res.Status) {
                    setoptStep(true)
                    const today = new Date();
                    const otpTime = new Date(res.Data.OTP_Expire_Time);
                    const diffMs = (otpTime - today); // milliseconds between now & otpTime
                    setcountdownMins(diffMs)
                    setotpId(res.Data.User_OTP_Id)
                }
            }
        }
    }

    const validate = (values) => {
        const errors = {}
        if(!optStep) {
            if (!values.username) {
                errors.username = t('error.field_required')
            }
            if(!forgetPass) {
                if (!values.password) {
                    errors.password = t('error.field_required')
                }
            } else {
                if(mobileLogin) {
                    if (!values.phone) {
                        errors.phone = t('error.field_required')
                    }
                } else {
                    if (!values.mail) {
                        errors.mail = `${t('error.field_required')}`;
                    }    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)) {
                        errors.mail = `${t('error.invalid_email')}`;
                    }
                }
            }
        } else {
            if (!values.code) {
                errors.code = t('error.field_required')
            }
            if (!values.password) {
                errors.password = t('error.field_required')
            }
        }

        return errors;
    }
    // Random component
    const Completionist = () => {
        setTimeout(() => {
            setoptStep(false)
        }, countdownMins);
    return <p>You didn't submit the code please try again</p>
};

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
        // Render a completed state
        return <Completionist />;
        } else {
        // Render a countdown
        return <h3 className="text-center">{minutes}:{seconds}</h3>;
        }
    };
    return(
        <Form
        onSubmit={onUserLogin}
        initialValues={{}}
        validate={validate}
        subscription={{ submitting: true }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
            !optStep ? 
                <form className="form-row" onSubmit={handleSubmit}>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                            className={style.formLabels}
                            name="username"
                            label={t('username')}
                            //autoComplete="username"
                                autoComplete="off"
                            showError={({ meta: { submitFailed, invalid } }) => { 
                                return submitFailed && invalid 
                            }}
                        
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            {!forgetPass ? 
                            <TextField
                            className={style.formLabels}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label={t('password')}
                            //autoComplete="current-password"
                                    autoComplete="off"
                            showError={({ meta: { submitFailed, invalid } }) => { 
                                return submitFailed && invalid 
                            } }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword}>
                                            <img className={style.passwordIcon} src={showPassword ? visible : invisible} /> 
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                                
                            />
                            :
                            <>
                            {mobileLogin ? 
                            <TextField
                                className={style.formLabels}
                                name="phone"
                                type="number"
                                            label={t('phone')}
                                            autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => { 
                                    return submitFailed && invalid 
                                }}
                                            
                            />
                            : 
                            <TextField
                                className={style.formLabels}
                                name="mail"
                                label={t('email')}
                                //autoComplete="email"
                                            autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => { 
                                    return submitFailed && invalid 
                                }}
                                            
                            />
                            }
                                <a 
                                    href="#" 
                                    className=
                                    {"d-block mt-4 text-center w-100"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setmobileLogin(!mobileLogin)
                                    }}
                                    >
                                        {!mobileLogin ? 
                                        t('phoneNumberLogin') : t('emailLogin')
                                        }
                                </a>
                            </>
                        }
                    </div>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="row align-items-center mt-3">
                        {!forgetPass ? 
                        <div className="col">
                            <Checkboxes
                                className={[style.keepMeSigned, 'm-0'].join(' ')}
                            name="saveInfo"
                            data={{ label: t('keep_me_signed'), value: 'saveinfo' }}
                            />
                        </div>
                        :
                        false
                        }
                            <div className="col text-center mt-0">
                                <Button
                                    className={style.signInBtn}
                                    variant="contained"
                                    type="submit"
                                    >
                                    {forgetPass ? 
                                    t('changePassButton') : t('signin')
                                    }
                                </Button>
                            </div>
                            <a 
                                href="#" 
                                className=
                                {"d-block mt-4 text-center w-100"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setforgetPass(!forgetPass)
                                }}
                                >
                                    {!forgetPass ? 
                                    t('forgetPass') : t('signin')
                                    }
    
                            </a>
                        </div>
                    </div>
                    
                </form>
                :
                <>
                    <form className="form-row" onSubmit={handleSubmit}>
                    <div className="col-12">
                        <div className="form-group">
                        {/* countdownMins */}
                            {countdownMins ? 
                            <Countdown 
                                date={Date.now() + countdownMins}
                                renderer={renderer}
                            >
                                <Completionist />
                            </Countdown>
                            :
                            false
                            }
                            <TextField
                            className={style.formLabels}
                            name="code"
                            label={t('code')}
                            subscription={{ touched: true, error: true, value: true }}
                            showError={({ meta: { submitFailed, invalid } }) => {
                                return submitFailed && invalid 
                            } }
                            />
                              <TextField
                            className={style.formLabels}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                                    label={t('password')}
                                    autoComplete="off"
                            //autoComplete="current-password"
                            showError={({ meta: { submitFailed, invalid } }) => { 
                                return submitFailed && invalid 
                            } }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword}>
                                            <img className={style.passwordIcon} src={showPassword ? visible : invisible} /> 
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                                    
                            />
                        </div>
                    </div>
                    <div className="col text-center mt-0">
                        <Button
                            className={style.signInBtn}
                            variant="contained"
                            disabled={submitting || pristine}
                            type="submit"
                        >
                            {t('verify')}
                        </Button>
                    </div>
                    
                    </form>
                </>
                // changeDefaultLogin
        )}
        />
    )
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { 
    triggerNotification, 
    setLoading,
    userLogin})(FormElement)