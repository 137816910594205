import apiConfig from "../../apiConfig";

export const getDepartmentJobsUsers = async (at, id) => {
  const response = await apiConfig.post(
    "API/BASIC/SETTINGS/USERS_AREAS/GET_DEPARTMENT_JOB_USERS_LIST",
    {
      PageIndex: 1,
      PageCount: 1000,
      Department_Job_Id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  if (response.data.Status) {
    return response.data.Data;
  }

  return [];
};
