import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import RequestsList from "./RequestsList";

import DateBox from "devextreme-react/date-box";
import { translationHook } from "../../translationHook";

const ServiceRequests = (props) => {
  const [selectedDateStart, setSelectedDateStart] = useState(
    moment().startOf("month").format("yyyy-MM-DD")
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    moment().format("yyyy-MM-DD")
  );

  const [searchResult, setSearchResult] = useState("");

  const { t } = useTranslation("common");

  return (
    <>
      <div className="align-items-center mb-4">
        <h3>{t("ServiceRequestsList")}</h3>
      </div>

      <div className="row mb-5">
        <div className="col-md-3">
          <label>{t("fromDate")}</label>
          <DateBox
            acceptCustomValue={false}
            defaultValue={selectedDateStart}
            dateSerializationFormat="yyyy-MM-dd"
            displayFormat="yyyy-MM-dd"
            onValueChanged={(e) => {
              setSelectedDateStart(e.value);
            }}
            type="date"
          />
        </div>

        <div className="col-md-3">
          <label>{t("toDate")}</label>
          <DateBox
            acceptCustomValue={false}
            defaultValue={selectedDateEnd}
            dateSerializationFormat="yyyy-MM-dd"
            displayFormat="yyyy-MM-dd"
            onValueChanged={(e) => {
              setSelectedDateEnd(e.value);
            }}
            type="date"
          />
        </div>

        {searchResult !== "" && (
          <div className="col-md-3">
            <label>{t("filter total result")}</label>
            <h3 className="py-2 font-weight-bold">{searchResult}</h3>
          </div>
        )}
      </div>
      <RequestsList
        date={{ selectedDateStart, selectedDateEnd }}
        setSearchResult={setSearchResult}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(translationHook(ServiceRequests));
