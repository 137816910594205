import React from 'react'

import Notes from '../Notes'
import { saveRequestApprovingDocument } from '../../../../apis/requests/processTask'

import Document from './Document'

export const ChartAudit = (props) => {
    
    return (
        <>
        <Document requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} task={props.task} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestApprovingDocument} />
        
        </>
    )
}

export const ChartApprove = (props) => {
    
    return (
        <>
        <Document requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} task={props.task} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestApprovingDocument} />
        
        </>
    )
}

