import React from "react";

import Notes from "../Notes";
import { rejectRequest } from "../../../../apis/requests/processTask";

const Reject = (props) => {
  return (
    <Notes
      required={true}
      requestId={props.requestId}
      requestTaskId={props.requestTaskId}
      tempId={props.tempId}
      onTaskSubmit={rejectRequest}
    />
  );
};

export default Reject;
