// this component currentlly not in use logic has been moved to ServiceVersionStep component but do not delete for future use !!!
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ButtonPrimary } from "../../UI/Buttons";
import { Form } from "react-final-form";
import { TextField, Select } from "mui-rff";

import apiConfig from "../../../apis/apiConfig";
import { translationHook } from "../../translationHook";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../actions";

class ServiceVersionStepForm extends Component {
	state = {
		timeTypes: [],
		serviceNotifications: [],
		stepJobs: [],
	};

	getTimeTypes = async () => {
		let typesArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_TIME_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ timeTypes: typesArr });
		}
	};

	getServiceNotifications = async () => {
		let notificationArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_NOTIFICATIONS"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				notificationArr.push({ value: item.Record_Id, label });
			});

			this.setState({ serviceNotifications: notificationArr });
		}
	};

	getDepartmentSectionJobs = async () => {
		let at = this.props.currentUser.Data.access_token,
			departmentsArr = [];
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SECTION_JOBS",
			{
				Department_Service_Version_Id:
					this.props.departmentServiceVersionId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Department_Job_Name_AR
						: item.Department_Job_Name_EN;

				departmentsArr.push({ value: item.Department_Job_Id, label });
			});

			this.setState({ stepJobs: departmentsArr });
		}
	};

	componentDidMount() {
		this.getTimeTypes();

		// if ( this.props.initValues && (this.props.initValues.notification === 1 || this.props.initValues.notification === true ) ) {
		//     this.getServiceNotifications()
		// }

		if (this.props.departmentServiceVersionId) {
			this.getDepartmentSectionJobs(
				this.props.departmentServiceVersionId
			);
		}
	}

	onDepartmentVersionStepSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPD_DEPARTMENT_VERSION_STEP",
			bodyData = {
				Department_Service_Step_Id: this.props.departmentStepId,
				Department_Job_Id: values.stepJob,
				// Department_Service_Step_Time: values.stepTime,
				// Department_Service_Time_Type_Id: values.stepTimeType,
				// Department_Service_Step_Notification_Id: values.serviceNotification,
			};

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			if (this.props.editMood) {
				this.props.triggerDialogForm(false);
			}
		}
	};

	validate = (values) => {
		const errors = {};

		// if (!values.stepTime) {
		//     errors.stepTime = `${this.props.t('error.field_required')}`
		// }

		// if (!values.stepTimeType) {
		//     errors.stepTimeType = `${this.props.t('error.field_required')}`
		// }

		// if (!values.serviceNotification && (values.notification === 1 || values.notification === true)) {
		//     errors.serviceNotification = `${this.props.t('error.field_required')}`
		// }

		return errors;
	};

	render() {
		let { initValues } = this.props;

		return (
			<>
				<Form
					onSubmit={this.onDepartmentVersionStepSubmit}
					initialValues={
						initValues
							? initValues
							: {
									// stepTime: '',
									// stepTimeType: '',
									// serviceNotification: '',
									stepJob: "",
							  }
					}
					validate={this.validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => {
						// if ( (values.notification === 0 || values.notification === false) && values.serviceNotification ) {
						//     delete values.serviceNotification
						// }

						return (
							<form
								id="serviceStepForm"
								className="row"
								onSubmit={handleSubmit}
								noValidate
							>
								{/* <Col xs={12} md={4}>
                        <TextField
                            label={this.props.t('stepTime')}
                            className="mb-2"
                            required={true}
                            name="stepTime"
                            showError={({ meta: { submitFailed, invalid } }) => { 
                                return submitFailed && invalid 
                            } }
                        />
                    </Col>

                    <Col xs={12} md={4}>
                        <Select
                            label={this.props.t('stepTimeType')}
                            className="mb-2"
                            required={true}
                            name="stepTimeType"
                            data={this.state.timeTypes}
                            showError={  () => {
                                let fieldStatus = form.getFieldState('stepTimeType')

                                if (fieldStatus) {

                                    return fieldStatus.submitFailed && fieldStatus.invalid
                                }
                            } }
                        >
                        </Select>
                    </Col> */}

								{values.stepId === 2 && (
									<Col xs={12} md={4}>
										<Select
											label={this.props.t("workGroup")}
											className="mb-2"
											name="stepJob"
											data={this.state.stepJobs}
											showError={() => {
												let fieldStatus =
													form.getFieldState(
														"stepJob"
													);
												if (fieldStatus) {
													return (
														fieldStatus.submitFailed &&
														fieldStatus.invalid
													);
												}
											}}
										></Select>
									</Col>
								)}

								{/* {(values.notification === 1 || values.notification === true) && 
                    <Col xs={12} md={4}>
                        <Select
                            label={this.props.t('serviceNotification')}
                            className="mb-2"
                            name="serviceNotification"
                            data={this.state.serviceNotifications}
                            showError={  () => {
                                let fieldStatus = form.getFieldState('serviceNotification')
                                if (fieldStatus) {

                                    return fieldStatus.submitFailed && fieldStatus.invalid
                                }
                            } }
                        >
                        </Select>
                    </Col>
                    } */}

								<Col className="mt-4" xs={12}>
									<Row className="align-items-baseline">
										<Col className="mt-2 submit-btn-wrap">
											{!this.props.viewMood && (
												<ButtonPrimary
													color="primary"
													variant="contained"
													disabled={
														submitting ||
														this.props.viewMood
													}
													type="submit"
												>
													{this.props.t("saveStep")}
												</ButtonPrimary>
											)}
										</Col>
									</Row>
								</Col>
							</form>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(ServiceVersionStepForm));
