import apiConfig from '../../apiConfig'
export const getChartsList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/GET_APPROVING_CHART_LIST',
		{
			pageSize: 10000,
			PageIndex: 1
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	return response.data.Data;
};


export const setHosuingModuleStatus = async (at, state, id) => {
    const response = await apiConfig.post(
        'API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/UPD_APPROVING_CHART_STATUS',
        {
            Active_Status_Id: state,
            Approving_Chart_Id: id
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    if (response.data.Status) {
        return response.data
    }

    return []
}
export const deleteModule = async (at, moduleId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/DEL_APPROVING_CHART',
        {
            Approving_Chart_Id: moduleId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    if (response.data.Status) {
        return response.data
    }

    return []
}
export const deleteTemp = async (at, moduleId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/DEL_APPROVING_CHART_TEMPLATE',
        {
            Approving_Chart_Template_Id: moduleId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    if (response.data.Status) {
        return response.data
    }

    return []
}
export const getInvoiceDetails = async (at , id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PAYMENTS/INVOICES/GET_INVOICE_DETAILS',
		{
            Invoice_Id: id
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};
