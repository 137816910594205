import apiConfig from '../../apiConfig';

export const getSupportRequestAllList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/SUPPORTREQUEST/GET_SUPPORT_REQUEST_All_LIST',
		{
			PageIndex: 1,
			PageCount: 1000
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);

	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Active_Status_Id === 1) {
				response.data.Data[i].Active_Status_Id = true;
			} else {
				response.data.Data[i].Active_Status_Id = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
