import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../apis/apiConfig'
import { Tab, Row, Col, Nav } from 'react-bootstrap'
import { Link } from '@reach/router'
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { ButtonSecondary } from '../../UI/Buttons'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import style from '../Service.module.scss'
import ServiceVersionInfo from './ServiceVersionInfo'
import ServiceVersionDetail from './ServiceVersionDetail'
import ServiceVersionStep from './ServiceVersionStep'
import ServiceVersionTemplate from './ServiceVersionTemplate'
import { translationHook } from '../../translationHook'
import ServiceVersionActions from "./ServiceVersionActions";
import ServiceVersionSteps from './ServiceVersionSteps'


class DepartmentServiceVersion extends Component {

    state = {
        key: 0,
        next: 1,
        prev: -1,
        versionID: null,
        first: false,
        second: true,
        third: true,
        fourth: true,
        fifth: true,
        six: true,
        edit: false,
        view: false,
        versionInfoInit: {}
    }


    handleNext = () => {
        this.setState({
            key: this.state.next,
            next: this.state.next + 1,
            prev: this.state.prev + 1
        })
    }

    handlePrev = () => {
        this.setState({
            key: this.state.prev,
            next: this.state.next - 1,
            prev: this.state.prev - 1
        })
    }

    handleFirstStepDone = (vID) => {
        this.getVersionInfo(vID)
    } 

    renderChevs = () => {
        let rtl = this.props.i18n.language === 'ar' ? true : false,
        iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
        iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />

        return (
            <>
            <IconButton aria-label="prev" color="primary" onClick={this.handlePrev} disabled={this.state.key > 0 ? false : true}>
                {iconPrev}
            </IconButton>
            <IconButton aria-label="next" color="primary" onClick={this.handleNext} disabled={this.state.key < 3 && !this.state.second ? false : true}>
                {iconNext}
            </IconButton>
            </>
        )
    }

    getVersionInfo = async (versionId) => {
        let at = this.props.currentUser.Data.access_token,
        values = {}

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SERVICE_VERSION_INFO',
            {
                Department_Service_Version_Id: versionId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        if (response) {
            values.versionNo = response.data.Data.Department_Service_Version_No
            values.versionCode = response.data.Data.Department_Service_Version_Name
            values.departmentSectionID = response.data.Data.Department_Section_Id
        }
        
        // if #view in url set state to view mood to prevent submititons and hide submit UI components in all steps
        this.setState({
            versionID: versionId,
            key: this.props.versionId ? 0 : 1,
            second: false,
            third: false,
            fourth: false,
            fifth: false,
            six: false,
            edit: this.props.location.hash === '#view' ? false : true,
            view: this.props.location.hash === '#view' ? true : false,
            versionInfoInit: {...this.state.versionInfoInit, ...values}
        })
    }

    componentDidMount() {
        // if edit version and there is versionId prop in the url 
        if ( this.props.versionId ) {
            this.getVersionInfo(this.props.versionId)
        }
    }


    render() {

        let mystyle
        if (this.props.i18n.language === 'ar') {
            mystyle = "ServiceTabsAr"
        } else {
            mystyle = "ServiceTabsEn"
        }
        

        return(
            <Tab.Container id="left-tabs-example" defaultActiveKey={0} activeKey={this.state.key} onSelect={(k) => this.setState({key: k})} unmountOnExit="true">
                <Row>
                    <Col sm={12} className='d-flex justify-content-between align-items-center'>
                    <div>
                    {this.renderChevs()}
                    </div>
                    <Nav variant="pills" className={style.stepsNav}>
                        
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 0 ? style.active : ''}`} eventKey="0" disabled={this.state.first}>{this.props.t('serviceInfo')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 1 ? style.active : ''}`} eventKey="1" disabled={this.state.second}>{this.props.t('servicesActionsOrdersStep')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 2 ? style.active : ''}`} eventKey="2" disabled={this.state.third}>{this.props.t('serviceTemplate')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 3 ? style.active : ''}`} eventKey="3" disabled={this.state.fourth}>{this.props.t('serviceDetails')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 4 ? style.active : ''}`} eventKey="4" disabled={this.state.fifth}>{this.props.t('serviceVersionSteps')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={mystyle}>
                            <Nav.Link className={`${style.navLink} ${this.state.key === 5 ? style.active : ''}`} eventKey="5" disabled={this.state.six}>{this.props.t('serviceVersionStep')}</Nav.Link>
                        </Nav.Item>
                        
                    </Nav>
                        <ButtonSecondary
                        variant="outlined"
                        endIcon={<ExitToAppIcon />}
                        component={Link}
                        to={`/services/municipalities-services/${encodeURIComponent(this.props.serviceName)}/versions`}
                        state={{ departmentServiceId: this.props.location.state.departmentServiceId }}
                        size="large"
                        >
                            {this.props.t('exitToVersions')}
                        </ButtonSecondary>
                    </Col>
                    <Col sm={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey={0} disabled={this.state.first}>
                            <ServiceVersionInfo departmentServiceId={this.props.location.state.departmentServiceId} versionid={this.state.versionID} active={!this.state.first} initValues={this.state.versionInfoInit} onStepDone={this.handleFirstStepDone} editMood={this.state.edit} viewMood={this.state.view} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={1} disabled={this.state.second}>
                            <ServiceVersionActions departmentServiceId={this.props.location.state.departmentServiceId} versionid={this.state.versionID} active={!this.state.second} initValues={this.state.versionInfoInit} editMood={this.state.edit} viewMood={this.state.view} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={2} disabled={this.state.third} unmountOnExit="true">
                            <ServiceVersionTemplate departmentServiceId={this.props.location.state.departmentServiceId} versionid={this.state.versionID} active={!this.state.third} editMood={this.state.edit} viewMood={this.state.view} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={3} disabled={this.state.fourth} unmountOnExit="true">
                            <ServiceVersionDetail versionid={this.state.versionID} active={!this.state.fourth} editMood={this.state.edit} viewMood={this.state.view} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={4} disabled={this.state.fourth} unmountOnExit="true">
                            <ServiceVersionSteps versionid={this.state.versionID} active={!this.state.fourth} editMood={this.state.edit} viewMood={this.state.view} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={5} disabled={this.state.six} unmountOnExit="true">
                            <ServiceVersionStep versionid={this.state.versionID} active={!this.state.six} editMood={this.state.edit} viewMood={this.state.view} />
                        </Tab.Pane>
                        
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }

}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps)(translationHook(DepartmentServiceVersion))