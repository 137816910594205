import apiConfig from '../apiConfig'

export const getSliderList = async (at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/SLIDERS_MANAGEMENT/Get_All_Sliders',
        {
            PageSize: 100000,
            PageIndex: 1
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
               response.data.Data[i].Active_Status_Id = true
            } else {
                response.data.Data[i].Active_Status_Id = false
            }
          }
        return response.data.Data
    }

    return []
}
