import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Selection
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import Switch from '@material-ui/core/Switch';

import { getDepartmentJobSectionsList } from '../../../apis/departments/getDepartmentJobSectionsList'
import { addDepartmentJobSection } from '../../../apis/departments/addDepartmentJobSection'
import { deleteDepartmentJobSection } from '../../../apis/departments/deleteDepartmentJobSection'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'


class DepartmentJobSectionsList extends Component {


    customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Selected}
			name={`jobStatus_${data.Department_Job_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					status = data.Selected,
                    departmentJobId = data.Department_Job_Id,
                    jobSectionId = data.Department_Job_Section_Id,
                    departmentSectionId = data.Department_Section_Id,
                    response

                    this.props.setLoading(true, 'dark')

                    if (status) {
                        response = await deleteDepartmentJobSection(at, jobSectionId )
                    } else {
                        response = await addDepartmentJobSection(at, departmentJobId, departmentSectionId )
                    }
                    
					this.props.triggerNotification(true, response)

					this.props.setLoading(false)
			}}
			/>
        )
        
    }


    render() {

        let columnAlign = 'left',
        SectionNameKey = 'Department_Section_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            SectionNameKey = 'Department_Section_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'Department_Job_Section_Id',
            load: () => {
                return getDepartmentJobSectionsList(at, this.props.departmentJobId)
            }
        });
        
        return(
            <>

            <div className="container-fluid pb-3">
                
                <DataGrid
                    ref={this.dataGridRef}
                    className={this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en'}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
                    columnWidth={'auto'}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <ColumnChooser enabled={true}  allowSearch={true}/>

                    <SearchPanel 
                        visible={true}
                        width={240}
                        placeholder={this.props.t('search')}
                    />

                    <FilterRow visible={true} />

                    <HeaderFilter visible={true} />
                    
                    <Column
                        type="selection"
                        cellRender={this.customizeCellCheckbox}
                    />


                    <Column dataField={SectionNameKey} caption={this.props.t('sectionName')} alignment={columnAlign}>
                    </Column>

                    
                </DataGrid>

            </div>
            </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentJobSectionsList))