import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';

import { getDepartmentsSectionsList } from '../../../apis/departments/getDepartmentsSectionsList';
import { setDepartmentSectionStatus } from '../../../apis/departments/setDepartmentSectionStatus'
import { deleteDepartmentSection } from '../../../apis/departments/deleteDepartmentSection'
import { triggerDialog, triggerDialogForm, setLoading, triggerNotification  } from '../../../actions';
import { translationHook } from '../../translationHook';
import {
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning
} from '../../UI/Buttons';

import DepartmentSectionFrom from './DepartmentSectionFrom'

class DepartmentsSectionsList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Active_Status_Id}
			name={`sectionStatus_${data.Department_Section_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					departmentSectionId = data.Department_Section_Id,
					status = data.Active_Status_Id ? 0 : 1

					this.props.setLoading(true, 'dark')

					const response = await setDepartmentSectionStatus(at, departmentSectionId, status)

					this.props.triggerNotification(true, response)

					this.props.setLoading(false)
			}}
			/>
        )
        
    }

	renderSectionForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
			editMood = params[2] === 'edit' ? true : false

        return <DepartmentSectionFrom departmentSectionId={params[0]} initValues={params[1]} editMood={editMood} viewMood={viewMood} />
    }


	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			departmentSectionId = data.Department_Section_Id,
			departmentSectionName =
				this.props.i18n.language === 'ar'
					? data.Department_Section_Name_AR
					: data.Department_Section_Name_EN,
			dialogTitle = `${this.props.t('confirmDeleteTitle')} ${departmentSectionName}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteDepartmentSection,
				params: [
					at,
					departmentSectionId
				],
				text: this.props.t('deleteSection'),
			},
		});
	}

	handleEditSection = (data, viewMood) => {

        let initValues = {
			sectionNo: data.Department_Section_No,
            sectionStatus: data.Active_Status_Id,
            sectionNameAR: data.Department_Section_Name_AR,
            sectionNameEN: data.Department_Section_Name_EN,
            sectionDescAR: data.Department_Section_Description_AR,
            sectionDescEN: data.Department_Section_Description_EN,
            telephone: data.Department_Section_Phone,
            sectionCode: data.Department_Section_Code,
			municipalAdministrations: data.Department_Sector_Id
        },
        sectionId = data.Department_Section_Id,
        sectionName = this.props.i18n.language === 'ar' ? data.Department_Section_Name_AR : data.Department_Section_Name_EN,
        dialogTitle = `${viewMood === 'view' ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${sectionName}`

		
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderSectionForm,
            params: [
                sectionId,
                initValues,
				viewMood
            ]
        })

    }

	render() {
		let columnAlign = 'left',
			DepSectionNameKey = 'Department_Section_Name_EN',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right'
			DepSectionNameKey = 'Department_Section_Name_AR'
		}

		const jsonDataSource = new CustomStore({
			key: 'Department_Section_Id',
			load: () => {
				return getDepartmentsSectionsList(at, this.props.currentUser.Data.Department_Id);
			},
		});

		return (
			<>

				<DepartmentSectionFrom />

				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
					dataField="Department_Section_No"
					caption={this.props.t('sectionNo')}
					alignment={'center'}
					>
					</Column>
					
					<Column
					dataField={DepSectionNameKey}
					caption={this.props.t('sectionName')}
					alignment={columnAlign}
					>
					</Column>
					
					<Column
					dataField='Active_Status_Id'
					caption={this.props.t('sectionStatus')}
					allowFiltering={false}
					cellRender={this.customizeCellCheckbox}
					>
					</Column>
				
					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
									variant='circleIcon'
									startIcon={<EditIcon />}
									onClick={() => {
                                        this.handleEditSection(record.data, 'edit')
                                    }}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
									variant='circleIcon'
									startIcon={<VisibilityIcon />}
									onClick={() => {
                                        this.handleEditSection(record.data, 'view')
                                    }}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>


						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant='circleIcon'
										startIcon={<DeleteIcon />}
										onClick={() => this.handleDelete(record.data)}>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	
	onExporting(e) {
		let sheetTitle = this.props.t('departmentSections')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, triggerNotification, setLoading })(
	translationHook(DepartmentsSectionsList)
);
