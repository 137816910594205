import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../translationHook";
import apiConfig from "../../apis/apiConfig";
import { ButtonPrimary, ButtonWhite, ButtonSecondary } from "../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Switches, Autocomplete } from "mui-rff";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
  reload,
} from "../../actions";
import FilesUpload from "../UI/FileUpload/UploadFile";
import FilesList from "../UI/FileUpload/FilesList";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import {
  deleteSuspensionAttachment,
  cancelSusbension,
  downloadSuspensionAttachment,
} from "../../apis/violations";

class SuspensionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      clientsList: [],
      autoCompleteVal: props.label ? props.label : "",
      filesData: [],
      violationId: props.violationId ? props.violationId : null,
      files: "",
    };
  }

  handleUploadClick = (event) => {
    let files = event.target.files;

    if (files.length > 5) {
      this.setState({ files: this.props.t("cannotUploadToSuspension") });
    } else {
      this.setState({ files: Array.from(files) });
    }
  };

  getClientsList = async () => {
    let clientsArr = [],
      at = this.props.currentUser.Data.access_token,
      url = this.props.editMood
        ? "/API/ADMINISTRATION/VIOLATIONS/GET_CLIENTS_PROFILES"
        : "API/ADMINISTRATION/VIOLATIONS/GET_CLIENTS_PROFILES_WITHOUT_VIOLATIONS";

    const response = await apiConfig.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((client) => {
        let label = "",
          option = {};

        if (this.props.i18n.language === "ar") {
          label = client.Client_Name_AR ? client.Client_Name_AR : "";
          label = client.Dealing_Type_Name_AR
            ? label + " - " + client.Dealing_Type_Name_AR
            : label;
        } else {
          label = client.Client_Name_EN ? client.Client_Name_EN : "";
          label = client.Dealing_Type_Name_EN
            ? label + " - " + client.Dealing_Type_Name_EN
            : label;
        }

        label = client.License_No ? label + " - " + client.License_No : label;

        option["label"] = label;
        option["value"] = client?.Client_Profile_Id;

        clientsArr.push(option);
      });

      this.setState({ clientsList: clientsArr });
    }
  };

  getViolationAttachmentList = async () => {
    let at = this.props.currentUser.Data.access_token,
      url = "/API/ADMINISTRATION/VIOLATIONS/GET_VIOLATION_ATTACHMENT_LIST";

    const response = await apiConfig.post(
      url,
      {
        Violation_Id: this.state.violationId,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      this.setState({ filesData: response.data.Data });
    }
  };

  onViolationSubmit = async (values, form) => {
    let bodyFormData = new FormData(),
      at = this.props.currentUser.Data.access_token,
      url = !this.props.editMood
        ? "/API/ADMINISTRATION/VIOLATIONS/ADD_VIOLATION"
        : "/API/ADMINISTRATION/VIOLATIONS/UPD_VIOLATION",
      bodyData = values;

    if (this.props.editMood) {
      bodyData.Violation_Id = this.props.violationId;
    }

    bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

    if (typeof this.state.files !== "string" && this.state.files.length > 0) {
      this.state.files.forEach((file, index) => {
        bodyFormData.append(`Violation_Attach_File${index + 1}`, file);
      });
    }

    this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

    const response = await apiConfig
      .post(url, this.props.editMood ? bodyData : bodyFormData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .catch((error) => {
        return error.response;
      });

    if (response.data.Status) {
      this.setState({ violationId: response.data.Data.Violation_Id });
      if (!this.props.editMood) {
        form.restart();
        this.setState({ files: "", autoCompleteVal: "" });
      }
    }

    if (this.props.editMood) {
      this.props.triggerDialogForm(false);
    }

    this.props.triggerNotification(true, response.data);

    this.props.setLoading(false);
  };

  componentDidMount() {
    this.getClientsList();

    if (this.state.violationId) {
      this.getViolationAttachmentList();
    }
  }

  render() {
    let { initValues } = this.props;

    const validate = (values) => {
      const errors = {};

      if (!values.Client_Profile_Id) {
        errors.Client_Profile_Id = `${this.props.t("error.field_required")}`;
      }

      if (!values.Violation_Notes) {
        errors.Violation_Notes = `${this.props.t("error.field_required")}`;
      }

      return errors;
    };

    return (
      <>
        {!this.props.editMood && !this.props.viewMood && (
          <div className="d-flex justify-content-between align-items-center pb-5">
            <h3>{this.props.t("manageSuspensions")}</h3>
            <ButtonPrimary
              variant={!this.state.showForm ? "contained" : "outlined"}
              onClick={() => this.setState({ showForm: !this.state.showForm })}
            >
              {!this.state.showForm && this.props.t("addSuspensions")}
              {this.state.showForm && this.props.t("cancel")}
            </ButtonPrimary>
          </div>
        )}

        <Form
          onSubmit={this.onViolationSubmit}
          initialValues={
            initValues
              ? initValues
              : {
                  Client_Profile_Id: "",
                  Violation_Notes: "",
                }
          }
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              id="addSuspensionForm"
              className={`row ${
                !this.state.showForm &&
                !this.props.editMood &&
                !this.props.viewMood
                  ? "d-none"
                  : ""
              }`}
              onSubmit={handleSubmit}
              noValidate
            >
              <Col xs={12} md={6}>
                <Autocomplete
                  disabled={this.props.viewMood}
                  label={this.props.t("clientsList")}
                  className="mb-2"
                  name="Client_Profile_Id"
                  required={true}
                  options={this.state.clientsList}
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.label}
                  inputValue={this.state.autoCompleteVal}
                  onChange={(e, v) => {
                    this.setState({ autoCompleteVal: v ? v.label : "" });
                  }}
                  onInputChange={(e, v) => {
                    this.setState({
                      autoCompleteVal: v ? v : this.state.autoCompleteVal,
                    });
                  }}
                  showError={() => {
                    let fieldStatus = form.getFieldState("Client_Profile_Id");

                    if (fieldStatus) {
                      return fieldStatus.submitFailed && fieldStatus.invalid;
                    }
                  }}
                ></Autocomplete>
              </Col>

              <Col xs={12}>
                <TextField
                  disabled={this.props.viewMood}
                  label={this.props.t("notes")}
                  className="mb-2"
                  name="Violation_Notes"
                  required
                  autoComplete="off"
                  showError={({ meta: { submitFailed, invalid } }) => {
                    return submitFailed && invalid;
                  }}
                  multiline
                  rows={10}
                />
              </Col>

              {!this.props.editMood && !this.props.viewMood && (
                <>
                  <Col
                    xs={12}
                    md={3}
                    className="d-flex justify-content-start align-items-center mb-2"
                  >
                    {!this.props.viewMood && (
                      <ButtonSecondary
                        color="primary"
                        variant="contained"
                        endIcon={<CloudUploadIcon />}
                      >
                        {this.props.t("attachments")}
                        <input
                          multiple
                          type="file"
                          name="serviceImg"
                          className="buttonFile"
                          onChange={this.handleUploadClick}
                        />
                      </ButtonSecondary>
                    )}
                  </Col>

                  {typeof this.state.files !== "string" &&
                  this.state.files.length > 0 ? (
                    <Col xs={12}>
                      <FilesList filesList={this.state.files} />
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <p>{this.state.files}</p>
                    </Col>
                  )}
                </>
              )}

              {(this.props.editMood || this.props.viewMood) &&
                this.state.violationId && (
                  <Col xs={12} md={6}>
                    {!this.props.viewMood && (
                      <h5 className="my-4 text-start">
                        {this.props.t("attachments")}
                      </h5>
                    )}

                    {this.props.viewMood && this.state.filesData.length > 0 && (
                      <h5 className="my-4 text-start">
                        {this.props.t("attachments")}
                      </h5>
                    )}
                    <FilesUpload
                      at={this.props.currentUser.Data.access_token}
                      endPoint="API/ADMINISTRATION/VIOLATIONS/ADD_VIOLATION_ATTACHMENT"
                      params={{ Violation_Id: this.state.violationId }}
                      filesData={this.state.filesData}
                      fileApiKey="Violation_Attach_File"
                      single={true}
                      deleteViolation={deleteSuspensionAttachment}
                      getUpdatedList={this.getViolationAttachmentList}
                      downloadCB={downloadSuspensionAttachment}
                      view={this.props.viewMood}
                    />
                  </Col>
                )}

              <Col className="my-4 justify-content-start d-flex" xs={12}>
                {!this.props.viewMood && (
                  <ButtonPrimary
                    color="primary"
                    variant="contained"
                    disabled={submitting || this.props.viewMood}
                    type="submit"
                  >
                    {this.props.t("save")}
                  </ButtonPrimary>
                )}

                {this.props.editMood && (
                  <ButtonWhite
                    className="mx-2"
                    variant="outline"
                    onClick={async () => {
                      this.props.setLoading(true, "dark", 1501);

                      await cancelSusbension(
                        this.props.currentUser.Data.access_token,
                        this.state.violationId
                      )
                        .then((result) => {
                          this.props.setLoading(false);
                          if (result.data.Status) {
                            this.props.triggerDialogForm(false);
                            this.props.reload();
                          }
                        })
                        .catch((error) => {
                          this.props.setLoading(false);
                          this.props.triggerNotification(
                            true,
                            error.response.data
                          );
                        });
                    }}
                  >
                    {this.props.t("cancelSuspension")}
                  </ButtonWhite>
                )}
              </Col>
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
  reload,
})(translationHook(SuspensionForm));
