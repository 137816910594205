import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ButtonPrimary } from '../../UI/Buttons'
import { Form } from 'react-final-form'
import { Select } from 'mui-rff'

import apiConfig from '../../../apis/apiConfig';
import { translationHook } from '../../translationHook'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'



class ServiceVersionDetailForm extends Component {

    state = {
        serviceLookups: []
    }


    getServiceLookups = async ()=> {
        let lookupsArr = []
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_SERVICES_LOOKUPS'
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN

                lookupsArr.push({value: item.Record_Id, label})
            })

            this.setState({serviceLookups: lookupsArr})
            
        }

    }

    componentDidMount() {

        this.getServiceLookups()

    }

    onDepartmentVersionDetailSubmit = async (values, form) => {

        let at = this.props.currentUser.Data.access_token,
        url = '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPD_DEPARTMENT_VERSION_DETAIL',
        bodyData = {
            Department_Service_Detail_Id: this.props.departmentDetailId,
            Department_Service_Detail_Lookup_Id: values.serviceLookups
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        );

        this.props.setLoading(false)

        this.props.triggerNotification(true, response.data)

        if (response.data.Status) {
            if (this.props.editMood) {
                this.props.triggerDialogForm(false)
            }
        }
        
    }

    validate = values => {
        const errors = {}

        if (!values.serviceLookups) {
            errors.serviceLookups = `${this.props.t('error.field_required')}`
        }
        
        return errors;
    }

    render() {
        let { initValues } = this.props

        return (
            <>
            <Form
            onSubmit={this.onDepartmentVersionDetailSubmit}
            initialValues={initValues ? initValues : {
                serviceLookups: ''
            }}
            validate={this.validate}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                
            return (
                <form
                id="versionDetailForm"
                className="row"
                onSubmit={handleSubmit}
                noValidate>
            

                    <Col xs={12} md={4}>
                        <Select
                            label={this.props.t('serviceLookups')}
                            className="mb-2"
                            required={true}
                            name="serviceLookups"
                            data={this.state.serviceLookups}
                            showError={  () => {
                                let fieldStatus = form.getFieldState('serviceLookups')

                                if (fieldStatus) {

                                    return fieldStatus.submitFailed && fieldStatus.invalid
                                }
                            } }
                        >
                        </Select>
                    </Col>


                    <Col className='mt-4' xs={12}>
                        <Row className='align-items-baseline'>
                            <Col className='mt-2 submit-btn-wrap'>
                                { !this.props.viewMood && 
                                <ButtonPrimary
                                    color="primary"
                                    variant="contained"
                                    disabled={submitting || this.props.viewMood}
                                    type="submit"
                                >
                                    {this.props.t('saveStep')}
                                </ButtonPrimary>
                                }
                            </Col>
                        </Row>
                    </Col>
                    
                </form>
                )
            }
        }
        />
            </>
        ) 
    }
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(ServiceVersionDetailForm))