import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import {
	ButtonPrimary,
	ButtonDanger,
	ButtonSecondary,
} from "../../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import FilesUpload from "../../../UI/FileUpload/UploadFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Avatar from "@material-ui/core/Avatar";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import { Form, Field } from "react-final-form";
import DateBox from "devextreme-react/date-box";
import { TextField, Select, Autocomplete } from "mui-rff";
import { downloadRequestAttachment } from "../../../../apis/requests/info/downloadRequestAttachment";

import TemplateInvoices from "./TemplateInvoices";
import IntegrationSearch from "./IntegrationSearch";
import TaskForm from "../../../dashboard/TaskForm";
import Processing from "../../../requests/processing/Processing";
import style from "./Template.module.scss";

class Template extends Component {
	state = {
		filesData: [],
		attachedData: [],
		showButton: true,
		templateVariables: [],
		templateInvoices: [],
		elements: [],
		initValues: {},
		selectedInvoices: [],
		templateApiData: null,
		serviceTemplates: [],
		selectedTemplate: null,
		autoCompleteVal: "",
		rerender: false,
		showTemplateForm: false,
		templateVarAttach: "",
		templateVarAttachImgUrl: "",
		templateAttchTypeErr: "",
		templateAttachList: [],
	};

	getLookupValue = async (variableId) => {
		let typesArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_LOOKUPS_VALUES",
			{
				Service_Lookup_Id: variableId,
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({
				[variableId]: typesArr,
				rerender: !this.state.rerender,
			});
		}
	};

	getServiceTemplates = async () => {
		let templatesArr = [];
		let at = this.props.currentUser.Data.access_token;

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_TEMPLATES_LIST",
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Template_Name_AR
						: item.Template_Name_EN;

				templatesArr.push({ value: item.Template_Id, label });
			});

			this.setState({ serviceTemplates: templatesArr });

			this.getRequestTemplateFiles();
		}
	};

	handleUploadClick = (event, variableId) => {
		const typesArray = [
			"image/bmp",
			"image/gif",
			"image/vnd.microsoft.icon",
			"image/jpeg",
			"image/png",
			"image/svg+xml",
			"image/tiff",
			"image/webp",
		];
		let file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);

		let templateAttachDataIndex = this.state.templateAttachList.findIndex(
				(element) => element.variableId == variableId
			),
			templateAttachData;

		if (!typesArray.includes(file.type)) {
			if (templateAttachDataIndex !== -1) {
				templateAttachData =
					this.state.templateAttachList[templateAttachDataIndex];

				templateAttachData["templateAttchTypeErr"] = this.props.t(
					"onlyImagesFileTypeAllowed"
				);
				templateAttachData["templateVarAttach"] = "";
				templateAttachData["templateVarAttachImgUrl"] = "";

				let modifiedList = this.state.templateAttachList.filter(
					(element) => element.variableId !== variableId
				);

				modifiedList.push(templateAttachData);

				this.setState({
					templateAttachList: modifiedList,
					rerender: !this.state.rerender,
				});
			} else {
				let newAttach = {
					variableId: variableId,
					templateVarAttach: "",
					templateVarAttachImgUrl: "",
					templateAttchTypeErr: this.props.t(
						"onlyImagesFileTypeAllowed"
					),
				};

				let modifiedList = this.state.templateAttachList;
				modifiedList.push(newAttach);

				this.setState({
					templateAttachList: modifiedList,
					rerender: !this.state.rerender,
				});
			}

			return;
		}

		reader.onloadend = (e) => {
			if (templateAttachDataIndex !== -1) {
				templateAttachData =
					this.state.templateAttachList[templateAttachDataIndex];

				templateAttachData["templateVarAttach"] = file;
				templateAttachData["templateVarAttachImgUrl"] = e.target.result;
				templateAttachData["templateAttchTypeErr"] = "";

				let modifiedList = this.state.templateAttachList.filter(
					(element) => element.variableId !== variableId
				);

				modifiedList.push(templateAttachData);

				this.setState({
					templateAttachList: modifiedList,
					rerender: !this.state.rerender,
				});
			} else {
				let newAttach = {
					variableId: variableId,
					templateVarAttach: file,
					templateVarAttachImgUrl: e.target.result,
					templateAttchTypeErr: "",
				};

				let modifiedList = this.state.templateAttachList;
				modifiedList.push(newAttach);

				this.setState({
					templateAttachList: modifiedList,
					rerender: !this.state.rerender,
				});
			}
		};
	};

	renderVariableFields = () => {
		let elements = [],
			initValues = {};

		this.state.templateVariables.map((variable, i) => {
			elements.push(
				<Col
					xs={12}
					md={
						variable.Template_Variable_Type_Id !== 2 &&
						variable.Template_Variable_Type_Id !== 5 &&
						variable.Template_Variable_Type_Id !== 6
							? 12
							: 6
					}
					key={variable.Template_Variable_Id}
					className={style.inputFields}
				>
					{variable.Template_Variable_Type_Id === 2 ? (
						<Select
							label={variable.Template_Variable_Name_AR}
							style={{ marginBottom: 0 }}
							name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}_${variable.Template_Variable_Lookup_Id}`}
							data={
								this.state[
									variable.Template_Variable_Lookup_Id
								] ?? []
							}
						></Select>
					) : variable.Template_Variable_Type_Id === 5 ? (
						<TextField
							label={variable.Template_Variable_Name_AR}
							name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
							type="number"
						/>
					) : variable.Template_Variable_Type_Id === 6 ? (
						<>
							<Field
								name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
							>
								{(fieldProps) => (
									<DateBox
										acceptCustomValue={false}
										defaultValue={null}
										dateSerializationFormat="yyyy-MM-dd"
										displayFormat="yyyy-MM-dd"
										onValueChanged={(e) => {
											fieldProps.input.onChange(e.value);
										}}
										style={{ borderRadius: "50px" }}
										type="date"
										placeholder={
											variable.Template_Variable_Name_AR
										}
										className="mt-3"
										// isValid={() => {
										//     return !fieldProps.meta?.invalid && !fieldProps.meta?.submitFailed
										// }}
									/>
								)}
							</Field>
						</>
					) : variable.Template_Variable_Type_Id === 3 ? (
						<div>
							<ButtonSecondary
								color="primary"
								variant="contained"
								endIcon={<CloudUploadIcon />}
							>
								<span className="mx-3">
									{this.props.i18n.language === "ar"
										? variable.Template_Variable_Name_AR
										: variable.Template_Variable_Name_EN}
								</span>
								<input
									type="file"
									name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
									className="buttonFile"
									onChange={(e) => {
										this.handleUploadClick(
											e,
											variable.Template_Variable_Id
										);
									}}
								/>
							</ButtonSecondary>

							{(() => {
								let templateAttachDataIndex =
										this.state.templateAttachList.findIndex(
											(element) =>
												element.variableId ==
												variable.Template_Variable_Id
										),
									templateAttachData;

								if (templateAttachDataIndex !== -1) {
									templateAttachData =
										this.state.templateAttachList[
											templateAttachDataIndex
										];

									if (
										templateAttachData.templateAttchTypeErr
									) {
										return (
											<p className="text-danger mt-3">
												{
													templateAttachData.templateAttchTypeErr
												}
											</p>
										);
									}

									if (templateAttachData.templateVarAttach) {
										return (
											<List
												component="nav"
												disablePadding={true}
												style={{
													backgroundColor: "#fff",
													border: "1px solid #eee",
													borderRadius: "4px",
													marginTop: "10px",
												}}
											>
												<ListItem>
													<ListItemAvatar
														style={{
															marginInline:
																"10px",
															minWidth: "20px",
														}}
													>
														<CropOriginalIcon />
													</ListItemAvatar>
													<ListItemText
														// ref={textRef}
														primary={
															templateAttachData
																.templateVarAttach
																?.name
														}
														secondary={
															templateAttachData
																.templateVarAttach
																?.size
														}
													/>

													<ListItemSecondaryAction>
														<IconButton
															edge="end"
															aria-label="download"
															onClick={() => {
																let modifiedList =
																	this.state.templateAttachList.filter(
																		(
																			element
																		) =>
																			element.variableId !==
																			variable.Template_Variable_Id
																	);

																this.setState({
																	templateAttachList:
																		modifiedList,
																	rerender:
																		!this
																			.state
																			.rerender,
																});
															}}
														>
															<DeleteIcon />
														</IconButton>
													</ListItemSecondaryAction>
												</ListItem>
											</List>
										);
									}
								}
							})()}
						</div>
					) : (
						<TextField
							label={variable.Template_Variable_Name_AR}
							name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
							multiline
							rows={4}
						/>
					)}

					<Field
						name={`tempVarId_${variable.Template_Variable_Id}`}
						render={() => {
							return (
								<input
									type="hidden"
									name={`tempVarId_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
									value={variable.Template_Variable_Id}
								/>
							);
						}}
					/>
				</Col>
			);

			if (
				variable.Template_Variable_Type_Id === 2 &&
				variable.Template_Variable_Lookup_Id
			) {
				initValues[
					`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}_${variable.Template_Variable_Lookup_Id}`
				] = variable.Template_Variable_Value
					? variable.Template_Variable_Value
					: "";
			} else {
				initValues[
					`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`
				] = variable.Template_Variable_Value
					? variable.Template_Variable_Value
					: "";
			}
		});

		this.setState({ elements });
		this.setState({ initValues });
	};

	saveRequestTaskTemplateFile = async (values) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_TEMPLATE_VARIABLES",
			arr = [],
			bodyFormData = new FormData(),
			templateAttachImgFieldTypeId;

		// make sure its template issuance step to prevenet accedentally submitting the form even if its not visible in approve & template approve steps
		if (this.props.task !== "templateIssuance") {
			return null;
		}

		for (const key in values) {
			if (Object.hasOwnProperty.call(values, key)) {
				let id = key.split("_")[1],
					value = values[key],
					typeId = key.split("_")[2],
					templateVariableValue = value;

				if (typeId == 2) {
					let lookupId = key.split("_")[3];

					this.state[lookupId].forEach((lookup) => {
						if (lookup.value == value) {
							templateVariableValue = lookup.label;
						}
					});
				}

				//skip adding image to formdata body if exist
				if (typeId == 3) {
					templateAttachImgFieldTypeId = id;
					continue;
				}

				arr.push({
					Template_Variable_Id: id,
					Template_Variable_Value: templateVariableValue,
				});
			}
		}

		let bodyData = {
			Request_Id: this.props.requestId,
			Request_Task_Id: this.props.requestTaskId,
			Temp_Action_Id: this.props.tempId,
			Template_Variables_Inputs_List: arr,
			Template_Variables_Invoices_List: this.state.selectedInvoices,
			Template_Id: this.state.selectedTemplate,
		};

		bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

		if (this.state.templateAttachList.length) {
			this.state.templateAttachList.forEach((element) => {
				if (element.templateVarAttach) {
					bodyFormData.append(
						element.variableId,
						element.templateVarAttach
					);
				}
			});
		}

		// if (
		// 	this.state.templateVarAttach &&
		// 	this.state.templateVarAttach !== ""
		// ) {
		// 	bodyFormData.append(
		// 		templateAttachImgFieldTypeId,
		// 		this.state.templateVarAttach
		// 	);
		// }

		this.props.setLoading(true, "dark", 999999);

		const response = await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			this.setState({
				filesData: response.data.Data,
				selectedTemplate: null,
				elements: [],
				templateApiData: null,
				templateInvoices: [],
				serviceTemplates: [],
				autoCompleteVal: "",
				initValues: {},
				templateVarAttach: "",
				templateVarAttachImgUrl: "",
				templateAttchTypeErr: "",
				templateAttachList: [],
			});
			this.props.handleNotesState(true);
			this.setState({ showTemplateForm: false });
			this.getServiceTemplates();
		}
	};

	getRequestTemplateFiles = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_TEMPLATES_FILES_LIST";

		const response = await apiConfig
			.post(
				url,
				{
					Request_Id: this.props.requestId,
					Request_Task_Id: this.props.requestTaskId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((res) => {
				if (res.data.Status) {
					this.setState({ filesData: res.data.Data });
				}
			});

		if (this.props.templateFilesLoadedCB) {
			this.props.templateFilesLoadedCB(true);
		}
	};

	getRequestTemplateVariables = async (Template_Id) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_TEMPLATE_VARIABLES";

		this.props.setLoading(true, "dark", 999999);

		this.setState({
			elements: [],
			templateVariables: [],
			templateApiData: null,
			templateInvoices: [],
			initValues: {},
		});

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				Template_Id,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status && response.data.Data) {
			let {
				Template_Variables_Data,
				Template_Invoices_Data,
				Template_API_Data,
			} = response.data.Data;

			if (Template_Variables_Data && Template_Variables_Data.length > 0) {
				this.setState({ templateVariables: Template_Variables_Data });

				Template_Variables_Data.forEach((variable) => {
					if (variable.Template_Variable_Type_Id === 2) {
						this.getLookupValue(
							variable.Template_Variable_Lookup_Id
						);
					}
				});
			}

			if (Template_Invoices_Data && Template_Invoices_Data.length > 0) {
				this.setState({ templateInvoices: Template_Invoices_Data });
			}

			if (Template_API_Data) {
				this.setState({ templateApiData: Template_API_Data });
			}

			this.setState({ rerender: !this.state.rerender });
		}

		this.props.setLoading(false);
	};

	approveRequestTaskTemplateFile = async ({ File_Code }) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				this.props.task === "templateIssuance"
					? "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SIGN_REQUEST_TASK_TEMPLATE_FILE"
					: "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/APPROVING_REQUEST_TASK_TEMPLATE_FILE";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ filesData: response.data.Data });
		}
	};

	unApproveRequestTaskTemplateFile = async ({ File_Code }) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/UNAPPROVING_REQUEST_TASK_TEMPLATE_FILE";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ filesData: response.data.Data });
		}
	};

	deleteRequestTaskTemplateFile = async (file) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/Delete_Request_Task_Template_File";

		this.props.setLoading(true, "dark", 999999);

		let response = await apiConfig
			.post(
				url,
				{
					Request_Id: this.props.requestId,
					Request_Task_Id: this.props.requestTaskId,
					File_Code: file.File_Code,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((result) => {
				if (result.data.Status) {
					const updatedFilesList = this.state.filesData.filter(
						(f) => {
							return file.File_Code !== f.File_Code;
						}
					);

					this.setState({ filesData: updatedFilesList });

					this.getServiceTemplates();
				}
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);
	};

	componentDidMount() {
		if (this.props.task === "templateIssuance") {
			// this.getRequestTemplateVariables();
			this.getServiceTemplates();
		}

		if (
			this.props.task === "approve" ||
			this.props.task === "templateApprove"
		) {
			this.getRequestTemplateFiles();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.rerender !== this.state.rerender) {
			this.renderVariableFields();
		}
	}

	handleClear = async () => {
		this.props.handleNotesState(true);
		this.setState({
			selectedTemplate: null,
			elements: [],
			templateVariables: [],
			templateApiData: null,
			templateInvoices: [],
			initValues: {},
			showTemplateForm: false,
			templateVarAttach: "",
			templateVarAttachImgUrl: "",
			templateAttchTypeErr: "",
			templateAttachList: [],
		});
	};

	render() {
		const validate = (values) => {
			const errors = {};
			return errors;
		};

		return (
			<>
				<Form
					onSubmit={this.saveRequestTaskTemplateFile}
					initialValues={this.state.initValues}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="templateVariableForm"
							className={`row ${
								this.props.i18n.language === "ar"
									? "rtlFields"
									: ""
							}`}
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12}>
								<Row>
									{this.props.task === "templateIssuance" &&
										!this.state.showTemplateForm && (
											<>
												<Col md={3}>
													<Autocomplete
														label={this.props.t(
															"selectServiceTemplate"
														)}
														className="my-2"
														name="serviceTemplate"
														required={true}
														options={
															this.state
																.serviceTemplates
														}
														getOptionValue={(
															option
														) => option.value}
														getOptionLabel={(
															option
														) => option.label}
														inputValue={
															this.state
																.autoCompleteVal
														}
														onChange={(e, v) => {
															this.setState({
																autoCompleteVal:
																	v
																		? v.label
																		: "",
															});
															if (v) {
																this.setState({
																	selectedTemplate:
																		v.value,
																});
															} else {
																this.setState({
																	elements:
																		[],
																	templateVariables:
																		[],
																	templateApiData:
																		null,
																	templateInvoices:
																		[],
																	initValues:
																		{},
																});
															}
														}}
														onInputChange={(e, v) =>
															this.setState({
																autoCompleteVal:
																	v ? v : "",
															})
														}
													></Autocomplete>
												</Col>

												<Col
													md={3}
													className="d-flex align-items-baseline mt-4 mb-3 col-md-3"
												>
													<ButtonPrimary
														color="primary"
														variant="contained"
														disabled={
															!this.state
																.selectedTemplate
														}
														onClick={() => {
															this.getRequestTemplateVariables(
																this.state
																	.selectedTemplate
															);
															this.getRequestTemplateFiles();
															this.setState({
																showTemplateForm: true,
															});
															this.props.handleNotesState(
																false
															);
														}}
													>
														{this.props.t(
															"issueTemplate"
														)}
													</ButtonPrimary>
												</Col>

												<Col md={6}>
													<h5>
														{this.props.t(
															"documents"
														)}
													</h5>
													<Row>
														<Col xs={12} md={8}>
															<FilesUpload
																at={
																	this.props
																		.currentUser
																		.Data
																		.access_token
																}
																endPoint="/API/ADMINISTRATION/REQUESTS/MANAGEMENT/ATTACHED_REQUEST_TASK_TEMPLATE_DOCUMENT"
																params={{
																	Request_Id:
																		this
																			.props
																			.requestId,
																	Request_Task_Id:
																		this
																			.props
																			.requestTaskId,
																}}
																downloadCB={
																	downloadRequestAttachment
																}
																view={false}
																filesData={
																	this.state
																		.filesData
																}
																deleteCB={
																	this
																		.deleteRequestTaskTemplateFile
																}
																approveCB={
																	this
																		.approveRequestTaskTemplateFile
																}
																unApproveCB={
																	this
																		.unApproveRequestTaskTemplateFile
																}
															/>
														</Col>
													</Row>
												</Col>
											</>
										)}
								</Row>
							</Col>

							{this.props.task === "templateIssuance" && (
								<>
									<Col xs={12} md={6}>
										<Row>{this.state.elements}</Row>
									</Col>

									<Col xs={12} md={6}>
										{this.state.templateInvoices &&
											this.state.templateInvoices.length >
												0 && (
												<TemplateInvoices
													invoices={
														this.state
															.templateInvoices
													}
													setSelectedInvoices={(
														invoicesList
													) =>
														this.setState({
															selectedInvoices:
																invoicesList,
														})
													}
												/>
											)}
									</Col>

									<Col xs={12}>
										{this.state.templateApiData && (
											<IntegrationSearch
												requestId={this.props.requestId}
												requestTaskId={
													this.props.requestTaskId
												}
												tempId={this.props.tempId}
												requestNo={this.props.requestNo}
												templateApi={
													this.state.templateApiData
														?.Template_API_Id
												}
												templateId={
													this.state.selectedTemplate
												}
											/>
										)}
									</Col>

									{this.state.showTemplateForm && (
										<>
											<Col
												className="my-5 justify-content-start d-flex"
												xs={12}
											>
												<ButtonPrimary
													color="primary"
													variant="contained"
													disabled={
														submitting ||
														this.state
															.templateAttchTypeErr
													}
													type="submit"
												>
													{this.props.t(
														"fillTemplate"
													)}
												</ButtonPrimary>

												<ButtonDanger
													style={{
														borderRadius: "30px",
														padding:
															".75rem 1.5rem",
														minWidth: 90,
													}}
													variant="contained"
													onClick={this.handleClear}
												>
													{this.props.t("cancel")}
												</ButtonDanger>
											</Col>
										</>
									)}
								</>
							)}
						</form>
					)}
				/>

				{this.props.task === "templateApprove" ||
				this.props.task === "approve" ? (
					<Col md={12}>
						<h5>{this.props.t("templatesList")}</h5>
						<Row>
							<Col xs={12} md={8}>
								<FilesUpload
									at={
										this.props.currentUser.Data.access_token
									}
									// endPoint="/API/ADMINISTRATION/REQUESTS/MANAGEMENT/ATTACHED_REQUEST_TASK_TEMPLATE_DOCUMENT"
									// params={{
									// 	Request_Id: this.props.requestId,
									// 	Request_Task_Id: this.props.requestTaskId
									// }}
									downloadCB={downloadRequestAttachment}
									view={true}
									filesData={this.state.filesData}
									deleteCB={
										this.deleteRequestTaskTemplateFile
									}
									approveCB={
										this.approveRequestTaskTemplateFile
									}
									unApproveCB={
										this.unApproveRequestTaskTemplateFile
									}
								/>
							</Col>
						</Row>
					</Col>
				) : null}
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return {
		currentUser: state.currentUser,
		currentDialogData: state.currentDialogData.Data,
	};
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(Template));
