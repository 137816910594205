import apiConfig from '../apiConfig';

export const addDepartmentJobSection = async (at, departmentJobId, departmentSectionId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/ADD_DEPARTMENT_JOB_SECTION', 
		{
			Department_Job_Id: departmentJobId,
            Department_Section_Id: departmentSectionId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    return response.data
};
