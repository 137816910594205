import React, { Component } from "react";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

import Login from "./Login/Login";
import VerificationLogin from "./verification/Login";
import ResetPassAfterFirstLog from "./Login/ResetPassword";
import Main from "./Main";
import Notification from "./UI/Notification";
import Loading from "./UI/Loading";
import DialogNotification from "./UI/DialogNotification";
import DialogForm from "./UI/DialogForm";
import { getUserDetails } from "../actions";

class App extends Component {
  componentDidMount() {
    this.props.getUserDetails();
  }

  render() {
    // const { currentUser } = this.props

    if (!this.props.currentUser.Status) {
      return (
        <>
          <Login />
          <Notification />
          <Loading />
        </>
      );
    } else {
      if (
        !this.props.currentUser.verifyMail ||
        !this.props.currentUser.verifyPhone ||
        this.props.currentUser.Data.Reset_Password === 1
      ) {
        return (
          <>
            <VerificationLogin />
            <Notification />
            <Loading />
          </>
        );
      } else {
        return (
          <>
            <Main />
            <Notification />
            <DialogNotification />
            <DialogForm />
            <Loading />
          </>
        );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default withCookies(connect(mapStateToProps, { getUserDetails })(App));
