import React, { useState, useEffect } from 'react'
import style from './Menu.module.scss'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItemComponent from './MenuItemComponent'
import { useTranslation } from 'react-i18next'
import SubMenu from './SubMenu/SubMenu'
import { RightArrow, LeftArrow } from './../../SVG/Arrow'

const MenuItem = props => {

    const { title, items = [], menuIsOpen, respIsOpen } = props
    const [menuOpen, setMenuOpen] = useState(false)
    const [respMenuOpen, setRespMenuOpen] = useState(true)
    const [respMood, setRespMood] = useState(false)
    const { t, i18n } = useTranslation('common')

    let tranStyle
    if (i18n.language === 'en') {
        tranStyle = require('./Menu-ltr.module.scss')
    } else {
        tranStyle = require('./Menu-rtl.module.scss')
    }

    useEffect(() => {
        setMenuOpen(menuIsOpen)
        if (menuIsOpen === undefined) {
            setMenuOpen(true)
        }
    }, [menuIsOpen])

    useEffect(() => {
        if (window.innerWidth <= 959) {
            setRespMood(true)
        }
        setRespMenuOpen(!respMenuOpen)
    }, [respIsOpen])

    const handleLev1Expand = e => {
        const item = e.target.closest('.lev1Btn')
        const lev1Items = document.querySelectorAll('.collapseLev1')
        const arrow = item.children[1].children[0].children.length > 0 &&
            item.children[1].children[0].children[0].children[0]

        // close all & open only the clicked one & change arrow direction
        lev1Items.forEach(x => {
            let xArrow = x.previousSibling.children[1].children[0].children.length > 0 &&
                x.previousSibling.children[1].children[0].children[0].children[0]
            if (!item.nextSibling.isEqualNode(x)) {
                x.classList.add('close-collapseLev1')
                x.classList.remove('open-collapseLev1')
                x.previousSibling.classList.remove('lev1BtnActive')
                if (xArrow) {
                    xArrow.style.transform = 'rotate(0deg)'
                }
                if (x.children.length > 0) {
                    let children = x.childNodes
                    children.forEach(child => {
                        if (child.classList.contains('open-collapseLev2')) {
                            child.classList.remove('open-collapseLev2')
                            child.classList.add('close-collapseLev2')
                            if (child.previousSibling.length > 0) {
                                child.previousSibling.children[1].style.transform = 'rotate(0deg)'
                            }
                            child.childNodes.forEach(x => {
                                if (x.classList.contains('open-collapseLev3')) {
                                    x.classList.remove('open-collapseLev3')
                                    x.classList.add('close-collapseLev3')
                                    if (x.previousSibling.length > 0) {
                                        x.previousSibling.children[1].style.transform = 'rotate(0deg)'
                                    }
                                }
                            })
                        }
                    })
                }
            }
        })
        if (item.nextSibling.classList.contains('open-collapseLev1')) {
            item.nextSibling.classList.remove('open-collapseLev1')
            item.nextSibling.classList.add('close-collapseLev1')
            if (item.nextSibling.children.length > 0) {
                item.classList.remove('lev1BtnActive')
            }
            if (arrow) {
                arrow.style.transform = 'rotate(0deg)'
            }
        } else {
            item.nextSibling.classList.add('open-collapseLev1')
            item.nextSibling.classList.remove('close-collapseLev1')
            if (item.nextSibling.children.length > 0) {
                item.classList.add('lev1BtnActive')
            }
            if (arrow) {
                arrow.style.transform = i18n.language === 'en' ? 'rotate(90deg)' : 'rotate(-90deg)'
            }
        }
    }

    const MenuItemRoot = (
        <>
            {items.map((item, i) => (
                <React.Fragment key={i}>
                    <MenuItemComponent
                        className={[
                            'lev1Btn',
                            style.menuItem,
                            item.active ? style.menuItemActive : null,
                            item.active ? tranStyle.menuItemActive : null
                        ].join(' ')}
                        link={item.link}

                    >
                        {/* <span className={[style.activeCurve, style.topCurve, tranStyle.activeCurve].join(' ')}>
                            {i18n.language === 'en' ?
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 14 14" xmlSpace="preserve">
                                    <g>
                                        <path d="M3,14h11V3C14,10.7,10.7,14,3,14z" />
                                    </g>
                                </svg>
                                :
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 14 14" xmlSpace="preserve">
                                    <g>
                                        <path d="M11,14H0V3C0,10.7,3.3,14,11,14z" />
                                    </g>
                                </svg>
                            }
                        </span>
                        <span className={[style.activeCurve, style.bottomCurve, tranStyle.activeCurve].join(' ')}>
                            {i18n.language === 'en' ?
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 14 14" xmlSpace="preserve">
                                    <g>
                                        <path d="M3,0h11v11C14,3.3,10.7,0,3,0z" />
                                    </g>
                                </svg>
                                :
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 14 14" xmlSpace="preserve">
                                    <g>
                                        <path d="M11,0H0v11C0,3.3,3.3,0,11,0z" />
                                    </g>
                                </svg>
                            }
                        </span> */}

                        <ListItemIcon className={style.icon}>
                            {item.Icon}
                        </ListItemIcon>

                        <ListItemText
                            className={[
                                !menuOpen && !respMood ? style.hideItemText : null,
                                style.itemText
                            ].join(' ')}
                            onClick={e => handleLev1Expand(e)}
                        >
                            {item.children ?
                                <>
                                    <div className='d-flex justify-content-between'>
                                        {item.name}

                                        {i18n.language === 'en' && menuOpen && !respMood || i18n.language === 'en' && respMood && !respMenuOpen ?
                                            RightArrow
                                            : null}
                                        {i18n.language === 'ar' && menuOpen && !respMood || i18n.language === 'ar' && respMood && !respMenuOpen ?
                                            LeftArrow
                                            : null}
                                    </div>
                                </>
                                :
                                <React.Fragment>
                                    {item.name}
                                </React.Fragment>
                            }
                        </ListItemText>
                    </MenuItemComponent>
                    <SubMenu
                        children={item.children}
                        menuOpen={menuOpen}
                    />
                </React.Fragment>
            ))}
        </>
    )

    return (
        <>
            {MenuItemRoot}
        </>
    )

}

export default MenuItem