import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ButtonPrimary } from './../UI/Buttons'
import { Form } from 'react-final-form'
import { Select } from 'mui-rff'
// import { OnChange } from 'react-final-form-listeners'

import apiConfig from '../../apis/apiConfig';
import { translationHook } from '../translationHook'
import { triggerNotification, setLoading } from '../../actions'
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	buttonRoot: {
		minWidth: "90px !important",
		padding: "0.5rem 1rem !important"
	}
})

class ServiceInfoForm extends Component {

    state = {
        actionsEvents: [],
        reactions: [],
        // serviceNotifications: [],
        // stepScreens: [],
        showForm: false
    }


    getActionsEvents = async ()=> {
        let actionsArr = []
        let url = this.props.serviceStepId ? '/API/SYSTEM/DDL/GET_SERVICES_STEPS_ACTIONS_EVENTS' : '/API/SYSTEM/DDL/GET_SERVICES_ACTIONS_EVENTS'
        // let data = { Service_Id: this.props.serviceid }
        // if (this.props.serviceStepId) {
        //     data = {...data, Service_Step_Id: this.props.serviceStepId}
        // }
        const response = await apiConfig.post(url)

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.RAction_Event_Name_AR : item.RAction_Event_Name_EN

                actionsArr.push({value: item.RAction_Event_Id, label})
            })

            this.setState({actionsEvents: actionsArr})
            
        }

    }

    getReactions = async (event_id)=> {
        let reactionsArr = []
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_RACTIONS',
            { RAction_Event_Id: event_id }
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.RAction_Name_AR : item.RAction_Name_EN

                reactionsArr.push({value: item.RAction_Id, label})
            })

            this.setState({reactions: reactionsArr})
        }

    }

    componentDidMount() {
        this.getActionsEvents()
    }

    onActionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = this.props.serviceStepId ? '/API/ADMINISTRATION/SERVICES/ADD_SERVICE_STEP_ACTION' : '/API/ADMINISTRATION/SERVICES/ADD_SERVICE_ACTION',
        bodyData = {
            Service_Id: this.props.serviceid,
            RAction_Event_Id: values.reactionEventId,
            RAction_Id: values.reactionId,
        }

        if (this.props.serviceStepId) {
            bodyData = {...bodyData, Service_Step_Id: this.props.serviceStepId}
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        ).catch((error) => {
            return error.response
        })

        this.props.setLoading(false)

        this.props.triggerNotification(true, response.data);

        if (response.data.Status) {
            // if (this.props.editMood) {
            //     this.props.triggerDialogForm(false)
            // }
            this.props.reloadDatagrid()
            form.restart()

            this.setState({showForm: !this.state.showForm})
        }
        
    }

    validate = values => {
        const errors = {}

        if (!values.reactionEventId) {
            errors.reactionEventId = `${this.props.t('error.field_required')}`
        }

        if (!values.reactionId) {
            errors.reactionId = `${this.props.t('error.field_required')}`
        }
        
        return errors;
    }

    render() {
        // let { initValues } = this.props

        return (
            <>
            {!this.props.editMood && !this.props.viewMood &&
            <div className="d-flex justify-content-end">
                <ButtonPrimary
                    variant={!this.state.showForm ? 'contained' : 'outlined'}
                    onClick={() => this.setState({showForm: !this.state.showForm}) }
                    hidden={this.state.showForm}
                >
                    {!this.state.showForm && this.props.t('servicesNewExecutionOrder')}
                    {/* {this.state.showForm && this.props.t('cancel')} */}
                </ButtonPrimary>
            </div>
            }
            <Form
            onSubmit={this.onActionSubmit}
            initialValues={{
                reactionEventId: '',
                reactionId: '',
            }}
            validate={this.validate}
            render={({ handleSubmit, form, submitting, values }) => {
                
                // if (this.props.editMood && values.stepType && values.stepType !== '' && values.stepScreens !== '' && this.state.stepScreens.length === 0) {
                //     this.getStepScreens(values.stepType)
                // }

                // if ( (values.notification === 0 || values.notification === false) && values.serviceNotification && !this.props.editMood ) {
                //     delete values.serviceNotification
                // }

            return (
                <form
                id="serviceStepForm"
                className={`row align-items-center ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                // onSubmit={handleSubmit}
                noValidate>
                    <Col xs={12} md={4}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t("servicesEventExecution")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										}`}
										required={true}
										name="reactionEventId"
										data={this.state.actionsEvents}
                                        value={values.reactionEventId}
                                        onChange={(e) => {
                                            form.change('reactionEventId', e.target.value)
                                            this.getReactions(e.target.value)
                                          }}
										showError={() => {
											let fieldStatus = form.getFieldState("reactionEventId");

											if (fieldStatus) {
												return fieldStatus.submitFailed && fieldStatus.invalid
											}
										}}
									></Select>
								</Col>
								<Col xs={12} md={4}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t("servicesExecutionOrder")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										}`}
										required={true}
										name="reactionId"
                                        value={values.reactionId}
										data={this.state.reactions}
                                        onChange={(e) => {
                                            form.change('reactionId', e.target.value)
                                            this.setState({ selectedReaction: e.target.value })
                                        }}
										showError={() => {
											let fieldStatus = form.getFieldState("reactionId");

                                            if (fieldStatus) {
                                                return fieldStatus.submitFailed && fieldStatus.invalid
                                            }
										}}
									></Select>
								</Col>
								<Col xs={12} md={4}>
									<Row>
                                        {!this.props.viewMood && (
                                            <ButtonPrimary
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                classes={{
                                                    root: this.props.classes.buttonRoot
                                                }}
                                                disabled={
                                                    submitting ||
                                                    this.props.viewMood
                                                }
                                                onClick={handleSubmit}
                                            >
                                                {this.props.t("save")}
                                            </ButtonPrimary>
                                        )}
                                        <ButtonPrimary
                                                color="primary"
                                                variant="outlined"
                                                size="small"
                                                classes={{
                                                    root: this.props.classes.buttonRoot
                                                }}
                                                onClick={() => this.setState({showForm: !this.state.showForm}) }
                                            >
                                                {this.props.t("cancel")}
                                            </ButtonPrimary>
                                    </Row>
								</Col>
                    
                </form>
                )
            }
        }
        />
            </>
        ) 
    }
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading })(translationHook(withStyles(styles)(ServiceInfoForm)))