import apiConfig from '../apiConfig'

export const deleteStamp = async (at, stampId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/STAMPS/Del_Document_Stamp_Type',
        {
            Document_Stamp_Type_Id: stampId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
