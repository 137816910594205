import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "./Approve.module.scss";

import Notes from "../Notes";
import { approveRequest } from "../../../../apis/requests/processTask";

import Document from "../charts/Document";
import Template from "../template/Template";
import RefundInvoices from "../insurance/RefundInvoices";
import InvoiceInfo from "../insurance/InvoiceInfo";
import ViolationSettlementForm from "../violation/ViolationSettlementForm";

const Approve = (props) => {
	const [refundInvoicesData, setRefundInvoicesData] = useState({}),
		[templateFilesLoaded, setTemplateFilesStatus] = useState(false);

	useEffect(() => {
		let at = props.currentUser.Data.access_token;

		(async () => {
			const response = await apiConfig.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_REFUND_DATA",
				{
					Request_Id: props.requestId,
					Request_Task_Id: props.requestTaskId,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			);

			if (response.data.Status) {
				setRefundInvoicesData(response.data.Data);
			}
		})();
	}, []);

	return (
		<>
			<Template
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				templateFilesLoadedCB={setTemplateFilesStatus}
			/>

			{templateFilesLoaded && (
				<Document
					requestId={props.requestId}
					requestTaskId={props.requestTaskId}
					tempId={props.tempId}
					task={props.task}
				/>
			)}

			<ViolationSettlementForm
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
			/>

			{refundInvoicesData && refundInvoicesData.length > 0 && (
				<div>
					<h4 className={style.labelCentered}>
						{props.t("insuranceRefundData")}
					</h4>
					{/* <RefundInvoices readOnly={true} invoicesData={refundInvoicesData} /> */}

					<InvoiceInfo
						invoiceId={refundInvoicesData.Invoice_Id}
						requestId={props.requestId}
						requestTaskId={props.requestTaskId}
						readOnly={true}
						insuranceRefundData={refundInvoicesData[0]}
					/>
				</div>
			)}

			<Notes
				required={false}
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				onTaskSubmit={approveRequest}
			/>
		</>
	);
};

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops)(translationHook(Approve));
