import React from 'react'
import style from './Footer.module.scss'
import { useTranslation } from 'react-i18next'

const Footer = props => {

    const { t } = useTranslation('common')
    
    return (
        <footer>
            <div className='row'>
                <h4 
                    className={[
                        style.footer,
                        'col-12 py-3 footer'
                    ].join(' ')}
                >
                    {t('footer_part1')} &#169; 
                    <span className={style.brandName}> {t('footer_brandName')} </span> 
                    {t('footer_part2')}
                </h4>
            </div>
        </footer>
    )
}

export default Footer