import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
  reload
} from "../../../actions";
import {
	DataGrid,
  Column,
	Grouping,
	Selection
} from 'devextreme-react/data-grid';
import PaymentNewCodeForm from "./paymentNewCodeForm";


class PaymentForm extends Component {
  state = {
    showForm: false,
    invoiceTypesCategorised: [],
    ConfiscationItemCategorised: [],
    invoiceCodes: [],
    reload: false,
    createdInvoiceId: null
  };

  getInvoiceTypesCategories = async () => {
    let categorise = [];
    const response = await apiConfig.post(
      "/API/SYSTEM/DDL/GET_INVOICES_TYPES_CATEGORIES"
    );
    // Invoice_Type_Category_Id
    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;

        categorise.push({ value: item.Record_Id, label });
      });

      this.setState({ invoiceTypesCategorised: categorise });
    }
  };

  getConfiscationItemCategorised = async () => {
    let categorise = [];
    const response = await apiConfig.post(
      "/API/SYSTEM/DDL/GET_CONFISCATIONS_INVOICES_TYPES"
    );
    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;

        categorise.push({ value: item.Record_Id, label });
      });

      this.setState({ ConfiscationItemCategorised: categorise });
    }
  };

  getInvoiceCodes = async () => {
    let at = this.props.currentUser.Data.access_token
    const response = await apiConfig.post(
      "API/ADMINISTRATION/PAYMENTS/INVOICETYPE/GET_INVOICE_TYPE_CODES_LIST",
      {
        Invoice_Type_Id: this.props.InviceTypeId || this.state.createdInvoiceId
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );
    if (response.data.Status) {
      this.setState({ invoiceCodes: response.data.Data });
    }
  };

  onSectionSubmit = async (values, form) => {
    let at = this.props.currentUser.Data.access_token,
      url = !this.props.editMood
        ? "/API/ADMINISTRATION/PAYMENTS/INVOICETYPE/ADD_INVOICE_TYPE"
        : "/API/ADMINISTRATION/PAYMENTS/INVOICETYPE/UPD_INVOICE_TYPE",
      bodyData = {
        Invoice_Type_Name_AR: values.InvoiceTypeNameAR,
        Invoice_Type_Name_EN: values.InvoiceTypeNameEN,
        Invoice_Type_Code: values.InviceTypeCode,
        Invoice_Type_Value: values.InvoiceTypeValue,
        Active_Status_Id: values.InvoiceStatus ? 1 : 0,
        Invoice_Type_Category_Id: values.paymentCategoryType,
        Invoice_Type_Online_Payment_Code: values.InvoiceTypeOnlinePaymentCode,
        Confiscation_Invoice_Type_Id:
          values.paymentCategoryType === 2 ? values.ConfiscationItem : null,
      };

    if (this.props.editMood) {
      bodyData.Invoice_Type_Id = this.props.InviceTypeId;
    }

    this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

    const response = await apiConfig
      .post(url, bodyData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .catch((error) => {
        return error.response;
      });

    if (this.props.editMood) {
      this.props.triggerDialogForm(false);
    }
    if (response.data.Status) {
      form.restart();
      this.setState({ createdInvoiceId: response.data.Data.Invoice_Type_Id })
    }

    //response.data
    console.log("response", response);
    this.props.triggerNotification(true, response.data);

    this.props.setLoading(false);
  };

  componentDidMount() {
    this.getInvoiceTypesCategories();
    this.getInvoiceCodes();
    this.getConfiscationItemCategorised();
  }

  render() {
    let { initValues } = this.props;
    let columnAlign = 'center'

    // if (this.props.i18n.language === 'en') {
		// 	columnAlign = 'left'
		// }

    const validate = (values) => {
      const errors = {};

      if (!values.InvoiceTypeNameAR) {
        errors.InvoiceTypeNameAR = `${this.props.t("error.field_required")}`;
      }

      if (!values.InvoiceTypeNameEN) {
        errors.InvoiceTypeNameEN = `${this.props.t("error.field_required")}`;
      }
      if (!values.paymentCategoryType) {
        errors.paymentCategoryType = `${this.props.t("error.field_required")}`;
      }

      console.log(errors);
      return errors;
    };

    return (
      <>
        {!this.props.editMood && !this.props.viewMood && (
          <div className="d-flex justify-content-between align-items-center pb-5">
            <h3>{this.props.t("managePaymentTypes")}</h3>
            <ButtonPrimary
              variant={!this.state.showForm ? "contained" : "outlined"}
              onClick={() => this.setState({ showForm: !this.state.showForm })}
            >
              {!this.state.showForm && this.props.t("addPaymentType")}
              {this.state.showForm && this.props.t("cancel")}
            </ButtonPrimary>
          </div>
        )}

        <Form
          onSubmit={this.onSectionSubmit}
          initialValues={
            initValues
              ? initValues
              : {
                  InvoiceStatus: true,
                  InviceTypeId: "",
                  InvoiceTypeNameAR: "",
                  InvoiceTypeNameEN: "",
                  InviceTypeCode: "",
                  InvoiceTypeValue: 0,
                  paymentCategoryType: "",
                  InvoiceTypeOnlinePaymentCode: "",
                }
          }
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              id="sliderform"
              className={`row ${
                !this.state.showForm &&
                !this.props.editMood &&
                !this.props.viewMood
                  ? "d-none"
                  : ""
              }`}
              onSubmit={handleSubmit}
              noValidate
            >
              <Col xs={12} md={6}>
                <TextField
                  disabled
                  label={this.props.t("PaymentNumber")}
                  className="mb-2"
                  name="InviceTypeId"
                />
              </Col>

              <Col
                xs={12}
                md={6}
                className={`${this.props.viewMood ? "read-only" : ""}`}
              >
                <Switches
                  disabled={this.props.viewMood}
                  color="primary"
                  name="InvoiceStatus"
                  data={{
                    label: `${this.props.t("paymentStatus")}`,
                    value: `${this.props.t("paymentStatus")}`,
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  disabled={this.props.viewMood}
                  label={this.props.t("paymentNameAR")}
                  className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                  name="InvoiceTypeNameAR"
                  required={true}
                  autoComplete="off"
                  showError={({ meta: { submitFailed, invalid } }) => {
                    return submitFailed && invalid;
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  disabled={this.props.viewMood}
                  label={this.props.t("paymentNameEN")}
                  className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                  name="InvoiceTypeNameEN"
                  required={true}
                  autoComplete="off"
                  showError={({ meta: { submitFailed, invalid } }) => {
                    return submitFailed && invalid;
                  }}
                />
              </Col>
              <Col xs={12} md={6} hidden={true}>
                <TextField
                  disabled={this.props.viewMood}
                  label={this.props.t("PaymentCode")}
                  className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                  name="InviceTypeCode"
                  required={true}
                  autoComplete="off"
                  showError={({ meta: { submitFailed, invalid } }) => {
                    return submitFailed && invalid;
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <Select
                  disabled={this.props.viewMood}
                  label={this.props.t("paymentCategoryType")}
                  className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                  required={true}
                  name="paymentCategoryType"
                  data={this.state.invoiceTypesCategorised}
                  showError={() => {
                    let fieldStatus = form.getFieldState("paymentCategoryType");
                    if (fieldStatus) {
                      return fieldStatus.submitFailed && fieldStatus.invalid;
                    }
                  }}
                ></Select>
              </Col>

              <Col xs={12} md={6} hidden={true}>
                <TextField
                  disabled={this.props.viewMood}
                  label={this.props.t("InvoiceTypeOnlinePaymentCode")}
                  className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                  name="InvoiceTypeOnlinePaymentCode"
                  required={false}
                  autoComplete="off"
                  showError={({ meta: { submitFailed, invalid } }) => {
                    return submitFailed && invalid;
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextField
                  disabled={this.props.viewMood}
                  label={this.props.t("paymentVirtualValue")}
                  className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                  name="InvoiceTypeValue"
                  autoComplete="off"
                  showError={({ meta: { submitFailed, invalid } }) => {
                    return submitFailed && invalid;
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                {values.paymentCategoryType === 2 && (
                  <Select
                    disabled={this.props.viewMood}
                    label={this.props.t("ConfiscationItem")}
                    className={`${this.props.viewMood ? "read-only" : ""} mb-2`}
                    required={false}
                    name="ConfiscationItem"
                    data={this.state.ConfiscationItemCategorised}
                    showError={() => {
                      let fieldStatus = form.getFieldState("ConfiscationItem");

                      if (fieldStatus) {
                        return fieldStatus.submitFailed && fieldStatus.invalid;
                      }
                    }}
                  ></Select>
                )}
              </Col>
              

              <Col className="my-4 justify-content-start d-flex" xs={12}>
                {!this.props.viewMood && (
                  <ButtonPrimary
                    color="primary"
                    variant="contained"
                    disabled={submitting || this.props.viewMood}
                    type="submit"
                  >
                    {this.props.t("save")}
                  </ButtonPrimary>
                )}
              </Col>

              {
                this.state.createdInvoiceId || this.props.InviceTypeId ? (
                  <Col md={12}>

                  {!this.props.viewMood &&
                  <Col xs={12} className='mb-3'>
                    <PaymentNewCodeForm invoiceTypeId={this.props.InviceTypeId || this.state.createdInvoiceId} reloadDatagrid={() => this.getInvoiceCodes()} />
                  </Col>
                  }

                  <DataGrid
                  className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
                  id="dataGrid"
                  width={'100%'}
                  keyExpr="Invoice_Type_Id"
                  dataSource={this.state.invoiceCodes}
                  showBorders={false}
                  showColumnLines= {false}
                  showRowLines= {true}
                  allowColumnResizing={true}
                  columnResizingMode={'widget'}
                  allowColumnReordering={true}
                  rowAlternationEnabled={false}
                  focusedRowEnabled={true}
                  // columnWidth={'auto'}
                  wordWrapEnabled={true}
                  rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
                  >

                  {/* <ColumnChooser enabled={true} allowSearch={true} search={'fff'}
                  title={this.props.t('dxColumnChooserTitle')}
                  emptyPanelText={this.props.t('dxColumnChooserPanelText')}
                  /> */}

                  {/* <SearchPanel 
                  visible={true}
                  width={240}
                  placeholder={this.props.t('search')}
                  />

                  <FilterRow visible={true} />

                  <HeaderFilter visible={true} />

                  <Export enabled={true} allowExportSelectedData={true}/> */}

                  <Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                      {/* <GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/> */}

                  <Selection
                  mode="multiple"
                  selectAllMode={'allPages'}
                  showCheckBoxesMode={'always'}
                  />

                    {/* <Column cssClass="outlinedColumn" fixed={true} fixedPosition={this.props.i18n.language === 'ar' ? 'right' : 'left'} alignment="center" width={'70px'} cellRender={this.renderOrderButtons}>
                    </Column> */}

                    <Column dataField="FDepartment_Code" caption={this.props.t('paymentDestinationCode')} alignment={columnAlign}>
                    </Column>
                    
                    {/* <Column caption={this.props.t('paymentDestinationName')} alignment={columnAlign}>
                    </Column> */}

                    <Column dataField="FSector_Code" caption={this.props.t('departmentCode')} alignment={columnAlign}>
                    </Column>

                    {/* <Column caption={this.props.t('depName')} alignment={columnAlign}>
                    </Column> */}

                    <Column dataField="FSection_Code" caption={this.props.t('sectionCode')} alignment={columnAlign}>
                    </Column>

                    {/* <Column
                      // dataField={"Mission_Date"}
                      caption={this.props.t('paymentRelatedToDestination')}
                      alignment={columnAlign}
                      // cellRender={({data}) => moment(data.Mission_Date).format('HH:mm YYYY-MM-DD')}
                    >
                    </Column>
                    <Column
                      // dataField={"Mission_Date"}
                      caption={this.props.t('paymentRelatedToDepartment')}
                      alignment={columnAlign}
                      // cellRender={({data}) => moment(data.Mission_Date).format('HH:mm YYYY-MM-DD')}
                    >
                    </Column>
                    <Column
                      // dataField={"Mission_Date"}
                      caption={this.props.t('paymentRelatedToSection')}
                      alignment={columnAlign}
                      // cellRender={({data}) => moment(data.Mission_Date).format('HH:mm YYYY-MM-DD')}
                    >
                    </Column> */}
                    <Column dataField="FService_Code" caption={this.props.t('paymentItemCode')} alignment={columnAlign}>
                    </Column>

                  </DataGrid>
                  </Col>
                ) : null
              }
              
            </form>
          )}
        />
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToprops, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
  reload
})(translationHook(PaymentForm));
