import apiConfig from '../../apiConfig';
export const getDepartmentVersionUnselectedDetailList = async (at, versionId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_VERSION_UNSELECTED_DETAILS_LIST',
        {
            Department_Service_Version_Id :versionId
        }, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Department_Service_Detail_Required === 1) {
				response.data.Data[i].Department_Service_Detail_Required = true;
			} else {
				response.data.Data[i].Department_Service_Detail_Required = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
