import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Link } from "@reach/router";
import apiConfig from "../../../apis/apiConfig";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { ButtonSecondary } from "./../../UI/Buttons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import style from "./Service.module.scss";
import ModuleMainInfo from "./ModuleMainInfo";
import ModuleSecondInfo from "./ModuleSecondInfo";
import ModuleGallery from "./ModuleGallery";
import ServiceStep from "./ServiceStep";
import { translationHook } from "../../translationHook";

class HouseModuleForm extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    key: 0,
    next: 1,
    prev: -1,
    moduleID: null,
    first: false,
    second: true,
    third: true,
    fourth: true,
    edit: false,
    view: false,
    initValues: {},
  };

  handleNext = () => {
    this.setState({
      key: this.state.next,
      next: this.state.next + 1,
      prev: this.state.prev + 1,
    });
  };

  handlePrev = () => {
    this.setState({
      key: this.state.prev,
      next: this.state.next - 1,
      prev: this.state.prev - 1,
    });
  };

  handleFirstStepDone = (mID, values) => {
    this.setState({
      moduleID: mID,
      key: 1,
      second: false,
      third: false,
      fourth: false,
      editMood: true,
      initValues: values,
    });
  };

  renderChevs = () => {
    let rtl = this.props.i18n.language === "ar" ? true : false,
      iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
      iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

    return (
      <>
        <IconButton
          aria-label="prev"
          color="primary"
          onClick={this.handlePrev}
          disabled={this.state.key > 0 ? false : true}
        >
          {iconPrev}
        </IconButton>
        <IconButton
          aria-label="next"
          color="primary"
          onClick={this.handleNext}
          disabled={this.state.key < 3 && !this.state.second ? false : true}
        >
          {iconNext}
        </IconButton>
      </>
    );
  };

  componentDidMount() {
    (async () => {
      if (this.props.editMood || this.props.viewMood) {
        let at = this.props.currentUser.Data.access_token,
          url =
            "API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/GET_APPROVING_CHART_TEMPLATE_LIST";
        const response = await apiConfig
          .post(
            url,
            { Approving_Chart_Id: this.props.moduleID },
            {
              headers: {
                Authorization: `Bearer ${at}`,
              },
            }
          )
          .catch((error) => {
            return error.response;
          });

        this.setState({
          moduleID: response.data.Data[0].Approving_Chart_Id,
          second: false,
          third: false,
          fourth: false,
          secondTabValues: response.data.Data,
        });
      }
    })();
    this.setState({
      initValues: this.props.initValues,
      editMood: this.props.editMood,
    });
  }

  render() {
    let mystyle;
    if (this.props.i18n.language === "ar") {
      mystyle = "ServiceTabsAr";
    } else {
      mystyle = "ServiceTabsEn";
    }

    return (
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={0}
        activeKey={this.state.key}
        onSelect={(k) =>
          this.setState({
            key: parseInt(k),
            next: parseInt(k) + 1,
            prev: parseInt(k) - 1,
          })
        }
        unmountOnExit="true"
      >
        {this.props.currentPage === "/housing-departments/add-module" && (
          <ButtonSecondary
            variant="outlined"
            component={Link}
            to="/housing-departments"
            size="large"
            className="ml-auto d-flex row col-2"
          >
            {this.props.t("exitModules")}
            <ExitToAppIcon />
          </ButtonSecondary>
        )}
        <div className="row mt-5 mb-3">
          <div className="col-2">{this.renderChevs()}</div>
          <Nav
            variant="pills"
            className={[style.stepsNav, "col-8 justify-content-center"].join(
              " "
            )}
          >
            <Nav.Item className={mystyle}>
              <Nav.Link
                className={`${style.navLink} ${
                  this.state.key === 0 ? style.active : ""
                }`}
                eventKey="0"
                disabled={this.state.first}
              >
                {this.props.t("ModuleMainData")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mystyle}>
              <Nav.Link
                className={`${style.navLink} ${
                  this.state.key === 1 ? style.active : ""
                }`}
                eventKey="1"
                disabled={this.state.second}
              >
                {this.props.t("ModuleData")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={mystyle}>
              <Nav.Link
                className={`${style.navLink} ${
                  this.state.key === 2 ? style.active : ""
                }`}
                eventKey="2"
                disabled={this.state.third}
              >
                {this.props.t("gallery")}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey={0} disabled={this.state.first}>
              <ModuleMainInfo
                moduleID={this.state.moduleID}
                active={!this.state.first}
                initValues={this.state.initValues}
                onStepDone={this.handleFirstStepDone}
                editMood={this.state.editMood}
                viewMood={this.props.viewMood}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey={1}
              disabled={this.state.second}
              unmountOnExit="true"
            >
              <ModuleSecondInfo
                moduleID={this.state.moduleID}
                active={!this.state.second}
                editMood={this.props.editMood}
                initValues={this.state.secondTabValues}
                viewMood={this.props.viewMood}
              />
            </Tab.Pane>
            <Tab.Pane
              eventKey={2}
              disabled={this.state.third}
              unmountOnExit="true"
            >
              <ModuleGallery
                moduleID={this.state.moduleID}
                active={!this.state.third}
                editMood={this.props.editMood}
                viewMood={this.props.viewMood}
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
        {/* </Row> */}
      </Tab.Container>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser, currentPage: state.currentPage };
};

export default connect(mapStateToProps)(translationHook(HouseModuleForm));
