import apiConfig from "../../apiConfig";

export const getDepartmentServiceTemplatesList = async (
	at,
	Department_Service_Version_Id
) => {
	const response = await apiConfig
		.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SERVICE_TEMPLATE_FILES_INFO",
			{
				Department_Service_Version_Id,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)
		.catch((error) => {
			return error.response;
		});

	return response.data.Data;
};
