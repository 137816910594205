import apiConfig from '../../apiConfig';

export const getNotificationList = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/NOTIFICATIONS/GET_NOTIFICATIONS_LIST', 
		{
			PageSize: 10000,
			PageIndex: 1
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);


	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Enable_SMS === 1) {
				response.data.Data[i].Enable_SMS = true;
			} else {
				response.data.Data[i].Enable_SMS = false;
			}
			if (response.data.Data[i].Enable_Mail === 1) {
				response.data.Data[i].Enable_Mail = true;
			} else {
				response.data.Data[i].Enable_Mail = false;
			}
			if (response.data.Data[i].Enable_Web === 1) {
				response.data.Data[i].Enable_Web = true;
			} else {
				response.data.Data[i].Enable_Web = false;
			}
			if (response.data.Data[i].Active_Status_Id === 1) {
				response.data.Data[i].Active_Status_Id = true;
			} else {
				response.data.Data[i].Active_Status_Id = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
