import apiConfig from '../../apiConfig'

export const deleteServiceDropValue = async (...params) => {
    const response = await apiConfig.post(
        'API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSVALUESMANAGEMENT/Del_Services_Lookups_Values',
        {
            Service_Lookup_Value_Id: params[1]
        },
        {
            headers: {
                Authorization: `Bearer ${params[0]}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
