import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TaskForm from './TaskForm';

import { triggerNotification, setLoading, triggerDialogForm } from '../../actions'
import { getAllTasksList } from '../../apis/tasks/myTasks';
import { triggerDialog } from '../../actions';
import { translationHook } from '../translationHook';
import {
	ButtonInfo,
} from '../UI/Buttons';
import moment from 'moment';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
  } from '@material-ui/pickers';
import { Col, Row} from 'react-bootstrap'
import DateFnsUtils from '@date-io/date-fns';

class AllTasksList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		view: false
	}
	handleDateChange = (date, field) => {
		this.setState({
			reqData: {
				...this.state.reqData,
				[field]: moment(date).lang("en").format('YYYY-MM-DD')
			},
			[field]: moment(date).lang("en").format('YYYY-MM-DD')
		})
	};

	renderEditForm = (...params) => {
        return <TaskForm missionID={params[0]} initValues={params[1]} />
    }

    handleEdit = (data) => {
        let missionId = data.Mission_Id,
		initValues = data,
		missionTitle = data.Mission_Title,
        dialogTitle = `${this.props.t('view') + ' ' +missionTitle}`

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                missionId,
				initValues
            ]
        })
    }
	render() {
		let columnAlign = 'left',
            missionTypeName = 'Mission_Type_Name_EN',
            missionFrom = 'Mission_from_User_Name_EN',
            missionTo = 'Mission_to_User_Name_EN',
			DepartmentNameKey = 'Department_Name_EN',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right';
			missionTypeName = 'Mission_Type_Name_AR';
			missionFrom = 'Mission_from_User_Name_AR';
			missionTo = 'Mission_to_User_Name_AR';
			DepartmentNameKey = 'Department_Name_AR';
		}

		const jsonDataSource = new CustomStore({
			key: 'Mission_Id',
			load: () => {
				// fromdate
				return getAllTasksList(at , this.state.reqData);
			},
		});

		return (
			<>
                <div className="d-flex justify-content-between align-items-center pb-5">
                    <h3>{this.props.t('AllTasks')}</h3>
                </div>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Row>
						<Col xs={6}>
							<KeyboardDatePicker
								margin='normal'
								format='yyyy-MM-dd'
								label={this.props.t('from')}
								value={this.state.fromdate}
								onChange={(e) => this.handleDateChange(e, 'fromdate')}
								KeyboardButtonProps={{
									'aria-label': this.props.t('fromdate'),
								}}
							/>
						</Col>
						<Col xs={6}>
							<KeyboardDatePicker
								margin='normal'
								label={this.props.t('to')}
								format='yyyy-MM-dd'
								value={this.state.todate}
								onChange={(e) => this.handleDateChange(e, 'todate')}
								KeyboardButtonProps={{
									'aria-label': this.props.t('todate'),
								}}
							/>
						</Col>
					</Row>
				</MuiPickersUtilsProvider>
				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>
					
					<Column
						dataField={"Mission_No"}
						caption={this.props.t('missionNo')}
						alignment={columnAlign}>
					</Column>
                    <Column
						dataField={"Mission_Date"}
						caption={this.props.t('missionDate')}
						alignment={columnAlign}
                        cellRender={({data}) => moment(data.Mission_Date).format('HH:mm YYYY-MM-DD')}
                        >
					</Column>
					<Column
					dataField={DepartmentNameKey}
					caption={this.props.t('DepartmentName')}
					alignment={columnAlign}
					></Column>
					<Column
						dataField={missionFrom}
						caption={this.props.t('missionFrom')}
						alignment={columnAlign}>
					</Column>
                    <Column
						dataField={missionTo}
						caption={this.props.t('missionTo')}
						alignment={columnAlign}>
					</Column>
					<Column
						dataField={missionTypeName}
						caption={this.props.t('missionTypeName')}
						alignment={columnAlign}
                        
                        >
					</Column>
					<Column
						dataField={"Mission_Title"}
						caption={this.props.t('missionTitle')}
						alignment={'center'}>
					</Column>
					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonInfo variant='circleIcon' 
									onClick={() => {
										this.handleEdit(record.data)
									}}
									startIcon={<VisibilityIcon />}>
									</ButtonInfo>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	
	onExporting(e) {
		let sheetTitle = this.props.t('manageDepartments')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog ,triggerNotification, setLoading, triggerDialogForm })(
	translationHook(AllTasksList)
);
