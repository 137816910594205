import apiConfig from '../apiConfig';

export const setDepartmentSectionStatus = async (at, departmentSectionId, status) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/UPD_DEPARTMENT_SECTION_ACTIVE',
		{
            Department_Section_Id: departmentSectionId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);

    return response.data;

}
