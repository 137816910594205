import apiConfig from "../../apiConfig";

export const applyUserSettings = async (at, fromId, toId) => {
  const response = await apiConfig.post(
    "API/BASIC/SETTINGS/USERS_AREAS/COPY_USER_ARES_SETTINGS",
    {
      From_User_Id: fromId,
      To_User_Id: toId,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  return [];
};
