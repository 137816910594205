import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../apis/apiConfig';
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { ButtonPrimary } from './../UI/Buttons'
import { OnChange } from 'react-final-form-listeners'

import { translationHook } from '../translationHook'
import { triggerNotification, setLoading } from '../../actions'
import { getServiceDealingTypes } from '../../apis/services/getServiceDealingTypes'

class ServiceDealingType extends Component {

    state = {
        dealingTypes: []
    }

    handleDeleteDealingType = async (serviceDealingTypeId) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')
    
        const response = await apiConfig.post(
            '/API/ADMINISTRATION/SERVICES/DEL_SERVICE_DEALING_TYPE',
            {
                Service_Dealing_Type_Id: serviceDealingTypeId
            },
            {
            headers: {
                Authorization: `Bearer ${at}`
            }
        });

        this.props.setLoading(false)

        if ( response.data.Status ) {
            const updateTypes = await getServiceDealingTypes(at,this.props.serviceid)
            this.setState({dealingTypes: updateTypes})
        }
    }

    onDealingTypeSubmit = async (values) => {
        let at = this.props.currentUser.Data.access_token,  
        url,
        dataBody = {}

        if ( values.serviceDealingTypeId ) {
            url = '/API/ADMINISTRATION/SERVICES/UPD_SERVICE_DEALING_TYPE'
            dataBody.Service_Dealing_Type_Id = values.serviceDealingTypeId
            dataBody.Service_Fees_AR = values.feesAr
            dataBody.Service_Fees_EN = values.feesEn
        } else {
            url = '/API/ADMINISTRATION/SERVICES/ADD_SERVICE_DEALING_TYPE'
            dataBody.Service_Id = this.props.serviceid
            dataBody.Dealing_Type_Id = values.dealingTypeId
            dataBody.Service_Fees_AR = values.feesAr
            dataBody.Service_Fees_EN = values.feesEn
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            dataBody,
            {
            headers: {
                Authorization: `Bearer ${at}`
            }
        });

        this.props.setLoading(false)

        this.props.triggerNotification(true, response.data)

        if ( response.data.Status ) {
            const updateTypes = await getServiceDealingTypes(at,this.props.serviceid)
            this.setState({dealingTypes: updateTypes})
        }
    }

    

    validate = (values) => {
        const errors = {}

        if (!values.feesAr) {
            errors.feesAr = `${this.props.t('error.field_required')}`
        } 

        if (!values.feesEn) {
            errors.feesEn = `${this.props.t('error.field_required')}`
        }
        
        return errors;
    }

    renderDealingTypeForms = (dealingTypes) => {
        const forms = dealingTypes.map((type) => {
            return (
                <Row key={type.Dealing_Type_Id} className="content-separated">
                    
                    <Col xs={12}>
                        <Form
                        onSubmit={this.onDealingTypeSubmit}
                        initialValues={{
                            feesAr: type.Service_Fees_AR ? type.Service_Fees_AR : '',
                            feesEn: type.Service_Fees_EN ? type.Service_Fees_EN : '',
                            status: type.Selected,
                            dealingTypeId: type.Dealing_Type_Id,
                            serviceDealingTypeId: type.Service_Dealing_Type_Id
                        }}
                        validate={this.validate}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {

                        return (
                            <form
                            className="row"
                            onSubmit={handleSubmit}
                            noValidate>
                                <Col xs={6} md={3}>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <p className="section-title">{this.props.i18n.language === 'ar' ? type.Dealing_Type_Name_AR : type.Dealing_Type_Name_EN}</p>

                                        <input
                                            name="dealingTypeId"
                                            type="hidden"
                                        />

                                        <input
                                            name="serviceDealingTypeId"
                                            type="hidden"
                                        />
                                    </div>
                                </Col>
                                <Col xs={6} md={3}>
                                    <div className={`d-flex justify-content-start align-items-center ${this.props.viewMood ? 'read-only' : ''}`}>
                                        <Switches
                                            disabled={this.props.viewMood}
                                            color="primary"
                                            name="status"
                                            data={{ label: `${this.props.t('active')}`, value: `${this.props.t('active')}` }}
                                        />

                                        <OnChange name="status">
                                            {(value, previous) => {
                                                if (!value && values.serviceDealingTypeId) {
                                                    this.handleDeleteDealingType(values.serviceDealingTypeId)
                                                }

                                            }}
                                        </OnChange>

                                    </div>
                                </Col>

                                {values.status && 
                                <>
                                
                                <Col xs={12} md={8}>
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <TextField
                                                    disabled={this.props.viewMood}
                                                    label={this.props.t('feesAr')}
                                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                    required={true}
                                                    name="feesAr"
                                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                                        return submitFailed && invalid 
                                                    } }
                                                    />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('feesEn')}
                                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                required={true}
                                                name="feesEn"
                                                showError={({ meta: { submitFailed, invalid } }) => { 
                                                    return submitFailed && invalid 
                                                } }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                
                                    <Col xs={12} md={4}>
                                    <Row className='align-items-center'>
                                        <Col className='my-2'>
                                            { !this.props.viewMood && 
                                            <ButtonPrimary
                                                color="primary"
                                                variant="contained"
                                                disabled={pristine}
                                                type="submit"
                                            >
                                                {this.props.t('save')}
                                            </ButtonPrimary>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                    </>
                                }
                                
                            </form>
                            )
                        }
                    }
                    />
                    </Col>
                </Row>
            )
        }
        )
        return forms;
    }


    componentDidMount() {
        (async () => {
            const response = await getServiceDealingTypes(this.props.currentUser.Data.access_token,this.props.serviceid)
            this.setState({dealingTypes: response})
        })()
    }

    render() {

        const formsList = this.renderDealingTypeForms(this.state.dealingTypes)

        return(
            <>
            {formsList}
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading })(translationHook(ServiceDealingType))