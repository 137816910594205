import apiConfig from '../apiConfig';

export const changeDepartmentStatus = async (auth, departmentId ,status) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/SET_DEPARTMENT_STATUS',
		{
            Department_Id: departmentId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${auth}`,
            },
        },
	);

	// if (response.data.Status) {
	// 	for (let i in response.data.Data) {
	// 		if (response.data.Data[i].Active_Status_Id === 1) {
	// 			response.data.Data[i].Active_Status_Id = true;
	// 		} else {
	// 			response.data.Data[i].Active_Status_Id = false;
	// 		}
	// 	}
	// }
    return response.data;
};
