import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from 'devextreme-react/data-grid';

import { ExcelJS, Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';

import CustomStore from 'devextreme/data/custom_store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import InvoiceForm from '../invoiceEditsList/InvoiceEditsForm';

import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	triggerDialog,
} from '../../../actions';
// import { getInvoicesList , getInvoiceDetails } from '../../../apis/system/invoices/index';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import DeleteIcon from '@material-ui/icons/Delete';

import { ButtonInfo, ButtonDanger } from '../../UI/Buttons';
import { Link } from '@reach/router';

import moment from 'moment';

import {deleteChangeEdit} from '../../../apis/system/invoices';
class InvoicesEditsList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		view: false,
	};

	componentDidMount() {}

	// renderEditForm = (...params) => {
	// 	let viewMood = params[2] === 'view' ? true : false,
	// 	editMood = params[2] === 'edit' ? true : false;
	//     return <InvoiceForm invoiceId={params[0]} initValues={params[2]}
	// 	viewMood={viewMood} editMood={editMood} />
	// }

	// handleEdit = async (data , viewMood) => {
	//     let invoiceId = data.Invoice_Id,
	// 	invoiceTitle = this.props.i18n.language === 'ar' ? data.Invoice_Type_Name_AR : data.Invoice_Type_Name_EN,
	// 	auth = this.props.currentUser.Data.access_token,
	//     dialogTitle = `${this.props.t('view')} ${invoiceTitle}`;

	// 	const invoiceDetails = await getInvoiceDetails(auth, invoiceId)

	//     this.props.triggerDialogForm(true, {
	//         title: dialogTitle,
	//         renderForm: this.renderEditForm,
	//         params: [
	// 			invoiceId,
	//             invoiceTitle,
	// 			invoiceDetails,
	// 			viewMood
	//         ]
	//     })
	// }

	handleDelete = (data) => {
		console.log(data)
		let at = this.props.currentUser.Data.access_token,
			invoiceId = data.Invoice_Id,
			invoiceChangeId = data.Invoice_Change_Id,
			dialogTitle = `${this.props.t('confirmDeleteTitle')}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteChangeEdit,
				params: [
					at,
					{ Invoice_Id: invoiceId,
					Invoice_Change_Id: invoiceChangeId
					}
				],
				text: this.props.t('delete'),
			},
		});
	}

	render() {
		let columnAlign = 'left',
			invoiceChangeStatus = 'Invoice_Change_Status_Name_EN',
			assignedName = 'Ins_User_Name_EN',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right';
			invoiceChangeStatus = 'Invoice_Change_Status_Name_AR';

			assignedName = 'Ins_User_Name_AR';
		}

		const jsonDataSource = new CustomStore({
			key: 'Invoice_Change_Id',
			load: async () => {
				//
				return this.props.invoiceChanges;
			},
		});

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === 'ar'
							? 'dx-grid-ar'
							: 'dx-grid-en',
						'globalBox',
					].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === 'ar' ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t(
							'dxColumnChooserPanelText'
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t('dxGroupPanelText')}
					></GroupPanel>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
						dataField={'Invoice_Change_Id'}
						caption={this.props.t('invoiceEditId')}
						alignment={columnAlign}
					></Column>
					<Column
						dataField={'Invoice_Change_Date'}
						caption={this.props.t('invoiceEditDate')}
						alignment={columnAlign}
						cellRender={({ data }) => {
							return data.Invoice_Date
								? data.Invoice_Date.slice(0, 10)
								: null;
						}}
					></Column>
					<Column
						dataField={invoiceChangeStatus}
						caption={this.props.t('invoiceEditStatus')}
						alignment={'center'}
					></Column>
					<Column
						dataField={'Invoice_Value_Before_Change'}
						caption={this.props.t('invoicevalueBeforeEdit')}
						alignment={'center'}
					></Column>
					<Column
						dataField={'Invoice_Discount_Value'}
						caption={this.props.t('invoiceEditDiscount')}
						alignment={'center'}
					></Column>
					<Column
						dataField={'Invoice_Value_After_Change'}
						caption={this.props.t('editFinalValue')}
						alignment={'center'}
					></Column>
					<Column
						dataField={assignedName}
						caption={this.props.t('assignedEditName')}
						alignment={'center'}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === 'ar' ? 'left' : 'right'
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										component={Link}
										to={`invoices-list/edit/${record.data.Invoice_Change_Id}`}
										onClick={() => {
											this.props.triggerDialogForm(false);
										}}
										state={{
											invoiceChangeData: record.data
										}}
										startIcon={<VisibilityIcon />}
									></ButtonInfo>
								);
							}}
						/>
						{}
						<Button
							render={(record) => {
								if (
									record.data.Invoice_Change_Status_Id === 0
								) {
									return (
										<ButtonDanger
											variant="circleIcon"
											startIcon={<DeleteIcon />}
											onClick={() =>
												this.handleDelete(record.data)
											}
										>
											{/* {this.props.t('delete')} */}
										</ButtonDanger>
									);
								}
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	onExporting(e) {
		let sheetTitle = this.props.t('manageSystemModules');
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: 'Cairo',
					size: 10,
					color: { argb: '151713' },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: 'middle',
				};
				if (gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: 'd0b166' },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: 'Cairo',
						size: 10,
						color: { argb: 'ffffff' },
						bold: true,
					};
				}
				if (gridCell.rowType === 'header') {
					excelCell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: '58743a' },
					};
					excelCell.alignment = {
						vertical: 'middle',
						horizontal: 'center',
					};
					excelCell.font = {
						family: 2,
						name: 'Cairo',
						size: 12,
						color: { argb: 'ffffff' },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: 'application/octet-stream' }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(InvoicesEditsList));
