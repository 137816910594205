import apiConfig from '../apiConfig'

export const getUsersList = async (at) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/USERS/GET_USERS_LIST', {},
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
               response.data.Data[i].Active_Status_Id = true
            } else {
                response.data.Data[i].Active_Status_Id = false
            }
          }
        return response.data.Data
    }

    return []
}
export const getProfilesList = async (userAccountId, at) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/USERS/GET_USER_ACCOUNT_PROFILES_LIST', {
            User_Account_Id: userAccountId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    // for (let i in response.data.Data) {
    //     if (response.data.Data[i].Active_Status_Id === 1) {
    //        response.data.Data[i].Active_Status_Id = true
    //     } else {
    //         response.data.Data[i].Active_Status_Id = false
    //     }
    //   }
    if (response.data.Status) {
        return response.data.Data
    }

    return []
}
