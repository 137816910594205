import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import style from "./RequestList.module.scss";
import DataGrid, {
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
  Pager,
  StateStoring,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import {
  triggerDialog,
  triggerDialogForm,
  getDialogData,
  setLoading,
  triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import { ButtonInfo } from "../../UI/Buttons";
import { getRequestsList } from "../../../apis/requests/getRequestsList";
import moment from "moment";
import apiConfig from "./../../../apis/apiConfig";
import Processing from "../processing/Processing";

class RequestsList extends Component {
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.searchState = React.createRef();
  }

  state = {
    gridState: [],
    gridData: [],
  };

  renderForm = (...params) => {
    let viewMood = params[2] === "view" ? true : false,
      editMood = params[2] === "edit" ? true : false;
    // return <OrderView id={params[0]} initValues={params[1]} editMood={editMood} viewMood={viewMood} />
  };

  customizeCellCheckbox = ({ data }) => {
    return data.Request_Date.slice(0, 10);
  };

  handleEdit = (data, viewMood) => {
    let initValues = {
        id: data.Request_Id,
        number: data.Request_No,
        date: moment(data.Request_Date).lang("en").format("YYYY-MM-DD"),
        departmentId: data.Department_Id,
        departmentNameAR: data.Department_Name_AR,
        departmentNameEN: data.Department_Name_EN,
        serviceNo: data.Service_No,
        serviceNameAR: data.Service_Name_AR,
        serviceNameEN: data.Service_Name_EN,
        requestStatusAR: data.Request_Status_Name_AR,
        requestStatusEN: data.Request_Status_Name_EN,
        clientMail: data.Client_Mail,
        clientNameAR: data.Client_Name_AR,
        clientNameEN: data.Client_Name_EN,
        clientProfileId: data.Client_Profile_Id,
        clientPhone: data.Client_Phone,
        dealingTypeEN: data.Dealing_Type_Name_EN,
        dealingTypeAR: data.Dealing_Type_Name_AR,
        stepNameAR: data.Department_Service_Step_Name_AR,
        stepNameEN: data.Department_Service_Step_Name_EN,
        StepUserNameAR: data.Step_User_Name_AR,
        StepUserNameEN: data.Step_User_Name_EN,
      },
      requestId = data.Request_Id,
      requestName =
        this.props.i18n.language === "ar"
          ? data.Service_Name_AR
          : data.Service_Name_AR,
      dialogTitle = `${
        viewMood === "view" ? this.props.t("view") : this.props.t("edit")
      } ${requestName}`;

    this.props.triggerDialogForm(true, {
      title: dialogTitle,
      renderForm: this.renderForm,
      params: [requestId, initValues, viewMood],
    });
  };

  saveState = async () => {
    // state.searchText = ''
    // state.columns.map(s => s.filterValue = undefined)
    const at = this.props.currentUser.Data.access_token,
      url = "API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS",
      bodyData = {
        System_Grid_Name: "Grid_Requests_List",
        System_Grid_Json: this.searchState.current,
        Lang: this.props.i18n.language,
      };

    await apiConfig.post(url, bodyData, {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    });

    this.props.triggerNotification(true, {
      Status: true,
    });
  };

  loadState = async () => {
    const at = this.props.currentUser.Data.access_token,
      url = "API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS",
      bodyData = {
        System_Grid_Name: "Grid_Requests_List",
        Lang: this.props.i18n.language,
      };

    const response = await apiConfig.post(url, bodyData, {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    });
    if (
      JSON.stringify(response?.data?.Data) !== "{}" &&
      JSON.stringify(this.state.gridState) !== response?.data?.Data
    ) {
      this.setState({ gridState: JSON.parse(response?.data?.Data) });
      this.dataGrid?.instance?.refresh();
    }

    return JSON.parse(response.data.Data);
  };

  getRequest = async () => {
    const { selectedDateStart, selectedDateEnd } = this.props.date;
    let at = this.props.currentUser.Data.access_token;
    const data = {
      From_Request_Date: selectedDateStart,
      To_Request_Date: selectedDateEnd,
      PageCount: 0,
      pageIndex: 1,
    };
    const res = await getRequestsList(at, data);

    if (res.Data.length > 0) {
      this.props.setSearchResult(res.Data.length);
      this.setState({ gridData: res.Data });
    }
    return res.Data;
  };

  async componentDidMount() {
    this.dataGrid?.instance?.beginCustomLoading();
    await this.loadState();
    await this.getRequest();
    this.dataGrid?.instance?.endCustomLoading();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.date.selectedDateStart !== this.props.date.selectedDateStart ||
      prevProps.date.selectedDateEnd !== this.props.date.selectedDateEnd
    ) {
      this.getRequest();
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: this.props.t("saveGridSettings"),
          height: "36px",
          icon: "save",
          onClick: () => this.saveState(),
          hint: this.props.t("saveGridSettings"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: this.props.t("clearSearch"),
          height: "36px",
          icon: "clearformat",
          onClick: () => this.dataGrid?.instance?.clearFilter(),
          hint: this.props.t("clearSearch"),
        },
      }
    );
  }

  renderViewForm = (params) => {
    return (
      <div
        className={style.dashboardListContainer}
        style={{
          padding: params.viewMood ? "7rem 2rem 0 2rem" : "7rem 2rem 0 2rem",
        }}
      >
        <Processing dataListObject={params} />
      </div>
    );
  };

  handleEditView = (data) => {
    let missionTitle = data.Request_Task_Title_Name_AR;
    let dialogTitle = `${this.props.t("view") + " " + missionTitle}`;

    this.props.getDialogData({
      title: dialogTitle,
      closeBtnTitle: this.props.t("closeAndReturnToMenu"),
      params: [
        {
          requestStatusId: data.Request_Status_Id,
          viewMood: true,
          isRequestsList: true,
          requestId: data.Request_Id,
          requestTaskId: data.Request_Task_Id,
        },
      ],
    });

    this.props.triggerDialogForm(true, {
      title: dialogTitle,
      closeBtnTitle: this.props.t("closeAndReturnToMenu"),
      renderForm: this.renderViewForm,
      params: [
        {
          requestStatusId: data.Request_Status_Id,
          viewMood: true,
          isRequestsList: true,
          requestId: data.Request_Id,
          requestTaskId: data.Request_Task_Id,
        },
      ],
    });
  };

  handleOnContentReady = (e) => {
    if(this.props.i18n.language === "ar") {
      const scrollable = e.component.getScrollable();
      const maxScrollLeft = scrollable.scrollWidth();

      scrollable.scrollTo({
        x: maxScrollLeft
      })
    }
  };

  render() {
    return (
      <>
        <DataGrid
          className={[
            this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
            "globalBox",
          ].join(" ")}
          id="dataGrid"
          width={"100%"}
          keyExpr="Request_Id"
          dataSource={this.state.gridData}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          allowColumnResizing={true}
          
          columnResizingMode={"widget"}
          allowColumnReordering={true}
          rowAlternationEnabled={false}
          focusedRowEnabled={true}
          onExporting={this.onExporting}
          onContentReady={this.handleOnContentReady}
          onRowPrepared={(e) => {
            if(e.rowType === "data") {
              if (e.data.Request_Status_Id === 0) {
                e.rowElement.style.backgroundColor = "#F48484"
              }

              if (e.data.Request_Status_Id === 3) {
                e.rowElement.style.backgroundColor = "#A4BE7B"
              }

                if (e.data.Request_Status_Id === 2 && e.data.Task_Service_Step_Type_Id === 1) {
                    e.rowElement.style.backgroundColor = "#f1d488"
                }


            }
          }}
          columnWidth={"auto"}
          wordWrapEnabled={true}
          onToolbarPreparing={this.onToolbarPreparing}
          rtlEnabled={this.props.i18n.language === "ar" ? true : false}
          ref={(ref) => (this.dataGrid = ref)}
        >
          <ColumnChooser
            enabled={true}
            allowSearch={true}
            search={"fff"}
            title={this.props.t("dxColumnChooserTitle")}
            emptyPanelText={this.props.t("dxColumnChooserPanelText")}
          />

          <StateStoring
            enabled={true}
            type="custom"
            // customLoad={this.loadState}
            customSave={(state) => {
              if (state) {
                state.searchText = "";
                state.columns.map((s) => (s.filterValue = undefined));
                this.searchState.current = JSON.stringify(state);
              }
            }}
          />

          <SearchPanel
            visible={true}
            width={240}
            placeholder={this.props.t("search")}
          />

          <Pager
            visible={true}
            showInfo={true}
            infoText={`${this.props.t("page")} {0} ${this.props.t(
              "of"
            )} {1} ({2} ${this.props.t("item")})`}
          />

          <FilterRow visible={true} />

          <HeaderFilter visible={true} />

          <Export enabled={true} allowExportSelectedData={true} />

          <Grouping contextMenuEnabled={true} expandMode="rowClick" />
          <GroupPanel
            visible={true}
            emptyPanelText={this.props.t("dxGroupPanelText")}
          />

          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />

          {this.state.gridState?.columns
            ?.filter((d) => d.name !== "buttons")
            .map((d, i) => (
              <Column
                dataField={d.dataField}
                caption={d.name}
                alignment={this.props.i18n.language === "ar" ? "right" : "left"}
                visible={d.visible}
                width={d.width ? d.width : "auto"}
                dataType={d.dataType}
                format={d.dataType == "date" ? "yyyy-MM-dd" : ""}
              ></Column>
            ))}

          <Column
            cssClass="outlinedColumn nowrapColumn"
            type="buttons"
            caption={this.props.t("details")}
            fixed={true}
            fixedPosition={this.props.i18n.language === "ar" ? "left" : "right"}
          >
            <Button
              render={(record) => {
                return (
                  <ButtonInfo
                    variant="outlined"
                    // component={Link}
                    // state={{
                    //   requestId: record.data.Request_Id,
                    //   requestStatusId: record.data.Request_Status_Id,
                    //   requestTaskId: record.data.Request_Task_Id,
                    //   viewMood: true,
                    //   isRequestsList: true,
                    // }}
                    // to={"/view-request"}
                    onClick={() => this.handleEditView(record.data)}
                  >
                    <VisibilityIcon />
                  </ButtonInfo>
                );
              }}
            />
          </Column>
        </DataGrid>
      </>
    );
  }

  onExporting(e) {
    let sheetTitle = this.props.t("ASDashboard");
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGridToExcel({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 2 },
      topRightCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        // excelCell.value = gridCell.value;
        excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        excelCell.alignment = {
          indent: 1,
          wrapText: true,
          vertical: "middle",
        };
        if (gridCell.rowType === "group") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          excelCell.alignment = { indent: 2 };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          excelCell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${sheetTitle}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerDialog,
  triggerDialogForm,
  getDialogData,
  setLoading,
  triggerNotification,
})(translationHook(RequestsList));
