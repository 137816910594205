import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Pager,
	Paging,
	StateStoring
} from 'devextreme-react/data-grid'
import { ExcelJS , Workbook } from "exceljs"
import saveAs from "file-saver"
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter"
import CustomStore from 'devextreme/data/custom_store'
import VisibilityIcon from '@material-ui/icons/Visibility'
import InvoiceForm from './InvoicesForm'
import { triggerNotification, setLoading, triggerDialogForm , triggerDialog } from '../../../actions'
import { getInvoicesList , getInvoiceDetails } from '../../../apis/system/invoices/index'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonInfo, ButtonPrimary } from '../../UI/Buttons'
import { Select, Autocomplete } from 'mui-rff'
import { Form } from 'react-final-form'
import { Row, Col } from 'react-bootstrap'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { CheckBox } from 'devextreme-react'

class InvoicesList extends Component {

	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
		this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
		this.devRef = React.createRef(null);
		this.searchState = React.createRef();
	}

	state = {
		view: false,
		invoiceTypes: [],
		invoiceStatus: [], 
		reqData: {
			Invoice_Type_Id_List: []
		},
		From_Invoice_Date: null,
		To_Invoice_Date: null,
		From_Invoice_Paid_Date: null,
		To_Invoice_Paid_Date: null,
		isFromInvoiceDateOpen: false,
		isToInvoiceDate: false,
		isFromInvoicePaidDate: false,
		isToInvoicePaidDate: false,
		fromInvoiceDateStatus: false,
		toInvoiceDateStatus: false,
		fromInvoicePaidDateStatus: false,
		toInvoicePaidDateStatus: false,
		gridState: [],
		gridData: [],
		gridKey: "",
		filterResults: 0,
		showGrid: false,
		searchResults: 0,
		saveGrid: false,
		pageSize: null,
		focusRow: -1
	}

	getInvoiceTypes = async ()=> {
        let invoiceTypes = []
        // let invoiceTypes = [{value: null, label: this.props.t('withoutSelection')}]
        const response = await apiConfig.post('/API/SYSTEM/DDL/GET_INVOICES_TYPES')
        if (response.data.Status) {
            response.data.Data?.map((item)=> {
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN
                invoiceTypes.push({value: item.Record_Id, label})
            })
            this.setState({ invoiceTypes })
        }
    }

	getInvoiceStatus = async ()=> {
        let invoiceStatus = [
			{
				value: null,
				label: this.props.t('withoutSelection') 
			}
		]
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_INVOICES_STATUS'
        )

        if (response.data.Status) {

            response.data.Data?.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN

                invoiceStatus.push({value: item.Record_Id, label})
            })

            this.setState({ invoiceStatus })
            
        }

    }

	componentDidMount() {
		this.loadState();
		this.getInvoiceTypes()
		this.getInvoiceStatus()
		this.setState({
			reqData: {
				Invoice_Type_Id_List: [],
				Invoice_Status_Id: null,
				From_Invoice_Date: this.state.From_Invoice_Date,
				To_Invoice_Date: this.state.To_Invoice_Date,
				From_Invoice_Paid_Date: this.state.From_Invoice_Paid_Date,
				To_Invoice_Paid_Date: this.state.To_Invoice_Paid_Date
			}
		})
	}
	
	renderEditForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
		editMood = params[2] === 'edit' ? true : false;
        return <InvoiceForm invoiceId={params[0]} initValues={params[2]} 
		viewMood={viewMood} editMood={editMood} />
    }

    handleEdit = async (data , viewMood) => {
        let invoiceId = data.Invoice_Id,
		invoiceTitle = this.props.i18n.language === 'ar' ? data.Invoice_Type_Name_AR : data.Invoice_Type_Name_EN,
		auth = this.props.currentUser.Data.access_token,
        dialogTitle = `${this.props.t('view')} ${invoiceTitle}`;

		const invoiceDetails = await getInvoiceDetails(auth, invoiceId)

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
				invoiceId,
                invoiceTitle,
				invoiceDetails,
				viewMood
            ]
        })
    }

	onSectionSubmit = async () => {
		let at = this.props.currentUser.Data.access_token;
		const response = getInvoicesList(at, this.state.reqData)
		.then((res) => {
			this.setState({ gridData: res })
			console.log(res)
		})
	}

    handleChange = (e, field) => {
        this.setState({
			reqData: {
				...this.state.reqData,
				[field]:  e.target.value
			}
        })
		
    }

	handleDateChange = (date, field) => {
		this.setState({
			reqData: {
				...this.state.reqData,
				[field]: moment(date).lang("en").format('YYYY-MM-DD')
			},
			[field]: moment(date).lang("en").format('YYYY-MM-DD')
		})
	}

	saveState = async () => {
		const at = this.props.currentUser.Data.access_token,
		  url = "API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS",
		  bodyData = {
			System_Grid_Name: 'Grid_Invoices_List',
			System_Grid_Json: this?.searchState?.current,
			Lang: this.props.i18n.language,
		  }
	
		await apiConfig.post(url, bodyData, {
		  headers: {
			Authorization: `Bearer ${at}`,
		  },
		})
	
		this.props.triggerNotification(true, {
		  Status: true,
		})
	}

	getState = async (state) => {
		if (state) {
		  state.searchText = "";
		  state.columns?.map((s) => (s.filterValue = undefined));
		  this.searchState.current = JSON.stringify(state);
		}
	}

	loadState = async () => {
		const at = this.props.currentUser.Data.access_token,
			url = "API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS",
			bodyData = {
				System_Grid_Name: 'Grid_Invoices_List',
				Lang: this.props.i18n.language,
			};
	
		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});
	
		if (response.data) {
			this.setState({
				gridState: JSON.parse(response?.data?.Data),
				// showGrid: true,
				pageSize: JSON.parse(response?.data?.Data)?.pageSize,
			});
		}
	}

	onToolbarPreparing(e) {
		e.toolbarOptions.items.unshift(
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("saveGridSettings"),
					height: "36px",
					icon: "save",
					onClick: () => this.saveState(),
					hint: this.props.t("saveGridSettings"),
				}
			},
			{
				location: "after",
				widget: "dxButton",
				options: {
					text: this.props.t("clearSearch"),
					height: "36px",
					icon: "clearformat",
					onClick: () => this.dataGrid?.instance?.clearFilter(),
					hint: this.props.t("clearSearch"),
				}
			}
		)
	}

	handleOnContentReady = (e) => {
		if(this.props.i18n.language === "ar") {
			const scrollable = e.component?.getScrollable();
			const maxScrollLeft = scrollable?.scrollWidth();
	
			scrollable?.scrollTo({
				x: maxScrollLeft
			})
		}
	}

	render() {
		const jsonDataSource = new CustomStore({
			key: 'Invoice_Id',
			load: () => {
				return this.state.gridData
			}
		})

		return (
			<>
				<div className='d-flex justify-content-between align-items-center mb-4'>
					<h3>{this.props.t('invoicesList')}</h3>
				</div>

				<Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={{
                        Invoice_Type_Id_List: [],
                        Invoice_Status_Id: null,
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form 
							className="row align-items-center"
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={6}>
								{/* <Select
                                    label={this.props.t('invoiceType')}
                                    className='mb-2'
                                    name="Invoice_Type_Id_List"
									value={this.state.reqData.Invoice_Type_Id_List}
									onChange={(e) => {
										this.handleChange(e , 'Invoice_Type_Id_List')
									}}
									data={this.state.invoiceTypes}
								>
                                </Select> */}
								<Autocomplete
									multiple
									label={this.props.t('invoiceType')}
									name="Invoice_Type_Id_List"
									className='mb-2'
									options={this.state.invoiceTypes}
									getOptionValue={option => option.value}
									getOptionLabel={option => option.label}
									disableCloseOnSelect={true}
									onChange={(e, v) => {
										let newArrId = []
										v.map(r => r?.value && newArrId.push(r?.value))
										this.setState({
											reqData: {
												...this.state.reqData,
												Invoice_Type_Id_List: newArrId,
											}
										})
									}}
								/>
							</Col>

							<Col xs={6}>
								<Select
                                    label={this.props.t('invoiceStatus')}
                                    className='mb-2'
                                    name="Invoice_Status_Id"
									value={this.state.reqData.Invoice_Status_Id}
									onChange={(e) => this.handleChange(e , 'Invoice_Status_Id')}
									data={this.state.invoiceStatus}
                                    >
                                </Select>
                           </Col>

							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								
								<Col xs={6}>
									<Row>
										<Col xs={10}>
											<KeyboardDatePicker
												margin="normal"
												format="yyyy-MM-dd"
												label={this.props.t('invoiceFrom')}
												value={this.state.From_Invoice_Date}
												onChange={(e) => this.handleDateChange(e , 'From_Invoice_Date')}
												KeyboardButtonProps={{
													'aria-label': this.props.t('invoiceFrom'),
												}}
												onClick={() => this.state.fromInvoiceDateStatus && this.setState({ isFromInvoiceDateOpen : true })}
												onClose={() => this.setState({ isFromInvoiceDateOpen : false })}
												open={this.state.isFromInvoiceDateOpen}
												disabled={!this.state.fromInvoiceDateStatus}
											/>
										</Col>

										<Col 
											xs={2} 
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start'
											}}
										>
											<input 
												type='checkbox' 
												style={{width: '20px', height: '20px', accentColor: '#669900'}}
												checked={this.state.fromInvoiceDateStatus}
												onChange={() => {
													this.setState({fromInvoiceDateStatus: !this.state.fromInvoiceDateStatus})
													if (!this.state.fromInvoiceDateStatus) {
														this.setState({ From_Invoice_Date: moment().startOf('month').format('YYYY-MM-DD') })
														this.handleDateChange(moment().startOf('month').format('YYYY-MM-DD') , 'From_Invoice_Date')
													} else {
														this.setState({ 
															From_Invoice_Date: null,
															reqData: {
																...this.state.reqData,
																From_Invoice_Date: null
															}
														})
													}
												}}
											/>
										</Col>
									</Row>
								</Col>

								<Col xs={6}>	
									<Row>
										<Col xs={10}>					
											<KeyboardDatePicker
												margin="normal"
												label={this.props.t('invoiceTo')}
												format="yyyy-MM-dd"
												value={this.state.To_Invoice_Date}
												onChange={(e) => this.handleDateChange(e , 'To_Invoice_Date')}
												KeyboardButtonProps={{
													'aria-label': this.props.t('invoiceTo'),
												}}
												onClick={() => this.state.toInvoiceDateStatus && this.setState({ isToInvoiceDate : true })}
												onClose={() => this.setState({ isToInvoiceDate : false })}
												open={this.state.isToInvoiceDate}
												disabled={!this.state.toInvoiceDateStatus}
											/>
										</Col>

										<Col 
											xs={2} 
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start'
											}}
										>
											<input 
												type='checkbox' 
												style={{width: '20px', height: '20px', accentColor: '#669900'}}
												checked={this.state.toInvoiceDateStatus}
												onChange={() => {
													this.setState({toInvoiceDateStatus: !this.state.toInvoiceDateStatus})
													if (!this.state.toInvoiceDateStatus) {
														this.setState({ To_Invoice_Date: moment().endOf('month').format('YYYY-MM-DD') })
														this.handleDateChange(moment().endOf('month').format('YYYY-MM-DD') , 'To_Invoice_Date')
													} else {
														this.setState({ 
															To_Invoice_Date: null,
															reqData: {
																...this.state.reqData,
																To_Invoice_Date: null
															} 
														})
													}
												}}
											/>
										</Col>	
									</Row>
								</Col>

								<Col xs={6}>
									<Row>
										<Col xs={10}>
											<KeyboardDatePicker
												margin="normal"
												label={this.props.t('payFrom')}
												format="yyyy-MM-dd"
												value={this.state.From_Invoice_Paid_Date}
												onChange={(e) => this.handleDateChange(e , 'From_Invoice_Paid_Date')}
												KeyboardButtonProps={{
													'aria-label': this.props.t('payFrom'),
												}}
												onClick={() => this.state.fromInvoicePaidDateStatus && this.setState({ isFromInvoicePaidDate : true })}
												onClose={() => this.setState({ isFromInvoicePaidDate : false })}
												open={this.state.isFromInvoicePaidDate}
												disabled={!this.state.fromInvoicePaidDateStatus}
											/>
										</Col>	

										<Col 
											xs={2} 
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start'
											}}
										>
											<input 
												type='checkbox' 
												style={{width: '20px', height: '20px', accentColor: '#669900'}}
												checked={this.state.fromInvoicePaidDateStatus}
												onChange={() => {
													this.setState({fromInvoicePaidDateStatus: !this.state.fromInvoicePaidDateStatus})
													if (!this.state.fromInvoicePaidDateStatus) {
														this.setState({ From_Invoice_Paid_Date: moment().startOf('month').format('YYYY-MM-DD') })
														this.handleDateChange(moment().startOf('month').format('YYYY-MM-DD') , 'From_Invoice_Paid_Date')
													} else {
														this.setState({ 
															From_Invoice_Paid_Date: null,
															reqData: {
																...this.state.reqData,
																From_Invoice_Paid_Date: null
															} 
														})
													}
												}}
											/>
										</Col>	
									</Row>						
								</Col>

								<Col xs={6}>	
									<Row>
										<Col xs={10}>
											<KeyboardDatePicker
												margin="normal"
												label={this.props.t('payTo')}
												format="yyyy-MM-dd"
												value={this.state.To_Invoice_Paid_Date}
												onChange={(e) => this.handleDateChange(e , 'To_Invoice_Paid_Date')}
												KeyboardButtonProps={{
													'aria-label': this.props.t('payTo')
												}}
												onClick={() => this.state.toInvoicePaidDateStatus && this.setState({isToInvoicePaidDate : true})}
												onClose={() => this.setState({isToInvoicePaidDate : false})}
												open={this.state.isToInvoicePaidDate}
												disabled={!this.state.toInvoicePaidDateStatus}
											/>
										</Col>

										<Col 
											xs={2} 
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start'
											}}
										>
											<input 
												type='checkbox' 
												style={{width: '20px', height: '20px', accentColor: '#669900'}}
												checked={this.state.toInvoicePaidDateStatus}
												onChange={() => {
													this.setState({toInvoicePaidDateStatus: !this.state.toInvoicePaidDateStatus})
													if (!this.state.toInvoicePaidDateStatus) {
														this.setState({ To_Invoice_Paid_Date: moment().endOf('month').format('YYYY-MM-DD') })
														this.handleDateChange(moment().endOf('month').format('YYYY-MM-DD') , 'To_Invoice_Paid_Date')
													} else {
														this.setState({ 
															To_Invoice_Paid_Date: null,
															reqData: {
																...this.state.reqData,
																To_Invoice_Paid_Date: null
															}  
														})
													}
												}}
											/>
										</Col>	
									</Row>						
								</Col>

							</MuiPickersUtilsProvider>

							<Col className='pb-4 submit-btn-wrap text-center'>
								<ButtonPrimary
									color='primary'
									variant='contained'
									// disabled={submitting}
									type='submit'>
									{this.props.t('search')}
								</ButtonPrimary>
							</Col>
                        </form>
                    )}
                />

				{this.state.gridState?.columns ? (
					<DataGrid
						className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
						id="dataGrid"
						width={'100%'}
						dataSource={jsonDataSource}
						showBorders={false}
						showColumnLines= {false}
						showRowLines= {true}
						allowColumnResizing={true}
						columnResizingMode={'widget'}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						onExporting={this.onExporting}
						columnWidth={'auto'}
						wordWrapEnabled={true}
						rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
						onToolbarPreparing={this.onToolbarPreparing}
						ref={(ref) => (this.dataGrid = ref)}
						bounceEnabled={false}
						onOptionChanged={(e) => {
						if (e?.fullName == "paging.pageSize") {
							this.setState({ pageSize: e.value });
						}

						if (e.fullName.split(".").pop() == "filterValue") {
							this.dataGrid.instance.refresh();
						}
						}}
						onContentReady={this.handleOnContentReady}
						remoteOperations={false}
					>
						<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
							title={this.props.t('dxColumnChooserTitle')}
							emptyPanelText={this.props.t('dxColumnChooserPanelText')}
						/>

						<StateStoring
							enabled={true}
							type="custom"
							// customLoad={this.loadState}
							customSave={this.getState}
						/>
						
						<SearchPanel 
							visible={true}
							width={240}
							placeholder={this.props.t('search')}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true}/>

						<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
						<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}>
						</GroupPanel>

						<Selection
							mode="multiple"
							selectAllMode={'allPages'}
							showCheckBoxesMode={'always'}
						/>

						<Paging
							enabled={true}
							defaultPageSize={this.state.pageSize}
							pageSize={this.state.pageSize}
						/>

						<Pager
							visible="true"
							allowedPageSizes={[5, 10, 20, 50, 100]}
							displayMode="full"
							showPageSizeSelector="true"
							showInfo="true"
							infoText=""
							showNavigationButtons="true"
						/>

						{this.state.gridState?.columns
							?.filter((d) => d?.name !== "buttons")
							?.map((d, i) => (
								<Column
								dataField={d?.dataField}
								caption={d?.name}
								alignment={this.props.i18n.language === "ar" ? 'right': 'left'}
								visible={d?.visible}
								width={d?.width ? d.width : "auto"}
								dataType={d?.dataType}
								format={d?.dataType == "date" ? "yyyy-MM-dd" : ""}
								></Column>
							))
						}
						
						<Column 
							cssClass="outlinedColumn nowrapColumn"
							type='buttons'
							caption={this.props.t('details')}
							fixed={true}
							fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
						>
							<Button
								render={(record) => {
									return (
										<ButtonInfo 
										variant='circleIcon'
										id="inv-grid-edit-btn" 
										onClick={() => {
											this.handleEdit(record.data , 'view')
										}}
										startIcon={<VisibilityIcon />}>
										</ButtonInfo>
									);
								}}
							/>
						</Column>
						
					</DataGrid>
				) : 
					<div 
						className="globalBox"
						style={{
							height: '300px',
							width: '100%',
							backgroundColor: '#f7f9f5',
							padding: '1rem',
							borderRadius: '20px',
						}}  
					>
					<div 
						style={{
						padding: '1rem 1rem 0 1rem',
						backgroundColor: '#fff',
						height: '100%',
						borderRadius: '20px'
						}}
					></div>
					</div>
				}
			</>
		)
	}

	onExporting(e) {
		let sheetTitle = this.props.t('manageSystemModules')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	}
}

export default connect(mapStateToProps, { triggerDialog ,triggerNotification, setLoading, triggerDialogForm })(
	translationHook(InvoicesList)
)
