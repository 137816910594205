import { RELOAD } from '../actions/types'


const reloadReducer = (status = false, action) => {

    switch (action.type) {
        case RELOAD:

            return !status


        default:
            return status
    }

}

export default reloadReducer