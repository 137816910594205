import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "@material-ui/core/Switch";

import { getInvoiceTypeList } from "../../../apis/system/payment";
import { deleteDepartmentSection } from "../../../apis/departments/deleteDepartmentSection";
import {
  triggerDialog,
  triggerDialogForm,
  setLoading,
  triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import {
  ButtonSecondary,
  ButtonDanger,
  ButtonInfo,
  ButtonWarning,
} from "../../UI/Buttons";

import PaymentForm from "./paymentForm";

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);
  }
  renderSectionForm = (...params) => {
    let viewMood = params[2] === "view" ? true : false,
      editMood = params[2] === "edit" ? true : false;

    return (
      <PaymentForm
        InviceTypeId={params[0]}
        initValues={params[1]}
        editMood={editMood}
        viewMood={viewMood}
        InvoiceCode={params[3]}
      />
    );
  };

  handleDelete = (data) => {
    let at = this.props.currentUser.Data.access_token,
      departmentSectionId = data.Department_Section_Id,
      departmentSectionName =
        this.props.i18n.language === "ar"
          ? data.Department_Section_Name_AR
          : data.Department_Section_Name_EN,
      dialogTitle = `${this.props.t(
        "confirmDeleteTitle"
      )} ${departmentSectionName}`,
      dialogMsg = this.props.t("confirmDeleteMsg");

    this.props.triggerDialog(true, {
      title: dialogTitle,
      message: dialogMsg,
      action: {
        action: deleteDepartmentSection,
        params: [at, departmentSectionId],
        text: this.props.t("deleteSection"),
      },
    });
  };

  handleEditSection = (data, viewMood) => {
    let initValues = {
        InvoiceStatus: data.Active_Status_Id,
        InviceTypeId: data.Invoice_Type_Id,
        InviceTypeCode: data.Invoice_Type_Code,
        InvoiceTypeNameAR: data.Invoice_Type_Name_AR,
        InvoiceTypeNameEN: data.Invoice_Type_Name_EN,
        paymentCategoryType: data.Invoice_Type_Category_Id,
        ConfiscationItem: data.Confiscation_Invoice_Type_Id,
        InvoiceTypeOnlinePaymentCode: data.Invoice_Type_Online_Payment_Code,
        InvoiceTypeValue: data.Invoice_Type_Value
      },
      InviceTypeId = data.Invoice_Type_Id,
      InvoiceTypeName =
        this.props.i18n.language === "ar"
          ? data.Invoice_Type_Name_AR
          : data.Invoice_Type_Name_EN,
      dialogTitle = `${
        viewMood === "view"
          ? this.props.t("viewServiceDetailTitle")
          : this.props.t("editServiceDetailTitle")
      } ${InvoiceTypeName}`;

    this.props.triggerDialogForm(true, {
      title: dialogTitle,
      renderForm: this.renderSectionForm,
      params: [InviceTypeId, initValues, viewMood, initValues.InviceTypeCode],
    });
  };

  render() {
    let columnAlign = "left",
      DepSectionNameKey = "Department_Section_Name_EN",
      at = this.props.currentUser.Data.access_token;

    if (this.props.i18n.language === "ar") {
      columnAlign = "right";
      DepSectionNameKey = "Department_Section_Name_AR";
    }

    const jsonDataSource = new CustomStore({
      key: "Invoice_Type_Id",
      load: () => {
        return getInvoiceTypeList(at);
      },
    });

    return (
      <>
        <PaymentForm />

        <DataGrid
          className={[
            this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
            "globalBox",
          ].join(" ")}
          id="dataGrid"
          width={"100%"}
          dataSource={jsonDataSource}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          allowColumnReordering={true}
          rowAlternationEnabled={false}
          focusedRowEnabled={true}
          onExporting={this.onExporting}
          columnWidth={"auto"}
          wordWrapEnabled={true}
          rtlEnabled={this.props.i18n.language === "ar" ? true : false}
        >
          <ColumnChooser
            enabled={true}
            allowSearch={true}
            search={"fff"}
            title={this.props.t("dxColumnChooserTitle")}
            emptyPanelText={this.props.t("dxColumnChooserPanelText")}
          />

          <SearchPanel
            visible={true}
            width={240}
            placeholder={this.props.t("search")}
          />

          <FilterRow visible={true} />

          <HeaderFilter visible={true} />

          <Export enabled={true} allowExportSelectedData={true} />

          <Grouping contextMenuEnabled={true} expandMode="rowClick" />
          <GroupPanel
            visible={true}
            emptyPanelText={this.props.t("dxGroupPanelText")}
          />

          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />

          <Column
            cssClass="outlinedColumn"
            dataField="Invoice_Type_Code"
            caption={this.props.t("PaymentNumber")}
            alignment={"center"}
          ></Column>

          <Column
            dataField={"Invoice_Type_Name_AR"}
            caption={this.props.t("paymentNameAR")}
            alignment={columnAlign}
          ></Column>
          <Column
            dataField={"Invoice_Type_Name_EN"}
            caption={this.props.t("paymentNameEN")}
            alignment={columnAlign}
          ></Column>
          <Column
            dataField={
              this.props.i18n.language === "ar"
                ? "Invoice_Type_Category_Name_AR"
                : "Invoice_Type_Category_Name_EN"
            }
            caption={this.props.t("paymentCategoryType")}
            alignment={"center"}
          ></Column>
          <Column
            dataField={"Invoice_Type_Online_Payment_Code"}
            caption={this.props.t("InvoiceTypeOnlinePaymentCode")}
            alignment={"center"}
          ></Column>

          <Column
            cssClass="outlinedColumn nowrapColumn"
            type="buttons"
            caption={this.props.t("details")}
            fixed={true}
            fixedPosition={this.props.i18n.language === "ar" ? "left" : "right"}
          >
            <Button
              render={(record) => {
                return (
                  <ButtonWarning
                    variant="circleIcon"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      this.handleEditSection(record.data, "edit");
                    }}
                  >
                    {/* {this.props.t('edit')} */}
                  </ButtonWarning>
                );
              }}
            />

            {/* <Button
							render={(record) => {
								return (
									<ButtonInfo
									variant='outlined'
									startIcon={<VisibilityIcon />}
									onClick={() => {
                                        this.handleEditSection(record.data, 'view')
                                    }}
									>
										{this.props.t('view')}
									</ButtonInfo>
								);
							}}
						/> */}
            {/* <Button
							render={(record) => {
								return (
									<ButtonDanger
										variant='outlined'
										startIcon={<DeleteIcon />}
										onClick={() => this.handleDelete(record.data)}>
										{this.props.t('delete')}
									</ButtonDanger>
								);
							}}
						/> */}
          </Column>
        </DataGrid>
      </>
    );
  }

  onExporting(e) {
    let sheetTitle = this.props.t("managePaymentTypes");
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGridToExcel({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 2 },
      topRightCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        // excelCell.value = gridCell.value;
        excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        excelCell.alignment = { indent: 1, wrapText: true, vertical: "middle" };
        if (gridCell.rowType === "group") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          excelCell.alignment = { indent: 2 };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          excelCell.alignment = { vertical: "middle", horizontal: "center" };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${sheetTitle}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerDialog,
  triggerDialogForm,
  triggerNotification,
  setLoading,
})(translationHook(PaymentList));
