import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';
import apiConfig from '../../../apis/apiConfig';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SystemModuleForm from './systemModulesApisForm';

import { triggerNotification, setLoading, triggerDialogForm , triggerDialog } from '../../../actions'
import { getTemplatesApisVariablesList } from '../../../apis/template/templateApis';
import { translationHook } from '../../translationHook';
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from '../../UI/Buttons';
import { Col } from 'react-bootstrap';
import { Select } from 'mui-rff';
import { Form } from 'react-final-form';

class SystemModulesApisList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		view: false,
		getTemplateApis: [],
		Template_API_Id: 1
	}

	renderEditForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
		editMood = params[2] === 'edit' ? true : false;
        return <SystemModuleForm departmentId={params[0]} initValues={params[1]} 
		viewMood={viewMood} editMood={editMood} />
    }

    handleEdit = (data, viewMood) => {
        let DepartmentId = data.Template_System_Variable_Id,
		initValues = data,
		departmentTitle = this.props.i18n.language === 'ar' ? data.Template_System_Variable_Name_AR : data.Template_System_Variable_Name_EN,
        dialogTitle = `${viewMood === 'view' ? this.props.t('view') : this.props.t('edit') }  ${departmentTitle}`

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                DepartmentId,
				initValues,
				viewMood
            ]
        })
    }
	getTemplateApis = async () => {
		let getTemplateApis = [];

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/TEMPLATESAPI/GET_TEMPLATES_API', {},
            {
                headers: {
                    Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
                },
            }
            );

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === 'ar'
						? item.Template_API_Name_AR
						: item.Template_API_Name_EN;

				getTemplateApis.push({ value: item.Template_API_Id, label });
			});

			this.setState({ getTemplateApis });
			// Template_API_Id
		}
	};
	// customizeCellCheckbox = ({data}) => {
    //     let label = this.props.i18n.language === 'ar' ? data.Active_Status_Name_AR : data.Active_Status_Name_EN
    //     return (
    //         <FormControlLabel
    //         control={
    //             <Switch
    //             checked={data.Active_Status_Id}
    //             name={`departmentStatus_${data.Department_Id}`}
    //             onChange={async () => {
	// 				console.log(data);
    //                 let at = this.props.currentUser.Data.access_token,	
    //                     departmentId = data.Department_Id,
    //                     status = data.Active_Status_Id ? 0 : 1

    //                     this.props.setLoading(true, 'dark')

    //                     const response = await changeDepartmentStatus(at, departmentId, status)

    //                     this.props.triggerNotification(true, response)

    //                     this.props.setLoading(false)
    //             }}
    //             />
    //         }
    //         label={label}
    //         />
    //     )
        
    // }
	componentDidMount() {
		this.getTemplateApis()
	}

	handleChange = (e) => {
		console.log(e.target.value)
		this.setState({
			Template_API_Id: e.target.value
		})
    }
	
	onSubmit = () => {
		
	}

	render() {
		let columnAlign = 'left',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right';
		}

		const jsonDataSource = new CustomStore({
			key: 'Template_API_Variable_Id',
			load: () => {
				return getTemplatesApisVariablesList(at, this.state.Template_API_Id);
			},
		});

		return (
			<>
				<div className='d-flex justify-content-between align-items-center mb-4'>
					<h3>{this.props.t('manageSystemModulesApis')}</h3>
				</div>
				<Form
                    onSubmit={this.onSubmit}
                    initialValues={{
                        Template_API_Id: '',
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form 
						className="row align-items-center"
						onSubmit={handleSubmit}
						noValidate
						>
							<Col xs={6}>
								<Select
                                    label={this.props.t('templateApiService')}
                                    className='mb-2'
                                    name="Template_API_Id"
									value={this.state.Template_API_Id}
									onChange={(e) => this.handleChange(e)}
									data={this.state.getTemplateApis}
                                    >
                                </Select>
								</Col>
                        </form>
                    )}
                />

				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>
					
					<Column
						dataField={'Template_API_Variable_Name_AR'}
						caption={this.props.t('varNameAR')}
						alignment={columnAlign}>
					</Column>
					<Column
						dataField={'Template_API_Variable_Name_EN'}
						caption={this.props.t('varNameEN')}
						alignment={columnAlign}>
					</Column>
					<Column
						dataField={'Template_API_Variable_Parameter'}
						caption={this.props.t('varParam')}
						alignment={'center'}>
					</Column>
					<Column
						dataField={"Template_API_Variable_Code"}
						caption={this.props.t('varCode')}
						alignment={'center'}>
					</Column>
					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
									variant='circleIcon' 
									startIcon={<EditIcon />}
									onClick={() => {
										// this.setState({
										// 	view: false
										// })
										this.handleEdit(record.data , 'edit')}}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
							/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo variant='circleIcon' 
									onClick={() => {
										this.handleEdit(record.data , 'view')
									}}
									startIcon={<VisibilityIcon />}>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	onExporting(e) {
		let sheetTitle = this.props.t('manageSystemModules')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog ,triggerNotification, setLoading, triggerDialogForm })(
	translationHook(SystemModulesApisList)
);
