import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../apis/apiConfig';
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Select, Switches } from 'mui-rff'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';

import { ButtonPrimary, ButtonSecondary } from './../../UI/Buttons'
import { translationHook } from '../../translationHook'
import { triggerNotification, setLoading } from '../../../actions'

class ServiceInfo extends Component {

    

    onServiceInfoSubmit = async (values, form) => {
        const at = this.props.currentUser.Data.access_token,
        url = this.props.editMood ? '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSMANAGEMENT/Upd_Services_Lookups' : '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSMANAGEMENT/ADD_Services_Lookups',
        bodyData = {
            Service_Lookup_Name_AR: values.dropDownNameAR,
            Service_Lookup_Name_EN: values.dropDownNameEN,
            Active_Status_Id: values.dropDowmeStatus ? 1 : 0
        }

        if (this.props.editMood) {
            bodyData.Service_Lookup_Id = this.props.dropdownId 
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })

        
        if (response.data.Status) {
            form.restart()
            this.props.onStepDone(response.data.Data.Service_Lookup_Id, values)
        } 

        this.props.triggerNotification(true, response.data)
        this.props.setLoading(false)
        
        
    }

    
    render() {
    
        const validate = values => {
            const errors = {}


            if (!values.dropDownNameAR) {
                errors.dropDownNameAR = `${this.props.t('error.field_required')}`;
            }

            if (!values.dropDownNameEN) {
                errors.dropDownNameEN = `${this.props.t('error.field_required')}`;
            }
            

            
            return errors;
        }


        return(
            <Form
                onSubmit={this.onServiceInfoSubmit}
                initialValues={this.props.initValues}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                className="row"
                onSubmit={handleSubmit}
                        noValidate>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12} md={5}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('dropDowndetailnameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="dropDownNameAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={5}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('dropDowndetailnameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="dropDownNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={2}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="dropDowmeStatus"
                                    data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                />
                            </Col>
                        </Row>
                    </Col>

                    {!this.props.viewMood ? 
                    <Col className='my-4' xs={12}>
                        <Row className='align-items-center'>
                            <Col className='mt-2'>
                                <ButtonPrimary
                                    color="primary"
                                    variant="contained"
                                    disabled={submitting}
                                    type="submit"
                                >
                                    {this.props.t('save')}
                                </ButtonPrimary>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <></>
                    }

                    
                </form>
                )}
            />
        )
    }

}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading })(translationHook(ServiceInfo))