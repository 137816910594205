import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Icon from '@material-ui/core/Icon'
import Sync from '@material-ui/icons/Sync';

import {
	ButtonPrimary
} from '../../UI/Buttons';

const useStyles = makeStyles({
    badgeRoot: {
        width: '100%'
    },
    root: {
        width: '100%',
        textAlign: 'center',
        boxShadow: 'none',
        height: '100%',
        background: 'none',
        position: 'relative',
        '& svg': {
            width: '32px',
            height: '32px',
        },
        '& .MuiButtonBase-root': {
            display: 'flex',
            flexDirection: 'column',
            background: '#ffffff',
            opacity: '1',
            borderBottom: '#d0b166 .6rem solid',
            height: '100%',
            padding: '.5rem 1rem',
            opacity: '.95',
            '&:hover':{
                opacity: '.95',
                borderBottom: '#162917 .3rem solid',
                boxShadow: 'inset 0 0 2px rgba(0,0,0,1)',
            }
        },
        '& .btnReload': {
            position: 'absolute',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            zIndex: '10',
            border: '2px solid #fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bottom: '25px',
            left: '18px',
            background:'rgba(170,140,70,0.7)',
            '& svg': {
                width: '24px',
                height: '24px',
                color:'#fff'
            }
        },
        '& .MuiCardContent-root': {
            textAlign: 'center',
            padding: '5px 0px',
        },
        '& .MuiButtonBase-root.Mui-disabled': {
            opacity: '.2'
        },
        '& .CardTitle': {
            color: '#33bb00',
            fontSize: '.8rem',
            fontWeight: 'bold',
            textAlign: 'start',
        },
        '& .CardCountNum': {
            fontWeight: 'bold',
            color: '#33bb00',
            fontSize: '2.5rem',
            textAlign: 'end',
        },
        '&.MuiCardActionArea-focusHighlight':{
            display: 'none',
        }
    },
    icon: {
        width: '85.0394px',
        height: '85.0394px',
        display: 'block',
        '& img':{
            maxWidth:'100%',
            maxHeight: '100%'
        }
    }
})


const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }


const DashboardCard = (props) => {
    const { t, i18n } = useTranslation('common')
    const classes = useStyles()
    const prevCount = usePrevious(props.count)
    const [showReloadBtn, setReloadStatus] = useState(false)

    const handleReloadRecord = () => {
        setReloadStatus(false)
        props.reload()
    }

    useEffect(() => {
        if (prevCount && props.count && (prevCount !== props.count)) {
            setReloadStatus(true)
        }
    }, [prevCount, props.count])

    return (
        <>
            <Card className={classes.root}>

                {(() => {
                    if (showReloadBtn) {
                        if (props.active) {
                            return (
                                <button className="btnReload" onClick={handleReloadRecord}>
                                    <Sync />
                                </button>
                            )
                        }
                    } else {
                        return null
                    }
                })()}

                
                <CardActionArea disabled={props.disabled} className="justify-content-between" onClick={props.onClick}>
                    <CardContent className="row d-flex w-100">
                        <div className="col-6 px-1">
                            <Icon className={classes.icon}>
                                {props.icon}
                            </Icon>
                        </div>
                        <Typography variant="h3" component="div" className="CardCountNum col-6 px-1">
                            {props.count}
                        </Typography>
                    </CardContent>
                    <Typography component="div" className="CardTitle row d-flex mt-1 w-100">
                        {props.title}
                    </Typography>
                </CardActionArea>
            </Card>
        </>
    )
}

export default DashboardCard