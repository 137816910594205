import apiConfig from '../apiConfig';

export const setUserStatus = async (auth, userId, status) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/SET_USER_STATUS',
		{
            User_Account_Id: userId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${auth}`,
            },
        },
	);

    return response.data;

	return [];
};

export const setProfileMain = async (auth, userId, status) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/UPD_USER_PROFILE_MAIN',
		{
            User_Id: userId
		},
        {
            headers: {
                Authorization: `Bearer ${auth}`,
            },
        },
	);

    return response.data;

	return [];
};
export const setProfileStatus = async (auth, data) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/UPD_USER_PROFILE_STATUS',
		data,
        {
            headers: {
                Authorization: `Bearer ${auth}`,
            },
        },
	);

    return response.data;

	return [];
};
