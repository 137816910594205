import React, { Component } from 'react';
import { connect } from 'react-redux';
import apiConfig from '../../apis/apiConfig';
import { translationHook } from '../translationHook';
import { ButtonPrimary, ButtonDanger, ButtonSecondary } from '../UI/Buttons';
import FilesUpload from '../UI/FileUpload/UploadFile';
import { Col , Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from '../../actions';
import { deleteAttachment } from '../../apis/tasks/assignTasks';
import { getMissionAttachmentsList, getMissionAttachmentsResList } from '../../apis/tasks/assignTasks'
class NoteForm extends Component {

	onSubmit = async (values, form) => {
        const auth = this.props.currentUser.Data.access_token,
			data = {
				Mission_Id: this.props.missionID,
				Mission_Response_Description: values.profileNotes,
			};
		this.props.setLoading(true, 'dark');

		const url = 'API/ADMINISTRATION/MISSIONS/COMPELET_MISSION'

		
		const response = await apiConfig
			.post(url, data, {
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			}).then(res => {
				this.props.triggerNotification(true, res.data);
			})
			.catch((error) => {
				this.props.triggerNotification(true, error);
				return error.response;
			});
		this.props.setLoading(false);
		this.props.triggerDialogForm(false);
	};

    validate = values => {
        const errors = {};
		if (!values.profileNotes) {
			errors.profileNotes = `${this.props.t('error.field_required')}`;
		}
        return errors;
    };
    
	render() {
		let {status} = this.props;

		return (
			<>
			{status === "compelete" ? 
				<Form
					onSubmit={this.onSubmit}
					initialValues={{
						profileNotes: ''
					}}
					validate={this.validate}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form
							id='taskFrom'
							className='row'
							onSubmit={handleSubmit}
							noValidate>
							<Col xs={12} md={12}>
								<TextField
									label='ملاحظات'
									className='mb-2'
									name='profileNotes'
									required={true}
									autoComplete='off'
									showError={({ meta: { submitFailed, invalid } }) => {
										return submitFailed && invalid;
									}}
									multiline
									rows={5}
									/>
							</Col>
							<Col className='mt-4 mb-4' xs={12} md={12}>
								<Row>
									<Col className='mt-2 submit-btn-wrap'>
										<ButtonPrimary
											color='primary'
											variant='contained'
											disabled={submitting}
											type="submit"
											>
											{this.props.t('save')}
										</ButtonPrimary>
									</Col>
								</Row>
							</Col>
						</form>
					)}
				/>
			:
			<p>
				<Col md={6}>
				<FilesUpload at={this.props.currentUser.Data.access_token} 
					endPoint="API/ADMINISTRATION/MISSIONS/ADD_MISSION_RESPONSE_ATTACHMENT" 
					params={{"mission_Id": this.props.missionID}}
					fileApiKey="Mission_Attach_File"
					deleteCB={deleteAttachment}
					/>
				</Col>

			</p>
			}

			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(NoteForm));
