import apiConfig from '../../apiConfig';
export const saveDepartmentSelectedServices = async (at, selectedServiceList) => {

    const response = await apiConfig.post(
        '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SAVE_DEPARMENT_SELECTED_SERVICES',
        {
            Service_Id_List: selectedServiceList
        }, 
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response;


}
