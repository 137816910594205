import apiConfig from '../../apiConfig'
export const getInvoicesList = async (at , data = {}) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PAYMENTS/INVOICES/GET_INVOICES_LIST',
		{
            ...data,
			PageSize: 100000,
			PageIndex: 1
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data.Data.Data;
	}
	return [];
};
export const getInvoiceDetails = async (at , id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PAYMENTS/INVOICES/GET_INVOICE_DETAILS',
		{
            Invoice_Id: id
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};
export const deleteInvoice = async (at , id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PAYMENTS/INVOICES/CANCEL_INVOICE',
		{
            Invoice_Id: id
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data;
	}
	return [];
};
export const deleteChangeEdit = async (at , data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PAYMENTS/INVOICES/DELETE_INVOICE_CHANGES_DRAFT',
		data,
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data;
	}
	return [];
};
