import apiConfig from '../apiConfig';

export const setCardStatus = async (auth, cardId, status) => {
	const response = await apiConfig.post(
		'/API/BASIC/SETTINGS/DASHBOARD_CARDS/SET_DASHBOARD_CARD_STATUS',
		{
            Dashboard_Card_Id: cardId,
            Dashboard_Card_Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${auth}`,
            },
        },
	);

    return response.data;
};