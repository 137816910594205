import React, { Component } from 'react';
import { connect } from 'react-redux';
import apiConfig from '../../../apis/apiConfig';
import { translationHook } from '../../translationHook';
import { ButtonPrimary, ButtonDanger, ButtonSecondary } from '../../UI/Buttons';
import { Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from '../../../actions';

class NoteForm extends Component {
	state = {
		action: 'accept',
	};
	componentDidMount() {
		console.log(this.props.verification);
	}

	beforeSubmit = (values, form) => {
		form.submit(values);
	};
	onSubmitAccept =
		({ action }) =>
		async (values, form) => {
			// return false
			const auth = this.props.currentUser.Data.access_token,
				data = {
					client_Profile_Id: this.props.profileId,
					Profile_Notes: values.profileNotes,
				};
			this.props.setLoading(true, 'dark');

			const url =
				action === 'accept'
					? 'API/ADMINISTRATION/CLIENTPROFILE/CLIENT_PROFILE_VERIFY'
					: action === 'Requestedit'
					? 'API/ADMINISTRATION/CLIENTPROFILE/CLIENT_PROFILE_MODIFY'
					: action === 'delete'
					? 'API/ADMINISTRATION/CLIENTPROFILE/CLIENT_PROFILE_DELETE'
					: '';

			const response = await apiConfig
				.post(url, data, {
					headers: {
						Authorization: `Bearer ${auth}`,
					},
				})
				.then((res) => {
					this.props.triggerNotification(true, res.data);
				})
				.catch((error) => {
					this.props.triggerNotification(true, error);
					return error.response;
				});
			this.props.setLoading(false);
			this.props.triggerDialogForm(false);
		};

	validate = (values) => {
		const errors = {};
		console.log(this.state.action);

		if (!values.profileNotes) {
			errors.profileNotes = `${this.props.t('error.field_required')}`;
		}

		return errors;
	};

	render() {
		return (
			<>
				<Form
					// onSubmit={this.state.action === 'accept' ? this.onSubmitAccept:
					//  this.state.action === 'Requestedit' ? this.onSubmitEdits :
					//  this.state.action === 'delete' ? this.onSubmitreject : this.beforeSubmit}
					onSubmit={this.onSubmitAccept({ action: this.state.action })}
					initialValues={{
						profileNotes: '',
					}}
					validate={
						this.state.action === 'accept'
							? () => {
									return [];
							  }
							: this.validate
					}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form
							id='taskFrom'
							className='row'
							onSubmit={handleSubmit}
							noValidate>
							<Col xs={12} md={12}>
								<TextField
									label='ملاحظات'
									className='mb-2'
									name='profileNotes'
									required={true}
									autoComplete='off'
									showError={({ meta: { submitFailed, invalid } }) => {
										return submitFailed && invalid;
									}}
									multiline
									rows={5}
								/>
							</Col>
							<Col className='mt-4 mb-4' xs={12} md={12}>
								<Row>
									<Col className='mt-2 submit-btn-wrap'>
										{/*
										 0 == new
										 1 = acepted
										 2 = rejected
										 3 = edit from customerEmail
										 4 = returned to edit empolyee
										 */}
										{this.props.verification === 0 ? (
											<>
												<ButtonPrimary
													color='primary'
													variant='contained'
													disabled={submitting}
													type='submit'
													onClick={() => {
														this.setState({
															action: 'accept',
														});
													}}>
													{this.props.t('acceptRequest')}
												</ButtonPrimary>
												<ButtonSecondary
													color='primary'
													variant='outlined'
													disabled={submitting}
													type='submit'
													onClick={() => {
														this.setState({
															action: 'Requestedit',
														});
													}}>
													{this.props.t('Requestedit')}
												</ButtonSecondary>
												<ButtonDanger
													color='primary'
													variant='contained'
													disabled={submitting}
													type='submit'
													onClick={(e) => {
														this.setState({
															action: 'delete',
														});
													}}>
													{this.props.t('reject')}
												</ButtonDanger>
											</>
										) : this.props.verification === 1 ? (
											<>
											<ButtonSecondary
												color='primary'
												variant='outlined'
												disabled={submitting}
												type='submit'
												onClick={() => {
													this.setState({
														action: 'Requestedit',
													});
												}}>
												{this.props.t('Requestedit')}
											</ButtonSecondary>
											<ButtonDanger
													color='primary'
													variant='contained'
													disabled={submitting}
													type='submit'
													onClick={(e) => {
														this.setState({
															action: 'delete',
														});
													}}>
													{this.props.t('reject')}
												</ButtonDanger>
											
											</>
										) : this.props.verification === 3 ? (
											<>
												<ButtonSecondary
													color='primary'
													variant='outlined'
													disabled={submitting}
													type='submit'
													onClick={() => {
														this.setState({
															action: 'Requestedit',
														});
													}}>
													{this.props.t('Requestedit')}
												</ButtonSecondary>
												
											</>
										)  : this.props.verification === 4 ? (
											<>
												<ButtonPrimary
													color='primary'
													variant='contained'
													disabled={submitting}
													type='submit'
													onClick={() => {
														this.setState({
															action: 'accept',
														});
													}}>
													{this.props.t('acceptRequest')}
												</ButtonPrimary>
												<ButtonSecondary
													color='primary'
													variant='outlined'
													disabled={submitting}
													type='submit'
													onClick={() => {
														this.setState({
															action: 'Requestedit',
														});
													}}>
													{this.props.t('Requestedit')}
												</ButtonSecondary>
												<ButtonDanger
													color='primary'
													variant='contained'
													disabled={submitting}
													type='submit'
													onClick={(e) => {
														this.setState({
															action: 'delete',
														});
													}}>
													{this.props.t('reject')}
												</ButtonDanger>
											</>
										) : <></>}
									</Col>
								</Row>
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(NoteForm));
