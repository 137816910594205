import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, navigate } from "@reach/router";
import apiConfig from "../../../apis/apiConfig";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWarning,
  ButtonInfo,
  ButtonDanger,
  ButtonWhite,
} from "../../UI/Buttons";
import { translationHook } from "../../translationHook";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../../actions";
import { clearTaskData } from "../../../apis/requests/processTask";

import Invoices from "./invoices";
import Reject from "./reject";
import { ReturnToEdit, ReturnToAdd, ReturnRequestStep } from "./return";
import Complete from "./complete";
import { TemplateIssuance, TemplateApproval } from "./template";
import { ChartAudit, ChartApprove } from "./charts";
import Approve from "./approve";
import {
  InspectionTime,
  InspectionReport,
  InspectionReportApproving,
  InspectionVisit,
} from "./inspection";
import AdditionalAttachmentForm from "../additional/AdditionalAttachmentForm";
import { InsuranceRefund } from "./insurance";
import { ViolationSettlement } from "./violation";
import { RenderStepUsers } from "./changeStepUser";
import ReOpeningServiceRequest from "../reopen/ReOpeningServiceRequest";
import Processing from "./Processing";

class ProcessingActions extends Component {
  state = {
    requestAssigned: false,
    closeFormTitle:
      window.location.href.indexOf("servicies-requests") > -1
        ? this.props.t("closeAndReturnToMenu")
        : this.props.t("cancelToDashboard"),
  };

  renderForm = (...params) => {
    switch (params[0]) {
      case "generateInvoice":
        return (
          <div className="p-5 text-start">
            <Invoices
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "reject":
        return (
          <div className="p-5 text-start">
            <Reject
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "returnEditData":
        return (
          <div className="p-5 text-start">
            <ReturnToEdit
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "returnAddData":
        return (
          <div className="p-5 text-start">
            <ReturnToAdd
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "complete":
        return (
          <div className="p-5 text-start">
            <Complete
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "templateIssuance":
        return (
          <div className="p-5 text-start">
            <TemplateIssuance
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              task={params[0]}
              requestNo={this.props.btns.Request_No}
            />
          </div>
        );

      case "templateApprove":
        return (
          <div className="p-5 text-start">
            <TemplateApproval
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              task={params[0]}
            />
          </div>
        );

      case "documentAudit":
        return (
          <div className="p-5 text-start">
            <ChartAudit
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              task={params[0]}
            />
          </div>
        );

      case "documentApprove":
        return (
          <div className="p-5 text-start">
            <ChartApprove
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              task={params[0]}
            />
          </div>
        );

      case "approve":
        return (
          <div className="p-5 text-start">
            <Approve
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              task={params[0]}
            />
          </div>
        );

      case "inspectionTime":
        return (
          <div className="p-5 text-start">
            <InspectionTime
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "inspectionReport":
        return (
          <div className="p-5 text-start">
            <InspectionReport
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "inspectionReportApprove":
        return (
          <div className="p-5 text-start">
            <InspectionReportApproving
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "inspectionVisit":
        return (
          <div className="p-5 text-start">
            <InspectionVisit
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              task={params[0]}
            />
          </div>
        );

      case "returnRequestStep":
        return (
          <div className="p-5 text-start">
            <ReturnRequestStep
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "addAdditionalAttachment":
        return (
          <div className="p-5 text-start">
            <AdditionalAttachmentForm
              requestId={this.props?.requestId}
              refetchData={this.props.refetchData}
            />
          </div>
        );

      case "reOpeningServiceRequest":
        return (
          <div className="p-5 text-start">
            <ReOpeningServiceRequest
              requestId={this.props?.requestId}
              requestTaskId={this.props?.requestTaskId}
            />
          </div>
        );

      case "refundInsurance":
        return (
          <div className="p-5 text-start">
            <InsuranceRefund
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "violationSettlement":
        return (
          <div className="p-5 text-start">
            <ViolationSettlement
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
            />
          </div>
        );

      case "changeStepUser":
        return (
          <div className="p-5 text-start">
            <RenderStepUsers
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              optional={true}
            />
          </div>
        );

      case "assignStepUser":
        return (
          <div className="p-5 text-start">
            <RenderStepUsers
              requestId={this.props?.btns?.Request_Id}
              requestTaskId={this.props.btns.Request_Task_Id}
              tempId={params[1]}
              optional={false}
            />
          </div>
        );

      default:
        return null;
    }
  };

  handleTaskAction = async (callback) => {
    this.props.setLoading(true, "dark");

    let at = this.props.currentUser.Data.access_token;

    const data = {
      Request_Id: this.props?.btns?.Request_Id,
      Request_Task_Id: this.props.btns.Request_Task_Id,
    };

    const res = await clearTaskData(at, data)
      .then(() => {
        this.props.setLoading(false);
        callback();
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.triggerNotification(true, error.response);
      });
  };

  handleReject = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم برفض الطلب",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["reject", 1],
    });
  };

  handleApprove = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بقبول الطلب",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["approve", 2],
    });
  };

  handleReturnEdit = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم إرجاع المعاملة للعميل للتعديل",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["returnEditData", 3],
    });
  };

  handleReturnAdd = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم إرجاع المعاملة للعميل لطلب بيانات اضافية",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["returnAddData", 4],
    });
  };

  handleInvoice = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باحتساب رسوم الطلب",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["generateInvoice", 5],
    });
  };
  //here 1/5
  handleProcess = async () => {
    let at = this.props.currentUser.Data.access_token;

    this.props.setLoading(true, "dark");

    const response = await apiConfig
      .post(
        "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/ASSIGN_USER_TO_REQUEST",
        {
          Request_Id: this.props?.btns?.Request_Id,
          Request_Task_Id: this.props.btns.Request_Task_Id,
        },
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      )
      .catch((error) => {
        return error.response;
      });

    this.props.setLoading(false);

    this.props.triggerNotification(true, response.data);
    if (response?.data?.Data?.Request_Task_Id) {
      this.setState({ requestAssigned: true });
      if (window.location.href.indexOf("view-request") !== -1) {
        navigate("/view-request", {
          state: {
            requestId: response.data.Data.Request_Id,
            requestTaskId: response.data.Data.Request_Task_Id,
          },
          replace: true,
        });
      } else {
        const renderViewForm = (params) => {
          return (
            <div
              style={{
                overflowY: "overlay",
                overflowX: "hidden",
                padding: "4rem 2rem 0 2rem",
                zIndex: 9,
              }}
            >
              <Processing dataListObject={params} />
            </div>
          );
        };

        this.props.triggerDialogForm(true, {
          title: "",
          closeBtnTitle: this.state.closeFormTitle,
          renderForm: renderViewForm,
          params: [
            {
              requestId: response.data.Data.Request_Id,
              requestTaskId: response.data.Data.Request_Task_Id,
            },
          ],
          dismissAction: {
            action: () => window.location.assign("/"),
            params: [],
          },
        });
      }
    }
  };

  handleReturnFromClient = async () => {
    let at = this.props.currentUser.Data.access_token;

    this.props.setLoading(true, "dark");

    //here 1/5
    const response = await apiConfig
      .post(
        "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_RETURN_FROM_CLIENT",
        {
          Request_Id: this.props?.btns?.Request_Id,
          Request_Task_Id: this.props.btns.Request_Task_Id,
        },
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      )
      .then(() => {
        this.props.setLoading(false);
        window.location.assign("/");
        // this.props.triggerDialogForm(false);
        // window.location.reload();
        // navigate("/");
      })
      .catch((error) => {
        return error.response;
      });

    this.props.setLoading(false);

    this.props.triggerNotification(true, response.data);

    if (response?.data?.Data?.Request_Task_Id) {
      if (window.location.href.indexOf("view-request") !== -1) {
        navigate("/view-request", {
          state: {
            requestId: response.data.Data.Request_Id,
            requestTaskId: response.data.Data.Request_Task_Id,
          },
          replace: true,
        });
      } else {
        const renderViewForm = (params) => {
          return (
            <div
              style={{
                overflowY: "overlay",
                overflowX: "hidden",
                padding: "4rem 2rem 0 2rem",
                zIndex: 9,
              }}
            >
              <Processing dataListObject={params} />
            </div>
          );
        };

        this.props.triggerDialogForm(true, {
          title: "",
          renderForm: renderViewForm,
          params: [
            {
              requestId: response.data.Data.Request_Id,
              requestTaskId: response.data.Data.Request_Task_Id,
            },
          ],
        });
      }
    }
  };

  handleTemplateIssue = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بإصدار نموذج للخدمة",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["templateIssuance", 6],
    });
  };

  handleTemplateApprove = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باعتماد نموذج الخدمة",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["templateApprove", 7],
    });
  };

  handleDocumentAudit = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بتدقيق المخططات و المستندات",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["documentAudit", 8],
    });
  };

  handleDocumentApprove = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باعتماد المخططات و المستندات",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["documentApprove", 9],
    });
  };

  handleCompleteRequest = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باتمام الطلب",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["complete", 10],
    });
  };

  handleSetInspectionTime = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بتحديد وقت الزيارة",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["inspectionTime", 11],
    });
  };

  handleInspectionReportIssuance = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باصدار تقرير الزيارة",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["inspectionReport", 12],
    });
  };

  handleInspectionReportApprove = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باعتماد تقرير الزيارة",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["inspectionReportApprove", 13],
    });
  };

  handleReturnRequestStep = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بارجاع المعاملة خطوة للخلف",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["returnRequestStep", 14],
    });
  };

  handleInspectionVisit = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باجراء زيارة التفتيش",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["inspectionVisit", 15],
    });
  };

  handleRefundInsurance = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بارداد التامين",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["refundInsurance", 16],
    });
  };

  handleViolationSettlement = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بتسوية الايقاف",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["violationSettlement", 17],
    });
  };

  handleaddAdditionalAttatchment = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بااضافة مرفقات اضافية للطلب",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["addAdditionalAttachment"],
    });
  };

  handleReOpeningServiceRequest = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم باعادة فتح طلب الخدمة",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["reOpeningServiceRequest"],
    });
  };

  handleSkipStep = async () => {
    let at = this.props.currentUser.Data.access_token;

    this.props.setLoading(true, "dark");
    //here 1/5
    await apiConfig
      .post(
        "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SKIP_STEP",
        {
          Request_Id: this.props?.btns?.Request_Id,
          Request_Task_Id: this.props.btns.Request_Task_Id,
        },
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      )
      .then(() => {
        this.props.setLoading(false);
        window.location.assign("/");
        // this.props.triggerDialogForm(false);
        // window.location.reload();
        // navigate("/");
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.triggerNotification(true, error.response.data);

        return error.response;
      });
  };

  handleChangeStepUser = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم تغير مستخدم المرحله",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["changeStepUser", 18],
    });
  };

  handleAssignStepUser = () => {
    this.props.triggerDialogForm(true, {
      title: "انت الان  تقوم بتعيين مستخدم المرحله",
      closeBtnTitle: this.state.closeFormTitle,
      renderForm: this.renderForm,
      params: ["assignStepUser", 19],
    });
  };

  handleCloseModal = () => {
    if (window.location.href.indexOf("view-request") === -1) {
      this.props.triggerDialogForm(false);
      if (this.state.requestAssigned) {
        window.location.assign("/");
      }
    }
  };

  render() {
    return (
      <>
        {
          <ButtonSecondary
            variant="solid"
            startIcon={<ExitToAppIcon />}
            component={Link}
            style={{
              padding: "1rem 0 !important",
              height: "100%",
            }}
            to={
              window.location.href.indexOf("servicies-requests") > -1
                ? "/servicies-requests"
                : ""
            }
            onClick={this.handleCloseModal}
          >
            {this.props.t("closeRequestServiceBtn")}
          </ButtonSecondary>
        }

        {this.props.btns?.Btn_Accept_Task === 1 && (
          <ButtonWhite
            variant="solid"
            startIcon={<EditIcon />}
            style={{
              height: "100%",
            }}
            onClick={this.handleProcess}
          >
            {this.props.t("processRequest")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Reject_Request === 1 && (
          <ButtonDanger
            variant="solid"
            startIcon={<DeleteIcon />}
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleReject);
            }}
          >
            {this.props.t("deleteRequest")}
          </ButtonDanger>
        )}

        {this.props.btns?.Btn_Request_Approving === 1 && (
          <ButtonWarning
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleApprove);
            }}
          >
            {this.props.t("acceptRequest")}
          </ButtonWarning>
        )}

        {this.props.btns?.Btn_Return_For_Update === 1 && (
          <ButtonInfo
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleReturnEdit);
            }}
          >
            {this.props.t("returnRequest")}
          </ButtonInfo>
        )}

        {this.props.btns?.Btn_Return_For_Additions === 1 && (
          <ButtonInfo
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleReturnAdd);
            }}
          >
            {this.props.t("returnRequestAdd")}
          </ButtonInfo>
        )}

        {this.props.btns?.Btn_Generate_Invoices === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleInvoice);
            }}
          >
            {this.props.t("issueInvoice")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Template_Issuance === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleTemplateIssue);
            }}
          >
            {this.props.t("templateIssuance")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Template_Approving === 1 && (
          <ButtonWarning
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleTemplateApprove);
            }}
          >
            {this.props.t("approveTemplates")}
          </ButtonWarning>
        )}

        {this.props.btns?.Btn_Charts_Audit === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleDocumentAudit);
            }}
          >
            {this.props.t("documentAudit")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Charts_Approving === 1 && (
          <ButtonWarning
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleDocumentApprove);
            }}
          >
            {this.props.t("documentApprove")}
          </ButtonWarning>
        )}

        {this.props.btns?.Btn_Complete_Step === 1 && (
          <ButtonPrimary
            className="btn-primary_alt"
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleCompleteRequest);
            }}
          >
            {this.props.t("completeRequest")}
          </ButtonPrimary>
        )}

        {this.props.btns?.Btn_Skip_Task === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={this.handleSkipStep}
          >
            {this.props.t("skipRequest")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Inspection_Time === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleSetInspectionTime);
            }}
          >
            {this.props.t("setInspectionTime")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Inspection_Report_Issuance === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleInspectionReportIssuance);
            }}
          >
            {this.props.t("inspectionReportIssuance")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Inspection_Report_Approving === 1 && (
          <ButtonWarning
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleInspectionReportApprove);
            }}
          >
            {this.props.t("inspectionReportApprove")}
          </ButtonWarning>
        )}

        {this.props.btns?.Btn_Inspection_Visit === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleInspectionVisit();
            }}
          >
            {this.props.t("processInspectionVisit")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Return_Step === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleReturnRequestStep);
            }}
          >
            {this.props.t("returnStep")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Insurance_Refund === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleRefundInsurance);
            }}
          >
            {this.props.t("refundInsurance")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Violation_Settlement === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleViolationSettlement);
            }}
          >
            {this.props.t("violationSettlement")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Add_Request_Additional_Data === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleaddAdditionalAttatchment();
            }}
          >
            {this.props.t("additionalData")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Reopen_Request === 1 && (
          <ButtonWhite
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleReOpeningServiceRequest();
            }}
          >
            {this.props.t("reOpeningServiceRequest")}
          </ButtonWhite>
        )}

        {this.props.btns?.Btn_Return_From_Client === 1 && (
          <ButtonInfo
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={this.handleReturnFromClient}
          >
            {this.props.t("returnFromClient")}
          </ButtonInfo>
        )}

        {this.props.btns?.Btn_Change_Step_User === 1 && (
          <ButtonInfo
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleChangeStepUser);
            }}
          >
            {this.props.t("changeStepUser")}
          </ButtonInfo>
        )}

        {this.props.btns?.Btn_Assign_Step_User === 1 && (
          <ButtonInfo
            variant="solid"
            style={{
              height: "100%",
            }}
            onClick={() => {
              this.handleTaskAction(this.handleAssignStepUser);
            }}
          >
            {this.props.t("assignStepUser")}
          </ButtonInfo>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(ProcessingActions));
