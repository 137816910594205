import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'



class CategoryForm extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
        }
    }

    componentDidMount() {
        const { initValues } = this.props
    }


    onSectionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? 
        '/API/ADMINISTRATION/SERVICESCATEGORIES/ADD_SERVICE_CATEGORY' : 
        '/API/ADMINISTRATION/SERVICESCATEGORIES/UPD_SERVICE_CATEGORY',
        bodyData = {
            Service_Category_Name_AR: values.categoryNameAR,
            Service_Category_Name_EN: values.categoryNameEN,
            Active_Status_Id: values.status ? 1 : 0
        }

        if (this.props.editMood) {
            bodyData.Service_Category_Id = this.props.categoryId
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)
    }



    render() {
        
        let { initValues } = this.props

        const validate = values => {
            const errors = {}

            if (!values.categoryNameAR) {
                errors.categoryNameAR = `${this.props.t('error.field_required')}`
            }

            if (!values.categoryNameEN) {
                errors.categoryNameEN = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('manageCategories')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addCategory')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }
                
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues ? initValues : {
                        status: true,
                        categoryNameAR: '',
                        categoryNameEN: '',
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('categoryNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="categoryNameAR"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('categoryNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="categoryNameEN"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="status"
                                    data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                />
                            </Col>


                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(CategoryForm))
