import apiConfig from '../apiConfig';

export const deleteDepartmentJob = async (at, departmentJobId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/DEL_DEPARTMENT_JOB', 
		{
			Department_Job_Id: departmentJobId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}
