import apiConfig from '../apiConfig'

export const getServiceDealingTypes = async (at, serviceid) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/SERVICES/GET_SERVICE_DEALING_TYPES_LIST',
        {
            Service_Id: serviceid
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        for (let i in response.data.Data) {
            response.data.Data[i].Selected = response.data.Data[i].Selected === 1 ? true : false
          }
        return response.data.Data
    }

    return []
}
