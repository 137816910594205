import apiConfig from '../../apiConfig';

export const getDropdownValuesList = async (at, serviceid) => {
	
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSVALUESMANAGEMENT/Get_All_Services_Lookups_Values',
		{
			Service_Lookup_Id: serviceid,
		},
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
	);
	
	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Active_Status_Id === 1) {
				response.data.Data[i].Active_Status_Id = true;
			} else {
				response.data.Data[i].Active_Status_Id = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
