import apiConfig from "../../apiConfig";

export const deleteDepartmentTemplateAttachment = async (
	at,
	Department_Service_Version_Id,
	File_Code
) => {
	const response = await apiConfig
		.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/DELETE_DEPARTMENT_SERVICE_TEMPLATE_FILE",
			{
				Department_Service_Version_Id,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)
		.catch((error) => {
			return error.response;
		});

	return response;
};
