import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import apiConfig from '../../../apis/apiConfig';
import { ButtonDanger, ButtonSecondary, ButtonInfo, ButtonWarning } from '../../UI/Buttons'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Switch from '@material-ui/core/Switch';

import { translationHook } from '../../translationHook'
import { getTemplateVariablesList , delTemplateVar, updTemplateVarStatus } from '../../../apis/template/templates'
import { triggerNotification, triggerDialog, triggerDialogForm, setLoading, reload } from '../../../actions'
import TemplateDetailForm from './TemplateDetailForm'


class ModuleDetail extends Component {
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Active_Status_Id}
			name={`templateVar_${data.Template_Variable_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					templateVarId = data.Template_Variable_Id,
					status = data.Active_Status_Id ? 0 : 1

					this.props.setLoading(true, 'dark')

					const response = await updTemplateVarStatus(at, templateVarId, status)

					this.props.triggerNotification(true, response)
					this.props.setLoading(false)

			}}
			/>
        )
        
    }

    state = {
        reload: false
    }

    handleDelete = (data) => {
        let at = this.props.currentUser.Data.access_token,
        templateId = data.Template_Variable_Id,
        serviceDetailName = this.props.i18n.language === 'ar' ? data.Template_Variable_Name_AR : data.Template_Variable_Name_EN,
        dialogTitle = `${this.props.t('confirmDeleteTitle')} ${serviceDetailName}`,
        dialogMsg = this.props.t('confirmDeleteMsg')

        this.props.triggerDialog(true, {
            title: dialogTitle,
            message: dialogMsg,
            action: {
                action: delTemplateVar,
                params: [
                    at,
                    templateId
                ],
                text: this.props.t('delete')
            }
        })

    }

    renderEditForm = (...params) => {
        return <TemplateDetailForm templateId={this.props.moduleId} reloadDatagrid={() => this.setState({reload: !this.state.reload})} editMood={this.props.editMood} viewMood={this.props.viewMood} templateVarId={params[0]} initValues={params[1]} />
    }

    handleEdit = (data) => {
        let initValues = {
            tempVarId: data.Template_Variable_No,
            valueNameAR: data.Template_Variable_Name_AR,
            valueNameEN: data.Template_Variable_Name_EN,
            valueCode: data.Template_Variable_Code,
            required: data.Active_Status_Id ? true : false,
            detailType: data.Template_Variable_Type_Id,
            serviceLookups: data.Template_Variable_Lookup_Id,
            Service_Detail_Predefined_Field_Id: data?.Service_Detail_Predefined_Field_Id
        },
        templateVarId = data.Template_Variable_Id,
        serviceDetailName = this.props.i18n.language === 'ar' ? data.Template_Variable_Name_AR : data.Template_Variable_Name_EN,
        dialogTitle = `${this.props.viewMood ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${serviceDetailName}`

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                templateVarId,
                initValues
            ]
        })
    }
    setOrderUp = async (varID) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/TEMPLATES/SET_TEMPLATE_VARIABLE_ORDER_UP',
            {
				Template_Variable_Id: varID
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`,
                }
            }
        )

        this.props.setLoading(false)
        this.props.reload()
    }

    setOrderDown = async (varID) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/TEMPLATES/SET_TEMPLATE_VARIABLE_ORDER_DOWN',
            {
                Template_Variable_Id: varID
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`,
                }
            }
        )

        this.props.setLoading(false)
        this.props.reload()
    }
	renderOrderButtons = ({data}) => {
        return (
            <div className="d-flex flex-column align-items-center order-btns">
                <IconButton onClick={() => this.setOrderUp(data.Template_Variable_Id)}>
                    <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton onClick={() => this.setOrderDown(data.Template_Variable_Id)}>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </div>
        )
    }


    render() {
        
        let columnAlign = 'left';

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'

        }
        
        let at = this.props.currentUser.Data.access_token

        const jsonDataSource = new CustomStore({
            key: 'Template_Variable_Id',
            load: async () => {
               return getTemplateVariablesList(at, this.props.moduleId)
            }
        });

        return(
            <Row>
                {!this.props.viewMood &&
                <Col xs={12} className='mb-5'>
                    <TemplateDetailForm 
                    templateId={this.props.moduleId} reloadDatagrid={() => this.setState({reload: !this.state.reload})} />
                </Col>
                }

                <Col xs={12}>
                    <DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>
                        <Column cssClass="outlinedColumn" fixed={true} fixedPosition={this.props.i18n.language === 'ar' ? 'right' : 'left'} alignment="center" cellRender={this.renderOrderButtons} width={'70px'}>
                        </Column>
                        <Column dataField={'Template_Variable_No'} caption={this.props.t('ModuleValNo')} alignment={'center'}>
                        </Column>
                    
                        <Column dataField={'Template_Variable_Name_AR'} caption={this.props.t('ModuleValuesAR')} alignment={columnAlign}>
                        </Column>
                        <Column dataField={'Template_Variable_Name_EN'} caption={this.props.t('ModuleValuesEN')} alignment={columnAlign}>
                        </Column>

                        <Column dataField="Active_Status_Id" 
                        caption={this.props.t('serviceStatus')} 
                        allowFiltering={false}
                        cellRender={this.customizeCellCheckbox}
                        alignment={'center'}
                        >
                        </Column>

                        <Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
                            {!this.props.viewMood && [
                            <Button
                            render={(record) => {
                                return (
                                    
                                    <ButtonWarning
                                    variant="circleIcon"
                                    startIcon={<EditIcon />}
                                    onClick={() => this.handleEdit(record.data)}
                                    >
                                        {/* {this.props.t('edit')} */}
                                    </ButtonWarning>
                                )
                            }}
                            />,
                            
                            <Button
                                render={(record) => {
                                    return (
                                        
                                        <ButtonDanger
                                        variant="circleIcon"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(record.data)}
                                        >
                                            {/* {this.props.t('delete')} */}
                                        </ButtonDanger>
                                    )
                                }}
                            />
                            ]}

                            {this.props.viewMood &&
                            <Button
                                render={(record) => {
                                    return (
                                        
                                        <ButtonInfo
                                        variant="circleIcon"
                                        startIcon={<VisibilityIcon />}
                                        onClick={() => this.handleEdit(record.data)}
                                        >
                                            {/* {this.props.t('view')} */}
                                        </ButtonInfo>
                                    )
                                }}
                            />
                            }

                        </Column>

                    </DataGrid>

                </Col>
            </Row>
        )
    }
    onExporting(e) {
		let sheetTitle = this.props.t('moduleValues')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }

}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, setLoading, triggerNotification, reload })(translationHook(ModuleDetail))