import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';

import { getNotificationList } from '../../../apis/system/managenotifications/getNotificationList';
import { deleteNotification } from '../../../apis/system/managenotifications/deleteNotification'
import { triggerDialog, triggerDialogForm, setLoading, triggerNotification  } from '../../../actions';
import { translationHook } from '../../translationHook';
import {
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning
} from '../../UI/Buttons';
import NotificationForm from './NotificationForm'
import NotificationUpdateForm from './NotificationUpdateForm'

class NotificationList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	renderForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
			editMood = params[2] === 'edit' ? true : false

        return <NotificationUpdateForm notificationId={params[0]} initValues={params[1]} editMood={editMood} viewMood={viewMood} />
    }


	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			notificationId = data.Notification_Id,
			name =
				this.props.i18n.language === 'ar'
					? data.Notification_Name_AR
					: data.Notification_Name_EN,
			dialogTitle = `${this.props.t('confirmDeleteTitle')} ${name}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteNotification,
				params: [
					at,
					notificationId,
				],
				text: this.props.t('delete'),
			},
		});
		
	}

	handleEdit = (data, viewMood) => {
        let notificationId = data.Notification_Id,
        notificationName = this.props.i18n.language === 'ar' ? data.Notification_Name_AR : data.Notification_Name_EN,
        dialogTitle = `${viewMood === 'view' ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${notificationName}`

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderForm,
            params: [
                notificationId
            ]
        })

    }

	render() {
		let columnAlign = 'left',
			NameKey = 'Notification_Name_EN',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right'
			NameKey = 'Notification_Name_AR'
		}

		const jsonDataSource = new CustomStore({
			key: 'Notification_Id',
			load: () => {
				return getNotificationList(at);
			},
		});

		return (
			<>
				<NotificationForm />
				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
					dataField={NameKey}
					caption={this.props.t('NotificationName')}
					alignment={columnAlign}
					>
					</Column>
                    <Column 
                        dataField="Enable_SMS" 
                        caption={this.props.t('smsNot')}
                        allowFiltering={false}>
                    </Column>
                    <Column 
                        dataField="Enable_Mail" 
                        caption={this.props.t('mailNot')}
                        allowFiltering={false}>
                    </Column>
                    <Column 
                        dataField="Enable_Web" 
                        caption={this.props.t('webNot')}
                        allowFiltering={false}>
                    </Column>
				
					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
									variant='circleIcon'
									startIcon={<EditIcon />}
									onClick={() => {
                                        this.handleEdit(record.data, 'edit')
                                    }}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
                                    record.data.Can_Delete ? 
                                        <ButtonDanger
                                            variant='circleIcon'
                                            startIcon={<DeleteIcon />}
                                            onClick={() => this.handleDelete(record.data)}>
                                            {/* {this.props.t('delete')} */}
                                        </ButtonDanger>
                                        : 
                                        <></>
                                    );
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	onExporting(e) {
		let sheetTitle = this.props.t('manageNotifications')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, triggerNotification, setLoading })(
	translationHook(NotificationList)
);
