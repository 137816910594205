import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../translationHook';
import apiConfig from '../../apis/apiConfig';
import { ButtonPrimary, ButtonSecondary } from '../UI/Buttons';
import { Row, Col } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from '../../actions';
import TaskAttachments from '../assignedTasks/TaskAttachments';
import style from '../system/accounts/account.module.scss';
import { openMission, compeleteMission } from '../../apis/tasks/myTasks';
import EditIcon from '@material-ui/icons/Edit';
import NoteForm from './NoteForm';
import moment from 'moment';
class TaskForm extends Component {
	state = {
		initValues: {},
		showForm: false,
		compelete: false
	};

	componentDidMount() {
		let { initValues } = this.props;
		if(initValues) {
			this.setState({initValues: initValues});			
		}


	}

	onSubmit =  ({compelete}) => async (values) => {
		const auth = this.props.currentUser.Data.access_token,
			data = {
				Mission_Id: this.props.missionID,
				Mission_Response_Description: values.Mission_Response_Description,
			};
		this.props.setLoading(true, 'dark');

		const url = compelete ? 'API/ADMINISTRATION/MISSIONS/COMPELET_MISSION'
		:'API/ADMINISTRATION/MISSIONS/UPD_MISSION_RESPONSE';
		const response = await apiConfig
			.post(url, data, {
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			})
			.then((res) => {
				this.props.triggerNotification(true, res.data);
				return res;
			})
			.catch((error) => {
				console.log(error)
				return error.response;
			});
			console.log(response)
			if(!response?.Status) {
				this.props.triggerNotification(true, response.data);
			}
		this.props.setLoading(false);
		this.props.triggerDialogForm(false);
	};

	handleProcess = async () => {
		let { initValues } = this.props;

		const response = await openMission(
			initValues.Mission_Id,
			this.props.currentUser.Data.access_token
		);
		this.props.triggerNotification(true, response.data);
		this.setState({
			initValues: {
				...this.state.initValues,
				Mission_Type_Id: 2
			}
		})
	};

	renderCompelete = (...params) => {
		return (
			<div className='p-5 text-start'>
				<NoteForm missionID={params[0]} status={params[1]} />
			</div>
		);
	};

	triggerAttach = async () => {
		let { Mission_Id } = this.props.initValues;
		this.props.triggerDialogForm(true, {
			title: this.props.t('addAttachments'),
			renderForm: this.renderCompelete,
			params: [Mission_Id, 'attchments'],
		});
	}
	validate = values => {
		const errors = {};

		if (!values.Mission_Response_Description) {
			errors.Mission_Response_Description = `${this.props.t('error.field_required')}`
		}

		return errors
	}

	render() {
		let { initValues } = this.props;
		return (
			<>
				<Form
					subscription={{ submitting: true, initialValues: true }}
					onSubmit={this.onSubmit({compelete: this.state.compelete})}
					initialValues={this.props.initValues}
					validate={this.validate}
					render={({ handleSubmit, form, submitting, pristine, values }) => {
						return (
							<form
								onSubmit={handleSubmit}
								className={`row mt-4 mx-3`}
								noValidate>
								<Col xs={6} md={4}>
									<TextField
										className={'read-only mb-2'}
										name='Mission_Date'
										disabled
										label={this.props.t('missionDate')}
										value={moment(initValues?.Mission_Date).format(
											'HH:mm YYYY-MM-DD'
										)}
									/>
								</Col>
								<Col xs={6} md={4}>
									<TextField
										className={'read-only mb-2'}
										name={
											this.props.i18n.language === 'ar'
												? 'Mission_from_User_Name_AR'
												: 'Mission_from_User_Name_EN'
										}
										label={this.props.t('missionFrom')}
										disabled
									/>
								</Col>
								<Col xs={6} md={4}>
									<TextField
										className={'read-only mb-2'}
										name={
											this.props.i18n.language === 'ar'
												? 'Mission_Type_Name_AR'
												: 'Mission_Type_Name_EN'
										}
										label={this.props.t('missionType')}
										disabled
									/>
								</Col>
								<Col xs={6} md={12}>
									<TextField
										className={'read-only mb-2'}
										name={'Mission_Title'}
										label={this.props.t('missionTitle')}
										disabled
									/>
								</Col>
								<Col xs={6} md={12}>
									<TextField
										className={'read-only mb-2'}
										name={'Mission_Description'}
										label={this.props.t('missionDesc')}
										multiline
										rows={4}
										disabled
									/>
								</Col>
								<Col xs={6} md={12}>
									<TaskAttachments
										view={true}
										missionID={initValues.Mission_Id}
									/>
								</Col>
								{this.state.initValues.Mission_Type_Id !== 1 && (
									<>
									<Col xs={6} md={6} className="mt-2">
										<TextField
											label='ملاحظات'
											className='mb-2'
											name='Mission_Response_Description'
											required={true}
											autoComplete='off'
											multiline
											rows={5}
											showError={({ meta: { submitFailed, invalid } }) => {
												return submitFailed && invalid
											}}
										/>
									</Col>
									<Col xs={6} md={12}>
									<h6 className="text-start mb-3">
										{this.props.t('taskAttachmentsResponse')}
									</h6>
									<TaskAttachments response={true} missionID={initValues.Mission_Id}  />
									</Col>
									</>
								)}
								<div
									className={`${style.actionsWrap} d-flex align-items-center`}>
									{this.state.initValues.Mission_Type_Id === 1 ? (
										<ButtonSecondary
											variant='outlined'
											onClick={this.handleProcess}>
											<EditIcon />
											{this.props.t('handleTask')}
										</ButtonSecondary>
									) : this.state.initValues.Mission_Type_Id === 3 ? (
										<p className='text-danger'>{this.props.t('taskDone')}</p>
									) : (
										<>
											<ButtonPrimary variant='contained' type='submit'
											onClick={() => {
												this.setState({
													compelete: false
												})
											}}
											>
												{this.props.t('save')}
											</ButtonPrimary>
											{/* <ButtonSecondary
												variant='outlined'
												onClick={this.triggerAttach}
												>
												{this.props.t('addAttachments')}
											</ButtonSecondary> */}
											<ButtonPrimary
												color='primary'
												variant='outlined'
												onClick={() => {
													this.setState({
														compelete: true
													})
												}}
												type="submit"
												>
												{this.props.t('compeleteTask')}
											</ButtonPrimary>
										</>
									)}
								</div>
							</form>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(TaskForm));
