import apiConfig from '../../apiConfig';

export const addUserGroupScreen = async (at, data) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/GROUPS/ADD_USERS_GROUP_SCREEN', data ,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    return response.data
};
