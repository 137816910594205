import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store'
import Switch from '@material-ui/core/Switch';

import { getUSerGrouppScreenList } from '../../../apis/system/userGroup/getScreensList'
import { getGroupDetailsScreen } from '../../../apis/system/userGroup/getGroupDetailsScreen'
import { addUserGroupScreen } from '../../../apis/system/userGroup/addUserGroupScreen'
import { deleteUserGroupScreen } from '../../../apis/system/userGroup/deleteUserGroupScreen'
import { updateUserGroupScreen } from '../../../apis/system/userGroup/updateUserGroupScreen'
import { triggerNotification, setLoading, triggerDialogForm, getUserDetails } from '../../../actions'
import { translationHook } from '../../translationHook'


class UserGroupsScreenList extends Component {
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
    state = {
        editable: false
    }


    customizeCellControl = ({data}) => {
        return (
            <div class="d-flex">
                <div className="flex-grow-1 text-center">
                    <p>
                        {this.props.t('canView')}
                    </p>
                    <Switch
			checked={data.Selected}
			name={`status_${data.User_Screen_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
                    userID = this.props.currentUser.Data.User_Id,
					status = data.Selected,
                    screenId = data.User_Screen_Id,
                    groupId = this.props.userGroupId,
                    response
                    this.props.setLoading(true, 'dark')

                    const formData = {
                        User_Group_Id: groupId,
                        User_Screen_Id: screenId,
                        Can_Add: data.Can_Add ? 1 : 0,
                        Can_Update: data.Can_Update ? 1 : 0,
                        Can_Delete: data.Can_Delete ? 1 : 0,
                    }

                    if (status) {
                        response = await deleteUserGroupScreen(at , data.User_Group_Screen_Id)
                    } else {
                        response = await addUserGroupScreen(at, formData)
                    }
                    // let { cookies } = this.props,
                    // token = cookies.get('wstoken', { doNotParse: true })
                    

                    this.props.getUserDetails({ token: at, userID })

					this.props.triggerNotification(true, response)

					this.props.setLoading(false)
                    this.setState({
                        editable: true
                    })
			}}
			/>
                </div>
                {/* <div className="flex-grow-1 text-center">
                    <p>
                        {this.props.t('canAdd')}
                    </p>
                    <Switch
                    checked={data.Can_Add}
                    name={`add_${data.User_Screen_Id}`}
                    onChange={async (e) => {
                        if(data.Selected) {
                        let at = this.props.currentUser.Data.access_token,
                            status = data.Can_Add,
                            userGroupScreenId = data.User_Group_Screen_Id,
                            response
                            this.props.setLoading(true, 'dark')

                            const formData = {
                                User_Group_Screen_Id: userGroupScreenId,
                                Can_Add: !status ? 1 : 0,
                                Can_Update: data.Can_Update ? 1 : 0,
                                Can_Delete: data.Can_Delete ? 1 : 0
                            }
                            if(status) {
                                response = await updateUserGroupScreen(at , formData)
                            } else {
                                response = await updateUserGroupScreen(at , formData)
                            }

                            this.props.triggerNotification(true, response)
                            this.props.setLoading(false)
                        }

                    }}
                    />
                </div>
                <div className="flex-grow-1 text-center">
                    <p>
                        {this.props.t('canUpdate')}
                    </p>
                    <Switch
                    checked={data.Can_Update}
                    name={`update_${data.User_Screen_Id}`}
                    onChange={async (e) => {
                        if(data.Selected) {
                        let at = this.props.currentUser.Data.access_token,
                            status = data.Can_Update,
                            userGroupScreenId = data.User_Group_Screen_Id,
                            response
                            this.props.setLoading(true, 'dark')

                            // User_Group_Id
                            const formData = {
                                User_Group_Screen_Id: userGroupScreenId,
                                Can_Add: data.Can_Add ? 1 : 0,
                                Can_Update: !status ? 1 : 0,
                                Can_Delete: data.Can_Delete ? 1 : 0
                            }
                            if(status) {
                                response = await updateUserGroupScreen(at , formData)
                            } else {
                                response = await updateUserGroupScreen(at , formData)
                            }

                            this.props.triggerNotification(true, response)
                            this.props.setLoading(false)
                        }
                    }}
                    />
                </div>
                <div className="flex-grow-1 text-center">
                    <p>
                        {this.props.t('canDelete')}
                    </p>
                    <Switch
                    checked={data.Can_Delete}
                    name={`delete_${data.User_Screen_Id}`}
                    onChange={async (e) => {
                        if(data.Selected) {
                        let at = this.props.currentUser.Data.access_token,
                            status = data.Can_Delete,
                            userGroupScreenId = data.User_Group_Screen_Id,
                            response
                            this.props.setLoading(true, 'dark')

                            // User_Group_Id
                            const formData = {
                                User_Group_Screen_Id: userGroupScreenId,
                                Can_Add: data.Can_Add ? 1 : 0,
                                Can_Update: data.Can_Update ? 1 : 0,
                                Can_Delete: !status ? 1 : 0
                            }
                            if(status) {
                                response = await updateUserGroupScreen(at , formData)
                            } else {
                                response = await updateUserGroupScreen(at , formData)
                            }

                            this.props.triggerNotification(true, response)
                            this.props.setLoading(false)
                        }
                    }}
                    />
                </div> */}
            </div>
        )
    }


    render() {
        let columnAlign = 'left',
        NameKey = 'User_Screen_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            NameKey = 'User_Screen_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'User_Group_Screen_Id',
            load: () => {
                if(this.props.mood === 'edit' || this.state.editable) {
                    return getGroupDetailsScreen(at, this.props.userGroupId)
                } else {
                    return getUSerGrouppScreenList(at , this.props.userGroupId)
                }
            }
        });
        return(
            <>

            <div className="container-fluid pt-5">
                
                <DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

                    <Column dataField={NameKey} caption={this.props.t('screens')} alignment={columnAlign}>
                    </Column>
                        {/* <Column
                            dataField='Selected'
                            caption={this.props.t('seeScreen')}
                            allowFiltering={false}
                            cellRender={this.customizeCellCheckbox}
                            >
                        </Column> */}
                        <Column
                            caption={this.props.t('control')}
                            allowFiltering={false}
                            cellRender={this.customizeCellControl}
                        /> 
                </DataGrid>
            </div>
            </>
        )
    }
    onExporting(e) {
		let sheetTitle = this.props.t('userTypeName')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm, getUserDetails })(translationHook(UserGroupsScreenList))