import { TRIGGER_NOTIFICATION } from '../actions/types'

const INIT_NOTIFICATION = {
    Open: false,
    success: null,
    error: null,
    messages: null
}

const notificationReducer = (status = INIT_NOTIFICATION, action) => {

    switch (action.type) {
        case TRIGGER_NOTIFICATION:

            return {...status, ...action.payload}


        default:
            return status
    }

}

export default notificationReducer