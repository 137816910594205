import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Select } from "mui-rff";
import apiConfig from "../../apis/apiConfig";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../actions";
import { translationHook } from "../translationHook";
import { ButtonPrimary } from "../UI/Buttons";
import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
} from "devextreme-react/data-grid";

class StructuralAuditing extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef(null);
  }

  state = {
    areas: [],
    orders: [],
    buildingPermitYear: "",
  };

  onSubmit = async (values, form) => {
    const at = this.props.currentUser.Data.access_token,
      url = "/API/REPORTS/REQUESTS/GET_PROJECT_EXECUTIVE_FILE",
      bodyData = {
        Project_No: values.projectNo,
        Area_Id: values.areaNumber,
        Plot_No: values.plotNo,
        Buidling_Permission_No: values.buildingPermitNo,
        Buidling_Permission_Year: values.buildingPermitYear,
      };

    this.props.setLoading(true, "dark");

    const response = await apiConfig.post(url, bodyData, {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    });

    // this.props.triggerNotification(true, response.data)

    this.props.setLoading(false);

    if (response.data.Status) {
      // form.restart()
      this.setState({ orders: response.data.Data });
    }
  };

  validate = (values) => {
    const errors = {};

    if (!values.areaNumber) {
      errors.areaNumber = `${this.props.t("error.field_required")}`;
    }
    if (!values.plotNo) {
      errors.plotNo = `${this.props.t("error.field_required")}`;
    }
    if (!values.buildingPermitNo) {
      errors.buildingPermitNo = `${this.props.t("error.field_required")}`;
    }
    if (!values.buildingPermitYear) {
      errors.buildingPermitYear = `${this.props.t("error.field_required")}`;
    }

    return errors;
  };

  getAreas = async () => {
    let at = this.props.currentUser.Data.access_token,
      areasArr = [];
    const response = await apiConfig.post(
      "API/SYSTEM/DDL/GET_AREAS",
      {},
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Area_Name_AR
            : item.Area_Name_EN;

        areasArr.push({ value: item.Area_Id, label });
      });

      this.setState({ areas: areasArr });
    }
  };

  componentDidMount() {
    this.getAreas();
  }

  onRequestPrint = async () => {
    let at = this.props.currentUser.Data.access_token,
      values = this.form.current;

    this.props.setLoading(true, "dark");

    const response = await apiConfig
      .post(
        "/API/REPORTS/REQUESTS/PRINT_PROJECT_EXECUTIVE_FILE_REPORT",
        {
          Project_No: values.projectNo,
          Area_Id: values.areaNumber,
          Plot_No: values.plotNo,
          Buidling_Permission_No: values.buildingPermitNo,
          Buidling_Permission_Year: values.buildingPermitYear,
        },
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
          responseType: "arraybuffer",
        }
      )
      .catch((error) => {
        return error.response;
      });

    if (response.status === 200) {
      let contentType = response.headers["content-type"],
        typesArray = [
          "application/pdf",
          "image/bmp",
          "image/gif",
          "image/vnd.microsoft.icon",
          "image/jpeg",
          "image/png",
          "image/svg+xml",
          "image/tiff",
          "image/webp",
        ],
        url = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        ),
        link = document.createElement("a");

      link.href = url;

      typesArray.includes(contentType)
        ? link.setAttribute("target", "blank")
        : link.setAttribute("download", null);

      document.body.appendChild(link);
      link.click();
      this.props.setLoading(false);
    }
  };

  render() {
    let departmentName = "Department_Name_AR",
      clientName = "Client_Name_AR",
      requestExecUserName = "Request_Execution_User_Name_AR",
      requestStatusName = "Request_Status_Name_AR";

    if (this.props.i18n.language === "en") {
      departmentName = "Department_Name_EN";
      clientName = "Client_Name_EN";
      requestExecUserName = "Request_Execution_User_Name_EN";
      requestStatusName = "Request_Status_Name_EN";
    }

    return (
      <>
        <Row>
          <Col className="d-flex justify-content-between align-items-center mb-4">
            <h3>{this.props.t("structuralAuditing")}</h3>
          </Col>
        </Row>

        <Form
          onSubmit={this.onSubmit}
          validate={this.validate}
          render={({
            setFieldValue,
            handleSubmit,
            submitting,
            form,
            values,
            pristine,
          }) => {
            this.form.current = values;
            return (
              <form
                id="structuralAuditing"
                onSubmit={handleSubmit}
                noValidate
                className="w-100"
              >
                <Row>
                  <Col xs={12} md={3}>
                    <TextField
                      name="projectNo"
                      label={this.props.t("projectNo")}
                    />
                  </Col>

                  <Col xs={12} md={3}>
                    <Select
                      label={this.props.t("areaNumber")}
                      name="areaNumber"
                      data={this.state.areas}
                      // data={this.state.municipalList}
                      onChange={(e) => {
                        form.change("areaNumber", e.target.value);
                      }}
                    ></Select>
                  </Col>

                  <Col xs={12} md={3}>
                    <TextField name="plotNo" label={this.props.t("plotNo")} />
                  </Col>

                  <Col xs={12} md={3}>
                    <TextField
                      name="buildingPermitNo"
                      label={this.props.t("buildingPermitNo")}
                    />
                  </Col>

                  <Col xs={12} md={3}>
                    <label>
                      <span className={"text-danger"}>
                        {this.props.t("common:dateFormatRequest")}*
                      </span>
                    </label>
                    <TextField
                      name="buildingPermitYear"
                      label={this.props.t("buildingPermitYear")}
                      type="text"
                      value={this.state.buildingPermitYear}
                      placeholder="____-__-__"
                      autoComplete="false"
                      onChange={(e) => {
                        let val = e.target.value.replace(/[^0-9]/g, "");
                        if (val.length > 8) return;
                        val = val.split("-").join("");
                        if (val.length > 0) {
                          val =
                            val.substring(0, 4) +
                            "-" +
                            val.substring(4, 6) +
                            "-" +
                            val.substring(6, 8);
                        }
                        this.setState({ buildingPermitYear: val });
                        if (val.length == 10) {
                          form.change("buildingPermitYear", val);
                        } else {
                          form.change("buildingPermitYear", "");
                        }
                      }}
                    />
                  </Col>

                  <Col xs={12} md={3} className="text-center mt-2">
                    <ButtonPrimary
                      color="primary"
                      variant="contained"
                      disabled={
                        !values.areaNumber ||
                        !values.plotNo ||
                        !values.buildingPermitNo ||
                        !values.buildingPermitYear
                      }
                      type="submit"
                    >
                      {this.props.t("search")}
                    </ButtonPrimary>
                  </Col>
                </Row>
              </form>
            );
          }}
        />

        <Row>
          <Col>
            <DataGrid
              className={[
                this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
                "globalBox",
              ].join(" ")}
              id="dataGrid"
              width={"100%"}
              keyExpr={"Request_Id"}
              dataSource={this.state.orders}
              showBorders={false}
              showColumnLines={false}
              showRowLines={true}
              allowColumnResizing={true}
              columnResizingMode={"widget"}
              allowColumnReordering={true}
              rowAlternationEnabled={false}
              focusedRowEnabled={true}
              onExporting={this.onExporting}
              columnWidth={"auto"}
              wordWrapEnabled={true}
              rtlEnabled={this.props.i18n.language === "ar" ? true : false}
            >
              <ColumnChooser
                enabled={true}
                allowSearch={true}
                search={"fff"}
                title={this.props.t("dxColumnChooserTitle")}
                emptyPanelText={this.props.t("dxColumnChooserPanelText")}
              />

              <SearchPanel
                visible={true}
                width={240}
                placeholder={this.props.t("search")}
              />

              <FilterRow visible={true} />

              <HeaderFilter visible={true} />

              <Export enabled={true} allowExportSelectedData={true} />

              <GroupPanel
                visible={true}
                emptyPanelText={this.props.t("dxGroupPanelText")}
              />

              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />

              <Column
                dataField={"Request_No"}
                caption={this.props.t("requestNo")}
                alignment={"center"}
              ></Column>

              <Column
                dataField={"Request_Date"}
                caption={this.props.t("requestDate")}
                alignment={"center"}
              ></Column>

              <Column
                dataField={departmentName}
                caption={this.props.t("DepartmentName")}
                alignment={"center"}
              ></Column>

              <Column
                dataField={clientName}
                caption={this.props.t("applicant")}
                alignment={"center"}
              ></Column>

              <Column
                dataField={requestExecUserName}
                caption={this.props.t("municipalEngineer")}
                alignment={"center"}
              ></Column>

              <Column
                dataField={requestStatusName}
                caption={this.props.t("serviceRequestStatus")}
                alignment={"center"}
              ></Column>

              <Column
                dataField={"Request_Execution_Result"}
                caption={this.props.t("requestResult")}
                alignment={"center"}
              ></Column>
            </DataGrid>
          </Col>
        </Row>

        <Row className="justify-content-end align-item-center mt-5">
          <Col xs={12} md={3} className="d-flex justify-content-end">
            <ButtonPrimary
              color="primary"
              variant="contained"
              onClick={this.onRequestPrint}
              disabled={!this.state.orders.length}
              type="submit"
            >
              {this.props.t("print")}
            </ButtonPrimary>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(StructuralAuditing));
