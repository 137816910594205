import apiConfig from '../../apiConfig';

export const setDepartmentCentersStatus = async (at, departmentSectionId, status) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/SET_DEPARTMENT_CENTER_STATUS',
		{
            Department_Service_Center_Id: departmentSectionId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);

    return response.data;

}
