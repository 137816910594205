import apiConfig from '../../apiConfig'

export const deleteDropdown = async (at, serviceid) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSMANAGEMENT/Del_Services_Lookups',
        {
            Service_Lookup_Id: serviceid
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
