import apiConfig from '../../apiConfig'
export const getDecisionCategories = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/GET_DECISION_CATEGORIES_LIST',
		{
			pageCount: 10000,
			PageIndex: 1
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	return response.data.Data.Data;
};
export const getDecisionsList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/GET_DECISIONS_LIST',
		{
			pageCount: 10000,
			PageIndex: 1
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	return response.data.Data.Data;
};
export const setDecisionCategoryStatus = async (at , data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/Upd_Decision_Category_Status',data,
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	return response.data;
};
export const setDecisionStatus = async (at , data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/UPD_DECISION_STATUS',data,
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	return response.data;
};
export const deleteDecisionCategory = async (at, id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/DEL_DECISION_CATEGORY', 
		{
			Decision_category_Id: id
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}
export const deleteDecision = async (at, id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/DEL_DECISION', 
		{
			Decision_Id: id
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}
