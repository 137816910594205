import apiConfig from '../../apiConfig';

export const deleteDepartmentCenter = async (at, departmentSectionId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/DEL_DEPARTMENT_CENTER', 
		{
			Department_Service_Center_Id: departmentSectionId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data.Data;
}
