import apiConfig from '../apiConfig'

export const getCreatedAccounts = async (at, statusId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/CLIENTPROFILE/CLIENTS_PROFILES_MANAGEMENT_LIST', 
        {
            PageIndex: 1,
            PageCount: 10000,
            Status_id: statusId
        },{
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data.Data
    }

    return []
}
