import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../apis/apiConfig'
import { Row, Col } from 'react-bootstrap'  
import { translationHook } from '../../translationHook'
import { triggerDialog, triggerNotification, 
    triggerDialogForm, setLoading, reload } from '../../../actions'
import { ButtonPrimary, ButtonSecondary } from './../../UI/Buttons';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileUploader from 'devextreme-react/file-uploader';
import AddModuleGallery from './AddModuleGallery';
class ModuleGallery extends Component {
    constructor(props){
		super(props);
	}
    state = {
        reload: false,
        images: [],
    }
    handleAddFiles = (e) => {
        // const urls = [e.target.files].map(item => window.URL.createObjectURL(item))
        // urls
		this.setState({
			files: e.target.files,
            images: {},
			fileName: e.target.files[0].name,
		});
	};
    uploadImages = async () => {
        let at = this.props.currentUser.Data.access_token,
        response = await apiConfig.post(
            '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/ADD_APPROVING_CHART_IMAGE',
            {
                Approving_Chart_Id: this.props.moduleID
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`,
                }
            }
        );
    }
    componentDidMount() {
        this.getImagesList()
    }
    
    getImagesList = async () => {
            let at = this.props.currentUser.Data.access_token;
            const response = await apiConfig.post(
                '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/GET_APPROVING_CHART_IMAGE_LIST',
                {
                    Approving_Chart_Id: this.props.moduleID
                },
                {
                    headers: {
                        Authorization: `Bearer ${at}`,
                    }
                }
            )

            this.setState({
                images: response.data.Data
            })
    }

    render() {
        console.log(this.props)
        return(
            <>
                {!this.props.viewMood && (
                    <AddModuleGallery viewMood={this.props.viewMood} moduleID={this.props.moduleID} onAddItem={this.getImagesList}/> 
                    )
                }
                <Row>
                    <Col md={6}>
                        {this.state.images && (
                            this.state.images.map(image => (
                        <ListItem className="shadow-sm">
                            <ListItemAvatar>
                                <CropOriginalIcon />
                            </ListItemAvatar>
                            <ListItemText primary={image.Approving_Chart_Image_Name}/>
                            <IconButton
                                    edge='start'
                                    aria-label='download'
                                    onClick={() => {
                                        window.open(image.Approving_Chart_Image_Url !== '' ? image.Approving_Chart_Image_Url : image , '_blank');
                                    }}
                                    >
                                    <GetAppIcon />
                                </IconButton>
                                {!this.props.viewMood && (
                                    <>
                                    <IconButton 
                                    edge="center" color={image.Active_Status_Id === 1 ? 'secondary' : ''} aria-label="download" onClick={ 
                                        async () => {
                                        let at = this.props.currentUser.Data.access_token;
                                        this.props.setLoading(true, 'dark', 3000)
                                        const response = await apiConfig.post(
                                            '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/UPD_APPROVING_CHART_IMAGE_STATUS',
                                            {
                                                Approving_Chart_Image_Id: image.Approving_Chart_Image_Id,
                                                Active_Status_Id: image.Active_Status_Id === 1 ? 0 : 1 
                                            },
                                            {
                                                headers: {
                                                    Authorization: `Bearer ${at}`,
                                                }
                                            }
                                        )
                                        this.props.triggerNotification(true, response.data)
                                        this.props.setLoading(false)
                                        this.getImagesList()
                                    }}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="download" onClick={
                                        async () => {
                                            let at = this.props.currentUser.Data.access_token;
                                            this.props.setLoading(true, 'dark', 3000)
                                            const response = await apiConfig.post(
                                                '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/DEL_APPROVING_CHART_IMAGE',
                                                {
                                                    Approving_Chart_Image_Id: image.Approving_Chart_Image_Id,
                                                },
                                                {
                                                    headers: {
                                                        Authorization: `Bearer ${at}`,
                                                    }
                                                }
                                            )
                                            this.props.triggerNotification(true, response.data)
                                            this.props.setLoading(false)
                                                this.getImagesList()
                                        }
                                        }>
                                    <DeleteIcon />
                                    </IconButton>

                                    </>

                                )}
                            </ListItem>
                            )) 
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerDialog,triggerNotification, triggerDialogForm, setLoading, reload })(translationHook(ModuleGallery))