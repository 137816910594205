import apiConfig from './apiConfig';

export const getProfileData = async (at) => {
    const response = await apiConfig.post(
    '/API/SYSTEM/USERS/PROFILES/GET_PROFILE_DATA', {},
    {
        headers: {
            Authorization: `Bearer ${at}`,
        },
    }
    );
    return response.data
}
