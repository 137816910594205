import apiConfig from "../../apiConfig";

export const updateDepartmentTemplateAttachmentList = async (
	at,
	Template_Id,
	Department_Service_Template_File_Ids_List,
	file
) => {
	const bodyFormData = new FormData(),
		url =
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPDATE_LIST_DEPARTMENT_SERVICES_TEMPLATES_FILES",
		bodyData = {
			Department_Service_Template_File_Ids_List,
			Template_Id,
		};

	bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

	bodyFormData.append("Attached_File", file);

	const response = await apiConfig.post(url, bodyFormData, {
		headers: {
			Authorization: `Bearer ${at}`,
		},
	});

	return response;
};
