import apiConfig from '../../apiConfig';
export const setDepartmentServiceTemplatesSigns = async (at, departmentServiceVersionId, templateId, userSignState, reviewSignState, approvalSignState, QRCodeState) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPDATE_DEPARTMENT_SERVICE_TEMPLATE_SIGNS', {
            Department_Service_Version_Id: departmentServiceVersionId,
            Template_Id: templateId,
            Enable_User_Sign: userSignState,
            Enable_Review_Sign: reviewSignState,
            Enable_Approval_Sign: approvalSignState,
            Enable_QR_Code: QRCodeState
        }, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
