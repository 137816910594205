import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { DataGrid,
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    Selection
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import Checkbox from '@material-ui/core/Checkbox';

import { getDepartmentVersionUnselectedStepList } from '../../../apis/services/departments/getDepartmentVersionUnselectedStepList'
import { saveDepartmentVersionSelectedStep } from '../../../apis/services/departments/saveDepartmentVersionSelectedStep'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'
import { ButtonPrimary } from '../../UI/Buttons'


class ServiceVersionUnselectedStep extends Component {

    constructor(props) {
        super(props);
 
        this.dataGridRef = React.createRef();
 
    }


    customizeCellCheckbox = (data) => {
        return (
            <Checkbox
                checked={data.row.isSelected}
                color="primary"
                inputProps={{ 'aria-label': 'select service checkbox' }}
                onChange={(e)=>{
                    let dataGridRef = this.dataGridRef.current.instance
                    
                    if ( e.target.checked ) {
                        dataGridRef.selectRows([data.key], true)
                    } else {
                        dataGridRef.deselectRows([data.key], true)
                    }

                }}
            />
        )
        
    }

    customizeCellHeaderCheckbox = (data) => {
        return (
            <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'select service checkbox' }}
                onChange={(e)=>{
                    let dataGridRef = this.dataGridRef.current.instance

                    if ( e.target.checked ) {
                        dataGridRef.selectAll()
                    } else {
                        dataGridRef.deselectAll()
                    }
                   
                }}
            />
        )
        
    }

    onSelectionChanged = (selection)=>{
        
        let dataGridRef = this.dataGridRef.current.instance,
        { currentSelectedRowKeys, currentDeselectedRowKeys } = selection,
        rowKey = currentSelectedRowKeys.length !== 0 ? currentSelectedRowKeys : currentDeselectedRowKeys
        
        dataGridRef.beginUpdate()
        let rowIndex=  rowKey.map((key)=>{
           return dataGridRef.getRowIndexByKey(key)
         })
        dataGridRef.endUpdate()

            
        dataGridRef.repaintRows(rowIndex)
    }


    handleSaveSelectedStep = async () => {
        let dataGridRef = this.dataGridRef.current.instance,
        selectedStep = dataGridRef.getSelectedRowKeys(),
        at = this.props.currentUser.Data.access_token

        if ( selectedStep.length !== 0 ) {

            this.props.setLoading(true, 'dark')
    
            const response = await saveDepartmentVersionSelectedStep(at, this.props.versionid, selectedStep)
    
            this.props.triggerNotification(true, response.data)

            this.props.triggerDialogForm(false)
    
            this.props.setLoading(false)
        }
        // this.props.handleStepsChange()
    }

    render() {
 
        let columnAlign = 'left',
        ServiceStepTitleKey = 'Service_Step_Name_EN',
        ServiceStepTypeKey = 'Service_Step_Type_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            ServiceStepTitleKey = 'Service_Step_Name_AR'
            ServiceStepTypeKey = 'Service_Step_Type_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'Service_Step_Id',
            load: () => {
                return getDepartmentVersionUnselectedStepList(at, this.props.versionid)
            }
        });
        
        return(
            <>

            <div className="container-fluid pt-5">
                
                <DataGrid
                    ref={this.dataGridRef}
                    id="dataGrid"
                    dataSource={jsonDataSource}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <SearchPanel visible={true}
                    width={240}
                    placeholder={this.props.t('search')}
                    />

                    <FilterRow visible={true} />

                    <HeaderFilter visible={true} />

                    <Selection
                        mode="multiple"
                        allowSelectAll={true}
                        showCheckBoxesMode="always"
                    />

                    <Column
                        type="selection"
                        cellRender={this.customizeCellCheckbox}
                        headerCellRender={this.customizeCellHeaderCheckbox}
                    />

                    <Column dataField="Service_Step_No" caption={this.props.t('stepNo')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={ServiceStepTitleKey} caption={this.props.t('stepName')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={ServiceStepTypeKey} caption={this.props.t('stepType')} alignment={columnAlign}>
                    </Column>

                    <Column dataField='Service_Step_Send_Notification' caption={this.props.t('notification')} alignment="center" allowFiltering={false}>
                    </Column>

                    
                </DataGrid>

                <div className="d-flex justify-content-start align-items-center mb-4">
                    <ButtonPrimary
                        size="large"
                        onClick={this.handleSaveSelectedStep}
                    >
                        {this.props.t('save')}
                    </ButtonPrimary>
                </div>
            </div>
            </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(ServiceVersionUnselectedStep))