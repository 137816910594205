import React, { useState, useEffect, useRef } from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import style from "./Main.module.scss";
import Header from "./UI/Header/Header";
import Footer from "./UI/Footer/Footer";
import SideMenu from "./UI/SideMenu/SideMenu";
import Service from "./services/Service";
import ServiceList from "./services/ServiceList";
import ServiceCatList from "./services/categories/ServiceCatList";
import ServiceDropDowns from "./services/services-drops/ServiceDropDowns";
import ServiceDropdown from "./services/services-drops/ServiceDropDown";
import Processing from "./requests/processing/Processing";

import DepartmentServicesList from "./services/departments/DepartmentServicesList";
import SystemModulesList from "./system/systemModules/SystemModulesList";
import SystemModulesApisList from "./system/systemModulesApis/systemModulesApisList";

import ModulesList from "./system/modules/ModulesList";
import AddModule from "./system/modules/AddModule";
import DepartmentServiceVersionsList from "./services/departments/DepartmentServiceVersionsList";
import DepartmentServiceVersion from "./services/departments/DepartmentServiceVersion";
import DepartmentsList from "./departments/DepartmentsList";
import AssignedTasks from "./assignedTasks/AssignedTasksList";
import AllTasksList from "./allTasks/AllTasksList";
import EmployeeTransactions from "./employeeTransactions";
import UsedDevices from "./usedDevices";
import AssignForm from "./assignedTasks/AssignForm";
import MyTasksList from "./myTasks/MyTasksList";
import DepartmentsSectionsList from "./departments/sections/DepartmentsSectionsList";
import PaymentList from "./portal/payments/PaymentsList";
import DepartmentscentersList from "./departments/centers/departmentscentersList";
import DepartmentsJobsist from "./departments/jobs/DepartmentsJobsList";
import DepartmentsAdministrationsList from "./departments/administrations/DepartmentsAdministrationsList";

import NotificationList from "./system/notifications/NotificationsList";

import Slider from "./portal/Slider";
import SuggestionsComplainsList from "./portal/SuggestionsComplainsList";

import Users from "./system/Users";
import AccountsList from "./system/accounts/AccountsList";
import InvoicesList from "./system/invoicesList/InvoicesList";
import InvoicesEditForm from "./system/invoiceEditsList/InvoiceEditsForm";
import UsersGroupsList from "./system/usersGroups/UsersGroupList";
import CustomerServicesList from "./system/customerServices/CustomerServicesList";
import UsersArea from "./system/usersArea/UsersArea";

import Dashboard from "./requests/Dashboard";
import CustomerSerDashboard from "./portal/customerSerDashbard/CustomerSerDashboard";
import EmploymentDashboard from "./portal/EmpDashboard/EmploymentDashboard";
import ServiceRequests from "./requests/list";
import GMDashboard from "./portal/GMDashboard/GMDashboard";
import SpVisorsDashboard from "./portal/SupervisorsDashboard/SpVisorsDashboard";

import DashboardView from "./dashboard/DashboardView";
import StructuralAuditing from "./structuralAuditing/StructuralAuditing";

import SupportRequest from "./system/supportRequest/supportRequestList";
import GovEntList from "./system/governmentEntities/GovEntList";
import FaqList from "./system/faq/FaqList";
import HousingDepartment from "./system/housing/housingList";
import HouseModuleForm from "./system/housing/HouseModuleForm";
import DecisionCategoriesList from "./system/decisionCategories/decisionCategoriesList";
import DecisionsList from "./system/decisions/decisionsList";
import SystemSetting from "./system/systemSettings";

import Profile from "./profile/index";

import VacationList from "./system/vacations/VacationsList";
import SuspensionsList from "./suspensions/SuspensionsList";
import AllSuspensionsList from "./suspensions/AllSuspensionsList";

import TypesOfDocuments from "./services/documents/TypesOfDocuments";
import TypesOfStamps from "./services/stamps/TypesOfStamps";
import UsersDashboardMenu from "./system/UsersDashboardMenu/UsersDashboardMenu";
import Cards from "./system/cardSettings/Cards";

const Main = (props) => {
  let currWinWidth = window.innerWidth;

  const mainBodyCon = useRef(null);

  const i18n = useTranslation();
  let dir = "ltr";
  let mystyle;
  if (i18n.i18n.language === "ar") {
    mystyle = {
      direction: "rtl",
      textAlign: "right",
      background: "url('./images/loginBackground.png')",
    };

    dir = "rtl";
  } else {
    mystyle = {
      direction: "ltr",
      textAlign: "left",
    };
  }

  const theme = createMuiTheme({
    typography: {
      fontFamily: ["Cairo", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#000033",
      },
      success: {
        main: "#00cc33",
      },
    },
  });

  const [respSideDrawer, setRespSideDrawer] = useState(false);
  const [respCustomDrawer, setRespCustomDrawer] = useState(false);
  const [respTopbarDrawer, setRespTopbarDrawer] = useState(false);
  const [departmentJobTypeId, setDepartmentJobTypeId] = useState(false);

  const handleRespSideDrawer = () => {
    setRespSideDrawer(!respSideDrawer);
    setRespCustomDrawer(false);
    setRespTopbarDrawer(false);
  };

  const handleRespCustomDrawer = () => {
    setRespCustomDrawer(!respCustomDrawer);
    setRespSideDrawer(false);
    setRespTopbarDrawer(false);
  };

  const handleRespTopbarDrawer = () => {
    setRespTopbarDrawer(!respTopbarDrawer);
    setRespCustomDrawer(false);
    setRespSideDrawer(false);
  };

  useEffect(() => {
    // document.body.style = `
    //     background-image: none;
    // `
    if (document.querySelector(".rootContainer")) {
      document.querySelector(".rootContainer").classList.add("mainContainer");
    }
  }, []);

  const { User_Permissions, Department_Job_Type_Id } = props.currentUser.Data;
  const allowedScreens = User_Permissions.map(
    (screen) => screen.User_Screen_Code
  );

  useEffect(() => {
    setDepartmentJobTypeId(Department_Job_Type_Id);
  }, [props.currentUser]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          fluid
          style={mystyle}
          className={[dir, "rootContainer p-0 w-100"].join(" ")}
        >
          <div className={style.root}>
            <SideMenu
              onToggleSide={handleRespSideDrawer}
              respSideDrawer={respSideDrawer}
              currentWidth={(open) => {
                let withOpenDrawer = `${currWinWidth - 265}px`;
                let withClosedDrawer = `${currWinWidth - 73}px`;
                if (mainBodyCon) {
                  document.querySelector(".fixedHeader").style.width =
                    withClosedDrawer;
                  if (open) {
                    mainBodyCon.current.style.width = withOpenDrawer;
                    document.querySelector(".fixedHeader").style.width =
                      withOpenDrawer;
                  } else {
                    mainBodyCon.current.style.width = withClosedDrawer;
                    document.querySelector(".fixedHeader").style.width =
                      withClosedDrawer;
                  }
                }
                // function handleResize() {
                //     currWinWidth = window.innerWidth
                //     withOpenDrawer = `${currWinWidth - 265}px`
                //     withClosedDrawer = `${currWinWidth - 73}px`
                //     // console.log(currWinWidth);
                //     if(open && document.querySelector('.fixedHeader')){
                //         document.querySelector('.fixedHeader')?.style.width = withOpenDrawer;
                //     }else{
                //         document.querySelector('.fixedHeader')?.style.width = withClosedDrawer;
                //     }
                // }
                // window.addEventListener('resize', handleResize)
              }}
            />

            <main
              ref={mainBodyCon}
              className={[style.content, "main"].join(" ")}
            >
              <Header
                onToggleSide={handleRespSideDrawer}
                onToggleCustom={handleRespCustomDrawer}
                respCustomDrawer={respCustomDrawer}
                onToggleTopbar={handleRespTopbarDrawer}
                respTopbarDrawer={respTopbarDrawer}
              />

              <div className="main-body py-5 px-2 w-100">
                <Router>
                  {allowedScreens.map((screenCode) => {
                    if (screenCode === "Users") {
                      return <Users path="users" />;
                    }
                    if (screenCode === "departments") {
                      return <DepartmentsList path="departments" />;
                    }
                    if (screenCode === "municipality-management") {
                      return (
                        <DepartmentsAdministrationsList path="municipality-management" />
                      );
                    }
                    if (screenCode === "Users_Groups") {
                      return <UsersGroupsList path="users-groups" />;
                    }
                    if (screenCode === "municipality-sections") {
                      return (
                        <DepartmentsSectionsList path="municipality-sections" />
                      );
                    }
                    if (screenCode === "municipality-centers") {
                      return (
                        <DepartmentscentersList path="municipality-centers" />
                      );
                    }
                    if (screenCode === "municipality-jobs") {
                      return <DepartmentsJobsist path="municipality-jobs" />;
                    }
                    if (screenCode === "manage-system-modules") {
                      return <SystemModulesList path="manage-system-modules" />;
                    }
                    if (screenCode === "system-modules-apis") {
                      return (
                        <SystemModulesApisList path="system-modules-apis" />
                      );
                    }
                    if (screenCode === "types-of-documents") {
                      return <TypesOfDocuments path="types-of-documents" />;
                    }
                    if (screenCode === "types-of-stamps") {
                      return <TypesOfStamps path="types-of-stamps" />;
                    }
                    if (screenCode === "users-dashboard-list") {
                      return <UsersDashboardMenu path="users-dashboard-list" />;
                    }
                    if (screenCode === "manage-modules") {
                      return (
                        <>
                          <ModulesList path="manage-modules" />
                          <AddModule path="manage-modules/add-module" />
                          <AddModule path="manage-modules/edit-module/:moduleId" />
                        </>
                      );
                    }
                    if (screenCode === "services") {
                      return (
                        <>
                          <ServiceList path="services" />
                          <Service path="services/add-service" />
                          <Service path="services/edit-service/:serviceId" />
                        </>
                      );
                    }
                    if (screenCode === "services/categories") {
                      return <ServiceCatList path="services/categories" />;
                    }
                    if (screenCode === "services/drop-downs") {
                      return (
                        <>
                          <ServiceDropDowns path="services/drop-downs" />
                          <ServiceDropdown path="services/drop-downs/add-drop-down" />
                          <ServiceDropdown path="services/drop-downs/edit-drop-down/:dropdownId" />
                        </>
                      );
                    }
                    if (screenCode === "services/municipalities-services") {
                      return (
                        <>
                          <DepartmentServicesList path="services/municipalities-services" />
                          <DepartmentServiceVersionsList path="services/municipalities-services/:serviceName/versions" />
                          <DepartmentServiceVersion path="services/municipalities-services/:serviceName/versions/add-version" />
                          <DepartmentServiceVersion path="services/municipalities-services/:serviceName/versions/edit-version/:versionId" />
                        </>
                      );
                    }
                    if (screenCode === "slider") {
                      return <Slider path="slider" />;
                    }
                    if (screenCode === "suggestionsAndComplains") {
                      return (
                        <SuggestionsComplainsList path="suggestionsAndComplains" />
                      );
                    }
                    if (screenCode === "manage-payments") {
                      return <PaymentList path="manage-payments" />;
                    }
                    if (screenCode === "manage-payments") {
                      return <PaymentList path="manage-payments" />;
                    }
                    if (screenCode === "accounts") {
                      return <AccountsList path="accounts" />;
                    }
                    if (screenCode === "manage-notifications") {
                      return <NotificationList path="manage-notifications" />;
                    }
                    if (screenCode === "support-request") {
                      return <SupportRequest path="support-request" />;
                    }
                    if (screenCode === "government-entities") {
                      return <GovEntList path="government-entities" />;
                    }
                    if (screenCode === "faq-list") {
                      return <FaqList path="faq-list" />;
                    }
                    if (screenCode === "housing-departments") {
                      return (
                        <>
                          <HousingDepartment path="housing-departments" />
                          <HouseModuleForm path="housing-departments/add-module" />
                        </>
                      );
                    }
                    if (screenCode === "decision-categories") {
                      return (
                        <DecisionCategoriesList path="decision-categories" />
                      );
                    }
                    if (screenCode === "decisions") {
                      return <DecisionsList path="decisions" />;
                    }
                    if (screenCode === "settings") {
                      return <SystemSetting path="settings" />;
                    }
                    if (screenCode === "invoices-list") {
                      return (
                        <>
                          <InvoicesList path="invoices-list" />
                          <InvoicesEditForm path="invoices-list/edit" />
                          <InvoicesEditForm path="invoices-list/edit/:id" />
                        </>
                      );
                    }
                    if (screenCode === "servicies-requests") {
                      return <ServiceRequests path="servicies-requests" />;
                    }
                    if (screenCode === "assigned-tasks") {
                      return (
                        <>
                          <AssignedTasks path="assigned-tasks" />
                          <AssignForm path="assigned-tasks/add-task" />
                          <AssignForm path="assigned-tasks/edit-task/:id" />
                        </>
                      );
                    }
                    if (screenCode === "all-tasks") {
                      return <AllTasksList path="all-tasks" />;
                    }
                    if (screenCode === "my-tasks") {
                      return <MyTasksList path="my-tasks" />;
                    }
                    if (screenCode === "holiday") {
                      return <VacationList path="holidays" />;
                    }
                    if (screenCode === "customer-services") {
                      return <CustomerServicesList path="customer-services" />;
                    }
                    if (screenCode === "users-areas") {
                      return <UsersArea path="users-areas" />;
                    }

                    if (screenCode === "manage-suspensions") {
                      return <SuspensionsList path="manage-suspensions" />;
                    }
                    if (screenCode === "suspensions") {
                      return <AllSuspensionsList path="suspensions" />;
                    }
                    if (screenCode === "employee-transactions") {
                      return (
                        <EmployeeTransactions path="employee-transactions" />
                      );
                    }
                    if (screenCode === "used-devices") {
                      return <UsedDevices path="used-devices" />;
                    }

                    if (screenCode === "Control-panel-card-settings") {
                      return <Cards path="cards" />;
                    }
                  })}
                  {departmentJobTypeId === 4 && (
                    <GMDashboard path="general-manager-dashboard" />
                  )}
                  {departmentJobTypeId === 1 && (
                    <CustomerSerDashboard path="customer-dashboard" />
                  )}
                  {departmentJobTypeId === 2 && (
                    <EmploymentDashboard path="employment-dashboard" />
                  )}
                  {departmentJobTypeId === 3 && (
                    <SpVisorsDashboard path="supervisors-dashboard" />
                  )}

                  <DashboardView path="/" />
                  <StructuralAuditing path="/structuralAuditing" />
                  <Processing path="view-request" />
                  <Profile path="profile" />
                </Router>
              </div>
              <Footer />
            </main>
          </div>
        </Container>
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(Main);
