import apiConfig from "../../apis/apiConfig";

export const emplyeedashboardStatistics = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_STATISTICS",
		{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getNewRequestList = async (at) => {
	const response = await apiConfig.post(
		"API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_NEW_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getAssignedRequestList = async (at) => {
	const response = await apiConfig.post(
		"API/ADMINISTRATION/DASHBOARD/EMPLOYEES//GET_EMPLOYEES_DASHBOARD_ASSIGNED_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getCompeletedRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_COMPELETED_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getRejectedRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_REJECTED_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getReturnedRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_RETURN_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getUnderProcessRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_UNDER_PROCESS_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getMissionList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_MISSIONS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};

export const getReturnedFromClientList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/EMPLOYEES/GET_EMPLOYEES_DASHBOARD_REQUESTS_RETURN_FROM_CLIENTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
