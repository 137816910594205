import React, { useEffect } from 'react'
import style from './SubMenu.module.scss'
import { RightArrow, LeftArrow } from '../../../SVG/Arrow'
import { useTranslation } from 'react-i18next'
// import { NavLink } from 'react-router-dom'
import { Link } from "@reach/router"
import ListItemIcon from '@material-ui/core/ListItemIcon'

const SubMenu2 = props => {

    const { children = [], menuOpen } = props
    const { t, i18n } = useTranslation('common')

    let tranStyle
    if (i18n.language === 'en') {
        tranStyle = require('./SubMenu-ltr.module.scss')
    } else {
        tranStyle = require('./SubMenu-rtl.module.scss')
    }

    useEffect(() => {
        let arrows = document.querySelectorAll('.itemLev3')
        arrows.forEach(x => {
            if (x.children[1] && !menuOpen) {
                x.children[1].style.transform = 'rotate(0deg)'
            }
        })
    }, [menuOpen])

    const handleLev3Expand = e => {
        let item = e.target.closest('.itemLev3').nextSibling
        const lev3Items = document.querySelectorAll('.collapseLev3')
        let arrow = item.previousSibling.children.length > 0 &&
            item.previousSibling.children[1]

        // close all & open only the clicked one & change arrow direction
        lev3Items.forEach(x => {
            let xArrow = x.previousSibling.children.length > 0 &&
                x.previousSibling.children[1]
            if (!item.isEqualNode(x)) {
                x.classList.add('close-collapseLev3')
                x.classList.remove('open-collapseLev3')
                if (xArrow) {
                    xArrow.style.transform = 'rotate(0deg)'
                }
            }
        })
        if (item.classList.contains('open-collapseLev3')) {
            item.classList.remove('open-collapseLev3')
            item.classList.add('close-collapseLev3')
            if (arrow) {
                arrow.style.transform = 'rotate(0deg)'
            }
        } else {
            item.classList.add('open-collapseLev3')
            item.classList.remove('close-collapseLev3')
            if (arrow) {
                // arrow.style.transform = i18n.language === 'en' ? 'rotate(90deg)' : 'rotate(-90deg)'
            }
        }
    }

    return (
        <ul
            className={[
                !menuOpen ? 'close-collapseLev2' : null,
                'collapseLev2 close-collapseLev2'
            ].join(' ')}
            style={{
                paddingRight: i18n.language == 'ar' && '22px',
                paddingLeft: i18n.language == 'en' && '22px'
            }}
        >
            {children && children.map((lev3, i) => (
                <React.Fragment key={i}>
                    {!lev3.children ?
                        <Link
                            to={lev3.link}
                            className={[
                                'd-flex align-items-center itemLev3',
                                // tranStyle.itemLev3
                            ].join(' ')}
                            onClick={e => handleLev3Expand(e)}
                        >
                            <ListItemIcon className={style.icon}>
                                {lev3.Icon}
                            </ListItemIcon>

                            <span className={[
                                lev3.active ? style.activeLink : null,
                                'd-flex align-items-center'
                            ].join(' ')}
                            >
                                {lev3.name}
                            </span>
                        </Link>
                        :
                        <li
                            className={[
                                'd-flex justify-content-between align-items-center itemLev3',
                                tranStyle.itemLev3, style.itemLev3
                            ].join(' ')}
                            onClick={e => handleLev3Expand(e)}
                        >
                            <ListItemIcon className={style.icon}>
                                {lev3.Icon}
                            </ListItemIcon>

                            <span className={[
                                'd-flex align-items-center',
                                lev3.active ? style.activeParent : null
                            ].join(' ')}
                            >
                                {/* <span className={style.bulletDot}></span> */}
                                - {lev3.name}
                            </span>

                            {lev3.children && i18n.language === 'en' ?
                                RightArrow
                                : null}
                            {lev3.children && i18n.language === 'ar' ?
                                LeftArrow
                                : null}
                        </li>
                    }

                    <ul
                        className={[
                            !menuOpen ? 'close-collapseLev3' : null,
                            'collapseLev3 close-collapseLev3'
                        ].join(' ')}
                    >
                        {lev3.children && lev3.children.map((lev4, i) => (
                            <Link key={i}
                                to={lev4.link}
                                className={[
                                    'd-flex justify-content-between align-items-center',
                                    tranStyle.itemLev4
                                ].join(' ')}
                            >
                                <span className={[
                                    lev4.active ? style.activeLink : null,
                                    'd-flex align-items-center'
                                ].join(' ')}>
                                    <span className={style.bulletDot}></span>
                                    {lev4.name}
                                </span>
                            </Link>
                        ))}
                    </ul>
                </React.Fragment>
            ))
            }
        </ul >
    )
}

export default SubMenu2
