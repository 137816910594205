import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../../apis/apiConfig';
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store'
import { translationHook } from '../../../translationHook'

import { setLoading, triggerNotification } from '../../../../actions'
import InvoiceInfo from './InvoiceInfo'



class RefundInvoices extends Component {
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

    state = {
        Invoice_Id: null
    }

    handleSelectedInvoices = ({ selectedRowsData }) => {
        if (this.props.invoicesData) {
            return null
        }

        let Invoice_Id = selectedRowsData[0].Invoice_Id
        
        if (Invoice_Id) {
            this.setState({ Invoice_Id })
        }
    }


    render() {
        
        let columnAlign = 'left',
            serviceNameKey = this.props.invoicesData ? 'Invoice_Service_Name_EN' : 'Service_Name_EN',
            departmentName = 'Department_Name_EN',
            jsonDataSource = new CustomStore({
                key: 'Invoice_Id',
                load: async () => {

                    if (this.props.invoicesData) {
                        return this.props.invoicesData
                    }

                    let at = this.props.currentUser.Data.access_token

                    const response = await apiConfig.post(
                        '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_REFUND_INVOICES_LIST',
                        {
                            Request_Id: this.props.requestId,
                            Request_Task_Id: this.props.requestTaskId
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${at}`
                            }
                        }
                    )
            
                    if (response.data.Status) {
                        return response.data.Data
                    }

                    return []
                }
            });

            if  (this.props.i18n.language === 'ar') {
                columnAlign = 'right'
                serviceNameKey = this.props.invoicesData ? 'Invoice_Service_Name_AR' : 'Service_Name_AR'
                departmentName = 'Department_Name_AR'
            }
     
        
        return (
            <>
            <DataGrid
            className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
            id={`dataGrid_${this.props.key}`}
            width={'100%'}
            dataSource={jsonDataSource}
            showBorders={false}
            showColumnLines= {false}
            showRowLines= {true}
            allowColumnResizing={true}
            columnResizingMode={'widget'}
            allowColumnReordering={true}
            rowAlternationEnabled={false}
            focusedRowEnabled={true}
            onExporting={this.onExporting}
            columnWidth={'auto'}
            wordWrapEnabled={true}
            rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
            onSelectionChanged = {this.handleSelectedInvoices}
        >

            <ColumnChooser enabled={true} allowSearch={true} search={'fff'}
                title={this.props.t('dxColumnChooserTitle')}
                emptyPanelText={this.props.t('dxColumnChooserPanelText')}
            />
            
            <SearchPanel 
                visible={true}
                width={240}
                placeholder={this.props.t('search')}
            />

            <FilterRow visible={true} />

            <HeaderFilter visible={true} />

            <Export enabled={true} allowExportSelectedData={true}/>

            <Grouping contextMenuEnabled={true} expandMode="rowClick"/>
            <GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

                {!this.props.readOnly && 
                    <Selection mode="single" />
                }

                <Column dataField="Payment_No" width='auto' caption={this.props.t('paymentNo')} alignment={columnAlign}>
                </Column>

                <Column dataField="Online_Reference_No" width='auto' caption={this.props.t('onlineRefNo')} alignment={columnAlign} sortOrder="asc" sortIndex={0} dataType="number">
                </Column>

                <Column dataField="Invoice_Value" width='auto' caption={this.props.t('invoiceValue')} alignment={columnAlign}>
                </Column>
                
                <Column dataField={this.props.invoicesData ? 'Invoice_Request_No' : 'Request_No'} width='auto' caption={this.props.t('requestNo')} alignment={columnAlign}>
                </Column>

                <Column dataField={serviceNameKey} width='auto' caption={this.props.t('serviceName')} alignment={columnAlign}>
                </Column>

                <Column dataField={departmentName} width='auto' caption={this.props.t('departmentName')} alignment={columnAlign}>
                </Column>

                <Column dataField="Total_Retain_Value" width='auto' caption={this.props.t('finalValue')} alignment={columnAlign} visible={!this.props.invoicesData ? false : true} showInColumnChooser={false}>
                </Column>
                
                <Column dataField="Total_Refund_Value" width='auto' caption={this.props.t('refundValue')} alignment={columnAlign} visible={!this.props.invoicesData ? false : true} showInColumnChooser={false}>
                </Column>
                

                
            </DataGrid>
            
            {this.state.Invoice_Id && (
                <InvoiceInfo invoiceId={this.state.Invoice_Id} requestId={this.props.requestId} requestTaskId={this.props.requestTaskId} tempId={this.props.tempId} />
            )}
            </>
        )
    }
     
	onExporting(e) {
		let sheetTitle = this.props.t('invoiceList')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { setLoading, triggerNotification })(translationHook(RefundInvoices))
