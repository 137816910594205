import React , { useState , useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom'

import { useNavigate } from "@reach/router";

import CustomerSerDashboard from '../portal/customerSerDashbard/CustomerSerDashboard';
import EmploymentDashboard from '../portal/EmpDashboard/EmploymentDashboard';
import GMDashboard from '../portal/GMDashboard/GMDashboard';
import SpVisorsDashboard from '../portal/SupervisorsDashboard/SpVisorsDashboard';

const Dashboard = (props) => {
    const { t, i18n } = useTranslation('common')
    const [whichUser, setwhichUser] = useState(props.currentUser.Data.Department_Job_Type_Id)
    const navigate = useNavigate();

    useEffect(() => {
        setwhichUser(props.currentUser.Data.Department_Job_Type_Id)
        if(whichUser === 0) {
            navigate('/departments', { replace: true })
        }
    }, [props.currentUser])

    // const redirectUser = () => {
        // history.push('/departments')
        // console.log('yes');
        // navigate('/departments', { replace: true })
    // }

    return (
        <>
        {whichUser === 4 ? 
            <GMDashboard />
        :
        whichUser === 1 ? 
        <CustomerSerDashboard />
        : 
        whichUser === 2 ?
        <EmploymentDashboard />
        :
        whichUser === 3 ? 
        <SpVisorsDashboard />
        :
        whichUser === 0 ? 
        <></>
        : 
        false
        }
        </>
    )
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps)(Dashboard)
{/* // <>
        // <div className="d-flex justify-content-between align-items-center mb-4">
        //     <h3>{t('dashboard')}</h3>
        // </div>
        // <Row className='py-5 justify-content-center'>
        //     <Col xl={3} lg={4} sm={12}>
        //         <DashboardCard title="حالة معاملات البلدية" count={100} icon={<DomainIcon />} onClick={() => {navigate("/view-request")}} />
        //     </Col>
        //     <Col xl={3} lg={4} sm={12}>
        //         <DashboardCard title="معاملات للاعتماد" count={6} icon={<FontAwesomeIcon icon={faStamp} size='2x' />} onClick={() => {navigate("/view-request")}} />
        //     </Col>
        //     <Col xl={3} lg={4} sm={12}>
        //         <DashboardCard title="المهام والتكليفات" count={12} icon={<FontAwesomeIcon icon={faTasks} size='2x' />} onClick={() => {navigate("/view-request")}} />
        //     </Col>
        //     <Col xl={3} lg={4} sm={12}>
        //         <DashboardCard title="طلبات الاسترحام" count={48} icon={<FormatListNumberedIcon />} disabled={true} />
        //     </Col>
        // </Row>
        // </>
        // </> */}