import React from 'react'

import HtmlEditor from 'devextreme-react/html-editor';
import style from './Wysiwyg.module.scss'


const Wysiwyg = ({ children, shouldRender , id= '', defaultValue = '', rtlEnabled = false, height = '200px', onChange, onFocusIn = () => {}, onFocusOut = () => {}, readOnly, showError }) => {

    if (shouldRender) {
        return null;
    }
    return (
        <>
        <HtmlEditor
            rtlEnabled={rtlEnabled}
            height={height}
            elementAttr={{class: `${style.editorContent} mt-4`}}
            defaultValue={defaultValue}
            onValueChanged={(e) => onChange(e.value)}
            readOnly={readOnly}
            id={id}
            isValid={showError ? !showError() : 'valid'}
            onFocusIn={onFocusIn}
            onFocusOut={onFocusOut}
            >
                {children}
        </HtmlEditor>
        </>
    )
}

export default Wysiwyg