import apiConfig from '../apiConfig'

export const deleteSlider = async (at, sliderid) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/SLIDERS_MANAGEMENT/Del_Slider',
        {
            Portal_Slider_Id: sliderid
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
