import React, { Component } from "react";
import { connect } from "react-redux";

import { translationHook } from "../../translationHook";
import { triggerNotification, setLoading } from "../../../actions";

import DepartmentServiceTemplatesList from "./DepartmentServiceTemplatesList";

class ServiceVersionTemplate extends Component {
	render() {
		return (
			<DepartmentServiceTemplatesList
				departmentServiceId={this.props.departmentServiceId}
				versionid={this.props.versionid}
				editMood={this.props.editMood}
				viewMood={this.props.viewMood}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, { triggerNotification, setLoading })(
	translationHook(ServiceVersionTemplate)
);
