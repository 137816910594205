import apiConfig from '../../apiConfig';

export const deleteNotification = async (at, id) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/NOTIFICATIONS/DEL_NOTIFICATION', 
		{
			Notification_Id: id
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}
