import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { ButtonPrimary, ButtonSecondary } from '../../UI/Buttons';

import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from '../../../actions';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ColorBox from 'devextreme-react/color-box';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'
import ListItemText from '@material-ui/core/ListItemText'

class AddTempColor extends Component {
	state = {
		showForm: false,
		file: null,
	};
	handleAddFiles = (e) => {
		this.setState({
			file: e.target.files[0],
			fileName: e.target.files[0].name,
			url: window.URL.createObjectURL(e.target.files[0])
		});
	};

	onSubmit = async (values, form) => {
		const bodyFormData = new FormData(),
			at = this.props.currentUser.Data.access_token,
			url =
				'/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/ADD_APPROVING_CHART_TEMPLATE',
            bodyData = {
                approving_Chart_Id: this.props.moduleID,
                approving_Chart_Template_Name_AR:
                    values.Approving_Chart_Template_Name_AR,
                approving_Chart_Template_Name_EN:
                    values.Approving_Chart_Template_Name_EN,
                approving_Chart_Template_Color_Name:
                    values.Approving_Chart_Template_Color_Name,
                approving_Chart_Template_Color: this.state.color,
				Active_Status_Id: 1
            };

		bodyFormData.append('PARAMETERS', JSON.stringify(bodyData));
		bodyFormData.append('Approving_Chart_Template_Image', this.state.file);
		this.props.setLoading(true, 'dark');

		this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900);

		const response = await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.restart();
            this.props.addDone()
			this.setState({
				showForm: !this.state.showForm,
				fileName: null,
			});
		}

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);
	};

    handleColorChange = ({ value }) => this.setState({color : value});

	render() {
        const validate = (values) => {
            const errors = {};
            if (!values.Approving_Chart_Template_Name_AR) {
                errors.Approving_Chart_Template_Name_AR = `${this.props.t(
                    'error.field_required'
                )}`;
            }
            if (!values.Approving_Chart_Template_Name_EN) {
                errors.Approving_Chart_Template_Name_EN = `${this.props.t(
                    'error.field_required'
                )}`;
            }
            if (!values.Approving_Chart_Template_Color_Name) {
                errors.Approving_Chart_Template_Color_Name = `${this.props.t(
                    'error.field_required'
                )}`;
            }
            return errors;
        };

		return (
			<>
				<div className='d-flex justify-content-between align-items-center pb-5'>
					<h3>{this.props.t('ModuleData')}</h3>
					<ButtonPrimary
						variant={!this.state.showForm ? 'contained' : 'outlined'}
						onClick={() => this.setState({ showForm: !this.state.showForm })}>
						{!this.state.showForm && this.props.t('addModule')}
						{this.state.showForm && this.props.t('cancel')}
					</ButtonPrimary>
				</div>
				<Form
					onSubmit={this.onSubmit}
					initialValues={{
                        approving_Chart_Template_Name_AR: "",
                        approving_Chart_Template_Name_EN: "",
                        approving_Chart_Template_Color_Name: "",
                        approving_Chart_Template_Color: "",
                        Active_Status_Id: true
                    }}
					validate={this.validate}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit={handleSubmit} noValidate
                        className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                        >
							<Col xs={12} md={9}>
								<Row>
									<Col xs={12} md={6}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t('ModuleNameAR')}
											className={`${
												this.props.viewMood ? 'read-only mb-2' : 'mb-2'
											}`}
											name='Approving_Chart_Template_Name_AR'
										/>
									</Col>
									<Col xs={12} md={6}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t('ModuleNameEN')}
											className={`${
												this.props.viewMood ? 'read-only mb-2' : 'mb-2'
											}`}
											name='Approving_Chart_Template_Name_EN'
										/>
									</Col>
									<Col md={6}>
										<div className='text-left MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth'>
											<label className='text-start MuiFormLabel-root MuiInputLabel-root mb-0 MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled'>
												{this.props.t('colorCode')}
											</label>
											<ColorBox
												disabled={this.props.viewMood}
												className='custom-colorPicker'
												value={this.state.color}
												applyValueMode='instantly'
												onValueChanged={(e) => this.handleColorChange(e)}
											/>
										</div>
									</Col>
									<Col xs={12} md={6}>
										<TextField
											disabled={this.props.viewMood}
											label={this.props.t('colorName')}
											className={`${
												this.props.viewMood ? 'read-only mb-2' : 'mb-2'
											}`}
											name='Approving_Chart_Template_Color_Name'
										/>
									</Col>
									{!this.props.viewMood && (
										<Col className='my-4 d-flex ml-auto my-4' xs={12} md={12}>
											<ButtonSecondary
												color='primary'
												variant='contained'
												endIcon={<CloudUploadIcon />}>
												{this.props.t('mainImage')}
												<input
													type='file'
													name='Approving_Chart_File'
													className='buttonFile'
													accept='image/*'
													onChange={(e) => this.handleAddFiles(e)}
												/>
											</ButtonSecondary>
										</Col>
									)}
									{this.state.url && (
										<Col xs={12} md={6}>
											<ListItem className='shadow-sm'>
												<ListItemAvatar>
													<CropOriginalIcon />
												</ListItemAvatar>
												<ListItemText primary={this.props.t('mainImage')} />
												<IconButton
													edge='start'
													aria-label='download'
													onClick={() => {
														window.open(this.state.url, '_blank');
													}}>
													<GetAppIcon />
												</IconButton>
											</ListItem>
										</Col>
									)}
                                    <Col className='my-4 d-flex ml-auto my-4' xs={12}>
                                        <ButtonPrimary
                                            color='primary'
                                            variant='contained'
                                            disabled={submitting || this.props.viewMood}
                                            type='submit'>
                                            {this.props.t('save')}
                                        </ButtonPrimary>
                                    </Col>
								</Row>
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(AddTempColor));
