import React from "react";

import ChangeStepUser from "./ChangeStepUser";
import Notes from "../Notes";
import {
	returnRequestEditData,
	returnRequestAddData,
	returnRequestStep,
} from "../../../../apis/requests/processTask";

export const RenderStepUsers = (props) => {
	return (
		<>
			<ChangeStepUser
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
				optional={props.optional}
			/>

			{/* <Notes
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				onTaskSubmit={returnRequestStep}
			/> */}
		</>
	);
};
