import React, { Component } from "react";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";

import { translationHook } from "../../translationHook";
import { triggerNotification, setLoading } from "../../../actions";

class ApplicantInfo extends Component {
  onServiceInfoSubmit = async (values, form) => {
    return null;
  };

  render() {
    let { info } = this.props;

    if (!info) {
      return <h6>{this.props.t("loadingClientData")}</h6>;
    }

    return (
      <Form
        onSubmit={this.onServiceInfoSubmit}
        initialValues={{
          clientNameAr: info.Client_Name_AR,
          clientNameEn: info.Client_Name_EN,
          dealingType:
            this.props.i18n.language === "ar"
              ? info.Dealing_Type_Name_AR
              : info.Dealing_Type_Name_EN,
          email: info.Client_Mail,
          phone: info.Client_Phone,
          idNumber: info.Client_EID,
          license: info.License_No,
          licenseCat: info.License_Category,
          licenseStartDate: info.License_Issue_Date
            ? info.License_Issue_Date.slice(0, 10)
            : "",
          licenseEndDate: info.License_Issue_Date
            ? info.License_Expired_Date.slice(0, 10)
            : "",
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form className="row" onSubmit={handleSubmit} noValidate>
            <Col xs={12}>
              {/* // */}
              <h4 className="w-25 my-4">{this.props.t("applicantInfo")}</h4>
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("clientNameAr")}
                className="read-only mb-4"
                name="clientNameAr"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("clientNameEn")}
                className="read-only mb-4"
                name="clientNameEn"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("dealingType")}
                className="read-only mb-4"
                name="dealingType"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("customerEmail")}
                className="read-only mb-4"
                name="email"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("customerPhone")}
                className="read-only mb-4"
                name="phone"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("id")}
                className="read-only mb-4"
                name="idNumber"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("licenseNo")}
                className="read-only mb-4"
                name="license"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("licenseCat")}
                className="read-only mb-4"
                name="licenseCat"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("licenseStartDate")}
                className="read-only mb-4"
                name="licenseStartDate"
              />
            </Col>
            <Col xs={12} md={6}>
              <TextField
                disabled
                label={this.props.t("licenseEndDate")}
                className="read-only mb-4"
                name="licenseEndDate"
              />
            </Col>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, { triggerNotification, setLoading })(
  translationHook(ApplicantInfo)
);
