import React, { Component } from 'react';
import { connect } from 'react-redux';
import apiConfig from '../../../apis/apiConfig';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField, Switches, Select } from 'mui-rff';

import { ButtonPrimary } from '../../UI/Buttons';
import { translationHook } from '../../translationHook';
import { triggerNotification, setLoading } from '../../../actions';

class ModuleInfo extends Component {
	state = {
		getTemplateApis: [],
	};

	onServiceInfoSubmit = async (values, form) => {
		const at = this.props.currentUser.Data.access_token,
			url = this.props.editMood
				? '/API/ADMINISTRATION/TEMPLATES/UPD_TEMPLATE'
				: '/API/ADMINISTRATION/TEMPLATES/ADD_TEMPLATE',
			bodyData = {
				Template_Name_AR: values.ModuleNameAR,
				Template_Name_EN: values.ModuleNameEN,
				Active_Status_Id: values.moduleStatus ? 1 : 0,
				Template_has_Invoices: values.showInvoices ? 1 : 0,
				Template_API_Id: values.templateApisId,
				Template_Skip_API_Errors: values.skipWhenError ? 1 : 0,
			};
		if (this.props.editMood) {
			bodyData.Template_Id = this.props.moduleId;
		}

		this.props.setLoading(true, 'dark');

		const response = await apiConfig
			.post(url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.restart();
			this.props.onStepDone(response.data.Data.Template_Id, values);
		}
		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
	};
	getTemplateApis = async () => {
		let getTemplateApis = [];

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/TEMPLATESAPI/GET_TEMPLATES_API', {},
            {
                headers: {
                    Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
                },
            }
            );

        if (response.data.Status) {

            let Emptylabel = `${this.props.t('NotemplateApi')}`;
            getTemplateApis.push({ value: '0', label: Emptylabel });

			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === 'ar'
						? item.Template_API_Name_AR
						: item.Template_API_Name_EN;

				getTemplateApis.push({ value: item.Template_API_Id, label });
			});

			this.setState({ getTemplateApis });
		}
	};
	componentDidMount() {
		this.getTemplateApis()
	}

	render() {
		const validate = (values) => {
			const errors = {};

			if (!values.ModuleNameAR) {
				errors.ModuleNameAR = `${this.props.t('error.field_required')}`;
			}

			if (!values.ModuleNameEN) {
				errors.ModuleNameEN = `${this.props.t('error.field_required')}`;
			}

			return errors;
		};

		return (
			<Form
				onSubmit={this.onServiceInfoSubmit}
				initialValues={this.props.initValues}
				validate={validate}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<form className='row' onSubmit={handleSubmit} noValidate>
						<Col xs={12} md={12}>
							<Row>
								<Col xs={12} md={4}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t('ModuleNameAR')}
										className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
										required={true}
										name='ModuleNameAR'
										autoComplete='off'
										showError={({ meta: { submitFailed, invalid } }) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={4}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t('ModuleNameEN')}
										className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
										required={true}
										name='ModuleNameEN'
										autoComplete='off'
										showError={({ meta: { submitFailed, invalid } }) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
                                <Col xs={12} md={4}>
									<Select
										label={this.props.t('templateApis')}
										className='mb-2'
										required={false}
										name='templateApisId'
										data={this.state.getTemplateApis}
										showError={() => {
											let fieldStatus = form.getFieldState('templateApis');

											if (fieldStatus) {
												return fieldStatus.submitFailed && fieldStatus.invalid;
											}
										}}></Select>
								</Col>
								<Col xs={12}>
									<Row>
										<Col md={8}>
											<div className='d-flex align-items-center mt-3'>
												<Switches
													disabled={this.props.viewMood}
													color='primary'
													name='moduleStatus'
													data={{
														label: `${this.props.t('serviceStatus')}`,
														value: `${this.props.t('ModuleStatus')}`,
													}}
												/>
												<Switches
													disabled={this.props.viewMood}
													color='primary'
													name='showInvoices'
													data={{
														label: `${this.props.t('showInvoicesInTemplates')}`,
														value: `${this.props.t('showInvoices')}`,
													}}
												/>
											</div>
										</Col>
										<Col md={4}>
                                        <div className='d-flex align-items-center mt-3'>
											<Switches
												disabled={this.props.viewMood}
												color='primary'
												name='skipWhenError'
												data={{
													label: `${this.props.t('skipWhenError')}`,
													value: `${this.props.t('skipWhenError')}`,
												}}
											/>
											</div>

										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						{!this.props.viewMood ? (
							<Col className='my-4' xs={12}>
								<Row className='align-items-center'>
									<Col className='mt-2'>
										<ButtonPrimary
											color='primary'
											variant='contained'
											disabled={submitting}
											type='submit'>
											{this.props.t('save')}
										</ButtonPrimary>
									</Col>
								</Row>
							</Col>
						) : (
							<></>
						)}
					</form>
				)}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, { triggerNotification, setLoading })(
	translationHook(ModuleInfo)
);
