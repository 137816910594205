import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../apis/apiConfig";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Select, Switches } from "mui-rff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Avatar from "@material-ui/core/Avatar";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import { ButtonPrimary, ButtonSecondary, ButtonDanger } from "./../UI/Buttons";
import { translationHook } from "../translationHook";
import { triggerDialog, setLoading, triggerNotification, reload } from "../../actions";
import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';

import { Toolbar, Item } from "devextreme-react/html-editor";
import Wysiwyg from "../UI/Wysiwyg";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';
import { getServiceActions } from "../../apis/services/getServiceActions";
import ServiceInfoForm from './ServiceInfoForm'
import { deleteServiceAction } from "../../apis/services/deleteServiceAction";

const styles = (theme) => ({
	avatarRoot: {
		width: "100% !important",
		height: "250px !important",
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		border: "2px solid #ebecea",
		padding: "3px",
    	borderRadius: "6px",
	},
	avatarImg: {
		borderRadius: "6px"
	},
	buttonRoot: {
		minWidth: "90px !important",
		padding: "0.5rem 1rem !important"
	}
})

class ServiceInfo extends Component {
	constructor(props){
		super(props)
		this.onExporting = this.onExporting.bind(this);
	}

	state = {
		timeTypes: [],
		serviceCategories: [],
		templatesList: [],
		createdServiceId: null,
		imgUrl: "",
		file: "",
		reload: false
	};

	handleUploadClick = (event) => {
		let file = event.target.files[0];
		const reader = new FileReader();
		let url = reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			this.setState({
				imgUrl: reader.result,
				file,
			});
		};
	};

	getTemplatesList = async () => {
		let templatesArr = [];

		const response = await apiConfig.post("/API/SYSTEM/DDL/GET_TEMPLATES");

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				templatesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ templatesList: templatesArr });
		}
	};

	getTimeTypes = async () => {
		let typesArr = [];

		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_TIME_TYPES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({ timeTypes: typesArr });
		}
	};

	getServiceCategories = async () => {
		let catsArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_CATEGORIES"
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				catsArr.push({ value: item.Record_Id, label });
			});

			this.setState({ serviceCategories: catsArr });
		}
	};

	componentDidMount() {
		if (this.props.active) {
			this.getServiceCategories();
			this.getTimeTypes();
			this.getTemplatesList();

			// update avatr img if there was one in initialValues
			if (this.props.initValues.serviceImg !== "") {
				this.setState({ imgUrl: this.props.initValues.serviceImg });
			}
		}
	}

	handleDelete = async (data) => {
		let at = this.props.currentUser.Data.access_token,
        serviceActionId = data.Service_Action_Id,
        serviceEventName = this.props.i18n.language === 'ar' ? data.RAction_Name_AR : data.RAction_Name_EN,
        dialogTitle = `${this.props.t('confirmDeleteTitle')} ${serviceEventName}`,
        dialogMsg = this.props.t('confirmDeleteMsg')

        this.props.triggerDialog(true, {
            title: dialogTitle,
            message: dialogMsg,
            action: {
                action: deleteServiceAction,
                params: [
                    at,
					this.state.createdServiceId || this.props.serviceid,
                    serviceActionId,
					'/API/ADMINISTRATION/SERVICES/DEL_SERVICE_ACTION'
                ],
                text: this.props.t('serviceActionDelete')
            }
        })
	}

	handleServiceImgUpload = async (serviceId) => {
		const bodyFormData = new FormData(),
			at = this.props.currentUser.Data.access_token,
			url = "/API/ADMINISTRATION/SERVICES/UPD_SERVICE_LOGO",
			bodyData = {
				Service_Id: serviceId,
			};

		bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

		bodyFormData.append("Service_Logo", this.state.file);

		const response = await apiConfig.post(url, bodyFormData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		});

		return response;
	};

	onServiceInfoSubmit = async (values, form) => {
		const at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? "/API/ADMINISTRATION/SERVICES/ADD_SERVICE"
				: "/API/ADMINISTRATION/SERVICES/UPD_SERVICE";
		let body = {
				Service_Code: values.serviceCode,
				Service_Name_AR: values.serviceNameAR,
				Service_Name_EN: values.serviceNameEN,
				Service_Place_Name_AR: values.servicePlaceNameAR,
				Service_Place_Name_EN: values.servicePlaceNameEN,
				Service_Working_Hours_AR: values.serviceHoursAR,
				Service_Working_Hours_EN: values.serviceHoursEN,
				Service_Description_AR: values.serviceDescAR,
				Service_Description_EN: values.serviceDescEN,
				Service_Rules_AR: values.serviceRulesAR,
				Service_Rules_EN: values.serviceRulesEN,
				Active_Status_Id: values.serviceStatus ? 1 : 0,
				Service_Total_Time: values.serviceTime,
				Service_Time_Type_Id: values.serviceTimeType,
				Service_Category_Id: values.serviceCategoryID,
				Charts_Signatures: values.chartSignature,
			}

		if (this.props.editMood) {
			body = {Service_Id: this.props.serviceid, ...body};

			// const uploadResult = this.handleServiceImgUpload();
		}

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, body, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});
		if (this.state.file) {
			await this.handleServiceImgUpload(response.data.Data.Service_Id)
		}
		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);

		if (response.data.Status) {
			values.serviceImg = this.state.imgUrl;
			this.props.onStepDone(response.data.Data.Service_Id, values);
			if(!this.props.editMood) {
				this.setState({ createdServiceId: response.data.Data.Service_Id })
			} else {
				form.restart();
			}
		}
	};
	

	render() {
		const validate = (values) => {
			const errors = {};

			if (!values.serviceCode) {
				errors.serviceCode = `${this.props.t("error.field_required")}`;
			}

			if (!values.serviceNameAR) {
				errors.serviceNameAR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceNameEN) {
				errors.serviceNameEN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.servicePlaceNameAR) {
				errors.servicePlaceNameAR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.servicePlaceNameEN) {
				errors.servicePlaceNameEN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceHoursAR) {
				errors.serviceHoursAR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceHoursEN) {
				errors.serviceHoursEN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceDescAR) {
				errors.serviceDescAR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceDescEN) {
				errors.serviceDescEN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceRulesAR) {
				errors.serviceRulesAR = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceRulesEN) {
				errors.serviceRulesEN = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceTime) {
				errors.serviceTime = `${this.props.t("error.field_required")}`;
			}

			if (!values.serviceTimeType) {
				errors.serviceTimeType = `${this.props.t(
					"error.field_required"
				)}`;
			}

			if (!values.serviceCategoryID) {
				errors.serviceCategoryID = `${this.props.t(
					"error.field_required"
				)}`;
			}

			// if (!values.chartSignature) {
			// 	errors.chartSignature = `${this.props.t(
			// 		"error.field_required"
			// 	)}`;
			// }

			return errors;
		};

		let columnAlign = 'right',
        ServiceEventNameKey = 'RAction_Event_Name_AR',
        ServiceNameKey = 'RAction_Name_AR'

		if (this.props.i18n.language === 'en') {
			columnAlign = 'left'
			ServiceEventNameKey = 'RAction_Event_Name_EN'
			ServiceNameKey = 'RAction_Name_EN'
		}

		const jsonDataSource = new CustomStore({
			key: 'Service_Action_Id',
			load: async () => {
				return getServiceActions(this.props.currentUser.Data.access_token, this.state.createdServiceId || this.props.serviceid, '/API/ADMINISTRATION/SERVICES/GET_SERVICE_ACTIONS_LIST')
			},
		});
		 
		return (
			<Form
				subscription={{ submitting: true }}
				onSubmit={this.onServiceInfoSubmit}
				initialValues={this.props.initValues}
				validate={validate}
				render={({
					handleSubmit,
					form,
					submitting,
				}) => (
					<form className="row" onSubmit={handleSubmit} noValidate>
						<Col xs={12} md={8}>
							<Row>
								<Col xs={12} md={6}>
									<TextField
										label={this.props.t("serviceNo")}
										className="mb-2"
										name="serviceNo"
										disabled
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceCode")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="serviceCode"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceNameAR")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="serviceNameAR"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceNameEN")}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="serviceNameEN"
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t(
											"servicePlaceNameAR"
										)}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="servicePlaceNameAR"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t(
											"servicePlaceNameEN"
										)}
										className={`${
											this.props.viewMood
												? "read-only"
												: ""
										} mb-2`}
										required={true}
										name="servicePlaceNameEN"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceHoursAR")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										} mb-2`}
										required={true}
										name="serviceHoursAR"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceHoursEN")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										} mb-2`}
										required={true}
										name="serviceHoursEN"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceDescAR")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										} mb-2`}
										required={true}
										name="serviceDescAR"
										multiline
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceDescEN")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										} mb-2`}
										required={true}
										name="serviceDescEN"
										multiline
										autoComplete="off"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
										"serviceRulesAR"
									)} *`}</label>
									<Field
										name="serviceRulesAR"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="250px"
												defaultValue={
													this.props.initValues
														.serviceRulesAR
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
											>
												<Toolbar multiline={false}>
													<Item name="undo" />
													<Item name="redo" />
													<Item name="separator" />
													<Item name="bold" />
													<Item name="italic" />
													<Item name="underline" />
													<Item name="strike" />
													<Item name="separator" />
													<Item name="link" />
													<Item
														name="header"
														acceptedValues={[
															false,
															3,
															4,
															5,
														]}
													/>
													<Item
														name="size"
														acceptedValues={[
															"11px",
															"14px",
															"16px",
															"22px",
														]}
													/>
													<Item name="orderedList" />
													<Item name="bulletList" />
													<Item name="alignLeft" />
													<Item name="alignCenter" />
													<Item name="alignRight" />
												</Toolbar>
											</Wysiwyg>
										)}
									</Field>
								</Col>
								<Col xs={12} md={6}>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
										"serviceRulesEN"
									)} *`}</label>
									<Field
										name="serviceRulesEN"
										subscription={{
											submitFailed: true,
											invalid: true,
										}}
										disabled={this.props.viewMood}
										required={true}
									>
										{(fieldProps) => (
											<Wysiwyg
												rtlEnabled={
													this.props.i18n.language ===
													"ar"
														? true
														: false
												}
												height="250px"
												defaultValue={
													this.props.initValues
														.serviceRulesEN
												}
												onChange={
													fieldProps.input.onChange
												}
												readOnly={this.props.viewMood}
												showError={() => {
													return (
														fieldProps.meta
															?.invalid &&
														fieldProps.meta
															?.submitFailed
													);
												}}
											>
												<Toolbar multiline={false}>
													<Item name="undo" />
													<Item name="redo" />
													<Item name="separator" />
													<Item name="bold" />
													<Item name="italic" />
													<Item name="underline" />
													<Item name="strike" />
													<Item name="separator" />
													<Item name="link" />
													<Item
														name="header"
														acceptedValues={[
															false,
															3,
															4,
															5,
														]}
													/>
													<Item
														name="size"
														acceptedValues={[
															"11px",
															"14px",
															"16px",
															"22px",
														]}
													/>
													<Item name="orderedList" />
													<Item name="bulletList" />
													<Item name="alignLeft" />
													<Item name="alignCenter" />
													<Item name="alignRight" />
												</Toolbar>
											</Wysiwyg>
										)}
									</Field>
								</Col>
								{!this.props.viewMood && (
									<Col xs={12} className="mt-3">
										<ButtonPrimary
											color="primary"
											variant="contained"											
											disabled={
												submitting ||
												this.props.viewMood
											}
											type="submit"
										>
											{this.props.t("save")}
										</ButtonPrimary>
									</Col>
								)}
							</Row>
						</Col>
						<Col xs={12} md={4}>
							<Row>
								<Col
									xs={12}
									md={12}
									className="d-flex ServiceAvatarContainer"
								>
									<label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{`${this.props.t(
									"serviceAvatarButton"
								)} *`}</label>
									<Avatar
										variant="square"
										classes={{
											root: this.props.classes.avatarRoot,
											img: this.props.classes.avatarImg
										}}
										src={
											this.state.imgUrl
												? this.state.imgUrl
												: "/broken-image.jpg"
										}
									/>
								</Col>
								{!this.props.viewMood && (
									<Col xs={12} md={12}>
										<ButtonSecondary
										color="primary"
										variant="contained"
										endIcon={<CloudUploadIcon />}
									>
										{this.props.t("serviceAvatarButton")}
										<input
											type="file"
											name="serviceImg"
											className="buttonFile"
											onChange={this.handleUploadClick}
										/>
									</ButtonSecondary>
									</Col>
								)}
								<Col md={12} className="mt-4">
									<Select
										disabled={this.props.viewMood}
										label={this.props.t("serviceCat")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										}`}
										required={true}
										name="serviceCategoryID"
										data={this.state.serviceCategories}
										showError={() => {
											let fieldStatus =
												form.getFieldState("serviceCategoryID");

											if (fieldStatus) {
												return (
													fieldStatus.submitFailed &&
													fieldStatus.invalid
												);
											}
										}}
									></Select>
								</Col>
								<Col md={12}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("serviceTime")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										}`}
										required={true}
										name="serviceTime"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
								{/* <Col md={12}>
							

									<TextField
										disabled={this.props.viewMood}
										label={this.props.t("chartSignature")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										} mb-2`}
										required={true}
										name="chartSignature"
										showError={({
											meta: { submitFailed, invalid },
										}) => {
											return submitFailed && invalid;
										}}
									/>
								</Col> */}
								<Col md={12}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t("serviceTimeType")}
										className={`${
											this.props.viewMood ? "read-only" : ""
										}`}
										required={true}
										name="serviceTimeType"
										data={this.state.timeTypes}
										showError={() => {
											let fieldStatus =
												form.getFieldState("serviceTimeType");

											if (fieldStatus) {
												return (
													fieldStatus.submitFailed &&
													fieldStatus.invalid
												);
											}
										}}
									></Select>
								</Col>
								<Col
									md={12}
									className={`${
										this.props.viewMood ? "read-only" : ""
									}`}
								>
									<Switches
										disabled={this.props.viewMood}
										color="primary"
										name="serviceStatus"
										data={{
											label: `${this.props.t(
												"serviceStatus"
											)}`,
											value: `${this.props.t(
												"serviceStatus"
											)}`,
										}}
									/>
								</Col>
							</Row>
						</Col>
						

						{/* <Col className="my-4" xs={12}>
							<Row className="align-items-center">
								<Col className="mt-2">
									{!this.props.viewMood && (
										<ButtonPrimary
											color="primary"
											variant="contained"
											disabled={
												submitting ||
												this.props.viewMood
											}
											type="submit"
										>
											{this.props.t("save")}
										</ButtonPrimary>
									)}
								</Col>
							</Row>
						</Col> */}
						{
							this.props.serviceid || this.state.createdServiceId ? 
							(
								
						<Col md={12}>
						<div className="mt-5">
							<Divider />
						</div>

						<h5 className="w-40 my-4">
							{this.props.t("servicesListOfEvents")}
						</h5>
						{/* <Row className="d-flex justify-content-center align-items-center">
							<Col xs={12} md={4}>
								<Select
									disabled={this.props.viewMood}
									label={this.props.t("servicesEventExecution")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									}`}
									required={true}
									name="serviceCategoryID"
									data={this.state.serviceCategories}
									showError={() => {
										let fieldStatus =
											form.getFieldState("serviceCategoryID");

										if (fieldStatus) {
											return (
												fieldStatus.submitFailed &&
												fieldStatus.invalid
											);
										}
									}}
								></Select>
							</Col>
							<Col xs={12} md={4}>
								<Select
									disabled={this.props.viewMood}
									label={this.props.t("servicesExecutionOrder")}
									className={`${
										this.props.viewMood ? "read-only" : ""
									}`}
									required={true}
									name="serviceCategoryID"
									data={this.state.serviceCategories}
									showError={() => {
										let fieldStatus =
											form.getFieldState("serviceCategoryID");

										if (fieldStatus) {
											return (
												fieldStatus.submitFailed &&
												fieldStatus.invalid
											);
										}
									}}
								></Select>
							</Col>
							<Col xs={12} md={4}>
								
								<ButtonPrimary
									color="secondary"
									size="small"
									variant="outlined"
									classes={{
										root: this.props.classes.buttonRoot
									}}
									disabled={
										submitting ||
										this.props.viewMood
									}
									type="submit"
								>
									{this.props.t("close")}
								</ButtonPrimary>
							</Col>
						</Row> */}

						{!this.props.viewMood &&
						<Col xs={12} className='mb-3'>
							<ServiceInfoForm serviceid={this.state.createdServiceId || this.props.serviceid} reloadDatagrid={() => this.setState({reload: !this.state.reload})} />
						</Col>
						}

						<DataGrid
				className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
				id="dataGrid"
				width={'100%'}
				dataSource={jsonDataSource}
				showBorders={false}
				showColumnLines= {false}
				showRowLines= {true}
				allowColumnResizing={true}
				columnResizingMode={'widget'}
				allowColumnReordering={true}
				rowAlternationEnabled={false}
				focusedRowEnabled={true}
				onExporting={this.onExporting}
				columnWidth={'auto'}
				wordWrapEnabled={true}
				rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
			>

				{/* <ColumnChooser enabled={true} allowSearch={true} search={'fff'}
					title={this.props.t('dxColumnChooserTitle')}
					emptyPanelText={this.props.t('dxColumnChooserPanelText')}
				/> */}
				
				<SearchPanel 
					visible={true}
					width={240}
					placeholder={this.props.t('search')}
				/>

				<FilterRow visible={true} />

				<HeaderFilter visible={true} />

				<Export enabled={true} allowExportSelectedData={true}/>

				<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
				<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

				<Selection
					mode="multiple"
					selectAllMode={'allPages'}
					showCheckBoxesMode={'always'}
				/>

					{/* <Column cssClass="outlinedColumn" fixed={true} fixedPosition={this.props.i18n.language === 'ar' ? 'right' : 'left'} alignment="center" width={'70px'} cellRender={this.renderOrderButtons}>
					</Column> */}

					<Column dataField={ServiceEventNameKey} caption={this.props.t('servicesEventExecution')} alignment={columnAlign}>
					</Column>

					<Column dataField={ServiceNameKey} caption={this.props.t('servicesExecutionOrder')} alignment={columnAlign}>
					</Column>

					<Column cssClass="outlinedColumn nowrapColumn"
					type='buttons'
					caption={this.props.t('delete')}
					fixed={true}
					fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
				>
					<Button
							render={(record) => {
								return (
									
									<ButtonDanger
									variant="circleIcon"
									startIcon={<DeleteIcon />}
									onClick={() => this.handleDelete(record.data)}
									>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								)
							}}
						/>
					</Column>

				</DataGrid>
					</Col>
							) : null
						}
					</form>
				)}
			/>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("servicesListOfEvents");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, { triggerDialog, setLoading, triggerNotification, reload })(
	translationHook(withStyles(styles)(ServiceInfo))
);
