import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import { translationHook } from "../../../translationHook";
import style from "./DocumentInfoLists.module.scss";
const DocumentInfoLists = (props) => {
  const lang = props.i18n.language === "ar" ? "AR" : "EN";

  return (
    <Row
      className={` ${
        props.file?.Can_Edit !== 1 ? style.documentInfoContainer : "w-75"
      }`}
    >
      {props.file.hasOwnProperty("Can_Edit") && props.file.Can_Edit === 1 ? (
        <Col s={12} md={3}>
          <Form
            onSubmit={() => {}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className="w-75">
                <Select
                  label={props.t("documentType")}
                  name="Document_Type_Id"
                  value={props.file.Document_Type_Id}
                  data={props.file.listsData[0].data}
                  onChange={(e) =>
                    props.file.listsData[0].handleChange(e, props.file)
                  }
                ></Select>
              </form>
            )}
          />
        </Col>
      ) : (
        props.file?.[`Document_Type_Name_${lang}`] && (
          <div className={style.documentInfoItem}>
            <lable>{props.t("documentType")}:</lable>
            <lable>
              {
                // props.file.listsData[0].data.filter(
                //   (ele) => ele.value === props.file.Document_Type_Id
                // )[0]?.label
                props.file[`Document_Type_Name_${lang}`]
              }
            </lable>
          </div>
        )
      )}

      {props.file.hasOwnProperty("Can_Edit") && props.file.Can_Edit === 1 ? (
        <Col s={12} md={3}>
          <Form
            onSubmit={() => {}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className="w-75">
                <Select
                  label={props.t("documentSize")}
                  name="Document_Size_Id"
                  value={props.file.Document_Size_Id}
                  data={props.file.listsData[1].data}
                  onChange={(e) =>
                    props.file.listsData[1].handleChange(e, props.file)
                  }
                ></Select>
              </form>
            )}
          />
        </Col>
      ) : (
        props.file?.[`Document_Size_Name_${lang}`] && (
          <div className={style.documentInfoItem}>
            <lable>{props.t("documentSize")}:</lable>
            {/* <lable>
              {
                props.file.listsData[1].data.filter(
                  (ele) => ele.value === props.file.Document_Size_Id
                )[0]?.label
              }
            </lable> */}
            {props.file[`Document_Size_Name_${lang}`]}
          </div>
        )
      )}

      {props.file.hasOwnProperty("Can_Edit") && props.file.Can_Edit === 1 ? (
        <Col s={12} md={3}>
          <Form
            onSubmit={() => {}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className="w-75">
                <Select
                  label={props.t("documentStamp")}
                  name="Document_Stamp_Type_Id"
                  value={props.file.Document_Stamp_Type_Id}
                  data={props.file.listsData[2].data}
                  onChange={(e) =>
                    props.file.listsData[2].handleChange(e, props.file)
                  }
                ></Select>
              </form>
            )}
          />
        </Col>
      ) : (
        props.file?.[`Document_Stamp_Type_Name_${lang}`] && (
          <div className={style.documentInfoItem}>
            <lable>{props.t("documentStamp")}:</lable>
            {/* <lable>
              {
                props.file.listsData[2].data.filter(
                  (ele) => ele.value === props.file.Document_Stamp_Type_Id
                )[0]?.label
              }
            </lable> */}
            {props.file[`Document_Stamp_Type_Name_${lang}`]}
          </div>
        )
      )}
    </Row>

    // {/* </div> */}
  );
};

export default translationHook(DocumentInfoLists);
