import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import { ButtonPrimary } from "./Buttons";
import { translationHook } from "../translationHook";
import {
  triggerDialogForm,
  triggerNotification,
  clearDialogData,
} from "../../actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogForm = (props) => {
  let textAlign = props.i18n.language === "ar" ? "right" : "left",
    dir = props.i18n.language === "ar" ? "rtl" : "ltr";

  const useStyles = makeStyles((theme) => ({
    root: {
      direction: dir,
      "& *": {
        fontFamily: "Cairo, sans-serif",
      },
      "& .MuiDialogContent-root, .MuiFormControl-root": {
        textAlign,
      },
      "& .MuiInputBase-root input": {
        textAlign,
      },
      "& .MuiPaper-root": {
        backgroundColor: "#f7f9f5",
      },
      "& .MuiTypography-root": {
        textAlign,
      },
      "& .submit-btn-wrap": {
        textAlign,
      },
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    appBarButton: {
      borderColor: "#fff",
      color: "#fff !important",
    },
  }));

  const closeFormState =
    props.dialogForm.Data.closeForm === undefined ||
    window.location.href.indexOf("view-request") > 0
      ? true
      : props.dialogForm.Data.closeForm;
  const classes = useStyles();

  let { Open, Data } = props.custom ? props.customDialogForm : props.dialogForm;

  if (!Open) {
    return null;
  }

  let renderForm = Data.renderForm,
    FormElement = renderForm(...Data.params);

  const handleClose = () => {
    let cancelAction = Data.action,
      dismissAction = Data.dismissAction ? Data.dismissAction.action : null;

    if (cancelAction) {
      cancelAction(false, false);
    }

    if (dismissAction) {
      dismissAction(...Data.dismissAction.params);
    }
    closeFormState && props.clearDialogData();
    closeFormState && props.triggerDialogForm(false, Data);
  };

  return (
    <Dialog
      fullScreen
      open={Open}
      onClose={
        props.customDialogForm ? props.customDialogForm.onClose : handleClose
      }
      TransitionComponent={Transition}
      className={classes.root}
      disableEnforceFocus={true}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {Data.title}
          </Typography>

          <ButtonPrimary
            className={classes.appBarButton}
            variant="outlined"
            onClick={
              props.customDialogForm
                ? props.customDialogForm.onClose
                : handleClose
            }
          >
            {Data.closeBtnTitle || props.t("cancel")}
          </ButtonPrimary>
        </Toolbar>
      </AppBar>
      {FormElement}
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return { dialogForm: state.dialogForm };
};

export default connect(mapStateToProps, {
  triggerNotification,
  triggerDialogForm,
  clearDialogData,
})(translationHook(DialogForm));
