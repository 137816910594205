
import apiConfig from '../apiConfig';

export const getTemplatesSystemVariablesList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/GET_TEMPLATES_SYSTEM_VARIABLES_LIST', {}, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data;
};
// UPD_TEMPLATES_SYSTEM_VARIABLES
export const updTemplatesSystemVariablesList = async (at , data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/UPD_TEMPLATES_SYSTEM_VARIABLES', data, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data;
};
// GET_TEMPLATES_SYSTEM_VARIABLES_INFO
export const updTemplatesSystemVariableInfo = async (at , data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/GET_TEMPLATES_SYSTEM_VARIABLES_INFO', data, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data;
};
