import apiConfig from '../apiConfig'

export const deleteServiceAction = async (at, serviceid, actionId, url, stepId) => {
    const response = await apiConfig.post(
        url,
        {
            Service_Id: serviceid,
            Service_Action_Id: actionId,
            Service_Step_Id: stepId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
