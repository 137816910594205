import { TRIGGER_DIALOG } from '../actions/types'

const INIT_DIALOG = {
    Open: false,
    Data: {}
}

const dialogReducer = (status = INIT_DIALOG, action) => {

    switch (action.type) {
        case TRIGGER_DIALOG:

            return {...status, ...action.payload}


        default:
            return status
    }

}

export default dialogReducer