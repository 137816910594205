import apiConfig from '../../apiConfig';

export const getServicesList = async (at , profileId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PROFILESERVICES/GET_PROFILE_STATUS_SERVICES_LIST',
        {
            pageIndex: 1,
            pageCount: 10000,
            Profile_Active_Status_Id: profileId
        }, {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
    return response.data.Data.Data;
};

export const addProfileToService = async (at, data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PROFILESERVICES/ADD_PROFILE_ACTIVE_STATUS_SERVICE',
        data, {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
    return response.data;
};
export const removeProfileService = async (at, data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PROFILESERVICES/DEL_PROFILE_ACTIVE_STATUS_SERVICE',
        data, {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
    return response.data;
};

export const getAllServicesList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PROFILESERVICES/GET_PROFILE_STATUS_SERVICES_ALL_LIST',
        {
            pageIndex: 1,
            pageCount: 10000
        }, {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);


    return response.data.Data.Data;
};
