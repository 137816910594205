import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches, Select } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'

import UserGroupsScreenList from './UserGroupsScreenList'


class UserGroupsFrom extends Component {

    state = {
        showForm: false,
        showSectionsList: false,
        jobTypes: []
    }

    renderScreensList = (...params) => {
        return <UserGroupsScreenList userGroupId={params[0]} mood={params[1]}/>
    }

    handleScreens = (data, mood) => {
        let userGroupId = data.User_Group_Id,
        groupName = this.props.i18n.language === 'ar' ? data.User_Group_Name_AR : data.User_Group_Name_EN,
        dialogTitle = `${this.props.viewMood ? this.props.t('jobSections') : this.props.t('selectJobSections')} - ${groupName}`

		
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderScreensList,
            params: [
                userGroupId,
                mood
            ]
        })
    }

    onSectionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/SYSTEM/USERS/GROUPS/ADD_USERS_GROUP' : '/API/SYSTEM/USERS/GROUPS/UPD_USERS_GROUP',
        bodyData = {
            User_Group_Name_AR: values.groupNameAR,
            User_Group_Name_EN: values.groupNameEN,
        }

        if (this.props.editMood) {
            bodyData.User_Group_Id = this.props.groupId
        }


        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )
        

        
        if (response.data.Status) {
            form.restart()
            if (!this.props.editMood) {
                this.handleScreens(response.data.Data)
            } else {
                this.setState({ showSectionsList: true })
            }
        }
        
        this.props.triggerNotification(true, response.data)
        
        this.props.setLoading(false)
        
    }
    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}

            if (!values.groupNameAR) {
                errors.groupNameAR = `${this.props.t('error.field_required')}`
            }

            if (!values.groupNameEN) {
                errors.groupNameEN = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('manageuserGroups')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addGroup')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }

                
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues ? initValues : {
                        groupNameAR: '',
                        groupNameEN: '',
                        groupNumber: ''
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('groupNumber')}
                                    className="mb-2"
                                    name="groupNumber"
                                />
                            </Col>


                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('groupNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="groupNameAR"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('groupNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="groupNameEN"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                       
                        </form>
                    )}
                />
                {/* && this.state.showSectionsList */}
               {this.props.editMood &&
                <UserGroupsScreenList userGroupId={this.props.groupId} mood={'edit'}/>
                } 
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(UserGroupsFrom))
