import apiConfig from "../../apiConfig";

export const getUserAreas = async (at, id) => {
  const response = await apiConfig.post(
    "API/BASIC/SETTINGS/USERS_AREAS/GET_USER_AREAS_LIST",
    {
      User_Id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  if (response.data.Status) {
    return response.data.Data;
  }

  return [];
};
