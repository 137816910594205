import React, { Component, useCallback } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import Processing from "../requests/processing/Processing";
import style from "./Dashboard.module.scss";
import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
  StateStoring,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import CustomStore from "devextreme/data/custom_store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import {
  triggerDialog,
  triggerDialogForm,
  setLoading,
  triggerNotification,
  getDialogData,
} from "./../../actions";
import { translationHook } from "./../translationHook";
import { ButtonInfo } from "./../UI/Buttons";
import apiConfig from "./../../apis/apiConfig";
import TaskForm from "./TaskForm";

class DashboardList extends Component {
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.devRef = React.createRef(null);
    this.searchState = React.createRef();
  }

  state = {
    gridState: [],
    gridKey: "",
    filterResults: 0,
    showGrid: false,
    searchResults: 0,
    saveGrid: false,
    pageSize: null,
    focusRow: -1,
  };

  renderEditForm = (...params) => {
    return <TaskForm missionID={params[0]} initValues={params[1]} />;
  };

  renderViewForm = (params) => {
    return (
      <div
        className={style.dashboardListContainer}
        style={{
          padding: params.viewMood ? "7rem 2rem 0 2rem" : "7rem 2rem 0 2rem",
        }}
      >
        <Processing dataListObject={params} />
      </div>
    );
  };

  handleEdit = (data) => {
    let missionId = data.Mission_Id,
      initValues = data,
      missionTitle = data.Mission_Title,
      dialogTitle = `${this.props.t("view") + " " + missionTitle}`;

    this.props.getDialogData({
      title: dialogTitle,
      missionId: missionId,
      params: [missionId, initValues],
    });

    this.props.triggerDialogForm(true, {
      title: dialogTitle,
      closeBtnTitle: this.props.t("cancelToDashboard"),
      renderForm: this.renderEditForm,
      params: [missionId, initValues],
    });
    // this.props.clearDashboard();
  };

  handleEditView = (data, isEdit) => {
    let missionTitle = data.Request_Task_Title_Name_AR;
    let dialogTitle = `${this.props.t("view") + " " + missionTitle}`;

    this.props.getDialogData({
      title: dialogTitle,
      params: [
        {
          requestStatusId: data.Request_Status_Id,
          viewMood: isEdit,
          isRequestsList: true,
          requestId: data.Request_Id,
          requestTaskId: data.Request_Task_Id,
        },
      ],
    });

    this.props.triggerDialogForm(true, {
      title: dialogTitle,
      closeBtnTitle: this.props.t("cancelToDashboard"),
      renderForm: this.renderViewForm,
      params: [
        {
          requestStatusId: data.Request_Status_Id,
          viewMood: isEdit,
          isRequestsList: true,
          requestId: data.Request_Id,
          requestTaskId: data.Request_Task_Id,
        },
      ],
    });
    // this.props.clearDashboard();
  };

  saveState = async () => {
    // if (this.state.saveGrid) {
    const at = this.props.currentUser.Data.access_token,
      url = "API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS",
      bodyData = {
        System_Grid_Name: this?.props?.systemGridName,
        System_Grid_Json: this.searchState.current,
        Lang: this.props.i18n.language,
      };

    await apiConfig.post(url, bodyData, {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    });

    this.props.triggerNotification(true, {
			Status: true,
		})
    // }
  };

  getState = async (state) => {
    if (state) {
      state.searchText = "";
      state.columns.map((s) => (s.filterValue = undefined));
      this.searchState.current = JSON.stringify(state);
    }
  };

  loadState = async () => {
    const at = this.props.currentUser.Data.access_token,
      url = "API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS",
      bodyData = {
        System_Grid_Name: this?.props?.systemGridName,
        Lang: this.props.i18n.language,
      };

    const response = await apiConfig.post(url, bodyData, {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    });

    if (response.data) {
      this.setState({
        gridState: JSON.parse(response?.data?.Data),
        showGrid: true,
        pageSize: JSON.parse(response?.data?.Data)?.pageSize,
      });
    }
    // this.props.showTitle(true);
  };

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: this.props.t("saveGridSettings"),
          height: "36px",
          icon: "save",
          onClick: () => this.saveState(),
          hint: this.props.t("saveGridSettings"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: this.props.t("clearSearch"),
          height: "36px",
          icon: "clearformat",
          onClick: () => this.dataGrid?.instance?.clearFilter(),
          hint: this.props.t("clearSearch"),
        },
      }
    );
  }

  handleOnContentReady = (e) => {
    if(this.props.i18n.language === "ar") {
      const scrollable = e.component.getScrollable();
      const maxScrollLeft = scrollable.scrollWidth();

      scrollable.scrollTo({
        x: maxScrollLeft
      })
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cardId !== prevProps.cardId) {
      this.setState({ showGrid: false, saveGrid: false });
      // this.props.showTitle(false);
      this.dataGrid?.instance?.clearFilter();
      this.loadState();
    }
  }

  render() {
    const jsonDataSource = new CustomStore({
      key: this.props.cardId == 2701 ? "Mission_Id" : "Row_Id",
      load: () => {
        let data = this.props.dataList;
        return data;
      },
    });

    return (
      <>
        {this.props.cardId ? (
          <DataGrid
            className={`${[
              this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
              "globalBox ",
              this.state.showGrid ? "d-block" : "d-none",
            ].join(" ")} `}
            id="dataGrid"
            width={"100%"}
            dataSource={jsonDataSource}
            showBorders={false}
            zshowColumnLines={false}
            showRowLines={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            allowColumnReordering={true}
            rowAlternationEnabled={false}
            focusedRowEnabled={true}
            onExporting={this.onExporting}
            // columnWidth={"auto"}
            wordWrapEnabled={true}
            onToolbarPreparing={this.onToolbarPreparing}
            bounceEnabled={false}
            // onRowClick={e => this.setState({ focusRow: e.rowIndex })}
            // focusedRowIndex={this.state.focusRow}
            // onOptionChanged={(e) => {
            //   console.log(e)
            //   if (e.name == "focusedRowIndex" || e.name == "focusedRowKey" || e.name == "focusedColumnIndex") {
            //     return
            //   }
            //   // if (e.name == "columns") {
            //   //   this.setState({ saveGrid: true })
            //   //   return
            //   // }
            //   // this.setState({ saveGrid: true })
            //   if (e.name == "paging") {
            //     this.setState({ pageSize: e.value })
            //   }
            // }}
            onOptionChanged={(e) => {
              if (e?.fullName == "paging.pageSize") {
                this.setState({ pageSize: e.value });
              }

              if (e.fullName.split(".").pop() == "filterValue") {
                this.dataGrid.instance.refresh();
              }
            }}
            onContentReady={this.handleOnContentReady}
            remoteOperations={false}
            rtlEnabled={this.props.i18n.language === "ar" ? true : false}
            ref={(ref) => (this.dataGrid = ref)}
          >
            <ColumnChooser
              enabled={true}
              allowSearch={true}
              search={"fff"}
              title={this.props.t("dxColumnChooserTitle")}
              emptyPanelText={this.props.t("dxColumnChooserPanelText")}
            />

            <Pager
              visible="true"
              allowedPageSizes={[5, 10, 20, 50, 100]}
              displayMode="full"
              showPageSizeSelector="true"
              showInfo="true"
              infoText=""
              // infoText={`${this.props.t("page")} {0} ${this.props.t(
              //   "of"
              // )} {1} ({2} ${this.props.t("item")})`}
              showNavigationButtons="true"
            />

            <Paging
              enabled={true}
              defaultPageSize={this.state.pageSize}
              pageSize={this.state.pageSize}
            />

            <StateStoring
              enabled={true}
              type="custom"
              // customLoad={this.loadState}
              customSave={this.getState}
            />

            <SearchPanel
              visible={true}
              width={240}
              placeholder={this.props.t("search")}
            />

            <FilterRow visible={true} applyFilter={true} />

            <HeaderFilter visible={true} />

            <Export enabled={true} allowExportSelectedData={true} />

            <Grouping contextMenuEnabled={true} expandMode="rowClick" />

            <GroupPanel
              visible={true}
              emptyPanelText={this.props.t("dxGroupPanelText")}
            />

            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"always"}
            />

            {this.state.gridState?.columns
              ?.filter((d) => d.name !== "buttons")
              .map((d, i) => (
                <Column
                  dataField={d.dataField}
                  caption={d.name}
                  alignment={this.props.i18n.language === "ar" ? 'right': 'left'}
                  visible={d.visible}
                  width={d.width ? d.width : "auto"}
                  dataType={d.dataType}
                  format={d.dataType == "date" ? "yyyy-MM-dd" : ""}
                ></Column>
              ))}

            <Column
              cssClass="outlinedColumn nowrapColumn"
              type="buttons"
              caption={this.props.t("details")}
              fixed={true}
              width="95"
              fixedPosition={
                this.props.i18n.language === "ar" ? "left" : "right"
              }
            >
              {this.props.dataType == "Grid_Dashboard_Requests" && (
                <Button
                  render={(record) => {
                    return (
                      <ButtonInfo
                        variant="circleIcon"
                        onClick={() => this.handleEditView(record.data, false)}
                      >
                        <EditIcon />
                      </ButtonInfo>
                    );
                  }}
                />
              )}

              {this.props.dataType == "Grid_Dashboard_View_Requests" && (
                <Button
                  render={(record) => {
                    return (
                      <ButtonInfo
                        variant="outlined"
                        id="ds-grid-edit-btn"
                        onClick={() => this.handleEditView(record.data, true)}
                      >
                        <VisibilityIcon />
                      </ButtonInfo>
                    );
                  }}
                />
              )}

              {this.props.dataType == "Grid_Dashboard_Missions" && (
                <Button
                  render={(record) => {
                    return (
                      <ButtonInfo
                        variant="circleIcon"
                        onClick={() => {
                          this.handleEdit(record.data);
                        }}
                        startIcon={<VisibilityIcon />}
                      ></ButtonInfo>
                    );
                  }}
                />
              )}
            </Column>
          </DataGrid>
        ) : null}
      </>
    );
  }

  onExporting(e) {
    let userType =
      this.props.i18n.language === "ar"
        ? this.props.currentUser.Data.Department_Job_Type_Name_AR
        : this.props.currentUser.Data.Department_Job_Type_Name_EN;
    let gridName = this.props.gridName;
    let name = `${this.props.t("dashboard")} ${userType} - ${gridName}`;
    let sheetTitle = name.charAt(0).toUpperCase() + name.slice(1);
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGridToExcel({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 2 },
      topRightCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        // excelCell.value = gridCell.value;
        excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        excelCell.alignment = {
          indent: 1,
          wrapText: true,
          vertical: "middle",
        };
        if (gridCell.rowType === "group") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          excelCell.alignment = { indent: 2 };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          excelCell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
      },
    })
      .then(() => {
        // header
        const headerRow = worksheet.getRow(1);
        headerRow.height = 40;
        let centerCell = Math.round(worksheet.actualColumnCount / 2);
        headerRow.getCell(centerCell).value = this.props.gridName;
        headerRow.getCell(centerCell).font = {
          name: "Segoe UI Light",
          size: 22,
          bold: true,
        };
        headerRow.getCell(centerCell).alignment = { horizontal: "center" };
      })
      .then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${sheetTitle}.xlsx`
          );
        });
      });
    e.cancel = true;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerDialog,
  triggerDialogForm,
  getDialogData,
  setLoading,
  triggerNotification,
})(translationHook(DashboardList));
