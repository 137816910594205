import apiConfig from "../apiConfig";

export const updateCardAttachment = async (
	at,
	cardId,
	file
) => {
	const bodyFormData = new FormData(),
		url =
			"/API/BASIC/SETTINGS/DASHBOARD_CARDS/UPD_DASHBOARD_CARD_ICON",
		bodyData = { Dashboard_Card_Id: cardId };

	bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

	bodyFormData.append("Card_Icon", file);

	const response = await apiConfig.post(url, bodyFormData, {
		headers: {
			Authorization: `Bearer ${at}`,
		},
	});

	return response;
};
