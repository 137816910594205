import apiConfig from '../../apiConfig'

export const deleteDepartmentVersionStep = async (...params) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/DEL_DEPARTMENT_VERSION_STEP',
        {
            Department_Service_Step_Id: params[1]
        },
        {
            headers: {
                Authorization: `Bearer ${params[0]}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
