import apiConfig from "../../apiConfig";

export const getDepartmentJobs = async (at) => {
  const response = await apiConfig.post(
    "/API/BASIC/SETTINGS/USERS_AREAS/GET_DEPARTMENT_JOBS_LIST",
    {},
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  if (response.data.Status) {
    return response.data.Data;
  }

  return [];
};
