import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ButtonPrimary } from '../../UI/Buttons'
import { Form } from 'react-final-form'
import { TextField, Select, Switches } from 'mui-rff'

import apiConfig from '../../../apis/apiConfig';
import { translationHook } from '../../translationHook'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'



class TemplateDetailForm extends Component {

    state = {
        showForm: false,
        detailTypes: [],
        serviceLookups: [],
        detaillPredefinedList: []
    }

    getDetailTypes = async ()=> {
        let typesArr = []
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_TEMPLATES_VARIABLES_TYPES'
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN

                typesArr.push({value: item.Record_Id, label})
            })

            this.setState({detailTypes: typesArr})
            
        }

    }

    getServiceLookups = async ()=> {
        let lookupsArr = []
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_SERVICES_LOOKUPS'
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN

                lookupsArr.push({value: item.Record_Id, label})
            })

            this.setState({serviceLookups: lookupsArr})
            
        }

    }

    getServiceDetaillPredefinedList = async () => {
        let detaillPredefinedList = [{value: null, label: this.props.t('withoutSelection')}]
        const response = await apiConfig.post('/API/ADMINISTRATION/SERVICES/GET_PREDEFINED_FIELDS', {}, {
            headers: {
				Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
			},
        })
        if (response.data.Status) {
            response.data.Data?.map((item)=> {
                let label = this.props.i18n.language === 'ar' ? item.Service_Detail_Predefined_Field_Name_AR : item.Service_Detail_Predefined_Field_Name_EN
                detaillPredefinedList.push({value: item.Service_Detail_Predefined_Field_Id, label})
            })
        }
        this.setState({ detaillPredefinedList })
    }

    validate = values => {
        const errors = {}


        if (!values.valueNameAR) {
            errors.valueNameAR = `${this.props.t('error.field_required')}`
        }

        if (!values.valueNameEN) {
            errors.valueNameEN = `${this.props.t('error.field_required')}`
        }

        if (!values.detailType) {
            errors.detailType = `${this.props.t('error.field_required')}`
        }

        if ( values.detailType === 2 && !values.serviceLookups ) {
            errors.serviceLookups = `${this.props.t('error.field_required')}`
        }


        
        return errors;
    }

    onServiceDetailSubmit = async (values, form)=> {

        let at = this.props.currentUser.Data.access_token,
        url = this.props.editMood ? '/API/ADMINISTRATION/TEMPLATES/Upd_Template_Variable/' : 
        '/API/ADMINISTRATION/TEMPLATES/ADD_TEMPLATE_VARIABLE';



        let bodyData = {
            Template_id: this.props.templateId,
            Template_Variable_Name_AR: values.valueNameAR,
            Template_Variable_Name_EN: values.valueNameEN,
            Template_Variable_Code: values.valueCode,
            Active_Status_Id: values.required ? 1 : 0,
            Template_Variable_Type_Id: values.detailType,
            Template_Variable_Lookup_Id: values.serviceLookups,
            Service_Detail_Predefined_Field_Id: values.Service_Detail_Predefined_Field_Id
        }

        if (this.props.editMood) {
            bodyData.Template_Variable_Id = this.props.templateVarId
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        ).catch((error) => {
            return error.response
        })

        this.props.setLoading(false)

        this.props.triggerNotification(true, response.data)

        if (response.data.Status) {
            if (this.props.editMood) {
                this.props.triggerDialogForm(false)
            }
            this.props.reloadDatagrid()
            form.restart()
        }
    }

    componentDidMount() {
        this.getDetailTypes()
        this.getServiceLookups()
        this.getServiceDetaillPredefinedList()
    }

    render() {
        let { initValues } = this.props
        console.log(initValues)
        return (
            <>
            {!this.props.editMood && !this.props.viewMood && 
                <div className="d-flex justify-content-end">
                    <ButtonPrimary
                        variant={!this.state.showForm ? 'contained' : 'outlined'}
                        onClick={() => this.setState({showForm: !this.state.showForm}) }
                    >
                        {!this.state.showForm && this.props.t('addVal')}
                        {this.state.showForm && this.props.t('cancel')}
                    </ButtonPrimary>
                </div>
            }
            <Form
                onSubmit={this.onServiceDetailSubmit}
                initialValues={ initValues ? initValues : {
                    tempVarId: '',
                    valueNameAR: '',
                    valueNameEN: '',
                    Service_Detail_Predefined_Field_Id: '',
                    required: true,
                }}
                validate={this.validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    if ( values.detailType !== 2 && values.serviceLookups ) {
                        delete values.serviceLookups
                    }

                return (
                    <form
                    id="serviceDetailForm"
                    className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                    onSubmit={handleSubmit}
                    noValidate>
                        <Col xs={12} md={6}>
                            <TextField
                                label={this.props.t('arrange Number')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                name="tempVarId"
                                disabled
                            />
                        </Col>
                        <Col xs={12} md={6} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                            <Switches
                                disabled={this.props.viewMood}
                                color="primary"
                                name="required"
                                data={{ label: `${this.props.t('active')}`, value: `${this.props.t('active')}` }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                disabled={this.props.viewMood}
                                label={this.props.t('ModuleValuesAR')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                required={true}
                                name="valueNameAR"
                                autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => { 
                                    return submitFailed && invalid 
                                } }
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                disabled={this.props.viewMood}
                                label={this.props.t('ModuleValuesEN')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                required={true}
                                name="valueNameEN"
                                autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => { 
                                    return submitFailed && invalid 
                                } }
                            />
                        </Col>

                        <Col xs={12} md={6}>
                            <Select
                                disabled={this.props.viewMood}
                                label={this.props.t('servicedetailtype')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                required={true}
                                name="detailType"
                                data={this.state.detailTypes}
                                showError={  () => {
                                    let fieldStatus = form.getFieldState('detailType')
    
                                    if (fieldStatus) {
    
                                        return fieldStatus.submitFailed && fieldStatus.invalid
                                    }
                                } }
                            >
                            </Select>
                        </Col>
                        
                        <Col xs={12} md={6}>
                            <Select
                                disabled={this.props.viewMood}
                                label={this.props.t('predefinedField')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                name="Service_Detail_Predefined_Field_Id"
                                data={this.state.detaillPredefinedList}
                            >
                            </Select>
                        </Col>

                        { values.detailType == 2 &&
                        <Col xs={12} md={6}>
                            <Select
                                disabled={this.props.viewMood}
                                label={this.props.t('dropdownListName')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                required={true}
                                name="serviceLookups"
                                data={this.state.serviceLookups}
                                showError={  () => {
                                    let fieldStatus = form.getFieldState('serviceLookups')

                                    if (fieldStatus) {

                                        return fieldStatus.submitFailed && fieldStatus.invalid
                                    }
                                } }
                            >
                            </Select>
                        </Col>
                        }

                        <Col xs={12} md={6}>
                            <TextField
                                disabled={this.props.viewMood}
                                label={this.props.t('valueCode')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                required={true}
                                name="valueCode"
                                autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => { 
                                    return submitFailed && invalid 
                                } }
                            />
                        </Col>
                        
                        <Col className='mt-4' xs={12}>
                            <Row className='align-items-baseline'>
                                <Col className='mt-2 submit-btn-wrap'>
                                    { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        
                    </form>
                    )
                }
            }
            />
            </>
        ) 
    }
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(TemplateDetailForm))