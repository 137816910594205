import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import style from "./account.module.scss";
import EditIcon from "@material-ui/icons/Edit";
import { ButtonSecondary } from "../../UI/Buttons";
import { triggerDialogForm } from "../../../actions";
import NoteForm from "./NoteForm";

class AccountInfo extends Component {
  renderForm = (...params) => {
    return (
      <div className="p-5 text-start">
        <NoteForm
          profileId={params[0]}
          status={params[2]}
          verification={params[1]}
        />
      </div>
    );
  };
  handleProcess = async () => {
    let {
      Client_Profile_Id,
      Profile_Active_Status_Id,
      Verification_Status_Id,
    } = this.props.request;
    this.props.triggerDialogForm(true, {
      title: this.props.t("viewRequest"),
      // انت الان تقوم بمعالجه علي الطلب
      renderForm: this.renderForm,
      params: [
        Client_Profile_Id,
        Verification_Status_Id,
        Profile_Active_Status_Id,
      ],
    });
  };

  render() {
    return (
      <>
        <div className={`${style.actionsWrap} d-flex align-items-center`}>
          {this.props.request.Verification_Status_Id !== 2 ? (
            <ButtonSecondary variant="outlined" onClick={this.handleProcess}>
              <EditIcon />
              {this.props.t("handleAccount")}
            </ButtonSecondary>
          ) : (
            <p className="text-danger">{this.props.t("rejectedAccount")}</p>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, { triggerDialogForm })(
  translationHook(AccountInfo)
);
