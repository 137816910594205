import { LOGIN_USER, LOGOUT_USER, GET_USER_DETAILS ,
    RESET_PASS,
    VERIFY_MAIL,
    VERIFY_PHONE } from '../actions/types'

const INIT_CURRENT_USER = {
    Status: false,
    Data: {}
}

const currentUserReducer = (currentUser = INIT_CURRENT_USER, action) => {

    switch (action.type) {
        case LOGIN_USER:
            
            return {...currentUser, ...action.payload, ...action.resetPass}

        case LOGOUT_USER:
            
            return action.payload

        case GET_USER_DETAILS:
        
            return {...currentUser, ...action.payload}
    
        default:
            return currentUser
    }

}

export default currentUserReducer