import apiConfig from '../apiConfig'

export const getServiceActionsListVersions = async (at, departmentId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SERVICE_VERSION_ACTIONS_LIST',
        {
            Department_Service_Version_Id: departmentId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )




    

    if (response.data.Status) {
        if (response.data.Data.Active_Status_Id === 1) {
            response.data.Data.Active_Status_Id = true;
        } else {
            response.data.Data.Active_Status_Id = false;
        }
        
        return response.data.Data
    }

    return []
}
