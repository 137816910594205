import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';

import { getHolidaysList , setHolidaysStatus, deleteHolidays } from '../../../apis/system/holidays';
import { triggerDialog, setLoading, triggerNotification, triggerDialogForm } from '../../../actions';
import { translationHook } from '../../translationHook';
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from '../../UI/Buttons';

import VacationForm from './VacationForm';
import moment from 'moment';
class VacationsList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		girdPaused: false
	}


	pauseGrid = (state) => {
		this.setState({ girdPaused: state })
	}

	customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Active_Status_Id}
			name={`status_${data.Official_holiday_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					holidayId = data.Official_holiday_Id,
					status = data.Active_Status_Id ? 0 : 1

					this.props.setLoading(true, 'dark')

					const response = await setHolidaysStatus(at, holidayId, status)

					this.props.triggerNotification(true, response)

					this.props.setLoading(false)
			}}
			/>
        )
        
    }

	renderJobForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
			editMood = params[2] === 'edit' ? true : false

        return <VacationForm holidayId={params[0]} initValues={params[1]} editMood={editMood} viewMood={viewMood} />
    }

	handleEdit = (data, viewMood) => {
		let holidayId = data.Official_holiday_Id,
        holidayName = data.Official_holiday_Name,
        dialogTitle = `${viewMood === 'view' ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${holidayName}`


		if ( viewMood === 'edit' ) {
			// pause Jobs grid to prevent from re-rendring the datagrid when submitting edits in popup & pass it as a cancel callback when closing the popuo to get the updated data in jobs list
			this.pauseGrid(true)
		}

		
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderJobForm,
            params: [
                holidayId,
                {
					...data,
					Official_holiday_to_Date: moment(data.Official_holiday_to_Date).format('YYYY-MM-DD'),
					Official_holiday_from_Date: moment(data.Official_holiday_from_Date).format('YYYY-MM-DD'),

				},
				viewMood
            ],
			dismissAction: viewMood === 'edit' ? {
				action: this.pauseGrid,
				params: [
					false
				]
			} : false
        })
	}

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			holidayId = data.Official_holiday_Id,
			holidayName = data.Official_holiday_Name,
			dialogTitle = `${this.props.t('confirmDeleteTitle')} ${holidayName}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteHolidays,
				params: [
					at,
					holidayId,
				],
				text: this.props.t('deleteHoliday'),
			},
		});
	};

	render() {
		let columnAlign = 'left',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right';
		}

		const jsonDataSource = new CustomStore({
			key: 'Official_holiday_Id',
			load: () => {

				return getHolidaysList(at, )
			},
		});

		return (
			<>
				<VacationForm />

				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
					dataField="Official_holiday_No"
					caption={this.props.t('holidayNo')}
					alignment={'center'}
					>
					</Column>
					<Column
					dataField="Official_holiday_Year"
					caption={this.props.t('holidayYear')}
					alignment={'center'}
					>
					</Column>

					<Column
						dataField={'Official_holiday_Name'}
						caption={this.props.t('holidayName')}
						alignment={'center'}></Column>
					<Column
					dataField='Active_Status_Id'
					caption={this.props.t('serviceStatus')}
					allowFiltering={false}
					cellRender={this.customizeCellCheckbox}
					alignment={'center'}
					>
					</Column>
					<Column
						dataField={'Official_holiday_from_Date'}
						caption={this.props.t('fromDate')}
						alignment={'center'}
						cellRender={({data}) => moment(data.Official_holiday_from_Date).format('YYYY-MM-DD')}
						>

						</Column>
					<Column
						dataField={'Official_holiday_to_Date'}
						caption={this.props.t('toDate')}
						alignment={'center'}
						cellRender={({data}) => moment(data.Official_holiday_to_Date).format('YYYY-MM-DD')}
						></Column>


					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
									variant='circleIcon'
									startIcon={<EditIcon />}
									onClick={() => this.handleEdit(record.data, 'edit') }
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
									variant='circleIcon'
									startIcon={<VisibilityIcon />}
									onClick={() => this.handleEdit(record.data, 'view') }
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant='circleIcon'
										startIcon={<DeleteIcon />}
										onClick={() => this.handleDelete(record.data)}>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	
	onExporting(e) {
		let sheetTitle = this.props.t('departmentJobs')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog, triggerNotification, setLoading, triggerDialogForm })(
	translationHook(VacationsList)
);
