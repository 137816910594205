import apiConfig from '../apiConfig'

export const deleteUser = async (at, userId) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/USERS/DEL_USER',
        {
            User_Account_Id: userId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}

export const deleteProfile = async (at, userId) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/USERS/DEL_USER_PROFILE',
        {
            User_Id: userId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
