import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { ButtonPrimary, ButtonSecondary } from '../../UI/Buttons';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import EditIcon from '@material-ui/icons/Edit';
import FilesUpload from '../../UI/FileUpload/UploadFile';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import style from '../accounts/account.module.scss';
import { downloadRequestAttachment } from '../../../apis/requests/info/downloadRequestAttachment'
import { deleteInvoice } from '../../../apis/system/invoices/index'
import InvoiceDetails from './invoiceDetails';
import InvoicesEditsList from './InvoiceEditsList';
import { Link } from '@reach/router';
import moment from 'moment'
import { triggerDialog, triggerDialogForm, setLoading, triggerNotification  } from '../../../actions';
import { Hidden } from '@material-ui/core';



class InvoiceForm extends Component {
	state = {
		initialValues: {},
		center: {},
		file: null,
		url: null,
		key: 0,
		next: 1,
		prev: -1,
		payerDataEditEnabled: false
	};

	componentDidMount() {
	}

	handleNext = () => {
		this.setState({
			key: this.state.next,
			next: this.state.next + 1,
			prev: this.state.prev + 1,
		});
	};

	handlePrev = () => {
		this.setState({
			key: this.state.prev,
			next: this.state.next - 1,
			prev: this.state.prev - 1,
		});
	};

	renderChevs = () => {
		let rtl = this.props.i18n.language === 'ar' ? true : false,
			iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
			iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

		return (
			<>
				<IconButton
					aria-label='prev'
					color='primary'
					onClick={this.handlePrev}
					disabled={this.state.key > 0 ? false : true}>
					{iconPrev}
				</IconButton>
				<IconButton
					aria-label='next'
					color='primary'
					onClick={this.handleNext}
					disabled={this.state.key < 3 && !this.state.second ? false : true}>
					{iconNext}
				</IconButton>
			</>
		);
	};

	onSubmit = async (values) => {
	};

	handleDelete = () => {
		let at = this.props.currentUser.Data.access_token,
			invoiceId = this.props.initValues.Invoice_Data.Invoice_Id,
			dialogTitle = `${this.props.t('confirmDeleteTitle')}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteInvoice,
				params: [
					at,
					invoiceId
				],
				text: this.props.t('delete'),
			},
		});
	}

	handleUpdateInvoicePayerInfo = async (values) => {
		const auth = this.props.currentUser.Data.access_token,
			url = '/API/ADMINISTRATION/PAYMENTS/INVOICES/UPD_INVOICE_PAYER_INFO'
			this.props.setLoading(true, 'dark');

			const response = await apiConfig
			.post(url, values, {
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

			if (response.data.Status) {
				this.setState({payerDataEditEnabled: false})
			}
		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);
	}

	refreshInvoice = async ( invoiceId ) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
          '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/REFRESH_REQUEST_INVOICE_STATUS',
          {Invoice_Id: invoiceId},
          {
              headers: {
                  Authorization: `Bearer ${at}`
              },
          }
        ).catch((error) => {
            return error.response
        })


        this.props.setLoading(false)

        if (response.status === 200) {
            const status = response.data.Data.Refresh_Invoice_Action_Id
            if (status === 1) {
                this.props.triggerDialogForm(false);
                document.getElementById("inv-grid-edit-btn").click()
            } else if (status === 2) {
                let link = document.createElement('a')
                link.href = response.data.Data.Payment_URL
                link.setAttribute('target', 'blank')

                document.body.appendChild(link)

                link.click()
            }

        }
    }

	render() {

		let { Invoice_Data, Invoice_Details, Invoice_File, Payment_File, Invoice_Changes_List} = this.props.initValues;
		let mystyle;
		if (this.props.i18n.language === 'ar') {
			mystyle = 'ServiceTabsAr';
		} else {
			mystyle = 'ServiceTabsEn';
		}

		return (
			<>
				<Form
					onSubmit={this.onSubmit}
					initialValues={Invoice_Data}
					render={({ handleSubmit, form, submitting, pristine, values, handleReset }) => (
						<form
							onSubmit={handleSubmit}
							onReset={handleReset}
							className={`${
								!this.props.editMood && !this.props.viewMood ? 'd-none' : ''
							}`}
							noValidate
							className="mt-4 mx-3 departmentFormClass"
						>
							<Tab.Container
								id='left-tabs-example'
								defaultActiveKey={0}
								activeKey={this.state.key}
								onSelect={(k) =>
									this.setState({
										key: parseInt(k),
										next: parseInt(k) + 1,
										prev: parseInt(k) - 1,
									})
								}>
								<Row>
									<Col
										sm={12}
										className='d-flex justify-content-center align-items-center'>
										<div>{this.renderChevs()}</div>
										<Nav variant='pills' className={style.stepsNav}>
											<Nav.Item className={mystyle}>
												<Nav.Link
													className={`${style.navLink} ${
														this.state.key === 0 ? style.active : ''
													}`}
													eventKey='0'
													disabled={this.state.first}>
													{this.props.t('invoiceInfo')}
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className={mystyle}>
												<Nav.Link
													className={`${style.navLink} ${
														this.state.key === 1 ? style.active : ''
													}`}
													eventKey='1'
													disabled={this.state.second}>
													{this.props.t('invoiceDetails')}
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className={mystyle}>
												<Nav.Link
													className={`${style.navLink} ${
														this.state.key === 2 ? style.active : ''
													}`}
													eventKey='2'
													disabled={this.state.third}>
													{this.props.t('customerInformation')}
												</Nav.Link>
											</Nav.Item>
											
											<Nav.Item className={mystyle}>
												<Nav.Link
													className={`${style.navLink} ${
														this.state.key === 3 ? style.active : ''
													}`}
													eventKey='3'
													disabled={this.state.forth}>
													{this.props.t('departmentInfo')}
												</Nav.Link>
											</Nav.Item>
											<Nav.Item className={mystyle}>
												<Nav.Link
													className={`${style.navLink} ${
														this.state.key === 4 ? style.active : ''
													}`}
													eventKey='4'
													disabled={this.state.fifth}>
													{this.props.t('invoiceAttachment')}
												</Nav.Link>
											</Nav.Item>
											{Invoice_Data.Invoice_Status_Id === 0 && (
												<Nav.Item className={mystyle}>
													<Nav.Link
														className={`${style.navLink} ${
															this.state.key === 5 ? style.active : ''
														}`}
														eventKey='5'
														disabled={this.state.sixth}>
														{this.props.t('invoiceEdits')}
													</Nav.Link>
												</Nav.Item>
											)}
										</Nav>
									</Col>
									<Col sm={12}>
										<Tab.Content>
											<Tab.Pane eventKey={0}>
												<Row>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceId')}
															className='read-only mb-3'
															name='Invoice_Id'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceNo')}
															className='read-only mb-3'
															name='Invoice_No'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceStatus')}
															className='read-only mb-3'
															name={this.props.i18n.language == 'en' ? 'Invoice_Status_Name_EN' : 'Invoice_Status_Name_AR'}
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('paymentReceiptNumber')}
															className='read-only mb-3'
															name='Payment_No'
														/>
													</Col>
													{/* <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceStatusEN')}
															className='read-only mb-3'
															name='Invoice_Status_Name_EN'
														/>
													</Col> */}
													{/* <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceCode')}
															className='read-only mb-3'
															name='Invoice_Type_Code'
														/>
													</Col> */}
													{/* <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceTypeAR')}
															className='read-only mb-3'
															name='Invoice_Type_Name_AR'
														/>
													</Col> */}
													{/* <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceTypeEN')}
															className='read-only mb-3'
															name='Invoice_Type_Name_EN'
														/>
													</Col> */}

													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoicePaidValue')}
															className='read-only mb-3'
															name='Invoice_Paid_Value'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceValue')}
															className='read-only mb-3'
															name='Invoice_Value'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceYear')}
															className='read-only mb-3'
															name='Invoice_Year'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceDate')}
															className='read-only mb-3'
															name='Invoice_Date'
															value={moment(Invoice_Data.Invoice_Date).format('h:mm:ss YYYY-MM-DD')}
														/>
													</Col>
													{/* <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoiceTime')}
															className='read-only mb-3'
															name='Ins_Time'
														/>
													</Col> */}
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('invoicePaidDate')}
															className='read-only mb-3'
															name='Invoice_Paid_Date'
															value={moment(Invoice_Data.Invoice_Paid_Date).format('h:mm:ss YYYY-MM-DD')}
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('onlineReferenceNo')}
															className='read-only mb-3'
															name='Online_Reference_No'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('requestNo')}
															className='read-only mb-3'
															name='Request_No'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('requestStatus')}
															className='read-only mb-3'
															name={this.props.i18n.language == 'en' ? 'Request_Status_Name_EN' : 'Request_Status_Name_AR'}
														/>
													</Col>
													{/* <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('requestStatusEN')}
															className='read-only mb-3'
															name='Request_Status_Name_EN'
														/>
													</Col> */}
												</Row>

												<hr />

												{Invoice_Data.Insurance_Refund_Done == 1 &&
													<>
														<Row>
															<Col xs={12}>
																<div className='d-flex justify-content-between align-items-center mb-4'>
																	<h3>{this.props.t('invoiceRedemptionData')}</h3>
																</div>
															</Col>

															<Col xs={12} md={3}>
																<TextField
																	disabled={!this.state.payerDataEditEnabled}
																	label={this.props.t('refundedValue')}
																	className='read-only mb-3'
																	name='Insurance_Refund_Value'
																/>
															</Col>

															<Col xs={12} md={3}>
																<TextField
																	disabled={!this.state.payerDataEditEnabled}
																	label={this.props.t('refundDate')}
																	className='read-only mb-3'
																	name='Insurance_Refund_Date'
																/>
															</Col>

															<Col xs={12} md={3}>
																<TextField
																	disabled={!this.state.payerDataEditEnabled}
																	label={this.props.t('refundAccount')}
																	className='read-only mb-3'
																	name='Insurance_Refund_IBAN_Account'
																/>
															</Col>

															<Col xs={12} md={3}>
																<TextField
																	disabled={!this.state.payerDataEditEnabled}
																	label={this.props.t('refundConfirmationCode')}
																	className='read-only mb-3'
																	name='Insurance_Refund_Code'
																/>
															</Col>
														</Row>
														<hr />
													</>
												}

												<Row>
													<Col xs={12}>
														<div className='d-flex justify-content-between align-items-center mb-4'>
															<h3>{this.props.t('invoicePayerData')}</h3>
														</div>
													</Col>

													<Col xs={12} md={3}>
														<TextField
															disabled={!this.state.payerDataEditEnabled}
															label={this.props.t('payerNameAR')}
															className='read-only mb-3'
															name='Invoice_Payer_Name_AR'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled={!this.state.payerDataEditEnabled}
															label={this.props.t('payerNameEN')}
															className='read-only mb-3'
															name='Invoice_Payer_Name_EN'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled={!this.state.payerDataEditEnabled}
															label={this.props.t('payerPhone')}
															className='read-only mb-3'
															name='Invoice_Payer_Phone'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled={!this.state.payerDataEditEnabled}
															label={this.props.t('payerLicense')}
															className='read-only mb-3'
															name='Invoice_Payer_License_No'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled={!this.state.payerDataEditEnabled}
															label={this.props.t('payerMail')}
															className='read-only mb-3'
															name='Invoice_Payer_Mail'
														/>
													</Col>

													<Col xs={12} md={12}>
														<Row className='d-flex justify-content-end mx-1'>
															{
																this.props.initValues.Show_Refresh_Invoice_Status ?
																	<ButtonPrimary 
																		onClick={() => {this.refreshInvoice(Invoice_Data.Invoice_Id)}}
																	>
																		{this.props.t('refreshInvoiceStatus')}
																	</ButtonPrimary>
																
															: null}
															<ButtonPrimary 
																variant="outlined"
																onClick={() => {
																	this.props.triggerDialogForm(false)
																}}
															>{this.props.t('back')}</ButtonPrimary>
														</Row>
													</Col>

													{this.state.payerDataEditEnabled ?
														<>
															<div className='d-flex justify-content-center align-items-center mx-1'>
																<ButtonPrimary 
																	onClick={() => {this.handleUpdateInvoicePayerInfo(values)}}
																>
																	{this.props.t('save')}
																</ButtonPrimary>
															</div>
															<div className='d-flex justify-content-center align-items-center'>
																<ButtonPrimary 
																	variant="outlined"
																	onClick={() => {
																		form.reset()
																		this.setState({payerDataEditEnabled: false})
																	}}
																>{this.props.t('cancel')}</ButtonPrimary>
															</div>
														</>
													: null}

													<Col xs={12} className='mb-4 mt-2'>
														<Row className='d-flex justify-content-between px-3'>
															<div>
																{this.props.initValues.Can_Update_Invoice_Payer ?
																	<div className='d-flex justify-content-center align-items-center mx-1'>
																		<ButtonPrimary 
																			variant="outlined"
																			onClick={() => this.setState({payerDataEditEnabled: true})}
																		>
																			<EditIcon /> 
																			{this.props.t('editBillPayerData')}
																		</ButtonPrimary>
																	</div>
																: null}
															</div>

															<div className='d-flex flex-row'>
																{this.props.initValues.Can_Delete_Invoice ?
																	<div className='d-flex justify-content-center align-items-center mx-1'>
																		<ButtonPrimary 
																			variant="outlined"
																			onClick={() => this.handleDelete()}
																		>
																			{this.props.t('cancelBill')}
																		</ButtonPrimary>
																	</div>
																: null}
																{this.props.initValues.Can_Update_Invoice ?
																	<div className='d-flex justify-content-center align-items-center mx-1'>
																		<ButtonPrimary 
																			variant="outlined" 
																			to={`invoices-list/edit`}
																			state={{
																				invoiceChangeData: Invoice_Data
																			}}
																			onClick={() => {
																				this.props.triggerDialogForm(false);
																			}}
																		> 
																			<EditIcon /> 
																			{this.props.t('editBill')}
																		</ButtonPrimary>
																	</div>
																: null}
															</div>
														</Row>
													</Col>
												</Row>
											</Tab.Pane>
                                            <Tab.Pane eventKey={1}>
												<InvoiceDetails details={Invoice_Details} 
												totalInvoice={Invoice_Data.Invoice_Value} />
											</Tab.Pane>
											<Tab.Pane eventKey={2}>
												<Row>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('loginName')}
                                                            className='read-only mb-3'
															name='Login_Name'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
                                                            disabled
															label={this.props.t('clientProfileId')}
															className='read-only mb-3'
															name='Client_Profile_Id'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('dealingTypeAR')}
															className='read-only mb-3'
															name='Dealing_Type_Name_AR'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('dealingTypeEN')}
															className='read-only mb-3'
															name='Dealing_Type_Name_EN'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('AccountNameAR')}
															className='read-only mb-3'
															name='Client_Name_AR'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('AccountNameEN')}
															className='read-only mb-3'
															name='Client_Name_EN'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('id')}
															className='read-only mb-3'
															name='Client_EID'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('clientPhone')}
                                                            className='read-only mb-3'
															name='Client_Phone'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('OfficialMobile')}
                                                            className='read-only mb-3'
															name='Official_Mobile'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('officialLandPhone')}
                                                            className='read-only mb-3'
															name='Official_Land_Phone'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('clientMail')}
                                                            className='read-only mb-3'
															name='Client_Mail'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('OfficialMail')}
                                                            className='read-only mb-3'
															name='Official_Mail'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseNo')}
                                                            className='read-only mb-3'
															name='License_No'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseNameAR')}className='read-only mb-3'
															name='License_Name_AR'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseNameEN')}
                                                            className='read-only mb-3'
															name='License_Name_EN'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseType')}
                                                            className='read-only mb-3'
															name='License_Type'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseCategory')}
                                                            className='read-only mb-3'
															name='License_Category'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseActivities')}
                                                            className='read-only mb-3'
															name='License_Activities'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseIssueDate')}
                                                            className='read-only mb-3'
															name='License_Issue_Date'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('licenseExpiredDate')}
                                                            className='read-only mb-3'
															name='License_Expired_Date'
														/>
													</Col>
                                                    
												</Row>
											</Tab.Pane>
                                            <Tab.Pane eventKey={3}>
                                                <Row>
												<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('munsCode')}
                                                            className='read-only mb-3'
															name='Department_Code'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('depNameAR')}
                                                            className='read-only mb-3'
															name='Department_Name_AR'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('depNameEN')}
                                                            className='read-only mb-3'
															name='Department_Name_EN'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('sectionCode')}
                                                            className='read-only mb-3'
															name='Department_Section_Code'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('sectionNameAR')}
                                                            className='read-only mb-3'
															name='Department_Section_Name_AR'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('sectionNameEN')}
                                                            className='read-only mb-3'
															name='Department_Section_Name_EN'
														/>
													</Col>
                                                    
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('departmentCode')}
                                                            className='read-only mb-3'
															name='Department_Sector_Code'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('depName')}
                                                            className='read-only mb-3'
															name='Department_Sector_Name_AR'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('depName')}
                                                            className='read-only mb-3'
															name='Department_Sector_Name_EN'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('departmentServiceStep')}
                                                            className='read-only mb-3'
															name='Department_Service_Step_Name_AR'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('departmentServiceStep')}
                                                            className='read-only mb-3'
															name='Department_Service_Step_Name_EN'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('serviceCode')}
                                                            className='read-only mb-3'
															name='Service_Code'
														/>
													</Col>
                                                    <Col xs={12} md={12}>
														<TextField
															disabled
															label={this.props.t('serviceNameAR')}
                                                            className='read-only mb-3'
															name='Service_Name_AR'
														/>
													</Col>
                                                    <Col xs={12} md={12}>
														<TextField
															disabled
															label={this.props.t('serviceNameEN')}
                                                            className='read-only mb-3'
															name='Service_Name_EN'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('serviceCategoryAR')}
                                                            className='read-only mb-3'
															name='Service_Category_Name_AR'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('serviceCategoryEN')}
                                                            className='read-only mb-3'
															name='Service_Category_Name_EN'
														/>
													</Col>
                                                    <Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('governmentEntityNameAR')}
                                                            className='read-only mb-3'
															name='Government_Entity_Name_AR'
														/>
													</Col>
													<Col xs={12} md={3}>
														<TextField
															disabled
															label={this.props.t('governmentEntityNameEN')}
                                                            className='read-only mb-3'
															name='Government_Entity_Name_EN'
														/>
													</Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={4}>
												<h5 className='my-4 text-start'>
													{this.props.t('requestAttachments')}
												</h5>
												{Invoice_File && (
													<FilesUpload
														downloadCB={downloadRequestAttachment}
														view={true}
														// attachments={[Invoice_File]}
														attachments={[{
															Attachment_Title_AR: "الفاتوره",
															Attachment_Title_EN: "Invoice",
															Files_Data: [Invoice_File]
														}]}
													/>
												)}
												{Payment_File && (
													<FilesUpload
														downloadCB={downloadRequestAttachment}
														view={true}
														attachments={[{
															Attachment_Title_AR: "سند السداد",
															Attachment_Title_EN: "Payment File",
															Files_Data: [Payment_File]}]}
													/>
												)}

                                            </Tab.Pane>
											{Invoice_Data.Invoice_Status_Id === 0 && (
                                            <Tab.Pane eventKey={5}>
												<InvoicesEditsList invoiceChanges={Invoice_Changes_List} />
											</Tab.Pane>	
											)}
										</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>
							{/* {Invoice_Data.Invoice_Status_Id === 0 && (
								<div className={`${style.actionsWrap} d-flex align-items-center`} 
								style={{zIndex: 10}}>
									<ButtonSecondary
									variant="outlined"
									component={Link}
									to={`invoices-list/edit`}
									state={{
										invoiceChangeData: Invoice_Data
									}}
									onClick={() => {
										this.props.triggerDialogForm(false);
									}}
									>
										<EditIcon />
										{this.props.t('editInvoice')}
									</ButtonSecondary>

								</div>
							)} */}
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload, };
};

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm, triggerDialog })(
	translationHook(InvoiceForm)
);
