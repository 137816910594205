import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import NewReq from "../../../images/dashboard-icons/shared/newRequests.svg";
import Rejected from "../../../images/dashboard-icons/shared/rejected.svg";
import UnderProccess from "../../../images/dashboard-icons/shared/underprocess.svg";
import EditedNots from "../../../images/dashboard-icons/shared/editedNotes.png";
import DuePayment from "../../../images/dashboard-icons/CST/duePayment.svg";
import Paid from "../../../images/dashboard-icons/CST/paid.svg";
import Trannsferred from "../../../images/dashboard-icons/CST/transferred.png";

import DashboardCard from "./../../UI/dashboardCard/index";
import OrdersList from "./OrdersList";
import "date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import { cSTDashoardStatistics } from "../../../apis/dashboards/cSTdashboard";

const CustomerSerDashboard = (props) => {
	const at = props.currentUser.Data.access_token;
	const { t, i18n } = useTranslation("common");
	const [cards, setCards] = useState([]);
	const [cardElements, setCardElements] = useState([]);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [tabsNumbers, settabsNumbers] = useState({});
	const [activeTab, setActiveTab] = useState("");
	const [activeCard, setActiveCard] = useState("");
	const [reloadGrid, triggerReloadGrid] = useState(false);
	const [intervalCB, setIntervalCB] = useState();

	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	useEffect(() => {
		(async () => {
			await cSTDashoardStatistics(at).then((data) => {
				settabsNumbers(...data);
				setActiveTab("New_Requests_Count");
			});
		})();
	}, []);

	const intervalCBF = async () => {
		await cSTDashoardStatistics(at)
			.then((data) => {
				settabsNumbers(...data);
			})
			.catch((error) => {
				if (
					error.response.status === 401 ||
					error.response.statusText === "Unauthorized"
				) {
					clearInterval(intervalCB);
				}
			});
	};

	useEffect(() => {
		clearInterval(intervalCB);
		let statInterval = setInterval(
			intervalCBF,
			props.currentUser.Data.Refresh_Time
				? props.currentUser.Data.Refresh_Time
				: 30000
		);
		setIntervalCB(statInterval);
	}, [props.currentUser]);

	useEffect(() => {
		return () => {
			clearInterval(intervalCB);
		};
	}, [intervalCB]);

	useEffect(() => {
		setCards(() => {
			let eles = [];

			for (const key in tabsNumbers) {
				if (Object.hasOwnProperty.call(tabsNumbers, key)) {
					const count = tabsNumbers[key],
						cardData = {},
						icon =
							key === "New_Requests_Count"
								? NewReq
								: key === "Under_Process_Requests_Count"
								? UnderProccess
								: key === "Paid_Requests_Count"
								? Paid
								: key === "Due_Payment_Requests_Count"
								? DuePayment
								: key === "Return_Requests_Count"
								? EditedNots
								: key === "Rejected_Requests_Count"
								? Rejected
								: key === "Transferred_Requests_Count"
								? Trannsferred
								: key === "Mission_Count"
								? Trannsferred
								: key === "Return_From_Clients"
								? EditedNots
								: "";

					cardData["key"] = key;
					cardData["card"] = (
						<DashboardCard
							title={t(key)}
							key={key}
							className="globalBox"
							count={count}
							icon={
								<img
									src={icon}
									className="img-fluid"
									alt={t(key)}
								/>
							}
							disabled={count === 0 ? true : false}
							onClick={() => {
								setActiveTab(key);
							}}
							active={activeTab === key ? true : false}
							reload={() => {
								triggerReloadGrid(true);
							}}
						/>
					);

					eles.push(cardData);
				}
			}

			return eles;
		});
	}, [tabsNumbers]);

	useEffect(() => {
		if (cards && cards.length > 0) {
			const active = cards.filter((cardData) => {
				return cardData.key === activeTab;
			});

			const remainingCards = cards.filter((cardData) => {
				return (
					cardData.key !== "Return_Requests_Count" &&
					cardData.key !== "Transferred_Requests_Count"
				);
			});

			setCardElements(remainingCards);
			setActiveCard(active[0]?.card);
		}
	}, [cards, activeTab]);

	return (
		<>
			<div className="dashboardOverlay"></div>
			<Row className="mb-5 dashboardCardsCon">
				<div className="col-12 d-flex justify-content-center align-items-center">
					<h3>{t("dashboard")}</h3>
				</div>
				{/* <div className="container">
            <div className="row align-items-center">
                    <div className="col-md-1">
                        {t('fromDate')}
                    </div>
                        <div className="col-md-3">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify='space-around'>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant='inline'
                                        format='MM/dd/yyyy'
                                        margin='normal'
                                        id='start-date'
                                        label={t('fromDate')}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-md-1">
                            {t('toDate')}
                        </div>
                        <div className="col-md-3">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify='space-around'>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant='inline'
                                        format='MM/dd/yyyy'
                                        margin='normal'
                                        id='end-date'
                                        label={t('toDate')}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div> */}
				<Col xs={12} xl={10} className="m-auto">
					<Row className="dashboardCards">
						<Col lg={4} className="active-card p-2">
							{activeCard}
						</Col>
						<Col lg={8}>
							<Row>
								{cardElements.map((cardData) => {
									return (
										<Col
											lg={4}
											className="p-2"
											key={cardData.key}
										>
											{cardData.card}
										</Col>
									);
								})}
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<OrdersList
				className="w-100"
				dataField={activeTab}
				reloadGrid={reloadGrid}
				dataLoadedCB={triggerReloadGrid}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(CustomerSerDashboard);
