import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches, Select } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'


class VacationForm extends Component {

    state = {
        showForm: false,
        showSectionsList: false,
        jobTypes: []
    }

    getJobTypes = async ()=> {
        let jobTypes = []
        

        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_DEPARTMENT_JOBS_TYPES'
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN

                jobTypes.push({value: item.Record_Id, label})
            })

            this.setState({ jobTypes })
            
        }

    }


    onSectionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/SYSTEM/OFFICIALHOLIDAY/ADD_OFFICIAL_HOLIDAY' : '/API/SYSTEM/OFFICIALHOLIDAY/UPD_OFFICIAL_HOLIDAY',
        bodyData = {
            ...values,
            Active_Status_Id: values.Active_Status_Id ? 1 : 0
        }

        if (this.props.editMood) {
            bodyData.Official_holiday_Id = this.props.holidayId
        }


        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )
        

        
        if (response.data.Status) {
            form.restart()
        }
        
        
        this.props.triggerNotification(true, response.data)
        
        this.props.setLoading(false)
        this.props.triggerDialogForm(false)
        


    }

    componentDidMount() {
        this.getJobTypes()
    }


    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}
            
            
            
            
            if (!values.Official_holiday_Name) {
                errors.Official_holiday_Name = `${this.props.t('error.field_required')}`
            }

            if (!values.Official_holiday_Year) {
                errors.Official_holiday_Year = `${this.props.t('error.field_required')}`
            }

            if (!values.Official_holiday_from_Date) {
                errors.Official_holiday_from_Date = `${this.props.t('error.field_required')}`
            }
            if (!values.Official_holiday_to_Date) {
                errors.Official_holiday_to_Date = `${this.props.t('error.field_required')}`
            }



            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('manageHolidays')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addVac')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }

                
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues ? initValues : {
                        Active_Status_Id: true,
                        Official_holiday_Name: "",
                        Official_holiday_Year: new Date().getFullYear(),
                        Official_holiday_from_Date: "",
                        Official_holiday_to_Date: ""
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >

                            <Col xs={12} md={4}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('holidayName')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="Official_holiday_Name"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={4}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('holidayYear')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="Official_holiday_Year"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={4} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="Active_Status_Id"
                                    data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('fromDate')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="Official_holiday_from_Date"
                                    type="date"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('toDate')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="Official_holiday_to_Date"
                                    required={true}
                                    type="date"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>


                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                       
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(VacationForm))
