import apiConfig from '../apiConfig';

export const deleteDepartmentSector = async (at, departmentSectorId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/DEL_DEPARTMENT_SECTOR', 
		{
			Department_Sector_Id: departmentSectorId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}
