import { TRIGGER_DIALOG_FORM } from '../actions/types'

const INIT_DIALOG = {
    Open: false,
    Data: {}
}

const dialogFormReducer = (status = INIT_DIALOG, action) => {

    switch (action.type) {
        case TRIGGER_DIALOG_FORM:

            return {...status, ...action.payload}


        default:
            return status
    }

}

export default dialogFormReducer