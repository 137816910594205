import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../../apis/apiConfig'
import { translationHook } from '../../../translationHook'
import { ButtonPrimary, ButtonSecondary } from '../../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Select, Autocomplete } from 'mui-rff'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';
import { triggerNotification, setLoading, triggerDialogForm } from '../../../../actions'
import FilesUpload from "../../../UI/FileUpload/UploadFile"
import { downloadSuspensionAttachment } from '../../../../apis/violations'

class ViolationSettlementForm extends Component {

    state = {
        violationReason: "",
        violationFiles: []
    }

    getViolationAttachmentList = async (violationId) => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/VIOLATIONS/GET_VIOLATION_ATTACHMENT_LIST'
         
     
            const response = await apiConfig.post(
            url,
                {
                    Violation_Id: violationId
                },
                {
                    headers: {
                        Authorization: `Bearer ${at}`
                    },
                }
            )

            if (response.data.Status) {
                this.setState({violationFiles: response.data.Data})
            }
    }

    
    getViolationData = async ()=> {
        let at = this.props.currentUser.Data.access_token,
        url = this.props.tempId ? '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_CLIENT_PENDING_VIOLATION_DATA' : '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_CLIENT_PENDING_VIOLATION_SETTLEMENT_APPROVING_DATA',
        body = {
            Request_Id: this.props.requestId,
            Request_Task_Id: this.props.requestTaskId,
        }
        
        if (this.props.tempId) {
            body.Temp_Action_Id = this.props.tempId
        }

        const response = await apiConfig.post(
            url,
            body,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        if (response.data.Status) {
            this.setState({violationReason: response.data?.Data?.Violation_Notes})

            if (response.data?.Data?.Violation_Id) {
                this.getViolationAttachmentList(response.data.Data.Violation_Id)
            }
        }

    }


    onSettlementSubmit = async (values, form) => {
        return null

    }

    componentDidMount() {
        this.getViolationData()
    }


    render() {
        

        const validate = values => {
            const errors = {}

            return errors
        }
     

        return (
            <>
                <Form
                onSubmit={this.onSettlementSubmit}
                initialValues={{
                    reason: this.state.violationReason
                }}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                        id="violationSettlement"
                        className="row"
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <Col xs={12} md={8}>
                            <Row>
                                {this.state.violationReason &&
                                <Col xs={12}>
                                    <TextField
                                        label={this.props.t("violationReason")}
                                        className="mb-2"
                                        name="reason"
                                        autoComplete="off"
                                        multiline
                                        rows={10}
                                        disabled
                                    />
                                </Col>
                                }

                                {this.state.violationFiles.length > 0 &&
                                <Col xs={12} md={6} className="mb-3">
                                    <h5 className="my-4 text-start">{this.props.t('violationAttachments')}</h5>
                                    <FilesUpload at={this.props.currentUser.Data.access_token} filesData={this.state.violationFiles} downloadCB={downloadSuspensionAttachment} view={true} />
                                </Col>
                                }
                            </Row>
                        </Col>

                    </form>
                )}
                />

            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(ViolationSettlementForm))
