import React, { useState, useEffect } from 'react'
import apiConfig from './../../../apis/apiConfig'
import { connect } from 'react-redux'
import { TextField } from 'mui-rff'
import { Form } from 'react-final-form'
import { navigate } from '@reach/router'

import { ButtonPrimary, ButtonDanger } from '../../UI/Buttons'
import FilesUpload from "../../UI/FileUpload/UploadFile"
import { Col } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

import { saveTaskAdditionalNotes, clearTaskData } from '../../../apis/requests/processTask'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'

import { downloadRequestAttachment } from '../../../apis/requests/info/downloadRequestAttachment'
import { deleteRequestAdditionalAttachment } from '../../../apis/requests/info/deleteRequestAdditionalAttachment'

const AdditionalAttachmentForm = (props) => {
    const [draft, saveDraft] = useState('')
    const [filesData, setFilesData] = useState([])
    const [draftData, refetchDraft] = useState(false)
    const [initSavingDraft, initDraft] = useState(false)
    const { t, i18n } = useTranslation('common')

    const onChange = (event) => {
        
        if ( !initSavingDraft ) {
            initDraft(true)
        } else {
            saveDraft(event.target.value)
        }

    }

    const handleClear = async () => {

        props.triggerDialogForm(false)

    }

    const onTaskSubmit = async () => {
        let at = props.currentUser.Data.access_token

        props.setLoading(true, 'dark')

        // save notes before submitting to make sure to get latest entered note
        await saveTaskAdditionalNotes(at, {
            Request_Id: props.requestId,
            Request_Additional_Notes: draft,
        }).then((result) => {
            
            props.setLoading(false)

            props.triggerNotification(true, result.data)

            if (result.data.Status) {
                props.triggerDialogForm(false)
                props.refetchData()
                // navigate('/servicies-requests')
            }

        }).catch((error) => {
            props.setLoading(false)
            props.triggerNotification(true, error.response)

            return error.response
        })

    }

    const getRequestAdditionalData = async () => {
        let at = props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_ADDITIONAL_DATA'
         
     
            const response = await apiConfig.post(
            url,
                {
                    Request_Id: props.requestId
                },
                {
                    headers: {
                        Authorization: `Bearer ${at}`
                    },
                }
            )

            if (response.data.Status) {
                saveDraft(response.data.Data.Request_Additional_Notes)
                setFilesData(response.data.Data.Request_Additional_Attachments)
            }
    }

    const validate = values => {
        const errors = {}

        if (!values.notes && props.required) {
            errors.notes = `${t('error.field_required')}`
        }

        return errors
    }

    useEffect(() => {

        let saveDraft

        clearInterval(saveDraft)

        if (initSavingDraft) {
            saveDraft = setInterval(async () => {
    
                const data = {
                    Request_Id: props.requestId,
                    Task_Notes: draft,
                }
    
                await saveTaskAdditionalNotes(props.currentUser.Data.access_token, data).then((result) => {
                    refetchDraft(!draftData)
                })
            }, 3000)
        }

        return () => {
            clearInterval(saveDraft)
        }

    }, [initSavingDraft, draftData])

    useEffect(() => {
        getRequestAdditionalData()
    }, [])


    return (
        <>
            <Form
                onSubmit={onTaskSubmit}
                initialValues={{notes: draft}}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                        id="taskFrom"
                        className="row"
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <Col xs={12} md={12}>
                            <TextField
                                label="ملاحظات"
                                className="mb-2"
                                name="notes"
                                required={props.required}
                                autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => {
                                    return submitFailed && invalid
                                }}
                                multiline
                                rows={12}
                                value={draft}
                                onChange={onChange}
                            />
                        </Col>

                        <Col xs={12} md={6}>
                            <h5 className="my-4 text-start">{t('attachments')}</h5>
                            <FilesUpload at={props.currentUser.Data.access_token} endPoint="API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_ADDITIONAL_ATTACHMENT" params={{"Request_Id": props.requestId}} downloadCB={downloadRequestAttachment} deleteCB={deleteRequestAdditionalAttachment} deleteParams={{"Request_Id": props.requestId}} filesData={filesData} fileApiKey="Additional_Attached_File" />
                        </Col>

                        <Col className='my-5 justify-content-start d-flex' xs={12}>
                            <ButtonPrimary
                                color="primary"
                                variant="contained"
                                disabled={submitting}
                                type="submit"
                            >
                                {t('save')}
                            </ButtonPrimary>
                            <ButtonDanger
                                variant='outlined'
                                onClick={handleClear}>
                                    {t('cancel')}
                            </ButtonDanger>
                        </Col>
                    </form>
                )}
            />
        </>
    )
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(AdditionalAttachmentForm)