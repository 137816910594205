import React from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

import { ButtonSecondary, ButtonDanger } from "../UI/Buttons";
import DeleteIcon from "@material-ui/icons/Delete";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { translationHook } from "../translationHook";
import { triggerDialog, triggerNotification } from "../../actions";

const DialogNotification = (props) => {
  let textAlign = props.i18n.language === "ar" ? "right" : "left";

  const useStyles = makeStyles((theme) => ({
    root: {
      "& *": {
        fontFamily: "Cairo, sans-serif",
      },
      "& .MuiDialogTitle-root, .MuiDialogContent-root": {
        textAlign,
      },
    },
  }));

  const classes = useStyles();

  let { Open, Data } = props.dialog;

  const handleClose = () => {
    props.triggerDialog(false, Data);
  };

  const handleConfirmAction = async () => {
    const action = Data.action.action;
    const response = await action(...Data.action.params);
    handleClose();
    props.triggerNotification(true, response);
  };

  if (!Open) {
    return null;
  }

  return (
    <Dialog
      open={Open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle>{Data.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{Data.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary
          onClick={handleClose}
          autoFocus
          variant="contained"
          startIcon={<ExitToAppIcon />}
        ></ButtonSecondary>
        <ButtonDanger
          onClick={handleConfirmAction}
          variant="outlined"
          startIcon={<DeleteIcon />}
        >
          {Data.action.text}
        </ButtonDanger>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return { dialog: state.dialog };
};

export default connect(mapStateToProps, { triggerDialog, triggerNotification })(
  translationHook(DialogNotification)
);
