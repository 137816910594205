import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import apiConfig from "./../../apis/apiConfig";
import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
  StateStoring,
  Paging,
  Pager
} from "devextreme-react/data-grid";
import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import CustomStore from "devextreme/data/custom_store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../actions";
import { getEmployeeTransactions } from "../../apis/transactions";
import { triggerDialog } from "../../actions";
import { translationHook } from "../translationHook";
import { ButtonInfo, ButtonPrimary } from "../UI/Buttons";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Col, Row } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";

class EmployeeTransactions extends Component {

  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.devRef = React.createRef(null);
    this.searchState = React.createRef();
  }

  state = {
    From_Date: moment().format("YYYY-MM-DD"),
    To_Date: moment().format("YYYY-MM-DD"),
    searchResult: "",
    gridData: [],
    reportType: [
      {
        value: '1',
        label: this.props.i18n.language == 'ar' ? 'على مستوي المرحل' : 'Over Steps',
      },
      {
        value: '2',
        label: this.props.i18n.language == 'ar' ? 'على مستوي المهام' : 'Over Tasks',
      }
    ],
    dateType: [
      {
        value: '1',
        label: this.props.i18n.language == 'ar' ? 'تاريخ الطلب' : 'Request Date',
      },
      {
        value: '2',
        label: this.props.i18n.language == 'ar' ? 'تاريخ بداية المرحلة' : 'Start Step Date',
      },
      {
        value: '3',
        label: this.props.i18n.language == 'ar' ? 'تاريخ بداية المهمة' : 'Task Step Date',
      }
    ],
    gridType: null,
    reportTypeId: null,
    periodTypeId: null,
    fromDateState: false,
    toDateState: false,
    gridState: [],
    gridKey: "",
    filterResults: 0,
    showGrid: false,
    searchResults: 0,
    saveGrid: false,
    pageSize: null,
    focusRow: -1,
  };

  handleDateChange = (date, field) => {
    this.setState({
      [field]: moment(date).lang("en").format("YYYY-MM-DD"),
    })
  }

  saveState = async () => {
    const at = this.props.currentUser.Data.access_token,
      url = "API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS",
      bodyData = {
        System_Grid_Name: this.state.gridType == 'steps' ? 'Grid_Report_Employees_Requests_Steps' : 'Grid_Report_Employees_Requests_Tasks',
        System_Grid_Json: this?.searchState.current,
        Lang: this.props.i18n.language,
      }

    await apiConfig.post(url, bodyData, {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    })

    this.props.triggerNotification(true, {
      Status: true,
    })
  }

  getState = async (state) => {
    if (state) {
      state.searchText = "";
      state.columns.map((s) => (s.filterValue = undefined));
      this.searchState.current = JSON.stringify(state);
    }
  }

  loadState = async () => {
    const at = this.props.currentUser.Data.access_token,
      url = "API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS",
      bodyData = {
        System_Grid_Name: this.state.gridType == 'steps' ? 'Grid_Report_Employees_Requests_Steps' : 'Grid_Report_Employees_Requests_Tasks',
        Lang: this.props.i18n.language,
      };

    const response = await apiConfig.post(url, bodyData, {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    });

    if (response.data) {
      this.setState({
        gridState: JSON.parse(response?.data?.Data),
        // showGrid: true,
        pageSize: JSON.parse(response?.data?.Data)?.pageSize,
      });
    }
  }

  getData = async() => {
    getEmployeeTransactions(this.props.currentUser.Data.access_token, {
      From_Date: this.state.From_Date,
      To_Date: this.state.To_Date,
      Report_Type_Id: this.state.reportTypeId,
      Period_Type_Id: this.state.periodTypeId
    }).then((res) => {
      this.setState({ gridData: res, searchResult: res.length });
      return res
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: this.props.t("saveGridSettings"),
          height: "36px",
          icon: "save",
          onClick: () => this.saveState(),
          hint: this.props.t("saveGridSettings"),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: this.props.t("clearSearch"),
          height: "36px",
          icon: "clearformat",
          onClick: () => this.dataGrid?.instance?.clearFilter(),
          hint: this.props.t("clearSearch"),
        },
      }
    );
  }

  handleOnContentReady = (e) => {
    if(this.props.i18n.language === "ar") {
      const scrollable = e.component.getScrollable();
      const maxScrollLeft = scrollable.scrollWidth();

      scrollable.scrollTo({
        x: maxScrollLeft
      })
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.gridType !== prevState.gridType) {
  //     this.dataGrid?.instance?.clearFilter();
  //     this.setState({ gridData: [] })
  //     this.loadState();
  //   }
  // }

  render() {
    const validate = (values) => {
      const errors = {};

      if (!values.reportsType) {
        errors.reportsType = `${this.props.t("error.field_required")}`;
      }

      if (!values.dateType) {
        errors.dateType = `${this.props.t("error.field_required")}`;
      }

      return errors;
    }

    const jsonDataSource = new CustomStore({
      key: 'Row_Id',
      load: () => {
        return this.state.gridData
      }
    })

    return ( 
      <>
        <div className="d-flex justify-content-between align-items-center pb-5">
          <h3>{this.props.t("employeeTransactions")}</h3>
        </div>

        <Form
          onSubmit={this.getData}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              id="returnStepForm"
              className="row"
              onSubmit={handleSubmit}
              noValidate
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Row className="align-items-center w-100">
                  <Col xs={2}>
                    <label className='MuiFormLabel-filled'>{this.props.t("reportType")}</label>
                    <Select
                      required={true}
                      name="reportsType"
                      data={this.state.reportType}
                      onChange={(e) => {
                        form.change("reportsType", e.target.value);
                        this.setState({ reportTypeId: e.target.value })
                        if (e.target.value == 1) {
                          this.setState({ 
                            dateType: [
                              {
                                value: '1',
                                label: this.props.i18n.language == 'ar' ? 'تاريخ الطلب' : 'Request Date',
                              },
                              {
                                value: '2',
                                label: this.props.i18n.language == 'ar' ? 'تاريخ بداية المرحلة' : 'Start Step Date',
                              }
                            ],
                            gridType: 'steps'
                          })
                        } else {
                          this.setState({
                            dateType: [
                              {
                                value: '1',
                                label: this.props.i18n.language == 'ar' ? 'تاريخ الطلب' : 'Request Date',
                              },
                              {
                                value: '2',
                                label: this.props.i18n.language == 'ar' ? 'تاريخ بداية المرحلة' : 'Start Step Date',
                              },
                              {
                                value: '3',
                                label: this.props.i18n.language == 'ar' ? 'تاريخ بداية المهمة' : 'Task Step Date',
                              }
                            ],
                            gridType: 'tasks'
                          })
                        }
                      }}
                    ></Select>
                  </Col>

                  <Col xs={2}>
                    <label className='MuiFormLabel-filled'>{this.props.t("periodDateType")}</label>
                    <Select
                      name="dateType"
                      data={this.state.dateType}
                      onChange={(e) => {
                        form.change("dateType", e.target.value)
                        this.setState({ periodTypeId: e.target.value })
                      }}
                    ></Select>
                  </Col>

                  <Col xs={2}>
                    <KeyboardDatePicker
                      margin="normal"
                      format="yyyy-MM-dd"
                      label={this.props.t("from")}
                      value={this.state.From_Date}
                      onChange={(e) => this.handleDateChange(e, "From_Date")}
                      KeyboardButtonProps={{
                        "aria-label": this.props.t("fromdate"),
                      }}
                      onClick={() => this.setState({fromDateState : true})}
											onClose={() => this.setState({fromDateState : false})}
                      open={this.state.fromDateState}
                    />
                  </Col>

                  <Col xs={2}>
                    <KeyboardDatePicker
                      margin="normal"
                      label={this.props.t("to")}
                      format="yyyy-MM-dd"
                      value={this.state.To_Date}
                      onChange={(e) => this.handleDateChange(e, "To_Date")}
                      KeyboardButtonProps={{
                        "aria-label": this.props.t("todate"),
                      }}
                      onClick={() => this.setState({toDateState : true})}
											onClose={() => this.setState({toDateState : false})}
                      open={this.state.toDateState}
                    />
                  </Col>

                  <Col xs={2}>
                    <ButtonPrimary
									    color='primary'
									    variant='contained'
									    // disabled={submitting}
									    type='submit'
                      onClick={() => {
                        this.setState({
                          From_Date: this.state.From_Date,
                          To_Date: this.state.To_Date,
                        });
                        this.loadState();
                      }}
                    >
                      {this.props.t('search')}
                    </ButtonPrimary>
                  </Col>

                  <Col className="d-flex" xs={2}>
                    {this.state.searchResult !== "" && (
                      <div className="text-center">
                        <label>{this.props.t("filter total result")}</label>
                        <h3 className="py-2 font-weight-bold">
                          {this.state.searchResult}
                        </h3>
                      </div>
                    )}
                  </Col>
                </Row>
              </MuiPickersUtilsProvider>
            </form>
          )} 
        />

        {this.state.gridState?.columns ? (
          <DataGrid
            className={[
              this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
              "globalBox",
            ].join(" ")}
            id="dataGrid"
            width={"100%"}
            dataSource={jsonDataSource}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            allowColumnReordering={true}
            rowAlternationEnabled={false}
            focusedRowEnabled={true}
            onExporting={this.onExporting}
            columnWidth={"auto"}
            wordWrapEnabled={true}
            rtlEnabled={this.props.i18n.language === "ar" ? true : false}
            onToolbarPreparing={this.onToolbarPreparing}
            ref={(ref) => (this.dataGrid = ref)}
            bounceEnabled={false}
            onOptionChanged={(e) => {
              if (e?.fullName == "paging.pageSize") {
                this.setState({ pageSize: e.value });
              }

              if (e.fullName.split(".").pop() == "filterValue") {
                this.dataGrid.instance.refresh();
              }
            }}
            onContentReady={this.handleOnContentReady}
            remoteOperations={false}
          >
            <ColumnChooser
              enabled={true}
              allowSearch={true}
              search={"fff"}
              title={this.props.t("dxColumnChooserTitle")}
              emptyPanelText={this.props.t("dxColumnChooserPanelText")}
            />

            <StateStoring
              enabled={true}
              type="custom"
              // customLoad={this.loadState}
              customSave={this.getState}
            />

            <SearchPanel
              visible={true}
              width={240}
              placeholder={this.props.t("search")}
            />

            <FilterRow visible={true} />

            <HeaderFilter visible={true} />

            <Export enabled={true} allowExportSelectedData={true} />

            <Grouping contextMenuEnabled={true} expandMode="rowClick" />

            <GroupPanel
              visible={true}
              emptyPanelText={this.props.t("dxGroupPanelText")}
            />

            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"always"}
            />

            <Paging
              enabled={true}
              defaultPageSize={this.state.pageSize}
              pageSize={this.state.pageSize}
            />

            <Pager
              visible="true"
              allowedPageSizes={[5, 10, 20, 50, 100]}
              displayMode="full"
              showPageSizeSelector="true"
              showInfo="true"
              infoText=""
              showNavigationButtons="true"
            />

            {this.state.gridState?.columns
              ?.filter((d) => d?.name !== "buttons")
              .map((d, i) => (
                <Column
                  dataField={d?.dataField}
                  caption={d?.name}
                  alignment={this.props.i18n.language === "ar" ? 'right': 'left'}
                  visible={d?.visible}
                  width={d?.width ? d.width : "auto"}
                  dataType={d?.dataType}
                  format={d?.dataType == "date" ? "yyyy-MM-dd" : ""}
                ></Column>
            ))}
          </DataGrid>
        ) : 
          <div 
            className="globalBox"
            style={{
              height: '300px',
              width: '100%',
              backgroundColor: '#f7f9f5',
              padding: '1rem',
              borderRadius: '20px',
            }}  
          >
            <div 
              style={{
                padding: '1rem 1rem 0 1rem',
                backgroundColor: '#fff',
                height: '100%',
                borderRadius: '20px'
              }}
            ></div>
          </div>
        }
      </>
    );
  }

  onExporting(e) {
    let sheetTitle = this.props.t("manageDepartments");
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGridToExcel({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 2 },
      topRightCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        // excelCell.value = gridCell.value;
        excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        excelCell.alignment = {
          indent: 1,
          wrapText: true,
          vertical: "middle",
        };
        if (gridCell.rowType === "group") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          excelCell.alignment = { indent: 2 };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          excelCell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${sheetTitle}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
}

export default connect(mapStateToProps, {
  triggerDialog,
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(EmployeeTransactions))
