import apiConfig from '../../apiConfig'

export const deleteRequestTaskAttachment = async ( { at, File_Code, Request_Task_Id, Request_Id } ) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DELETE_REQUEST_TASK_ATTACHMENT',
        {
            File_Code,
            Request_Task_Id,
            Request_Id
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}
