import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import apiConfig from "../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import moment from "moment";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";

import { getDepartmentServiceTemplatesList } from "../../../apis/services/departments/getDepartmentServiceTemplatesList";
import { downloadDepartmentTemplateAttachment } from "../../../apis/services/departments/downloadDepartmentTemplateAttachment";
import { deleteDepartmentTemplateAttachment } from "../../../apis/services/departments/deleteDepartmentTemplateAttachment";
import { saveDepartmentTemplateAttachment } from "../../../apis/services/departments/saveDepartmentTemplateAttachment";
import { testDepartmentTemplateAttachment } from "../../../apis/services/departments/testDepartmentTemplateAttachment";
import { updateDepartmentTemplateAttachment } from "../../../apis/services/departments/updateDepartmentTemplateAttachment";
import { updateDepartmentTemplateAttachmentList } from "../../../apis/services/departments/updateDepartmentTemplateAttachmentList";
import { setDepartmentServiceTemplatesSigns } from "../../../apis/services/departments/setDepartmentServiceTemplatesSigns";

import {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import imgLoading from "./../../../images/ShMunAnimatedGif.gif";
import { ButtonWhite } from "../../UI/Buttons";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import "../Service.module.scss";

class DepartmentServiceTemplatesList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
		this.onSelectionChanged = this.handleSelectionChanged.bind(this);
	}

	state = {
		fileIdsList: [],
	};

	handleUploadClick = async (
		event,
		Template_Id,
		Department_Service_Version_Id,
		Department_Service_Id,
		update = false
	) => {
		let file = event.target.files[0],
			response;

		if (file) {
			let at = this.props.currentUser.Data.access_token;

			this.props.setLoading(true, "dark");

			if (update) {
				response = await updateDepartmentTemplateAttachment(
					at,
					Department_Service_Version_Id,
					Department_Service_Id,
					Template_Id,
					file
				);
			} else {
				response = await saveDepartmentTemplateAttachment(
					at,
					Department_Service_Version_Id,
					Department_Service_Id,
					Template_Id,
					file
				);
			}

			this.props.triggerNotification(true, response.data);

			this.props.setLoading(false);

			if (response.data.Status) {
				this.props.reload();
			}
		}
	};

	customizeUploadCell = ({ data }) => {
		if (data.File_Code) {
			return (
				<List className="grid-list-item">
					<ListItem key={data.Template_Id}>
						<div className="d-flex w-100 flex-column align-items-end">
							<ListItemText
								className="text-center"
								primary={data.File_Name}
								secondary={data.File_Size}
							/>

							<ListItemSecondaryAction
								className="d-flex"
								style={{
									position: 'unset',
									top: 'auto',
									transform: 'none'
								}}
							>
								<>
									<IconButton
										edge="end"
										aria-label="download"
										onClick={async () => {
											let at =
												this.props.currentUser.Data
													.access_token;

											let downloadResult =
												await downloadDepartmentTemplateAttachment(
													{
														at,
														File_Code: data.File_Code,
														Department_Service_Version_Id:
															data.Department_Service_Version_Id,
													}
												);

											if (downloadResult.status === 200) {
												let contentType =
														downloadResult.headers[
															"content-type"
														],
													typesArray = [
														"application/pdf",
														"image/bmp",
														"image/gif",
														"image/vnd.microsoft.icon",
														"image/jpeg",
														"image/png",
														"image/svg+xml",
														"image/tiff",
														"image/webp",
													],
													url = window.URL.createObjectURL(
														new Blob(
															[downloadResult.data],
															{ type: contentType }
														)
													),
													link = document.createElement("a");

												if (
													downloadResult?.data?.Data
														?.File_Bytes
												) {
													url = window.URL.createObjectURL(
														new Blob(
															[
																downloadResult.data.Data
																	.File_Bytes,
															],
															{ type: contentType }
														)
													);

													data.File_Name =
														downloadResult.data.Data.File_Name;
												}

												link.href = url;

												typesArray.includes(contentType)
													? link.setAttribute(
															"target",
															"_blank"
													)
													: link.setAttribute(
															"download",
															data.File_Name
													);

												document.body.appendChild(link);
												link.click();
											}
										}}
									>
										<GetAppIcon />
									</IconButton>

                                    
                                    {Boolean(data.Can_Update) && (
										<IconButton className="grid-upload-button">
											<input
												type="file"
												onChange={(e) => {
													this.handleUploadClick(
														e,
														data.Template_Id,
														data.Department_Service_Version_Id,
														this.props.departmentServiceId,
														true
													);
												}}
											/>
											<PublishIcon />
										</IconButton>
									)}

									{this.props.editMood && Boolean(data.Can_Delete) && (
										<IconButton
											className="mx-2"
											edge="end"
											aria-label="delete"
											onClick={async () => {
												let at =
													this.props.currentUser.Data
														.access_token;

												let response =
													await deleteDepartmentTemplateAttachment(
														at,
														data.Department_Service_Version_Id,
														data.File_Code
													);

												if (response.data.Status) {
													this.props.reload();
												}
											}}
										>
											<DeleteIcon />
										</IconButton>
									)}

									{Boolean(data.Can_Test) && (
										<button 
											id={data.Department_Service_Template_File_Id}
											className='testTemplateBtn'
											onClick={async (e) => {
												let at = this.props.currentUser.Data.access_token;

												if (data.Department_Service_Template_File_Id == e.target.id) {
													e.target.disabled = true
													e.target.childNodes[0].innerHTML = ' '
													e.target.style.backgroundImage = `url(${imgLoading})`
												}

												let downloadResult = await testDepartmentTemplateAttachment(
													at,
													data.Department_Service_Version_Id,
													data.Template_Id,
													data.File_Code
												);

												if (downloadResult.status === 200) {
													let contentType =
															downloadResult.headers[
																"content-type"
															],
														typesArray = [
															"application/pdf",
															"image/bmp",
															"image/gif",
															"image/vnd.microsoft.icon",
															"image/jpeg",
															"image/png",
															"image/svg+xml",
															"image/tiff",
															"image/webp",
														],
														url = window.URL.createObjectURL(
															new Blob(
																[downloadResult.data],
																{type: contentType}
															)
														),
														link = document.createElement("a");

													if (downloadResult?.data?.Data?.File_Bytes) {
														url = window.URL.createObjectURL(
															new Blob(
																[
																	downloadResult.data.Data.File_Bytes,
																],
																{
																	type: contentType,
																}
															)
														);
														data.File_Name = downloadResult.data.Data.File_Name;
													}

													link.href = url;

													typesArray.includes(contentType)
														? link.setAttribute(
															"target",
															"_blank"
														)
														: link.setAttribute(
															"download",
															data.File_Name
														);

													document.body.appendChild(link);
													link.click();

													e.target.childNodes[0].innerHTML = `<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>`
													e.target.style.backgroundImage = 'none'
													e.target.disabled = false
												}
											}}
										>
											<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>
										</button>
									)}
								</>
							</ListItemSecondaryAction>
						</div>
					</ListItem>
				</List>
			);
		} else {
			if (this.props.editMood) {
				return (
					<div className='d-flex justify-content-end'>
						<IconButton className="grid-upload-button m-0">
							<input
								type="file"
								onChange={(e) => {
									this.handleUploadClick(
										e,
										data.Template_Id,
										data.Department_Service_Version_Id,
										this.props.departmentServiceId
									);
								}}
							/>
							<PublishIcon />
						</IconButton>
					</div>
				);
			} else {
				return null;
			}
		}
	};

	handleUploadListClick = async (event, Template_Id) => {
		console.log(event, Template_Id)
		console.log(this.state.fileIdsList)

		let file = event.target.files[0],
			response;

		if (file) {
			let at = this.props.currentUser.Data.access_token;

			this.props.setLoading(true, "dark");

			response = await updateDepartmentTemplateAttachmentList(
				at,
				Template_Id,
				this.state.fileIdsList,
				file
			);

			this.props.triggerNotification(true, response.data);

			this.props.setLoading(false);

			if (response.data.Status) {
				this.props.reload();
			}
		}
	}

	handleSelectionChanged = (e) => {
		this.setState({fileIdsList: e.selectedRowKeys})
	}

	customizeCellCheckbox = ({ data }) => {
		return (
			<>
				<FormControlLabel
					className='d-block'
					control={
						<Switch
							checked={data.Enable_User_Sign}
							name={`userSign`}
							onChange={async () => {
								let at = this.props.currentUser.Data.access_token,
									departmentServiceVersionId = data.Department_Service_Version_Id,
									templateId = data.Template_Id,
									userSignState = data.Enable_User_Sign ? 0 : 1,
									reviewSignState = data.Enable_Review_Sign,
									approvalSignState = data.Enable_Approval_Sign,
									QRCodeState = data.Enable_QR_Code

								this.props.setLoading(true, "dark");

								const response = await setDepartmentServiceTemplatesSigns(
									at,
									departmentServiceVersionId,
									templateId,
									userSignState,
									reviewSignState,
									approvalSignState,
									QRCodeState
								);

								this.props.triggerNotification(true, response);

								this.props.setLoading(false);
							}}
						/>
					}
					label={this.props.t("enableUserSign")}
				/>

				<FormControlLabel
					className='d-block'
					control={
						<Switch
							checked={data.Enable_Review_Sign}
							name={`reviewSign`}
							onChange={async () => {
								let at = this.props.currentUser.Data.access_token,
									departmentServiceVersionId = data.Department_Service_Version_Id,
									templateId = data.Template_Id,
									userSignState = data.Enable_User_Sign,
									reviewSignState = data.Enable_Review_Sign ? 0 : 1,
									approvalSignState = data.Enable_Approval_Sign,
									QRCodeState = data.Enable_QR_Code

								this.props.setLoading(true, "dark");

								const response = await setDepartmentServiceTemplatesSigns(
									at,
									departmentServiceVersionId,
									templateId,
									userSignState,
									reviewSignState,
									approvalSignState,
									QRCodeState
								);

								this.props.triggerNotification(true, response);

								this.props.setLoading(false);
							}}
						/>
					}
					label={this.props.t("enableReviewSign")}
				/>

				<FormControlLabel
					className='d-block'
					control={
						<Switch
							checked={data.Enable_Approval_Sign}
							name={`approvalSign`}
							onChange={async () => {
								let at = this.props.currentUser.Data.access_token,
									departmentServiceVersionId = data.Department_Service_Version_Id,
									templateId = data.Template_Id,
									userSignState = data.Enable_User_Sign,
									reviewSignState = data.Enable_Review_Sign,
									approvalSignState = data.Enable_Approval_Sign ? 0 : 1,
									QRCodeState = data.Enable_QR_Code

								this.props.setLoading(true, "dark");

								const response = await setDepartmentServiceTemplatesSigns(
									at,
									departmentServiceVersionId,
									templateId,
									userSignState,
									reviewSignState,
									approvalSignState,
									QRCodeState
								);

								this.props.triggerNotification(true, response);

								this.props.setLoading(false);
							}}
						/>
					}
					label={this.props.t("enableApprovalSign")}
				/>

				<FormControlLabel
					className='d-block'
					control={
						<Switch
							checked={data.Enable_QR_Code}
							name={`QRCodeSign`}
							onChange={async () => {
								let at = this.props.currentUser.Data.access_token,
									departmentServiceVersionId = data.Department_Service_Version_Id,
									templateId = data.Template_Id,
									userSignState = data.Enable_User_Sign,
									reviewSignState = data.Enable_Review_Sign,
									approvalSignState = data.Enable_Approval_Sign,
									QRCodeState = data.Enable_QR_Code ? 0 : 1

								this.props.setLoading(true, "dark");

								const response = await setDepartmentServiceTemplatesSigns(
									at,
									departmentServiceVersionId,
									templateId,
									userSignState,
									reviewSignState,
									approvalSignState,
									QRCodeState
								);

								this.props.triggerNotification(true, response);

								this.props.setLoading(false);
							}}
						/>
					}
					label={this.props.t("enableQRCode")}
				/>
			</>
		);
	}

	render() {
		let columnAlign = "left",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "Department_Service_Template_File_Id",
			load: () => {
				return getDepartmentServiceTemplatesList(
					at,
					this.props.versionid
				);
			},
		});

		return (
			<>
                
                <div className="d-none mb-4 justify-content-end upload-btn-wrapper ">
					<ButtonWhite 
						variant="solid"
					>
						{this.props.t("updateSelectedServicesFiles")}
						<input
                            type="file"
							onChange={(e) => {
								this.handleUploadListClick(
									e,
									this.props.versionid
								);
							}}
						/>
					</ButtonWhite>
				</div>
                
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={false}
					onExporting={this.onExporting}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
					onSelectionChanged={this.handleSelectionChanged}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField={
							this.props.i18n.language === "en"
								? "Template_Name_EN"
								: "Template_Name_AR"
						}
						caption={this.props.t("templateName")}
						alignment={columnAlign}
					></Column>

					<Column
						caption={this.props.t("templatesSignatures")}
						alignment={columnAlign}
						cellRender={this.customizeCellCheckbox}
					></Column>

					<Column
						dataField={
							this.props.i18n.language === "en"
								? "User_Name_EN"
								: "User_Name_AR"
						}
						caption={this.props.t("userName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Upd_Time"}
						caption={this.props.t("updateTime")}
						alignment={columnAlign}
						cssClass="dir-ltr"
						calculateCellValue={(data) => {
							return moment(data.Upd_Time).format(
								"YYYY-MM-DD, hh:mm A "
							);
						}}
					></Column>

					<Column
						caption={this.props.t("attachments")}
						cellRender={this.customizeUploadCell}
						width="200"
					></Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageServices");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(DepartmentServiceTemplatesList));
