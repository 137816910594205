import apiConfig from '../../apiConfig'

export const getServiceDropdwnInfo = async (at, dropDownid) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSMANAGEMENT/Get_Service_Lookup',
        {
            Service_Lookup_Id: dropDownid
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    if (response.data.Status) {
        if (response.data.Data.Active_Status_Id === 1) {
            response.data.Data.Active_Status_Id = true;
        } else {
            response.data.Data.Active_Status_Id = false;
        }
        
        return response.data.Data
    }

    return []
}
