import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { ButtonDanger, ButtonPrimary } from "./Buttons";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = (props) => {
  const {
    isOpen,
    title,
    isFullScreen,
    handleClose,
    actionCallback,
    closeBtnTitle,
    actionBtnTitle,
  } = props;
  const { t, i18n } = useTranslation("common");

  let textAlign = i18n.language === "ar" ? "right" : "left",
    dir = i18n.language === "ar" ? "rtl" : "ltr";

  const useStyles = makeStyles((theme) => ({
    root: {
      direction: dir,
      "& *": {
        fontFamily: "Cairo, sans-serif",
        display: "flex",
        flexDirection: "column",
      },
      "& .MuiDialogContent-root, .MuiFormControl-root": {
        textAlign,
      },
      "& .MuiInputBase-root input": {
        textAlign,
      },
      "& .MuiPaper-root": {
        backgroundColor: "#ffffff !important",
        borderRadius: theme.spacing(1),
        borderWidth: 2
      },
      "& .MuiTypography-root": {
        textAlign,
        color: '#000'
      },
      "& .submit-btn-wrap": {
        textAlign,
      },
    },
    appBar: {
      position: "relative",
      minHeight: '12rem',

    },
    title: {
      marginLeft: theme.spacing(1),
      flex: 1,
      margin: "10px 0 10px 0",
    },
    appBarButton: {
      borderColor: "#fff",
      color: "#fff !important",
    },
    buttonsContainer: {
      disply: "flex",
      flexDirection: "row",
      gap: "5px",
      margin: "10px 0 20px 0",
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={isOpen}
      hideBackdrop
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.root}
      disableEnforceFocus={true}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.buttonsContainer}>
            <ButtonPrimary
              className={classes.appBarButton}
              variant="contained"
              onClick={actionCallback}
            >
              {actionBtnTitle || "Ok"}
            </ButtonPrimary>
            <ButtonDanger
              className={classes.appBarButton}
              variant="contained"
              style={{ borderRadius: "30px" }}
              onClick={handleClose}
            >
              {closeBtnTitle || t("cancel")}
            </ButtonDanger>
          </div>
        </Toolbar>
      </AppBar>
    </Dialog>
  );
};

export default ConfirmationDialog;
