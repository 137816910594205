import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../../apis/apiConfig';
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    // SearchPanel,
    // FilterRow,
    // HeaderFilter,
    // ColumnChooser,
	// Export,
	// Grouping,
	// GroupPanel,
	Selection
} from 'devextreme-react/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// import { ExcelJS , Workbook } from "exceljs";
// import saveAs from "file-saver";
// import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store'
import { translationHook } from '../../../translationHook'
// import IconButton from '@material-ui/core/IconButton';
// import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ButtonDanger, ButtonSecondary } from '../../../UI/Buttons'
import { setLoading, triggerNotification, triggerDialogForm } from '../../../../actions'
import { deleteRequestInvoice } from '../../../../apis/requests/info/deleteRequestInvoice'
// import { deleteRequestTaskAttachment } from '../../../../apis/requests/info/deleteRequestTaskAttachment'
import VisibilityIcon from "@material-ui/icons/Visibility";


class InvoiceList extends Component {
    constructor(props){
		super(props);
		// this.onExporting = this.onExporting.bind(this);
	}

    state = {
        invoiceItems: []
    }

    downloadInvoice = async ( File_Code, File_Name ) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
          '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DOWNLOAD_REQUEST_ATTACHMENT',
          {File_Code},
          {
              headers: {
                  Authorization: `Bearer ${at}`
              },
              responseType: 'arraybuffer',
          }
        ).catch((error) => {
            return error.response
        })


        if (response.status === 200) {
            let contentType = response.headers['content-type'],
                typesArray = [ 'application/pdf', 'image/bmp', 'image/gif', 'image/vnd.microsoft.icon', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp' ],
                url = window.URL.createObjectURL(new Blob([response.data], { type: contentType })),
                link = document.createElement("a")

            link.href = url

            typesArray.includes(contentType) ? link.setAttribute('target', 'blank') : link.setAttribute('download', File_Name)

            document.body.appendChild(link)
            link.click()
            this.props.setLoading(false)
        }
        
    }

    refreshInvoice = async ( invoiceId ) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
          '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/REFRESH_REQUEST_INVOICE_STATUS',
          {Invoice_Id: invoiceId},
          {
              headers: {
                  Authorization: `Bearer ${at}`
              },
          }
        ).catch((error) => {
            return error.response
        })


        this.props.setLoading(false)

        if (response.status === 200) {
            const status = response.data.Data.Refresh_Invoice_Action_Id
            if (status === 1) {
                this.props.triggerDialogForm(false);
                document.getElementById("ds-grid-edit-btn").click()
            } else if (status === 2) {
                let link = document.createElement('a')
                link.href = response.data.Data.Payment_URL
                link.setAttribute('target', 'blank')

                document.body.appendChild(link)

                link.click()
            }

        }
    }

    handleDelete = async (row) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        // Promise.all([
        //     deleteRequestTaskAttachment({ at, File_Code: row.Invoice_File.File_Code, Request_Task_Id: this.props.requestTaskId, Request_Id: this.props.requestId }),
        //     deleteRequestInvoice(at, row.Invoice_No, this.props.requestTaskId, this.props.requestId )
        // ]).then((response) => {
        //     console.log(response);
        // }).catch((error) => {
        //     console.log(error.response)
        // })

        const response = await deleteRequestInvoice(at, row.Invoice_No, this.props.requestTaskId, this.props.requestId ).catch((error) => {
            return error.response
        })

        this.props.triggerNotification(true, response.data)

        this.props.deleteCB(row.Invoice_No)

        this.props.setLoading(false)
    }

    


    render() {
        
        let columnAlign = 'center',
            invoiceStatusKey = 'Invoice_Status_Name_EN',
            // invoiceTypeKey = 'Invoice_Type_Name_EN',
            jsonDataSource = new CustomStore({
                key: 'Invoice_No',
                load: () => {
                    return this.props.invoices
                }
            });

            if  (this.props.i18n.language === 'ar') {
                invoiceStatusKey = 'Invoice_Status_Name_AR'
                // invoiceTypeKey = 'Invoice_Type_Name_AR'
        }

        return (
            <>
                <DataGrid
                className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
                id={`dataGrid_${this.props.key}`}
                width={'100%'}
                dataSource={jsonDataSource}
                showBorders={false}
                showColumnLines= {false}
                showRowLines= {true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                allowColumnReordering={true}
                rowAlternationEnabled={false}
                focusedRowEnabled={true}
                // onExporting={this.onExporting}
                columnWidth={'auto'}
                wordWrapEnabled={true}
                rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
            >

                {/* <ColumnChooser enabled={true} allowSearch={true} search={'fff'}
                    title={this.props.t('dxColumnChooserTitle')}
                    emptyPanelText={this.props.t('dxColumnChooserPanelText')}
                />
                
                <SearchPanel 
                    visible={true}
                    width={240}
                    placeholder={this.props.t('search')}
                /> */}

                {/* <FilterRow visible={true} /> */}

                {/* <HeaderFilter visible={true} /> */}

                {/* <Export enabled={true} allowExportSelectedData={true}/> */}

                {/* <Grouping contextMenuEnabled={true} expandMode="rowClick"/> */}
                {/* <GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/> */}

                <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'always'}
                />

                    <Column dataField="Invoice_No" caption={this.props.t('invoiceNo')} alignment={columnAlign} width={140}>
                    </Column>
                    <Column dataField="Payment_No" caption={this.props.t('invoiceReceiptNumber')} alignment={columnAlign} width={140}>
                    </Column>

                    <Column dataField="Invoice_Date" dataType={"date"} caption={this.props.t('invoiceDate')} alignment={columnAlign} format="yyyy-MM-dd">
                    </Column>

                    <Column dataField="Invoice_Value" caption={this.props.t('invoiceValue')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={invoiceStatusKey} caption={this.props.t('invoiceType')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={"Online_Reference_No"} caption={this.props.t('collectNumber')} alignment={columnAlign}>
                    </Column>

                    <Column dataField="Invoice_Paid_Date" dataType={"date"} caption={this.props.t('invoicePaidDate')} alignment={columnAlign} format="yyyy-MM-dd">
                    </Column>
                    {/* <Column
                    caption={this.props.t('invoiceItemsDetails')}
                    width={200}
                    alignment={columnAlign}
                    cellRender={({ data }) => 
                        <Col xs={12}>
                            {data.Invoice_Items.map((i) => {
                                let itemName = this.props.i18n.language === 'ar' ? i.Invoice_Type_Name_AR : i.Invoice_Type_Name_EN
                                return (
                                    <Row className='d-flex justify-content-between mb-3'>
                                        <span className='bold'>{this.props.t('invoiceItemType')}: {itemName}</span>
                                        <span>{this.props.t('invoiceItemValue')}: {i.Invoice_Type_Value}</span>
                                    </Row>
                                )
                            })}
                        </Col>
                    }></Column> */}

                    {/* <Column dataField={invoiceStatusKey} caption={this.props.t('invoiceStatus')} alignment={columnAlign}>
                    </Column>*/}

                    <Column cssClass="outlinedColumn nowrapColumn"
                            type='buttons'
                            caption={this.props.t('details')}
                            fixed={true}
                            fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
                        >
                        <Button
                            render={({ data }) => {
                                if ( data.File_Code ) {
                                    return (
                                        <ButtonSecondary
                                        variant='outlined'
                                        // startIcon={<ArrowDownwardIcon />}
                                        onClick={() => this.downloadInvoice(data.File_Code, data.File_Name)}
                                        >
                                            {this.props.t('downloadInvoice')}
                                        </ButtonSecondary>
                                    //     <Button
                                    //     render={(record) => {
                                    //         return (
                                    //             <ButtonSecondary
                                    //             variant="circleIcon"
                                    //             startIcon={<ArrowDownwardIcon />}
                                    //             onClick={() => this.downloadInvoice(data.File_Code, data.File_Name)}
                                    //             >
                                    //             </ButtonSecondary>
                                    //         )
                                    //     }}
                                    // />
                                    )
                                }

                                if ( data.Show_Refresh_Invoice_Status ) {
                                    return (
                                        <ButtonSecondary
                                        variant='primary'
                                        // startIcon={<ArrowDownwardIcon />}
                                        onClick={() => this.refreshInvoice(data.Invoice_Id)}
                                        >
                                            {this.props.t('refreshInvoiceStatus')}
                                        </ButtonSecondary>
                                    //     <Button
                                    //     render={(record) => {
                                    //         return (
                                    //             <ButtonSecondary
                                    //             variant="circleIcon"
                                    //             startIcon={<ArrowDownwardIcon />}
                                    //             onClick={() => this.downloadInvoice(data.File_Code, data.File_Name)}
                                    //             >
                                    //             </ButtonSecondary>
                                    //         )
                                    //     }}
                                    // />
                                    )
                                }

                                if ( data.Invoice_File ) {
                                    return (
                                        // <IconButton edge="end" aria-label="download" onClick={() => {this.downloadInvoice(data.Invoice_File.File_Code, data.Invoice_File.File_Name)}}>
                                        //     <GetAppIcon />
                                        // </IconButton>
                                        <ButtonSecondary
                                        variant="circleIcon"
                                        startIcon={<ArrowDownwardIcon />}
                                        onClick={() => this.downloadInvoice(data.Invoice_File.File_Code, data.Invoice_File.File_Name)}
                                        >
                                        </ButtonSecondary>
                                    )
                                }

                                return null
                            }}
                        />
                        { this.props.showDialog && <Button
                            render={(record) => {
                                return (
                                    <ButtonSecondary
                                    variant="circleIcon"
                                    startIcon={<VisibilityIcon />}
                                    onClick={() => this.setState({ invoiceItems: record.data.Invoice_Items })}
                                    >
                                    </ButtonSecondary>
                                )
                            }}
                        /> }

                        { this.props.deleteCB &&
                            <Button
                                render={(record) => {
                                    return (
                                        <ButtonDanger
                                        variant="circleIcon"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(record.data)}
                                        >
                                        </ButtonDanger>
                                    )
                                }}
                            />
                        }
                        
                    </Column>
                </DataGrid>
                <Dialog onClose={() => this.setState({ invoiceItems: [] })} aria-labelledby="customized-dialog-title" open={this.state.invoiceItems.length}>
                    <DialogTitle id="customized-dialog-title" style={{ 
                        textAlign: this.props.i18n.language === 'ar' ? 'right' : 'left',
                        fontFamily: 'Cairo,sans-serif'
                    }} onClose={() => this.setState({ invoiceItems: [] })}>
                        {this.props.t('invoiceItemsDetails')}
                    </DialogTitle>
                    <DialogContent dividers>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" style={{
                                direction: this.props.i18n.language === 'ar' ? 'rtl': 'ltr'
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontFamily: 'Cairo,sans-serif' }}>{this.props.t('invoiceItemNumber')}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: 'Cairo,sans-serif' }}>{this.props.t('invoiceItemCategory')}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: 'Cairo,sans-serif' }}>{this.props.t('invoiceItemType')}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: 'Cairo,sans-serif' }}>{this.props.t('invoiceItemValue')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.invoiceItems.length ? this.state.invoiceItems.map((i) => (
                                    <TableRow key={i.Invoice_Type_Id}>
                                        <TableCell component="th" scope="row" style={{ fontFamily: 'Cairo,sans-serif' }}>
                                            {i.Invoice_Type_No}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontFamily: 'Cairo,sans-serif' }}>{this.props.i18n.language === 'ar' ? i.Invoice_Type_Category_Name_AR: i.Invoice_Type_Category_Name_EN}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: 'Cairo,sans-serif' }}>{this.props.i18n.language === 'ar' ? i.Invoice_Type_Name_AR: i.Invoice_Type_Name_EN}</TableCell>
                                        <TableCell align="center" style={{ fontFamily: 'Cairo,sans-serif' }}>{i.Invoice_Type_Value}</TableCell>
                                    </TableRow>
                                )) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <ButtonSecondary
                        variant="outlined"
                        onClick={() => this.setState({ invoiceItems: [] })}
                        >
                            {this.props.t('cancel')}
                        </ButtonSecondary>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
     
	// onExporting(e) {
	// 	let sheetTitle = this.props.t('invoiceList')
    //     const workbook = new Workbook();
    //     const worksheet = workbook.addWorksheet('Main sheet');
    //     exportDataGridToExcel({
    //         component: e.component,
    //         worksheet: worksheet,
	// 		autoFilterEnabled: true,
	// 		topLeftCell: { row: 2, column: 2 },
	// 		topRightCell: { row: 2, column: 2 },
    //         customizeCell: ({gridCell, excelCell}) => {
    //             // excelCell.value = gridCell.value;
    //             excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
	// 			excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
	// 			if(gridCell.rowType === 'group') {
	// 				excelCell.fill = {
	// 					type: 'pattern',
	// 					pattern:'solid',
	// 					fgColor:{argb:'d0b166'}
	// 				  };
	// 				excelCell.alignment = { indent: 2 };
	// 				excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
	// 			}
	// 			if(gridCell.rowType === 'header') {
	// 			excelCell.fill = {
	// 				type: 'pattern',
	// 				pattern:'solid',
	// 				fgColor:{argb:'58743a'}
	// 				};
	// 			excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
	// 			excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
	// 			}
    //         } 
    //     }).then(function() {
    //         workbook.xlsx.writeBuffer()
    //             .then(function(buffer) {
    //                 saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
    //             });
    //     });
    //     e.cancel = true;
    // }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { setLoading, triggerNotification, triggerDialogForm })(translationHook(InvoiceList))