import apiConfig from './apiConfig';

export const sendVerifyMail = async (at) => {
    const response = await apiConfig.post(
    '/API/SYSTEM/LOGIN/SEND_MAIL_OTP_VERIFICATION', {},
    {
        headers: {
            Authorization: `Bearer ${at}`,
        },
    }
    );
    // .Data ? response.data.Data : [];
    return response.data
}

export const setMailVerify = async (at , data) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/LOGIN/SET_MAIL_VERIFICATION', data,
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
        );
        return response.data ? response.data : {};
}

export const sendVerifyMobile = async (at) => {
    const response = await apiConfig.post(
        '/API/SYSTEM/LOGIN/SEND_MOBILE_OTP_VERIFICATION', {},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );
    // .Data ? response.data.Data : [];
    return response.data
}
export const setMobileVerification = async (at , data) => {
        const response = await apiConfig.post(
            '/API/SYSTEM/LOGIN/SET_MOBILE_VERIFICATION', data,
    {
        headers: {
            Authorization: `Bearer ${at}`,
        },
    }
);
return response.data ? response.data : {};
}