import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import { triggerNotification, setLoading } from '../../../actions';
class InvoiceDetails extends Component {
    state = {};

  

	render() {
        const { details } = this.props;

       
     

            const invoiceValue = () => {

                if(Array.isArray(details) && details.length > 0) {
                    const detailNumber = details.map(item => item.Invoice_Detail_Grand_Total_Value)
    
                    const reducerdetailNumber = (previousValue, currentValue) => previousValue + currentValue;
        
                    console.log(detailNumber)
                    console.log(reducerdetailNumber)
        
                    return detailNumber.reduce(reducerdetailNumber)
                }
                else {
                    return false
                }
            }


		return (
			<>
            <div className="row border-bottom pb-2 mb-4 border-top pt-3">
                <div class='col-3 text-center border-left text-success font-weight-bold'>
                        <label className='ml-2'>{this.props.t('InvoiceDetailsNameAR')}</label>
                </div>
                <div className='col-3 text-center border-left text-green font-weight-bold'>
                    <label className='ml-2'>{this.props.t('InvoiceDetailsNameEN')}</label>
                </div>
                <div className='col-2 text-center border-left text-green font-weight-bold'>
                    <label className='ml-2'>{this.props.t('InvoicDetailsTaxVal')}</label>
                </div>
                <div className='col-2 text-center border-left text-green font-weight-bold'>
                    <label className='ml-2'>{this.props.t('totalValue')}</label>
                </div>
                <div className='col-2 text-center text-green font-weight-bold'>
                    <label className='ml-2'>{this.props.t('invoiceDetailsGrandTotal')}</label>
                </div>
            </div>
             {details.map(({Invoice_Detail_Id , Invoice_Detail_Type_Name_AR, 
            Invoice_Detail_Type_Name_EN, Invoice_Detail_No, Invoice_Detail_Grand_Total_Value, Invoice_Detail_Tax_Value, Invoice_Detail_Total_Value}) => (
                <div className="row mb-2" key={Invoice_Detail_Id}>
                    <div className='col-3 text-center border-left pb-2 border-bottom'>
                        <p>{Invoice_Detail_Type_Name_AR}</p>
                    </div>
                    <div className='col-3 text-center border-left pb-2 border-bottom'>
                        <p>{Invoice_Detail_Type_Name_EN}</p>
                    </div>
                    <div className='col-2 text-center border-left pb-2 border-bottom'>
                        <p>{Invoice_Detail_Tax_Value}</p>
                    </div>
                    <div className='col-2 text-center border-left pb-2 border-bottom'>
                        <p>{Invoice_Detail_Total_Value}</p>
                    </div>
                    <div className='col-2 text-center pb-2 border-bottom'>
                        <p>{Invoice_Detail_Grand_Total_Value}</p>
                    </div>
                </div>
            ))}
            <div className="row mb-2 border-bottom">
                <div className='col-3 text-center pb-2 border-left offset-7'>
                    <label className='ml-2'>{this.props.t('invoiceValue')}</label>
                </div>
                <div className='col-2 text-center pb-2 border-right'>
                    <label className='ml-2'>{invoiceValue()}</label>
                </div>
            
            </div>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { triggerNotification, setLoading })(
	translationHook(InvoiceDetails)
);
