import apiConfig from "../apiConfig";

export const getDepartmentJobInfo = async (at, Department_Job_Id) => {
	const response = await apiConfig.post(
		"/API/LOOKUPS/DEPARTMENTJOBS/GET_DEPARTMENT_JOB_INFO",
		{
			Department_Job_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
