import React from "react";

import Notes from "../Notes";
import InvoiceForm from "./InvoiceForm";
import { generateRequestFees } from "../../../../apis/requests/processTask";

const Invoices = (props) => {
  const [showNotes, setViewState] = React.useState(true);

  return (
    <>
      <InvoiceForm
        requestId={props.requestId}
        requestTaskId={props.requestTaskId}
        tempId={props.tempId}
        toggleNotes={(state) => setViewState(state)}
      />

      {showNotes ? (
        <Notes
          required={false}
          requestId={props.requestId}
          requestTaskId={props.requestTaskId}
          tempId={props.tempId}
          onTaskSubmit={generateRequestFees}
        />
      ) : null}
    </>
  );
};

export default Invoices;
