import apiConfig from "../../apiConfig";

export const getRequestManagmentInfo = async (
	at,
	requestId,
	requestTaskId
	// Fetch_Type = 0,
	// Target_Request_Step_Id = null
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUESTS_MANAGEMENT_INFO",
		{
			Request_Id: requestId,
			Request_Task_Id: requestTaskId,
			// Fetch_Type,
			// Target_Request_Step_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
