import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import style from './LoginForm.module.scss'
import FormElement from './FormElement'
import { Link } from '@reach/router'

const question = () => {
    return (
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 
            256-256-114.835938-256-256-256zm0 405.332031c-11.777344 
            0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031 21.332031 
            9.554687 21.332031 21.332031-9.554687 21.332031-21.332031 21.332031zm33.769531-135.636719c-7.550781 
            3.476563-12.4375 11.09375-12.4375 19.394532v9.578125c0 11.773437-9.535156 21.332031-21.332031 
            21.332031s-21.332031-9.558594-21.332031-21.332031v-9.578125c0-24.898438 14.632812-47.722656 
            37.226562-58.15625 21.738281-10.003906 37.4375-36.566406 37.4375-49.601563 
            0-29.394531-23.914062-53.332031-53.332031-53.332031s-53.332031 23.9375-53.332031 53.332031c0 
            11.777344-9.539063 21.335938-21.335938 21.335938s-21.332031-9.558594-21.332031-21.335938c0-52.925781 
            43.070312-96 96-96s96 43.074219 96 96c0 28.824219-25.003906 71.191407-62.230469 88.363281zm0 0"
        />
        </svg>
    )
}

const LoginForm = () => {

    const { t } = useTranslation('auth')

    return (
        <>
            <Row className={[style.loginForm, 'justify-content-center my-1 adminLoginForm'].join(' ')}>
                <Col lg={11} xs={12}>
                    <Row>
                        <Col xs={12}>
                            {/* <span className={style.followUS}>{t('follow_us')}</span> */}
                            {/* <div className={style.socialMediaCont}>
                                <a 
                                    className={style.socialLink}
                                    href={twitter}
                                    target='_blank'
                                >
                                    <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 
                                            256-256-114.636719-256-256-256zm116.886719 199.601562c.113281 2.519532.167969 5.050782.167969 
                                            7.59375 0 77.644532-59.101563 167.179688-167.183594 167.183594h.003906-.003906c-33.183594 
                                            0-64.0625-9.726562-90.066406-26.394531 4.597656.542969 9.277343.8125 14.015624.8125 27.53125 
                                            0 52.867188-9.390625 72.980469-25.152344-25.722656-.476562-47.410156-17.464843-54.894531-40.8125 
                                            3.582031.6875 7.265625 1.0625 11.042969 1.0625 5.363281 0 10.558593-.722656 
                                            15.496093-2.070312-26.886718-5.382813-47.140624-29.144531-47.140624-57.597657 0-.265624 
                                            0-.503906.007812-.75 7.917969 4.402344 16.972656 7.050782 26.613281 
                                            7.347657-15.777343-10.527344-26.148437-28.523438-26.148437-48.910157 0-10.765624 2.910156-20.851562 
                                            7.957031-29.535156 28.976563 35.554688 72.28125 58.9375 121.117187 
                                            61.394532-1.007812-4.304688-1.527343-8.789063-1.527343-13.398438 0-32.4375 26.316406-58.753906 
                                            58.765625-58.753906 16.902344 0 32.167968 7.144531 42.890625 18.566406 13.386719-2.640625 25.957031-7.53125 
                                            37.3125-14.261719-4.394531 13.714844-13.707031 25.222657-25.839844 32.5 11.886719-1.421875 23.214844-4.574219 
                                            33.742187-9.253906-7.863281 11.785156-17.835937 22.136719-29.308593 30.429687zm0 0"
                                        />
                                    </svg>
                                </a>
                                <a 
                                    className={style.socialLink}
                                    href={youtube}
                                    target='_blank'
                                >
                                    <svg height="512pt" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m224.113281 303.960938 83.273438-47.960938-83.273438-47.960938zm0 0"/>
                                        <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 
                                            256-256-114.636719-256-256-256zm159.960938 256.261719s0 51.917969-6.585938 76.953125c-3.691406 
                                            13.703125-14.496094 24.507812-28.199219 28.195312-25.035156 6.589844-125.175781 
                                            6.589844-125.175781 6.589844s-99.878906 0-125.175781-6.851562c-13.703125-3.6875-24.507813-14.496094-28.199219-28.199219-6.589844-24.769531-6.589844-76.949219-6.589844-76.949219s0-51.914062 
                                            6.589844-76.949219c3.6875-13.703125 14.757812-24.773437 28.199219-28.460937 25.035156-6.589844 
                                            125.175781-6.589844 125.175781-6.589844s100.140625 0 125.175781 6.851562c13.703125 3.6875 24.507813 
                                            14.496094 28.199219 28.199219 6.851562 25.035157 6.585938 77.210938 6.585938 77.210938zm0 0"
                                        />
                                    </svg>
                                </a>
                                <a 
                                    className={style.socialLink}
                                    href={fb}
                                    target='_blank'
                                >
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 167.657 167.657" style={{enableBackground: 'new 0 0 167.657 167.657'}} xmlSpace="preserve"
                                    >
                                        <g>
                                            <path fill="#010002" d="M83.829,0.349C37.532,0.349,0,37.881,0,84.178c0,41.523,30.222,75.911,69.848,82.57v-65.081H49.626
                                                v-23.42h20.222V60.978c0-20.037,12.238-30.956,30.115-30.956c8.562,0,15.92,0.638,18.056,0.919v20.944l-12.399,0.006
                                                c-9.72,0-11.594,4.618-11.594,11.397v14.947h23.193l-3.025,23.42H94.026v65.653c41.476-5.048,73.631-40.312,73.631-83.154
                                                C167.657,37.881,130.125,0.349,83.829,0.349z"
                                            />
                                        </g>
                                    </svg>
                                </a>
                            </div> */}
                        </Col>
                        <Col xs={12}>
                            <div className={style.titleContainer}>
                                <p className={style.welcome}>{t('welcome')}!</p>
                                <p className={style.login2YourAcc}>{t('login_to_your_Acc')}</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={11} xs={12}>
                    <FormElement />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <span className={style.help}>
                        {question()}
                        <a href="#" target='_blank'>{t('help')}</a>
                        <span className={style.dot}></span>
                        <a href="#" target='_blank'>{t('terms_and_privacy')}</a>
                    </span>
                </Col>
            </Row>
        </>
    )
}

export default LoginForm