import { CLEAR_DIALOG_DATA, GET_DIALOG_DATA } from "../actions/types";

const INIT_DIALOG = {
  Data: {},
};

const currentDialogData = (status = INIT_DIALOG, action) => {
  switch (action.type) {
    case GET_DIALOG_DATA:
      return { ...status, ...action.payload };
    case CLEAR_DIALOG_DATA:
      return { Data: {} };
    default:
      return status;
  }
};

export default currentDialogData;
