import apiConfig from '../../apiConfig'
export const getHolidaysList = async (at, data) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/OFFICIALHOLIDAY/GET_OFFICIAL_HOLIDAY_LIST',
		{
			pageSize: 10000,
			PageIndex: 1,

		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	return response.data.Data.Data;
};
export const setHolidaysStatus = async (at, holidayId, status) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/OFFICIALHOLIDAY/UPD_OFFICIAL_HOLIDAY_STATUS',
		{
            Official_holiday_Id: holidayId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);

    return response.data;

}
export const deleteHolidays = async (at, holidayId) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/OFFICIALHOLIDAY/DEL_OFFICIAL_HOLIDAY', 
		{
			Official_holiday_Id: holidayId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}

