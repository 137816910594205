import apiConfig from '../apiConfig';

export const deleteDepartmentSection = async (at, departmentSectionId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/DEL_DEPARTMENT_SECTION', 
		{
			Department_Section_Id: departmentSectionId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}
