import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches, Select } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import ColorBox from 'devextreme-react/color-box'

class TypesOfStampsForm extends Component {

    state = {
        showForm: false,
    }

    onSubmit = async (values, form) => {
        console.log(this.props.stampTypeId)
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/STAMPS/Add_Document_Stamp_Type' : '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/STAMPS/Upd_Document_Stamp_Type',
        bodyData = {
            Document_Stamp_Type_Name_AR: values.Document_Stamp_Type_Name_AR,
            Document_Stamp_Type_Name_EN: values.Document_Stamp_Type_Name_EN,
            Document_Stamp_Key_Word: values.Document_Stamp_Key_Word,
            Document_Stamp_Color: values.Document_Stamp_Color,
            Active_Status_Id: values.Active_Status_Id == true ? 1 : 0,
            Document_Stamp_Notes: values.Document_Stamp_Notes,
        }

        if (this.props.editMood) {
            bodyData.Document_Stamp_Type_Id = this.props.stampTypeId
        } else {
            bodyData.Document_Stamp_Type_Id = this.props.currentUser.Data.stampTypeId
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
            this.setState({ showForm: false })
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)
    }

    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}
            if (!values.Document_Stamp_Type_Name_AR) {
                errors.Document_Stamp_Type_Name_AR = `${this.props.t('error.field_required')}`
            }

            if (!values.Document_Stamp_Type_Name_EN) {
                errors.Document_Stamp_Type_Name_EN = `${this.props.t('error.field_required')}`
            }

            if (!values.Document_Stamp_Key_Word) {
                errors.Document_Stamp_Key_Word = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('typesOfStamps')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addStamp')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }

                <Form
                    onSubmit={this.onSubmit}
                    initialValues={initValues ? initValues : {
                        Document_Stamp_Type_Name_AR: '',
                        Document_Stamp_Type_Name_EN: '',
                        Document_Stamp_Key_Word: '',
                        Document_Stamp_Color: '',
                        Active_Status_Id: '',
                        Document_Stamp_Notes: ''
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('stampNameAR')}
                                    className="mb-2"
                                    name="Document_Stamp_Type_Name_AR"
                                    required={true}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('stampNameEN')}
                                    className="mb-2"
                                    name="Document_Stamp_Type_Name_EN"
                                    required={true}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('docStamp')}
                                    className="mb-2"
                                    name="Document_Stamp_Key_Word"
                                    required={true}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('notes')}
                                    className="mb-2"
                                    name="Document_Stamp_Notes"
                                    required={true}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <div className='text-left MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth'>
                                    <label className='text-start MuiFormLabel-root MuiInputLabel-root mb-0 MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled'>
                                        {this.props.t('color')}
                                    </label>
                                    <ColorBox
                                        disabled={this.props.viewMood}
                                        className='custom-colorPicker'
                                        defaultValue={values.Document_Stamp_Color}
                                        name='Document_Stamp_Color'
                                        applyValueMode='instantly'
                                        onValueChanged={(e) => form.change("Document_Stamp_Color", e.value)}
                                    />
                                </div>
                            </Col>

                            <Col xs={12} md={6}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="Active_Status_Id"
                                    data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                />
                            </Col>

                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(TypesOfStampsForm))

