import apiConfig from '../../apiConfig';

export const deletegroup = async (at, groupUsersId) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/GROUPS/DEL_USERS_GROUP', 
		{
			User_Group_Id: groupUsersId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data.Data;
}
