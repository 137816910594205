import apiConfig from '../apiConfig'

export const getServiceActions = async (at, serviceid, url, stepId ) => {

    const response = await apiConfig.post(
        url,
        {
            Service_Id: serviceid,
            Service_Step_Id: stepId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        if (response.data.Data.Active_Status_Id === 1) {
            response.data.Data.Active_Status_Id = true;
        } else {
            response.data.Data.Active_Status_Id = false;
        }
        
        return response.data.Data
    }

    return []
}
