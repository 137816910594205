import apiConfig from '../apiConfig'

export const getCardsList = async (at) => {
    const response = await apiConfig.post(
        '/API/BASIC/SETTINGS/DASHBOARD_CARDS/GET_DASHBOARD_CARDS_LIST', {},
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data.Data
    }

    return []
}
