import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { ButtonPrimary, ButtonSecondary } from '../../UI/Buttons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField, Switches , Select } from 'mui-rff';
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from '../../../actions';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileUploader from 'devextreme-react/file-uploader';


class DecisionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showForm: false,
			decisionCategories: [],
			decisionDate: moment(new Date()).format('YYYY-MM-DD'),
			initValues: {}
		};
	}
	getDecisionCategories = async () => {
		let decisionCategories = [];

		const response = await apiConfig.post(
			'/API/SYSTEM/DDL/GET_DECISION_CATEGORIES'
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === 'ar'
						? item.Record_Name_AR
						: item.Record_Name_EN;

				decisionCategories.push({ value: item.Record_Id, label });
			});

			this.setState({ decisionCategories });
		}
	};
	onSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			bodyFormData = new FormData(),
			url = !this.props.editMood
				? '/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/ADD_DECISION'
				: '/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/Upd_Decision',
			bodyData = {
				decision_category_Id: values.Decision_category_Id,
				Decision_Code: values.Decision_Code,
				Decision_Name_AR: values.Decision_Name_AR,
				Decision_Name_EN: values.Decision_Name_EN,
				Decision_Date: moment(this.state.decisionDate).format('YYYY-MM-DD'),
				Decision_Description_AR: values.Decision_Description_AR,
				Decision_Description_EN: values.Decision_Description_EN,
				Active_Status_Id: values.Active_Status_Id ? 1 : 0,
			};

		if (this.props.editMood) {
			bodyData.Decision_Id = this.props.decisionCatId;
		}

		this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900);

		bodyFormData.append('Parameters', JSON.stringify(bodyData));
		if(this.props.editMood && this.state.file) {
			bodyFormData.append('Decision_File', this.state.file);
		}

		const response = await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		if (response.data.Status) {
			form.restart();
		}

		if (this.props.editMood) {
			this.props.triggerDialogForm(false);
		}

		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
	};
	handleUploadClick = (event) => {
		let file = event.target.files[0],
		url =  window.URL.createObjectURL(event.target.files[0]);
		this.setState({
			initValues: {
				Decision_File_Url: url,
				Decision_File_Type: file.name + file.type,
				Decision_File_Size: file.size
			},
			file,
		});
	};
    handleDateChange = (date) => {
        this.setState({
            decisionDate: moment(date).lang("en").format('YYYY-MM-DD')
        })
	}
	componentDidMount() {
		this.getDecisionCategories()
		let { initValues } = this.props;
		if(initValues) {
			this.setState({
				initValues: {
					Decision_File_Type: initValues.Decision_File_Type,
					Decision_File_Size: initValues.Decision_File_Size,
					Decision_File_Url: initValues.Decision_File_Url
				},
				decisionDate: initValues.Decision_Date
			})
		}
	}
	

	render() {
		let { initValues } = this.props;

		const validate = (values) => {
			const errors = {};
			if (!values.Decision_Code) {
				errors.Decision_Code = `${this.props.t('error.field_required')}`;
			}
			if (!values.Decision_Name_AR) {
				errors.Decision_Name_AR = `${this.props.t('error.field_required')}`;
			}
			if (!values.Decision_Name_EN) {
				errors.Decision_Name_EN = `${this.props.t('error.field_required')}`;
			}
			if (!values.Decision_category_Id) {
				errors.Decision_category_Id = `${this.props.t('error.field_required')}`;
			}
			if (!values.Decision_Description_AR) {
				errors.Decision_Description_AR = `${this.props.t(
					'error.field_required'
				)}`;
			}
			if (!values.Decision_Description_EN) {
				errors.Decision_Description_EN = `${this.props.t(
					'error.field_required'
				)}`;
			}
			return errors;
		};

		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className='d-flex justify-content-between align-items-center pb-5'>
						<h3>{this.props.t('manageDecisions')}</h3>
						<ButtonPrimary
							variant={!this.state.showForm ? 'contained' : 'outlined'}
							onClick={() => this.setState({ showForm: !this.state.showForm })}>
							{!this.state.showForm && this.props.t('addDecision')}
							{this.state.showForm && this.props.t('cancel')}
						</ButtonPrimary>
					</div>
				)}

				<Form
					onSubmit={this.onSubmit}
					initialValues={
						initValues
							? initValues
							: {
									Active_Status_Id: true,
									Decision_Code: '',
									Decision_Name_AR: '',
									Decision_Name_EN: '',
									Decision_Date: '',
									Decision_Description_AR: '',
									Decision_Description_EN: '',
							  }
					}
					validate={validate}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form
							id='sliderform'
							className={`row ${
								!this.state.showForm &&
								!this.props.editMood &&
								!this.props.viewMood
									? 'd-none'
									: ''
							}`}
							onSubmit={handleSubmit}
							noValidate>
							<Col xs={12} md={6}>
								<TextField
									disabled
									label={this.props.t('decisionID')}
									className='mb-2'
									name='Decision_Id'
								/>
							</Col>
							<Col xs={12} md={6}>
								<TextField
									label={this.props.t('decisionCode')}
									className='mb-2'
									name='Decision_Code'
								/>
							</Col>
							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t('decisionNameAR')}
									className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
									name='Decision_Name_AR'
									required={true}
									autoComplete='off'
									showError={({ meta: { submitFailed, invalid } }) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t('decisionNameEN')}
									className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
									name='Decision_Name_EN'
									required={true}
									autoComplete='off'
									showError={({ meta: { submitFailed, invalid } }) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>
							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t('decisionDescAR')}
									className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
									name='Decision_Description_AR'
									required={true}
									multiline
									rows={6}
									autoComplete='off'
									showError={({ meta: { submitFailed, invalid } }) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>
							<Col xs={12} md={6}>
								<TextField
									disabled={this.props.viewMood}
									label={this.props.t('decisionDescEN')}
									className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
									name='Decision_Description_EN'
									multiline
									rows={6}
									required={true}
									autoComplete='off'
									showError={({ meta: { submitFailed, invalid } }) => {
										return submitFailed && invalid;
									}}
								/>
							</Col>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Col xs={6}>
									<KeyboardDatePicker
										margin='normal'
										disabled={this.props.viewMood}
										className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
										format='yyyy-MM-dd'
										label={this.props.t('decisionDate')}
										value={this.state.decisionDate}
                                        onChange={(e) => this.handleDateChange(e)}
										KeyboardButtonProps={{
											'aria-label': this.props.t('decisionDate'),
										}}
									/>
								</Col>
							</MuiPickersUtilsProvider>

							<Col
								xs={12}
								md={6}
								className={`${this.props.viewMood ? 'read-only' : ''} mt-2`}>
								<Switches
									disabled={this.props.viewMood}
									color='primary'
									name='Active_Status_Id'
									data={{
										label: `${this.props.t('serviceStatus')}`,
										value: `${this.props.t('serviceStatus')}`,
									}}
								/>
							</Col>
							<Col md={6}>
								<Select
									disabled={this.props.viewMood}
									label={this.props.t('decisionCategories')}
									className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
									required={true}
									name="Decision_category_Id"
									data={this.state.decisionCategories}
									showError={  () => {
										let fieldStatus = form.getFieldState('Decision_category_Id')

										if (fieldStatus) {

											return fieldStatus.submitFailed && fieldStatus.invalid
										}
									} }
								>
								</Select>
							</Col>
								{!this.props.viewMood && (
								<Col md={12} className='d-flex'>
									<ButtonSecondary
										color='primary'
										variant='contained'
										className='mt-2'
										endIcon={<CloudUploadIcon />}>
										{this.props.t('decisionFile')}
										<input
											type='file'
											accept='image/*,.pdf'

											name='Decision_File'
											className='buttonFile'
											onChange={(e) => this.handleUploadClick(e)}
										/>
									</ButtonSecondary>
								</Col>
								)}
							{this.state.initValues.Decision_File_Url && this.state.initValues?.Decision_File_Url !== '' && (
							<Col md={6}>
								<ListItem className="shadow-sm">
								<ListItemAvatar>
									<CropOriginalIcon />
								</ListItemAvatar>
								<ListItemText primary={this.state.initValues?.Decision_File_Type} secondary={this.state.initValues?.Decision_File_Size}/>
								<IconButton
										edge='start'
										aria-label='download'
										onClick={() => {
											window.open(this.state.initValues?.Decision_File_Url , '_blank');
										}}
										>
										<GetAppIcon />
									</IconButton>
								</ListItem>
							</Col>
							)}
							<Col className='my-4 justify-content-start d-flex' xs={12}>
								{!this.props.viewMood && (
									<ButtonPrimary
										color='primary'
										variant='contained'
										disabled={submitting || this.props.viewMood}
										type='submit'>
										{this.props.t('save')}
									</ButtonPrimary>
								)}
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(DecisionForm));
