import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { ButtonPrimary , ButtonSecondary } from '../../UI/Buttons';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField, Switches, Select } from 'mui-rff';
import { triggerNotification, setLoading } from '../../../actions';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';

class SystemModuleForm extends Component {
	state = {
		initialValues: {},
        center: {},
		file: null,
		url: null,
        detaillPredefinedList: []
	};
    

	componentDidMount() {
        this.getServiceDetaillPredefinedList()

		const {
			Template_API_Variable_Id,
			Template_API_Variable_Name_AR,
			Template_API_Variable_Name_EN,
			Template_API_Variable_Code,
            Template_API_Variable_Parameter,
            Template_API_Id,
            Service_Detail_Predefined_Field_Id
		} = this.props.initValues;

		this.setState({
			initialValues: {
				Template_API_Variable_Id,
				Template_API_Variable_Name_AR,
				Template_API_Variable_Name_EN,
				Template_API_Variable_Code,
                Template_API_Variable_Parameter,
                Template_API_Id,
                Service_Detail_Predefined_Field_Id
            },
		});
	}

    getServiceDetaillPredefinedList = async () => {
        let detaillPredefinedList = [{value: null, label: this.props.t('withoutSelection')}]
        const response = await apiConfig.post('/API/ADMINISTRATION/SERVICES/GET_PREDEFINED_FIELDS', {}, {
            headers: {
				Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
			},
        })
        if (response.data.Status) {
            response.data.Data?.map((item)=> {
                let label = this.props.i18n.language === 'ar' ? item.Service_Detail_Predefined_Field_Name_AR : item.Service_Detail_Predefined_Field_Name_EN
                detaillPredefinedList.push({value: item.Service_Detail_Predefined_Field_Id, label})
            })
        }
        this.setState({ detaillPredefinedList })
    }

	onSubmit = async (values) => {
		const auth = this.props.currentUser.Data.access_token,
		url =
			'/API/ADMINISTRATION/TEMPLATESAPI/UPD_TEMPLATE_API_VARIABLE';

		const data = {
			...values,
		}

		this.props.setLoading(true, 'dark')

		const response = await apiConfig.post(url, data, {
			headers: {
				Authorization: `Bearer ${auth}`,
			},
		}).catch((error) => {
            return error.response
        })
		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);

	};


	render() {

		return (
			<>
				<Form
					onSubmit={this.onSubmit}
					initialValues={this.state.initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form
							onSubmit={handleSubmit}
							className={`row mt-4 mx-3 departmentFormClass ${!this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
							noValidate>
                                <Col xs={6} md={9}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row>
                                                <Col xs={12} md={4}>
                                                    <TextField
                                                        disabled
                                                        label={this.props.t('varId')}
														className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_API_Variable_Id'
                                                    />
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <TextField
                                                        disabled={this.props.viewMood}
                                                        label={this.props.t('varCode')}
														className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_API_Variable_Code'
                                                    />
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <TextField
                                                        disabled={this.props.viewMood}
                                                        label={this.props.t('varParam')}
														className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_API_Variable_Parameter'
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <TextField
                                                        disabled={this.props.viewMood}
                                                        label={this.props.t('varNameAR')}
                                                        className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_API_Variable_Name_AR'
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <TextField
                                                        disabled={this.props.viewMood}
                                                        label={this.props.t('varNameEN')}
                                                        className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_API_Variable_Name_EN'
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Select
                                                        disabled={this.props.viewMood}
                                                        label={this.props.t('predefinedField')}
                                                        className='mb-2'
                                                        name="Service_Detail_Predefined_Field_Id"
                                                        data={this.state.detaillPredefinedList}
                                                    >
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='mt-4 mb-4' xs={12}>
                                    <Row>
                                        <Col className='mt-2 submit-btn-wrap'>
                                            { !this.props.viewMood && 
                                                <ButtonPrimary
                                                    color='primary'
                                                    variant='contained'
                                                    disabled={submitting}
                                                    type='submit'>
                                                    {this.props.t('save')}
                                                </ButtonPrimary>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { triggerNotification, setLoading })(
	translationHook(SystemModuleForm)
);
