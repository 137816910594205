import apiConfig from '../../apiConfig'

export const deleteRequestInvoice = async ( at, Invoice_No, Request_Task_Id, Request_Id ) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DEL_REQUEST_TASK_INVOICE',
        {
            Invoice_No,
            Request_Task_Id,
            Request_Id
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}
