import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'

class OrderView extends Component {

    state = {
        showForm: false
    }

    onSectionSubmit = async (values, form) => {

    }


    render() {
        let { initValues } = this.props

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('departmentSections')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addSection')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }
                 {/* ? initValues : {
                        sectionStatus: true,
                        sectionNameAR: '',
                        sectionNameEN: '',
                        sectionDescAR: '',
                        sectionDescEN: '',
                        telephone: ''
                    } */}
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestNo')}
                                    className="mb-2"
                                    name="number"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestDate')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="date"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('dealingTypeAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="dealingTypeAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('dealingTypeEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="dealingTypeEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('DepartmentNameAr')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="departmentNameAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('DepartmentNameEn')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="departmentNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>

                            <Col xs={12} md={12}>
                                <TextField
                                    disabled
                                    label={this.props.t('serviceNo')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNo"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('serviceNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNameAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('serviceNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestStatusAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="requestStatusAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestStatusEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="requestStatusEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerEmail')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientMail"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientNameAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerPhone')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientPhone"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(OrderView))
