import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import { Row, Col } from 'react-bootstrap'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import CardList from './CardList'
import CardForm from './CardForm'

class Cards extends Component {

    state = {
        viewMood: false,
        editMood: false
    }

    triggerView = (viewMood, editMood) => {
        this.setState({viewMood, editMood})
    }

    handleEdit = (data) => {
        let initValues = {
            cardNameAR: data.Dashboard_Card_Name_AR,
            cardNameEN: data.Dashboard_Card_Name_EN,
            cardDescAR: data.Dashboard_Card_Description_AR,
            cardDescEN: data.Dashboard_Card_Description_EN,
            cardImageURL: data.Dashboard_Card_Icon_URL,
            cardStatus: data.Dashboard_Card_Active_Status_Id
        },

        cardId = data.Dashboard_Card_Id,
        cardName = this.props.i18n.language === 'ar' ? data.Dashboard_Card_Name_AR : data.Dashboard_Card_Name_EN,
        dialogTitle = `${this.props.t('editCardDetailTitle')} ${cardName}`
    
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                cardId,
                initValues
            ],
            action: this.triggerView,
        })
    }

    renderEditForm = (...params) => {
        return <CardForm cardId={params[0]} editMood={this.state.editMood} viewMood={this.state.viewMood} initValues={params[1]} triggerView={this.triggerView} />
    }

    render() {

        return (
            <>
                <Row>
                    {/* {!this.state.viewMood && !this.state.editMood &&
                        <Col xs={12} className='mb-5'>
                            <UserForm />
                        </Col>
                    } */}

                    <Col xs={12}>
                        <CardList triggerView={this.triggerView} handleEdit={this.handleEdit} />
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(Cards))
