import React from 'react'
import style from './Topbar.module.scss'
import LanguageBtn from './../../LanguageBtn/LanguageBtn'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import UserDropdown from './UserDropdown/UserDropdown'
import NotificationsDropdown from './NotificationsDropdown/NotificationsDropdown'
import Search from './Search/Search'
import Tasks from './Tasks/Tasks'
import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Topbar = props => {

    const useStyles = makeStyles(theme => ({
        respDrawer: {
            width: '100%',
            height: 128,
            background: '#fff!important'
        },
        arrow: {
            "&:before": {
                backgroundColor: 'white',
                boxShadow: theme.shadows[2],
            },
        },
        tooltip: {
            backgroundColor: 'white',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[2],
            fontSize: 14,
            padding: '6px 12px'
        }
    }))

    const classes = useStyles()
    const { t, i18n } = useTranslation('common')

    const handleDrawerClose = e => {
        if (e.target.className === 'MuiBackdrop-root') {
            props.onToggleTopbar()
        }
    }

    const topbar = (
        <ul className={style.topbarUl}>

            {/* <Tooltip
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} 
                title={`${t('search')}`} 
                placement="left"
                arrow
            >
                <li>
                    <Search />
                </li>
            </Tooltip> */}
            
            {/* <Tooltip
                title={`${t('tasks')}`}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} 
                arrow
            >
                <li>
                    <Tasks />
                </li>
            </Tooltip> */}

            <Tooltip 
                title={`${t('notifications')}`}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} 
                arrow
            >
                <li>
                    <NotificationsDropdown />
                </li>
            </Tooltip>

            <Tooltip 
                title={`${t('language')}`}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} 
                arrow
            >
                <li>
                    <LanguageBtn login={false} />
                </li>
            </Tooltip>

            <li>
                <UserDropdown />
            </li>
        </ul>
    )

    return (
        <>
            <Hidden smDown>
                {topbar}
            </Hidden>
        
            <Hidden mdUp>
                <Drawer
                    anchor='top'
                    open={props.respTopbarDrawer}
                    onClick={e => handleDrawerClose(e)}
                    className={style.respDrawer}
                    classes={{
                        paper: classes.respDrawer,
                    }}
                >
                    {topbar}
                </Drawer>
            </Hidden>
        </>
    )
}

export default Topbar