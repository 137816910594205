import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ButtonDanger, ButtonSecondary, ButtonPrimary, ButtonWarning } from '../../UI/Buttons'
import apiConfig from '../../../apis/apiConfig';
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


import { translationHook } from '../../translationHook'
import { getDepartmentServiceVersionDetailList } from '../../../apis/services/departments/getDepartmentServiceVersionDetailList'
import { deleteDepartmentVersionDetail } from '../../../apis/services/departments/deleteDepartmentVersionDetail'
import { triggerDialog, triggerDialogForm, setLoading, reload } from '../../../actions'
import ServiceVersionUnselectedDetail from './ServiceVersionUnselectedDetail'
import ServiceVersionDetailForm from './ServiceVersionDetailForm'


class ServiceVersionDetail extends Component {
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
    handleDelete = (data) => {
        let at = this.props.currentUser.Data.access_token,
        serviceDetailid = data.Department_Service_Detail_Id,
        serviceDetailName = this.props.i18n.language === 'ar' ? data.Department_Service_Detail_Title_AR : data.Department_Service_Detail_Title_EN,
        dialogTitle = `${this.props.t('confirmDeleteTitle')} ${serviceDetailName}`,
        dialogMsg = this.props.t('confirmDeleteMsg')

        this.props.triggerDialog(true, {
            title: dialogTitle,
            message: dialogMsg,
            action: {
                action: deleteDepartmentVersionDetail,
                params: [
                    at,
                    serviceDetailid
                ],
                text: this.props.t('deleteServiceDetail')
            }
        })

    }

    renderUnselectedServiceDetail = () => {
        return <ServiceVersionUnselectedDetail versionid={this.props.versionid} />
    }

    renderServiceVersionDetailForm = (...params) => {
        return <ServiceVersionDetailForm departmentDetailId={params[0]} initValues={params[1]} editMood={this.props.editMood} />
    }

    handleAddDetail = () => {

        this.props.triggerDialogForm(true, {
            title: '',
            renderForm: this.renderUnselectedServiceDetail,
            params: []
        })

    }

    handleEditDetail = (data) => {
        let initValues = {
            serviceLookups: data.Department_Service_Detail_Lookup_Id
        },
        departmentDetailId = data.Department_Service_Detail_Id,
        departmentDetailName = this.props.i18n.language === 'ar' ? data.Department_Service_Detail_Title_AR : data.Department_Service_Detail_Title_EN,
        dialogTitle = `${this.props.viewMood ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${departmentDetailName}`

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderServiceVersionDetailForm,
            params: [
                departmentDetailId,
                initValues
            ]
        })

    }

    setOrderUp = async (departmentServiceDetailId) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SET_DEPARTMENT_VERSION_DETAIL_ORDER_UP',
            {
                Department_Service_Detail_Id: departmentServiceDetailId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`,
                }
            }
        )

        this.props.setLoading(false)
        this.props.reload()
    }

    setOrderDown = async (departmentServiceDetailId) => {
        let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SET_DEPARTMENT_VERSION_DETAIL_ORDER_DOWN',
            {
                Department_Service_Detail_Id: departmentServiceDetailId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`,
                }
            }
        )

        this.props.setLoading(false)
        this.props.reload()
    }

    renderOrderButtons = ({data}) => {
        return (
            <div className="d-flex flex-column align-items-center order-btns">
                <IconButton onClick={() => this.setOrderUp(data.Department_Service_Detail_Id)}>
                    <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton onClick={() => this.setOrderDown(data.Department_Service_Detail_Id)}>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </div>
        )
    }


    render() {
        let columnAlign = 'left',
        ServiceDetailTitleKey = 'Department_Service_Detail_Title_EN',
        ServiceDetailTypeKey = 'Service_Detail_Type_Name_EN',
        ServiceLookupNameKey = 'Service_Lookup_Name_EN',
        FileTypeNameKey = 'File_Content_Type_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            ServiceDetailTitleKey = 'Department_Service_Detail_Title_AR'
            ServiceDetailTypeKey = 'Service_Detail_Type_Name_AR'
            ServiceLookupNameKey = 'Service_Lookup_Name_AR'
            FileTypeNameKey = 'File_Content_Type_Name_AR'
        }
        

        const jsonDataSource = new CustomStore({
            key: 'Department_Service_Detail_Id',
            load: async () => {
               return getDepartmentServiceVersionDetailList(at, this.props.versionid)
            }
        });

        return(
            <Row>
                
                {!this.props.viewMood &&
                <Col xs={12}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                            <ButtonPrimary
                                size="large"
                                onClick={this.handleAddDetail}
                            >
                                {this.props.t('addNewDetail')}
                            </ButtonPrimary>
                        
                    </div>
                </Col>
                }
                <Col xs={12}>
                    <DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

                        <Column cssClass="outlinedColumn" fixed={true} fixedPosition={this.props.i18n.language === 'ar' ? 'right' : 'left'} alignment="center" width={'70px'} cellRender={this.renderOrderButtons}>
                        </Column>
                        
                        <Column dataField="Department_Service_Detail_No" sortOrder="asc" caption={this.props.t('detailno')} alignment={'center'}>
                        </Column>

                        <Column dataField={ServiceDetailTitleKey} caption={this.props.t('serviceDetailName')} alignment={columnAlign}>
                        </Column>

                        <Column dataField={ServiceDetailTypeKey} caption={this.props.t('serviceDetailType')} alignment={columnAlign}>
                        </Column>

                        <Column cssClass="outlinedColumn" dataField='Department_Service_Detail_Required' caption={this.props.t('required')} allowFiltering={false}>
                        </Column>

                        <Column dataField={ServiceLookupNameKey} caption={this.props.t('lookupDDValue')} alignment={columnAlign}>
                        </Column>

                        <Column dataField={FileTypeNameKey} caption={this.props.t('fileType')} alignment={columnAlign}>
                        </Column>

                        <Column dataField='Department_Service_Detail_File_Extension' caption={this.props.t('fileExtension')} alignment={columnAlign}>
                        </Column>

                        <Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
                            {!this.props.viewMood && [
                            <Button
                            visible={({row}) => {
                                return row.data.Can_Edit_Detail === 1 ? true : false 
                            }}
                            render={(record) => {
                                return (
                                    
                                    <ButtonWarning
                                    variant="circleIcon"
                                    startIcon={<EditIcon />}
                                    onClick={() => this.handleEditDetail(record.data)}
                                    >
                                        {/* {this.props.t('edit')} */}
                                    </ButtonWarning>
                                )
                            }}
                            />,
                            
                            <Button
                            visible={({row}) => {
                                return row.data.Can_Delete_Detail === 1 ? true : false 
                            }}
                            render={(record) => {
                                return (
                                    
                                    <ButtonDanger
                                    variant="circleIcon"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => this.handleDelete(record.data)}
                                    >
                                        {/* {this.props.t('delete')} */}
                                    </ButtonDanger>
                                )
                            }}
                            />
                            ]}

                        </Column>

                    </DataGrid>

                </Col>
            </Row>
        )
    }
    onExporting(e) {
		let sheetTitle = this.props.t('serviceDetails')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }

}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, setLoading, reload })(translationHook(ServiceVersionDetail))