import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../apis/apiConfig";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";

import { ButtonPrimary } from "../../UI/Buttons";
import { translationHook } from "../../translationHook";
import { triggerNotification, setLoading, reload } from "../../../actions";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import style from "../processing/Processing.module.scss";
import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
// import moment from "moment";
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { getRequestManagmentInfo } from "../../../apis/requests/info/getRequestManagmentInfo";

import DataGrid, {
	Column,
	// Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Pager,
} from "devextreme-react/data-grid";

class RequestAdditionalInfo extends Component {
	state =  {
		requestAdditionalAttachments: [],
		requestInvoices: [],
		requestActions: [],
		requestResultsDocumentsFiles: [],
		requestResultsTemplatesFiles: []
	}

	onServiceInfoSubmit = async (values, form) => {
		return null;
	};

	// componentDidMount() {
	// 	(async () => {
	// 		await getRequestManagmentInfo(
	// 			this.props.currentUser.Data.access_token,
	// 			this.props.requestId,
	// 			4
	// 		)
	// 		.then((data) => {
	// 			this.setState({
	// 				requestAdditionalAttachments: data.data.Data.Request_Additional_Data.Request_Additional_Attachments,
	// 				requestInvoices: data.data.Data.Request_Additional_Data.Request_Invoices,
	// 				requestResultsDocumentsFiles: data.data.Data.Request_Additional_Data.Request_Results_Documents_Files,
	// 				requestResultsTemplatesFiles: data.data.Data.Request_Additional_Data.Request_Results_Templates_Files,
	// 				requestActions: data.data.Data.Request_Additional_Data.Request_Actions
	// 			})
				
	// 			if (!data.data.Status) {
	// 				throw data.data;
	// 			}

	// 			this.props.cachRequestAdditionalInfoCB({
	// 				notes:
	// 					data.data.Data?.Request_Additional_Data
	// 						?.Request_Additional_Notes ?? null,
	// 				attachments:
	// 					data.data.Data?.Request_Additional_Data
	// 						?.Request_Additional_Attachments ?? [],
	// 			});
	// 		})
	// 		.catch((err) => {
	// 			this.props.triggerNotification(true, err);
	// 		});
	// 	})();
	// }

	onDownloadFile = async (fileCode) => {
		let params = {
			at: this.props.currentUser.Data.access_token,
			File_Code: fileCode
		};

		const downloadResult = await downloadRequestAttachment(params);
		const url = window.URL.createObjectURL(
			new Blob([downloadResult.data], { type: "application/pdf" })
		  )
		const link = document.createElement("a");
		link.href = url
		link.setAttribute("target", "_blank")
		link.click()
	}

	reExecuteRequest = async (eRequestId) => {
		let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/REEXECUTE_REQUEST_ACTION',
            {
                Request_Id: this.props.requestId,
				Execute_Result_Id: eRequestId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`,
                }
            }
        )

		console.log(response);
		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false)

		if (response.data.Status) {
			this.props.reload()
		}
	}

	render() {
		let { notes, attachments, actions, invoices, templatesFiles, documentsFiles} = this.props.info;
		let alignment = 'left',
		invoiceType = 'Invoice_Type_Name_EN',
		invoiceStatus = 'Invoice_Status_Name_EN',
		invoiceCategory = 'Invoice_Type_Category_Name_EN',
		invoicePayer = 'Invoice_Payer_Name_EN',
		rActionEventLevelName = 'RAction_Event_Level_Name_EN',
		rActionEventName = 'RAction_Event_Name_EN',
		rActionName = 'RAction_Name_EN',
		rStepName = 'Request_Step_Name_EN',
		executeResult = 'Execute_Result_EN'

		if (this.props.i18n.language === 'ar') {
			alignment = 'right';
			invoiceType = 'Invoice_Type_Name_AR'
			invoiceStatus = 'Invoice_Status_Name_AR'
			invoiceCategory = 'Invoice_Type_Category_Name_AR'
			invoicePayer = 'Invoice_Payer_Name_AR'
			rActionEventLevelName = 'RAction_Event_Level_Name_AR'
			rActionEventName = 'RAction_Event_Name_AR'
			rActionName = 'RAction_Name_AR'
			rStepName = 'Request_Step_Name_AR'
			executeResult = 'Execute_Result_AR'
		}

		return (
			<>
				
				{
					actions.length ? (
						<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
							"mb-5"
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						keyExpr="Request_Id"
						dataSource={actions}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={false}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={this.props.i18n.language === "ar" ? true : false}
					>
						{/* <ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/> */}

						{/* <SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/> */}

						<Pager
							visible={true}
							showInfo={true}
							infoText={`${this.props.t("page")} {0} ${this.props.t(
								"of"
							)} {1} ({2} ${this.props.t("item")})`}
						/>

						{/* <FilterRow visible={true} /> */}

						{/* <HeaderFilter visible={true} /> */}

						{/* <Export enabled={true} allowExportSelectedData={true} /> */}

						<Grouping contextMenuEnabled={true} expandMode="rowClick" />
						{/* <GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/> */}

						<Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/>

						<Column
							dataField={rActionEventLevelName}
							caption={this.props.t('requestAdInfoLevel')}
							alignment={alignment}
							width={200}
						></Column>


						<Column
							dataField={rStepName}
							caption={this.props.t('stepName')}
							alignment={alignment}
							width={180}
						></Column>

						<Column
							dataField={rActionEventName}
							caption={this.props.t('requestAdInfoActionEvent')}
							alignment={alignment}
							width={180}
						></Column>

						<Column
							dataField={rActionName}
							caption={this.props.t('servicesExecutionOrder')}
							alignment={alignment}
							width={250}
						></Column>

						<Column
							// dataField={executeResult}
							caption={this.props.t('requestAdInfoActionResult')}
							alignment={"center"}
							cellRender={({ data }) => (
								data.Execute_Result_Status ? 
									<CheckCircleIcon color="primary" /> : <CancelIcon color="secondary" />
							)}
							// dataType="date"
							// format="yyyy-MM-dd"
						></Column>

						<Column
							dataField={executeResult}
							caption={this.props.t('requestAdInfoActionMessage')}
							alignment={alignment}
							width={250}
						></Column>

						<Column
							cellRender={({ data }) => (
								data.Can_Reexecute ? (
								<ButtonPrimary
									color='primary'
									variant='contained'
									onClick={() => this.reExecuteRequest(data.Execute_Result_Id)}
									// disabled={submitting}
									>
									{this.props.t('requestAdInfoReExecute')}
								</ButtonPrimary>
								) : null
							)}
							caption={this.props.t('requestAdInfoReExecute')}
							alignment={"center"}
							width={180}
						></Column>
					</DataGrid>
					) : null
				}
				
				{
					invoices.length ? (
				<>
					<div className="align-items-center mb-4">
						<h5>{this.props.t("serviceRequestInvoices")}</h5>
					</div>
					<DataGrid
						className={[
							this.props.i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						keyExpr="Invoice_Id"
						dataSource={invoices}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={true}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={this.props.i18n.language === "ar" ? true : false}
					>
						<ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/>

						<SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/>

						<Pager
							visible={true}
							showInfo={true}
							infoText={`${this.props.t("page")} {0} ${this.props.t(
								"of"
							)} {1} ({2} ${this.props.t("item")})`}
						/>

						<FilterRow visible={true} />

						<HeaderFilter visible={true} />

						<Export enabled={true} allowExportSelectedData={true} />

						<Grouping contextMenuEnabled={true} expandMode="rowClick" />
						<GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/>

						<Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/>

						<Column
							dataField='Payment_No'
							caption={this.props.t('paymentReceiptNumber')}
							alignment={alignment}
							width={180}
						></Column>

						<Column
							cellRender={({ data }) => (
								<IconButton color="secondary" aria-label="download" onClick={() => this.onDownloadFile(
									data.Invoice_File_Code || data.Payment_File_Code
								)}>
									<GetAppIcon />
								</IconButton>
							)}
							alignment={alignment}
							caption={this.props.t('invoiceFiles')}
							width={80}
						></Column>

						<Column
							dataField={invoiceType}
							caption={this.props.t('invoiceType')}
							alignment={alignment}
						></Column>

						<Column
							dataField={invoiceCategory}
							caption={this.props.t('additionalInvoiceDetails')}
							alignment={alignment}
						></Column>

						<Column
							dataField={invoicePayer}
							caption={this.props.t('invoiceDocumentName')}
							alignment={alignment}
							width={250}
						></Column>

						<Column
							dataField='Invoice_Date'
							caption={this.props.t('invoiceDate')}
							alignment={alignment}
							dataType="date"
							format="yyyy-MM-dd"
							width={180}
						></Column>

						<Column
							dataField='Invoice_Value'
							caption={this.props.t('invoiceValue')}
							alignment={alignment}
						></Column>

						<Column
							dataField='Online_Reference_No'
							caption={this.props.t('ePaymentNumber')}
							alignment={alignment}
						></Column>

						<Column
							dataField='Invoice_Paid_Date'
							caption={this.props.t('invoicePaidDate')}
							alignment={alignment}
							dataType="date"
							format="yyyy-MM-dd"
							width={180}
						></Column>

						<Column
							dataField='Invoice_Paid_Value'
							caption={this.props.t('invoicePaidValue')}
							alignment={alignment}
						></Column>


						<Column
							dataField={invoiceStatus}
							caption={this.props.t('invoiceStatus')}
							alignment={alignment}
						></Column>


						<Column
							dataField='Invoice_Payer_License_No'
							caption={this.props.t('licenseNumber')}
							alignment={alignment}
						></Column>

						

						{/* <Column
							dataField='Payment_No'
							caption={this.props.t('paymentNo')}
							alignment={alignment}
						></Column> */}
					</DataGrid>
				</>
					)
					:
					null
				}

				{/* ----- */}

				{
					templatesFiles.length ? (
						<Row className={style.altWrap}>
							<Col xs={12} md={12}>
								<h5 className="my-4">
									{this.props.t("serviceRequestForms")}
								</h5>
								<FilesUpload
									downloadCB={downloadRequestAttachment}
									inline={true}
									view={true}
									attachments={[{ Files_Data: templatesFiles }]}
								/>
							</Col>
						</Row>
					) : null
				}
				

				{
					documentsFiles.length ? (
						<Row className={style.altWrap}>
							<Col xs={12} md={12}>
								<h5 className="my-4">
									{this.props.t("serviceRequestDocumentsCharts")}
								</h5>
								<FilesUpload
									downloadCB={downloadRequestAttachment}
									inline={true}
									view={true}
									attachments={[{ Files_Data: documentsFiles}]}
								/>
							</Col>
						</Row>
					) : null
				}

				{
					notes ? (
						<>
							<div className="align-items-center my-4">
					<h5>{this.props.t("additionalAttachments")}</h5>
				</div>

				<Form
					onSubmit={this.onServiceInfoSubmit}
					initialValues={{
						requestAdditionalData: notes,
					}}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<>
							<form
								className="row"
								onSubmit={handleSubmit}
								noValidate
							>
								<Col xs={12}>
									<TextField
										disabled
										label={this.props.t("notes")}
										className="read-only mb-4"
										name="requestAdditionalData"
										multiline={true}
										value={notes}
									/>
								</Col>
							</form>

							<Row className={style.altWrap}>
								<Col xs={12} md={4}>
									<FilesUpload
										downloadCB={downloadRequestAttachment}
										view={true}
										// attachments={[{ Files_Data: attachments }]}
										attachments={[{ Files_Data: attachments }]}
									/>
								</Col>
							</Row>
						</>
					)}
				/>
						</>
					) : null
				}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, { triggerNotification, setLoading, reload })(
	translationHook(RequestAdditionalInfo)
);
