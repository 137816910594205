import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../translationHook';
import apiConfig from '../../apis/apiConfig';
import { ButtonPrimary, ButtonSecondary } from '../UI/Buttons';
import { Row, Col } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import { triggerNotification, setLoading } from '../../actions';
import TaskAttachments from '../assignedTasks/TaskAttachments';
import moment from 'moment';
class TaskForm extends Component {
	state = {
		initialValues: {},
		showForm: false,
	};

	componentDidMount() {
		console.log(this.props.initValues)
	}

	onSubmit = async (values) => {
		
	};

	render() {
		return (
			<>
				<Form
					subscription={{ submitting: true, initialValues: true }}
					onSubmit={this.onSubmit}
					initialValues={this.props.initValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => {
						return (
							<form
								onSubmit={handleSubmit}
								className={`row mt-4 mx-3`}
								noValidate>
								<Col xs={6} md={4}>
									<TextField
										className={'read-only mb-2'}
										name="Mission_Date"
										disabled
										label={this.props.t('missionDate')}
										value={moment(this.props.initValues?.Mission_Date).format('HH:mm YYYY-MM-DD')}

									/>
								</Col>
								<Col xs={6} md={4}>
									<TextField
										className={'read-only mb-2'}
										name={this.props.i18n.language === 'ar' 
										? "Mission_from_User_Name_AR" : 'Mission_from_User_Name_EN'}
										label={this.props.t('missionFrom')}
										disabled
									/>
								</Col>
								<Col xs={6} md={4}>
									<TextField
										className={'read-only mb-2'}
										name={this.props.i18n.language === 'ar' 
										? "Mission_Type_Name_AR" : 'Mission_Type_Name_EN'}
										label={this.props.t('missionType')}
										disabled
									/>
								</Col>
								<Col xs={6} md={12}>
									<TextField
										className={'read-only mb-2'}
										name={'Mission_Title'}
										label={this.props.t('missionTitle')}
										disabled
									/>
								</Col>
								<Col xs={6} md={12}>
									<TextField
										className={'read-only mb-2'}
										name={'Mission_Description'}
										multiline
										rows={4}
										label={this.props.t('missionDesc')}
										disabled
									/>
								</Col>
								<Col xs={6} md={12}>
									<TaskAttachments view={true} missionID={this.props.initValues.Mission_Id}  />
								</Col>
								
								{/* <Col className='mt-4 mb-4' xs={12}>
                                    <ButtonPrimary
                                        color='primary'
                                        variant='contained'
                                        disabled={submitting}
                                        type='submit'>
                                        {this.props.t('save')}
                                    </ButtonPrimary>
								</Col> */}
							</form>
						);
					}}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { triggerNotification, setLoading })(
	translationHook(TaskForm)
);
