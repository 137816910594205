import apiConfig from '../../apiConfig';

export const getGroupDetailsScreen = async (at, groupId) => {
    console.log(groupId);
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/GROUPS/GET_USERS_GROUP_DETAILS',{
			User_Group_Id: groupId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    

	if (response.data.Status) {
		for (let i in response.data.Data.User_Group_Screens) {
			if (response.data.Data.User_Group_Screens[i].Selected === 1) {
				response.data.Data.User_Group_Screens[i].Selected = true;
			} else {
				response.data.Data.User_Group_Screens[i].Selected = false;
			}
			if (response.data.Data.User_Group_Screens[i].Can_Add === 1) {
				response.data.Data.User_Group_Screens[i].Can_Add = true;
			} else {
				response.data.Data.User_Group_Screens[i].Can_Add = false;
			}
			if (response.data.Data.User_Group_Screens[i].Can_Update === 1) {
				response.data.Data.User_Group_Screens[i].Can_Update = true;
			} else {
				response.data.Data.User_Group_Screens[i].Can_Update = false;
			}
			if (response.data.Data.User_Group_Screens[i].Can_Delete === 1) {
				response.data.Data.User_Group_Screens[i].Can_Delete = true;
			} else {
				response.data.Data.User_Group_Screens[i].Can_Delete = false;
			}
		}
		return response.data.Data.User_Group_Screens;
	}
    console.log(response.data.Data);

	return [];
};
