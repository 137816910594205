import React from 'react'
import apiConfig from '../../../apis/apiConfig'
import { connect } from 'react-redux'
import { TextField } from 'mui-rff'
import { Form } from 'react-final-form'
import { navigate } from '@reach/router'
import { ButtonPrimary, ButtonDanger } from '../../UI/Buttons'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { saveTaskAdditionalNotes } from '../../../apis/requests/processTask'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'

const ReOpeningServiceRequest = (props) => {
    const { t, i18n } = useTranslation('common')

    const onTaskSubmit = async (values) => {
        props.setLoading(true, 'dark')

        let at = props.currentUser.Data.access_token,
        url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_REOPEN'

        const response = await apiConfig.post(url,
            {
                Request_Id: props.requestId,
                Request_Task_Id: props.requestTaskId,
                Request_Reopen_Reasons: values.reasonsforOpening
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        if (response) {
            props.setLoading(false)
            props.triggerNotification(true, response.data)

            if (response.data.Status) {
                props.triggerDialogForm(false)
                navigate('/servicies-requests')
            } else {
                values.reasonsforOpening = ''
            }
        }
    }

    const handleClear = () => props.triggerDialogForm(false)

    const validate = values => {
        const errors = {}
        if (!values.notes && props.required) {
            errors.notes = `${t('error.field_required')}`
        }
        return errors
    }

    return (
        <>
            <Form
                onSubmit={onTaskSubmit}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                        id="taskFrom"
                        className="row"
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <Col xs={12} md={12}>
                            <TextField
                                label={t('reasonsFoOpeningServiceRequest')}
                                className="mb-2"
                                name="reasonsforOpening"
                                required={props.required}
                                autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => {
                                    return submitFailed && invalid
                                }}
                                multiline
                                rows={12}
                            />
                        </Col>

                        <Col className='my-5 justify-content-start d-flex' xs={12}>
                            <ButtonPrimary
                                color="primary"
                                variant="contained"
                                disabled={submitting}
                                type="submit"
                            >
                                {t('save')}
                            </ButtonPrimary>
                            <ButtonDanger
                                variant='outlined'
                                onClick={handleClear}>
                                    {t('cancel')}
                            </ButtonDanger>
                        </Col>
                    </form>
                )}
            />
        </>
    )
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(ReOpeningServiceRequest)