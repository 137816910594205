import apiConfig from "../apiConfig";
export const getInvoiceTypeList = async (at) => {
  const response = await apiConfig.post(
    "/API/ADMINISTRATION/PAYMENTS/INVOICETYPE/GET_INVOICE_TYPE_LIST",
    {
      PageSize: 10000,
      PageIndex: 1,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  if (response.data.Status) {
    for (let i in response.data.Data) {
      if (response.data.Data[i].Active_Status_Id === 1) {
        response.data.Data[i].Active_Status_Id = true;
      } else {
        response.data.Data[i].Active_Status_Id = false;
      }
    }
    console.log(response.data.Data);
    return response.data.Data;
  }

  return [];
};
