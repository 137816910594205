import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary , ButtonSecondary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'

class DecisionCategoryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
        }
    }
    onSectionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? 
        '/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/ADD_DECISION_CATEGORY' : 
        '/API/ADMINISTRATION/PORTAL/DECISIONCATEGORIES/UPD_DECISION_CATEGORY',
        bodyData = {
            Decision_category_Name_AR: values.Decision_category_Name_AR,
            Decision_category_Name_EN: values.Decision_category_Name_EN,
            Active_Status_Id: values.Active_Status_Id ? 1 : 0
        }

        if (this.props.editMood) {
            bodyData.Decision_category_Id = this.props.decisionCatId
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        this.props.triggerNotification(true, response.data)
        this.props.setLoading(false)
    }

    render() {
        
        let { initValues } = this.props;

        const validate = values => {
            const errors = {}

            if (!values.Decision_category_Name_AR) {
                errors.Decision_category_Name_AR = `${this.props.t('error.field_required')}`
            }

            if (!values.Decision_category_Name_EN) {
                errors.Decision_category_Name_EN = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('manageDecisionCategories')}</h3>
                        <div>
                            <ButtonPrimary
                                variant={!this.state.showForm ? 'contained' : 'outlined'}
                                onClick={() => this.setState({showForm: !this.state.showForm , status: 1}) }
                            >
                                {!this.state.showForm &&  this.props.t('addgroup')}
                                {this.state.showForm &&  this.props.t('cancel')}
                            </ButtonPrimary>
                            {/* <ButtonPrimary
                                variant={!this.state.showForm ? 'contained' : 'outlined'}
                                onClick={() => this.setState({showForm: !this.state.showForm, status: 2}) }
                            >
                                {!this.state.showForm && this.props.t('addDecision')}
                                {this.state.showForm && this.state.status ===  2 && this.props.t('cancel')}
                            </ButtonPrimary> */}
                        </div>
                    </div>
                }
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues ? initValues : {
                        Active_Status_Id: true,
                        Decision_category_Name_AR: '',
                        Decision_category_Name_EN: ''
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('decisionCategoryID')}
                                    className="mb-2"
                                    name="Decision_category_Id"
                                />
                            </Col>

                            <Col xs={12} md={6} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="Active_Status_Id"
                                    data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('decisionCategoryNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="Decision_category_Name_AR"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('decisionCategoryNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="Decision_category_Name_EN"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>


                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                        </form>
                    )}
                />

            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DecisionCategoryForm))
