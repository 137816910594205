import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../../apis/apiConfig'
import { translationHook } from '../../../translationHook'
import GoogleMap from '../../../UI/google-map/googleMap';
import { Form, Field } from 'react-final-form'
import { TextField } from 'mui-rff'
import { Row, Col } from 'react-bootstrap'
import { ButtonSecondary } from '../../../UI/Buttons'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../../actions'



class Visit extends Component {

    state = {
        visitData: null,
        locationUrl: '',
        showStartBtn: false,
        showEndBtn: false,
        markers:[]
    }

    getLongAndLat = () => {
        return new Promise((resolve, reject) =>
            navigator.geolocation.getCurrentPosition(resolve, reject, {
                enableHighAccuracy: true,
                maximumAge: 0
            })
        );
    }
    

    getRequestInspectionVisitData = async () => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_INSPECTION_VISIT_DATA'
         
        const response = await apiConfig.post(
        url,
            {
                Request_Id: this.props.requestId,
                Request_Task_Id: this.props.requestTaskId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )


        if (response.data.Status) {
            // console.log(response.data)
            let showStartBtn = !response.data.Data.Inspection_Visit_Start_Time ? true : false,
            showEndBtn = !response.data.Data.Inspection_Visit_End_Time ? true : false,
            markers = [...this.state.markers, {lat: parseFloat(response.data.Data.Inspection_Visit_latitudes), lng: parseFloat(response.data.Data.Inspection_Visit_longitudes) }]

            if (response.data.Data.Inspection_Visit_Start_latitudes && response.data.Data.Inspection_Visit_Start_longitudes) {
                // this.setState({markers: [...this.state.markers, {lat: response.data.Data.Inspection_Visit_Start_latitudes, lng: response.data.Data.Inspection_Visit_Start_longitudes }] })
                markers.push(
                    {lat: parseFloat(response.data.Data.Inspection_Visit_Start_latitudes), lng: parseFloat(response.data.Data.Inspection_Visit_Start_longitudes) }
                )
            }

            if (response.data.Data.Inspection_Visit_End_latitudes && response.data.Data.Inspection_Visit_End_longitudes) {
                // this.setState({markers: [...this.state.markers, {lat: response.data.Data.Inspection_Visit_End_latitudes, lng: response.data.Data.Inspection_Visit_End_longitudes }] })
                markers.push(
                    {lat: parseFloat(response.data.Data.Inspection_Visit_End_latitudes), lng: parseFloat(response.data.Data.Inspection_Visit_End_longitudes) }
                )
            }

            this.setState({ 
                visitData: response.data.Data, 
                showStartBtn, 
                showEndBtn, 
                markers
            })
        }

        let locUrl = `https://www.google.com/maps/search/?api=1&query=${parseFloat(response.data.Data.Inspection_Visit_latitudes)},${parseFloat(response.data.Data.Inspection_Visit_longitudes)}`,
        encodedURL = encodeURI(locUrl)
        this.setState({locationUrl: encodedURL})
    }

    startRequestInspectionTime = async (coords) => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/START_REQUEST_TASK_INSPECTION_VISIT'


            this.props.setLoading(true, 'dark', 99999)



            const position = await this.getLongAndLat().then(async (data) => {
                const response = await apiConfig.post(
                url,
                    {
                        Request_Id: this.props.requestId,
                        Request_Task_Id: this.props.requestTaskId,
                        Temp_Action_Id: this.props.tempId,
                        Start_longitudes: data.coords.longitude,
                        Start_latitudes: data.coords.latitude
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${at}`
                        },
                    }
                ).then((result) => {
                    this.setState({visitData: result.data.Data, markers: [...this.state.markers, {lat: data.coords.latitude, lng: data.coords.longitude }]})
                    this.props.setLoading(false)
                })
            }).catch(error => {
                this.props.triggerNotification(true, error)
            })
         
     
    }

    endRequestInspectionTime = async () => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/END_REQUEST_TASK_INSPECTION_VISIT'


            this.props.setLoading(true, 'dark', 99999)

            const position = await this.getLongAndLat().then(async (data) => {
                const response = await apiConfig.post(
                url,
                    {
                        Request_Id: this.props.requestId,
                        Request_Task_Id: this.props.requestTaskId,
                        Temp_Action_Id: this.props.tempId,
                        End_longitudes: data.coords.longitude,
                        End_latitudes: data.coords.latitude
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${at}`
                        },
                    }
                ).then((result) => {
                    this.setState({visitData: result.data.Data, markers: [...this.state.markers, {lat: data.coords.latitude, lng: data.coords.longitude }]})
                    this.props.setLoading(false)
                })
            }).catch(error => {
                this.props.triggerNotification(true, error)
            })
    }

    componentDidMount() {
        this.getRequestInspectionVisitData()
    }

    // componentDidUpdate() {
    //     if (this.state.visitData && this.state.locationUrl === '') {
    //         let url = `https://www.google.com/maps/search/?api=1&query=${this.state.visitData.Inspection_Visit_End_latitudes},${this.state.visitData.Inspection_Visit_End_longitudes}`,

    //         encodedURL = encodeURI(url)

    //         this.setState({locationUrl: encodedURL})
    //     }
    // }

    render() {
        
        const validate = values => {
            const errors = {}
    
            return errors
        }

        return (
            <>
            <Row>
                {this.state.visitData && (
                    <>
                    <Col xs={12}>
                        <h4 className="my-3"><a target="blank" href={this.state.locationUrl}>{ this.props.t('goToInspectionLocation') }</a></h4>
                    </Col>
                    <Col xs={12}>
                        <GoogleMap
                            markers={this.state.markers}
                            labels={[this.props.t('visitLocation'), this.props.t('inspectionStartLocation'), this.props.t('inspectionEndLocation')]}
                        />
                    </Col>
                    </>
                )}

                <Col xs={12}>
                    <Form
                    onSubmit={() => { return null}}
                    initialValues={{startTime: this.state.visitData?.Inspection_Visit_Start_Time ? this.state.visitData.Inspection_Visit_Start_Time : '', endTime: this.state.visitData?.Inspection_Visit_End_Time ? this.state.visitData.Inspection_Visit_End_Time : ''}}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="inspectionVisitForm"
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Row>

                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                label={this.props.t('inspectionStartTime')}
                                                disabled={true}
                                                className="mb-2"    
                                                name="startTime"
                                            />
                                        </Col>
                                        <Col xs={12} md={6} className="d-flex align-items-center">
                                                { this.state.showStartBtn &&
                                                <ButtonSecondary
                                                variant="outlined"
                                                className="mb-2"
                                                onClick={() => { this.startRequestInspectionTime() }}
                                                >
                                                    {this.props.t('startInspectionVisit')}
                                                </ButtonSecondary>
                                                }
                                        </Col>
                                    </Row>
                                </Col>


                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                label={this.props.t('inspectionEndTime')}
                                                disabled={true}
                                                className="mb-2"    
                                                name="endTime"
                                            />
                                        </Col>

                                        <Col xs={12} md={6} className="d-flex align-items-center">
                                                { this.state.showEndBtn &&
                                                <ButtonSecondary
                                                variant="outlined"
                                                className="mb-2"
                                                onClick={() => { this.endRequestInspectionTime() }}
                                                >
                                                    {this.props.t('endInspectionVisit')}
                                                </ButtonSecondary>
                                                }
                                        </Col>
                                    </Row>
                                </Col>


                            </Row>
                        </form>
                    )}
                    />
                    
                </Col>
                
            </Row>
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(Visit))
