import apiConfig from "../../apis/apiConfig";

export const cSTDashoardStatistics = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_STATISTICS",
		{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getNewRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_NEW_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getDuePaymentRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_DUE_PAYMENT_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getPaidRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_PAID_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getRejectedRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_REJECTED_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getReturnedRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_RETURN_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data.Data ? response.data.Data : [];
};
export const getTransferredRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_TRANSFERRED_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getUnderProcessRequestList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_UNDER_PROCESS_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getMissionsList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_MISSIONS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};

export const getReturnedFromClientList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/CUSTOMERSERVICE/GET_CUSTOMER_SERVICE_DASHBOARD_REQUESTS_RETURN_FROM_CLIENTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
