import apiConfig from "../apis/apiConfig";
import {
  LOGIN_USER,
  ACCOUNT_PROFILES,
  LOGOUT_USER,
  GET_USER_DETAILS,
  TRIGGER_NOTIFICATION,
  SET_ACTIVE_PAGE,
  TRIGGER_DIALOG,
  TRIGGER_DIALOG_FORM,
  GET_DIALOG_DATA,
  CLEAR_DIALOG_DATA,
  RELOAD,
  TRIGGER_LOADING,
  RESET_PASS,
} from "./types";

export const setLoading = (status, style, zIndex) => {
  return {
    type: TRIGGER_LOADING,
    payload: { status, style, zIndex },
  };
};

export const reload = () => {
  return {
    type: RELOAD,
    payload: true,
  };
};

export const setCurrentPage = (route) => {
  return {
    type: SET_ACTIVE_PAGE,
    payload: route,
  };
};
export const setCurrentProfile = (userId, token) => {
  return async (dispatch, getState, id = userId) => {
    dispatch(setLoading(true, "light"));

    const response = await apiConfig
      .post(
        "/API/SYSTEM/LOGIN/SET_USER_ACCOUNT_PROFILE",
        {
          User_Id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        window.sessionStorage.setItem("login", JSON.stringify(res.data));
        dispatch({ type: GET_USER_DETAILS, payload: res.data });

        dispatch(triggerNotification(true, res.data));

        dispatch(setLoading(false));

        let urlName = window.location.href.split("/").at(-1);

        let list = res?.data?.Data?.User_Permissions?.filter(
          (ele) => ele?.User_Screen_URL === `/${urlName}`
        );

        if (list.length < 1) {
          window.location.replace("/");
        }
      });
  };
};

export const triggerNotification = (status, data = {}) => {
  return async (dispatch, getState) => {
    if (status) {
      dispatch(reload());
    }

    const dataArr = Array.isArray(data?.Data) ? data?.Data : [data];

    let payload = {
      Open: status,
      success: data?.Status,
      error: !data?.Status,
      messages: data?.message ? data.message : !data?.Status ? dataArr : null,
    };

    dispatch({
      type: TRIGGER_NOTIFICATION,
      payload,
    });
  };
};

export const triggerDialog = (status, data = {}) => {
  return {
    type: TRIGGER_DIALOG,
    payload: {
      Open: status,
      Data: data,
    },
  };
};

export const triggerDialogForm = (status, data = {}) => {
  return {
    type: TRIGGER_DIALOG_FORM,
    payload: {
      Open: status,
      Data: data,
    },
  };
};

export const getDialogData = (data = {}) => {
  console.log(data);
  return {
    type: GET_DIALOG_DATA,
    payload: {
      Data: data,
    },
  };
};

export const clearDialogData = () => {
  return {
    type: CLEAR_DIALOG_DATA,
    payload: {
      Data: {},
    },
  };
};

export const userLogin = (formValues) => {
  return async (dispatch, getState, values = formValues) => {
    dispatch(setLoading(true, "light"));

    const response = await apiConfig
        .post("/API/SYSTEM/LOGIN/USERS_LOGIN", {
        Login_Name: values.username,
        Login_Password: values.password,
      })
      .then((res) => {
        if (!res.data.Status) {
          return Promise.reject(res);
        }

        const data = {
          ...res.data,
          resetPass: res.data.Data.Reset_Password === 0 ? false : true,
          verifyMail: res.data.Data.User_Mail_Verified === 1 ? true : false,
          verifyPhone: res.data.Data.User_Phone_Verified === 1 ? true : false,
        };

        dispatch({ type: LOGIN_USER, payload: data });
        window.sessionStorage.setItem("login", JSON.stringify(data));
        window.sessionStorage.setItem(
          "verifyMail",
          res.data.Data.User_Mail_Verified === 1 ? true : false
        );
        window.sessionStorage.setItem(
          "verifyPhone",
          res.data.Data.User_Phone_Verified === 1 ? true : false
        );
        dispatch(setLoading(false));
        dispatch(triggerNotification(true, res.data));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          triggerNotification(true, {
            Status: false,
            message: err.data.Error_AR + " / " + err.data.Error_EN,
          })
        );
      });
  };
};

export const userLogout = () => {
  window.sessionStorage.removeItem("login");
  window.sessionStorage.removeItem("verifyMail");
  window.sessionStorage.removeItem("verifyPhone");
  return {
    type: LOGOUT_USER,
    payload: {
      Status: false,
      Data: {},
    },
  };
};

export const getUserDetails = (params) => {
  return async (dispatch, getState, auth = params) => {
    const data = {
      ...JSON.parse(window.sessionStorage.getItem("login")),
      verifyMail: JSON.parse(window.sessionStorage.getItem("verifyMail")),
      verifyPhone: JSON.parse(window.sessionStorage.getItem("verifyPhone")),
    };

    dispatch({ type: GET_USER_DETAILS, payload: data });
  };
};
export const getAccountProfiles = (params) => {
  return async (dispatch, getState, auth = params) => {
    dispatch(setLoading(true, "light"));

    const response = await apiConfig.post(
      "API/SYSTEM/USERS/GET_USER_ACCOUNT_PROFILES",
      {},
      {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    if (response.data.Status) {
      const permissionsResponse = await apiConfig.post(
        "/API/SYSTEM/LOGIN/GET_USER_PERMISSIONS",
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      if (permissionsResponse.data.Status) {
        response.data.Data.User_Permissions = permissionsResponse.data.Data;
      }

      response.data.Data.access_token = auth.token;
    }
    // resetPass: response.data.Data.Reset_Password === 0 ? false : true,
    const data = {
      ...response.data,
    };

    dispatch({ type: GET_USER_DETAILS, payload: data });

    dispatch(setLoading(false));
  };
};
