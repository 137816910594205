import React from "react";
import { connect } from "react-redux";

import imgLight from "../../images/ShMunAnimatedGif-inside.gif";
import imgDark from "../../images/ShMunAnimatedGif.gif";

const Loading = (props) => {
	let styleClass = `loading-${props.loading.style}`,
		display = props.loading.status,
		zIndex = props.loading.zIndex;

	return (
		<div
			className={`${styleClass} ${!display ? "d-none" : ""}`}
			style={{ zIndex }}
		>
			{props.loading.style === "light" && (
				<img src={imgDark} alt="spinner" />
			)}

			{props.loading.style === "dark" && (
				<img src={imgDark} alt="spinner" />
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return { loading: state.loading };
};

export default connect(mapStateToProps)(Loading);
