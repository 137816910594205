import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import HistoryIcon from "@material-ui/icons/History";

import { getDepartmentServiceList } from "../../../apis/services/departments/getDepartmentServiceList";
import { setDepartmentServiceStatus } from "../../../apis/services/departments/setDepartmentServiceStatus";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import { ButtonPrimary, ButtonSecondary } from "../../UI/Buttons";

import DepartmentUnselectedServiceList from "./DepartmentUnselectedServicesList";

class DepartmentsServicesList extends Component {
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);
  }
  customizeCellCheckbox = ({ data }) => {
    let label =
      this.props.i18n.language === "ar"
        ? data.Active_Status_Name_AR
        : data.Active_Status_Name_EN;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={data.Active_Status_Id}
            name={`serviceStatus_${data.Service_Id}`}
            onChange={async () => {
              let at = this.props.currentUser.Data.access_token,
                departmentServiceId = data.Department_Service_Id,
                status = data.Active_Status_Id ? 0 : 1;

              this.props.setLoading(true, "dark");

              const response = await setDepartmentServiceStatus(
                at,
                departmentServiceId,
                status
              );

              this.props.triggerNotification(true, response);

              this.props.setLoading(false);
            }}
          />
        }
        // label={label}
      />
    );
  };

  renderUnselectedServiceList = (...params) => {
    return <DepartmentUnselectedServiceList />;
  };

  handleAddService = () => {
    this.props.triggerDialogForm(true, {
      title: "",
      renderForm: this.renderUnselectedServiceList,
      params: [],
    });
  };

  render() {
    let columnAlign = "left",
      ServiceNameKey = "Service_Name_EN",
      ServiceCategoryKey = "Service_Category_Name_EN",
      ServiceSectionKey = "Department_Section_Name_EN",
      at = this.props.currentUser.Data.access_token;

    if (this.props.i18n.language === "ar") {
      columnAlign = "right";
      ServiceNameKey = "Service_Name_AR";
      ServiceCategoryKey = "Service_Category_Name_AR";
      ServiceSectionKey = "Department_Section_Name_AR";
    }

    const jsonDataSource = new CustomStore({
      key: "Department_Service_Id",
      load: () => {
        return getDepartmentServiceList(at);
      },
    });

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>{this.props.t("municipalitiesServices")}</h3>
          <ButtonPrimary size="large" onClick={this.handleAddService}>
            {this.props.t("addService")}
          </ButtonPrimary>
        </div>

        <DataGrid
          className={[
            this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
            "globalBox",
          ].join(" ")}
          id="dataGrid"
          width={"100%"}
          dataSource={jsonDataSource}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          allowColumnReordering={true}
          rowAlternationEnabled={false}
          focusedRowEnabled={true}
          onExporting={this.onExporting}
          columnWidth={"auto"}
          wordWrapEnabled={true}
          rtlEnabled={this.props.i18n.language === "ar" ? true : false}
        >
          <ColumnChooser
            enabled={true}
            allowSearch={true}
            search={"fff"}
            title={this.props.t("dxColumnChooserTitle")}
            emptyPanelText={this.props.t("dxColumnChooserPanelText")}
          />

          <SearchPanel
            visible={true}
            width={240}
            placeholder={this.props.t("search")}
          />

          <FilterRow visible={true} />

          <HeaderFilter visible={true} />

          <Export enabled={true} allowExportSelectedData={true} />

          <Grouping contextMenuEnabled={true} expandMode="rowClick" />
          <GroupPanel
            visible={true}
            emptyPanelText={this.props.t("dxGroupPanelText")}
          />

          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />

          <Column
            dataField="Service_No"
            caption={this.props.t("serviceNo")}
            alignment={"center"}
            width={"100px"}
          ></Column>

          <Column
            dataField={ServiceNameKey}
            caption={this.props.t("serviceName")}
            alignment={columnAlign}
          ></Column>

          <Column
            cssClass="outlinedColumn"
            dataField="Service_Code"
            caption={this.props.t("serviceCode")}
            alignment={"center"}
            width={"150px"}
          ></Column>

          <Column
            dataField={ServiceCategoryKey}
            caption={this.props.t("serviceCat")}
            alignment={columnAlign}
          ></Column>

          <Column
            dataField={ServiceSectionKey}
            caption={this.props.t("serviceSection")}
            alignment={columnAlign}
          ></Column>

          <Column
            cssClass="outlinedColumn"
            dataField="Department_Service_Version_Name"
            caption={this.props.t("currentVer")}
            alignment={"center"}
            width={"120px"}
          ></Column>

          <Column
            dataField="Active_Status_Id"
            caption={this.props.t("serviceStatus")}
            allowFiltering={false}
            cellRender={this.customizeCellCheckbox}
            alignment={"center"}
            width={"150px"}
          ></Column>

          <Column
            cssClass="outlinedColumn nowrapColumn"
            type="buttons"
            caption={this.props.t("details")}
            fixed={true}
            fixedPosition={this.props.i18n.language === "ar" ? "left" : "right"}
          >
            <Button
              render={(record) => {
                let serviceName =
                  this.props.i18n.language === "ar"
                    ? record.data.Service_Name_AR
                    : record.data.Service_Name_EN;
                return (
                  <ButtonSecondary
                    startIcon={<HistoryIcon />}
                    component={Link}
                    state={{
                      departmentServiceId: record.data.Department_Service_Id,
                    }}
                    to={`${encodeURIComponent(serviceName)}/versions`}
                  >
                    {this.props.t("versions")}
                  </ButtonSecondary>
                );
              }}
            />
          </Column>
        </DataGrid>
      </>
    );
  }
  onExporting(e) {
    let sheetTitle = this.props.t("municipalitiesServices");
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGridToExcel({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 2 },
      topRightCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        // excelCell.value = gridCell.value;
        excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        excelCell.alignment = { indent: 1, wrapText: true, vertical: "middle" };
        if (gridCell.rowType === "group") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          excelCell.alignment = { indent: 2 };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          excelCell.alignment = { vertical: "middle", horizontal: "center" };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${sheetTitle}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(DepartmentsServicesList));
