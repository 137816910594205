import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { DataGrid,
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    Selection
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import Checkbox from '@material-ui/core/Checkbox';

import { getDepartmentVersionUnselectedDetailList } from '../../../apis/services/departments/getDepartmentVersionUnselectedDetailList'
import { saveDepartmentVersionSelectedDetail } from '../../../apis/services/departments/saveDepartmentVersionSelectedDetail'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'
import { ButtonPrimary } from '../../UI/Buttons'


class ServiceVersionUnselectedDetail extends Component {

    constructor(props) {
        super(props);
 
        this.dataGridRef = React.createRef();
 
    }


    customizeCellCheckbox = (data) => {
        return (
            <Checkbox
                checked={data.row.isSelected}
                color="primary"
                inputProps={{ 'aria-label': 'select service checkbox' }}
                onChange={(e)=>{
                    let dataGridRef = this.dataGridRef.current.instance
                    
                    if ( e.target.checked ) {
                        dataGridRef.selectRows([data.key], true)
                    } else {
                        dataGridRef.deselectRows([data.key], true)
                    }

                }}
            />
        )
        
    }

    customizeCellHeaderCheckbox = (data) => {
        return (
            <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'select service checkbox' }}
                onChange={(e)=>{
                    let dataGridRef = this.dataGridRef.current.instance

                    if ( e.target.checked ) {
                        dataGridRef.selectAll()
                    } else {
                        dataGridRef.deselectAll()
                    }
                   
                }}
            />
        )
        
    }

    onSelectionChanged = (selection)=>{
        
        let dataGridRef = this.dataGridRef.current.instance,
        { currentSelectedRowKeys, currentDeselectedRowKeys } = selection,
        rowKey = currentSelectedRowKeys.length !== 0 ? currentSelectedRowKeys : currentDeselectedRowKeys
        
        dataGridRef.beginUpdate()
        let rowIndex=  rowKey.map((key)=>{
           return dataGridRef.getRowIndexByKey(key)
         })
        dataGridRef.endUpdate()

            
        dataGridRef.repaintRows(rowIndex)
    }


    handleSaveSelectedDetail = async () => {
        let dataGridRef = this.dataGridRef.current.instance,
        selectedDetail = dataGridRef.getSelectedRowKeys(),
        at = this.props.currentUser.Data.access_token

        if ( selectedDetail.length !== 0 ) {

            this.props.setLoading(true, 'dark')
    
            const response = await saveDepartmentVersionSelectedDetail(at, this.props.versionid, selectedDetail)
    
            this.props.triggerNotification(true, response.data)

            this.props.triggerDialogForm(false)
    
            this.props.setLoading(false)

        }
    }

    render() {
 
        let columnAlign = 'left',
        ServiceDetailTitleKey = 'Service_Detail_Title_EN',
        ServiceDetailTypeKey = 'Service_Detail_Type_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            ServiceDetailTitleKey = 'Service_Detail_Title_AR'
            ServiceDetailTypeKey = 'Service_Detail_Type_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'Service_Detail_Id',
            load: () => {
                return getDepartmentVersionUnselectedDetailList(at, this.props.versionid)
            }
        });
        
        return(
            <>

            <div className="container-fluid pt-5">
                
                <DataGrid
                    ref={this.dataGridRef}
                    id="dataGrid"
                    dataSource={jsonDataSource}
                    showBorders={true}
                    rowAlternationEnabled={true}
                    onSelectionChanged={this.onSelectionChanged}
                >
                    <SearchPanel visible={true}
                    width={240}
                    placeholder={this.props.t('search')}
                    />

                    <FilterRow visible={true} />

                    <HeaderFilter visible={true} />

                    <Selection
                        mode="multiple"
                        allowSelectAll={true}
                        showCheckBoxesMode="always"
                    />

                    <Column
                        type="selection"
                        cellRender={this.customizeCellCheckbox}
                        headerCellRender={this.customizeCellHeaderCheckbox}
                    />

                    <Column dataField="Service_Detail_No" caption={this.props.t('serviceNo')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={ServiceDetailTitleKey} caption={this.props.t('serviceName')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={ServiceDetailTypeKey} caption={this.props.t('serviceCat')} alignment={columnAlign}>
                    </Column>

                    <Column dataField="Department_Service_Detail_Required" caption={this.props.t('required')} allowFiltering={false}>
                    </Column>

                    
                </DataGrid>

                <div className="d-flex justify-content-start align-items-center mb-4">
                    <ButtonPrimary
                        size="large"
                        onClick={this.handleSaveSelectedDetail}
                    >
                        {this.props.t('save')}
                    </ButtonPrimary>
                </div>
            </div>
            </>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(ServiceVersionUnselectedDetail))