import apiConfig from '../../apiConfig';
export const saveDepartmentVersionSelectedDetail = async (at, versionId, selectedDetailList) => {

    const response = await apiConfig.post(
        '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SAVE_DEPARTMENT_VERSION_SELECTED_DETAILS',
        {
            Department_Service_Version_Id: versionId,
            Service_Detail_Id_List: selectedDetailList
        }, 
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response;


}
