import React from 'react'

import Notes from '../Notes'
import { completeStep } from '../../../../apis/requests/processTask'


const Complete = (props) => {
    
    return (
        <>

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={completeStep} />
        
        </>
    )
}


export default Complete