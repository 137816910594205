import apiConfig from '../apiConfig';

export const getTemplatesList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/GET_TEMPLATES_LIST', {
            PageCount: 1000,
            PageIndex: 1 
        }, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
               response.data.Data[i].Active_Status_Id = true
            } else {
                response.data.Data[i].Active_Status_Id = false
            }
          }
        return response.data.Data
    }

    return []
};
export const getTemplateInfo = async (at , templateId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/GET_TEMPLATE_INFO', {
            Template_id: templateId,
        }, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	)
    if (response.data.Status) {
            if (response.data.Data.Active_Status_Id === 1) {
               response.data.Data.Active_Status_Id = true
            } else {
                response.data.Data.Active_Status_Id = false
            }
        return response.data.Data
    }
    return []
};
export const addTemplate = async (at , data) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/ADD_TEMPLATE', data , 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
               response.data.Data[i].Active_Status_Id = true
            } else {
                response.data.Data[i].Active_Status_Id = false
            }
          }
        return response.data.Data
    }

    return []
};
export const delTemplate = async (at , tempId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/DEL_TEMPLATE', {
            Template_Id: tempId
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    if (response.data.Status) {
        return response.data
    }
};
export const getTemplateVariablesList = async (at, templateId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/Get_Templates_Variables_List',
		{
			template_id: templateId,
		},
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
	);
	
	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Active_Status_Id === 1) {
				response.data.Data[i].Active_Status_Id = true;
			} else {
				response.data.Data[i].Active_Status_Id = false;
			}
		}
		return response.data.Data;
	}

	return [];
};

export const delTemplateVar = async (...params) => {
    const response = await apiConfig.post(
        'API/ADMINISTRATION/TEMPLATES/DEL_TEMPLATE_VARIABLE',
        {
            Template_Variable_Id: params[1]
        },
        {
            headers: {
                Authorization: `Bearer ${params[0]}`
            }
        }
    )
    if (response.data.Status) {
        return response.data
    }

    return []
}
export const updTemplateStatus = async (at, templateId, status) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/UPD_TEMPLATE_STATUS',
		{
            Template_Id: templateId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	).catch((error) => {
        return error.response
    });
    return response.data;
}   
export const updTemplateVarStatus = async (at, templateId, status) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATES/UPD_TEMPLATE_VARIABLE_STATUS',
		{
            Template_Variable_Id: templateId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	).catch((error) => {
        return error.response
    });
    return response.data;
}   