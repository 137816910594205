import { SET_ACTIVE_PAGE } from '../actions/types'

const currentPageReducer = (route = '/', action) => {

    switch (action.type) {
        case SET_ACTIVE_PAGE:
            
            return action.payload
    
        default:
            return route
    }

}

export default currentPageReducer