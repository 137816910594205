import React, { useEffect, useState } from "react";
import style from "./UserDropdown.module.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import profileImg from "./../../../../../images/avatar.png";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { navigate } from "@reach/router";
import { userLogout, setCurrentProfile } from "../../../../../actions";
import { Link } from "@reach/router";
import apiConfig from "../../../../../apis/apiConfig";
import Done from "@material-ui/icons/Done";

const UserDropdown = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountProfiles, setAccountProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(
    props.currentUser.Data
  );
  useEffect(async () => {
    const response = await apiConfig.post(
      "API/SYSTEM/LOGIN/GET_USER_ACCOUNT_PROFILES",
      {},
      {
        headers: {
          Authorization: `Bearer ${props.currentUser.Data.access_token}`,
        },
      }
    );
    if (response.data.Status) {
      setAccountProfiles(response.data.Data);
    }
  }, []);
  useEffect(async () => {
    setSelectedProfile(props.currentUser.Data);
  }, [props.currentUser]);

  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation("common");
  const user = props.currentUser.Data;

  let tranStyle;
  if (i18n.language === "en") {
    tranStyle = {
      textAlign: "row",
    };
  } else {
    tranStyle = {
      flexDirection: "row-reverse",
    };
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    props.userLogout();
    navigate("/");
  };

  return (
    <div>
      <button
        className={style.dropdownBtn}
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <span
          className={style.userName}
          style={{ margin: i18n.language === "en" ? "0 9px 0 0" : "0 0 0 9px" }}
        >
          {i18n.language === "en"
            ? selectedProfile.User_Name_EN
            : selectedProfile.User_Name_AR}

          <span className={style.departmentName}>
            {i18n.language === "en"
              ? selectedProfile.Department_Job_Name_EN
              : selectedProfile.Department_Job_Name_AR}{" "}
            |{" "}
            {i18n.language === "en"
              ? selectedProfile.Department_Name_EN
              : selectedProfile.Department_Name_AR}
          </span>
        </span>
        <img
          src={
            selectedProfile.User_Image || selectedProfile.User_Image !== ""
              ? selectedProfile.User_Image
              : profileImg
          }
          className={style.profileImg}
        />
      </button>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        className={[style.dropdown, "mt-2"].join(" ")}
      >
        <MenuItem
          className={[style.menuItem, style.userDropdown].join(" ")}
          style={tranStyle}
        >
          <img
            src={
              selectedProfile.User_Image || selectedProfile.User_Image !== ""
                ? selectedProfile.User_Image
                : profileImg
            }
            className={style.profileImg}
          />
          <div
            className={[
              style.userInfo,
              i18n.language === "en" ? style.userInfoEN : style.userInfoAR,
            ].join(" ")}
          >
            <span className={style.dropdownUsername}>
              {i18n.language === "en"
                ? selectedProfile.User_Name_EN
                : selectedProfile.User_Name_AR}
            </span>
            <span className={style.dropdownDepartmentName}>
              {i18n.language === "en"
                ? selectedProfile.Department_Name_EN
                : selectedProfile.Department_Name_AR}
            </span>
          </div>
        </MenuItem>
        {/* <Link to="relative">Relative</Link> */}
        {/* {Department_Job_Name_AR,Department_Job_Name_EN,Department_Name_AR,Department_Name_EN}  */}
        <MenuItem
          className={[style.menuItem, style.subTitle]}
          style={tranStyle}
        >
          <h6>{t("myOtherProfiles")}</h6>
        </MenuItem>
        <div className="border-top border-bottom border-dark-gray">
          {accountProfiles?.map((item) => (
            <MenuItem
              className={`${style.dropdownItem} ${
                item.User_Id === selectedProfile.User_Id
                  ? style.selectedItem
                  : ""
              } ${style.AccountItem} border-bottom border-light-gray`}
              onClick={() => {
                props.setCurrentProfile(
                  item.User_Id,
                  props.currentUser.Data.access_token
                );
                handleClose();
              }}
              style={tranStyle}
            >
              <div
                className={`${
                  i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
                } d-flex align-items-center`}
              >
                <img
                  src={
                    item.Department_Logo_Url
                      ? item.Department_Logo_Url
                      : profileImg
                  }
                  className={`${style.profileImg} ${
                    i18n.language === "ar" ? "ml-3" : "mr-3"
                  }`}
                  width="30px"
                  alt=""
                />
                {item.User_Id === selectedProfile.User_Id && <Done />}
                <div
                  className={`${
                    i18n.language === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  <p className="mb-0">
                    {i18n.language === "ar"
                      ? `${
                          item.Department_Job_Name_AR !== null
                            ? item.Department_Job_Name_AR
                            : ""
                        } -
                            ${
                              item.Department_Name_AR !== null
                                ? item.Department_Name_AR
                                : ""
                            }`
                      : item.Department_Job_Name_EN +
                        " - " +
                        item.Department_Name_EN}
                  </p>
                  <span className={style.profileGroup}>
                    {i18n.language === "ar"
                      ? item.User_Group_Name_AR
                      : item.User_Group_Name_EN}
                  </span>
                </div>
              </div>
              {item.Main_Profile ? (
                <span
                  className={`${
                    i18n.language === "ar" ? "mr-3" : "ml-3"
                  } bg-danger text-white p-1 rounded text-small`}
                >
                  Main
                </span>
              ) : (
                false
              )}
            </MenuItem>
          ))}
        </div>
        <MenuItem
          className={[style.menuItem, style.subTitle]}
          style={tranStyle}
        >
          <h6>{t("manageAccount")}</h6>
        </MenuItem>
        <MenuItem
          className={style.menuItem}
          onClick={() => {
            handleClose();
          }}
          component={Link}
          to="/profile"
          style={tranStyle}
        >
          {t("myProfile")}
        </MenuItem>
        <MenuItem
          className={style.menuItem}
          onClick={handleSignOut}
          style={tranStyle}
        >
          {t("signOut")}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default withCookies(
  connect(mapStateToProps, { userLogout, setCurrentProfile })(UserDropdown)
);
