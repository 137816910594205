import apiConfig from '../../apiConfig';

export const downloadDepartmentTemplateAttachment = async ({ at, File_Code, Department_Service_Version_Id }) => {

    const response = await apiConfig.post(
        '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/DOWNLOAD_DEPARTMENT_SERVICE_TEMPLATE_FILE',
        {
            Department_Service_Version_Id,
            File_Code
        }, 
        {
            headers: {
                Authorization: `Bearer ${at}`
            },
            responseType: 'arraybuffer',
        }
    ).catch((error) => {
        return error.response
    })

    return response


}
