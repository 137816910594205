import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ButtonPrimary } from './../../UI/Buttons'
import { Form } from 'react-final-form'
import { Select, TextField, Checkboxes } from 'mui-rff'
// import { OnChange } from 'react-final-form-listeners'

import apiConfig from '../../../apis/apiConfig';
import { translationHook } from '../../translationHook'
import { triggerNotification, setLoading } from '../../../actions'
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	buttonRoot: {
		minWidth: "90px !important",
		padding: "0.5rem 1rem !important"
	}
})

class PaymentNewCodeForm extends Component {

    state = {
        actionsEvents: [],
        reactions: [],
        // serviceNotifications: [],
        // stepScreens: [],
        showForm: false
    }


    // getActionsEvents = async ()=> {
    //     let actionsArr = []
    //     let url = this.props.serviceStepId ? '/API/SYSTEM/DDL/GET_SERVICES_STEPS_ACTIONS_EVENTS' : '/API/SYSTEM/DDL/GET_SERVICES_ACTIONS_EVENTS'
    //     // let data = { Service_Id: this.props.serviceid }
    //     // if (this.props.serviceStepId) {
    //     //     data = {...data, Service_Step_Id: this.props.serviceStepId}
    //     // }
    //     const response = await apiConfig.post(url)

    //     if (response.data.Status) {

    //         response.data.Data.map((item)=>{
    //             let label = this.props.i18n.language === 'ar' ? item.RAction_Event_Name_AR : item.RAction_Event_Name_EN

    //             actionsArr.push({value: item.RAction_Event_Id, label})
    //         })

    //         this.setState({actionsEvents: actionsArr})
            
    //     }

    // }

    // getReactions = async (event_id)=> {
    //     let reactionsArr = []
    //     const response = await apiConfig.post(
    //         '/API/SYSTEM/DDL/GET_RACTIONS',
    //         { RAction_Event_Id: event_id }
    //     )

    //     if (response.data.Status) {

    //         response.data.Data.map((item)=>{
    //             let label = this.props.i18n.language === 'ar' ? item.RAction_Name_AR : item.RAction_Name_EN

    //             reactionsArr.push({value: item.RAction_Id, label})
    //         })

    //         this.setState({reactions: reactionsArr})
    //     }

    // }

    componentDidMount() {
        // this.getActionsEvents()
    }

    onActionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = 'API/ADMINISTRATION/PAYMENTS/INVOICETYPE/ADD_INVOICE_TYPE_CODE',
        bodyData = {
            Invoice_Type_Id: this.props.invoiceTypeId,
            FDepartment_Code: values.deptCode,
            FSector_Code: values.sectorCode,
            FSection_Code: values.sectionCode,
            FService_Code: values.itemCode,
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        ).catch((error) => {
            return error.response
        })

        this.props.setLoading(false)

        this.props.triggerNotification(true, response.data);

        if (response.data.Status) {
            // if (this.props.editMood) {
            //     this.props.triggerDialogForm(false)
            // }
            this.props.reloadDatagrid()
            form.restart()

            this.setState({showForm: !this.state.showForm})
        }
        
    }

    validate = values => {
        const errors = {}

        if (!values.deptCode) {
            errors.deptCode = `${this.props.t('error.field_required')}`
        }

        if (!values.sectionCode) {
            errors.sectionCode = `${this.props.t('error.field_required')}`
        }

        if (!values.sectorCode) {
            errors.sectorCode = `${this.props.t('error.field_required')}`
        }

        if (!values.itemCode) {
            errors.itemCode = `${this.props.t('error.field_required')}`
        }
        
        return errors;
    }

    render() {
        // let { initValues } = this.props

        return (
            <>
            {!this.props.editMood && !this.props.viewMood &&
            <div className="d-flex justify-content-end">
                <ButtonPrimary
                    variant={!this.state.showForm ? 'contained' : 'outlined'}
                    onClick={() => this.setState({showForm: !this.state.showForm}) }
                    hidden={this.state.showForm}
                >
                    {!this.state.showForm && this.props.t('paymentAddNewCode')}
                    {/* {this.state.showForm && this.props.t('cancel')} */}
                </ButtonPrimary>
            </div>
            }
            <Form
            onSubmit={this.onActionSubmit}
            initialValues={{
                deptCode: '',
                sectorCode: '',
                itemCode: '',
                sectionCode: '',

            }}
            validate={this.validate}
            render={({ handleSubmit, form, submitting, values }) => {
                
                // if (this.props.editMood && values.stepType && values.stepType !== '' && values.stepScreens !== '' && this.state.stepScreens.length === 0) {
                //     this.getStepScreens(values.stepType)
                // }

                // if ( (values.notification === 0 || values.notification === false) && values.serviceNotification && !this.props.editMood ) {
                //     delete values.serviceNotification
                // }

            return (
                <form
                id="serviceStepForm"
                className={`row align-items-center ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                // onSubmit={handleSubmit}
                noValidate>
                    <Col xs={12} md={8}>
                        <Row>
                            <Col xs={12} md={4}>
                                {/* <Select
                                    disabled={this.props.viewMood}
                                    label={this.props.t("paymentCollectionPoint")}
                                    className={`${
                                        this.props.viewMood ? "read-only" : ""
                                    }`}
                                    required={true}
                                    name="reactionEventId"
                                    data={[]}
                                    value={values.reactionEventId}
                                    // onChange={(e) => {
                                    //     form.change('reactionEventId', e.target.value)
                                    //     this.getReactions(e.target.value)
                                    //     }}
                                    showError={() => {
                                        let fieldStatus = form.getFieldState("reactionEventId");

                                        if (fieldStatus) {
                                            return fieldStatus.submitFailed && fieldStatus.invalid
                                        }
                                    }}
                                ></Select>
                            
                                <Checkboxes
                                    name="templatesFiles"
                                    data={{label: this.props.t('paymentRelatedToDestination')}}
                                    // disabled={this.state.activeStep.Can_Edit_Step == 0 ? true : false}
                                    // onChange={e => {
                                    //     this.handleCheckboxes(e.target.checked, values.chartsFiled)
                                    //     form.change("templatesFiles", e.target.checked);
                                    // }}
                                />
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("paymentDestinationName")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="InvoiceTypeNameAR"
                                    required={true}
                                    autoComplete="off"
                                    // showError={({ meta: { submitFailed, invalid } }) => {
                                    //     return submitFailed && invalid;
                                    // }}
                                    />
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("paymentDestinationEnName")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="InvoiceTypeNameAR"
                                    required={true}
                                    autoComplete="off"
                                    // showError={({ meta: { submitFailed, invalid } }) => {
                                    //     return submitFailed && invalid;
                                    // }}
                                    /> */}
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("paymentDestinationCode")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="deptCode"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid;
                                    }}
                                    />
                                    
                            </Col>
                            <Col xs={12} md={4}>
                                {/* <Select
                                    disabled={this.props.viewMood}
                                    label={this.props.t("department")}
                                    className={`${
                                        this.props.viewMood ? "read-only" : ""
                                    }`}
                                    required={true}
                                    name="reactionId"
                                    value={values.reactionId}
                                    data={this.state.reactions}
                                    onChange={(e) => {
                                        form.change('reactionId', e.target.value)
                                        this.setState({ selectedReaction: e.target.value })
                                    }}
                                    showError={() => {
                                        let fieldStatus = form.getFieldState("reactionId");

                                        if (fieldStatus) {
                                            return fieldStatus.submitFailed && fieldStatus.invalid
                                        }
                                    }}
                                ></Select>
                                <Checkboxes
                                    name="templatesFiles"
                                    data={{label: this.props.t('paymentRelatedToDepartment')}}
                                    // disabled={this.state.activeStep.Can_Edit_Step == 0 ? true : false}
                                    // onChange={e => {
                                    //     this.handleCheckboxes(e.target.checked, values.chartsFiled)
                                    //     form.change("templatesFiles", e.target.checked);
                                    // }}
                                />
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("depName")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="InvoiceTypeNameAR"
                                    required={true}
                                    autoComplete="off"
                                    // showError={({ meta: { submitFailed, invalid } }) => {
                                    //     return submitFailed && invalid;
                                    // }}
                                    />
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("depNameEN")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="InvoiceTypeNameAR"
                                    required={true}
                                    autoComplete="off"
                                    // showError={({ meta: { submitFailed, invalid } }) => {
                                    //     return submitFailed && invalid;
                                    // }}
                                    /> */}
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("departmentCode")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="sectorCode"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid;
                                    }}
                                    />
                                    
                            </Col>
                            <Col xs={12} md={4}>
                                {/* <Select
                                    disabled={this.props.viewMood}
                                    label={this.props.t("section")}
                                    className={`${
                                        this.props.viewMood ? "read-only" : ""
                                    }`}
                                    required={true}
                                    name="reactionId"
                                    value={values.reactionId}
                                    data={[]}
                                    // onChange={(e) => {
                                    //     form.change('reactionId', e.target.value)
                                    //     this.setState({ selectedReaction: e.target.value })
                                    // }}
                                    showError={() => {
                                        let fieldStatus = form.getFieldState("reactionId");

                                        if (fieldStatus) {
                                            return fieldStatus.submitFailed && fieldStatus.invalid
                                        }
                                    }}
                                ></Select>
                                <Checkboxes
                                    name="templatesFiles"
                                    data={{label: this.props.t('paymentRelatedToSection')}}
                                    // disabled={this.state.activeStep.Can_Edit_Step == 0 ? true : false}
                                    // onChange={e => {
                                    //     this.handleCheckboxes(e.target.checked, values.chartsFiled)
                                    //     form.change("templatesFiles", e.target.checked);
                                    // }}
                                />
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("sectionName")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="InvoiceTypeNameAR"
                                    required={true}
                                    autoComplete="off"
                                    // showError={({ meta: { submitFailed, invalid } }) => {
                                    //     return submitFailed && invalid;
                                    // }}
                                    />
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("sectionNameEN")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="InvoiceTypeNameAR"
                                    required={true}
                                    autoComplete="off"
                                    // showError={({ meta: { submitFailed, invalid } }) => {
                                    //     return submitFailed && invalid;
                                    // }}
                                    /> */}
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("sectionCode")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="sectionCode"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid;
                                    }}
                                    />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4} className='align-self-end'>
                        <Row className="d-flex align-items-center">
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t("paymentItemCode")}
                                    className={`${this.props.viewMood ? "read-only" : ""}`}
                                    name="itemCode"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid;
                                    }}
                                    />
                            </Col>
                            <Col xs={12} md={6}>
                            <Row>{!this.props.viewMood && (
                                <ButtonPrimary
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    classes={{
                                        root: this.props.classes.buttonRoot
                                    }}
                                    disabled={
                                        submitting ||
                                        this.props.viewMood
                                    }
                                    onClick={handleSubmit}
                                >
                                    {this.props.t("save")}
                                </ButtonPrimary>
                            )}
                                <ButtonPrimary
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    classes={{
                                        root: this.props.classes.buttonRoot
                                    }}
                                    onClick={() => this.setState({showForm: !this.state.showForm}) }
                                >
                                    {this.props.t("cancel")}
                                </ButtonPrimary>
                            </Row>
                            </Col>
                        </Row>
                    </Col>
                    
                </form>
                )
            }
        }
        />
            </>
        ) 
    }
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading })(translationHook(withStyles(styles)(PaymentNewCodeForm)))