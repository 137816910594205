import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
    Pager
} from 'devextreme-react/data-grid'
import { ExcelJS, Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter'
import CustomStore from 'devextreme/data/custom_store'
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
    triggerDialog
} from '../../../actions'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import ColorBox from 'devextreme-react/color-box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TypesOfStampsForm from './TypesOfStampsForm'
import {
	ButtonDanger,
	ButtonInfo,
	ButtonWarning
} from '../../UI/Buttons'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import { deleteStamp } from './../../../apis/services/deleteStamp'

class TypesOfStamps extends Component {

    constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

    onSubmit = () => {}

    renderStampsForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
			editMood = params[2] === 'edit' ? true : false

        return <TypesOfStampsForm stampTypeId={params[0]} initValues={params[1]} editMood={editMood} viewMood={viewMood} />
    }

    getStampsTypes = async() => {
        const response = await apiConfig.post(
			'/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/STAMPS/Get_Documents_Stamps_Types_List',
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		)
        return response.data.Data
    }

    handleDocStampColor = ({ data }) => {
        return (
            <ColorBox
                defaultValue={data.Document_Stamp_Color}
                applyValueMode='instantly'
                onValueChanged={async({ value }) => {
                    this.props.setLoading(true, 'dark');

                    const response = await apiConfig.post(
                        '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/STAMPS/Upd_Document_Stamp_Type',
                        {
                            Document_Stamp_Type_Id: data.Document_Stamp_Type_Id,
                            Document_Stamp_Color: value,
                            Document_Stamp_Type_Name_AR: data.Document_Stamp_Type_Name_AR,
                            Document_Stamp_Type_Name_EN: data.Document_Stamp_Type_Name_EN,
                            Document_Stamp_Key_Word: data.Document_Stamp_Key_Word
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
                            },
                        }
                    );

                    this.props.setLoading(false);
                }}
            />
        )
    }

    handleDocStampState = ({ data }) => {
        let label =
			this.props.i18n.language === 'ar'
				? data.Active_Status_Name_AR
				: data.Active_Status_Name_EN;
        return (
			<FormControlLabel
				control={
					<Switch
						checked={data.Active_Status_Id == 0 ? false : true}
						name={`stamp_${data.Active_Status_Id}`}
						onChange={async () => {
							this.props.setLoading(true, 'dark')

                            const response = await apiConfig.post(
                                '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/STAMPS/Set_Document_Stamp_Type_Status',
                                {
                                    Document_Stamp_Type_Id: data.Document_Stamp_Type_Id,
                                    Active_Status_Id: data.Active_Status_Id == 0 ? 1 : 0
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
                                    },
                                }
                            );
							this.props.setLoading(false);
                            this.dataGrid?.instance?.refresh()
						}}
					/>
				}
				label={label}
			/>
		);
    }

    handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
            stampTypeId = data.Document_Stamp_Type_Id,
			stampName =
				this.props.i18n.language === 'ar'
					? data.Document_Stamp_Type_Name_AR
					: data.Document_Stamp_Type_Name_EN,
			dialogTitle = `${this.props.t('confirmDeleteTitle')} ${stampName}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteStamp,
				params: [
					at,
					stampTypeId
				],
				text: this.props.t('deleteStamp'),
			},
		});
	}

    handleEdit = (data, viewMood) => {
        let initValues = {
			Document_Stamp_Type_Name_AR: data.Document_Stamp_Type_Name_AR,
            Document_Stamp_Type_Name_EN: data.Document_Stamp_Type_Name_EN,
            Document_Stamp_Key_Word: data.Document_Stamp_Key_Word,
            Document_Stamp_Color: data.Document_Stamp_Color,
            Active_Status_Id: data.Active_Status_Id,
            Document_Stamp_Notes: data.Document_Stamp_Notes,

        },
        stampTypeId = data.Document_Stamp_Type_Id,
        stampName = this.props.i18n.language === 'ar' ? data.Document_Type_Name_AR : data.Document_Type_Name_EN,
        dialogTitle = `${viewMood === 'view' ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${stampName}`

		
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderStampsForm,
            params: [
                stampTypeId,
                initValues,
				viewMood
            ]
        })

    }

    render() {

        let stampStatus = "Active_Status_Name_AR",
        columnAlign = "center"

        if (this.props.i18n.language === "en") {
            stampStatus = "Active_Status_Name_EN"
        }

        const jsonDataSource = new CustomStore({
			key: 'Document_Stamp_Type_Id',
			load: () => {
                return this.getStampsTypes()
            },
		});

        return (
            <>
                <TypesOfStampsForm />

                <DataGrid
                    className={[
                        this.props.i18n.language === "ar"
                            ? "dx-grid-ar"
                            : "dx-grid-en",
                        "globalBox",
                    ].join(" ")}
                    id="dataGrid"
                    width={"100%"}
                    dataSource={jsonDataSource}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    allowColumnReordering={true}
                    rowAlternationEnabled={false}
                    focusedRowEnabled={true}
                    onExporting={this.onExporting}
                    columnWidth={"auto"}
                    wordWrapEnabled={true}
                    rtlEnabled={
                        this.props.i18n.language === "ar" ? true : false
                    }
                    ref={(ref) => (this.dataGrid = ref)}
                >
                    <Pager
                        visible='true'
                        allowedPageSizes={[5, 10, 20, 50, 100]}
                        displayMode='full'
                        showPageSizeSelector='true'
                        showInfo='true'
                        infoText={`${this.props.t("page")} {0} ${this.props.t(
                            "of"
                        )} {1} ({2} ${this.props.t("item")})`}
                        showNavigationButtons='true'
                    />

                    <ColumnChooser
                        enabled={true}
                        allowSearch={true}
                        search={"fff"}
                        title={this.props.t("dxColumnChooserTitle")}
                        emptyPanelText={this.props.t(
                            "dxColumnChooserPanelText"
                        )}
                    />

                    <SearchPanel
                        visible={true}
                        width={240}
                        placeholder={this.props.t("search")}
                    />

                    <FilterRow visible={true} />

                    <HeaderFilter visible={true} />

                    <Export enabled={true} allowExportSelectedData={true} />

                    <Grouping contextMenuEnabled={true} expandMode="rowClick" />
                    <GroupPanel
                        visible={true}
                        emptyPanelText={this.props.t("dxGroupPanelText")}
                    />

                    <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"always"}
                    />

                    <Column
                        dataField='Document_Stamp_Type_Name_AR'
                        caption={this.props.t("stampNameAR")}
                        alignment={columnAlign}
                        width={150}
                    ></Column>

                    <Column
                        dataField='Document_Stamp_Type_Name_EN'
                        caption={this.props.t("stampNameEN")}
                        alignment={columnAlign}
                        width={150}
                    ></Column>
                    
                    <Column
                        dataField='Document_Stamp_Key_Word'
                        caption={this.props.t("docStamp")}
                        alignment={columnAlign}
                        width={150}
                    ></Column>
                    
                    <Column
                        dataField="Document_Stamp_Color"
                        caption={this.props.t("color")}
                        alignment={columnAlign}
                        cellRender={this.handleDocStampColor}
                        width={200}
                    ></Column>

                    <Column
                        dataField={stampStatus}
                        caption={this.props.t("stampStatus")}
                        alignment={columnAlign}
                        cellRender={this.handleDocStampState}
                        width={100}
                    ></Column>

                    <Column
                        cssClass="outlinedColumn nowrapColumn"
                        type="buttons"
                        caption={this.props.t("details")}
                        fixed={true}
                        fixedPosition={
                            this.props.i18n.language === "ar"
                                ? "left"
                                : "right"
                        }
                    >    
                        <Button
							render={(record) => {
								return (
									<ButtonWarning
									variant='circleIcon'
									startIcon={<EditIcon />}
									onClick={() => {
                                        this.handleEdit(record.data, 'edit')
                                    }}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
									variant='circleIcon'
									startIcon={<VisibilityIcon />}
									onClick={() => {
                                        this.handleEdit(record.data, 'view')
                                    }}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant='circleIcon'
										startIcon={<DeleteIcon />}
										onClick={() => this.handleDelete(record.data)}>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
                    </Column>   
                </DataGrid>
            </>
        )
    }

    onExporting(e) {
        let sheetTitle = this.props.t("typesOfStamps");
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            topLeftCell: { row: 2, column: 2 },
            topRightCell: { row: 2, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                // excelCell.value = gridCell.value;
                excelCell.font = {
                    family: 2,
                    name: "Cairo",
                    size: 10,
                    color: { argb: "151713" },
                };
                excelCell.alignment = {
                    indent: 1,
                    wrapText: true,
                    vertical: "middle",
                };
                if (gridCell.rowType === "group") {
                    excelCell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "d0b166" },
                    };
                    excelCell.alignment = { indent: 2 };
                    excelCell.font = {
                        family: 2,
                        name: "Cairo",
                        size: 10,
                        color: { argb: "ffffff" },
                        bold: true,
                    };
                }
                if (gridCell.rowType === "header") {
                    excelCell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "58743a" },
                    };
                    excelCell.alignment = {
                        vertical: "middle",
                        horizontal: "center",
                    };
                    excelCell.font = {
                        family: 2,
                        name: "Cairo",
                        size: 12,
                        color: { argb: "ffffff" },
                        bold: true,
                    };
                }
            },
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `${sheetTitle}.xlsx`
                );
            });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(TypesOfStamps)); 