import React, { Component } from 'react'
import { translationHook } from '../translationHook'
import { triggerDialog, triggerDialogForm, setLoading, triggerNotification } from '../../actions'
import { connect } from 'react-redux'
import { getProfilesList } from './../../apis/system/getUsersList'
import { deleteProfile } from './../../apis/system/deleteUser'
import CustomStore from 'devextreme/data/custom_store'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { setProfileStatus, setProfileMain } from './../../apis/system/setUserStatus';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';
import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import { 
    ButtonSecondary, 
    ButtonDanger, 
    ButtonInfo,
    ButtonWarning 
} from './../UI/Buttons'
import AccountProfileForm from './AccountProfileForm'

class AccountProfilesList extends Component {
    
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

    handleDelete = data => {
        let at = this.props.currentUser.Data.access_token,
        userId = data.User_Id,
        profile = `${this.props.i18n.language === 'ar' ? 
        data.Department_Job_Name_AR + " " + data.Department_Job_Type_Name_AR 
        : data.Department_Job_Name_EN + ' ' + data.Department_Job_Type_Name_EN}`,
        dialogTitle = `${this.props.t('confirmDeleteTitle')} ${profile}`,
        dialogMsg = this.props.t('confirmDeleteMsg')

        this.props.triggerDialog(true, {
            title: dialogTitle,
            message: dialogMsg,
            action: {
                action: deleteProfile,
                params: [
                    at,
                    userId
                ],
                text: this.props.t('delete')
            }
        })
    }

    handleEdit = (data) => {
        console.log(data)
        let initValues = {
            group: data.User_Group_Id,
            lang: data.Lang_Id,
            department: data.Department_Id,
            job: data.Department_Job_Id,
            userStatus: data.Active_Status_Id,
            userAccountId: data.User_Account_Id,
            jobSignature: data.User_Signature_Text
        },
        userId = data.User_Id,
        profile = `${this.props.i18n.language === 'ar' ? 
        data.Department_Job_Name_AR + " " + data.Department_Job_Type_Name_AR 
        : data.Department_Job_Name_EN + ' ' + data.Department_Job_Type_Name_EN}`,
        dialogTitle = `${this.props.viewMood ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${profile}`
    
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                userId,
                initValues,
                data.Main_Profile
            ],
            action: this.props.triggerView(false, false),
        })
    }

    renderEditForm = (...params) => {
        return <AccountProfileForm userId={params[0]} initValues={params[1]} mainProfile={params[2]}  />
    }

    customizeCellCheckbox = ({data}) => {
        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={data.Main_Profile}
                        name='userStatus'
                        onChange={async () => {
                            let at = this.props.currentUser.Data.access_token,	
                            userId = data.User_Id

                            this.props.setLoading(true, 'dark')

                            const response = await setProfileMain(at, userId)

                            this.props.triggerNotification(true, response)

                            this.props.setLoading(false)
                        }}
                    />
                }
                // label={label}
            />
        )
    }

    customizeStatus = ({data}) => {
        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={data.Active_Status_Id}
                        name='userStatus'
                        onChange={async () => {
                            let at = this.props.currentUser.Data.access_token,
                            resp = {
                                User_Id: data.User_Id,
                                Active_Status_Id: data.Active_Status_Id ? 0 : 1,
                            }

                            this.props.setLoading(true, 'dark')

                            const response = await setProfileStatus(at, resp)

                            this.props.triggerNotification(true, response)

                            this.props.setLoading(false)
                        }
                    }
                    />
                }
                // label={label}
            />
        )
    }

    jobCol = ({data}) => {
        return (
            <div>
                {data.Main_Profile === 1 && (
                <span className="bg-danger text-white p-1 rounded mx-2">main</span>
                )}
                {this.props.i18n.language === 'ar' ? data.Department_Job_Name_AR : data.Department_Job_Name_EN}
            </div>
        )
    }

    render() {
        let columnAlign = 'left',
        UserGroupNameKey = 'User_Group_Name_EN',
        DepartmentNameKey = 'Department_Name_EN',
        DepartmentJobKey = 'Department_Job_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            UserGroupNameKey = 'User_Group_Name_AR'
            DepartmentNameKey = 'Department_Name_AR'
            DepartmentJobKey = 'Department_Job_Name_AR'
        }

        const jsonDataSource = new CustomStore({
            key: 'User_Id',
            load: () => {
                return getProfilesList(this.props.userAccId, at)
            }
        })

        return (
            <>
                <DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

                    <Column cssClass="outlinedColumn"
                        dataField={UserGroupNameKey} 
                        caption={this.props.t('userGroupName')}
                        alignment={'center'}
                    >
                    </Column>

                    <Column 
                        dataField={DepartmentNameKey} 
                        caption={this.props.t('departmentName')}
                        alignment={columnAlign}
                    >
                    </Column>
                    <Column 
                        dataField={DepartmentJobKey} 
                        caption={this.props.t('departmentJob')}
                        alignment={'center'}
                        cellRender={this.jobCol}
                    >
                    </Column>
                    <Column 
                        dataField={'Main_Profile'}
                        caption={this.props.t('main')}
                        alignment={'center'}
                        cellRender={this.customizeCellCheckbox}
                    >
                    </Column>
                    <Column 
                        dataField={'Active_Status_Id'}
                        caption={this.props.t('serviceStatus')}
                        alignment={'center'}
                        cellRender={this.customizeStatus}
                    >
                    </Column>


                    <Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>

                        <Button
                            render={(record) => {
                                return (
                                    <ButtonWarning
                                        variant="circleIcon"
                                        startIcon={<EditIcon />}
                                        onClick={() => {
                                            // this.props.triggerView(false, true)
                                            this.handleEdit(record.data)
                                        }}
                                    >
                                        {/* {this.props.t('edit')} */}
                                    </ButtonWarning>
                                )
                            }}
                        />
                            
                        <Button
                            render={(record) => {
                                return (
                                    <ButtonDanger
                                        variant="circleIcon"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(record.data)}
                                    >
                                    </ButtonDanger>
                                )
                            }}
                        />

                    </Column>

                </DataGrid>
            </>
        )
    }
    
	onExporting(e) {
		let sheetTitle = this.props.t('manageUsers')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload,
    }
}

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, setLoading, triggerNotification })(
	translationHook(AccountProfilesList)
)
