import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

import { getDepartmentsJobsList } from "../../../apis/departments/getDepartmentsJobsList";
import { getDepartmentJobInfo } from "../../../apis/departments/getDepartmentJobInfo";
import { setDepartmentJobStatus } from "../../../apis/departments/setDepartmentJobStatus";
import { deleteDepartmentJob } from "../../../apis/departments/deleteDepartmentJob";
import {
	triggerDialog,
	setLoading,
	triggerNotification,
	triggerDialogForm,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "../../UI/Buttons";

import DepartmentJobFrom from "./DepartmentJobFrom";

class DepartmentsJobsList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		girdPaused: false,
	};

	pauseGrid = (state) => {
		this.setState({ girdPaused: state });
	};

	customizeCellCheckbox = ({ data }) => {
		return (
			<Switch
				checked={data.Active_Status_Id}
				name={`jobStatus_${data.Department_Job_Id}`}
				onChange={async () => {
					let at = this.props.currentUser.Data.access_token,
						departmentJobId = data.Department_Job_Id,
						status = data.Active_Status_Id ? 0 : 1;

					this.props.setLoading(true, "dark");

					const response = await setDepartmentJobStatus(
						at,
						departmentJobId,
						status
					);

					this.props.triggerNotification(true, response);

					this.props.setLoading(false);
				}}
			/>
		);
	};

	renderJobForm = (...params) => {
		let viewMood = params[2] === "view" ? true : false,
			editMood = params[2] === "edit" ? true : false;

		return (
			<DepartmentJobFrom
				departmentJobId={params[0]}
				initValues={params[1]}
				editMood={editMood}
				viewMood={viewMood}
			/>
		);
	};

	handleEdit = async (data, viewMood) => {
		this.props.setLoading(true, "dark");

		let initValues = {},
			departmentJobId = data.Department_Job_Id,
			departmentJobName =
				this.props.i18n.language === "ar"
					? data.Department_Job_Name_AR
					: data.Department_Job_Name_EN,
			dialogTitle = `${
				viewMood === "view"
					? this.props.t("viewServiceDetailTitle")
					: this.props.t("editServiceDetailTitle")
			} ${departmentJobName}`;

		await getDepartmentJobInfo(
			this.props.currentUser.Data.access_token,
			data.Department_Job_Id
		)
			.then((res) => {
				if (res.data.Status) {
					initValues["jobStatus"] = res.data.Data.Active_Status_Id;
					initValues["jobNameAR"] =
						res.data.Data.Department_Job_Name_AR;
					initValues["jobNameEN"] =
						res.data.Data.Department_Job_Name_EN;
					initValues["jobType"] =
						res.data.Data.Department_Job_Type_Id;
					initValues["jobNo"] = res.data.Data.Department_Job_No;
					initValues["jobSignature"] = res.data.Data.Department_Job_Signature_Text;
				}
			})
			.catch((error) => {
				this.props.triggerNotification(true, error.response);
			});

		this.props.setLoading(false);

		if (viewMood === "edit") {
			// pause Jobs grid to prevent from re-rendring the datagrid when submitting edits in popup & pass it as a cancel callback when closing the popuo to get the updated data in jobs list
			this.pauseGrid(true);
		}

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderJobForm,
			params: [departmentJobId, initValues, viewMood],
			dismissAction:
				viewMood === "edit"
					? {
							action: this.pauseGrid,
							params: [false],
					  }
					: false,
		});
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			departmentJobId = data.Department_Job_Id,
			departmentJobName =
				this.props.i18n.language === "ar"
					? data.Department_Job_Name_AR
					: data.Department_Job_Name_EN,
			dialogTitle = `${this.props.t(
				"confirmDeleteTitle"
			)} ${departmentJobName}`,
			dialogMsg = this.props.t("confirmDeleteMsg");

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteDepartmentJob,
				params: [at, departmentJobId],
				text: this.props.t("deleteJob"),
			},
		});
	};

	render() {
		let columnAlign = "left",
			JobNameKey = "Department_Job_Name_EN",
			DepJobTypeKey = "Department_Job_Type_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			JobNameKey = "Department_Job_Name_AR";
			DepJobTypeKey = "Department_Job_Type_Name_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Department_Job_Id",
			load: () => {
				if (!this.state.girdPaused) {
					return getDepartmentsJobsList(
						at,
						this.props.currentUser.Data.Department_Id
					);
				}

				return [];
			},
		});

		return (
			<>
				<DepartmentJobFrom />

				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField="Department_Job_No"
						caption={this.props.t("jobNo")}
						alignment={"center"}
					></Column>

					<Column
						dataField={JobNameKey}
						caption={this.props.t("jobName")}
						alignment={columnAlign}
					></Column>

					<Column
						cssClass="outlinedColumn"
						dataField={DepJobTypeKey}
						caption={this.props.t("jobType")}
						alignment={"center"}
					></Column>

					<Column
						dataField="Selected"
						caption={this.props.t("jobStatus")}
						allowFiltering={false}
						cellRender={this.customizeCellCheckbox}
						alignment={"center"}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
										variant="circleIcon"
										startIcon={<EditIcon />}
										onClick={() =>
											this.handleEdit(record.data, "edit")
										}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
										variant="circleIcon"
										startIcon={<VisibilityIcon />}
										onClick={() =>
											this.handleEdit(record.data, "view")
										}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant="circleIcon"
										startIcon={<DeleteIcon />}
										onClick={() =>
											this.handleDelete(record.data)
										}
									>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("departmentJobs");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(DepartmentsJobsList));
