import React, { Component } from "react";
import { connect } from "react-redux";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
} from "devextreme-react/data-grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";

import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";

import {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";

class InputChangesList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	customizeUploadCell = ({ data }) => {
		if (data.Files_Changes && data.Files_Changes.length > 0) {
			return (
				<List className="grid-list-item">
					{data.Files_Changes.map((file) => {
						return (
							<ListItem key={file.File_Code}>
								<div className="d-flex w-100 align-items-center">
									<ListItemText
										className="text-center"
										primary={file.File_Name}
										secondary={
											this.props.i18n.language === "ar"
												? file.File_Status_Name_AR
												: file.File_Status_Name_EN
										}
									/>

									<ListItemSecondaryAction
										className="d-flex"
										style={{
											position: "unset",
											top: "auto",
											transform: "none",
										}}
									>
										<IconButton
											edge="end"
											aria-label="download"
											onClick={async () => {
												let at =
													this.props.currentUser.Data
														.access_token;

												let downloadResult =
													await downloadRequestAttachment(
														{
															at,
															File_Code:
																file.File_Code,
														}
													);

												if (
													downloadResult.status ===
													200
												) {
													let contentType =
															downloadResult
																.headers[
																"content-type"
															],
														typesArray = [
															"application/pdf",
															"image/bmp",
															"image/gif",
															"image/vnd.microsoft.icon",
															"image/jpeg",
															"image/png",
															"image/svg+xml",
															"image/tiff",
															"image/webp",
														],
														url =
															window.URL.createObjectURL(
																new Blob(
																	[
																		downloadResult.data,
																	],
																	{
																		type: contentType,
																	}
																)
															),
														link =
															document.createElement(
																"a"
															);

													if (
														downloadResult?.data
															?.Data?.File_Bytes
													) {
														url =
															window.URL.createObjectURL(
																new Blob(
																	[
																		downloadResult
																			.data
																			.Data
																			.File_Bytes,
																	],
																	{
																		type: contentType,
																	}
																)
															);

														data.File_Name =
															downloadResult.data.Data.File_Name;
													}

													link.href = url;

													typesArray.includes(
														contentType
													)
														? link.setAttribute(
																"target",
																"_blank"
														  )
														: link.setAttribute(
																"download",
																data.File_Name
														  );

													document.body.appendChild(
														link
													);
													link.click();
												}
											}}
										>
											<GetAppIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</div>
							</ListItem>
						);
					})}
				</List>
			);
		}
	};

	render() {
		let columnAlign = "left";

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		const jsonDataSource = new CustomStore({
			key: "Change_No",
			load: () => {
				return this.props.changesList;
			},
		});

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={false}
					onExporting={this.onExporting}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Column
						dataField={"Change_No"}
						caption={this.props.t("changeNo")}
						alignment={columnAlign}
					></Column>

					{!this.props.isInputAttachment && (
						<Column
							dataField={
								this.props.i18n.language === "en"
									? "Input_Value_EN"
									: "Input_Value_AR"
							}
							caption={this.props.t("changeValue")}
							alignment={columnAlign}
						></Column>
					)}

					<Column
						dataField={"Change_Time"}
						caption={this.props.t("updateTime")}
						alignment={columnAlign}
						cssClass="dir-ltr"
						calculateCellValue={(data) => {
							return moment(data.Change_Time).format(
								"YYYY-MM-DD, hh:mm A "
							);
						}}
					></Column>

					{this.props.isInputAttachment && (
						<Column
							caption={this.props.t("attachments")}
							cellRender={this.customizeUploadCell}
							width="500"
						></Column>
					)}
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("manageServices");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(InputChangesList));
