import React from 'react'

import Notes from '../Notes'
import { saveRequestInsuranceRefund } from '../../../../apis/requests/processTask'

import RefundInvoices from './RefundInvoices'

export const InsuranceRefund = (props) => {
    
    return (
        <>
        <RefundInvoices requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestInsuranceRefund} />
        
        </>
    )
}
