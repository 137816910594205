import React, { Component } from 'react';
import { connect } from 'react-redux';
import apiConfig from '../../../apis/apiConfig';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';
import { Form } from 'react-final-form'

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import { Select } from 'mui-rff'

import CustomStore from 'devextreme/data/custom_store';
import Switch from '@material-ui/core/Switch';

import { getServicesList , getAllServicesList, addProfileToService, removeProfileService } from '../../../apis/system/customerServices';
import { triggerDialog, setLoading, triggerNotification, triggerDialogForm } from '../../../actions';
import { translationHook } from '../../translationHook';


class CustomerServicesList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		girdPaused: false,
		userTypes: [],
		profileId: ''
	}
	componentDidMount() {
		this.getUserTypes();
	}
	


	pauseGrid = (state) => {
		this.setState({ girdPaused: state })
	}

	customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Checked}
			name={`jobStatus_${data.Service_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					serviceId = data.Service_Id;

					this.props.setLoading(true, 'dark')

					let response;

					if(!data.Checked) {
						response = await addProfileToService(at, {
							Service_Id: serviceId,
							Profile_Active_Status_Id: this.state.profileId
						})
					} else {
						response = await removeProfileService(at, {
							Clients_Profiles_Active_Status_Service_Id: data.Clients_Profiles_Active_Status_Service_Id
						})
					}


					this.props.triggerNotification(true, response)

					this.props.setLoading(false)
			}}
			/>
        )
        
    }


	getUserTypes = async ()=> {
        let userTypes = []
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_PROFILES_ACTIVE_STATUS'
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN

                userTypes.push({value: item.Record_Id, label})
            })

            this.setState({ userTypes })
            
        }
    }

	handleChange = (id) => {
		this.setState({
			profileId: id
		})
    }

	onSectionSubmit = () => {
		
    }

	render() {
		let columnAlign = 'left',
			serviceNameKey = 'Service_Name_EN',
			serviceCatNameKey = 'Service_Category_Name_EN',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right';
			serviceNameKey = 'Service_Name_AR';
			serviceCatNameKey = 'Service_Category_Name_AR';
		}

		const jsonDataSource = new CustomStore({
			key: 'Service_Id',
			load: () => {

				if(String(this.state.profileId) != '') {
					return getServicesList(at , this.state.profileId)
				} else {
					if(this.state.userTypes.length > 0) {
						return getAllServicesList(at)
					}
				}

			},
		});

		return (
			<>
			 	<div className="d-flex justify-content-between align-items-center">
					<h3>{this.props.t('CustomerServicesSettings')}</h3>
				</div>
			 	<div className="d-flex justify-content-between align-items-center">
					<Form
						onSubmit={this.onSectionSubmit}
						initialValues={{userType: ''}}
						render={({ handleSubmit, form, submitting, pristine, values }) => (
							<form 
							className="row col-3 align-items-center"
							onSubmit={handleSubmit}
							noValidate
							>
								<div className="w-100">
									<Select
										label={this.props.t('userTypes')}
										className='mb-2'
										name="userType"
										value={this.state.profileId}
										onChange={(e) => this.handleChange(e.target.value)}
										data={this.state.userTypes}
										>
									</Select>
								</div>
							</form>
							)}
							/>
				</div>

				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
					<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
						dataField="Checked"
						caption={this.props.t('active')}
						cellRender={this.customizeCellCheckbox}
						alignment={'center'}></Column>

					<Column
						dataField="Service_Code"
						caption={this.props.t('serviceCode')}
						alignment={'center'}></Column>
						
					<Column
						dataField={serviceNameKey}
						caption={this.props.t('serviceName')}
						alignment={'center'}></Column>
					<Column
						dataField={serviceCatNameKey}
						caption={this.props.t('serviceCatName')}
						alignment={'center'}></Column>

					{/* <Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
									variant='circleIcon'
									startIcon={<EditIcon />}
									onClick={() => this.handleEdit(record.data, 'edit') }
									>
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
									variant='circleIcon'
									startIcon={<VisibilityIcon />}
									onClick={() => this.handleEdit(record.data, 'view') }
									>
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant='circleIcon'
										startIcon={<DeleteIcon />}
										onClick={() => this.handleDelete(record.data)}>
									</ButtonDanger>
								);
							}}
						/>
					</Column> */}
				</DataGrid>
			</>
		);
	}
	
	onExporting(e) {
		let sheetTitle = this.props.t('usersGroups')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog, triggerNotification, setLoading, triggerDialogForm })(
	translationHook(CustomerServicesList)
);
