import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';

import { getDepartmentsSectorsList } from '../../../apis/departments/getDepartmentsSectorsList';
import { setDepartmentSectorStatus } from '../../../apis/departments/setDepartmentSectorStatus'
import { deleteDepartmentSector } from '../../../apis/departments/deleteDepartmentSector'

import { triggerDialog, triggerDialogForm, setLoading, triggerNotification  } from '../../../actions';
import { translationHook } from '../../translationHook';
import {
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning
} from '../../UI/Buttons';

import DepartmentsAdministrationsForm from './DepartmentsAdministrationsForm'

class DepartmentsAdministrationsList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	customizeCellCheckbox = ({data}) => {
        return (
            <Switch
				checked={data.Active_Status_Id}
				name={`sectorStatus_${data.Department_Id}`}
				onChange={async () => {
					let at = this.props.currentUser.Data.access_token,
						departmentSectorId = data.Department_Sector_Id,
						status = data.Active_Status_Id ? 0 : 1

						this.props.setLoading(true, 'dark')

						const response = await setDepartmentSectorStatus(at, departmentSectorId, status)

						this.props.triggerNotification(true, response)

						this.props.setLoading(false)
				}}
			/>
        )
        
    }

	renderSectorForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
			editMood = params[2] === 'edit' ? true : false

        return <DepartmentsAdministrationsForm departmentSectorId={params[0]} initValues={params[1]} editMood={editMood} viewMood={viewMood} />
    }

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			departmentSectorId = data.Department_Sector_Id,
			departmentSectorName =
				this.props.i18n.language === 'ar'
					? data.Department_Sector_Name_AR
					: data.Department_Sector_Name_EN,
			dialogTitle = `${this.props.t('confirmDeleteTitle')} ${departmentSectorName}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: deleteDepartmentSector,
				params: [
					at,
					departmentSectorId
				],
				text: this.props.t('deleteSection'),
			},
		});
	}

	handleEdit = (data, viewMood) => {

        let initValues = {
			sectorCode: data.Department_Sector_Code,
            sectorNameAR: data.Department_Sector_Name_AR,
            sectorNameEN: data.Department_Sector_Name_EN,
            sectortNo: data.Department_Sector_No,
			sectorStatus: data.Active_Status_Id,

        },
        sectorId = data.Department_Sector_Id,
        sectorName = this.props.i18n.language === 'ar' ? data.Department_Sector_Name_AR : data.Department_Sector_Name_EN,
        dialogTitle = `${viewMood === 'view' ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${sectorName}`

		
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderSectorForm,
            params: [
                sectorId,
                initValues,
				viewMood
            ]
        })

    }

	render() {
		let columnAlign = 'left',
            SectorNameKey = 'Department_Sector_Name_EN',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right'
			SectorNameKey = 'Department_Sector_Name_AR'
		}

		const jsonDataSource = new CustomStore({
			key: 'Department_Sector_Id',
			load: () => {
				return getDepartmentsSectorsList(at, this.props.currentUser.Data.Department_Id, this.props.currentUser.Data.User_Id);
			}
		});

		return (
			<>
				<DepartmentsAdministrationsForm />

				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
                        dataField="Department_Sector_No"
                        caption={this.props.t('departmentNo')}
                        alignment={'center'}
					>
					</Column>

					<Column cssClass="outlinedColumn"
                        dataField="Department_Sector_Code"
                        caption={this.props.t('departmentCode')}
                        alignment={'center'}
					>
					</Column>
					
					<Column
                        dataField={SectorNameKey}
                        caption={this.props.t('depName')}
                        alignment={columnAlign}
					>
					</Column>
					
					<Column
                        dataField='Active_Status_Id'
                        caption={this.props.t('depStatus')}
                        allowFiltering={false}
                        cellRender={this.customizeCellCheckbox}
                        alignment={'center'}
					>
					</Column>
				
					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonWarning
									variant='circleIcon'
									startIcon={<EditIcon />}
									onClick={() => {
                                        this.handleEdit(record.data, 'edit')
                                    }}
									>
										{/* {this.props.t('edit')} */}
									</ButtonWarning>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonInfo
									variant='circleIcon'
									startIcon={<VisibilityIcon />}
									onClick={() => {
                                        this.handleEdit(record.data, 'view')
                                    }}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>

						<Button
							render={(record) => {
								return (
									<ButtonDanger
										variant='circleIcon'
										startIcon={<DeleteIcon />}
										onClick={() => this.handleDelete(record.data)}>
										{/* {this.props.t('delete')} */}
									</ButtonDanger>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	
	onExporting(e) {
		let sheetTitle = this.props.t('municipalAdministrations')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, triggerNotification, setLoading })(
	translationHook(DepartmentsAdministrationsList)
);


