import apiConfig from '../../apis/apiConfig';

export const GMdashboardStatistics = async (at) => {
	const response = await apiConfig.post(
		'API/ADMINISTRATION/DASHBOARD/GENERALMANAGER/GET_GM_DASHBOARD_STATISTICS', {},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data ? response.data.Data : [];
}
export const getNewRequestListGM = async (at) => {
	const response = await apiConfig.post(
		'API/ADMINISTRATION/DASHBOARD/GENERALMANAGER/GET_GM_DASHBOARD_NEW_REQUESTS_LIST', 
		{
            pageIndex: 1,
            pagecount: 1000
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data ? response.data.Data : [];
}
export const getapprovalRequestListGM = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/DASHBOARD/GENERALMANAGER/GET_GM_DASHBOARD_APPROVAL_REQUESTS_LIST', 
		{
            pageIndex: 1,
            pagecount: 1000
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data ? response.data.Data : [];
}
export const getdepartmentsRequestListGM = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/DASHBOARD/GENERALMANAGER/GET_GM_DASHBOARD_DEPARTMENT_REQUESTS_LIST', 
		{
            pageIndex: 1,
            pagecount: 1000
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data ? response.data.Data : [];
}
export const getMercyRequestListGM = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/DASHBOARD/GENERALMANAGER/GET_GM_DASHBOARD_MERCY_REQUESTS_LIST', 
		{
            pageIndex: 1,
            pagecount: 1000
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data ? response.data.Data : [];
}
export const getAssignedRequestListGM = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/DASHBOARD/GENERALMANAGER/GET_GM_DASHBOARD_ASSIGNED_REQUESTS_LIST', 
		{
            pageIndex: 1,
            pagecount: 1000
        },
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data ? response.data.Data : [];
}