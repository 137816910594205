import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { ButtonPrimary , ButtonSecondary } from '../../UI/Buttons';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import { triggerNotification, setLoading } from '../../../actions';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';

class SystemModuleForm extends Component {
	state = {
		initialValues: {},
        center: {},
		file: null,
		url: null,
	};
    

	componentDidMount() {
		const {
			Template_System_Variable_Id,
			Template_System_Variable_Name_AR,
			Template_System_Variable_Name_EN,
			Template_System_Variable_Code
		} = this.props.initValues;

		this.setState({
			initialValues: {
				Template_System_Variable_Id,
				Template_System_Variable_Name_AR,
				Template_System_Variable_Name_EN,
				Template_System_Variable_Code,
            },
		});
	}

	onSubmit = async (values) => {
		const auth = this.props.currentUser.Data.access_token,
		url =
			'/API/ADMINISTRATION/TEMPLATES/UPD_TEMPLATES_SYSTEM_VARIABLES';

		const data = {
			...values,
		}

		this.props.setLoading(true, 'dark')

		const response = await apiConfig.post(url, data, {
			headers: {
				Authorization: `Bearer ${auth}`,
			},
		}).catch((error) => {
            return error.response
        })
		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);

	};


	render() {

		return (
			<>
				<Form
					onSubmit={this.onSubmit}
					initialValues={this.state.initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => (
						<form
							onSubmit={handleSubmit}
							className={`row ${!this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
							noValidate
                            className='row mt-4 mx-3 departmentFormClass'>
                                <Col xs={6} md={6}>
                                    <Row>
                                        <Col xs={12} md={9}>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <TextField
                                                        disabled
                                                        label={this.props.t('varId')}
														className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_System_Variable_Id'
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <TextField
                                                        disabled={this.props.viewMood}
                                                        label={this.props.t('varCode')}
														className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_System_Variable_Code'
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <TextField
                                                        disabled
                                                        label={this.props.t('varNameAR')}
                                                        className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_System_Variable_Name_AR'
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <TextField
                                                        disabled
                                                        label={this.props.t('varNameEN')}
                                                        className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                        name='Template_System_Variable_Name_EN'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='mt-4 mb-4' xs={12}>
                                    <Row>
                                        <Col className='mt-2 submit-btn-wrap'>
                                            { !this.props.viewMood && 
                                                <ButtonPrimary
                                                    color='primary'
                                                    variant='contained'
                                                    disabled={submitting}
                                                    type='submit'>
                                                    {this.props.t('save')}
                                                </ButtonPrimary>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { triggerNotification, setLoading })(
	translationHook(SystemModuleForm)
);
