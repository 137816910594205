import apiConfig from '../apiConfig'

export const getSuggAndCompList = async (at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/CLIENTMESSAGESMANAGEMENT/Client_Messages_List', {},
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        for (let i in response.data.Data) {
            if (response.data.Data[i].Active_Status_Id === 1) {
               response.data.Data[i].Active_Status_Id = true
            } else {
                response.data.Data[i].Active_Status_Id = false
            }
          }
        return response.data.Data
    }

    return []
}
