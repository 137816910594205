import apiConfig from '../apiConfig';

export const setDepartmentSectorStatus = async (at, departmentSectorId, status) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/UPD_DEPARTMENT_SECTOR_ACTIVE',
		{
            Department_Sector_Id: departmentSectorId,
            Active_Status_Id: status
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);

    return response.data;

}
