import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../apis/apiConfig';
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Select } from 'mui-rff'

import { ButtonPrimary } from '../../UI/Buttons'
import { translationHook } from '../../translationHook'
import { triggerNotification, setLoading } from '../../../actions'

class ServiceVersionInfo extends Component {

    state = {
        departmentSections: []
    }


    getDepartmentSections = async ()=> {
        let at = this.props.currentUser.Data.access_token,
        catsArr = []
        const response = await apiConfig.post(
            '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SECTIONS',
            {},
            {
                headers: {
                    Authorization: `Bearer ${at}`,
                },
            },
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Department_Section_Name_AR : item.Department_Section_Name_EN

                catsArr.push({value: item.Department_Section_Id, label})
            })

            this.setState({departmentSections: catsArr})
            
        }

    }

    componentDidMount() {

        if ( this.props.active ) {
            this.getDepartmentSections()
        }

    }

    onServiceInfoSubmit = async (values, form) => {
        const at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/ADD_VERSION' : '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPD_VERSION',
        bodyData = {
            Department_Section_Id: values.departmentSectionID
        }

        if ( !this.props.editMood ) {
            bodyData.Department_Service_Id = this.props.departmentServiceId
        } else if (this.props.editMood) {
            bodyData.Department_Service_Version_Id = this.props.versionid
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)


        if (response.data.Status) {
            form.restart()
            this.props.onStepDone(response.data.Data.Department_Service_Version_Id)
        }
        
        
    }

    
    render() {
    
        const validate = values => {
            const errors = {}

            if (!values.departmentSectionID) {
                errors.departmentSectionID = `${this.props.t('error.field_required')}`;
            }
            
            return errors;
        }


        return(
            <Form
                onSubmit={this.onServiceInfoSubmit}
                initialValues={this.props.initValues ? this.props.initValues : {}}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                className="row"
                onSubmit={handleSubmit}
                        noValidate>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12} md={4}>
                                <TextField
                                    label={this.props.t('versionNo')}
                                    className="mb-2"
                                    name="versionNo"
                                    disabled
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <TextField
                                    label={this.props.t('versionCode')}
                                    className="mb-2"
                                    name="versionCode"
                                    disabled
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={4}>
                        <Select
                            disabled={this.props.viewMood}
                            label={this.props.t('serviceSection')}
                            className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                            required={true}
                            name="departmentSectionID"
                            data={this.state.departmentSections}
                            showError={  () => {
                                let fieldStatus = form.getFieldState('departmentSectionID')

                                if (fieldStatus) {

                                    return fieldStatus.submitFailed && fieldStatus.invalid
                                }
                            } }
                        >
                        </Select>
                    </Col>
                    
                    <Col className='my-4' xs={12}>
                        <Row className='align-items-center'>
                            <Col className='mt-2'>
                                { !this.props.viewMood && 
                                <ButtonPrimary
                                    color="primary"
                                    variant="contained"
                                    disabled={submitting || this.props.viewMood}
                                    type="submit"
                                >
                                    {this.props.t('save')}
                                </ButtonPrimary>
                                }
                            </Col>
                        </Row>
                    </Col>
                    
                </form>
                )}
            />
        )
    }

}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading })(translationHook(ServiceVersionInfo))