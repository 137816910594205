import { TRIGGER_LOADING } from '../actions/types'

const INIT_LOADING = {
    status: false,
    style: 'light',
    zIndex: 900
}


const loadingReducer = (status = INIT_LOADING, action) => {

    switch (action.type) {
        case TRIGGER_LOADING:
            
            return {...status, ...action.payload}
    
        default:
            return status
    }

}

export default loadingReducer