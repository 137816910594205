import apiConfig from '../apiConfig'

export const getMyTasksList = async (at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_MY_LIST', {
            PageIndex: 1,
            PageCount: 1000
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    if (response.data.Status) {
        return response.data.Data.Data
    }

    return []
}
export const getAssignedTasksList = async (at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_LIST', {
            PageIndex: 1,
            PageCount: 1000
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    if (response.data.Status) {
        return response.data.Data.Data
    }

    return []
}
export const getAllTasksList = async (at , data) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_ALL_LIST', {
            PageIndex: 1,
            PageCount: 1000,
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    if (response.data.Status) {
        return response.data.Data.Data
    }

    return []
}
export const openMission = async (id ,at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/OPEN_MISSION', {
            Mission_Id: id,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    
    return response
}
export const compeleteMission = async (data ,at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/COMPELET_MISSION',data,
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    
    return response
}