import React, { Component } from "react";
import { connect } from "react-redux";

import { ButtonPrimary } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { Select } from "mui-rff";
import {
  DataGrid,
  Column,
  Selection,
  ColumnChooser,
  SearchPanel,
  FilterRow,
  HeaderFilter,
} from "devextreme-react/data-grid";
import Switch from "@material-ui/core/Switch";
import { Icon, IconButton } from "@material-ui/core";

import { getDepartmentJobs } from "../../../apis/system/userAreas/getDepartmentJobs";
import { translationHook } from "../../translationHook";
import { getDepartmentJobsUsers } from "../../../apis/system/userAreas/getDepartmentJobsUsers";
import CustomStore from "devextreme/data/custom_store";
import { getUserAreas } from "../../../apis/system/userAreas/getUserAreas";
import { addUserArea } from "../../../apis/system/userAreas/addUserArea";
import { deleteUserArea } from "../../../apis/system/userAreas/deleteUserArea";
import { applyUserSettings } from "../../../apis/system/userAreas/applyUserSettings";

import { setLoading, triggerNotification } from "../../../actions";

class UsersArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      at: this.props.currentUser.Data.access_token,
      jobsList: [],
      jobId: undefined,
      currentSelectedUser: undefined,
      copiedUserId: undefined,
      message: null,
      areasList: undefined,
    };
  }

  // =======================================================================
  // ============================ Jobs =====================================
  // =======================================================================

  getJobs = async () => {
    let jobsList = [];

    const data = await getDepartmentJobs(this.state.at);
    if (data) {
      data.map((item, index) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Department_Job_Name_AR
            : item.Department_Job_Name_EN;

        return jobsList.push({
          key: index,
          value: item.Department_Job_Id,
          label,
        });
      });
      this.setState({ jobsList });
    }
  };

  handleJobsSelectChange = (e) => {
    this.setState({ jobId: e.target.value, currentSelectedUser: undefined });
  };

  // =======================================================================
  // ============================ Users ====================================
  // =======================================================================

  handleJobsUsersGridColumns = () => {
    let columnsData = {
      columnAlign: "left",
      userName: "User_Name_EN",
      userGroupName: "User_Group_Name_EN",
      departmentJobName: "Department_Job_Name_EN",
      departmentName: "Department_Name_EN",
      userMail: "User_Mail",
      loginName: "Login_Name",
      userId: "User_Id",
    };

    if (this.props.i18n.language === "ar") {
      columnsData = {
        ...columnsData,
        columnAlign: "right",
        userName: "User_Name_AR",
        userGroupName: "User_Group_Name_AR",
        departmentJobName: "Department_Job_Name_AR",
        departmentName: "Department_Name_AR",
      };
    }

    return columnsData;
  };

  handleJobsUsersGridData = () => {
    if (this.state.jobId !== undefined) {
      return new CustomStore({
        key: this.handleJobsUsersGridColumns().userId,

        load: () => {
          return getDepartmentJobsUsers(this.state.at, this.state.jobId);
        },
      });
    }
  };

  handleJobsUserSelection = (selection) => {
    if (selection["selectedRowsData"].length > 0) {
      this.setState({
        currentSelectedUser: selection["selectedRowsData"][0],
        areasList: undefined,
      });
    }
  };

  // =======================================================================
  // ============================ Areas ====================================
  // =======================================================================

  handleUserAreasColumns = () => {
    let columnsData = {
      columnAlign: "left",
      select: "Selected",
      areaNumber: "Area_Id",
      areaName: "Area_Name_EN",
    };

    if (this.props.i18n.language === "ar") {
      columnsData = {
        ...columnsData,
        columnAlign: "right",
        areaName: "Area_Name_AR",
      };
    }

    return columnsData;
  };

  handleUserAreasGridData = () => {
    if (this.state.currentSelectedUser?.User_Id !== undefined) {
      return new CustomStore({
        key: this.handleUserAreasColumns().areaNumber,

        load: () => {
          if (!this.state.areasList) {
            return getUserAreas(
              this.state.at,
              this.state.currentSelectedUser.User_Id
            );
          } else {
            return this.state.areasList;
          }
        },
      });
    }
  };

  handleUserAreaSelection = async (selection) => {
    if (selection["currentDeselectedRowKeys"].length > 0) {
      await deleteUserArea(
        this.state.at,
        this.state.currentSelectedUser.User_Id,
        selection["currentDeselectedRowKeys"][0]
      );
    } else if (selection["selectedRowsData"].length > 0) {
      await addUserArea(
        this.state.at,
        this.state.currentSelectedUser.User_Id,
        selection["selectedRowsData"].at(-1)?.Area_Id
      );
    }
  };

  customizeUserAreaStatusCheckbox = ({ data }) => {
    return (
      <Switch
        checked={data.Selected}
        // name={`centerStatus_${data.Department_Service_Center_Id}`}
        onChange={async () => {
          this.props.setLoading(true, "dark");

          if (data.Selected) {
            await deleteUserArea(
              this.state.at,
              this.state.currentSelectedUser.User_Id,
              data.Area_Id
            ).then((res) => {
              this.setState({ areasList: res.Data });
              this.props.triggerNotification(true, res);
              this.props.setLoading(false);
            });
          } else {
            await addUserArea(
              this.state.at,
              this.state.currentSelectedUser.User_Id,
              data.Area_Id
            ).then((res) => {
              this.setState({ areasList: res.Data });
              this.props.triggerNotification(true, res);
              this.props.setLoading(false);
            });
          }
        }}
      />
    );
  };
  // =======================================================================
  // ============================ Copy & Apply =============================
  // =======================================================================

  handleCoppyUserSettings = () => {
    if (this.state.currentSelectedUser) {
      this.setState({ copiedUserId: this.state.currentSelectedUser.User_Id });

      this.props.i18n.language === "ar"
        ? this.setState({
            message: `الإعدادت المنسوخة حاليًا للمستخدم: ${this.state.currentSelectedUser?.User_Name_AR} , وظيفته: ${this.state.currentSelectedUser?.Department_Job_Name_AR}`,
          })
        : this.setState({
            message: `Current copied settings are for user: ${this.state.currentSelectedUser?.User_Name_EN}, his job: ${this.state.currentSelectedUser?.Department_Job_Name_EN}`,
          });
    }
  };

  handleApplyUserSettings = async () => {
    await applyUserSettings(
      this.state.at,
      this.state.copiedUserId,
      this.state.currentSelectedUser.User_Id
    ).then((res) => {
      this.setState({ message: null });
    });
  };

  componentDidMount() {
    this.getJobs();
  }

  render() {
    return (
      <>
        {!this.props.editMood && !this.props.viewMood && (
          <div className="d-flex justify-content-between align-items-center pb-5">
            <h3> {this.props.t("usersAreas")}</h3>
          </div>
        )}

        <Row>
          <Form
            onSubmit={() => {}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className="row w-100">
                <Col xs={12} md={8}>
                  <Row>
                    <Col xs={12}>
                      <Select
                        label=""
                        className="mb-2"
                        name="returnStepId"
                        value={this.state.jobId}
                        data={this.state.jobsList}
                        onChange={this.handleJobsSelectChange}
                      ></Select>
                    </Col>
                  </Row>
                </Col>
              </form>
            )}
          />
        </Row>

        <Row>
          <Col xs={12} md={8}>
            <DataGrid
              className={[
                this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
                "globalBox",
              ].join(" ")}
              id="dataGrid"
              width={"100%"}
              dataSource={this.handleJobsUsersGridData()}
              showBorders={false}
              showColumnLines={false}
              showRowLines={true}
              allowColumnResizing={true}
              columnResizingMode={"widget"}
              allowColumnReordering={true}
              rowAlternationEnabled={false}
              focusedRowEnabled={true}
              columnWidth={"auto"}
              onSelectionChanged={this.handleJobsUserSelection}
              rtlEnabled={this.props.i18n.language === "ar" ? true : false}
            >
              <Selection mode="single" />

              <ColumnChooser
                enabled={true}
                allowSearch={true}
                search={"fff"}
                title={this.props.t("dxColumnChooserTitle")}
                emptyPanelText={this.props.t("dxColumnChooserPanelText")}
              />

              <SearchPanel
                visible={true}
                width={240}
                placeholder={this.props.t("search")}
              />

              <FilterRow visible={true} />

              <HeaderFilter visible={true} />

              <Column
                dataField={this.handleJobsUsersGridColumns().userName}
                caption={this.props.t("userName")}
                alignment={this.handleJobsUsersGridColumns().columnAlign}
              ></Column>

              <Column
                dataField={this.handleJobsUsersGridColumns().loginName}
                caption={this.props.t("loginName")}
                alignment={this.handleJobsUsersGridColumns().columnAlign}
              ></Column>

              <Column
                dataField={this.handleJobsUsersGridColumns().userGroupName}
                caption={this.props.t("userGroupName")}
                alignment={this.handleJobsUsersGridColumns().columnAlign}
              ></Column>

              <Column
                dataField={this.handleJobsUsersGridColumns().departmentJobName}
                caption={this.props.t("departmentJobName")}
                alignment={this.handleJobsUsersGridColumns().columnAlign}
              ></Column>

              <Column
                dataField={this.handleJobsUsersGridColumns().departmentName}
                caption={this.props.t("departmentName")}
                alignment={this.handleJobsUsersGridColumns().columnAlign}
              ></Column>

              <Column
                dataField={this.handleJobsUsersGridColumns().userMail}
                caption={this.props.t("userMail")}
                alignment={this.handleJobsUsersGridColumns().columnAlign}
              ></Column>
            </DataGrid>
          </Col>

          <Col xs={12} md={4}>
            <DataGrid
              className={[
                this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
                "globalBox",
              ].join(" ")}
              id="dataGrid"
              width={"100%"}
              dataSource={this.handleUserAreasGridData()}
              showBorders={false}
              showColumnLines={false}
              showRowLines={true}
              allowColumnResizing={true}
              columnResizingMode={"widget"}
              allowColumnReordering={true}
              rowAlternationEnabled={false}
              focusedRowEnabled={true}
              columnWidth={"auto"}
              rtlEnabled={this.props.i18n.language === "ar" ? true : false}
            >
              <ColumnChooser
                enabled={true}
                allowSearch={true}
                search={"fff"}
                title={this.props.t("dxColumnChooserTitle")}
                emptyPanelText={this.props.t("dxColumnChooserPanelText")}
              />

              <SearchPanel
                visible={true}
                width={240}
                placeholder={this.props.t("search")}
              />

              <FilterRow visible={true} />

              <HeaderFilter visible={true} />

              <Column
                dataField={this.handleUserAreasColumns().select}
                caption={this.props.t("select")}
                alignment={this.handleUserAreasColumns().columnAlign}
                cellRender={this.customizeUserAreaStatusCheckbox}
              ></Column>

              <Column
                dataField={this.handleUserAreasColumns().areaNumber}
                caption={this.props.t("areaNumber")}
                alignment={this.handleUserAreasColumns().columnAlign}
              ></Column>

              <Column
                dataField={this.handleUserAreasColumns().areaName}
                caption={this.props.t("areaName")}
                alignment={this.handleUserAreasColumns().columnAlign}
              ></Column>
            </DataGrid>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col xs={12} md={6}>
            {this.state.message && (
              <p style={{ background: "yellow", padding: "1rem" }}>
                <span>{this.state.message}</span>
              </p>
            )}
          </Col>

          <Col xs={12} md={6}>
            <Row className="justify-content-around">
              {this.state.currentSelectedUser && (
                <ButtonPrimary
                  color="primary"
                  variant="contained"
                  className="mb-3"
                  onClick={this.handleCoppyUserSettings}
                >
                  {this.props.t("copyCurrentUserSettings")}
                </ButtonPrimary>
              )}

              {this.state.message && (
                <ButtonPrimary
                  color="primary"
                  variant="contained"
                  className="mb-3"
                  onClick={this.handleApplyUserSettings}
                >
                  {this.props.t("applyCurrentUserCopiedSettings")}
                </ButtonPrimary>
              )}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerNotification,
  setLoading,
})(translationHook(UsersArea));
