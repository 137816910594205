import apiConfig from '../../apiConfig';
export const setDepartmentServiceStatus = async (at, departmentServiceId, statusId) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SET_DEPARTMENT_SERVICE_STATUS', {
            Department_Service_Id: departmentServiceId,
            Active_Status_Id: statusId
        }, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
