import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import { ButtonPrimary } from "../../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import FilesUpload from "../../../UI/FileUpload/UploadFile";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import { Form, Field } from "react-final-form";
import DateBox from "devextreme-react/date-box";
import { TextField, Select } from "mui-rff";
import { downloadRequestAttachment } from "../../../../apis/requests/info/downloadRequestAttachment";

import ReportInvoices from "./ReportInvoices";

class Report extends Component {
	state = {
		filesData: [],
		showButton: true,
		templateVariables: [],
		templateInvoices: [],
		showVariables: false,
		elements: [],
		initValues: {},
		selectedInvoices: [],
		templateApiData: null,
		rerender: false,
	};

	getLookupValue = async (variableId) => {
		let typesArr = [];
		const response = await apiConfig.post(
			"/API/SYSTEM/DDL/GET_SERVICES_LOOKUPS_VALUES",
			{
				Service_Lookup_Id: variableId,
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Record_Name_AR
						: item.Record_Name_EN;

				typesArr.push({ value: item.Record_Id, label });
			});

			this.setState({
				[variableId]: typesArr,
				rerender: !this.state.rerender,
			});
		}
	};

	renderVariableFields = () => {
		let elements = [],
			initValues = {};

		this.state.templateVariables.map((variable) => {
			elements.push(
				<Col
					xs={12}
					md={
						variable.Template_Variable_Type_Id !== 2 &&
						variable.Template_Variable_Type_Id !== 5 &&
						variable.Template_Variable_Type_Id !== 6
							? 12
							: 4
					}
					className="mb-3"
					key={variable.Template_Variable_Id}
				>
					{variable.Template_Variable_Type_Id === 2 ? (
						<Select
							label={variable.Template_Variable_Name_AR}
							className="mb-2"
							name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}_${variable.Template_Variable_Lookup_Id}`}
							data={
								this.state[
									variable.Template_Variable_Lookup_Id
								] ?? []
							}
						></Select>
					) : variable.Template_Variable_Type_Id === 5 ? (
						<TextField
							label={variable.Template_Variable_Name_AR}
							className="mb-2"
							name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
							type="number"
						/>
					) : variable.Template_Variable_Type_Id === 6 ? (
						<>
							<Field
								name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
							>
								{(fieldProps) => (
									<DateBox
										acceptCustomValue={false}
										defaultValue={null}
										dateSerializationFormat="yyyy-MM-dd"
										displayFormat="yyyy-MM-dd"
										onValueChanged={(e) => {
											fieldProps.input.onChange(e.value);
										}}
										type="date"
										placeholder={
											variable.Template_Variable_Name_AR
										}
										className="mt-3"
										// isValid={() => {
										//     return !fieldProps.meta?.invalid && !fieldProps.meta?.submitFailed
										// }}
									/>
								)}
							</Field>
						</>
					) : (
						<TextField
							label={variable.Template_Variable_Name_AR}
							className="mb-2"
							name={`tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
							multiline
							rows={4}
						/>
					)}

					<Field
						name={`tempVarId_${variable.Template_Variable_Id}`}
						render={() => {
							return (
								<input
									type="hidden"
									name={`tempVarId_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`}
									value={variable.Template_Variable_Id}
								/>
							);
						}}
					/>
				</Col>
			);

            if (variable.Template_Variable_Type_Id === 2 &&
                variable.Template_Variable_Lookup_Id
            ) {
                initValues[
                    `tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}_${variable.Template_Variable_Lookup_Id}`
                ] = variable.Template_Variable_Value
                        ? variable.Template_Variable_Value
                        : "";
			} else {
                initValues[
                    `tempVar_${variable.Template_Variable_Id}_${variable.Template_Variable_Type_Id}`
                ] = variable.Template_Variable_Value
                        ? variable.Template_Variable_Value
                        : "";
			}
		});

		this.setState({ elements });
		this.setState({ initValues });
	};

	saveRequestTaskInspectionFile = async (values) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_INSPECTION_REPORT_VARIABLES",
			arr = [];

		for (const key in values) {
			if (Object.hasOwnProperty.call(values, key)) {
				let id = key.split("_")[1],
					value = values[key],
					typeId = key.split("_")[2],
					templateVariableValue = value;

				if (typeId == 2) {
					let lookupId = key.split("_")[3];

					this.state[lookupId].forEach((lookup) => {
						if (lookup.value == value) {
							templateVariableValue = lookup.label;
						}
					});
				}

				arr.push({
					Template_Variable_Id: id,
					Template_Variable_Value: templateVariableValue,
				});
			}
		}

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				Template_Variables_Inputs_List: arr,
				Template_Variables_Invoices_List: this.state.selectedInvoices,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ filesData: response.data.Data });
		}
	};

	getRequestInspectionVariables = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_INSPECTION_REPORT_VARIABLES";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status && response.data.Data) {
			let { Template_Variables_Data, Template_Invoices_Data } =
				response.data.Data;

			if (Template_Variables_Data && Template_Variables_Data.length > 0) {
				this.setState({ templateVariables: Template_Variables_Data });

				Template_Variables_Data.forEach((variable) => {
					if (variable.Template_Variable_Type_Id === 2) {
						this.getLookupValue(
							variable.Template_Variable_Lookup_Id
						);
					}
				});
			}

			if (Template_Invoices_Data && Template_Invoices_Data.length > 0) {
				this.setState({ templateInvoices: Template_Invoices_Data });
			}

			this.setState({ rerender: !this.state.rerender });
		}
	};

	signRequestTaskInspectionFile = async ({ File_Code }) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SIGN_REQUEST_TASK_INSPECTION_REPORT_FILE";

		const response = await apiConfig.post(
			url,
			{
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				File_Code,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			this.setState({ filesData: response.data.Data });
		}
	};

	deleteRequestTaskReportFile = async (file) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DELETE_REQUEST_TASK_INSPECTION_REPORT_FILE";

		await apiConfig
			.post(
				url,
				{
					Request_Id: this.props.requestId,
					Request_Task_Id: this.props.requestTaskId,
					File_Code: file.File_Code,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.then((result) => {
				if (result.data.Status) {
					const updatedFilesList = this.state.filesData.filter(
						(f) => {
							return file.File_Code !== f.File_Code;
						}
					);

					this.setState({ filesData: updatedFilesList });
				}
			});
	};

	componentDidMount() {
		this.getRequestInspectionVariables().then(() => {
			this.renderVariableFields();
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.rerender !== this.state.rerender) {
			this.renderVariableFields();
		}
	}

	render() {
		const validate = (values) => {
			const errors = {};

			return errors;
		};

		return (
			<>
				{this.state.showButton && this.state.filesData.length === 0 && (
					<>
						<Form
							onSubmit={this.saveRequestTaskInspectionFile}
							initialValues={this.state.initValues}
							validate={validate}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values,
							}) => (
								<form
									className="row"
									onSubmit={handleSubmit}
									noValidate
								>
									{this.state.elements}

									<Col xs={12}>
										<ReportInvoices
											invoices={
												this.state.templateInvoices
											}
											setSelectedInvoices={(
												invoicesList
											) =>
												this.setState({
													selectedInvoices:
														invoicesList,
												})
											}
										/>
									</Col>

									<Col
										className="my-5 justify-content-start d-flex"
										xs={12}
									>
										<ButtonPrimary
											color="primary"
											variant="contained"
											disabled={submitting}
											type="submit"
										>
											{this.props.t("fillTemplate")}
										</ButtonPrimary>
									</Col>
								</form>
							)}
						/>
					</>
				)}

				{this.state.filesData && this.state.filesData.length > 0 && (
					<Row>
						<Col xs={12}>
							<h4 className="my-3">
								{this.props.t("reportsList")}
							</h4>
						</Col>
						<Col xs={12} md={6}>
							<FilesUpload
								downloadCB={downloadRequestAttachment}
								view={true}
								attachments={[
									{ Files_Data: this.state.filesData },
								]}
								deleteCB={this.deleteRequestTaskReportFile}
								approveCB={this.signRequestTaskInspectionFile}
							/>
						</Col>
					</Row>
				)}
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(Report));
