import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../apis/apiConfig';
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Select, Switches } from 'mui-rff'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'
import ListItemText from '@material-ui/core/ListItemText'

import { ButtonPrimary, ButtonSecondary } from './../../UI/Buttons'
import { translationHook } from '../../translationHook'
import { triggerNotification, setLoading } from '../../../actions'
// import FilesUpload from "../../../UI/FileUpload/UploadFile";

class ModuleMainInfo extends Component {

    constructor(props){
        super(props);
        this.state = {
            areaType: [],
            file: '',
            fileUrl: '',
            imgUrl: ""
            // this.setState({fileUrl: initValues.Approving_Chart_File_Url})

        };
      }


    getAreaTypeList = async ()=> {
        let areaType = []

        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_AREA_TYPES'
        )

        if (response.data.Status) {

            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN

                areaType.push({value: item.Record_Id, label})
            })
            this.setState({areaType: areaType})
        }
    }

    handleAddFiles = (e) => {
        let url = window.URL.createObjectURL(e.target.files[0]);
        this.setState({
            file: e.target.files[0],
            fileName: e.target.files[0].name,
            fileUrl: url,
        })
    }
    uploadNewFile = async () => {
        const bodyFormData = new FormData(),
        at = this.props.currentUser.Data.access_token,
        url =  'API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/UPD_APPROVING_CHART_FILE',
        bodyData = {
            Approving_Chart_Id: this.props.moduleID
        }

        bodyFormData.append('PARAMETERS', JSON.stringify(bodyData))

        bodyFormData.append('Approving_Chart_File', this.state.file)

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)

        if (response.data.Status) {
            this.setState({
                file: null
            })
        }

    }
    componentDidMount() {
        const { initValues } = this.props;
        console.log(initValues)

        if ( <this className="props active"></this>) {
            this.getAreaTypeList()
        }
    }

    onSumit = async (values, form) => {
        const bodyFormData = new FormData(),
        at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/ADD_APPROVING_CHART' : '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/UPD_APPROVING_CHART',
        bodyData = {
            Active_Status_Id: values.Active_Status_Id ? 1 : 0,
            approving_Chart_Name: values.Approving_Chart_Name,
            approving_Chart_Floors_Description_AR: values.Approving_Chart_Floors_Description_AR,
            approving_Chart_Floors_Description_EN: values.Approving_Chart_Floors_Description_EN, 
            approving_Chart_Consultant_Name_AR: values.Approving_Chart_Consultant_Name_AR,
            approving_Chart_Consultant_Name_EN: values.Approving_Chart_Consultant_Name_EN,
            approving_Chart_Bedroom_No: values.Approving_Chart_Bedroom_No,
            approving_Chart_Area: values.Approving_Chart_Area,
            approving_Chart_Floors_No: values.Approving_Chart_Floors_No,
            approving_Chart_Width: values.Approving_Chart_Width,
            approving_Chart_Length: values.Approving_Chart_Length,
            approving_Chart_Height: values.Approving_Chart_Height,
            approving_Chart_Special_Advantages_AR: values.Approving_Chart_Special_Advantages_AR,
            approving_Chart_Special_Advantages_EN: values.Approving_Chart_Special_Advantages_EN,
            approving_Chart_AC_Type_AR: values.Approving_Chart_AC_Type_AR,
            approving_Chart_AC_Type_EN: values.Approving_Chart_AC_Type_EN,
            approving_Chart_Notes_AR: values.Approving_Chart_Notes_AR,
            approving_Chart_Notes_EN: values.Approving_Chart_Notes_EN,
            approving_Chart_Cost: values.Approving_Chart_Cost,
            area_Type_Id: values.Area_Type_Id
        }
        if ( this.props.editMood ) {
            bodyData.approving_Chart_Id = this.props.moduleID 
        }   
        
        bodyFormData.append('PARAMETERS', JSON.stringify(bodyData))
        
        if(this.state.file) {
            bodyFormData.append('Approving_Chart_File', this.state.file)
        }


        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })

        this.props.triggerNotification(true, response.data)
        this.props.setLoading(false)

        if (response.data.Status && !this.props.editMood) {
            form.restart()
        }
        this.props.onStepDone(response.data.Data?.Approving_Chart_Id, response.data.Data)
        this.setState({fileUrl: response.data.Data?.Approving_Chart_File_Url})
    }

    
    render() {
    
        const validate = values => {
            const errors = {}

            if (!values.Approving_Chart_Name) {
                errors.Approving_Chart_Name = `${this.props.t('error.field_required')}`;
            }
            if (!values.Area_Type_Id) {
                errors.Area_Type_Id = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Consultant_Name_AR) {
                errors.Approving_Chart_Consultant_Name_AR = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Consultant_Name_EN) {
                errors.approving_Chart_Consultant_Name_EN = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Bedroom_No) {
                errors.Approving_Chart_Bedroom_No = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Area) {
                errors.Approving_Chart_Area = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Floors_No) {
                errors.Approving_Chart_Floors_No = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Floors_Description_AR) {
                errors.Approving_Chart_Floors_Description_AR = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Floors_Description_EN) {
                errors.Approving_Chart_Floors_Description_EN = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Width) {
                errors.Approving_Chart_Width = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Length) {
                errors.Approving_Chart_Length = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Height) {
                errors.Approving_Chart_Height = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Special_Advantages_AR) {
                errors.Approving_Chart_Special_Advantages_AR = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Special_Advantages_EN) {
                errors.Approving_Chart_Special_Advantages_EN = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_AC_Type_AR) {
                errors.Approving_Chart_AC_Type_AR = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_AC_Type_EN) {
                errors.Approving_Chart_AC_Type_EN = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Notes_EN) {
                errors.Approving_Chart_Notes_EN = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Notes_AR) {
                errors.Approving_Chart_Notes_AR = `${this.props.t('error.field_required')}`;
            }
            if (!values.Approving_Chart_Cost) {
                errors.Approving_Chart_Cost = `${this.props.t('error.field_required')}`;
            }
            return errors;
        }
        const { initValues } = this.props;

        let Approving_Chart_File_Url;

        if(initValues) {
            Approving_Chart_File_Url  = initValues.Approving_Chart_File_Url;
        }

        return(
            <Form
                onSubmit={this.onSumit}
                initialValues={initValues ? initValues : {
                    Active_Status_Id: true,
                    Approving_Chart_Name: "",
                    Approving_Chart_Consultant_Name_AR: "",
                    Approving_Chart_Consultant_Name_EN: "",
                    Approving_Chart_Special_Advantages_AR: "",
                    Approving_Chart_Special_Advantages_EN: "",
                    Approving_Chart_AC_Type_AR: "",
                    Approving_Chart_AC_Type_EN: "",
                    Approving_Chart_Notes_AR: "",
                    Approving_Chart_Notes_EN: "",
                    Approving_Chart_Floors_No: "",
                    Area_Type_Id: "",
                    Approving_Chart_Floors_Description_AR: "",
                    Approving_Chart_Floors_Description_EN: "",
                    Approving_Chart_Bedroom_No: "",
                    Approving_Chart_Area: "",
                    Approving_Chart_Height: "",
                    Approving_Chart_Width: "",
                    Approving_Chart_Length: "",
                    Approving_Chart_Cost: "",
                    Approving_Chart_File_Url: ""
                }}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form
                onSubmit={handleSubmit}
                        noValidate>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12}  md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('moduleName')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Name"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={4} 
                            className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="Active_Status_Id"
                                    data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('Active_Status_Id')}` }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('consultantNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Consultant_Name_AR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('consultantNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Consultant_Name_EN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('specialAdvAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Special_Advantages_AR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('specialAdvEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Special_Advantages_EN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('acTypeAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_AC_Type_AR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('acTypeEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_AC_Type_EN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('notesAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Notes_AR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('notesEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Notes_EN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('floorNumbers')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Floors_No"
                                    type="number"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <Select
                                    disabled={this.props.viewMood}
                                    label={this.props.t('areaType')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Area_Type_Id"
                                    data={this.state.areaType}
                                    showError={  () => {
                                        let fieldStatus = form.getFieldState('Area_Type_Id')

                                        if (fieldStatus) {

                                            return fieldStatus.submitFailed && fieldStatus.invalid
                                        }
                                    } }
                                >
                                </Select>
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('floorDescAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Floors_Description_AR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('floorDescEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Floors_Description_EN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('bedRoomNumbers')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    type="number"
                                    name="Approving_Chart_Bedroom_No"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                           
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('Area')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Area"
                                    type="number"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('height')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Height"
                                    type="number"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('width')}
                                    type="number"
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="Approving_Chart_Width"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('length')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    type="number"
                                    name="Approving_Chart_Length"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('price')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    type="number"
                                    name="Approving_Chart_Cost"
                                    showError={({ meta: { submitFailed, invalid } }) => { 
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>
                            {!this.props.viewMood && (
                                <Col className='my-4 d-flex ml-auto my-4' xs={12} md={12}   >
                                    <ButtonSecondary
                                        color='primary'
                                        variant='contained'
                                        endIcon={<CloudUploadIcon />}>
                                    {this.props.t('designFile')}
                                        <input
                                            type='file'
                                            name='Approving_Chart_File_Url'
                                            className='buttonFile'
                                            onChange={(e) => this.handleAddFiles(e)}
                                            accept=".zip,.rar"
                                        />
                                    </ButtonSecondary>
                                </Col>
                            )}
                            {this.state.fileUrl && (
                                <Col xs={12} md={6}>
                                    <ListItem className="shadow-sm">
                                        <ListItemAvatar>
                                            <CropOriginalIcon />
                                        </ListItemAvatar>
                                        <ListItemText primary={this.props.t('mainImage')}/>
                                        <IconButton
                                                edge='start'
                                                aria-label='download'
                                                onClick={() => {
                                                    window.open(this.state.fileUrl, '_blank');
                                                }}>
                                                <GetAppIcon />
                                            </IconButton>
                                    </ListItem>
                                </Col>
                                )}
                            {Approving_Chart_File_Url && !this.state.fileUrl && (
                                <Col xs={12} md={6}>
                                    <ListItem className="shadow-sm">
                                        <ListItemAvatar>
                                            <CropOriginalIcon />
                                        </ListItemAvatar>
                                        <ListItemText primary={this.props.t('mainImage')}/>
                                        <IconButton
                                                edge='start'
                                                aria-label='download'
                                                onClick={() => {
                                                    window.open(Approving_Chart_File_Url, '_blank');
                                                }}>
                                                <GetAppIcon />
                                            </IconButton>
                                    </ListItem>
                                </Col>
                                )}
                                {this.state.file && this.props.editMood && (
                                <Col xs={12} md={6}>
                                <ButtonPrimary
                                    color="primary"
                                    variant="contained"
                                    type="button"
                                    className='ml-auto d-flex align-items-center'
                                    onClick={(e) => this.uploadNewFile()}
                                >
                                    {this.props.t('uploadnewFile')}
                                </ButtonPrimary>
                                </Col>
                                )}
                            <Col className='my-4 d-flex ml-auto my-4' xs={12}>
                                { !this.props.viewMood && 
                                <ButtonPrimary
                                    color="primary"
                                    variant="contained"
                                    disabled={submitting || this.props.viewMood}
                                    type="submit"
                                >
                                    {this.props.t('save')}
                                </ButtonPrimary>
                                }
                            </Col>
                        </Row>
                    </Col>                
                    
                </form>
                )}
            />
        )
    }

}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading })(translationHook(ModuleMainInfo))