import apiConfig from '../../apiConfig'

export const getDepartmentServiceVersionsList = async (at, departmentServiceId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SERVICE_VERSIONS_LIST',
        {
            Department_Service_Id: departmentServiceId
        },
        {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
    )


    if (response.data.Status) {
        return response.data.Data
    }

    return []
}
