import React, { Component } from 'react'
import { connect } from 'react-redux'
import apiConfig from '../../../../apis/apiConfig'
import { translationHook } from '../../../translationHook'
import { Row, Col } from 'react-bootstrap'
import FilesUpload from '../../../UI/FileUpload/UploadFile'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../../actions'
import { downloadRequestAttachment } from '../../../../apis/requests/info/downloadRequestAttachment'



class ReportApproving extends Component {

    state = {
        filesData: [],
    }

    getRequestReportFiles = async () => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_INSPECTION_REPORT_FILES_LIST'
         
     
            const response = await apiConfig.post(
            url,
                {
                    Request_Id: this.props.requestId,
                    Request_Task_Id: this.props.requestTaskId
                },
                {
                    headers: {
                        Authorization: `Bearer ${at}`
                    },
                }
            )


            if (response.data.Status) {
                this.setState({ filesData: response.data.Data })
            }
    }    
    

    approveRequestTaskInspectionFile = async ({ File_Code }) => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/APPROVING_REQUEST_TASK_INSPECTION_REPORT_FILE'
         
     
            const response = await apiConfig.post(
            url,
                {
                    Request_Id: this.props.requestId,
                    Request_Task_Id: this.props.requestTaskId,
                    File_Code
                },
                {
                    headers: {
                        Authorization: `Bearer ${at}`
                    },
                }
            )

            if ( response.data.Status ) {
                this.setState({ filesData : response.data.Data });
            }
    }

    unApproveRequestTaskInspectionFile = async ({ File_Code }) => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/UNAPPROVING_REQUEST_TASK_INSPECTION_REPORT_FILE'
         
     
            const response = await apiConfig.post(
            url,
                {
                    Request_Id: this.props.requestId,
                    Request_Task_Id: this.props.requestTaskId,
                    File_Code
                },
                {
                    headers: {
                        Authorization: `Bearer ${at}`
                    },
                }
            )

            if ( response.data.Status ) {
                this.setState({ filesData : response.data.Data });
            }
    }

    deleteRequestTaskReportFile = async (file) => {
        let at = this.props.currentUser.Data.access_token,
            url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DELETE_REQUEST_TASK_INSPECTION_REPORT_FILE'
         
     
            await apiConfig.post(
            url,
                {
                    Request_Id: this.props.requestId,
                    Request_Task_Id: this.props.requestTaskId,
                    File_Code: file.File_Code
                },
                {
                    headers: {
                        Authorization: `Bearer ${at}`
                    },
                }
            ).then((result) => {
                if (result.data.Status) {
                    const updatedFilesList = this.state.filesData.filter((f) => {
                      return file.File_Code !== f.File_Code
                    })

                    this.setState({filesData: updatedFilesList})
                }
            })
    }

    componentDidMount() {
        this.getRequestReportFiles()
    }
    


    render() {
     
        return (
            <>
            { this.state.filesData && this.state.filesData.length > 0 &&
            <Row>
                <Col xs={12}>
                    <h4 className="my-3">{ this.props.t('reportsList') }</h4>
                </Col>
                <Col xs={12} md={6}>
                    <FilesUpload downloadCB={downloadRequestAttachment} view={true} attachments={[{Files_Data: this.state.filesData}]} deleteCB={this.deleteRequestTaskReportFile} approveCB={this.approveRequestTaskInspectionFile} unApproveCB={this.unApproveRequestTaskInspectionFile} />
                </Col>
            </Row>
            }
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(ReportApproving))
