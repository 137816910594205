import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Switches, Select } from "mui-rff";
import { Link } from "@reach/router";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { translationHook } from "../translationHook";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../actions";
import apiConfig from "../../apis/apiConfig";
import { ButtonPrimary, ButtonSecondary } from "./../UI/Buttons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Avatar from "@material-ui/core/Avatar";
import { getProfileData } from "../../apis/profile";
import { downloadAttachment } from "../../apis/system/downloadAttachment";
import { deleteAttachment } from "../../apis/system/deleteAttachment";
import AvatarImg from "../../images/avatar.png";

import invisible from "./../../images/login/svgs/invisible.svg";
import visible from "./../../images/login/svgs/visible.svg";
import { Button, InputAdornment, IconButton } from "@material-ui/core";
import FilesUpload from "../UI/FileUpload/UploadFile";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langList: [],
      imgUrl: AvatarImg,
      activeDir: false,
      showPassForm: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      initVal: {
        User_Name_AR: "",
        User_Name_EN: "",
        User_Phone: "",
        User_Mail: "",
        Login_Name: "",
        User_Group_Id: "",
        Lang_Id: "",
        Department_Id: "",
        Department_Job_Id: "",
        lang: "",
        userStatus: true,
      },
      filesData: [],
      userSignatureFile: {},
    };
  }

  getLang = async () => {
    let getLangUsersArr = [],
      at = this.props.currentUser.Data.access_token;
    const response = await apiConfig.post(
      "/API/SYSTEM//DDL/GET_LANGUAGUES",
      {},
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;
        getLangUsersArr.push({ value: item.Record_Id, label });
      });
      getLangUsersArr.unshift({ value: "", label: this.props.t("delete") });

      this.setState({ langList: getLangUsersArr });
    }
  };

  getProfileData = async () => {
    this.props.setLoading(true, "dark");

    const res = await getProfileData(this.props.currentUser.Data.access_token);
    if (res.Status) {
      this.setState({
        initVal: res?.Data,
        activeDir: res?.Data?.AD_Ref ? true : false,
        userSignatureFile: res?.Data?.User_Signature_File,
      });
      if (
        res?.Data?.User_Signature_File &&
        Object.keys(res?.Data?.User_Signature_File).length !== 0
      ) {
        this.setState({ filesData: [res?.Data?.User_Signature_File] });
      }
    }
    this.props.setLoading(false);
  };

  onUserSubmit = async (values, form) => {
    const at = this.props.currentUser.Data.access_token,
      url = this.state.showPassForm
        ? "API/SYSTEM/USERS/PROFILES/CHANGE_PASSWORD"
        : "/API/SYSTEM/USERS/PROFILES/UPD_USER_PROFILE_DATA",
      bodyData = this.state.showPassForm
        ? {
            Current_Password: values.Current_Password,
            New_Password: values.New_Password,
          }
        : {
            User_Name_AR: values.User_Name_AR,
            User_Name_EN: values.User_Name_EN,
            User_Phone: values.User_Phone,
            User_Mail: values.User_Mail,
            Login_Name: values.Login_Name,
            Lang_Id: values.Lang_Id,
          };
    this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

    const response = await apiConfig
      .post(url, bodyData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .then((res) => {
        this.props.setLoading(false);
        this.props.triggerNotification(true, res.data);
        return res;
      })
      .catch((err) => {
        this.props.setLoading(false);
        this.props.triggerNotification(true, err.data);
        return err;
      });
    // if new user add user image
    if (this.state.file) {
      const bodyFormData = new FormData();
      bodyFormData.append("Profile_Image_File", this.state.file);

      const responseImg = await apiConfig.post(
        "/API/SYSTEM/USERS/PROFILES/UPD_PROFILE_IMAGE",
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      );
    }
    if (this.state.showPassForm) {
      form.restart();
    }
    if (response.Status) {
      this.props.triggerNotification(true, response.data);
      this.props.setLoading(false);
    }
  };

  handleUploadClick = (files) => {
    let file = files[0];
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);
    reader.onload = (e) => {
      this.setState({
        imgUrl: reader.result,
        file,
      });
    };
  };

  deleteAttachment = async ({ File_Code }) => {
    let at = this.props.currentUser.Data.access_token,
      url = "/API/SYSTEM/USERS/DEL_USER_SIGNATURE_IMAGE";

    const response = await apiConfig.post(
      url,
      {
        User_Account_Id: this.props.currentUser.Data.User_Account_Id,
        User_Signature_File_Code: File_Code,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      this.getProfileData();
    }

    return response;
  };

  componentDidMount() {
    this.getLang();
    this.getProfileData();
  }

  render() {
    const validate = (values) => {
      const errors = {};
      if (this.state.showPassForm) {
        if (!values.Current_Password) {
          errors.Current_Password = `${this.props.t("error.field_required")}`;
        }
        if (!values.New_Password) {
          errors.New_Password = `${this.props.t("error.field_required")}`;
        }
        if (!values.confirmPassword) {
          errors.confirmPassword = `${this.props.t("error.field_required")}`;
        }
        if (values.confirmPassword != values.New_Password) {
          errors.confirmPassword = `${this.props.t("error.field_notMatch")}`;
        }
      }
      return errors;
    };

    return (
      <>
        <Row>
          <Col md={{ span: 2, offset: 10 }}>
            <ButtonSecondary
              variant="outlined"
              endIcon={<ExitToAppIcon />}
              component={Link}
              to="/"
              size="large"
            >
              {this.props.t("exit")}
            </ButtonSecondary>
          </Col>
        </Row>

        <Form
          onSubmit={this.onUserSubmit}
          initialValues={this.state.initVal}
          enableReinitialize={true}
          validate={validate}
          render={({ handleSubmit, form, submitting, handleReset }) => {
            return (
              <form
                id="profileForm"
                className="row"
                onSubmit={handleSubmit}
                onReset={handleReset}
                noValidate
              >
                <Col xs={12} md={3}>
                  <div className="d-flex justify-content-start align-items-center flex-column ">
                    <Field name="slider">
                      {({ input: { value, onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={[
                              "upload-avatar mx-3 mb-5",
                              !this.props.viewMood &&
                              meta.submitFailed &&
                              meta.invalid
                                ? "error-avatar"
                                : null,
                            ].join(" ")}
                          >
                            <Avatar
                              src={
                                this.state.initVal?.User_Image
                                  ? this.state.initVal.User_Image
                                  : this.state.imgUrl
                              }
                            />
                          </div>

                          {!this.props.viewMood && (
                            <ButtonSecondary
                              color="primary"
                              variant="contained"
                              endIcon={<CloudUploadIcon />}
                            >
                              {this.props.t("userAvatarButton")}
                              <input
                                {...input}
                                type="file"
                                className="buttonFile"
                                accept="image/*"
                                onChange={({ target }) => {
                                  onChange(target.files);
                                  this.handleUploadClick(target.files);
                                }}
                              />
                            </ButtonSecondary>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </Col>

                {this.state.showPassForm && (
                  <Col xs={12} md={9} className="m-auto">
                    <Row>
                      <Col xs={12} md={7}>
                        <TextField
                          label={this.props.t("oldPassword")}
                          className={`mb-2`}
                          name="Current_Password"
                          type={
                            this.state.showCurrentPassword ? "text" : "password"
                          }
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      showCurrentPassword:
                                        !this.state.showCurrentPassword,
                                    });
                                  }}
                                >
                                  <img
                                    style={{ width: "1.3rem" }}
                                    src={
                                      this.state.showCurrentPassword
                                        ? visible
                                        : invisible
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                      <Col xs={12} md={7}>
                        <TextField
                          label={this.props.t("newPassword")}
                          className={`mb-2`}
                          name="New_Password"
                          type={
                            this.state.showNewPassword ? "text" : "password"
                          }
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      showNewPassword:
                                        !this.state.showNewPassword,
                                    });
                                  }}
                                >
                                  <img
                                    style={{ width: "1.3rem" }}
                                    src={
                                      this.state.showNewPassword
                                        ? visible
                                        : invisible
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                      <Col xs={12} md={7}>
                        <TextField
                          label={this.props.t("confirmPassword")}
                          className={`mb-2`}
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? "text" : "password"
                          }
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      showConfirmPassword:
                                        !this.state.showConfirmPassword,
                                    });
                                  }}
                                >
                                  <img
                                    style={{ width: "1.3rem" }}
                                    src={
                                      this.state.showConfirmPassword
                                        ? visible
                                        : invisible
                                    }
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}

                {!this.state.showPassForm && (
                  <Col xs={12} md={9} className="m-auto">
                    <Row>
                      <Col xs={12} md={6}>
                        <TextField
                          label={this.props.t("userNameAR")}
                          className={`mb-2`}
                          name="User_Name_AR"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={6}>
                        <TextField
                          label={this.props.t("userNameEN")}
                          className={`mb-2`}
                          name="User_Name_EN"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={6}>
                        <TextField
                          disabled
                          label={this.props.t("loginName")}
                          className="read-only mb-2"
                          name="Login_Name"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={6}>
                        <TextField
                          disabled={this.state.activeDir}
                          label={this.props.t("mail")}
                          className={`${
                            this.state.activeDir ? "read-only" : ""
                          } mb-2`}
                          name="User_Mail"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={6}>
                        <TextField
                          label={this.props.t("phone")}
                          className={`mb-2`}
                          name="User_Phone"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={6}>
                        <Select
                          label={this.props.t("language")}
                          className={`mb-2`}
                          required={true}
                          name="Lang_Id"
                          data={this.state.langList}
                          showError={() => {
                            let fieldStatus = form.getFieldState("Lang_Id");

                            if (fieldStatus) {
                              return (
                                fieldStatus.submitFailed && fieldStatus.invalid
                              );
                            }
                          }}
                        ></Select>
                      </Col>

                      <Col xs={12} md={8} className="mb-2">
                        {!this.props.viewMood && (
                          <h5 className="my-4 text-start">
                            {this.props.t("attachments")}
                          </h5>
                        )}

                        {this.props.viewMood &&
                          this.state.filesData.length > 0 && (
                            <h5 className="my-4 text-start">
                              {this.props.t("attachments")}
                            </h5>
                          )}

                        <FilesUpload
                          at={this.props.currentUser.Data.access_token}
                          endPoint="API/SYSTEM/USERS/UPD_USER_SIGNATURE_IMAGE"
                          params={{
                            User_Account_Id:
                              this.props.currentUser.Data.User_Account_Id,
                          }}
                          filesData={this.state.filesData}
                          fileApiKey="User_Signature_Image_File"
                          single={true}
                          deleteCB={this.deleteAttachment}
                          deleteParams={{}}
                          downloadCB={downloadAttachment}
                          handleSetFilesData={this.getProfileData}
                          view={this.props.viewMood}
                        />

                        <div className="my-3 text-danger">
                          <p>{this.props.t("attachNotes")}:</p>
                          <div>{this.props.t("imgType")}</div>
                          <div>{this.props.t("imgBg")}</div>
                          <div>{this.props.t("imgSize")}</div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )}

                <Col xs={9}>
                  <Row className="align-items-center">
                    <Col className="mt-2 justify-content-end d-flex">
                      {!this.props.viewMood && (
                        <ButtonPrimary
                          color="primary"
                          variant="contained"
                          disabled={submitting || this.props.viewMood}
                          type="submit"
                        >
                          {this.props.t("save")}
                        </ButtonPrimary>
                      )}

                      {!this.state.activeDir && (
                        <ButtonPrimary
                          color="primary"
                          variant="contained"
                          disabled={submitting || this.props.viewMood}
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              showPassForm: !this.state.showPassForm,
                            });
                          }}
                        >
                          {!this.state.showPassForm
                            ? this.props.t("changePass")
                            : this.props.t("mainData")}
                        </ButtonPrimary>
                      )}
                    </Col>
                  </Row>
                </Col>
              </form>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, { triggerNotification, setLoading })(
  translationHook(Profile)
);
