import React from "react";

import ReturnStep from "./ReturnStep";
import Notes from "../Notes";
import InputChangesEditList from "./InputChangesEditList";
import {
	returnRequestEditData,
	returnRequestAddData,
	returnRequestStep,
} from "../../../../apis/requests/processTask";

export const ReturnToEdit = (props) => {
	return (
		<>
			<InputChangesEditList
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
			/>

			<Notes
				required={false}
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				onTaskSubmit={returnRequestEditData}
			/>
		</>
	);
};

export const ReturnToAdd = (props) => {
	return (
		<Notes
			required={true}
			requestId={props.requestId}
			requestTaskId={props.requestTaskId}
			tempId={props.tempId}
			onTaskSubmit={returnRequestAddData}
		/>
	);
};

export const ReturnRequestStep = (props) => {
	return (
		<>
			<ReturnStep
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				task={props.task}
			/>

			<Notes
				requestId={props.requestId}
				requestTaskId={props.requestTaskId}
				tempId={props.tempId}
				onTaskSubmit={returnRequestStep}
			/>
		</>
	);
};
