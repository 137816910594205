import apiConfig from '../apiConfig';

export const getTemplatesApisVariablesList = async (at, id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/TEMPLATESAPI/GET_TEMPLATES_API_VARIABLES_LIST', {
            Template_API_Id: id
        }, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    return response.data.Data;
};