import apiConfig from '../apiConfig'

export const downloadAttachment = async ({at, File_Code}) => {

	const response = await apiConfig.post(
		'API/SYSTEM/GENERAL/DOWNLOAD_ATTACHMENT',
		{
			File_Code: File_Code
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
			responseType: 'arraybuffer',
        },
	)

	return response;
};