import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonDanger,
} from "../../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Select, Autocomplete } from "mui-rff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Avatar from "@material-ui/core/Avatar";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../../../actions";
import InvoiceList from "./InvoiceList";
import { withStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  Column,
  Button,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  buttonRoot: {
    minWidth: "90px !important",
    padding: "0.5rem 1rem !important",
  },
});

class InvoiceForm extends Component {
  state = {
    invoiceTypes: [],
    invoiceItems: [],
    imgUrl: "",
    file: "",
    invoiceList: [],
    autoCompleteVal: "",
    showForm: false,
  };

  handleUploadClick = (event) => {
    let file = event.target.files[0];

    const reader = new FileReader();
    let url = file ? reader.readAsDataURL(file) : "";
    reader.onloadend = (e) => {
      this.setState({
        imgUrl: reader.result,
        file,
      });
    };
  };

  getInvoiceTypes = async () => {
    let typesArr = [],
      at = this.props.currentUser.Data.access_token;

    const response = await apiConfig.post(
      "API/ADMINISTRATION/REQUESTS/MANAGEMENT/Get_Invoices_Types_List",
      {
        Request_Id: this.props.requestId,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Invoice_Type_Name_AR
            : item.Invoice_Type_Name_EN;
        let itemCategory =
          this.props.i18n.language === "ar"
            ? item.Invoice_Type_Category_Name_AR
            : item.Invoice_Type_Category_Name_EN;
        let itemPrice = item.Invoice_Type_Value;
        let itemNo = item.Invoice_Type_No;

        typesArr.push({
          value: item.Invoice_Type_Id,
          label,
          itemCategory,
          itemPrice,
          itemNo,
        });
      });

      this.setState({ invoiceTypes: typesArr });
    }
  };

  onInvoiceSubmit = async (form) => {
    const bodyFormData = new FormData(),
      at = this.props.currentUser.Data.access_token,
      url = "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/ADD_REQUEST_TASK_INVOICE",
      invoicesList = this.state.invoiceItems.map((i) => {
        return {
          Invoice_Type_Id: i.value,
          Invoice_No: i.itemNo,
          Invoice_Type_Value: i.itemPrice,
        };
      }),
      bodyData = {
        Invoice_Items_List: invoicesList,
        Request_Id: this.props.requestId,
        Request_Task_Id: this.props.requestTaskId,
        Temp_Action_Id: this.props.tempId,
      };

    bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

    bodyFormData.append("Attached_File", this.state.file);

    this.props.setLoading(true, "dark");

    const response = await apiConfig
      .post(url, bodyFormData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .catch((error) => {
        return error.response;
      });

    this.props.triggerNotification(true, response.data);

    this.props.setLoading(false);

    if (response.data.Status) {
      form.restart();
      this.setState({
        invoiceList: response.data.Data,
        imgUrl: "",
        file: "",
        showForm: false,
        invoiceItems: [],
      });
      this.props.toggleNotes(true);
    }
  };

  onInvoiceItemSubmit = async (values, form) => {
    if (this.state.invoiceItems.length < 12) {
      let item = this.state.invoiceTypes.find(
        (i) => i.label === values.invoiceItemType
      );
      item = {
        ...item,
        itemPrice: values.invoiceItemValue,
      };
      this.setState({ invoiceItems: [...this.state.invoiceItems, item] });
      form.restart();
    } else {
      this.props.triggerNotification(true, {
        Status: false,
        message: this.props.t("addNewItemValidatorMsg"),
      });
    }
  };

  onInvoiceDeleteItem = (data) => {
    let items = this.state.invoiceItems.filter((i) => i.itemNo !== data.itemNo);
    this.setState({ invoiceItems: items });
  };

  reloadInvoiceList = (invoiceNo) => {
    const updatedList = this.state.invoiceList.filter((invoice) => {
      return invoice.Invoice_No !== invoiceNo;
    });

    this.setState({ invoiceList: updatedList });
  };

  componentDidMount() {
    this.getInvoiceTypes();
  }

  render() {
    let { initValues } = this.props;

    const validate = (values) => {
      const errors = {};

      if (!values.invoiceItemType) {
        errors.invoiceItemType = `${this.props.t("error.field_required")}`;
      }

      // if (!values.invoiceNo) {
      //     errors.invoiceNo = `${this.props.t('error.field_required')}`
      // }

      if (!values.invoiceItemValue) {
        errors.invoiceItemValue = `${this.props.t("error.field_required")}`;
      }

      return errors;
    };

    return (
      <>
        <div className="d-flex justify-content-start mb-3">
          <ButtonPrimary
            variant={"contained"}
            onClick={() => {
              this.setState({ showForm: true });
              this.props.toggleNotes(false);
            }}
          >
            {this.props.t("addNewInvoice")}
            {/* {this.state.showForm && this.props.t('cancel')} */}
          </ButtonPrimary>
        </div>
        <Form
          onSubmit={this.onInvoiceItemSubmit}
          initialValues={{
            invoiceItemType: "",
            invoiceItemValue: "",
          }}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              id="invoiceFrom"
              className="row"
              onSubmit={handleSubmit}
              noValidate
            >
              <Col xs={12}>
                <Row
                  className={`${
                    !this.state.showForm &&
                    !this.props.editMood &&
                    !this.props.viewMood
                      ? "d-none"
                      : "d-flex align-items-center"
                  }`}
                >
                  <Col xs={12} md={8}>
                    <Row className="d-flex align-items-center">
                      <Col xs={12} md={4}>
                        <Autocomplete
                          label={this.props.t("invoiceItemType")}
                          className="mb-2"
                          name="invoiceItemType"
                          required={true}
                          options={this.state.invoiceTypes}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                          inputValue={values.invoiceItemType}
                          onChange={(e, v) => {
                            // this.setState({autoCompleteVal: v ? v.label : ""})
                            form.change("invoiceItemType", v ? v.label : "");
                            form.change(
                              "invoiceItemValue",
                              v ? v.itemPrice.toString() : ""
                            );
                            
                          }}
                          onInputChange={(e, v) => {
                            form.change("invoiceItemType", v);
                          }}
                          showError={() => {
                            let fieldStatus =
                              form.getFieldState("invoiceItemType");

                            if (fieldStatus) {
                              return (
                                fieldStatus.submitFailed && fieldStatus.invalid
                              );
                            }
                          }}
                        ></Autocomplete>
                      </Col>

                      {/* <Col xs={12} md={6}>
                                        <TextField
                                            label={this.props.t('invoiceNo')}
                                            className="mb-2"
                                            name="invoiceNo"
                                            autoComplete="off"
                                            required={true}
                                            showError={({ meta: { submitFailed, invalid } }) => { 
                                                return submitFailed && invalid 
                                            } }
                                        />
                                    </Col> */}

                      <Col xs={12} md={4}>
                        <TextField
                          label={this.props.t("invoiceItemValue")}
                          className="mb-2"
                          name="invoiceItemValue"
                          autoComplete="off"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <Row>
                          <ButtonPrimary
                            color="primary"
                            variant="contained"
                            size="small"
                            classes={{
                              root: this.props.classes.buttonRoot,
                            }}
                            disabled={submitting}
                            onClick={handleSubmit}
                          >
                            {this.props.t("addNewItem")}
                          </ButtonPrimary>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    md={4}
                    className="d-flex justify-content-start align-items-center ServiceAvatarContainer mb-3"
                  >
                    <div className="upload-avatar my-3">
                      <Avatar
                        src={
                          this.state.imgUrl
                            ? this.state.imgUrl
                            : "/broken-image.jpg"
                        }
                      />
                    </div>
                    <ButtonSecondary
                      color="primary"
                      variant="contained"
                      endIcon={<CloudUploadIcon />}
                    >
                      {this.state.file
                        ? this.state.file.name
                        : this.props.t("invoiceAttachment")}
                      <input
                        type="file"
                        name="invoiceAttachment"
                        className="buttonFile"
                        onChange={this.handleUploadClick}
                      />
                    </ButtonSecondary>
                  </Col>
                </Row>
              </Col>

              {this.state.showForm ? (
                <>
                  <DataGrid
                    className={[
                      this.props.i18n.language === "ar"
                        ? "dx-grid-ar"
                        : "dx-grid-en",
                      "globalBox",
                    ].join(" ")}
                    id={`dataGrid_${this.props.key}`}
                    width={"100%"}
                    keyExpr="itemNo"
                    dataSource={this.state.invoiceItems}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    allowColumnReordering={true}
                    rowAlternationEnabled={false}
                    focusedRowEnabled={true}
                    columnWidth={"auto"}
                    wordWrapEnabled={true}
                    rtlEnabled={
                      this.props.i18n.language === "ar" ? true : false
                    }
                  >
                    <Selection
                      mode="multiple"
                      selectAllMode={"allPages"}
                      showCheckBoxesMode={"always"}
                    />

                    <Column
                      dataField="itemNo"
                      caption={this.props.t("invoiceItemNumber")}
                      alignment={"center"}
                    ></Column>

                    <Column
                      dataField={"itemCategory"}
                      caption={this.props.t("invoiceItemCategory")}
                      alignment={"center"}
                    ></Column>

                    {/* <Column dataField="Invoice_Date" caption={this.props.t('invoiceDate')} alignment={"center"} format="yyyy-MM-dd">
                                </Column> */}

                    <Column
                      dataField={"label"}
                      caption={this.props.t("invoiceItemType")}
                      alignment={"center"}
                    ></Column>

                    <Column
                      dataField="itemPrice"
                      caption={this.props.t("invoiceItemValue")}
                      alignment={"center"}
                    ></Column>

                    <Summary>
                      <TotalItem
                        column="itemPrice"
                        summaryType="sum"
                        showInColumn="itemPrice"
                        alignment="center"
                        displayFormat={`${this.props.t("totalValue")} : {0}`}
                      />
                    </Summary>

                    <Column
                      cssClass="outlinedColumn nowrapColumn"
                      type="buttons"
                      caption={this.props.t("delete")}
                      fixed={true}
                      fixedPosition={
                        this.props.i18n.language === "ar" ? "left" : "right"
                      }
                    >
                      <Button
                        render={(record) => {
                          return (
                            <ButtonDanger
                              variant="circleIcon"
                              startIcon={<DeleteIcon />}
                              onClick={() =>
                                this.onInvoiceDeleteItem(record.data)
                              }
                            ></ButtonDanger>
                          );
                        }}
                      />
                    </Column>
                  </DataGrid>
                  <div className="d-flex row justify-content-end w-100 my-5">
                    <ButtonPrimary
                      color="primary"
                      variant="contained"
                      size="small"
                      classes={{
                        root: this.props.classes.buttonRoot,
                      }}
                      onClick={() => this.onInvoiceSubmit(form)}
                      // disabled={submitting}
                      // type="submit"
                    >
                      {this.props.t("saveInvoice")}
                    </ButtonPrimary>
                    <ButtonPrimary
                      color="primary"
                      variant="outlined"
                      size="small"
                      classes={{
                        root: this.props.classes.buttonRoot,
                      }}
                      onClick={() => {
                        this.setState({
                          showForm: !this.state.showForm,
                          invoiceItems: [],
                        });
                        this.props.toggleNotes(true);
                      }}
                    >
                      {this.props.t("cancel")}
                    </ButtonPrimary>
                  </div>
                </>
              ) : null}
            </form>
          )}
        />

        {!this.state.showForm ? (
          <InvoiceList
            invoices={this.state.invoiceList}
            requestId={this.props.requestId}
            requestTaskId={this.props.requestTaskId}
            deleteCB={this.reloadInvoiceList}
            showDialog={true}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(withStyles(styles)(InvoiceForm)));
