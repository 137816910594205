import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
// import the react-json-view component
import ReactJson from 'react-json-view'
class SupportRequestForm extends Component {

    state = {
        showForm: false,
        showClerkNote: false,
        showHandleBtn: true,
        saveBtn: false,
        closeBtn: false
    }

    onSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = this.state.saveBtn ? '/API/ADMINISTRATION/SUPPORTREQUEST/SAVE_SUPPORT_REQUEST_NOTES' : '/API/ADMINISTRATION/SUPPORTREQUEST/CLOSE_SUPPORT_REQUEST',
        
        bodyData = {
            Support_Request_Id: this.props.supportRequestId,
            Process_Result_Notes: values.processResultNotes
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
        }

        // if (this.props.editMood) {
        //     this.props.triggerDialogForm(false)
        // }

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)

        this.props.triggerDialogForm(false)

        if (response.data.Status) {
            form.restart()
        }


        if ( this.props.editMood ) {
            this.props.triggerView(false, false)
        }
    }

    handleRequest = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = '/API/ADMINISTRATION/SUPPORTREQUEST/ASSIGN_SUPPORT_REQUEST',
        bodyData = {
            Support_Request_Id: this.props.supportRequestId
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)

        this.setState({
            showClerkNote: true,
            showHandleBtn: false
        })
    }

    render() {
        let { initValues } = this.props;
        // console.log(beautify(initValues?.errorRequestPostedData, null, 2, 180))

        const validate = values => {
            const errors = {}
            if (!values.processResultNotes) {
                errors.processResultNotes = `${this.props.t('error.field_required')}`
            }
            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('supportRequest')}</h3>
                    </div>
                }
                {this.state.showForm || this.props.editMood || this.props.viewMood ? 
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={initValues ? initValues : {}}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled                                    
                                    label={this.props.t('supportRequestNo')}
                                    className="mb-2"
                                    name="supportRequestNo"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled              
                                    label={this.props.t('supportRequestType')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="supportRequestType"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('clientNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('clientNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientNameAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={12}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('clientNotes')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientNotes"
                                    autoComplete="off"
                                    multiline
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            {this.props.i18n.language === 'ar' ? 
                            <>
                                <Col xs={12} md={6}>
                                    <TextField
                                        disabled={this.props.viewMood}
                                        label={this.props.t('DepartmentNameAr')}
                                        className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                        name="departmentNameAR"
                                        autoComplete="off"
                                        showError={({ meta: { submitFailed, invalid } }) => {
                                            return submitFailed && invalid
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('serviceNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNameAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('supportRequestStatusAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="requestStatusNameAR"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            </>
                            :
                            <>
                             <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('DepartmentNameEn')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="departmentNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('serviceNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('supportRequestStatusEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="requestStatusNameEN"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            </>
                            }
                            <Col xs={12} md={4}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('pageURL')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="pageURL"
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={12}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('errorRequestURL')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="errorRequestURL"
                                    autoComplete="off"
                                    multiline
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            {/* <Col xs={12} md={12}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('pageInputsData')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="pageInputsData"
                                    autoComplete="off"
                                    multiline
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col> */}
                            <Col xs={12} md={{span: 6, offset: 6}}>
                                {typeof JSON.parse(initValues.pageInputsData) === 'object' ? 
                                <>
                                    <div className="MuiFormControl-root MuiTextField-root read-only mb-2 MuiFormControl-fullWidth">
                                    <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled" data-shrink="true">{this.props.t('pageInputsData')}</label>
                                    </div>
                                    <ReactJson collapsed={1} src={JSON.parse(initValues.pageInputsData)} />
                                </>
                                :
                                    <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('pageInputsData')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="pageInputsData"
                                    autoComplete="off"
                                    multiline
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                                }
                            </Col>
                            <Col xs={12} md={{span: 6, offset: 6}}>
                                <div className="MuiFormControl-root MuiTextField-root read-only mb-2 MuiFormControl-fullWidth">
                                <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled" data-shrink="true">{this.props.t('errorRequestPostedData')}</label>
                                </div>       
                                <ReactJson collapsed={1} src={JSON.parse(initValues.errorRequestPostedData)} />
                            </Col>
                            <Col xs={12} md={{span: 6, offset: 6}}>
                                {typeof JSON.parse(initValues.errorRequestResponseData) === 'object' ? 
                                <>
                                    <div className="MuiFormControl-root MuiTextField-root read-only mb-2 MuiFormControl-fullWidth">
                                    <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-disabled Mui-disabled MuiFormLabel-filled" data-shrink="true">{this.props.t('errorRequestResponseData')}</label>
                                    </div>
                                    <ReactJson collapsed={1} src={JSON.parse(initValues.errorRequestResponseData)} />
                                </>
                                : 
                                <TextField
                                disabled={this.props.viewMood}
                                label={this.props.t('errorRequestResponseData')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                name="pageInputsData"
                                autoComplete="off"
                                multiline
                                showError={({ meta: { submitFailed, invalid } }) => {
                                    return submitFailed && invalid
                                }}
                            />
                                }
                            </Col>
                            
                            {this.props.initValues?.requestStatusId == 1 && this.state.showHandleBtn &&
                                <Col className='my-4 justify-content-start d-flex' xs={12}>
                                    <ButtonPrimary
                                        color="primary"
                                        variant='contained'
                                        disabled={submitting}
                                        onClick={this.handleRequest}
                                    >
                                        {this.props.t('handleRequest')}
                                    </ButtonPrimary>
                                </Col>
                            }

                            {this.props.initValues?.requestStatusId == 2 || 
                                this.props.initValues?.requestStatusId == 3 ||
                                this.state.showClerkNote ?
                                <>
                                    <Col xs={12} md={6}>
                                        <TextField
                                            disabled={this.props.initValues?.requestStatusId == 3}
                                            label={this.props.t('processResultNotes')}
                                            className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                            name="processResultNotes"
                                            autoComplete="off"
                                            multiline
                                            showError={({ meta: { submitFailed, invalid } }) => {
                                                return submitFailed && invalid
                                            }}
                                        />
                                    </Col>
                                </>
                            : null}

                            {this.props.initValues?.requestStatusId == 2 || this.state.showClerkNote ?
                                <>        
                                    <Col className='my-4 justify-content-start d-flex' xs={12}>
                                        <ButtonPrimary
                                            color="primary"
                                            variant="contained"
                                            disabled={submitting}
                                            type="submit"
                                            onClick={() => this.setState({
                                                saveBtn: true,
                                                closeBtn: false
                                            })}
                                        >
                                            {this.props.t('save')}
                                        </ButtonPrimary>

                                        <ButtonPrimary
                                            color="primary"
                                            variant='contained'
                                            disabled={submitting}
                                            type="submit"
                                            onClick={() => this.setState({
                                                saveBtn: false,
                                                closeBtn: true
                                            })}
                                        >
                                            {this.props.t('closeRequest')}
                                        </ButtonPrimary>
                                    </Col>
                                </>
                            : null}
                        </form>
                    )}
                />
                : ""}
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(SupportRequestForm))
