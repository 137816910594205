import apiConfig from "../apiConfig";

export const deleteServiceTemplate = async (at, Service_Id, Template_Id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/SERVICES/DEL_SERVICE_TEMPLATE",
		{
			Service_Id,
			Template_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response.data;
};
