import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";

import { translationHook } from "../../translationHook";
import {
  triggerNotification,
  triggerDialogForm,
  setLoading,
} from "../../../actions";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import style from "../processing/Processing.module.scss";
import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
import { getRequestInputChangesList } from "../../../apis/requests/getRequestInputChangesList";
import InputChangesList from "./InputChangesList";

import moment from "moment";
import DialogForm from "../../UI/DialogForm";

class RequestInfo extends Component {
  state = {
    showDialog: false,
    dialogData: null,
  };

  onServiceInfoSubmit = async (values, form) => {
    return null;
  };

  renderChangesList = (...params) => {
    return (
      <div className="p-3">
        <InputChangesList
          changesList={params[0]}
          isInputAttachment={params[1]}
        />
      </div>
    );
  };

  handleGetRequestInputChangesList = async (
    Request_Id,
    Department_Service_Detail_Id
  ) => {
    let changesList;
    this.props.setLoading(true, "dark");

    await getRequestInputChangesList(this.props.currentUser.Data.access_token, {
      Request_Id,
      Department_Service_Detail_Id,
    })
      .then((res) => {
        changesList = res.data.Data;
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.triggerNotification(true, error.response);
        return;
      });

    this.props.setLoading(false);
    return changesList;
  };

  render() {
    let { info, data, attachments } = this.props,
      dataElements = [];

    if (!info) {
      return <h6>{this.props.t("loadingClientData")}</h6>;
    }

    if (data) {
      data.forEach((row) => {
        dataElements.push(
          <Col
            xs={12}
            md={row.Department_Service_Detail_Type_Id === 4 ? 12 : 6}
            key={row.Department_Service_Detail_No}
          >
            <div className={style.advisorNumberCheckColumn}>
              {Boolean(row.Service_Detail_Changed) && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  role="button"
                  href="#"
                  className={
                    this.props.i18n.language === "ar"
                      ? style.labelActionAr
                      : style.labelActionEn
                  }
                  onClick={async (e) => {
                    e.preventDefault();

                    await this.handleGetRequestInputChangesList(
                      row.Request_Id,
                      row.Department_Service_Detail_Id
                    ).then((data) => {
                      if (data) {
                        // this.props.triggerDialogForm(
                        // 	true,
                        // 	{
                        // 		title: this.props.t(
                        // 			"viewChangesList"
                        // 		),
                        // 		renderForm:
                        // 			this
                        // 				.renderChangesList,
                        // 		params: [data, false],
                        // 	}
                        // );
                        this.setState({
                          showDialog: true,
                          dialogData: {
                            renderForm: this.renderChangesList,
                            params: [data, false],
                          },
                        });
                      }
                    });
                  }}
                >
                  {this.props.t("viewChangesList")}
                </a>
              )}
              <TextField
                disabled
                label={
                  this.props.i18n.language === "ar"
                    ? row.Department_Service_Detail_Title_AR
                    : row.Department_Service_Detail_Title_EN
                }
                className="read-only mb-4"
                name={`requestData_${row.Department_Service_Detail_No}`}
                multiline={
                  row.Department_Service_Detail_Type_Id === 4 ? true : false
                }
                value={
                  this.props.i18n.language === "ar"
                    ? row.Service_Detail_Value_AR
                    : row.Service_Detail_Value_EN
                }
              />
              {row.Info_Btn_Show === 1 &&
                this.props.requestInfoButtonCallback(
                  "showDetails",
                  row.Info_Btn_Id,

                  this.props.i18n.language === "ar"
                    ? `حساب ${row.Service_Detail_Value_AR}`
                    : `${row.Service_Detail_Value_EN} Acconut`
                )}
            </div>
          </Col>
        );
      });
    }

    return (
      <>
        {this.props.children && this.props.children}
        <Form
          onSubmit={this.onServiceInfoSubmit}
          initialValues={{
            requestNo: info.Request_No,
            requestDate: info.Request_Date
              ? info.Request_Date.slice(0, 10)
              : "",
            serviceName:
              this.props.i18n.language === "ar"
                ? info.Service_Name_AR
                : info.Service_Name_EN,
            serviceVersion: info.Department_Service_Version_Name,
            departmentName:
              this.props.i18n.language === "ar"
                ? info.Department_Name_AR
                : info.Department_Name_EN,
            requestApplicationTime: info.Ins_Time
              ? moment(info.Ins_Time).format("h:mm:ss, YYYY-MM-DD")
              : "",
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <>
              <form className="row" onSubmit={handleSubmit} noValidate>
                <Col xs={12}>
                  <h5 className="w-25 my-4">{this.props.t("requestInfo")}</h5>
                </Col>

                <Col xs={12} md={6}>
                  <TextField
                    disabled
                    label={this.props.t("serviceName")}
                    className="read-only mb-4"
                    name="serviceName"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <TextField
                    disabled
                    label={this.props.t("departmentName")}
                    className="read-only mb-4"
                    name="departmentName"
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    disabled
                    label={this.props.t("version")}
                    className="read-only mb-4"
                    name="serviceVersion"
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    disabled
                    label={this.props.t("requestNo")}
                    className="read-only mb-4"
                    name="requestNo"
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    disabled
                    label={this.props.t("requestDate")}
                    className="read-only mb-4"
                    name="requestDate"
                  />
                </Col>
                <Col xs={12} md={3}>
                  <TextField
                    disabled
                    label={this.props.t("requestApplicationTime")}
                    className="read-only mb-4"
                    name="requestApplicationTime"
                  />
                </Col>

                <Col xs={12}>
                  <h5 className="w-25 my-4">
                    {this.props.t("requestDetails")}
                  </h5>
                </Col>

                {dataElements}
              </form>

              <Row className={style.altWrap}>
                <Col xs={12} md={12}>
                  <h5 className="my-4">{this.props.t("requestAttachments")}</h5>
                  <FilesUpload
                    requestId={this.props.info.Request_Id}
                    downloadCB={downloadRequestAttachment}
                    view={true}
                    attachments={attachments}
                    chunk={true}
                    labelAction={this.handleGetRequestInputChangesList}
                    labelActionCB={(data) => {
                      this.props.triggerDialogForm(true, {
                        title: this.props.t("viewChangesList"),
                        renderForm: this.renderChangesList,
                        params: [data, true],
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        />
        <DialogForm
          custom={true}
          customDialogForm={{
            Open: this.state.showDialog || false,
            Data: this.state.dialogData,
            onClose: () => this.setState({ showDialog: false }),
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, {
  triggerNotification,
  triggerDialogForm,
  setLoading,
})(translationHook(RequestInfo));
