import React, { Component } from "react";
import { connect } from "react-redux";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../actions";
import { triggerDialog } from "../../actions";
import { translationHook } from "../translationHook";
import FilesUpload from "../UI/FileUpload/UploadFile";
import { deleteAttachment } from "../../apis/tasks/assignTasks";
import { Col } from "react-bootstrap";
import {
  getMissionAttachmentsList,
  getMissionAttachmentsResList,
} from "../../apis/tasks/assignTasks";

class TaskAttachments extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedFiles: undefined,
    progressInfos: { val: [] },
    filesData: [],
    filesDataRes: [],
  };
  componentDidMount() {
    (async () => {
      const res = await getMissionAttachmentsList(
        this.props.currentUser.Data.access_token,
        this.props.missionID
      );
      const arrFiles = [];
      if (res.data.Status) {
        res.data.Data?.map((item) => {
          const file = {
            File_Name: item.Mission_Attach_File_Name,
            Can_Delete: this.props.view ? 0 : 1,
            Mission_Attach_Id: item.Mission_Attach_Id,
            Mission_Attach_File: item.Mission_Attach_File,
          };
          arrFiles.push(file);
        });
        this.setState({
          filesData: arrFiles,
        });
      }
      const aattachres = await getMissionAttachmentsResList(
        this.props.currentUser.Data.access_token,
        this.props.missionID
      );
      if (aattachres.data.Status) {
        aattachres.data.Data?.map((item) => {
          const file = {
            File_Name:
              item.Mission_Attach_File_Name +
              this.props.t("taskAttachmentsResponse"),
            Can_Delete: this.props.view ? 0 : 1,
            Mission_Attach_Id: item.Mission_Attach_Id,
            Mission_Attach_File: item.Mission_Attach_File,
          };
          arrFiles.push(file);
        });
        this.setState({
          filesDataRes: aattachres,
        });
      }
    })();
  }

  render() {
    return (
      <>
        <Col md={6}>
          {!this.props.response ? (
            <>
              <h6 className="text-start mb-3">
                {this.props.t("taskAttachments")}
              </h6>
              <FilesUpload
                at={this.props.currentUser.Data.access_token}
                endPoint="API/ADMINISTRATION/MISSIONS/ADD_MISSION_ATTACHMENT"
                params={{ mission_Id: this.props.missionID }}
                deleteCB={deleteAttachment}
                fileApiKey="Mission_Attach_File"
                filesData={this.state.filesData}
                view={this.props.view}
              />
            </>
          ) : (
            <FilesUpload
              at={this.props.currentUser.Data.access_token}
              endPoint="API/ADMINISTRATION/MISSIONS/ADD_MISSION_ATTACHMENT"
              params={{ mission_Id: this.props.missionID }}
              deleteCB={deleteAttachment}
              fileApiKey="Mission_Attach_File"
              filesData={this.state.filesDataRes}
              view={this.props.view}
            />
          )}
        </Col>
      </>
    );
  }
}
// deleteParams={{"Mission_Attach_Id": props.requestId}}
// filesData={filesData}
// downloadCB={downloadRequestAttachment}
//
//
// ADMINISTRATION/MISSIONS/DEL_MISSION_ATTACHMENT
const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerDialog,
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(TaskAttachments));
