import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ButtonDanger, ButtonSecondary, ButtonInfo, ButtonWarning } from './../../UI/Buttons'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';


import { translationHook } from '../../translationHook'
import { getDropdownValuesList } from '../../../apis/services/dropdowns/getDropdownValuesList'
import { deleteServiceDropValue } from '../../../apis/services/dropdowns/delServiceDropValue'
import { triggerDialog, triggerDialogForm , setLoading, triggerNotification } from '../../../actions'
import ServiceDetailForm from './ServiceDetailForm'
import Switch from '@material-ui/core/Switch';
import apiConfig  from '../../../apis/apiConfig';


class ServiceDetail extends Component {
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
    state = {
        reload: false
    }
    customizeCellCheckbox = ({data}) => {
        return (
            <Switch
			checked={data.Active_Status_Id}
			name={`templateStatus_${data.Service_Category_Id}`}
			onChange={async () => {
				let at = this.props.currentUser.Data.access_token,
					url = '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSVALUESMANAGEMENT/Upd_Services_Lookups_Values',
					bodyData = {
						Service_Lookup_Value_Id: data.Service_Lookup_Value_Id,
						Service_Lookup_Id: data.Service_Lookup_Id,
						Service_Lookup_Value_Name_AR: data.Service_Lookup_Value_Name_AR,
						Service_Lookup_Value_Name_EN: data.Service_Lookup_Value_Name_EN,
						Active_Status_Id: data.Active_Status_Id ? 0 : 1
					};
					this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)
			
					const response = await apiConfig.post(
						url,
						bodyData,
						{
							headers: {
								Authorization: `Bearer ${at}`
							},
						}
					).catch((error) => {
						return error.response
					})
					this.props.triggerNotification(true, response.data)
					this.props.setLoading(false)
			}}
			/>
        )
    }

    handleDelete = (data) => {
        let at = this.props.currentUser.Data.access_token,
        serviceDetailid = data.Service_Lookup_Value_Id,
        serviceDetailName = this.props.i18n.language === 'ar' ? data.Service_Lookup_Value_Name_AR : data.Service_Lookup_Value_Name_EN,
        dialogTitle = `${this.props.t('confirmDeleteTitle')} ${serviceDetailName}`,
        dialogMsg = this.props.t('confirmDeleteMsg')

        this.props.triggerDialog(true, {
            title: dialogTitle,
            message: dialogMsg,
            action: {
                action: deleteServiceDropValue,
                params: [
                    at,
                    serviceDetailid
                ],
                text: this.props.t('deleteServiceDetail')
            }
        })

    }

    renderEditForm = (...params) => {
        return <ServiceDetailForm serviceid={this.props.dropdownId} reloadDatagrid={() => this.setState({reload: !this.state.reload})} editMood={this.props.editMood} viewMood={this.props.viewMood} serviceDetailId={params[0]} initValues={params[1]} />
    }

    handleEdit = (data) => {
        let initValues = {
            valueNameAR: data.Service_Lookup_Value_Name_AR,
            valueNameEN: data.Service_Lookup_Value_Name_EN,
            required: data.Active_Status_Id ? true : false,

        },
        serviceDetailId = data.Service_Lookup_Value_Id,
        serviceDetailName = this.props.i18n.language === 'ar' ? data.Service_Detail_Title_AR : data.Service_Detail_Title_EN,
        dialogTitle = `${this.props.viewMood ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${serviceDetailName}`

        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                serviceDetailId,
                initValues
            ]
        })

    }


    render() {
        
        let columnAlign = 'left',
        ServiceDetailTitleKey = 'Service_Lookup_Value_Name_EN'


        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            ServiceDetailTitleKey = 'Service_Lookup_Value_Name_AR'
        }
        
        let at = this.props.currentUser.Data.access_token

        const jsonDataSource = new CustomStore({
            key: 'Service_Lookup_Value_Id',
            load: async () => {
               return getDropdownValuesList(at, this.props.dropdownId)
            }
        });

        return(
            <Row>
                

                {!this.props.viewMood &&
                <Col xs={12} className='mb-5'>
                    <ServiceDetailForm serviceid={this.props.dropdownId} reloadDatagrid={() => this.setState({reload: !this.state.reload})} />
                </Col>
                }

                <Col xs={12}>
                    <DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

                        <Column dataField={ServiceDetailTitleKey} caption={this.props.t('lookupDDValue')} alignment={columnAlign}>
                        </Column>

                        <Column dataField="Active_Status_Id" caption={this.props.t('serviceStatus')} allowFiltering={false}
                        width= {'120px'} cellRender={this.customizeCellCheckbox}
                        >
                        </Column>

                        <Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
                            {!this.props.viewMood && [
                            <Button
                            render={(record) => {
                                return (
                                    
                                    <ButtonWarning
                                    variant="circleIcon"
                                    startIcon={<EditIcon />}
                                    onClick={() => this.handleEdit(record.data)}
                                    >
                                        {/* {this.props.t('edit')} */}
                                    </ButtonWarning>
                                )
                            }}
                            />,
                            
                            <Button
                                render={(record) => {
                                    return (
                                        
                                        <ButtonDanger
                                        variant="circleIcon"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(record.data)}
                                        >
                                            {/* {this.props.t('delete')} */}
                                        </ButtonDanger>
                                    )
                                }}
                            />
                            ]}

                            {this.props.viewMood &&
                            <Button
                                render={(record) => {
                                    return (
                                        
                                        <ButtonInfo
                                        variant="circleIcon"
                                        startIcon={<VisibilityIcon />}
                                        onClick={() => this.handleEdit(record.data)}
                                        >
                                            {/* {this.props.t('view')} */}
                                        </ButtonInfo>
                                    )
                                }}
                            />
                            }

                        </Column>

                    </DataGrid>

                </Col>
            </Row>
        )
    }

	onExporting(e) {
		let sheetTitle = this.props.t('dropdownValues')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload
    }
}

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm , setLoading , triggerNotification })(translationHook(ServiceDetail))