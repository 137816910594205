import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'


class DepartmentsAdministrationsForm extends Component {

    state = {
        showForm: false
    }

    onSectorSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/ADD_Department_Sector' : '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTSSECTORS/UPD_DEPARTMENT_SECTOR',
        bodyData = {
            Department_Sector_Name_AR: values.sectorNameAR,
            Department_Sector_Name_EN: values.sectorNameEN,
            Active_Status_Id: 1,
            Department_Sector_Code: values.sectorCode,
        }

        if (this.props.editMood) {
            bodyData.Department_Sector_Id = this.props.departmentSectorId
        } else {
            bodyData.Department_Id = this.props.currentUser.Data.Department_Id
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        //response.data
        this.props.triggerNotification(true, response)

        this.props.setLoading(false)
    }

    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}
            if (!values.sectorCode) {
                errors.sectorCode = `${this.props.t('error.field_required')}`
            }

            if (!values.sectorNameAR) {
                errors.sectorNameAR = `${this.props.t('error.field_required')}`
            }

            if (!values.sectorNameEN) {
                errors.sectorNameEN = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('municipalAdministrations')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addDepartment')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }
                
                <Form
                    onSubmit={this.onSectorSubmit}
                    initialValues={initValues ? initValues : {
                        sectorCode: '',
                        sectorNameAR: '',
                        sectorNameEN: '',
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('departmentNo')}
                                    className="mb-2"
                                    name="departmentNo"
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('departmentCode')}
                                    className="mb-2"
                                    name="sectorCode"
                                    required={true}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('depNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="sectorNameAR"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('depNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="sectorNameEN"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                       
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentsAdministrationsForm))
