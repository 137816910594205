import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'


class OrderView extends Component {

    state = {
        showForm: false
    }

    onSectionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/ADD_DEPARTMENT_SECTION' : '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/UPD_DEPARTMENT_SECTION',
        bodyData = {
            Department_Section_Name_AR: values.sectionNameAR,
            Department_Section_Name_EN: values.sectionNameEN,
            Department_Section_Description_AR: values.sectionDescAR,
            Department_Section_Description_EN: values.sectionDescEN,
            Department_Section_Phone: values.telephone,
            Active_Status_Id: values.sectionStatus ? 1 : 0
        }

        if (this.props.editMood) {
            bodyData.Department_Section_Id = this.props.departmentSectionId
        } else {
            bodyData.Department_Id = this.props.currentUser.Data.Department_Id
        }


        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        

        
        if (response.data.Status) {
            form.restart()
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)
        

    }


    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}

            if (!values.sectionNameAR) {
                errors.sectionNameAR = `${this.props.t('error.field_required')}`
            }

            if (!values.sectionNameEN) {
                errors.sectionNameEN = `${this.props.t('error.field_required')}`
            }

            if (!values.sectionDescAR) {
                errors.sectionDescAR = `${this.props.t('error.field_required')}`
            }

            if (!values.sectionDescEN) {
                errors.sectionDescEN = `${this.props.t('error.field_required')}`
            }

            if (!values.telephone) {
                errors.telephone = `${this.props.t('error.field_required')}`
            }


            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('departmentSections')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addSection')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }
                
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestNo')}
                                    className="mb-2"
                                    name="number"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestDate')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="date"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('dealingTypeAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="dealingTypeAR"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('dealingTypeEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="dealingTypeEN"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField                                    disabled
                                    label={this.props.t('DepartmentNameAr')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="departmentNameAR"
                                    autoComplete="off"

                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('DepartmentNameEn')}                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="departmentNameEN"
                                    autoComplete="off"
                                />
                            </Col>

                            <Col xs={12} md={12}>
                                <TextField
                                    disabled
                                    label={this.props.t('serviceNo')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNo"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('serviceNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNameAR"
                                    autoComplete="off"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('serviceNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="serviceNameEN"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestStatusEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="requestStatusEN"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('requestStatusAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="requestStatusAR"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerEmail')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientMail"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientNameAR"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientNameEN"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('customerPhone')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="clientPhone"
                                />
                            </Col>

                            {/* <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col> */}
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(OrderView))
