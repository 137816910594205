import React, { Component } from "react";
import { translationHook } from "../../translationHook";
import {
  triggerDialog,
  triggerDialogForm,
  setLoading,
  triggerNotification,
} from "../../../actions";
import apiConfig from "../../../apis/apiConfig";
import { connect } from "react-redux";
import { getCreatedAccounts } from "./../../../apis/accounts/Accounts";
import CustomStore from "devextreme/data/custom_store";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Col } from "react-bootstrap";
import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import {
  ButtonSecondary,
  ButtonDanger,
  ButtonInfo,
  ButtonWarning,
  ButtonPrimary,
} from "./../../UI/Buttons";
import AccountForm from "./AccountForm";
import { Select } from "mui-rff";
import { Form } from "react-final-form";

class AccountsList extends Component {
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);
  }
  state = {
    requestType: "5",
  };

  renderEditForm = (...params) => {
    let viewMood = params[2] === "view" ? true : false;
    return (
      <AccountForm
        accountId={params[0]}
        initValues={params[1]}
        viewMood={viewMood}
      />
    );
  };

  handleEdit = (data, viewMood) => {
    let accountId = data.Client_Profile_Id,
      initValues = data,
      accountTitle =
        this.props.i18n.language === "ar"
          ? `حساب ${data.Client_Name_AR}`
          : `${data.Client_Name_EN} Acconut`,
      dialogTitle = `${this.props.t("view")}  ${accountTitle}`;

    initValues.License_Issue_Date = initValues.License_Issue_Date
      ? initValues.License_Issue_Date.slice(0, 10)
      : initValues.License_Issue_Date;
    initValues.License_Expired_Date = initValues.License_Expired_Date
      ? initValues.License_Expired_Date.slice(0, 10)
      : initValues.License_Expired_Date;

    this.props.triggerDialogForm(true, {
      title: dialogTitle,
      renderForm: this.renderEditForm,
      params: [accountId, initValues, viewMood],
    });
  };
  onSectionSubmit = (values) => {};
  handleChange = (e) => {
    this.setState({
      requestType: e.target.value,
    });
  };

  render() {
    let columnAlign = "left",
      UserNameKey = "Client_Name_EN",
      at = this.props.currentUser.Data.access_token;

    if (this.props.i18n.language === "ar") {
      columnAlign = "right";
      UserNameKey = "Client_Name_AR";
    }

    const jsonDataSource = new CustomStore({
      key: "Client_Profile_Id",
      load: () => {
        return getCreatedAccounts(at, this.state.requestType);
      },
    });

    return (
      <>
        {!this.props.editMood && !this.props.viewMood && (
          <div className="d-flex justify-content-start align-items-center mb-5">
            <h3>{this.props.t("manageAccounts")}</h3>
          </div>
        )}
        <Form
          onSubmit={this.onSectionSubmit}
          initialValues={{
            requestType: "4",
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form className="row align-items-center">
              <Col xs={4}>
                <Select
                  label={this.props.t("accountType")}
                  className="mb-2"
                  name="requestType"
                  value={this.state.requestType}
                  onChange={this.handleChange}
                  data={[
                    { value: "0", label: this.props.t("newAccounts") },
                    { value: "1", label: this.props.t("acceptedAccounts") },
                    { value: "2", label: this.props.t("rejectedAccounts") },
                    {
                      value: "3",
                      label: this.props.t("returendAccountsEmpolyee"),
                    },
                    { value: "4", label: this.props.t("returendAccounts") },
                    { value: "5", label: this.props.t("allAccounts") },
                  ]}
                ></Select>
              </Col>
            </form>
          )}
        />
        <DataGrid
          className={[
            this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
            "globalBox",
          ].join(" ")}
          id="dataGrid"
          width={"100%"}
          dataSource={jsonDataSource}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          allowColumnReordering={true}
          rowAlternationEnabled={false}
          focusedRowEnabled={true}
          onExporting={this.onExporting}
          columnWidth={"auto"}
          wordWrapEnabled={true}
          rtlEnabled={this.props.i18n.language === "ar" ? true : false}
        >
          <ColumnChooser
            enabled={true}
            allowSearch={true}
            search={"fff"}
            title={this.props.t("dxColumnChooserTitle")}
            emptyPanelText={this.props.t("dxColumnChooserPanelText")}
          />

          <SearchPanel
            visible={true}
            width={240}
            placeholder={this.props.t("search")}
          />

          <FilterRow visible={true} />

          <HeaderFilter visible={true} />

          <Export enabled={true} allowExportSelectedData={true} />

          <Grouping contextMenuEnabled={true} expandMode="rowClick" />
          <GroupPanel
            visible={true}
            emptyPanelText={this.props.t("dxGroupPanelText")}
          />

          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />

          <Column
            cssClass="outlinedColumn"
            dataField={UserNameKey}
            caption={this.props.t("accountName")}
            alignment={columnAlign}
          ></Column>
          <Column
            dataField="Login_Name"
            caption={this.props.t("loginName")}
            alignment={columnAlign}
          ></Column>
          <Column
            dataField="Client_Mail"
            caption={this.props.t("customerEmail")}
            alignment={columnAlign}
          ></Column>
          <Column
            dataField="Client_Phone"
            caption={this.props.t("phone")}
            alignment={columnAlign}
          ></Column>
          <Column
            dataField="Client_EID"
            caption={this.props.t("id")}
            alignment={columnAlign}
            visible={false}
          ></Column>
          <Column
            dataField="License_No"
            caption={this.props.t("licenseNumber")}
            alignment={columnAlign}
            visible={false}
          ></Column>
          <Column
            cssClass="outlinedColumn"
            dataField={"License_Type"}
            caption={this.props.t("LicenseType")}
            alignment={"center"}
          ></Column>
          <Column
            dataField={"License_Issue_Date"}
            caption={this.props.t("LicenseIssueDate")}
            alignment={columnAlign}
            format="yyyy-MM-dd"
            dataType="date"
            visible={false}
          ></Column>
          <Column
            dataField={"License_Expired_Date"}
            caption={this.props.t("LicenseExpiredDate")}
            alignment={columnAlign}
            format="yyyy-MM-dd"
            dataType="date"
            visible={false}
          ></Column>
          <Column
            dataField={"Official_Mail"}
            caption={this.props.t("OfficialMail")}
            alignment={columnAlign}
            visible={false}
          ></Column>
          <Column
            dataField={"Official_Mobile"}
            caption={this.props.t("OfficialMobile")}
            alignment={columnAlign}
            visible={false}
          ></Column>
          <Column
            dataField={"Official_Land_Phone"}
            caption={this.props.t("OfficialLandPhone")}
            alignment={columnAlign}
            visible={false}
          ></Column>
          <Column
            cssClass="outlinedColumn nowrapColumn"
            type="buttons"
            caption={this.props.t("details")}
            fixed={true}
            fixedPosition={this.props.i18n.language === "ar" ? "left" : "right"}
          >
            <Button
              render={(record) => {
                return (
                  <ButtonInfo
                    variant="circleIcon"
                    startIcon={<VisibilityIcon />}
                    onClick={() => {
                      // this.props.triggerView(true, false)

                      this.handleEdit(record.data, "view");
                    }}
                  >
                    {/* {this.props.t('edit')} */}
                  </ButtonInfo>
                );
              }}
            />
          </Column>
        </DataGrid>
      </>
    );
  }

  onExporting(e) {
    let sheetTitle = this.props.t("manageAccounts");
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGridToExcel({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 2 },
      topRightCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        // excelCell.value = gridCell.value;
        excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        excelCell.alignment = { indent: 1, wrapText: true, vertical: "middle" };
        if (gridCell.rowType === "group") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          excelCell.alignment = { indent: 2 };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          excelCell.alignment = { vertical: "middle", horizontal: "center" };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${sheetTitle}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerDialog,
  triggerDialogForm,
  setLoading,
  triggerNotification,
})(translationHook(AccountsList));
