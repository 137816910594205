import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store'
import Checkbox from '@material-ui/core/Checkbox';

import { getDepartmentUnselectedServiceList } from '../../../apis/services/departments/getDepartmentUnselectedServiceList'
import { saveDepartmentSelectedServices } from '../../../apis/services/departments/saveDepartmentSelectedServices'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'
import { ButtonPrimary } from '../../UI/Buttons'


class DepartmentUnselectedServicesList extends Component {

    constructor(props) {
        super(props);
 
        this.dataGridRef = React.createRef();
        this.onExporting = this.onExporting.bind(this);
 
    }


    customizeCellCheckbox = (data) => {
        return (
            <Checkbox
                checked={data.row.isSelected}
                color="primary"
                inputProps={{ 'aria-label': 'select service checkbox' }}
                onChange={(e)=>{
                    let dataGridRef = this.dataGridRef.current.instance
                    
                    if ( e.target.checked ) {
                        dataGridRef.selectRows([data.key], true)
                    } else {
                        dataGridRef.deselectRows([data.key], true)
                    }

                }}
            />
        )
        
    }

    customizeCellHeaderCheckbox = (data) => {
        return (
            <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'select service checkbox' }}
                onChange={(e)=>{
                    let dataGridRef = this.dataGridRef.current.instance

                    if ( e.target.checked ) {
                        dataGridRef.selectAll()
                    } else {
                        dataGridRef.deselectAll()
                    }
                   
                }}
            />
        )
        
    }

    onSelectionChanged = (selection)=>{
        
        let dataGridRef = this.dataGridRef.current.instance,
        { currentSelectedRowKeys, currentDeselectedRowKeys } = selection,
        rowKey = currentSelectedRowKeys.length !== 0 ? currentSelectedRowKeys : currentDeselectedRowKeys
        
        dataGridRef.beginUpdate()
        let rowIndex=  rowKey.map((key)=>{
           return dataGridRef.getRowIndexByKey(key)
         })
        dataGridRef.endUpdate()

            
        dataGridRef.repaintRows(rowIndex)
    }


    handleSaveSelectedServices = async () => {
        let dataGridRef = this.dataGridRef.current.instance,
        selectedServices = dataGridRef.getSelectedRowKeys(),
        at = this.props.currentUser.Data.access_token

        if ( selectedServices.length !== 0 ) {

            this.props.setLoading(true, 'dark')
    
            const response = await saveDepartmentSelectedServices(at, selectedServices)
    
            this.props.triggerNotification(true, response.data)

            this.props.triggerDialogForm(false)
    
            this.props.setLoading(false)

        }
    }

    render() {
 
        let columnAlign = 'left',
        ServiceNameKey = 'Service_Name_EN',
        ServiceCategoryKey = 'Service_Category_Name_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            ServiceNameKey = 'Service_Name_AR'
            ServiceCategoryKey = 'Service_Category_Name_AR'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'Service_Id',
            load: () => {
                return getDepartmentUnselectedServiceList(at)
            }
        });
        
        return(
            <>

            <div className="container-fluid pt-5">
                
                <DataGrid
                    ref={this.dataGridRef}
                    className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
                    onSelectionChanged={this.onSelectionChanged}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

                    {/* <Column cssClass="outlinedColumn"
                        type="selection"
                        cellRender={this.customizeCellCheckbox}
                        headerCellRender={this.customizeCellHeaderCheckbox}
                    /> */}

                    <Column dataField="Service_No" caption={this.props.t('serviceNo')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={ServiceNameKey} caption={this.props.t('serviceName')} alignment={columnAlign}>
                    </Column>

                    <Column cssClass="outlinedColumn" dataField="Service_Code" caption={this.props.t('serviceCode')} alignment={'center'}>
                    </Column>

                    <Column dataField={ServiceCategoryKey} caption={this.props.t('serviceCat')} alignment={columnAlign}>
                    </Column>

                    
                </DataGrid>

                <div className="d-flex justify-content-start align-items-center mb-4">
                    <ButtonPrimary
                        size="large"
                        onClick={this.handleSaveSelectedServices}
                    >
                        {this.props.t('save')}
                    </ButtonPrimary>
                </div>
            </div>
            </>
        )
    }

	onExporting(e) {
		let sheetTitle = this.props.t('versions')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser
    }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentUnselectedServicesList))