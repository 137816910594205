import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches, Select } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'


class DepartmentSectionFrom extends Component {

    state = {
        showForm: false,
        administrations: []
    }

    onSectionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/ADD_DEPARTMENT_SECTION' : '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS_SECTIONS/UPD_DEPARTMENT_SECTION',
        bodyData = {
            Department_Section_Name_AR: values.sectionNameAR,
            Department_Section_Name_EN: values.sectionNameEN,
            Department_Section_Description_AR: values.sectionDescAR,
            Department_Section_Description_EN: values.sectionDescEN,
            Department_Section_Phone: values.telephone,
            Active_Status_Id: values.sectionStatus ? 1 : 0,
            Department_Section_Code: values.sectionCode,
            Department_Sector_Id: values.municipalAdministrations
        }

        if (this.props.editMood) {
            bodyData.Department_Section_Id = this.props.departmentSectionId
        } else {
            bodyData.Department_Id = this.props.currentUser.Data.Department_Id
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        //response.data
        this.props.triggerNotification(true, response)

        this.props.setLoading(false)
    }

    getDepartmentSectors = async () => {
        let departmentSectorsArr = [],
        departmentId = this.props.currentUser.Data.Department_Id,
        at = this.props.currentUser.Data.access_token
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_DEPARTMENT_SECTORS', 
            {
                Department_id: departmentId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        )

        if (response.data.Status) {
            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN
                departmentSectorsArr.push({value: item.Record_Id, label})
            })

            this.setState({administrations: departmentSectorsArr})
        }
    }

    componentDidMount() {
        this.getDepartmentSectors()
    }

    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}

            if (!values.sectionNameAR) {
                errors.sectionNameAR = `${this.props.t('error.field_required')}`
            }

            if (!values.sectionNameEN) {
                errors.sectionNameEN = `${this.props.t('error.field_required')}`
            }

            if (!values.sectionDescAR) {
                errors.sectionDescAR = `${this.props.t('error.field_required')}`
            }

            if (!values.sectionDescEN) {
                errors.sectionDescEN = `${this.props.t('error.field_required')}`
            }

            if (!values.telephone) {
                errors.telephone = `${this.props.t('error.field_required')}`
            }

            if (!values.sectionCode) {
                errors.sectionCode = `${this.props.t('error.field_required')}`
            }

            if (!values.municipalAdministrations) {
                errors.municipalAdministrations = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('departmentSections')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addSection')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }
                
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues ? initValues : {
                        sectionStatus: true,
                        sectionNameAR: '',
                        sectionNameEN: '',
                        sectionDescAR: '',
                        sectionDescEN: '',
                        telephone: '',
                        municipalAdministrations: '',
                        sectionCode: ''
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('sectionNo')}
                                    className="mb-2"
                                    name="sectionNo"
                                />
                            </Col>

                            <Col xs={12} md={6} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="sectionStatus"
                                    data={{ label: `${this.props.t('sectionStatus')}`, value: `${this.props.t('sectionStatus')}` }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('sectionNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="sectionNameAR"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('sectionNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="sectionNameEN"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('sectionDescAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="sectionDescAR"
                                    multiline
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('sectionDescEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="sectionDescEN"
                                    multiline
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('telephone')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="telephone"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('sectionCode')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="sectionCode"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={4}>
                                <Select
                                    disabled={this.props.viewMood}
                                    label={this.props.t('municipalAdministrations')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name='municipalAdministrations'
                                    data={this.state.administrations}
                                    required={true}
                                    showError={  () => {
                                        let fieldStatus = form.getFieldState('municipalAdministrations')
                                        if (fieldStatus) {
                                            return fieldStatus.submitFailed && fieldStatus.invalid
                                        }
                                    } }
                                >
                                </Select>
                            </Col>

                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                       
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentSectionFrom))
