import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Editing,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";
import { translationHook } from "../../../translationHook";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { ButtonSecondary } from "../../../UI/Buttons";
import { setLoading, triggerNotification } from "../../../../actions";

class InputChangesEditList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	downloadInvoice = async (File_Code,File_Name) => {
		let at = this.props.currentUser.Data.access_token;

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(
				"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DOWNLOAD_REQUEST_ATTACHMENT",
				{ File_Code },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
					responseType: "arraybuffer",
				}
			)
			.catch((error) => {
				return error.response;
			});

		if (response.status === 200) {
			let contentType = response.headers["content-type"],
				typesArray = [
					"application/pdf",
					"image/bmp",
					"image/gif",
					"image/vnd.microsoft.icon",
					"image/jpeg",
					"image/png",
					"image/svg+xml",
					"image/tiff",
					"image/webp",
				],
				url = window.URL.createObjectURL(
					new Blob([response.data], { type: contentType })
				),
				link = document.createElement("a");

			link.href = url;

			if (parseInt(response.headers["content-length"]) < 300) {
				let messageArra = [
					{
						Error_AR: "خطأ : البيانات المطلوبة غير متوفرة",
						Error_EN: "Error : Requested Data Not Found",
					},
				];

				this.props.triggerNotification(true, {
					Status: false,
					Data: messageArra,
				});
			} else if (typesArray.includes(contentType)) {
				link.setAttribute("target", "blank");
				document.body.appendChild(link);
				link.click();
			} else {
                link.setAttribute("download", File_Name);
				document.body.appendChild(link);
				link.click();
			}

			this.props.setLoading(false);
		}
	};

	render() {
		let columnAlign = "left",
			nameKey =
				this.props.i18n.language === "ar"
					? "Department_Service_Detail_Name_AR"
					: "Department_Service_Detail_Name_EN",
			jsonDataSource = new CustomStore({
				// key: 'Input_Change_Id',
				load: async () => {
					if (
						this.props.inputChangesList &&
						this.props.inputChangesList.length > 0
					) {
						return this.props.inputChangesList;
					}

					let at = this.props.currentUser.Data.access_token;

					const response = await apiConfig
						.post(
							"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_TASK_INPUTS_CHANGES_EDIT_LIST",
							{
								Request_Id: this.props.requestId,
								Request_Task_Id: this.props.requestTaskId,
								Temp_Action_Id: this.props.tempId,
							},
							{
								headers: {
									Authorization: `Bearer ${at}`,
								},
							}
						)
						.catch((error) => {
							return error.response;
						});

					if (response.status === 200) {
						return response.data.Data;
					}

					return [];
				},
				update: async (key, values) => {
					let at = this.props.currentUser.Data.access_token;

					const response = await apiConfig
						.post(
							"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_INPUT_CHANGES_EDIT_NOTES",
							{
								Request_Id: this.props.requestId,
								Request_Task_Id: this.props.requestTaskId,
								Temp_Action_Id: this.props.tempId,
								Input_Change_Id: key.Input_Change_Id,
								Department_Service_Detail_Id:
									key.Department_Service_Detail_Id,
								Input_Notes: values.Input_Notes,
							},
							{
								headers: {
									Authorization: `Bearer ${at}`,
								},
							}
						)
						.catch((error) => {
							return error.response;
						});
				},
			});

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
		}

		return (
			<DataGrid
				className={[
					this.props.i18n.language === "ar"
						? "dx-grid-ar"
						: "dx-grid-en",
					"globalBox",
				].join(" ")}
				id={`dataGrid_${this.props.key}`}
				width={"100%"}
				dataSource={jsonDataSource}
				showBorders={false}
				showColumnLines={false}
				showRowLines={true}
				allowColumnResizing={true}
				columnResizingMode={"widget"}
				allowColumnReordering={true}
				rowAlternationEnabled={false}
				focusedRowEnabled={false}
				onExporting={this.onExporting}
				columnWidth={"auto"}
				wordWrapEnabled={true}
				rtlEnabled={this.props.i18n.language === "ar" ? true : false}
			>
				<ColumnChooser
					enabled={true}
					allowSearch={true}
					search={"fff"}
					title={this.props.t("dxColumnChooserTitle")}
					emptyPanelText={this.props.t("dxColumnChooserPanelText")}
				/>

				<SearchPanel
					visible={true}
					width={240}
					placeholder={this.props.t("search")}
				/>

				<FilterRow visible={true} />

				<HeaderFilter visible={true} />

				<Export enabled={true} allowExportSelectedData={true} />

				<Grouping contextMenuEnabled={true} expandMode="rowClick" />
				<GroupPanel
					visible={true}
					emptyPanelText={this.props.t("dxGroupPanelText")}
				/>

				{!this.props.inputChangesList && (
					<Editing
						mode="cell"
						allowUpdating={true}
						allowAdding={false}
						allowDeleting={false}
					/>
				)}

				<Column
					dataField={nameKey}
					width="auto"
					caption={this.props.t("serviceDetailName")}
					alignment={columnAlign}
					allowEditing={false}
				></Column>

				<Column
					allowEditing={false}
					dataField="Input_Value"
					width="auto"
					caption={this.props.t("value")}
					alignment={columnAlign}
					cellRender={({ row: { data } }) => {
						if (data.Input_Type === 1) {
							return <span>{data.Input_Value}</span>;
						} else if (
							data.Input_Type === 2 &&
							data.Input_Value !== ""
						) {
							return (
								<ButtonSecondary
									variant="circleIcon"
									startIcon={<ArrowDownwardIcon />}
									onClick={() =>
                                        this.downloadInvoice(data.Input_Value, data.Department_Service_Detail_Name_AR + "_OLD")
									}
								></ButtonSecondary>
							);
						} else {
							return null;
						}
					}}
				></Column>

				{this.props.inputChangesList && (
					<Column
						allowEditing={false}
						dataField="New_Input_Value"
						width="auto"
						caption={this.props.t("valueAfterEdit")}
						alignment={columnAlign}
						cellRender={({ row: { data } }) => {
							if (data.Input_Type === 1) {
								return <span>{data.New_Input_Value}</span>;
							} else if (
								data.Input_Type === 2 &&
								data.New_Input_Value !== ""
							) {
								return (
									<ButtonSecondary
										variant="circleIcon"
										startIcon={<ArrowDownwardIcon />}
										onClick={() =>
											this.downloadInvoice(
                                                data.New_Input_Value, data.Department_Service_Detail_Name_AR + "_NEW"
											)
										}
									></ButtonSecondary>
								);
							} else {
								return null;
							}
						}}
					></Column>
				)}

				<Column
					dataField="Input_Notes"
					width="auto"
					caption={this.props.t("processResultNotes")}
					alignment={columnAlign}
				></Column>
			</DataGrid>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("invoiceList");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { setLoading, triggerNotification })(
	translationHook(InputChangesEditList)
);
