import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
} from 'devextreme-react/data-grid';

import { ExcelJS, Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';

import CustomStore from 'devextreme/data/custom_store';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from '../../actions';
import { getUsedDevicesTypes } from '../../apis/usedDevices';
import { triggerDialog } from '../../actions';
import { translationHook } from '../translationHook';
import { ButtonInfo } from '../UI/Buttons';
import moment from 'moment';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { Col, Row } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';

class UsedDevices extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
	state = {
		view: false,
		reqData: {

		}
	};
	componentDidMount() {
		this.setState({
			From_Date: moment().format('YYYY-MM-DD'),
			To_Date: moment().format('YYYY-MM-DD')
		})
	}
	handleDateChange = (date, field) => {
		this.setState({
			[field]: moment(date).lang('en').format('YYYY-MM-DD'),
			view: false
		});
	};

	render() {
		let columnAlign = 'left',
			UserTypeNameKey = 'User_Type_Name_EN',
			requestUser = "Request_User_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
            UserTypeNameKey = 'User_Type_Name_AR';
			requestUser = "Request_User_Name_AR";
			columnAlign = 'right';
			
		}

		const jsonDataSource = new CustomStore({
			key: 'Row_Id',
			load: () => {
				if (this.state.view) {
					return getUsedDevicesTypes(at, this.state.reqData);
				} else {
					return [];
				}
			},
		});

		return (
			<>
				<div className="d-flex justify-content-between align-items-center pb-5">
					<h3>{this.props.t('usedDevicesReport')}</h3>
				</div>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Row className="align-items-center">
						<Col xs={5}>
							<KeyboardDatePicker
								margin="normal"
								format="yyyy-MM-dd"
								label={this.props.t('from')}
								value={this.state.From_Date}
								onChange={(e) =>
									this.handleDateChange(e, 'From_Date')
								}
								KeyboardButtonProps={{
									'aria-label': this.props.t('fromdate'),
								}}
							/>
						</Col>
						<Col xs={5}>
							<KeyboardDatePicker
								margin="normal"
								label={this.props.t('to')}
								format="yyyy-MM-dd"
								value={this.state.To_Date}
								onChange={(e) =>
									this.handleDateChange(e, 'To_Date')
								}
								KeyboardButtonProps={{
									'aria-label': this.props.t('todate'),
								}}
							/>
						</Col>
						<Col xs={2}>
							<ButtonInfo
								variant="outlined"
								onClick={() => {
									this.setState({
										view: true,
										reqData: {
											From_Date: this.state.From_Date,
											To_Date: this.state.To_Date
										}
									})
								}}
							>
								<VisibilityIcon />
							</ButtonInfo>
						</Col>
					</Row>
				</MuiPickersUtilsProvider>
				<DataGrid
					className={[
						this.props.i18n.language === 'ar'
							? 'dx-grid-ar'
							: 'dx-grid-en',
						'globalBox',
					].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === 'ar' ? true : false
					}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t(
							'dxColumnChooserPanelText'
						)}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t('dxGroupPanelText')}
					/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
						dataField={'Request_Log_Time'}
						caption={this.props.t('date&Time')}
						alignment={'center'}
					></Column>
					<Column
						dataField={'Request_User'}
						caption={this.props.t('loginName')}
						alignment={'center'}
					></Column>
					<Column 
                        dataField={UserTypeNameKey} 
                        caption={this.props.t('userType')}
                        alignment={columnAlign}
                    >
                    </Column>
					<Column 
                        dataField={requestUser} 
                        caption={this.props.t('userName')}
                        alignment={columnAlign}
                    >
                    </Column>
					<Column 
                        dataField={"Request_Device"} 
                        caption={this.props.t('usedDevice')}
                        alignment={'center'}
                    >
                    </Column>
					<Column 
                        dataField={"Request_Device_Type"} 
                        caption={this.props.t('usedDeviceType')}
                        alignment={'center'}
                    >
                    </Column>
        
					
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t('manageDepartments');
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: 'Cairo',
					size: 10,
					color: { argb: '151713' },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: 'middle',
				};
				if (gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: 'd0b166' },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: 'Cairo',
						size: 10,
						color: { argb: 'ffffff' },
						bold: true,
					};
				}
				if (gridCell.rowType === 'header') {
					excelCell.fill = {
						type: 'pattern',
						pattern: 'solid',
						fgColor: { argb: '58743a' },
					};
					excelCell.alignment = {
						vertical: 'middle',
						horizontal: 'center',
					};
					excelCell.font = {
						family: 2,
						name: 'Cairo',
						size: 12,
						color: { argb: 'ffffff' },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: 'application/octet-stream' }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(UsedDevices));
