import apiConfig from '../../apiConfig'
export const getGovEntList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/LOOKUPS/GOVERNMENTENTITIES/GET_GOVERNMENTENTITIES_LIST',
		{
			pageCount: 10000,
			PageIndex: 1
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	// if (response.data.Status) {
	// 	for (let i in response.data.Data) {
	// 		if (response.data.Data.Data[i].Active_Status_Id === 1) {
	// 			response.data.Data.Data[i].Active_Status_Id = true;
	// 		} else {
	// 			response.data.Data.Data[i].Active_Status_Id = false;
	// 		}
	// 	}
	// }
	// return [];
	return response.data.Data.Data;
};
export const deletegovEnt = async (at, govEntId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/GOVERNMENTENTITIES/DEL_GOVERNMENTENTITIES',
        {
            Government_Entity_Id: govEntId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    if (response.data.Status) {
        return response.data
    }

    return []
}
export const getInvoiceDetails = async (at , id) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PAYMENTS/INVOICES/GET_INVOICE_DETAILS',
		{
            Invoice_Id: id
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	if (response.data.Status) {
		return response.data.Data;
	}
	return [];
};
