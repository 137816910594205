import apiConfig from "../apiConfig";

export const getRequestDetails = async (at, requestId, requestTaskId) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/LIST/GET_REQUEST_DETAILS",
		{
			Request_Id: requestId,
			Request_Task_Id: requestTaskId,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
