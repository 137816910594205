import React, { forwardRef } from 'react'
import ListItem from '@material-ui/core/ListItem'
import { Link } from "@reach/router"
import { connect } from 'react-redux'

import { setCurrentPage } from '../../../../actions'




const MenuItemComponent = props => {
    
    const handleSetActive = () => {
        props.setCurrentPage(props.link)
    }


    const { className, onClick, link, children } = props

    // If link is not set return the ordinary ListItem
    if (!link || typeof link !== 'string') {
        return (
        <ListItem
            button
            className={className}
            children={children}
            onClick={onClick}
        />
        )
    }

    // Return a LitItem with a link component
    return (
        <ListItem
            button
            className={className}
            children={children}
            component={forwardRef((props, ref) => <Link {...props} innerRef={ref} />)}
            to={link}
            onClick={handleSetActive}
        />
    )
}


export default connect(null, { setCurrentPage })(MenuItemComponent)