import apiConfig from '../apiConfig'
export const getDepUsersList = async (at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_DEPARTMENT_USERS', {},
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    if (response.data.Status) {
        return response.data
    }

    return []
}
export const deleteAttachment = async (file) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/DEL_MISSION_ATTACHMENT',
        {
            Mission_Attach_Id: file.Mission_Attach_Id
        },
        {
            headers: {
                Authorization: `Bearer ${file.at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}
export const getMissionAttachmentsList = async (at , missionId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_ATTACHMENT_LIST',
        {
            Mission_Id: missionId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}
export const getMissionAttachmentsResList = async (at , missionId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/GET_MISSION_RESPONSE_ATTACHMENT_LIST',
        {
            Mission_Id: missionId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}
export const cancelMission = async (id ,at) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/MISSIONS/CANCEL_MISSION', {
            Mission_Id: id,
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )
    
    return response
}
