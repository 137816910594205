import React from 'react'
import { translationHook } from '../translationHook'
import { triggerDialog, triggerDialogForm } from '../../actions'
import { connect } from 'react-redux'
import CustomStore from 'devextreme/data/custom_store'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import { getSuggAndCompList } from '../../apis/portal/getSuggAndCompList'
import EditIcon from '@material-ui/icons/Edit'
import SuggestionsComplainsForm from './SuggestionsComplainsForm'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import { 
    ButtonSecondary,
    ButtonWarning 
} from './../UI/Buttons'

const SuggestionsComplainsList = props => {

    let columnAlign = 'left',
    MessageTitleKey = 'Client_Message_Type_Name_EN',
    at = props.currentUser.Data.access_token

    if  (props.i18n.language === 'ar') {
        columnAlign = 'right'
        MessageTitleKey = 'Client_Message_Type_Name_AR'
    }

    const jsonDataSource = new CustomStore({
        key: 'Client_Message_Id',
        load: () => {
            return getSuggAndCompList(at)
        }
    })

    const renderEditForm = (...params) => {
        return <SuggestionsComplainsForm clientMessageId={params[0]} />
    }

    const handleEdit = data => {
        let clientMessageId = data.Client_Message_Id,
        clientMessageName = props.i18n.language === 'ar' ? data.Client_Message_Type_Name_AR : data.Client_Message_Type_Name_EN,
        dialogTitle = `${props.viewMood ? props.t('viewServiceDetailTitle') : props.t('editServiceDetailTitle')} ${clientMessageName}`

        props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: renderEditForm,
            params: [
                clientMessageId,
            ]
        })
    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-4'>
                <h3>{props.t('suggestionsAndComplains')}</h3>
            </div>

            <DataGrid
					className={[props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={props.t('dxColumnChooserTitle')}
						emptyPanelText={props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>
                <Column 
                    dataField='Client_Message_Name'
                    caption={props.t('name')} 
                    alignment={columnAlign}
                >
                </Column>

                <Column 
                    dataField='Client_Message_Phone'
                    caption={props.t('phone')} 
                    alignment={columnAlign}
                    visible={false}
                >
                </Column>

                <Column 
                    dataField='Client_Message_Mail'
                    caption={props.t('mail')} 
                    alignment={columnAlign}
                    visible={false}
                >
                </Column>

                <Column 
                    dataField='Client_Message_Date'
                    caption={props.t('date')} 
                    alignment={'center'}
                    dataType='date'
                    format='yyyy-MM-dd'
                    width={'150px'}
                >
                </Column>

                <Column 
                    dataField={MessageTitleKey} 
                    caption={props.t('messageType')} 
                    alignment={'center'}
                    width={'150px'}
                >
                </Column>

                <Column 
                    dataField='Client_Message_Message'
                    caption={props.t('message')} 
                    alignment={columnAlign}
                >
                </Column>

                <Column 
                    dataField='Client_Message_Reply'
                    caption={props.t('reply')} 
                    alignment={columnAlign}
                >
                </Column>

                <Column 
                    dataField='Closed'
                    caption={props.t('closed')} 
                    alignment='center'
                    allowFiltering={false}
                    width={'100px'}
                >
                </Column>

                <Column  cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={props.t('details')}
						fixed={true}
						fixedPosition={props.i18n.language === 'ar' ? 'left' : 'right'}
					>

                    <Button
                        render={(record) => {
                            if (record.data.Closed === false) {
                                return (
                                    <ButtonWarning
                                        variant="circleIcon"
                                        startIcon={<EditIcon />}
                                        onClick={() => handleEdit(record.data)}
                                    >
                                        {/* {props.t('close')} */}
                                    </ButtonWarning>
                                )
                            }
                        }}
                    />

                </Column>

            </DataGrid>
        </>
    )

    function onExporting(e) {
        let sheetTitle = props.t('suggestionsAndComplains')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            topLeftCell: { row: 2, column: 2 },
            topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
                excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
                if(gridCell.rowType === 'group') {
                    excelCell.fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'d0b166'}
                      };
                    excelCell.alignment = { indent: 2 };
                    excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
                }
                if(gridCell.rowType === 'header') {
                excelCell.fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'58743a'}
                    };
                excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
                excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
                }
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}





const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload,
    }
}

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm })(
	translationHook(SuggestionsComplainsList)
)