import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../translationHook';
import apiConfig from '../../apis/apiConfig';
import { ButtonPrimary , ButtonSecondary } from '../UI/Buttons';
import { Row, Col } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { TextField, Switches } from 'mui-rff';
import { triggerNotification, setLoading } from '../../actions';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';
import GoogleMap from '../UI/google-map/googleMap';

import { Toolbar, Item } from 'devextreme-react/html-editor';
import Wysiwyg from '../UI/Wysiwyg'

    class DepartmentForm extends Component {

	state = {
		initialValues: {},
        center: {},
		file: null,
		url: null,
        position : {
            lat: '',
            lng: ''
        },
	};

	componentDidMount() {
		const {
			Department_Logo_Url,
			Department_latitudes,
			Department_longitudes,
		} = this.props.initValues;

		this.setState({
            position : {
                lat: Department_latitudes,
                lng: Department_longitudes
            },
			url: Department_Logo_Url
		});
	}

	onSubmit = async (values) => {
		if(this.state.file) {
			this.submitLogo(values.Department_Id)
		} 
		const auth = this.props.currentUser.Data.access_token,
		url =
			'/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/UPD_DEPARTMENT';

            
		let data = {
			...values,
			Department_latitudes: this.state.position.lat, 
            Department_longitudes: this.state.position.lng,
            Active_Status_Id: values.Active_Status_Id ? 1 : 0
		}

		this.props.setLoading(true, 'dark')


		const response = await apiConfig.post(url, data, {
			headers: {
				Authorization: `Bearer ${auth}`,
			},
		}).catch((error) => {
            return error.response
        })

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		

	};
	handleUploadClick =  e => {
		let file = e.target.files[0],
		url = window.URL.createObjectURL(file);
		this.setState({
			file,
			url
		})
    }
    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.sharjaPosition);
        }
    }

    sharjaPosition = (position) => {
        this.setState({
            position: {
                lat:  position.coords.latitude,
                lng: position.coords.longitude,
            }
        })

        this.getLatLng(this.state.position)
    }

	getLatLng = (val) => {
		this.setState({
			initialValues: {
				...this.state.initialValues,
				Department_latitudes: val.lat,
				Department_longitudes: val.lng,
			}
		})
	}
	submitLogo = async (id) => {
		const bodyFormData = new FormData(),
        at = this.props.currentUser.Data.access_token,
        url = '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/UPD_DEPARTMENT_LOGO',
        bodyData = {
            Department_Id: id,
        }

        bodyFormData.append('PARAMETERS', JSON.stringify(bodyData))

        bodyFormData.append('Department_Logo', this.state.file)

		const response = await apiConfig.post(
            url,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)

	}

	render() {

		return (
			<>
				<Form
                subscription={{submitting: true, initialValues: true}}
					onSubmit={this.onSubmit}
					initialValues={this.props.initValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                                className={`row ${!this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                                noValidate
                                className='row mt-4 mx-3 departmentFormClass'>
                                    <Col xs={6} md={6}>
                                        <Row>
                                            <Col xs={12} md={9}>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <TextField
                                                            disabled
                                                        label={this.props.t('departmentID')}
                                                            className='mb-2'
                                                            name='Department_Id'
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <TextField
                                                            disabled
                                                            label={this.props.t('DepartmentCode')}
                                                            className='mb-2'
                                                            name='Department_Code'
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <TextField
                                                            disabled
                                                            label={this.props.t('DepartmentNameAr')}
                                                            className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                            name='Department_Name_AR'
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <TextField
                                                            disabled
                                                            label={this.props.t('DepartmentNameEn')}
                                                            className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                            name='Department_Name_EN'
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Switches
                                                        disabled={this.props.viewMood}
                                                        color="primary"
                                                        name="Active_Status_Id"
                                                        data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={3} className='d-flex justify-content-start align-items-center ServiceAvatarContainer mb-2'>
                                                <div className='upload-avatar mx-3'>
                                                    <Avatar
                                                        src={this.state.url}
                                                    />
                                                    {/* // ? this.state.initialValues.Department_Logo_Url
                                                // : '/broken-image.jpg' */}
                                                </div>
                                                {!this.props.viewMood && (
                                                    <ButtonSecondary
                                                        color='primary'
                                                        variant='contained'
                                                        endIcon={<CloudUploadIcon />}>
                                                    {this.props.t('departmentAvatar')}
                                                        <input
                                                            type='file'
                                                            name='Department_Logo_Url'
                                                            className='buttonFile'
                                                            onChange={(e) => this.handleUploadClick(e)}
                                                        />
                                                    </ButtonSecondary>
                                                )}
                                        </Col>
                                        </Row>
                                    <Row>
                                    <Col xs={12}>
                                        <div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
                                            <label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{this.props.t('departmentDescAr')}</label>

                                            <Field 
                                                name="Department_Description_AR" 
                                                subscription={{error:true, initial: true}}
                                                disabled={this.props.viewMood}
                                            >
                                                {fieldProps => (
                                                    <Wysiwyg
                                                        rtlEnabled={this.props.i18n.language === 'ar' ? true : false}
                                                        height="300px"
                                                        defaultValue={this.props.initValues.Department_Description_AR}
                                                        onChange={fieldProps.input.onChange}
                                                        readOnly={this.props.viewMood}
                                                    >
                                                        <Toolbar
                                                            multiline={false}
                                                            
                                                        >
                                                            <Item name="undo" />
                                                            <Item name="redo" />
                                                            <Item name="separator" />
                                                            <Item name="bold" />
                                                            <Item name="italic" />
                                                            <Item name="underline" />
                                                            <Item name="strike" />
                                                            <Item name="separator" />
                                                            <Item name="link" />
                                                            <Item name="header" acceptedValues={[false, 3, 4, 5]} />
                                                            <Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
                                                            <Item name="orderedList" />
                                                            <Item name="bulletList" />
                                                            <Item name="alignLeft" />
                                                            <Item name="alignCenter" />
                                                            <Item name="alignRight" />
                                                        </Toolbar>
                                                    </Wysiwyg>
                                                )}
                                            </Field>
                                            
                                        </div>
                                        </Col>
                                        <Col xs={12}>
                                        <div className="MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth">
                                            <label className="text-start MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{this.props.t('departmentDescEn')}</label>

                                            <Field 
                                                name="Department_Description_EN" 
                                                subscription={{error:true, initial: true}}
                                                disabled={this.props.viewMood}
                                            >
                                                {fieldProps => (
                                                    <Wysiwyg
                                                        rtlEnabled={this.props.i18n.language === 'ar' ? true : false}
                                                        height="300px"
                                                        defaultValue={this.props.initValues.Department_Description_EN}
                                                        onChange={fieldProps.input.onChange}
                                                        readOnly={this.props.viewMood}
                                                    >
                                                        <Toolbar
                                                            multiline={false}
                                                            
                                                        >
                                                            <Item name="undo" />
                                                            <Item name="redo" />
                                                            <Item name="separator" />
                                                            <Item name="bold" />
                                                            <Item name="italic" />
                                                            <Item name="underline" />
                                                            <Item name="strike" />
                                                            <Item name="separator" />
                                                            <Item name="link" />
                                                            <Item name="header" acceptedValues={[false, 3, 4, 5]} />
                                                            <Item name="size" acceptedValues={['11px', '14px', '16px', '22px']} />
                                                            <Item name="orderedList" />
                                                            <Item name="bulletList" />
                                                            <Item name="alignLeft" />
                                                            <Item name="alignCenter" />
                                                            <Item name="alignRight" />
                                                        </Toolbar>
                                                    </Wysiwyg>
                                                )}
                                            </Field>
                                            
                                        </div>
                                        </Col>
                                        <Col xs={12}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('DepartmentAddAr')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Address_AR'
                                                multiline
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('DepartmentAddEN')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Address_EN'
                                                multiline
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('mail')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Email'
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('facebook')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Facebook'
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('phone')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Telephone'
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('instagram')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Instagram'
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('website')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Website'
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('twitter')}
                                                className={`${this.props.viewMood ? 'read-only' : 'mb-2'}`}
                                                name='Department_Twitter'
                                            />
                                        </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6} className={`${this.props.viewMood ? 'read-only text-start' : 'text-start'}`}>
                                    <ButtonSecondary 
                                            className="mb-3"
                                            variant='outlined' 
                                            onClick={() => {
                                            this.getLocation()
                                        }}>
                                            {this.props.t('knowMyLocation')}
                                        </ButtonSecondary>
                                        <GoogleMap
                                            position={this.state.position}
                                            latLng={this.getLatLng} />
                                    </Col>
                                    <Col className='mt-4 mb-4' xs={12}>
                                        <Row>
                                            <Col className='mt-2 submit-btn-wrap'>
                                                { !this.props.viewMood && 
                                                    <ButtonPrimary
                                                        color='primary'
                                                        variant='contained'
                                                        disabled={submitting}
                                                        type='submit'>
                                                        {this.props.t('save')}
                                                    </ButtonPrimary>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                            </form>
                        )
                    }}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { triggerNotification, setLoading })(
	translationHook(DepartmentForm)
);
