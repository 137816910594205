import apiConfig from '../../apiConfig';

export const getServiceDropDownsList = async (at) => {
	const response = await apiConfig.post(
		'API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSMANAGEMENT/Get_All_Services_Lookups',
		{
			PageSize: 10000,
			PageIndex: 1,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Active_Status_Id === 1) {
				response.data.Data[i].Active_Status_Id = true;
			} else {
				response.data.Data[i].Active_Status_Id = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
