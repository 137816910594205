import apiConfig from "../apiConfig";

export const getRequestInputChangesList = async (at, data) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_REQUEST_INPUT_CHANGES_LIST",
		data,
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	return response;
};
