import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
	DataGrid,
	Column,
	Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	StateStoring
} from "devextreme-react/data-grid";
import apiConfig from './../../../apis/apiConfig';
import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import CustomStore from "devextreme/data/custom_store";
import DeleteIcon from "@material-ui/icons/Delete";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { getServiceCatList } from "../../../apis/services/ServiceCatList";
import { deleteService } from "../../../apis/services/deleteService";
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import {
	ButtonPrimary,
	ButtonSecondary,
	ButtonDanger,
	ButtonInfo,
	ButtonWarning,
} from "../../UI/Buttons";
import OrderView from "./orderView";
import {
	getNewRequestListGM,
	getapprovalRequestListGM,
	getAssignedRequestListGM,
	getMercyRequestListGM,
	getdepartmentsRequestListGM,
} from "../../../apis/dashboards/GMDashboard";
import moment from "moment";

class OrdersList extends Component {
	constructor(props) {
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

	renderForm = (...params) => {
		let viewMood = params[2] === "view" ? true : false,
			editMood = params[2] === "edit" ? true : false;

		return (
			<OrderView
				id={params[0]}
				initValues={params[1]}
				editMood={editMood}
				viewMood={viewMood}
			/>
		);
	};

	customizeCellCheckbox = ({ data }) => {
		return data.Request_Date ? data.Request_Date.slice(0, 10) : null;
	};

	handleEdit = (data, viewMood) => {
		let initValues = {
				id: data.Request_Id,
				number: data.Request_No,
				date: moment(data.Request_Date).lang("en").format("YYYY-MM-DD"),
				departmentId: data.Department_Id,
				departmentNameAR: data.Department_Name_AR,
				departmentNameEN: data.Department_Name_EN,
				serviceNo: data.Service_No,
				serviceNameAR: data.Service_Name_AR,
				serviceNameEN: data.Service_Name_EN,
				requestStatusAR: data.Request_Status_Name_AR,
				requestStatusEN: data.Request_Status_Name_EN,
				clientMail: data.Client_Mail,
				clientNameAR: data.Client_Name_AR,
				clientNameEN: data.Client_Name_EN,
				clientProfileId: data.Client_Profile_Id,
				clientPhone: data.Client_Phone,
				dealingTypeEN: data.Dealing_Type_Name_EN,
				dealingTypeAR: data.Dealing_Type_Name_AR,
			},
			requestId = data.Request_Id,
			requestName =
				this.props.i18n.language === "ar"
					? data.Service_Name_AR
					: data.Service_Name_EN,
			dialogTitle = `${
				viewMood === "view"
					? this.props.t("view")
					: this.props.t("edit")
			} ${requestName}`;

		this.props.triggerDialogForm(true, {
			title: dialogTitle,
			renderForm: this.renderForm,
			params: [requestId, initValues, viewMood],
		});
	};

	componentDidUpdate(prevProp) {
		if (this.props.reloadGrid) {
			this.dataGrid.instance.refresh().then(() => {
				this.props.dataLoadedCB(false);
			});
		}
	}

	saveState = async (state) => {
		const at = this.props.currentUser.Data.access_token,
		url ='API/SYSTEM/GENERAL/SAVE_USER_GRID_SETTINGS',
		bodyData = {
			System_Grid_Name: 'Grid_Dashboard_Requests',
			System_Grid_Json: JSON.stringify(state),
			Lang: this.props.i18n.language
		};

		await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		})
	}

	loadState = async () => {
		const at = this.props.currentUser.Data.access_token,
		url ='API/SYSTEM/GENERAL/GET_USER_GRID_SETTINGS',
		bodyData = {
			System_Grid_Name: 'Grid_Dashboard_Requests',
			Lang: this.props.i18n.language
		};

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		})
		return JSON.parse(response.data.Data)
	}

	render() {
		let columnAlign = "left",
			ServiceNameKey = "Service_Name_EN",
			DepartmentNameKey = "Department_Name_EN",
			RequestStatus = "Request_Status_Name_EN",
			dealingType = "Dealing_Type_Name_EN",
			AreaNameKey = "Area_Name_EN",
			clientName = "Client_Name_EN",
			stepName = "Department_Service_Step_Name_EN",
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === "ar") {
			columnAlign = "right";
			clientName = "Client_Name_AR";
			stepName = "Department_Service_Step_Name_AR";
			RequestStatus = "Request_Status_Name_AR";
			DepartmentNameKey = "Department_Name_AR";
			dealingType = "Dealing_Type_Name_AR";
			AreaNameKey = "Area_Name_AR";
			ServiceNameKey = "Service_Name_AR";
		}

		const jsonDataSource = new CustomStore({
			key: "Request_Id",
			load: async () => {
				let tab = this.props.dataField,
					response;

				switch (tab) {
					default:
						return [];

					case "New_Requests_Count":
						response = await getNewRequestListGM(at);
						return response;
						break;
					case "Approval_Requests_Count":
						response = await getapprovalRequestListGM(at);
						return response;
						break;
					case "Assigned_Requests_Count":
						response = await getAssignedRequestListGM(at);
						return response;
						break;
					case "Mercy_Requests_Count":
						response = await getMercyRequestListGM(at);
						return response;
						break;
					case "Department_Requests_Count":
						response = await getdepartmentsRequestListGM(at);
						return response;
						break;
				}
			},
		});
		
		let tab = this.props.dataField;

		return (
			<>
				<DataGrid
					className={[
						this.props.i18n.language === "ar"
							? "dx-grid-ar"
							: "dx-grid-en",
						"globalBox",
					].join(" ")}
					id="dataGrid"
					width={"100%"}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines={false}
					showRowLines={true}
					allowColumnResizing={true}
					columnResizingMode={"widget"}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={"auto"}
					wordWrapEnabled={true}
					rtlEnabled={
						this.props.i18n.language === "ar" ? true : false
					}
					ref={(ref) => (this.dataGrid = ref)}
				>
					<ColumnChooser
						enabled={true}
						allowSearch={true}
						search={"fff"}
						title={this.props.t("dxColumnChooserTitle")}
						emptyPanelText={this.props.t(
							"dxColumnChooserPanelText"
						)}
					/>

					<StateStoring 
						enabled={true} 
						type="custom"
						customLoad={this.loadState}
						customSave={this.saveState}
					/>

					<SearchPanel
						visible={true}
						width={240}
						placeholder={this.props.t("search")}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true} />

					<Grouping contextMenuEnabled={true} expandMode="rowClick" />
					<GroupPanel
						visible={true}
						emptyPanelText={this.props.t("dxGroupPanelText")}
					/>

					<Selection
						mode="multiple"
						selectAllMode={"allPages"}
						showCheckBoxesMode={"always"}
					/>

					<Column
						dataField={"Request_No"}
						caption={this.props.t("orderNumber")}
						alignment={columnAlign}
						width="220px"
					></Column>

					<Column
						dataField={"Request_Date"}
						caption={this.props.t("orderDate")}
						cellRender={this.customizeCellCheckbox}
						alignment={"center"}
						format="yyyy-MM-dd"
						dataType="date"
						width="150px"
					></Column>

					<Column
						dataField={ServiceNameKey}
						caption={this.props.t("ServiceName")}
						alignment={columnAlign}
						width={400}
					></Column>

					<Column
						dataField="Department_Service_Version_Name"
						caption={this.props.t("serviceVersion")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={stepName}
						caption={this.props.t("requestStepName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={DepartmentNameKey}
						caption={this.props.t("DepartmentName")}
						alignment={columnAlign}
						visible={false}
					></Column>

					<Column
						dataField={RequestStatus}
						caption={this.props.t("orderStatus")}
						alignment={"center"}
					></Column>

					{tab === "approvalRequest" ? (
						<Column
							dataField={RequestStatus}
							caption={this.props.t("backFrom")}
							alignment={columnAlign}
						></Column>
					) : (
						false
					)}

					{tab === "assignedRequest" ? (
						<Column
							dataField={RequestStatus}
							caption={this.props.t("assignedTO")}
							alignment={columnAlign}
						></Column>
					) : (
						false
					)}

					<Column
						dataField={dealingType}
						caption={this.props.t("dealingType")}
						alignment={"center"}
						width="130px"
					></Column>

					<Column
						dataField="Owner_Name"
						caption={this.props.t("ownerName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Plot_No"
						caption={this.props.t("plotNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={AreaNameKey}
						caption={this.props.t("AreaName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField="Project_No"
						caption={this.props.t("projectNo")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={clientName}
						caption={this.props.t("customerName")}
						alignment={columnAlign}
					></Column>

					<Column
						dataField={"Client_EID"}
						caption={this.props.t("customerID")}
						alignment={columnAlign}
						visible={false}
					></Column>

					<Column
						dataField={"Client_Phone"}
						caption={this.props.t("customerPhone")}
						alignment={columnAlign}
						visible={false}
					></Column>

					<Column
						dataField={"Client_Mail"}
						caption={this.props.t("customerEmail")}
						alignment={columnAlign}
						visible={false}
					></Column>

					<Column
						cssClass="outlinedColumn nowrapColumn"
						type="buttons"
						caption={this.props.t("details")}
						fixed={true}
						fixedPosition={
							this.props.i18n.language === "ar" ? "left" : "right"
						}
					>
						<Button
							render={(record) => {
								return (
									<ButtonInfo
										component={Link}
										variant="circleIcon"
										state={{
											requestId: record.data.Request_Id,
											requestTaskId:
												record.data.Request_Task_Id,
										}}
										to={"/view-request"}
									>
										<EditIcon />
									</ButtonInfo>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}

	onExporting(e) {
		let sheetTitle = this.props.t("GMDashboard");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	triggerNotification,
})(translationHook(OrdersList));
