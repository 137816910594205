import apiConfig from '../../apiConfig'

export const downloadRequestAttachment = async ( { at, File_Code } ) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DOWNLOAD_REQUEST_ATTACHMENT',
        {
            File_Code
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            },
            responseType: 'arraybuffer',
        }
    ).catch((error) => {
        return error.response
    })

    return response
}
