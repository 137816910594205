import apiConfig from "../../apiConfig";

export const testDepartmentTemplateAttachment = async (
	at,
	Department_Service_Version_Id,
	Template_Id,
	File_Code
) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/TEST_DEPARTMENT_SERVICE_TEMPLATE_FILE",
		{
			File_Code,
			Department_Service_Version_Id,
			Template_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
			responseType: 'arraybuffer',
		}
	);

	return response;
};
