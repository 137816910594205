import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import { CheckBox } from 'devextreme-react/check-box';
import CustomStore from 'devextreme/data/custom_store';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { getSupportRequestList } from '../../../apis/system/supportRequest/getSupportRequestList';
import { getSupportRequestAllList } from '../../../apis/system/supportRequest/getSupportRequestAllList';

import { triggerDialog, triggerDialogForm, setLoading, triggerNotification  } from '../../../actions';
import { translationHook } from '../../translationHook';
import { ButtonInfo } from '../../UI/Buttons';

import SupportRequestForm from './SupportRequestForm'
import apiConfig from '../../../apis/apiConfig'

class supportRequestList extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
    state = {
        allList: false
    }

	renderSectorForm = (...params) => {
		let viewMood = params[2] === 'view' ? true : false,
			editMood = params[2] === 'edit' ? true : false

        return <SupportRequestForm supportRequestId={params[0]} initValues={params[1]} editMood={editMood} viewMood={viewMood} />
    }

	handleEdit = (data, viewMood) => {
        let initValues = {
			supportRequestNo: data.Support_Request_No,
            pageURL: data.Page_URL,
            pageImage: data.Page_Image,
            pageInputsData: data.Page_Inputs_Data,
            errorRequestURL: data.Error_Request_URL,
            errorRequestPostedData: data.Error_Request_Posted_Data,
            errorRequestResponseData: data.Error_Request_Response_Data,
            clientNotes: data.Client_Notes,
			departmentNameEN: data.Department_Name_EN,
			departmentNameAR: data.Department_Name_AR,
			serviceNameEN: data.Service_Name_EN,
			serviceNameAR: data.Service_Name_AR,
			clientNameEN: data.Client_Name_EN,
			clientNameAR: data.Client_Name_AR,
			requestStatusNameAR: data.Support_Request_Status_Name_AR,
			requestStatusNameEN: data.Support_Request_Status_Name_EN, 
			requestStatusId: data.Support_Request_Status_Id, 
			processResultNotes: data.Process_Result_Notes,
			supportRequestType:  this.props.i18n.language === 'ar' ? 
			data.Support_Request_Type_Name_AR : data.Support_Request_Type_Name_EN
        },

        supportRequestId = data.Support_Request_Id,
        supportRequestTypeName = this.props.i18n.language === 'ar' ? data.Support_Request_Type_Name_AR : data.Support_Request_Type_Name_EN,
        dialogTitle = `${viewMood === 'view' ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${supportRequestTypeName}`

		
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderSectorForm,
            params: [
                supportRequestId,
                initValues,
				viewMood
            ]
        })
    }

	imgDownload = ({data}) => {
		return (
			<a 
				href='#!'
				onClick={async () => {
					let at = this.props.currentUser.Data.access_token
					const response = await apiConfig.post(
						'/API/ADMINISTRATION/SUPPORTREQUEST/DOWNLOAD_SUPPORT_REQUEST_IMG',
						{
							Support_Request_Id: data.Support_Request_Id
						},
						{
							headers: {
								Authorization: `Bearer ${at}`
							}
						}
					)
					if (response.data.Status) {
						let File_Bytes = `data:image/png;base64, ${response.data.Data.File_Bytes}`,
						File_Name = response.data.Data.File_Name,
						a = document.createElement("a"); //Create <a>
						a.href = File_Bytes; //Image Base64 Goes here
						a.download = File_Name; //File name Here
						a.click(); //Downloaded file
					}
				}}
			>
				{this.props.t('downloadImg')}
			</a>
		)
	}

	render() {
		let columnAlign = 'left',
            DepartmentNameKey = 'Department_Name_EN',
            ServiceNameKey = 'Service_Name_EN',
            clientKeyName = 'Client_Name_EN',
            requestStatusKey = 'Support_Request_Status_Name_EN',
			at = this.props.currentUser.Data.access_token;

		if (this.props.i18n.language === 'ar') {
			columnAlign = 'right'
			DepartmentNameKey = 'Department_Name_AR'
            ServiceNameKey = 'Service_Name_AR'
            clientKeyName = 'Client_Name_AR'
            requestStatusKey = 'Support_Request_Status_Name_AR'
		}

		const jsonDataSource = new CustomStore({
			key: 'Support_Request_Id',
			load: async () => {
                if (this.state.allList) {
                    return getSupportRequestAllList(at, this.props.currentUser.Data.Department_Id, this.props.currentUser.Data.User_Id);
                } else {
					const data = await getSupportRequestList(at, this.props.currentUser.Data.Department_Id, this.props.currentUser.Data.User_Id)
                    return data.reverse();
                }
			}
		});

		return (
			<>
				<SupportRequestForm />

                <div className="p-2">
                    <CheckBox
                        defaultValue={false} 
                        onValueChanged={() => this.setState({allList: !this.state.allList})} 
                    />
                    <span className='px-2'>{this.props.t('allRequests')}</span>
                </div>

				<DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

					<Column
                        dataField="Support_Request_No"
                        caption={this.props.t('supportRequestNo')}
                        alignment={'center'}
						width={'200px'}
					>
					</Column>

					<Column
                        dataField={DepartmentNameKey}
                        caption={this.props.t('DepartmentName')}
                        alignment={columnAlign}
					>
					</Column>
					
					<Column
                        dataField={ServiceNameKey}
                        caption={this.props.t('serviceName')}
                        alignment={columnAlign}
					>
					</Column>

					<Column cssClass="outlinedColumn"
                        dataField='Department_Service_Version_Name'
                        caption={this.props.t('serviceVersion')}
                        alignment={'center'}
						width={'100px'}
					>
					</Column>

					<Column
                        dataField={clientKeyName}
                        caption={this.props.t('client')}
                        alignment={columnAlign}
						visible={false}
					>
					</Column>
					
					<Column
                        dataField={requestStatusKey}
                        caption={this.props.t('supportRequestStatus')}
                        allowFiltering={false}
						alignment={'center'}
						width={'120px'}
					>
					</Column>

					<Column
                        dataField='Page_Image'
                        caption={this.props.t('pageImage')}
                        allowFiltering={false}
						cellRender={this.imgDownload}
						alignment={'center'}
						width={'120px'}
					>
					</Column>
				
					<Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
						<Button
							render={(record) => {
								return (
									<ButtonInfo
									variant='circleIcon'
									startIcon={<VisibilityIcon />}
									onClick={() => {
                                        this.handleEdit(record.data, 'view')
                                    }}
									>
										{/* {this.props.t('view')} */}
									</ButtonInfo>
								);
							}}
						/>
					</Column>
				</DataGrid>
			</>
		);
	}
	onExporting(e) {
		let sheetTitle = this.props.t('supportRequest')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, triggerNotification, setLoading })(
	translationHook(supportRequestList)
);


