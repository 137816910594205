import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { translationHook } from '../translationHook'
import { triggerDialog, triggerDialogForm, triggerNotification, setLoading } from '../../actions'
import { connect } from 'react-redux'
import { getSliderList } from '../../apis/portal/getSliderList'
import { deleteSlider } from '../../apis/portal/deleteSlider'
import Slider from './Slider'
import CustomStore from 'devextreme/data/custom_store'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from '@reach/router'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import { 
    ButtonPrimary, 
    ButtonSecondary, 
    ButtonDanger, 
    ButtonInfo, 
    ButtonWarning 
} from './../UI/Buttons'

import SliderForm from './SliderForm'

import Switch from '@material-ui/core/Switch';
import apiConfig  from '../../apis/apiConfig';
class SliderList extends Component {
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}

    customizeCellCheckbox = ({data}) => {
        return (
            <Switch
            checked={data.Active_Status_Id}
            name={`templateStatus_${data}`}
            onChange={async () => {
                console.log(data);
                delete data.Portal_Slider_Media_Url;
                delete data.Portal_Slider_Action_Url;
                let at = this.props.currentUser.Data.access_token,
                    url = '/API/ADMINISTRATION/PORTAL/SLIDERS_MANAGEMENT/upd_Slider';
                    
                    data.Active_Status_Id = data.Active_Status_Id ? 0 : 1

                    console.log(data);

                    const formData = new FormData();

                    formData.append('Data', JSON.stringify(data))

                    this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)
            
                    const response = await apiConfig.post(
                        url,
                        formData,
                        {
                            headers: {
                                Authorization: `Bearer ${at}`
                            },
                        }
                    ).catch((error) => {
                        return error.response
                    })
                    this.props.triggerNotification(true, response.data)
                    this.props.setLoading(false)
            }}
            />
        )
    }

    handleDelete = data => {
        let at = this.props.currentUser.Data.access_token,
        sliderid = data.Portal_Slider_Id,
        sliderName = this.props.i18n.language === 'ar' ? data.Portal_Slider_Title_AR : data.Portal_Slider_Title_EN,
        dialogTitle = `${this.props.t('confirmDeleteTitle')} ${sliderName}`,
        dialogMsg = this.props.t('confirmDeleteMsg')

        this.props.triggerDialog(true, {
            title: dialogTitle,
            message: dialogMsg,
            action: {
                action: deleteSlider,
                params: [
                    at,
                    sliderid
                ],
                text: this.props.t('deleteSlider')
            }
        })
    }

    imgPreview = data => {
        if (data.data.Portal_Slider_Type === 1) {
            return (
                <img 
                    src={data.value}
                    style={{width: '100%', height: '50px', objectFit: 'cover'}}
                />
            )
        } else {
            return (
                <a target='_blank' href={data.value}>{this.props.t('viewVideo')}</a>
            )
        }
    }

    render() {
        let columnAlign = 'left',
        SliderTitleKey = 'Portal_Slider_Title_EN',
        SliderDescriptionKey = 'Portal_Slider_Content_EN',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
            SliderTitleKey = 'Portal_Slider_Title_AR'
            SliderDescriptionKey = 'Portal_Slider_Content_AR'
        }

        const jsonDataSource = new CustomStore({
            key: 'Portal_Slider_Id',
            load: () => {
                return getSliderList(at)
            }
        })

        return (
            <>
            <DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

                <Column 
                    dataField={SliderTitleKey} 
                    caption={this.props.t('slideTitle')} 
                    alignment={columnAlign}
                >
                </Column>

                <Column 
                    dataField={SliderDescriptionKey} 
                    caption={this.props.t('slidedesc')} 
                    alignment={columnAlign}
                >
                </Column>

                <Column 
                    dataField='Portal_Slider_Media_Url' 
                    caption={this.props.t('slideAvatarButton')}
                    alignment='center'
                    cellRender={this.imgPreview}
                    
                >
                </Column>

                <Column 
                    dataField="Active_Status_Id" 
                    caption={this.props.t('slideStatus')} 
                    allowFiltering={false}
                    cellRender={this.customizeCellCheckbox}
                    width={'120px'}
                >
                </Column>

    

                <Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
                        <Button
                        render={(record) => {
                            return (
                                
                                <ButtonWarning
                                variant="circleIcon"
                                startIcon={<EditIcon />}
                                onClick={() => {
                                    this.props.triggerView(false, true)

                                    this.props.handleEdit(record.data)
                                }}
                                >
                                    {/* {this.props.t('edit')} */}
                                </ButtonWarning>
                            )
                        }}
                        />

                        <Button
                            render={(record) => {
                                return (
                                    
                                    <ButtonInfo
                                    variant="circleIcon"
                                    startIcon={<VisibilityIcon />}
                                    onClick={() => {
                                        this.props.triggerView(true, false)

                                        this.props.handleEdit(record.data)
                                    }}
                                    >
                                        {/* {this.props.t('view')} */}
                                    </ButtonInfo>
                                )
                            }}
                        />
                        
                        <Button
                            render={(record) => {
                                return (
                                    
                                    <ButtonDanger
                                    variant="circleIcon"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => this.handleDelete(record.data)}
                                    >
                                        {/* {this.props.t('delete')} */}
                                    </ButtonDanger>
                                )
                            }}
                        />
                    

                </Column>

            </DataGrid>
    
            </>
        )
    }
    
	onExporting(e) {
		let sheetTitle = this.props.t('manageSlider')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }

}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload,
    }
}

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, 
    triggerNotification,
    setLoading })(
	translationHook(SliderList)
)
