import { combineReducers } from "redux";
import currentUserReducer from "./currentUserReducer";
import notificationReducer from "./notificationReducer";
import currentPageReducer from "./currentPageReducer";
import dialogReducer from "./dialogReducer";
import dialogFormReducer from "./dialogFormReducer";
import reloadReducer from "./reloadReducer";
import loadingReducer from "./loadingReducer";
import currentDialogData from "./currentDialogData";

// use combineReducers method to combine all reducers created above
// the names of the keys is the names that will show in our state object in store
export default combineReducers({
  currentUser: currentUserReducer,
  notification: notificationReducer,
  currentPage: currentPageReducer,
  dialog: dialogReducer,
  dialogForm: dialogFormReducer,
  reload: reloadReducer,
  loading: loadingReducer,
  currentDialogData: currentDialogData,
});
