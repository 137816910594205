import apiConfig from "../../../apis/apiConfig";

const upload = (file, onUploadProgress, at, params = {}, endPoint, key = 'Attached_File') => {
  let formData = new FormData();

  formData.append('PARAMETERS', JSON.stringify(params));

  formData.append(key, file);

  return apiConfig.post(endPoint, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${at}`
    },
    onUploadProgress,
  });
};

const FileUploadService = {
  upload
};

export default FileUploadService; 