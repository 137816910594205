import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../translationHook'
import apiConfig from '../../apis/apiConfig'
import { ButtonPrimary } from './../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { triggerNotification, setLoading } from '../../actions'


class SuggestionsComplainsForm extends Component {

    onSliderSubmit = async (values, form) => {
        const bodyFormData = new FormData(),
        at = this.props.currentUser.Data.access_token,
        url = '/API/ADMINISTRATION/PORTAL/CLIENTMESSAGESMANAGEMENT/Close_Client_Message',
        bodyData = {
            Client_Message_Reply: values.messageReply
        }

        bodyData.Client_Message_Id = this.props.clientMessageId 

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)

        if (response.data.Status) {
            form.restart()
        }
    }

    render() {

        const validate = values => {
            const errors = {}

            if (!values.messageReply) {
                errors.messageReply = `${this.props.t('error.field_required')}`
            }
            return errors
        }

        return (
            <>
                <Form
                    onSubmit={this.onSliderSubmit}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (

                        <form
                            className="row"
                            onSubmit={handleSubmit}
                            noValidate
                            className='row mt-4 mx-3'
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    label={this.props.t('reply')}
                                    className="mb-2"
                                    name="messageReply"
                                    required={true}
                                />
                            </Col>
                                
                            <Col className='mt-4' xs={12}>
                                <Row>
                                    <Col className='mt-2 submit-btn-wrap'>
                                        <ButtonPrimary
                                            color="primary"
                                            variant="contained"
                                            disabled={submitting}
                                            type="submit"
                                        >
                                            {this.props.t('save')}
                                        </ButtonPrimary>
                                    </Col>
                                </Row>
                            </Col>
                               
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading })(translationHook(SuggestionsComplainsForm))