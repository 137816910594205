import apiConfig from "../apiConfig";

export const getServiceTemplatesList = async (at, Service_Id) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/SERVICES/GET_SERVICE_TEMPLATES_LIST",
		{
			Service_Id,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Selected === 1) {
				response.data.Data[i].Selected = true;
			} else {
				response.data.Data[i].Selected = false;
			}

			if (response.data.Data[i].Template_has_Invoices === 1) {
				response.data.Data[i].Template_has_Invoices = true;
			} else {
				response.data.Data[i].Template_has_Invoices = false;
			}

			if (response.data.Data[i].Template_Skip_API_Errors === 1) {
				response.data.Data[i].Template_Skip_API_Errors = true;
			} else {
				response.data.Data[i].Template_Skip_API_Errors = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
