import apiConfig from '../../apiConfig';

export const deleteUserGroupScreen = async (at, userGroupId) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/GROUPS/DEL_USERS_GROUP_SCREEN', 
		{
			User_Group_Screen_Id: userGroupId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
    
    return response.data;
}
