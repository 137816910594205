import apiConfig from '../apiConfig';

export const deleteDepartmentJobSection = async (at, jobSectionId) => {
	const response = await apiConfig.post(
		'/API/LOOKUPS/DEPARTMENTJOBS/DEL_DEPARTMENT_JOB_SECTION', 
		{
			Department_Job_Section_Id: jobSectionId
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    return response.data
};
