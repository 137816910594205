import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../translationHook'
import apiConfig from '../../apis/apiConfig'
import { ButtonPrimary, ButtonSecondary } from './../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form, Field } from 'react-final-form'
import { TextField, Switches, Select } from 'mui-rff'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Avatar from '@material-ui/core/Avatar'
import { triggerNotification, setLoading, triggerDialogForm } from '../../actions'
import { OnChange } from 'react-final-form-listeners'
import { Autocomplete } from 'devextreme-react/autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import AccountProfilesList from './AccountProfilesList';

class AccountProfileForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departments: [],
            departmentJobs: [],
            usersGroupList: [],
            initVal: {
                User_Name_AR:'',
                User_Name_EN:'',
                User_Phone:'',
                User_Mail:'',
                Login_Name:'',
                User_Group_Id: '',
                Lang_Id: '',
                Department_Id: '',
                Department_Job_Id: '',
                lang: '',
                userStatus: true,
                jobSignature: ''
            },
            activeDir: null
        };
    }

    getDepartments = async () => {
        let departmentArr = []
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_DEPARTMENTS'
        )
        if (response.data.Status) {
            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN
                departmentArr.push({value: item.Record_Id, label})
            })
            departmentArr.unshift({value: 0, label: this.props.t('withoutDep')})

            this.setState({departments: departmentArr})
        }
    }

    getDepartmentJobs = async (departmentId) => {
        let departmentJobsArr = [],
        at = this.props.currentUser.Data.access_token
        const response = await apiConfig.post(
            '/API/SYSTEM/DDL/GET_ALL_DEPARTMENT_JOBS', 
            {
                Department_Id: departmentId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        )

        if (response.data.Status) {
            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.Record_Name_AR : item.Record_Name_EN
                departmentJobsArr.push({value: item.Record_Id, label})
            })
            departmentJobsArr.unshift({value: 0, label: this.props.t('withoutJob')})


            this.setState({departmentJobs: departmentJobsArr})
        }
    }

    getUsersGroupList = async () => {
        let usersGroupListArr = [],
        at = this.props.currentUser.Data.access_token
        const response = await apiConfig.post(
            '/API/SYSTEM/USERS/GROUPS/GET_USERS_GROUPS_LIST', {},
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        )

        if (response.data.Status) {
            response.data.Data.map((item)=>{
                let label = this.props.i18n.language === 'ar' ? item.User_Group_Name_AR : item.User_Group_Name_EN
                usersGroupListArr.push({value: item.User_Group_Id, label})
            })
            usersGroupListArr.unshift({value: 0, label: this.props.t('delete')})

            this.setState({usersGroupList: usersGroupListArr})
        }
    }

    onUserSubmit = async (values, form) => {
        console.log(values, 'oo')
        console.log(this.props, 'oo')
        const at = this.props.currentUser.Data.access_token,
        url = '/API/SYSTEM/USERS/Upd_User_Profile_Data',
        bodyData = {
            Department_Id: values.department ? values.department : 0,
            Department_Job_Id: values.job ? values.job : 0,
            User_Group_Id: values.group,
            User_Id: this.props.userId,
            Active_Status_Id: values.userStatus ? 1 : 0,
            Main_Profile: this.props.mainProfile,
            User_Signature_Text: values.jobSignature
        }
     
        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        if (this.props.editMood) {
            // update user image with user id
            bodyData.User_Id = this.props.userId
        }

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).then(res => {
            this.props.triggerNotification(true, res.data)
            this.props.setLoading(false)
            // this.props.triggerDialogForm(false)
        }).catch(err => {
            this.props.setLoading(false)
        })
    }

    componentDidMount() {
        let { initValues , viewMood, editMood } = this.props;
        console.log(initValues)
        this.getDepartments();
        this.getUsersGroupList();
        this.getDepartmentJobs(initValues.department)
        if(initValues && (editMood || viewMood)) {
            this.setState({
                initVal: initValues,
            })
        }
    }

    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}
            if (!values.group) {
                errors.group = `${this.props.t('error.field_required')}`;
            }
            // if (!values.department) {
            //     errors.department = `${this.props.t('error.field_required')}`;
            // }
            // if (!values.job) {
            //     errors.job = `${this.props.t('error.field_required')}`;
            // }
            return errors
        }

        return (
            <>
                <Form
                    onSubmit={this.onUserSubmit}
                    onReset={this.onUserReset}
                    initialValues={initValues}
                    enableReinitialize={true}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values, handleReset }) => {
                        if (this.props.editMood && values.department && values.department !== '') {
                            this.getDepartmentJobs(values.department)
                        }

                        return (
                            <form
                                id="userform"
                                className={`row`}
                                onSubmit={handleSubmit}
                                onReset={handleReset}
                                noValidate
                            >
                                <Col xs={12} md={9}>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <Select
                                                disabled={this.props.viewMood}
                                                label={this.props.t('userPermissionsGroup')}
                                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                required={true}
                                                name="group"
                                                data={this.state.usersGroupList}
                                                showError={  () => {
                                                    let fieldStatus = form.getFieldState('group')
                                                    if (fieldStatus) {
                                                        return fieldStatus.submitFailed && fieldStatus.invalid
                                                    }
                                                }}
                                            >
                                            </Select>
                                        </Col>

                                        <Col xs={12} md={4}>
                                            <Select
                                                disabled={this.props.viewMood}
                                                label={this.props.t('departmentName')}
                                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                name='department'
                                                data={this.state.departments}
                                                showError={  () => {
                                                    let fieldStatus = form.getFieldState('department')
                                                    if (fieldStatus) {
                                                        return fieldStatus.submitFailed && fieldStatus.invalid
                                                    }
                                                }}
                                            >
                                            </Select>
                                        </Col>

                                        <OnChange name="department">
                                            {(value) => {this.getDepartmentJobs(value)}}
                                        </OnChange>

                                        <Col xs={12} md={4}>
                                            <Select
                                                disabled={this.props.viewMood}
                                                label={this.props.t('departmentJob')}
                                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                name="job"
                                                data={this.state.departmentJobs}
                                                showError={  () => {
                                                    let fieldStatus = form.getFieldState('departmentJobType')
                                                    if (fieldStatus) {
                                                        return fieldStatus.submitFailed && fieldStatus.invalid
                                                    }
                                                }}
                                            >
                                            </Select>
                                        </Col>

                                        <Col xs={12} md={3}>
                                            <TextField
                                                disabled={this.props.viewMood}
                                                label={this.props.t('jobSignature')}
                                                className={`${
                                                    this.props.viewMood ? 'read-only' : ''
                                                } mb-2`}
                                                name='jobSignature'
                                                showError={({ meta: { submitFailed, invalid } }) => {
                                                    return submitFailed && invalid;
                                                }}
                                            />
                                        </Col>

                                        <Col xs={12} md={4}>
                                            <Switches
                                                disabled={this.props.viewMood}
                                                color='primary'
                                                name='userStatus'
                                                data={{
                                                    label: `${this.props.t('userStatus')}`,
                                                    value: `${this.props.t('userStatus')}`,
                                                }}
                                            />
                                        </Col>

                                        <Col className='mt-2'>
                                            {!this.props.viewMood && (
                                                <ButtonPrimary
                                                    color='primary'
                                                    variant='contained'
                                                    disabled={submitting}
                                                    type='submit'>
                                                    {this.props.t('save')}
                                                </ButtonPrimary>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </form>
                        )
                    }}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(AccountProfileForm))
