import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../translationHook'
import { Row, Col } from 'react-bootstrap'
import { triggerNotification, setLoading, triggerDialogForm } from '../../actions'
import UsersList from './UsersList'
import UserForm from './UserForm'

class Users extends Component {

    state = {
        viewMood: false,
        editMood: false
    }

    triggerView = (viewMood, editMood) => {
        this.setState({viewMood, editMood})
    }

    handleEdit = (data) => {
        let initValues = {
            arUserName: data.User_Name_AR,
            enUserName: data.User_Name_EN,
            phone: data.User_Phone,
            mail: data.User_Mail,
            loginName: data.Login_Name,
            group: data.User_Group_Id,
            userStatus: data.Active_Status_Id,
            lang: data.Lang_Id,
            department: data.Department_Id,
            job: data.Department_Job_Id,
            AD_Ref: data.AD_Ref,
            imgUrl: data.User_Image,
            userAccountId: data.User_Account_Id,
            userSignatureFileCode: data.User_Signature_File_Code,
            userSignatureFile: data.User_Signature_File
        },

        userId = data.User_Id,
        userName = this.props.i18n.language === 'ar' ? data.User_Name_AR : data.User_Name_EN,
        dialogTitle = `${this.props.viewMood ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${userName}`
    
        this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                userId,
                initValues
            ],
            action: this.triggerView,
        })
    }

    renderEditForm = (...params) => {
        return <UserForm userId={params[0]} editMood={this.state.editMood} viewMood={this.state.viewMood} initValues={params[1]} triggerView={this.triggerView} />
    }

    render() {

        return (
            <>
                <Row>
                    {!this.state.viewMood && !this.state.editMood &&
                        <Col xs={12} className='mb-5'>
                            <UserForm />
                        </Col>
                    }

                    <Col xs={12}>
                        <UsersList triggerView={this.triggerView} handleEdit={this.handleEdit} />
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(Users))
