import React from 'react'

import Notes from '../Notes'
import { saveRequestViolationSettlement } from '../../../../apis/requests/processTask'

import ViolationSettlementForm from './ViolationSettlementForm'

export const ViolationSettlement = (props) => {
    
    return (
        <>
        <ViolationSettlementForm requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestViolationSettlement} />
        
        </>
    )
}
