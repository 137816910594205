import React, { Component } from 'react'
import { translationHook } from '../../translationHook'
import { triggerDialog, triggerDialogForm, setLoading, triggerNotification } from '../../../actions'
import { connect } from 'react-redux'
import { getCreatedAccounts } from './../../../apis/accounts/Accounts'
import CustomStore from 'devextreme/data/custom_store'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import moment from 'moment';


class CustomerInternalViolations extends Component {
	constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
	}
    state = {
        requestType: '4'
    }



    handleChange = (e) => {
        this.setState({
            requestType: e.target.value
        })
    }


    render() {
        let violationStatus = "Violations_Status_Name_EN",
        depNameKey = 'Department_Name_EN';

        if  (this.props.i18n.language === 'ar') {
            violationStatus = "Violations_Status_Name_AR"
            depNameKey = 'Department_Name_AR'
        }

        const jsonDataSource = new CustomStore({
            key: 'Violation_Id',
            load: () => {
                return this.props.internalViolations
            }
        })

        return (
            <>
                <DataGrid
					className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
					id="dataGrid"
					width={'100%'}
					dataSource={jsonDataSource}
					showBorders={false}
					showColumnLines= {false}
					showRowLines= {true}
					allowColumnResizing={true}
					columnResizingMode={'widget'}
					allowColumnReordering={true}
					rowAlternationEnabled={false}
					focusedRowEnabled={true}
					onExporting={this.onExporting}
					columnWidth={'auto'}
					wordWrapEnabled={true}
					rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
				>

					<ColumnChooser enabled={true} allowSearch={true} search={'fff'}
						title={this.props.t('dxColumnChooserTitle')}
						emptyPanelText={this.props.t('dxColumnChooserPanelText')}
					/>
					
					<SearchPanel 
						visible={true}
						width={240}
						placeholder={this.props.t('search')}
					/>

					<FilterRow visible={true} />

					<HeaderFilter visible={true} />

					<Export enabled={true} allowExportSelectedData={true}/>

					<Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                	<GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

					<Selection
						mode="multiple"
						selectAllMode={'allPages'}
						showCheckBoxesMode={'always'}
					/>

                    <Column cssClass="outlinedColumn"
                        dataField={'Violation_No'} 
                        caption={this.props.t('internalViolationNo')} 
                        alignment={'center'}
                    >
                    </Column>
                    <Column 
                        cssClass="outlinedColumn"
                        dataField={'Violation_Date'} 
                        caption={this.props.t('internalViolationDate')} 
                        alignment={'center'}
                        cellRender={({data}) => {
                            if(data.Violation_Date != null) {
                                return moment(data.Violation_Date).format('HH:mm YYYY-MM-DD')
                            } else {
                                return '';
                            }
                        }}
                    >   
                    </Column>
                    <Column 
                        cssClass="outlinedColumn"
                        dataField={violationStatus}
                        caption={this.props.t('internalViolationType')}
                        alignment={'center'}
                        
                    >
                    </Column>
                    <Column 
                        dataField={depNameKey}
                        caption={this.props.t('internalViolationDepartment')} 
                        alignment={'center'}>
                    </Column>

                </DataGrid>
            </>
        )
    }
      
	onExporting(e) {
		let sheetTitle = this.props.t('manageAccounts')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        reload: state.reload,
    }
}

export default connect(mapStateToProps, { triggerDialog, triggerDialogForm, setLoading, triggerNotification })(
	translationHook(CustomerInternalViolations)
)
