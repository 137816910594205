import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../../translationHook";
import apiConfig from "../../../apis/apiConfig";
import { ButtonPrimary } from "../../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { TextField, Switches, Select } from "mui-rff";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../../actions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Tab, Nav } from "react-bootstrap";
import style from "./DashboardForm.module.scss";
import { ButtonSecondary } from "./../../UI/Buttons";
import { Link } from "@reach/router";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
  Pager,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import Switch from "@material-ui/core/Switch";

class DashboardForm extends Component {
  state = {
    key: 0,
    next: 1,
    prev: -1,
    showForm: false,
    targetType: this.props.initValues?.Dashboard_Target_Type
      ? this.props.initValues?.Dashboard_Target_Type.toString()
      : "1",
    dashboardTargetsList: [],
    dashboardId: this.props.dashboardId,
  };

  handleNext = () => {
    this.setState({
      key: this.state.next,
      next: this.state.next + 1,
      prev: this.state.prev + 1,
    });
  };

  handlePrev = () => {
    this.setState({
      key: this.state.prev,
      next: this.state.next - 1,
      prev: this.state.prev - 1,
    });
  };

  getDashboardTargetsList = async () => {
    const response = await apiConfig.post(
      "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/GET_DASHBOARD_TARGETS_LIST",
      {
        Dashboard_Target_Type: this.state.targetType,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    let dataArray = [];
    if (response?.data?.Data) {
      response.data.Data.map((ele) => {
        let label = "";
        let value = "";
        if (this.state.targetType === "1") {
          label =
            this.props.i18n.language === "ar"
              ? ele.Department_Job_Type_Name_AR
              : ele.Department_Job_Type_Name_EN;
          value = ele.Department_Job_Type_Id;
        } else if (this.state.targetType === "2") {
          label =
            this.props.i18n.language === "ar"
              ? ele.Department_Job_Name_AR
              : ele.Department_Job_Name_EN;
          value = ele.Department_Job_Id;
        } else if (this.state.targetType === "3") {
          label =
            this.props.i18n.language === "ar"
              ? ele.User_Name_AR
              : ele.User_Name_EN;
          value = ele.User_Id;
        }
        return dataArray.push({ value, label });
      });
    }
    this.setState({ dashboardTargetsList: dataArray });
  };

  // getDashboardInfo = async () => {
  //   const response = await apiConfig.post(
  //     "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/GET_DASHBOARD_INFO",
  //     {
  //       Dashboard_Id: this.state.dashboardId,
  //       Dashboard_Target_Type: "3",
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
  //       },
  //     }
  //   );

  //   console.log(response.data.Data);
  // };

  onSubmit = async (values, form) => {
    let at = this.props.currentUser.Data.access_token,
      url = !this.props.editMood
        ? "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/ADD_DASHBOARD"
        : "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/UPD_DASHBOARD",
      bodyData = {
        Dashboard_Name_AR: values.Dashboard_Name_AR,
        Dashboard_Name_EN: values.Dashboard_Name_EN,
        Dashboard_Active_Status_Id:
          values.Dashboard_Active_Status_Id === true ||
          values.Dashboard_Active_Status_Id === 1
            ? 1
            : 0,
        Dashboard_Target_Type: Number(this.state.targetType),
        Dashboard_Target_Id: values.Dashboard_Target_Id,
      };

    if (this.props.editMood) {
      bodyData.Dashboard_Id = this.state.dashboardId;
    } else {
      bodyData.Dashboard_Id = this.props.currentUser.Data.dashboardId;
    }

    this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

    const response = await apiConfig
      .post(url, bodyData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .catch((error) => {
        return error.response;
      });

    if (response.data.Status) {
      this.setState({ dashboardId: response.data.Data[0].Dashboard_Id });
      // form.restart();
      // this.setState({ showForm: false });
    }

    if (this.props.editMood) {
      // this.props.triggerDialogForm(false);
    }

    this.props.triggerNotification(true, response.data);

    this.props.setLoading(false);
  };

  renderChevs = () => {
    let rtl = this.props.i18n.language === "ar" ? true : false,
      iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
      iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

    return (
      <>
        <IconButton
          aria-label="prev"
          color="primary"
          onClick={this.handlePrev}
          disabled={this.state.key > 0 ? false : true}
        >
          {iconPrev}
        </IconButton>
        <IconButton
          aria-label="next"
          color="primary"
          onClick={this.handleNext}
          disabled={
            (this.state.key < 1 && !this.state.second ? false : true) ||
            this.state.dashboardId === undefined
          }
        >
          {iconNext}
        </IconButton>
      </>
    );
  };

  componentDidMount() {
    this.getDashboardTargetsList();
    // this.getDashboardInfo();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.targetType !== this.state.targetType) {
      this.getDashboardTargetsList();
    }
  };

  handleDashboardState = ({ data }) => {
    return (
      <FormControlLabel
        control={
          <Switch
            disabled={this.props.viewMood}
            checked={data.Selected === 0 ? false : true}
            name={`dashboardCards_${data.Selected}`}
            onChange={async () => {
              this.props.setLoading(true, "dark");

              if (data.Selected === 0) {
                const response = await apiConfig.post(
                  "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/ADD_DASHBOARD_CARD",
                  {
                    Dashboard_Id: this.state.dashboardId,
                    Dashboard_Card_Id: data.Dashboard_Card_Id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
                    },
                  }
                );
              } else {
                const response = await apiConfig.post(
                  "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/DEL_DASHBOARD_CARD",
                  {
                    Dashboard_Id: this.state.dashboardId,
                    Dashboard_Card_Id: data.Dashboard_Card_Id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
                    },
                  }
                );
              }

              this.props.setLoading(false);
              this.dataGrid?.instance?.refresh();
            }}
          />
        }
      />
    );
  };

  getDashboardCardsList = async () => {
    const response = await apiConfig.post(
      "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/GET_DASHBOARD_CARDS_LIST",
      {
        Dashboard_Id: this.state.dashboardId,
      },
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );
    return response.data.Data;
  };

  render() {
    let { initValues } = this.props;
    let mystyle;
    if (this.props.i18n.language === "ar") {
      mystyle = "ServiceTabsAr";
    } else {
      mystyle = "ServiceTabsEn";
    }

    let dashboardCardName = "Dashboard_Card_Name_AR",
      dashboardCardTypeName = "Dashboard_Card_Type_Name_AR",
      columnAlign = "center";

    if (this.props.i18n.language === "en") {
      dashboardCardName = "Dashboard_Card_Name_EN";
      dashboardCardTypeName = "Dashboard_Card_Type_Name_EN";
    }

    const validate = (values) => {
      const errors = {};
      if (!values.Dashboard_Name_AR) {
        errors.Dashboard_Name_AR = `${this.props.t("error.field_required")}`;
      }

      if (!values.Dashboard_Name_EN) {
        errors.Dashboard_Name_EN = `${this.props.t("error.field_required")}`;
      }

      if (!values.Dashboard_Target_Id) {
        errors.Dashboard_Target_Id = `${this.props.t("error.field_required")}`;
      }

      return errors;
    };

    const jsonDataSource = new CustomStore({
      key: "Dashboard_Card_Id",
      load: () => {
        return this.getDashboardCardsList();
      },
    });

    return (
      <>
        {!this.props.editMood && !this.props.viewMood && (
          <div className="d-flex justify-content-between align-items-center pb-5">
            <h3>{this.props.t("addDashboard")}</h3>
            <ButtonPrimary
              variant={!this.state.showForm ? "contained" : "outlined"}
              onClick={() => {
                this.props.handleShowTable(!this.state.showForm);
                this.setState({ showForm: !this.state.showForm });
              }}
            >
              {!this.state.showForm && this.props.t("addDashboard")}
              {this.state.showForm && this.props.t("returnToDashboardMenu")}
            </ButtonPrimary>
          </div>
        )}

        <Form
          onSubmit={this.onSubmit}
          initialValues={
            initValues
              ? initValues
              : {
                  Dashboard_Name_AR: "",
                  Dashboard_Name_EN: "",
                  Dashboard_Active_Status_Id: true,
                  Dashboard_Target_Type: "",
                }
          }
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            if (
              !this.props.editMood &&
              !this.props.viewMood &&
              !this.state.showForm
            ) {
              if (this.state.key !== 0) {
                this.setState({ key: 0, next: 1, prev: -1 });
              }
              form.restart();
            }

            return (
              <form
                id="sliderform"
                className={`row ${
                  !this.state.showForm &&
                  !this.props.editMood &&
                  !this.props.viewMood
                    ? "d-none"
                    : ""
                }`}
                onSubmit={handleSubmit}
                noValidate
              >
                <Col sm={12}>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={0}
                    activeKey={this.state.key}
                    onSelect={(k) =>
                      this.setState({
                        key: parseInt(k),
                        next: parseInt(k) + 1,
                        prev: parseInt(k) - 1,
                      })
                    }
                    unmountOnExit="true"
                  >
                    {this.props.currentPage ===
                      "/housing-departments/add-module" && (
                      <ButtonSecondary
                        variant="outlined"
                        component={Link}
                        to="/housing-departments"
                        size="large"
                        className="ml-auto d-flex row col-2"
                      >
                        {this.props.t("exitModules")}
                        <ExitToAppIcon />
                      </ButtonSecondary>
                    )}
                    <div className="row mt-5 mb-3">
                      <div className="col-2">{this.renderChevs()}</div>
                      <Nav
                        variant="pills"
                        className={[
                          style.stepsNav,
                          "col-8 justify-content-center",
                        ].join(" ")}
                      >
                        <Nav.Item className={mystyle}>
                          <Nav.Link
                            className={`${style.navLink} ${
                              this.state.key === 0 ? style.active : ""
                            }`}
                            eventKey="0"
                            disabled={this.state.first}
                          >
                            {this.props.t("dashboardInformation")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={mystyle}>
                          <Nav.Link
                            className={`${style.navLink} ${
                              this.state.key === 1 ? style.active : ""
                            }`}
                            eventKey="1"
                            disabled={
                              this.state.second ||
                              this.state.dashboardId === undefined
                            }
                          >
                            {this.props.t("dashboardCards")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>

                    <Tab.Content>
                      <Tab.Pane
                        eventKey={0}
                        disabled={this.state.second}
                        unmountOnExit="true"
                      >
                        <Row>
                          <Col xs={12} md={4}>
                            <TextField
                              disabled={this.props.viewMood}
                              label="اسم لوحة التحكم"
                              className="mb-2"
                              name="Dashboard_Name_AR"
                              required={true}
                            />
                          </Col>

                          <Col xs={12} md={4}>
                            <TextField
                              disabled={this.props.viewMood}
                              label="Dashboard Name"
                              className="mb-2"
                              name="Dashboard_Name_EN"
                              required={true}
                            />
                          </Col>

                          <Col xs={12} md={4}>
                            <Switches
                              disabled={this.props.viewMood}
                              color="primary"
                              name="Dashboard_Active_Status_Id"
                              data={{
                                label: `${this.props.t("serviceStatus")}`,
                                value: `${this.props.t("serviceStatus")}`,
                              }}
                            />
                          </Col>

                          <Col xs={12}>
                            <RadioGroup
                              row
                              name="Dashboard_Target_Type"
                              value={this.state.targetType}
                              onChange={(e) => {
                                this.setState({
                                  targetType: e.target.value,
                                });
                              }}
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label={this.props.t("onJobTypeLevel")}
                                disabled={this.props.viewMood}
                              />
                              <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label={this.props.t("onJobLevel")}
                                disabled={this.props.viewMood}
                              />
                              <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label={this.props.t("onUserLevel")}
                                disabled={this.props.viewMood}
                              />
                            </RadioGroup>
                          </Col>

                          <Col xs={12} md={6}>
                            <Select
                              name="Dashboard_Target_Id"
                              data={this.state.dashboardTargetsList}
                              onChange={(e) => {
                                form.change(
                                  "Dashboard_Target_Id",
                                  e.target.value
                                );
                              }}
                              disabled={this.props.viewMood}
                            ></Select>
                          </Col>

                          <Col
                            className="my-4 justify-content-start d-flex"
                            xs={12}
                          >
                            {!this.props.viewMood && (
                              <ButtonPrimary
                                color="primary"
                                variant="contained"
                                disabled={submitting || this.props.viewMood}
                                type="submit"
                              >
                                {this.props.t("save")}
                              </ButtonPrimary>
                            )}
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey={1}
                        disabled={this.state.second}
                        unmountOnExit="true"
                      >
                        <Row>
                          <Col xs={12}>
                            <DataGrid
                              className={[
                                this.props.i18n.language === "ar"
                                  ? "dx-grid-ar"
                                  : "dx-grid-en",
                                "globalBox",
                              ].join(" ")}
                              id="dataGrid"
                              width={"100%"}
                              dataSource={jsonDataSource}
                              showBorders={false}
                              showColumnLines={false}
                              showRowLines={true}
                              allowColumnResizing={true}
                              columnResizingMode={"widget"}
                              allowColumnReordering={true}
                              rowAlternationEnabled={false}
                              focusedRowEnabled={true}
                              onExporting={this.onExporting}
                              columnWidth={"auto"}
                              wordWrapEnabled={true}
                              rtlEnabled={
                                this.props.i18n.language === "ar" ? true : false
                              }
                              ref={(ref) => (this.dataGrid = ref)}
                            >
                              <Pager
                                visible="true"
                                allowedPageSizes={[5, 10, 20, 50, 100]}
                                displayMode="full"
                                showPageSizeSelector="true"
                                showInfo="true"
                                infoText={`${this.props.t(
                                  "page"
                                )} {0} ${this.props.t(
                                  "of"
                                )} {1} ({2} ${this.props.t("item")})`}
                                showNavigationButtons="true"
                              />
                              <ColumnChooser
                                enabled={true}
                                allowSearch={true}
                                search={"fff"}
                                title={this.props.t("dxColumnChooserTitle")}
                                emptyPanelText={this.props.t(
                                  "dxColumnChooserPanelText"
                                )}
                              />
                              <SearchPanel
                                visible={true}
                                width={240}
                                placeholder={this.props.t("search")}
                              />
                              <FilterRow visible={true} />
                              <HeaderFilter visible={true} />
                              <Export
                                enabled={true}
                                allowExportSelectedData={true}
                              />
                              <Grouping
                                contextMenuEnabled={true}
                                expandMode="rowClick"
                              />
                              <GroupPanel
                                visible={true}
                                emptyPanelText={this.props.t(
                                  "dxGroupPanelText"
                                )}
                              />
                              <Selection
                                mode="multiple"
                                selectAllMode={"allPages"}
                                showCheckBoxesMode={"always"}
                              />
                              <Column
                                caption={this.props.t("choice")}
                                alignment={columnAlign}
                                cellRender={this.handleDashboardState}
                                width={200}
                              ></Column>
                              <Column
                                dataField={dashboardCardName}
                                caption={this.props.t("cardName")}
                                alignment={columnAlign}
                                width={150}
                              ></Column>
                              <Column
                                dataField={dashboardCardTypeName}
                                caption={this.props.t("cardType")}
                                alignment={columnAlign}
                                width={150}
                              ></Column>
                            </DataGrid>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Col>
              </form>
            );
          }}
        />
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(DashboardForm));
