import apiConfig from "../apiConfig";

export const SPVdashboardStatistics = async (at) => {
	const response = await apiConfig.post(
		"API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_STATISTICS",
		{},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getNewRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_NEW_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getApprovalRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_APPROVAL_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getAssignedRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_ASSIGNED_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getLateRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_LATE_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getMercyRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_MERCY_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getReturnedRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_RETURN_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data;
};
export const getUnderProcessRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR//GET_SUPERVISORS_DASHBOARD_UNDER_PROCESS_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getSectionRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_SECTION_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getTransferredRequestListSPV = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_TRANSFEERED_REQUESTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
export const getMissionList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISORS_DASHBOARD_MISSIONS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};

export const getReturnedFromClientList = async (at) => {
	const response = await apiConfig.post(
		"/API/ADMINISTRATION/DASHBOARD/SUPERVISOR/GET_SUPERVISOR_DASHBOARD_REQUESTS_RETURN_FROM_CLIENTS_LIST",
		{
			pageIndex: 1,
			pagecount: 1000,
		},
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);
	return response.data.Data ? response.data.Data : [];
};
