import apiConfig from '../apiConfig'
export const getSystemSetting  = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/SETTINGS/GET_SYSTEM_SETTINGS',{},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
    return response.data.Data;
};
export const getWorkingData  = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/SETTINGS/GET_WORKING_DAYS',{},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
    return response.data.Data;
};
export const deleteTemplateAttachment = async (params) => {
      const response = await apiConfig.post(
        '/API/SYSTEM/SETTINGS/DELETE_INSPECTION_TEMPLATE_FILE',
        {
          File_Code: params.File_Code
        },
        {
            headers: {
                Authorization: `Bearer ${params.at}`
            }
        }
      ).catch((error) => {
          return error.response
      })

      return response
  }
export const uploadFile = async (params , at) => {
        const response = apiConfig.post('/API/SYSTEM/SETTINGS/SAVE_INSPECTION_TEMPLATE_FILE', params, {
            headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${at}`
        }})
        ;

    return response
  }
  
