import apiConfig from '../apiConfig'

export const deleteServiceStep = async (...params) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/SERVICES/DEL_SERVICE_STEP',
        {
            Service_Step_Id: params[1]
        },
        {
            headers: {
                Authorization: `Bearer ${params[0]}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
