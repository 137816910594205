import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary , ButtonSecondary} from '../../UI/Buttons'

import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


class AddModuleGallery extends Component {

    state = {
        showForm: false,
        file: null
    }
    handleAddFiles = (e) => {
		this.setState({
			file: e.target.files[0],
			fileName: e.target.files[0].name,
		});
	};

    onSectorSubmit = async (values, form) => {
		const bodyFormData = new FormData(),
        at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/ADD_APPROVING_CHART_IMAGE' : '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/UPD_APPROVING_CHART_IMAGE',
        bodyData = {
			approving_Chart_Id: this.props.moduleID,
			approving_Chart_Image_Name: values.approving_Chart_Image_Name,
            Active_Status_Id: values.Active_Status_Id ? 1 : 0
        }
		// Active_Status_Id: 1
        if ( this.props.editMood ) {
            bodyData.Approving_Chart_Image_Id = this.props.moduleImageID
        }   
		

        bodyFormData.append('PARAMETERS', JSON.stringify(bodyData))
        bodyFormData.append('Approving_Chart_Image', this.state.file)
        this.props.setLoading(true, 'dark')

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            this.props.onAddItem()
            form.restart()
            this.setState({
                showForm: !this.state.showForm,
                fileName: null
            })
        }   

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)
    }

    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}
            if (!values.approving_Chart_Image_Name) {
                errors.approving_Chart_Image_Name = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                <div className="d-flex justify-content-between align-items-center pb-5">
                    <h3>{this.props.t('gallery')}</h3>
                    <ButtonPrimary
                        variant={!this.state.showForm ? 'contained' : 'outlined'}
                        onClick={() => this.setState({showForm: !this.state.showForm}) }
                    >
                        {!this.state.showForm && this.props.t('addPhoto')}
                        {this.state.showForm && this.props.t('cancel')}
                    </ButtonPrimary>
                </div>
                <Form
                    onSubmit={this.onSectorSubmit}
                    initialValues={initValues ? initValues : {
                        approving_Chart_Image_Name: '',
                        Active_Status_Id: true
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('imageName')}
                                    className={this.props.viewMood ? 'read-only':"mb-2 read-only"}
                                    name="approving_Chart_Image_Name"
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <Switches
                                disabled={this.props.viewMood}
                                color="primary"
                                name="Active_Status_Id"
                                data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                />
                            </Col>
                            <Col className='my-4 d-flex ml-auto my-4' xs={12} md={12}>
                                <ButtonSecondary
                                    color='primary'
                                    variant='contained'
                                    >
                                        {/* endIcon={} */}
                                    {this.props.t('mainImage')}
                                    <CloudUploadIcon />
                                    <input
                                        type='file'
                                        name='Approving_Chart_File'
                                        className='buttonFile'
                                        accept='image/*'
                                        multiple
                                        onChange={(e) => this.handleAddFiles(e)}
                                    />
                                </ButtonSecondary>
                                {this.state.fileName && (
                                    <p>{this.state.fileName}</p>
                                )}
                            </Col>
                        <Col className='my-4 justify-content-start d-flex' xs={12}>
                            <ButtonPrimary
                                color="primary"
                                variant="contained"
                                disabled={submitting || this.props.viewMood}
                                type="submit"
                            >
                                {this.props.t('save')}
                            </ButtonPrimary>
                        </Col>
                       
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(AddModuleGallery))
