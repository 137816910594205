import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { Select, TextField, Checkboxes } from 'mui-rff'
import { ButtonDanger, ButtonPrimary } from '../../UI/Buttons'
import apiConfig from '../../../apis/apiConfig'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import DeleteIcon from '@material-ui/icons/Delete'
import TreeView from 'devextreme-react/tree-view'
import Sortable from 'devextreme-react/sortable'
import { translationHook } from '../../translationHook'
import { deleteDepartmentVersionStep } from '../../../apis/services/departments/deleteDepartmentVersionStep'
import {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification
} from '../../../actions'
import ServiceVersionUnselectedStep from './ServiceVersionUnselectedStep'

class ServiceVersionStep extends Component {
	constructor(props) {
		super(props);
		this.treeViewRef = React.createRef();
		this.onDragChange = this.onDragChange.bind(this);
    	this.onDragEnd = this.onDragEnd.bind(this);
	}

	state = {
		stepJobs: [],
		treeData: [],
		activeStep: {}
	}

	renderUnselectedServiceStep = () => {
		return (
			<ServiceVersionUnselectedStep 
				versionid={this.props.versionid}
				handleStepsChange={this.getDepartmentVersionStepsTree}
			/>
		);
	}

	handleAddStep = () => {
		this.props.triggerDialogForm(true, {
			title: "",
			renderForm: this.renderUnselectedServiceStep,
			params: [],
		});
	}

	handleDelete = async () => {
		let at = this.props.currentUser.Data.access_token

        this.props.setLoading(true, 'dark')
    
        const response = await apiConfig.post(
			'/API/ADMINISTRATION/DEPARTMENTS/SERVICES/DEL_DEPARTMENT_VERSION_STEP',
            {
                Department_Service_Step_Id: this.state.activeStep.Department_Service_Step_Id
            },
            {
            headers: {
                Authorization: `Bearer ${at}`
            }
        })
		.then(res => {
			if (res.data.Status) {
				this.getDepartmentVersionStepsTree()
				this.setState({ activeStep: {} })
			}
			this.props.setLoading(false)
			this.props.triggerNotification(true, res.data)
		})
	}

	getDepartmentVersionStepInfo = async (departmentServiceStepId) => {
		this.props.setLoading(true, 'dark');

		let at = this.props.currentUser.Data.access_token
		const response = await apiConfig.post(
			"API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_VERSION_STEP_INFO",
			{
				Department_Service_Step_Id: departmentServiceStepId
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)

		if (response.data.Status) {
			this.setState({ activeStep: response.data.Data })
		}

		this.props.setLoading(false)
	}

	getDepartmentVersionStepsTree = async () => {
		this.props.setLoading(true, 'dark');
		let at = this.props.currentUser.Data.access_token
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_VERSION_STEPS_TREE_LIST",
			{
				Department_Service_Version_Id: this.props.versionid,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)

		if (response.data.Status) {
			let data = response.data.Data
			this.setState({ treeData: data })
		}

		this.props.setLoading(false)
	}

	getDepartmentSectionJobs = async () => {
		let at = this.props.currentUser.Data.access_token,
			departmentsArr = [];
		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/GET_DEPARTMENT_SECTION_JOBS",
			{
				Department_Service_Version_Id: this.props.versionid,
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Department_Job_Name_AR
						: item.Department_Job_Name_EN;

				departmentsArr.push({ value: item.Department_Job_Id, label });
			});

			this.setState({ stepJobs: departmentsArr });
		}
	}

	handleActiveStep = data => {
		if (data.isDirectory !== true) {
			this.getDepartmentVersionStepInfo(data.Department_Service_Step_Id)
		}
	}

	onDepartmentVersionStepChange = async ( jobId ) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPD_DEPARTMENT_VERSION_STEP",
			bodyData = {
				Department_Service_Step_Id: this.state.activeStep.Department_Service_Step_Id,
				Department_Job_Id: jobId,
			};

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			url, bodyData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			})
			.then(res => {
				if (res.data.Status) {
					this.getDepartmentVersionStepInfo(this.state.activeStep.Department_Service_Step_Id)
				}
				this.props.triggerNotification(true, res.data);
				this.props.setLoading(false);
			}
		)
	}

	handleCheckboxes = async(templatesFilesState, chartsFiledState) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SAVE_DEPARTMENT_VERSION_STEPS_OPERATIONS_SETTINGS",
			bodyData = {
				Department_Service_Step_Id: this.state.activeStep.Department_Service_Step_Id,
				Show_Templates_Files: templatesFilesState ? 1 : 0,
				Show_Charts_Files: chartsFiledState ? 1 : 0
			};

		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		})
		.catch((error) => {
			return error.response;
		})
		.then(res => {
			if (res.data.Status) {
				this.getDepartmentVersionStepInfo(this.state.activeStep.Department_Service_Step_Id)
			}
			this.props.setLoading(false)
			this.props.triggerNotification(true, res.data)
		})
	}

	// On Reorder
	handleUpdateTree = async (stepsArr) => {
		let at = this.props.currentUser.Data.access_token;
		this.props.setLoading(true, "dark");

		const response = await apiConfig.post(
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SAVE_DEPARTMENT_VERSION_STEPS_TREE",
			{
				Department_Service_Version_Id: this.props.versionid,
				Steps: stepsArr
			},
			{
				headers: {
					Authorization: `Bearer ${at}`,
				},
			}
		)
		.catch((error) => {
			return error.response;
		});

		this.getDepartmentVersionStepsTree()

		this.props.setLoading(false);
		this.props.triggerNotification(true, response.data);
		this.props.reload();
	}

	componentDidMount() {
		this.getDepartmentVersionStepsTree();
		this.getDepartmentSectionJobs();
	}

	render() {

		let treeStyle
		if (this.props.i18n.language === "ar") {
			treeStyle = {
				borderLeft: '2px solid #6b6c6a'
			}
		} else {
			treeStyle = {
				borderRight: '2px solid #6b6c6a'
			}
		}

		return (
			<Row>
				{!this.props.viewMood && (
					<Col xs={12}>
						<div className="d-flex justify-content-between align-items-center mb-4">
							<ButtonPrimary
								size="large"
								onClick={this.handleAddStep}
							>
								{this.props.t("addNewStep")}
							</ButtonPrimary>
						</div>
					</Col>
				)}

				<Col md={3} xs={12} style={treeStyle}>
					<Sortable
						filter=".dx-treeview-item"
						group="shared"
						data="stepsTree"
						allowDropInsideItem={true}
						allowReordering={true}
						onDragChange={this.onDragChange}
						onDragEnd={this.onDragEnd}
						expandAllEnabled={true}
					>
						<TreeView
							id="treeview"
							expandNodesRecursive={true}
							ref={this.treeViewRef}
							items={this.state.treeData}
							displayExpr={this.props.i18n.language === "ar" ? 'Department_Service_Step_Name_AR' : 'Department_Service_Step_Name_EN'}
							width={'100%'}
							dataStructure="plain"							
							keyExpr="id"
							parentIdExpr="parentId"
							rtlEnabled={this.props.i18n.language === "ar" ? true : false}
							onItemClick={e => this.handleActiveStep(e.itemData)}
							noDataText={this.props.t("noData")}
						/>
					</Sortable>
				</Col>

				{Object.keys(this.state.activeStep).length !== 0 ?
					<Col md={9} xs={12}>
						<Form
							onSubmit={() => {}}
							initialValues={{
								sectionJob: this.state.activeStep.Department_Job_Id,
								stepNo: this.state.activeStep.Department_Service_Step_No,
								stepName: this.props.i18n.language == 'ar' ? this.state.activeStep.Department_Service_Step_Name_AR : this.state.activeStep.Department_Service_Step_Name_EN,
								stepType: this.props.i18n.language == 'ar' ? this.state.activeStep.Service_Step_Type_Name_AR : this.state.activeStep.Service_Step_Type_Name_EN,
								jobType: this.props.i18n.language == 'ar' ? this.state.activeStep.Department_Job_Type_Name_AR : this.state.activeStep.Department_Job_Type_Name_EN,
								notificationType: this.props.i18n.language == 'ar' ? this.state.activeStep.Notification_Name_AR : this.state.activeStep.Notification_Name_AR,
								notification: this.state.activeStep.Department_Service_Step_Send_Notification,
								chartsFiled: this.state.activeStep.Show_Charts_Files == 0 ? false : true,
								templatesFiles: this.state.activeStep.Show_Templates_Files == 0 ? false : true
							}}
							render={({ handleSubmit, form, values }) => {
								return (
									<form
										id="serviceStep"
										className="row"
										onSubmit={handleSubmit}
									>
										<Col md={6} xs={12}>
											<TextField
												className={'read-only mb-2'}
												name="stepNo"
												disabled
												label={this.props.t('stepNo')}
											/>
										</Col>

										<Col md={6} xs={12}>
											<TextField
												className={'read-only mb-2'}
												name="stepName"
												disabled
												label={this.props.t('stepName')}
											/>
										</Col>

										<Col md={6} xs={12}>
											<TextField
												className={'read-only mb-2'}
												name="stepType"
												disabled
												label={this.props.t('stepType')}
											/>
										</Col>

										{this.state.activeStep.Department_Service_Step_Type_Id != 1 ? 
											<Col md={6} xs={12}>
												<TextField
													className={'read-only mb-2'}
													name="jobType"
													disabled
													label={this.props.t('workGroup')}
												/>
											</Col>
										:null}

										<Col md={6} xs={12}>
											<TextField
												className={'read-only mb-2'}
												name="notificationType"
												disabled
												label={this.props.t('notificationType')}
											/>
										</Col>

										{this.state.activeStep.Department_Service_Step_Type_Id != 1 ?
											<Col md={6} xs={12}>
												<Select
													disabled={this.state.activeStep.Can_Edit_Step == 0 ? true : false}
													label={this.props.t('departmentJobName')}
													className={'read-only mb-2'}
													name="sectionJob"
													data={this.state.stepJobs}
													onChange={(e) => {
														form.change("sectionJob", e.target.value);
														this.onDepartmentVersionStepChange(e.target.value)
													}}
												>
												</Select>
											</Col>
										:null}

										<Col md={6} xs={12}>
											<Checkboxes
												className="mb-2"
												name="notification"
												data={{label: this.props.t('notification')}}
												disabled
												color="default"
											/>
										</Col>

										{this.state.activeStep.Department_Service_Step_Screen_Id == 28 ? 
											<>
												<Col md={6} xs={12}>
													<Checkboxes
														className="mb-2"
														name="templatesFiles"
														data={{label: this.props.t('showTemplatesFiles')}}
														disabled={this.state.activeStep.Can_Edit_Step == 0 ? true : false}
														onChange={e => {
															this.handleCheckboxes(e.target.checked, values.chartsFiled)
															form.change("templatesFiles", e.target.checked);
														}}
													/>
												</Col>

												<Col md={6} xs={12}>
													<Checkboxes
														className="mb-2"
														name="chartsFiled"
														data={{label: this.props.t('showChartsFiles')}}
														disabled={this.state.activeStep.Can_Edit_Step == 0 ? true : false}
														onChange={e => {
															form.change("chartsFiled", e.target.checked);
															this.handleCheckboxes(values.templatesFiles, e.target.checked)
														}}													
													/>
												</Col>
											</>
										:null}

										{this.state.activeStep.Can_Delete_Step !== 0 ?
											<Col xs={12} className='d-flex justify-content-end'>
												<ButtonDanger
													size="large"
													onClick={this.handleDelete}
												>
													<DeleteIcon />
													{this.props.t("delete")}
												</ButtonDanger>
											</Col>
										:null}
									</form>
								)
							}}
						/>
					</Col>
				: null}
			</Row>
		)
	}


	onDragChange(e) {
		if (e.fromComponent === e.toComponent) {
			const fromNode = this.findNode(this.treeViewRef.current.instance, e.fromIndex);
			const toNode = this.findNode(this.treeViewRef.current.instance, this.calculateToIndex(e));

			if (fromNode.itemData.isDirectory) {
				e.cancel = true;
			}

			if (toNode !== null && this.isChildNode(fromNode, toNode)) {
				e.cancel = true;
			}
		}
	}
	
	onDragEnd(e) {
		if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
			return;
		}

		const fromTreeView = this.treeViewRef.current.instance;
		const toTreeView = this.treeViewRef.current.instance;

		const fromNode = this.findNode(fromTreeView, e.fromIndex);
		const toNode = this.findNode(toTreeView, this.calculateToIndex(e));


		if (e.dropInsideItem && toNode !== null && !toNode.itemData.isDirectory) {
			return;
		}

		const fromTopVisibleNode = this.getTopVisibleNode(e.fromComponent);
		const toTopVisibleNode = this.getTopVisibleNode(e.toComponent);

		const fromItems = this.state.treeData;
		const toItems = this.state.treeData;

		this.moveNode(fromNode, toNode, fromItems, toItems, e.dropInsideItem);

		this.setState({
			treeData: [...fromItems],
			treeData: [...toItems]
		});

		fromTreeView.scrollToItem(fromTopVisibleNode);
		toTreeView.scrollToItem(toTopVisibleNode);

		let stepsArr = []
		this.state.treeData.map((s, i) => {
			stepsArr.push({
				Order: i,
				id: s.id,
				parentId: s.parentId
			})
		})

		this.handleUpdateTree(stepsArr)
	}
	
	calculateToIndex(e) {
		if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
			return e.toIndex;
		}

		return e.fromIndex >= e.toIndex
			? e.toIndex
			: e.toIndex + 1;
	}
	
	findNode(treeView, index) {
		const nodeElement = treeView.element().querySelectorAll('.dx-treeview-node')[index];
		if (nodeElement) {
			return this.findNodeById(treeView.getNodes(), nodeElement.getAttribute('data-item-id'));
		}
		return null;
	}
	
	findNodeById(nodes, id) {
		for (let i = 0; i < nodes.length; i += 1) {
			if (nodes[i].itemData.id === id) {
			return nodes[i];
			}
			if (nodes[i].children) {
			const node = this.findNodeById(nodes[i].children, id);
			if (node != null) {
				return node;
			}
			}
		}
		return null;
	}
	
	moveNode(fromNode, toNode, fromItems, toItems, isDropInsideItem) {
		const fromIndex = fromItems.findIndex((item) => item.id === fromNode.itemData.id);
		fromItems.splice(fromIndex, 1);

		const toIndex = toNode === null || isDropInsideItem
			? toItems.length
			: toItems.findIndex((item) => item.id === toNode.itemData.id);
		toItems.splice(toIndex, 0, fromNode.itemData);

		this.moveChildren(fromNode, fromItems, toItems);
		if (isDropInsideItem) {
			fromNode.itemData.parentId = toNode.itemData.id;
		} else {
			fromNode.itemData.parentId = toNode != null
			? toNode.itemData.parentId
			: undefined;
		}
	}
	
	moveChildren(node, fromDataSource, toDataSource) {
		if (!node.itemData.isDirectory) {
			return;
		}

		node.children.forEach((child) => {
			if (child.itemData.isDirectory) {
			this.moveChildren(child, fromDataSource, toDataSource);
			}

			const fromIndex = fromDataSource.findIndex((item) => item.id === child.itemData.id);
			fromDataSource.splice(fromIndex, 1);
			toDataSource.splice(toDataSource.length, 0, child.itemData);
		});
	}
	
	isChildNode(parentNode, childNode) {
		let { parent } = childNode;
		while (parent !== null) {
			if (parent.itemData.id === parentNode.itemData.id) {
			return true;
			}
			parent = parent.parent;
		}
		return false;
	}
	
	getTopVisibleNode(component) {
		const treeViewElement = component.element();
		const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
		const nodes = treeViewElement.querySelectorAll('.dx-treeview-node');
		for (let i = 0; i < nodes.length; i += 1) {
			const nodeTopPosition = nodes[i].getBoundingClientRect().top;
			if (nodeTopPosition >= treeViewTopPosition) {
			return nodes[i];
			}
		}

		return null;
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
}

export default connect(mapStateToProps, {
	triggerDialog,
	triggerDialogForm,
	setLoading,
	reload,
	triggerNotification,
})(translationHook(ServiceVersionStep))



