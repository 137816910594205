import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { ButtonPrimary } from '../../UI/Buttons';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField, Select, Switches } from 'mui-rff';
import NotificationUpdateForm from './NotificationUpdateForm';
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from '../../../actions';

class NotificationForm extends Component {
	state = {
		showForm: false,
		showUpdateMood: false,
		notificationsTypes: [],
	};

	getnotificationsTypes = async () => {
		let notificationsTypes = [];
		const response = await apiConfig.post(
			'/API//SYSTEM/NOTIFICATIONS/GET_BASIC_NOTIFICATIONS_LIST', {},
		{
			headers: {
				Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
			},
		}
		);

		if (response.data.Status) {
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === 'ar'
						? item.Notification_Name_AR
						: item.Notification_Name_EN;

				notificationsTypes.push({ value: item.Notification_Id, label });
			});

			this.setState({ notificationsTypes });
		}
	};

	initvaluesState =  {
		NameAR: 'name ar'
	}

	componentDidMount() {
        this.getnotificationsTypes()
    }
	
	validate = (values) => {
		const errors = {};
		if (this.props.editMood) {
		} else {
		if (!values.notificationId) {
			errors.notificationId = `${this.props.t('error.field_required')}`;
		}

		if (!values.NameEN) {
			errors.NameEN = `${this.props.t('error.field_required')}`;
		}

		if (!values.NameAR) {
			errors.NameAR = `${this.props.t('error.field_required')}`;
		}
	}
	return errors;
	}
	// ? this.state.initvaluesState: this.initvaluesState
	renderEditForm = (...params) => {
		console.log(params);
		return <NotificationUpdateForm notificationId={params[0]}/>
	}

	handleEditForm = async (data) => {
		let notificationId = data.Notification_Id,
        notificationName = this.props.i18n.language === 'ar' ? data.Notification_Name_AR : data.Notification_Name_EN,
        dialogTitle = `${this.props.t('editServiceDetailTitle')} ${notificationName}`

        await this.props.triggerDialogForm(true, {
            title: dialogTitle,
            renderForm: this.renderEditForm,
            params: [
                notificationId,
				
            ]
        })
    }

	// submit Form
	onSectionSubmit = async (values, form) => {
		let at = this.props.currentUser.Data.access_token,
			url = !this.props.editMood
				? '/API/SYSTEM/NOTIFICATIONS/ADD_NOTIFICATION'
				: '/API/SYSTEM/NOTIFICATIONS/UPD_NOTIFICATION',
			bodyData = {
				Notification_Id: values.notificationId,
				Notification_Name_AR: values.NameAR,
				Notification_Name_EN: values.NameEN,
                enable_SMS: values.smsstatus
			};

		// if (this.props.editMood) {
		//     bodyData.enable_SMS = values.smsstatus
		//     bodyData.enable_Mail = values.mailStatus
		//     bodyData.enable_Web = values.webStatus
		//     bodyData.enable_Mobile = values.mobileStatus
		//     bodyData.mail_Reply_To = values.mailReplyTo
		//     bodyData.mail_To_CC = values.mailCC
		//     bodyData.mail_To_BCC = values.mailBCC
		//     bodyData.notification_Top_Priority = values.
		//     bodyData.smS_MSG_AR = values.smsMsgAr
		//     bodyData.smS_MSG_EN = values.smsMsgEn
		//     bodyData.mail_Title_MSG_AR = values.mailTitleAR
		//     bodyData.mail_Title_MSG_EN = values.mailTitleEN
		//     bodyData.mail_Body_MSG_AR = values.mailbodyAR
		//     bodyData.mail_Body_MSG_EN = values.mailbodyEN
		//     bodyData.web_Title_MSG_AR = values.webtitleAR
		//     bodyData.web_Title_MSG_EN = values.webTitleEN
		//     bodyData.web_Body_MSG_AR = values.webMsgAR
		//     bodyData.web_Body_MSG_EN = values.webMsgEN
		//     bodyData.mobile_Title_MSG_AR = values.mobiletitleAR
		//     bodyData.mobile_Title_MSG_EN = values.mobileTitleEN
		//     bodyData.mobile_Body_MSG_AR = values.mobileMsgAR
		//     bodyData.mobile_Body_MSG_EN = values.mobileMsgEN
		// }

		this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900);

		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}).catch(error => error)

		if (response.data.Status) {
			form.restart();
		}

		if (!this.props.editMood) {
			this.handleEditForm(response.data.Data)
		} else {
			this.setState({
                showUpdateMood: true
			})
        }

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);
	};

	render() {
		return (
			<>
				{!this.props.editMood && !this.props.viewMood && (
					<div className='d-flex justify-content-between align-items-center pb-5'>
						<h3>{this.props.t('manageNotifications')}</h3>
						<ButtonPrimary
							variant={!this.state.showForm ? 'contained' : 'outlined'}
							onClick={() => this.setState({ 
                                showForm: !this.state.showForm,
                                showUpdateMood: false,
                            })}>
							{!this.state.showForm && this.props.t('addNotification')}
							{this.state.showForm && this.props.t('cancel')}
						</ButtonPrimary>
					</div>
				)}
				{this.props.editMood || this.state.showUpdateMood ? (
					// <NotificationUpdateForm notificationI={} />
					<></>
				) : (
					// Add Mood Form
					<Form
						onSubmit={this.onSectionSubmit}
						initialValues={{
							notificationId: '',
							NameEN: '',
							NameAR: '',
						}}
						validate={this.validate}
						render={({ handleSubmit, form, submitting, pristine, values }) => (
							<form
								id='notificationForm'
								className={`row ${
									!this.state.showForm &&
									!this.props.editMood &&
									!this.props.viewMood
										? 'd-none'
										: ''
								}`}
								onSubmit={handleSubmit}
								noValidate>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t('notificationNameAR')}
										className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
										name='NameAR'
										required={true}
										autoComplete='off'
										showError={({ meta: { submitFailed, invalid } }) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>

								<Col xs={12} md={6}>
									<TextField
										disabled={this.props.viewMood}
										label={this.props.t('notificationNameEN')}
										className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
										name='NameEN'
										required={true}
										autoComplete='off'
										showError={({ meta: { submitFailed, invalid } }) => {
											return submitFailed && invalid;
										}}
									/>
								</Col>
                                <Col xs={12} md={6}>
									<Select
										disabled={this.props.viewMood}
										label={this.props.t('notificationsTypes')}
										className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
										required={true}
										name='notificationId'
										data={this.state.notificationsTypes}
										showError={() => {
											let fieldStatus = form.getFieldState('notificationId');

											if (fieldStatus) {
												return fieldStatus.submitFailed && fieldStatus.invalid;
											}
										}}></Select>
								</Col>

								<Col className='my-4 justify-content-start d-flex' xs={12}>
									{!this.props.viewMood && (
										<ButtonPrimary
											color='primary'
											variant='contained'
											disabled={submitting || this.props.viewMood}
											type='submit'>
											{this.props.t('save')}
										</ButtonPrimary>
									)}
								</Col>
							</form>
						)}
					/>
				)}
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(NotificationForm));
