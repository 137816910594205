import { Component } from "react";
import { connect } from "react-redux";

import {
  DataGrid,
  Column,
  Button,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
  Pager,
} from "devextreme-react/data-grid";
import { ButtonDanger, ButtonInfo, ButtonWarning } from "../../UI/Buttons";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { ExcelJS, Workbook } from "exceljs";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import saveAs from "file-saver";
import CustomStore from "devextreme/data/custom_store";
import apiConfig from "../../../apis/apiConfig";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
  triggerDialog,
} from "../../../actions";
import { translationHook } from "../../translationHook";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DashboardForm from "./DashboardForm";

class UsersDashboardMenu extends Component {
  state = {
    showTable: true,
  };

  renderDashboardForm = (...params) => {
    let viewMood = params[2] === "view" ? true : false,
      editMood = params[2] === "edit" ? true : false;

    return (
      <DashboardForm
        dashboardId={params[0]}
        initValues={params[1]}
        editMood={editMood}
        viewMood={viewMood}
      />
    );
  };

  getDashboardsList = async () => {
    const response = await apiConfig.post(
      "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/GET_DASHBOARDS_LIST",
      {},
      {
        headers: {
          Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
        },
      }
    );

    return response.data.Data;
  };

  deleteDashboard = async (at, dashboardId) => {
    const response = await apiConfig.post(
      "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/DEL_DASHBOARD",
      {
        Dashboard_Id: dashboardId,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      return response.data;
    }

    return [];
  };

  handleDashboardState = ({ data }) => {
    let label =
      this.props.i18n.language === "ar"
        ? data.Dashboard_Active_Status_Name_AR
        : data.Dashboard_Active_Status_Name_EN;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={data.Dashboard_Active_Status_Id === 0 ? false : true}
            name={`dashboard_${data.Dashboard_Active_Status_Id}`}
            onChange={async () => {
              this.props.setLoading(true, "dark");

              const response = await apiConfig.post(
                "/API/ADMINISTRATION/DASHBOARDS/MANAGEMENT/SET_DASHBOARD_STATUS",
                {
                  Dashboard_Id: data.Dashboard_Id,
                  Dashboard_Active_Status_Id:
                    data.Dashboard_Active_Status_Id === 0 ? 1 : 0,
                },
                {
                  headers: {
                    Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
                  },
                }
              );
              this.props.setLoading(false);
              this.dataGrid?.instance?.refresh();
            }}
          />
        }
        label={label}
      />
    );
  };

  handleEdit = (data, viewMood) => {
    let initValues = {
        Dashboard_Name_AR: data.Dashboard_Name_AR,
        Dashboard_Name_EN: data.Dashboard_Name_EN,
        Dashboard_Active_Status_Id: data.Dashboard_Active_Status_Id,
        Dashboard_Target_Type: data.Dashboard_Target_Type,
        Dashboard_Target_Id: data.Dashboard_Target_Id,
      },
      dashboardId = data.Dashboard_Id,
      dashboardName =
        this.props.i18n.language === "ar"
          ? data.Dashboard_Name_AR
          : data.Dashboard_Name_EN,
      dialogTitle = `${
        viewMood === "view"
          ? this.props.t("viewServiceDetailTitle")
          : this.props.t("editServiceDetailTitle")
      } ${dashboardName}`;

    this.props.triggerDialogForm(true, {
      title: dialogTitle,
      closeBtnTitle: this.props.t("returnToDashboardMenu"),
      renderForm: this.renderDashboardForm,
      params: [dashboardId, initValues, viewMood],
    });
  };

  handleDelete = (data) => {
    let at = this.props.currentUser.Data.access_token,
      dashboardId = data.Dashboard_Id,
      dashboardName =
        this.props.i18n.language === "ar"
          ? data.Dashboard_Name_AR
          : data.Dashboard_Name_EN,
      dialogTitle = `${this.props.t("confirmDeleteTitle")} ${dashboardName}`,
      dialogMsg = this.props.t("confirmDeleteMsg");

    this.props.triggerDialog(true, {
      title: dialogTitle,
      closeBtnTitle: this.props.t("returnToDashboardMenu"),
      message: dialogMsg,
      action: {
        action: this.deleteDashboard,
        params: [at, dashboardId],
        text: this.props.t("deleteDashboard"),
      },
    });
  };

  handleShowTable = (state) => {
    this.setState({ showTable: !state });
  };

  render() {
    let dashboardName = "Dashboard_Name_AR",
      departmentName = "Department_Name_AR",
      departmentJobTypeName = "Department_Job_Type_Name_AR",
      departmentJobName = "Department_Job_Name_AR",
      userName = "User_Name_AR",
      dashboardStatus = "Dashboard_Active_Status_Name_AR",
      columnAlign = "center";

    if (this.props.i18n.language === "en") {
      dashboardName = "Dashboard_Name_EN";
      departmentName = "Department_Name_EN";
      departmentJobTypeName = "Department_Job_Type_Name_EN";
      dashboardStatus = "Dashboard_Active_Status_Name_EN";
      departmentJobName = "Department_Job_Name_EN";
      userName = "User_Name_EN";
    }

    const jsonDataSource = new CustomStore({
      key: "Dashboard_Id",
      load: () => {
        return this.getDashboardsList();
      },
    });

    return (
      <>
        <DashboardForm handleShowTable={this.handleShowTable} />

        {this.state.showTable && (
          <DataGrid
            className={[
              this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
              "globalBox",
            ].join(" ")}
            id="dataGrid"
            width={"100%"}
            dataSource={jsonDataSource}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            allowColumnResizing={true}
            columnResizingMode={"widget"}
            allowColumnReordering={true}
            rowAlternationEnabled={false}
            focusedRowEnabled={true}
            onExporting={this.onExporting}
            columnWidth={"auto"}
            wordWrapEnabled={true}
            rtlEnabled={this.props.i18n.language === "ar" ? true : false}
            ref={(ref) => (this.dataGrid = ref)}
          >
            <Pager
              visible="true"
              allowedPageSizes={[5, 10, 20, 50, 100]}
              displayMode="full"
              showPageSizeSelector="true"
              showInfo="true"
              infoText={`${this.props.t("page")} {0} ${this.props.t(
                "of"
              )} {1} ({2} ${this.props.t("item")})`}
              showNavigationButtons="true"
            />

            <ColumnChooser
              enabled={true}
              allowSearch={true}
              search={"fff"}
              title={this.props.t("dxColumnChooserTitle")}
              emptyPanelText={this.props.t("dxColumnChooserPanelText")}
            />

            <SearchPanel
              visible={true}
              width={240}
              placeholder={this.props.t("search")}
            />

            <FilterRow visible={true} />

            <HeaderFilter visible={true} />

            <Export enabled={true} allowExportSelectedData={true} />

            <Grouping contextMenuEnabled={true} expandMode="rowClick" />
            <GroupPanel
              visible={true}
              emptyPanelText={this.props.t("dxGroupPanelText")}
            />

            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"always"}
            />

            <Column
              dataField={dashboardName}
              caption={this.props.t("dashboardName")}
              alignment={columnAlign}
              width={150}
            ></Column>
            <Column
              dataField={departmentName}
              caption={this.props.t("municipal")}
              alignment={columnAlign}
              width={150}
            ></Column>
            <Column
              dataField={departmentJobTypeName}
              caption={this.props.t("jobType")}
              alignment={columnAlign}
              width={150}
            ></Column>
            <Column
              dataField={departmentJobName}
              caption={this.props.t("job")}
              alignment={columnAlign}
              width={150}
            ></Column>
            <Column
              dataField={userName}
              caption={this.props.t("employeeName")}
              alignment={columnAlign}
              width={150}
            ></Column>

            <Column
              dataField={dashboardStatus}
              caption={this.props.t("dashboardStatus")}
              alignment={columnAlign}
              cellRender={this.handleDashboardState}
              width={150}
            ></Column>

            <Column
              cssClass="outlinedColumn nowrapColumn"
              type="buttons"
              caption={this.props.t("details")}
              fixed={true}
              fixedPosition={
                this.props.i18n.language === "ar" ? "left" : "right"
              }
            >
              <Button
                render={(record) => {
                  return (
                    <ButtonWarning
                      variant="circleIcon"
                      startIcon={<EditIcon />}
                      onClick={() => {
                        this.handleEdit(record.data, "edit");
                      }}
                    >
                      {/* {this.props.t('edit')} */}
                    </ButtonWarning>
                  );
                }}
              />

              <Button
                render={(record) => {
                  return (
                    <ButtonInfo
                      variant="circleIcon"
                      startIcon={<VisibilityIcon />}
                      onClick={() => {
                        this.handleEdit(record.data, "view");
                      }}
                    >
                      {/* {this.props.t('view')} */}
                    </ButtonInfo>
                  );
                }}
              />

              <Button
                render={(record) => {
                  return (
                    <ButtonDanger
                      variant="circleIcon"
                      startIcon={<DeleteIcon />}
                      onClick={() => this.handleDelete(record.data)}
                    >
                      {/* {this.props.t('delete')} */}
                    </ButtonDanger>
                  );
                }}
              />
            </Column>
          </DataGrid>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerDialog,
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(UsersDashboardMenu));
