import apiConfig from "../../apiConfig";

export const updateDepartmentTemplateAttachment = async (
	at,
	Department_Service_Version_Id,
	Department_Service_Id,
	Template_Id,
	file
) => {
	const bodyFormData = new FormData(),
		url =
			"/API/ADMINISTRATION/DEPARTMENTS/SERVICES/UPDATE_DEPARTMENT_SERVICE_TEMPLATE_FILE",
		bodyData = {
			Department_Service_Id,
			Department_Service_Version_Id,
			Template_Id,
		};

	bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

	bodyFormData.append("Attached_File", file);

	const response = await apiConfig.post(url, bodyFormData, {
		headers: {
			Authorization: `Bearer ${at}`,
		},
	});

	return response;
};
