import React, { Component } from "react";
import { connect } from "react-redux";
import apiConfig from "../../../../apis/apiConfig";
import { translationHook } from "../../../translationHook";
import style from "./IntegrationSearch.module.scss";
import { ButtonPrimary, ButtonSecondary } from "../../../UI/Buttons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
} from "../../../../actions";
import FormControl from "@material-ui/core/FormControl";
import DateBox from "devextreme-react/date-box";

class IntegrationSearch extends Component {
	state = {
		searchTypeId: "3",
		searchResult: "",
	};

	onSearchSubmit = async (values, form) => {
		let url =
				this.props.templateApi === 1
					? "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_TEMPLATE_CHART_APPROVING_INTEGRATION_DATA"
					: "/API/ADMINISTRATION/REQUESTS/MANAGEMENT/GET_TEMPLATE_BUILDING_PERMISSION_INTEGRATION_DATA",
			at = this.props.currentUser.Data.access_token,
			body = {
				Request_Id: this.props.requestId,
				Request_Task_Id: this.props.requestTaskId,
				Temp_Action_Id: this.props.tempId,
				Search_Type: this.state.searchTypeId,
				Template_Id: this.props.templateId,
				Intg_Req_No: values.Intg_Req_No,
				Intg_Req_Year: values.Intg_Req_Year,
				Certificate_No: values.Certificate_No,
				Certificate_Year: values.Certificate_Year,
				Certificate_Date: values.Certificate_Date,
				Request_No: values.Request_No,
			};

		this.props.setLoading(true, "dark");

		const response = await apiConfig
			.post(url, body, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

		if (response.data.Status) {
			this.setState({ searchResult: response.data.Data });
		}
	};

	render() {
		const validate = (values) => {
			const errors = {};

			return errors;
		};

		return (
			<>
				<Form
					onSubmit={this.onSearchSubmit}
					initialValues={{
						Search_Type: this.state.searchTypeId,
						Intg_Req_No: 0,
						Intg_Req_Year: 0,
						Certificate_No: 0,
						Certificate_Year: 0,
						Certificate_Date: "",
						Request_No: this.props.requestNo,
					}}
					validate={validate}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							id="returnStepForm"
							className="row"
							onSubmit={handleSubmit}
							noValidate
						>
							<Col xs={12}>
								<FormControl className="mt-3">
									<label className={style.radioLabel}>
										{this.props.t("searchIntegrationBy")}
									</label>
									<RadioGroup
										row
										name="Search_Type"
										value={this.state.searchTypeId}
										onChange={(e) => {
											this.setState({
												searchTypeId: e.target.value,
											});
										}}
									>
										<FormControlLabel
											value="3"
											control={<Radio />}
											label={this.props.t("requestNo")}
										/>
										<FormControlLabel
											value="1"
											control={<Radio />}
											label={this.props.t(
												"requestEngData"
											)}
										/>
										<FormControlLabel
											value="2"
											control={<Radio />}
											label={this.props.t(
												"certificateData"
											)}
										/>
									</RadioGroup>
								</FormControl>
							</Col>

							{this.state.searchTypeId == 3 && (
								<Col xs={12} md={4}>
									<TextField
										label={this.props.t("requestServiceNo")}
										className="read-only mt-3"
										name="Request_No"
										disabled
									/>
								</Col>
							)}

							{this.state.searchTypeId == 1 && (
								<>
									<Col xs={12} md={3}>
										<TextField
											label={this.props.t("requestNo")}
											className="mt-3"
											name="Intg_Req_No"
										/>
									</Col>

									<Col xs={12} md={3}>
										<TextField
											label={this.props.t("requestYear")}
											className="mt-3"
											name="Intg_Req_Year"
										/>
									</Col>
								</>
							)}

							{this.state.searchTypeId == 2 && (
								<>
									<Col xs={12} md={3}>
										<TextField
											label={this.props.t("certNo")}
											className="mt-3"
											name="Certificate_No"
										/>
									</Col>

									<Col xs={12} md={3}>
										<TextField
											label={this.props.t("certYear")}
											className="mt-3"
											name="Certificate_Year"
										/>
									</Col>

									<Col xs={12} md={3}>
										<>
											<label className={style.radioLabel}>
												{this.props.t("certDate")}
											</label>
											<Field 
												name="Certificate_Date"
											>
												{(fieldProps) => (
													<DateBox
														acceptCustomValue={
															false
														}
														style={{borderRadius: '50px!important'}}
														defaultValue={
															new Date()
														}
														dateSerializationFormat="yyyy-MM-dd"
														displayFormat="yyyy-MM-dd"
														onValueChanged={(e) => {
															fieldProps.input.onChange(
																e.value
															);
														}}
														type="date"
														placeholder="certDate"
														// isValid={() => {
														//     return !fieldProps.meta?.invalid && !fieldProps.meta?.submitFailed
														// }}
													/>
												)}
											</Field>
										</>
									</Col>
								</>
							)}

							<Col md={3} xs={12} className='d-flex align-items-center'>
								<ButtonPrimary
									color="primary"
									variant="contained"
									disabled={submitting}
									type="button"
									onClick={() => {
										this.onSearchSubmit(
											values,
											form
										);
									}}
								>
									{this.props.t("search")}
								</ButtonPrimary>
							</Col>

							<Col xs={12}>
								<TextField
									disabled
									label={this.props.t(
										"integrationSearchResult"
									)}
									className="mb-2 read-only"
									name="searchResult"
									autoComplete="off"
									multiline
									rows={20}
									value={this.state.searchResult}
								/>
							</Col>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
})(translationHook(IntegrationSearch));
