import React, { useState , useEffect } from 'react'
import { connect } from 'react-redux';
import { Form } from 'react-final-form'
import { TextField, Checkboxes } from 'mui-rff'
import { Button, InputAdornment, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { GET_USER_DETAILS } from '../../actions/types'

import style from './Form.module.scss'
import invisible from './../../images/login/svgs/invisible.svg'
import visible from './../../images/login/svgs/visible.svg'
import { userLogin } from '../../actions';
import { ForgetPass , ResetForgetPass } from '../../apis/system/forgetPass'
import { triggerNotification, setLoading } from '../../actions';
import { sendVerifyMail , setMailVerify , setMobileVerification, sendVerifyMobile } from '../../apis/verify'
import Countdown from "react-countdown";
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../../actions'

// state.currentUser
const FormElement = (props) => {
    const { t, i18n } = useTranslation(['auth'])
    const [verifyMail, setVerifyMail] = useState(false);
    const [verifyPhone, setVerifyPhone] = useState(false);
    const [mailstep, setmailstep] = useState();
    const [optID, setoptID] = useState(0)
    const dispatch = useDispatch();


    useEffect(() => {
        setmailstep(props.currentUser.verifyMail)

    }, [props.currentUser])


    const validate = (values) => {
        const errors = {}
        if (!values.code) {
            errors.code = t('error.field_required')
        }

        return errors;
    }
    const setMailVerification =  async (values, form) => {
        if(!mailstep) {
            const data = {
                User_OTP_Id: optID,
                OTP_Value: values.code
            }
            const res = await setMailVerify(props.currentUser.Data.access_token , data)
            setVerifyMail(true)
            props.triggerNotification(true, res);
            if(res.Status) {
                skipForMail();
                form.restart();

            }
        } else {
            const data = {
                User_OTP_Id: optID,
                OTP_Value: values.code
            }
            const res = await setMobileVerification(props.currentUser.Data.access_token , data)
            setVerifyPhone(true)
            props.triggerNotification(true, res);
            if(res.Status) {
                skipForPhone()
            }
        }
    }
    const skipForMail = () => {
        setmailstep(true)
        const data = {
            ...props.currentUser,
            verifyMail: true
        }
        window.sessionStorage.setItem("verifyMail", true)
        // getUserDetails(data) 
        // dispatch({ type: GET_USER_DETAILS, payload: data });
    }
    const skipForPhone = () => {
        skipForMail()
        setVerifyPhone(true)
        const data = {
            ...props.currentUser,
            verifyPhone: true,
            verifyMail: true,
        }
        window.sessionStorage.setItem("verifyPhone", true)
        dispatch({ type: GET_USER_DETAILS, payload: data });
        // window.location.reload()
        // getUserDetails(data)
    }
    const setOtp = (id) => {
        setoptID(id)
    }

    return(
        <Form
        onSubmit={setMailVerification}
        initialValues={{}}
        validate={validate}
        subscription={{ submitting: true }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
            !mailstep ? 
                <VerifyMailComp getotpId={setOtp} triggerNotification={props.triggerNotification}  currentUser={props.currentUser} handleSubmit={handleSubmit} submitting={submitting} skipStep={skipForMail}/>
                : 
                <VerifyPhoneComp getotpId={setOtp} triggerNotification={props.triggerNotification} currentUser={props.currentUser}  handleSubmit={handleSubmit} submitting={submitting} skipStep={skipForPhone}/>
        )}
        />
    )
}
const VerifyMailComp = (props) => {
    const { t, i18n } = useTranslation(['auth', 'common'])
    const [verifyMail, setVerifyMail] = useState(false);
    const [countdownMins, setcountdownMins] = useState(100)
    
    const sendMail =  async () => {
        const res = await sendVerifyMail(props.currentUser.Data.access_token);

        props.triggerNotification(true, res)
        props.getotpId(res.Data.User_OTP_Id)

        const today = new Date();
        const otpTime = new Date(res.Data.OTP_Expire_Time);
        const diffMs = (otpTime - today); // milliseconds between now & otpTime
        setcountdownMins(diffMs)
        setVerifyMail(true)
    }
        // Random component
    const Completionist = () => {
        setTimeout(() => {
            props.skipStep()
        }, countdownMins);
    return <p>You didn't submit the code please try again</p>
    };
    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
        // Render a completed state
        return <Completionist />;
        } else {
        // Render a countdown
        return <h3 className="text-center">{minutes}:{seconds}</h3>;
        }
    };

    return (
        !verifyMail ? 
        <>
        <Button
            className={style.signInBtn}
            variant="contained"
            type="button"
            onClick={sendMail}
        >
            {t('verifyYourMail')}
        </Button>
        <a 
        href="#" 
        className=
        {"d-block mt-4 text-center w-100"}
        onClick={(e) => {
                e.preventDefault();
                props.skipStep();
            }}
            >
            {t('skipForNow')}
        </a>
        </>
        :
        <>
        <form className="form-row" onSubmit={props.handleSubmit}>
            <div className="col-12">
                <Countdown 
                    date={Date.now() + countdownMins}
                    renderer={renderer}
                >
                    <Completionist />
                </Countdown>
                <div className="form-group">
                <TextField
                    className={style.formLabels}
                    name="code"
                    label={t('code')}
                    showError={({ meta: { submitFailed, invalid } }) => { 
                        return submitFailed && invalid 
                    } }
                    />
                </div>
            </div>
        <div className="col text-center mt-0">
            <Button
                className={style.signInBtn}
                variant="contained"
                disabled={props.submitting || props.pristine}
                type="submit"
            >
                {t('verifyYourMail')}
            </Button>
            <a 
                href="#" 
                className=
                {"d-block mt-4 text-center w-100"}
                onClick={(e) => {
                    e.preventDefault();
                    props.skipStep()
                }}
                >
                {t('skipForNow')}
            </a>
        </div>
        </form>
        </> 

    )
}
const VerifyPhoneComp = (props) => {
    const { t, i18n } = useTranslation(['auth', 'common'])
    const [sendphoneOtp, setsendphoneOtp] = useState(false)
    const [countdownMins, setcountdownMins] = useState(100)
    const sendPhoneVerify = async () => {
        const res = await sendVerifyMobile(props.currentUser.Data.access_token)

        props.triggerNotification(true, res)
        props.getotpId(res.Data.User_OTP_Id)
        const today = new Date();
        const otpTime = new Date(res.Data.OTP_Expire_Time);
        const diffMs = (otpTime - today); // milliseconds between now & otpTime
        setcountdownMins(diffMs)
        setsendphoneOtp(true)
    }
    // Random component
    const Completionist = () => {
        setTimeout(() => {
            props.skipStep()
        }, countdownMins);
    return <p>You didn't submit the code please try again</p>
    };
    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
        // Render a completed state
        return <Completionist />;
        } else {
        // Render a countdown
        return <h3 className="text-center">{minutes}:{seconds}</h3>;
        }
    };
    return(
        !sendphoneOtp ?
        <>
            <Button
                className={style.signInBtn}
                variant="contained"
                type="button"
                onClick={sendPhoneVerify}
            >
                {t('verifyPhone')}
            </Button>
            <a 
            href="#" 
            className=
            {"d-block mt-4 text-center w-100"}
            onClick={(e) => {
                    e.preventDefault();
                    props.skipStep()
                }}
                >
                {t('skipForNow')}
            </a>
        </>
        :
        <>
            <form className="form-row" onSubmit={props.handleSubmit}>
                <div className="col-12">
                    <Countdown 
                        date={Date.now() + countdownMins}
                        renderer={renderer}
                    >
                        <Completionist />
                    </Countdown>
                    <div className="form-group">
                    <TextField
                        className={style.formLabels}
                        name="code"
                        label={t('code')}
                        autoComplete="current-password"
                        showError={({ meta: { submitFailed, invalid } }) => { 
                            return submitFailed && invalid 
                        } }
                        />
                    </div>
                </div>
            <div className="col text-center mt-0">
                <Button
                    className={style.signInBtn}
                    variant="contained"
                    disabled={props.submitting || props.pristine}
                    type="submit"
                >
                    {t('verifyPhone')}
                </Button>
                <a 
                    href="#" 
                    className=
                    {"d-block mt-4 text-center w-100"}
                    onClick={(e) => {
                        e.preventDefault();
                        props.skipStep()
                    }}
                    >
                    {t('skipForNow')}
                </a>
            </div>
            </form>
        </>
    )
}
const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { 
    triggerNotification, 
    setLoading,
    userLogin})(FormElement)