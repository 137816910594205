import apiConfig from '../apiConfig';

export const getUsedDevicesTypes = async (at, data) => {
	const response = await apiConfig.post(
		'/API/REPORTS/SYSTEM/GET_USERS_LOGIN_DEVICES_LIST', {
            ...data,
            PageSize: 1000,
            PageIndex: 1
        }, 
		{
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}
	);

    if (response.data.Status) {
        return response.data.Data.Data
    }

    return []
};
