import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tab, Nav } from "react-bootstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import moment from "moment";
import { ButtonPrimary } from "../../UI/Buttons";

import style from "../processing/Processing.module.scss";
import FilesUpload from "../../UI/FileUpload/UploadFile";
import InvoiceList from "../processing/invoices/InvoiceList";
import InputChangesEditList from "../processing/return/InputChangesEditList";
import RefundInvoices from "../processing/insurance/RefundInvoices";
import InvoiceInfo from "../processing/insurance/InvoiceInfo";
import { triggerNotification, setLoading } from "../../../actions";
import DataGrid, {
	Column,
	// Button,
	SearchPanel,
	FilterRow,
	HeaderFilter,
	ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection,
	Pager,
} from "devextreme-react/data-grid";
import { downloadRequestAttachment } from "../../../apis/requests/info/downloadRequestAttachment";
import { getRequestManagmentInfo } from "../../../apis/requests/info/getRequestManagmentInfo";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export const StepsNav = ({ steps }) => {
  let { i18n, t } = useTranslation("common"),
    mystyle,
    navs = [];

  if (i18n.language === "ar") {
    mystyle = "ServiceTabsAr";
  } else {
    mystyle = "ServiceTabsEn";
  }

  steps.forEach((step, i) => {
    navs.push(
      <Nav.Item className={mystyle} key={`nav_${i}`}>
        <Nav.Link
          title={step.Step_Id === "" ? t("stepUnderProcess") : ""}
          className={`${style.navLink} ${
            step.Step_Id === "" ? style.processingNavLink : (step.Step_End_Time === null || step.Step_Start_Time === null) ? style.processingNavLinkTodo : style.processingNavLinkDone
          }`}
          style={{ textAlign: i18n.language === "ar" ? 'right' : 'left' }}
          eventKey={i + 2}
        >
          {i18n.language === "ar" ? step.Step_Name_AR : step.Step_Name_EN}
        </Nav.Link>
      </Nav.Item>
    );
  });

  return navs;
};

export const StepsTab = ({
  steps,
  requestId,
  cachRequestStepsCB,
  activeTab,
  triggerLoader,
}) => {
  let { t, i18n } = useTranslation("common"),
    [tabsElemets, setTabsElements] = useState([]),
    tabs = [],
    rActionName = 'RAction_Name_EN',
		executeResult = 'Execute_Result_EN',
    alignment = 'left';

    if (i18n.language === 'ar') {
      alignment = 'right'
			rActionName = 'RAction_Name_AR'
			executeResult = 'Execute_Result_AR'
		}
    
  useEffect(() => {
    steps.forEach((step, i) => {
      tabs.push(
        <Tab.Pane
          className={style.stepWrap}
          eventKey={i + 2}
          key={`tab_${i}`}
          unmountOnExit={true}
        >
          <h4>{t("requestStepInfo")}</h4>
          <div className="row">
            <div className="col-3">
              <label className="ml-2">{t("requestStepNo")}</label>
              <p>{step.Step_No}</p>
            </div>
            {step.Step_Date && (
              <div className="col-3">
                <label className="ml-2">{t("requestStepDate")}</label>
                <p>{step.Step_Date.slice(0, 10)}</p>
              </div>
            )}
            <div className="col-3">
              <label className="ml-2">{t("requestStepName")}</label>
              <p>
                {i18n.language === "ar" ? step.Step_Name_AR : step.Step_Name_EN}
              </p>
            </div>
            {step.Step_Start_Time && (
              <div className="col-3">
                <label className="ml-2">{t("requestStepStartTime")}</label>
                <p>{step.Step_Start_Time.slice(11, 19)}</p>
              </div>
            )}
            {step.Step_End_time && (
              <div className="col-3">
                <label className="ml-2">{t("requestStepEndTime")}</label>
                <p>{step.Step_End_time.slice(11, 19)}</p>
              </div>
            )}
            <div className="col-3">
              <label className="ml-2">{t("requestStepType")}</label>
              <p>
                {i18n.language === "ar"
                  ? step.Step_Type_Name_AR
                  : step.Step_Type_Name_EN}
              </p>
            </div>
            {step.User_Name_AR && (
              <div className="col-3">
                <label className="ml-2">{t("requestStepUser")}</label>
                <p>
                  {step.User_Name_AR === -1
                    ? t("client")
                    : i18n.language === "ar"
                    ? step.User_Name_AR
                    : step.User_Name_EN}
                </p>
              </div>
            )}

            {step.Step_Result_Name_AR && (
              <div className="col-3">
                <label className="ml-2">{t("requestStepResult")}</label>
                <p>
                  {i18n.language === "ar"
                    ? step.Step_Result_Name_AR
                    : step.Step_Result_Name_EN}
                </p>
              </div>
            )}

            <div className="col-3">
              <label className="ml-2">{t("workGroup")}</label>
              <p>
                {i18n.language === "ar" ? step.Job_Name_AR : step.Job_Name_EN}
              </p>
            </div>
          </div>
          {
            step.Step_Actions && step.Step_Actions.length ? (
              <DataGrid
						className={[
							i18n.language === "ar"
								? "dx-grid-ar"
								: "dx-grid-en",
							"globalBox",
              "mt-5"
						].join(" ")}
						id="dataGrid"
						width={"100%"}
						keyExpr="Execute_Result_Id"
						dataSource={step.Step_Actions}
						showBorders={false}
						showColumnLines={false}
						showRowLines={true}
						allowColumnResizing={false}
						columnResizingMode={"widget"}
						allowColumnReordering={true}
						rowAlternationEnabled={false}
						focusedRowEnabled={true}
						columnWidth={"auto"}
						wordWrapEnabled={true}
						rtlEnabled={i18n.language === "ar" ? true : false}
					>
						{/* <ColumnChooser
							enabled={true}
							allowSearch={true}
							search={"fff"}
							title={this.props.t("dxColumnChooserTitle")}
							emptyPanelText={this.props.t(
								"dxColumnChooserPanelText"
							)}
						/> */}

						{/* <SearchPanel
							visible={true}
							width={240}
							placeholder={this.props.t("search")}
						/> */}

						<Pager
							visible={true}
							showInfo={true}
							infoText={`${t("page")} {0} ${t(
								"of"
							)} {1} ({2} ${t("item")})`}
						/>

						{/* <FilterRow visible={true} /> */}

						{/* <HeaderFilter visible={true} /> */}

						{/* <Export enabled={true} allowExportSelectedData={true} /> */}

						<Grouping contextMenuEnabled={true} expandMode="rowClick" />
						{/* <GroupPanel
							visible={true}
							emptyPanelText={this.props.t("dxGroupPanelText")}
						/> */}

						<Selection
							mode="multiple"
							selectAllMode={"allPages"}
							showCheckBoxesMode={"always"}
						/>

						<Column
							dataField={rActionName}
							caption={t('servicesExecutionOrder')}
							alignment={alignment}
							width={250}
						></Column>

						<Column
							// dataField={executeResult}
							caption={t('requestAdInfoActionResult')}
							alignment={"center"}
							cellRender={({ data }) => (
								data.Execute_Result_Status ? 
									<CheckCircleIcon color="primary" /> : <CancelIcon color="secondary" />
							)}
							// dataType="date"
							// format="yyyy-MM-dd"
						></Column>

						<Column
							dataField={executeResult}
							caption={t('requestAdInfoActionMessage')}
							alignment={alignment}
							width={250}
						></Column>

						<Column
							cellRender={({ data }) => (
								data.Can_Reexecute ? (
								<ButtonPrimary
									color='primary'
									variant='contained'
									onClick={() => this.reExecuteRequest(data.Execute_Result_Id)}
									// disabled={submitting}
									>
									{t('requestAdInfoReExecute')}
								</ButtonPrimary>
								) : null
							)}
							caption={t('requestAdInfoReExecute')}
							alignment={"center"}
							width={180}
						></Column>
					</DataGrid>
            ) : null
          }
          <div className={style.tasksWrap}>
            <h4 className="my-4">{t("requestStepTasks")}</h4>
            <StepTasks
              stepId={step.Step_Id}
              tasks={step.Tasks_Data ? step.Tasks_Data : []}
              requestId={requestId}
              cachRequestStepsCB={cachRequestStepsCB}
              triggerLoader={triggerLoader}
            />
          </div>
        </Tab.Pane>
      );
    });

    setTabsElements(tabs);
  }, [steps, activeTab]);

  return tabsElemets;
};

const mapStateToprops = (state) => {
  return { currentUser: state.currentUser };
};

const StepTasks = connect(mapStateToprops, { triggerNotification })(
  ({
    stepId,
    tasks,
    requestId,
    cachRequestStepsCB,
    triggerLoader,
    ...props
  }) => {
    let { t, i18n } = useTranslation("common");
    const [stepTasks, setStepTasks] = useState(tasks);
    const [taskElements, setTaskElements] = useState([]);
    let elementsArr = [];

    // useEffect(() => {
    // 	// do not call the api if data is cached in parent component
    // 	if (tasks && tasks.length > 0) {
    // 		return;
    // 	}

    // 	triggerLoader(true);

    // 	(async () => {
    // 		await getRequestManagmentInfo(
    // 			props.currentUser.Data.access_token,
    // 			requestId,
    // 			5,
    // 			stepId
    // 		)
    // 			.then((data) => {
    // 				if (!data.data.Status) {
    // 					throw data.data;
    // 				}

    // 				if (
    // 					!data.data.Data.Request_Steps ||
    // 					data.data.Data.Request_Steps.length < 1
    // 				) {
    // 					return;
    // 				}

    // 				setStepTasks(
    // 					data.data.Data.Request_Steps[0].Tasks_Data
    // 				);

    // 				cachRequestStepsCB(data.data.Data.Request_Steps[0]);
    // 			})
    // 			.catch((err) => {
    // 				props.triggerNotification(true, err);
    // 			});

    // 		triggerLoader(false);
    // 	})();
    // }, []);

    useEffect(() => {
      tasks.forEach((task, index) => {
        console.log(task);
        elementsArr.push(
          <Accordion
            className={`${style.accordionWrap} py-4 mb-3`}
            key={`task_${index}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`task_${index}`}
              id={`task_${index}`}
            >
              {task.Task_No && (
                <div className="col-2">
                  <label className="ml-2">{t("taskNo")}</label>
                  <p>{task.Task_No}</p>
                </div>
              )}

              {task.Task_Date && (
                <div class="col-2">
                  <label className="ml-2">{t("taskDate")}</label>
                  <p>{task.Task_Date.slice(0, 10)}</p>
                </div>
              )}

              {(task.Task_Title_AR || task.Task_Title_EN) && (
                <div class="col-3">
                  <label className="ml-2">{t("taskTitle")}</label>
                  <p>
                    {i18n.language === "ar"
                      ? task.Task_Title_AR
                      : task.Task_Title_EN}
                  </p>
                </div>
              )}

              {(task.Task_Process_Side_Name_AR || task.Task_Process_Side_Name_EN) && (
                <div class="col-4">
                  <label className="ml-2" style={{color: 'red'}}>{t('taskProcessing')}</label>
                  <p>
                    <span style={{fontSize: '11px',color: '#669900', paddingInlineEnd: '3px'}}> 
                      {i18n.language === "ar" ? task.Task_Process_Side_Name_AR : task.Task_Process_Side_Name_EN} : 
                    </span>
                    {i18n.language === "ar" ? task.Task_Process_User_Name_AR : task.Task_Process_User_Name_EN}
                  </p>
                </div>
              )}
            </AccordionSummary>

            <AccordionDetails className={style.accordionDetail}>
              <div className="row mx-0">
                {task.Task_Start_Time && (
                  <div class="col-3">
                    <label className="ml-2">{t("taskTimeStart")}</label>
                    <p>
                      {task.Task_Start_Time.slice(0, 10)}{" "}
                      {task.Task_Start_Time.slice(11, 19)}
                    </p>
                  </div>
                )}

                {task.Task_End_Time && (
                  <div class="col-3">
                    <label className="ml-2">{t("taskTimeEnd")}</label>
                    <p>
                      {task.Task_End_Time.slice(0, 10)}{" "}
                      {task.Task_End_Time.slice(11, 19)}
                    </p>
                  </div>
                )}

                {task.User_Name_AR && (
                  <div className="col-3">
                    <label className="ml-2">{t("taskUser")}</label>
                    <p>
                      {task.User_Name_AR === -1
                        ? t("client")
                        : i18n.language === "ar"
                        ? task.User_Name_AR
                        : task.User_Name_EN}
                    </p>
                  </div>
                )}

                {task.Task_Action_Name_AR && (
                  <div className="col-3">
                    <label className="ml-2">{t("taskResult")}</label>
                    <p>
                      {i18n.language === "ar"
                        ? task.Task_Action_Name_AR
                        : task.Task_Action_Name_EN}
                    </p>
                  </div>
                )}

                {task.Task_Notes && (
                  <div className="col-12">
                    <label className="ml-2">{t("taskNots")}</label>
                    <p>{task.Task_Notes}</p>
                  </div>
                )}

                {task.Inspection_Time && (
                  <div className="col-3">
                    <label className="ml-2">{t("inspectionTime")}</label>
                    <p>
                      {moment(task.Inspection_Time).format(
                        "YYYY-MM-DD , h:mm a"
                      )}
                    </p>
                  </div>
                )}

                        
                {task.Invoices_Data.length > 0 && (
                  <div className="col-12">
                    <label className="my-4">{t("invoicesData")}</label>
                    <InvoiceList
                      invoices={task.Invoices_Data}
                      key={task.Task_Id}
                    />
                  </div>
                )}

                {task.Files_Data.length > 0 && (
                  <div className="col-12">
                    <label className="my-4">{t("attachments")}</label>
                    <FilesUpload
                      downloadCB={downloadRequestAttachment}
                      view={true}
                      attachments={[
                        {
                          Files_Data: task.Files_Data,
                        },
                      ]}
                    />
                  </div>
                )}
                {task.Approving_Files_Data.length > 0 && (
                  <div className="col-12">
                    <label className="my-4">{t("ApprovingFiles")}</label>
                    <FilesUpload
                      downloadCB={downloadRequestAttachment}
                      // hasInfo={true}
                      view={true}
                      inline={true}
                      attachments={[
                        {
                          Files_Data: task.Approving_Files_Data,
                        },
                      ]}
                    />
                  </div>
                )}

                {task.Inputs_Changes_Edit_Data.length > 0 && (
                  <div className="col-12">
                    <label className="my-4">{t("requestedFieldEdits")}</label>
                    <InputChangesEditList
                      inputChangesList={task.Inputs_Changes_Edit_Data}
                    />
                  </div>
                )}

                {task.Insurance_Refund_Data.length > 0 && (
                  <div className="col-12">
                    <label className={style.labelCentered}>
                      {t("insuranceRefundData")}
                    </label>

                    {task.Insurance_Refund_Data.map((invoice) => {
                      return (
                        <InvoiceInfo
                          invoiceId={invoice.Invoice_Id}
                          requestId={requestId}
                          requestTaskId={task.Task_Id}
                          readOnly={true}
                          insuranceRefundData={invoice}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      });

      setTaskElements(elementsArr);
    }, [tasks]);

    return taskElements;
  }
);
