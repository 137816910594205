import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { ButtonPrimary } from './../../UI/Buttons'
import { Form } from 'react-final-form'
import { TextField, Select, Switches } from 'mui-rff'

import apiConfig from '../../../apis/apiConfig';
import { translationHook } from '../../translationHook'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'



class ServiceDetailForm extends Component {

    state = {
        showForm: false
    }

    validate = values => {
        const errors = {}


        if (!values.valueNameAR) {
            errors.valueNameAR = `${this.props.t('error.field_required')}`
        }

        if (!values.valueNameEN) {
            errors.valueNameEN = `${this.props.t('error.field_required')}`
        }


        
        return errors;
    }

    onServiceDetailSubmit = async (values, form)=> {
        let at = this.props.currentUser.Data.access_token,
        url = this.props.editMood ? '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSVALUESMANAGEMENT/Upd_Services_Lookups_Values' : '/API/ADMINISTRATION/PORTAL/SERVICESLOOKUPSVALUESMANAGEMENT/ADD_Services_Lookups_Values'


        let bodyData = {
            Service_Lookup_Value_Name_AR: values.valueNameAR,
            Service_Lookup_Value_Name_EN: values.valueNameEN,
            Active_Status_Id: values.required ? 1 : 0,
        }

        if (!this.props.editMood) {
            bodyData.Service_Lookup_Id = this.props.serviceid
        } else {
            bodyData.Service_Lookup_Id = this.props.serviceid
            bodyData.Service_Lookup_Value_Id = this.props.serviceDetailId
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        ).catch((error) => {
            return error.response
        })

        this.props.setLoading(false)

        this.props.triggerNotification(true, response.data)

        if (response.data.Status) {
            if (this.props.editMood) {
                this.props.triggerDialogForm(false)
            }
            this.props.reloadDatagrid()
            form.restart()
        }
    }

    render() {
        let { initValues } = this.props
        return (
            <>
            {!this.props.editMood && !this.props.viewMood && 
                <div className="d-flex justify-content-end">
                    <ButtonPrimary
                        variant={!this.state.showForm ? 'contained' : 'outlined'}
                        onClick={() => this.setState({showForm: !this.state.showForm}) }
                    >
                        {!this.state.showForm && this.props.t('addVal')}
                        {this.state.showForm && this.props.t('cancel')}
                    </ButtonPrimary>
                </div>
            }
            <Form
                onSubmit={this.onServiceDetailSubmit}
                initialValues={ initValues ? initValues : {
                    valueNameAR: '',
                    valueNameEN: '',
                    required: true,
                }}
                validate={this.validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                return (
                    <form
                    id="serviceDetailForm"
                    className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                    onSubmit={handleSubmit}
                    noValidate>
                        <Col xs={12} md={6}>
                            <TextField
                                label={this.props.t('detailno')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                name="detailNo"
                                disabled
                            />
                        </Col>
                        <Col xs={12} md={6} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                            <Switches
                                disabled={this.props.viewMood}
                                color="primary"
                                name="required"
                                data={{ label: `${this.props.t('active')}`, value: `${this.props.t('active')}` }}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                disabled={this.props.viewMood}
                                label={this.props.t('dropDowndetailnameAR')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                required={true}
                                name="valueNameAR"
                                autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => { 
                                    return submitFailed && invalid 
                                } }
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <TextField
                                disabled={this.props.viewMood}
                                label={this.props.t('dropDowndetailnameEN')}
                                className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                required={true}
                                name="valueNameEN"
                                autoComplete="off"
                                showError={({ meta: { submitFailed, invalid } }) => { 
                                    return submitFailed && invalid 
                                } }
                            />
                        </Col>
                        
                        <Col className='mt-4' xs={12}>
                            <Row className='align-items-baseline'>
                                <Col className='mt-2 submit-btn-wrap'>
                                    { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        
                    </form>
                    )
                }
            }
            />
            </>
        ) 
    }
}

const mapStateToProps = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToProps, { triggerNotification, setLoading, triggerDialogForm })(translationHook(ServiceDetailForm))