import React, { Component } from "react";
import { connect } from "react-redux";
import { translationHook } from "../translationHook";
import apiConfig from "../../apis/apiConfig";
import { ButtonPrimary, ButtonSecondary } from "./../UI/Buttons";
import { Row, Col } from "react-bootstrap";
import { Form, Field } from "react-final-form";
import { TextField, Switches, Select } from "mui-rff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Avatar from "@material-ui/core/Avatar";
import {
  triggerNotification,
  setLoading,
  triggerDialogForm,
} from "../../actions";
import FilesUpload from "../UI/FileUpload/UploadFile";
import { downloadAttachment } from "../../apis/system/downloadAttachment";
import { deleteAttachment } from "../../apis/system/deleteAttachment";
import { OnChange } from "react-final-form-listeners";
import { Autocomplete } from "devextreme-react/autocomplete";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import AccountProfilesList from "./AccountProfilesList";
class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: "",
      file: "",
      showForm: false,
      openProfile: false,
      departments: [],
      departmentJobs: [],
      usersGroupList: [],
      langList: [],
      connect: true,
      searchResults: [""],
      searchContent: "",
      searchType: "1",
      initVal: {
        User_Name_AR: "",
        User_Name_EN: "",
        User_Phone: "",
        User_Mail: "",
        Login_Name: "",
        User_Group_Id: "",
        Lang_Id: "",
        Department_Id: "",
        Department_Job_Id: "",
        lang: "",
        userStatus: true,
        User_Account_Id: "",
      },
      activeDir: null,
      filesData: [],
    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSelectType = this.handleSelectType.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  getDepartments = async () => {
    let departmentArr = [];
    const response = await apiConfig.post("/API/SYSTEM/DDL/GET_DEPARTMENTS");
    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;
        departmentArr.push({ value: item.Record_Id, label });
      });
      departmentArr.unshift({ value: "", label: this.props.t("withoutDep") });

      this.setState({ departments: departmentArr });
    }
  };

  getDepartmentJobs = async (departmentId) => {
    let departmentJobsArr = [],
      at = this.props.currentUser.Data.access_token;
    const response = await apiConfig.post(
      "/API/SYSTEM/DDL/GET_ALL_DEPARTMENT_JOBS",
      {
        Department_Id: departmentId,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;
        departmentJobsArr.push({ value: item.Record_Id, label });
      });
      departmentJobsArr.unshift({
        value: 0,
        label: this.props.t("withoutJob"),
      });

      this.setState({ departmentJobs: departmentJobsArr });
    }
  };

  getUsersGroupList = async () => {
    let usersGroupListArr = [],
      at = this.props.currentUser.Data.access_token;
    const response = await apiConfig.post(
      "/API/SYSTEM/USERS/GROUPS/GET_USERS_GROUPS_LIST",
      {},
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.User_Group_Name_AR
            : item.User_Group_Name_EN;
        usersGroupListArr.push({ value: item.User_Group_Id, label });
      });
      usersGroupListArr.unshift({ value: "", label: this.props.t("delete") });

      this.setState({ usersGroupList: usersGroupListArr });
    }
  };

  getLang = async () => {
    let getLangUsersArr = [],
      at = this.props.currentUser.Data.access_token;
    const response = await apiConfig.post(
      "/API/SYSTEM//DDL/GET_LANGUAGUES",
      {},
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      response.data.Data.map((item) => {
        let label =
          this.props.i18n.language === "ar"
            ? item.Record_Name_AR
            : item.Record_Name_EN;
        getLangUsersArr.push({ value: item.Record_Id, label });
      });
      getLangUsersArr.unshift({ value: "", label: this.props.t("delete") });

      this.setState({ langList: getLangUsersArr });
    }
  };

  onUserSubmit = async (values, form) => {
    const bodyFormData = new FormData(),
      at = this.props.currentUser.Data.access_token,
      url = !this.props.editMood
        ? "/API/SYSTEM/USERS/ADD_USER"
        : "/API/SYSTEM/USERS/UPD_USER",
      bodyData =
        this.state.activeDir && this.state.connect
          ? {
              AD_Ref: this.state.activeDir.ADSid,
              User_Group_Id: values.group,
              User_Name_AR: values.arUserName,
              User_Name_EN: values.enUserName,
              User_Phone: values.phone,
              User_Mail: values.mail,
              Login_Name: values.loginName,
              Active_Status_Id: values.userStatus ? 1 : 0,
              Department_Id: values.department ? values.department : 0,
              Department_Job_Id: values.job ? values.job : 0,
              Lang_Id: values.lang,
              User_Signature_Text: values.jobSignature,
            }
          : {
              User_Name_AR: values.arUserName,
              User_Name_EN: values.enUserName,
              User_Phone: values.phone,
              User_Mail: values.mail,
              Login_Name: values.loginName,
              Active_Status_Id: values.userStatus ? 1 : 0,
              Lang_Id: localStorage.getItem("lang") === "en" ? 2 : 1,
              User_Group_Id: values.group,
              Department_Id: values.department ? values.department : 0,
              Department_Job_Id: values.job ? values.job : 0,
              Lang_Id: values.lang,
              User_Signature_Text: values.jobSignature,
            };

    // if new user add user image
    if (!this.props.editMood && !this.props.viewMood && this.state.file) {
      bodyFormData.append("User_Image_File", this.state.file);
    }

    this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

    // if editMood update user image if changed in two requests
    if (this.props.editMood) {
      // update user image with user id
      bodyData.User_Account_Id = this.props.initValues.userAccountId;

      // handle update user image if user changed file input
      if (this.state.file) {
        const mediaBodyFormData = new FormData();

        mediaBodyFormData.append(
          "PARAMETERS",
          JSON.stringify({
            User_Account_Id: this.props.initValues.userAccountId,
          })
        );
        mediaBodyFormData.append("User_Image_File", this.state.file);

        const mediaResponse = await apiConfig.post(
          "/API/SYSTEM/USERS/UPD_USER_IMAGE",
          mediaBodyFormData,
          {
            headers: {
              Authorization: `Bearer ${at}`,
            },
          }
        );
      }
      // end handle update user image
    }

    bodyFormData.append("PARAMETERS", JSON.stringify(bodyData));

    const response = await apiConfig
      .post(url, bodyFormData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .then((res) => {
        this.props.triggerNotification(true, res.data);
        this.props.setLoading(false);
        this.props.triggerDialogForm(false);
        if (res.data.Status) {
          form.restart();
        }
        this.setState({ imgUrl: "", file: "" });
      })
      .catch((err) => {
        this.props.triggerNotification(true, err);
        this.props.setLoading(false);
      });

    // reset parent Component Users.js editMood and viewMood states after automatically closing the edit modal via triggerDialogForm
    if (this.props.editMood) {
      this.props.triggerView(false, false);
    }
  };

  addProfile = async (values, form) => {
    const at = this.props.currentUser.Data.access_token,
      url = "/API/SYSTEM/USERS/ADD_USER_PROFILE_DATA",
      bodyData = {
        User_Account_Id: this.props.initValues.userAccountId,
        Department_Id: values.department,
        Department_Job_Id: values.job,
        User_Group_Id: values.group,
        User_Signature_Text: values.jobSignature,
      };

    this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

    const response = await apiConfig
      .post(url, bodyData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .then((res) => {
        this.props.triggerNotification(true, res.data);
        if (res.data.Status && !this.props.editMood) {
          form.restart();
        }
      })
      .catch((err) => {
        this.props.setLoading(false);
      });

    this.props.setLoading(false);

    // this.props.triggerDialogForm(false)
  };

  handleUploadClick = (files) => {
    if (files?.length > 0) {
      let file = files[0];
      const reader = new FileReader();
      let url = reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.setState({
          imgUrl: reader.result,
          file,
        });
      };
    }
  };

  handleValueChange = async (e) => {
    this.setState({
      searchContent: e.value,
    });
    if (!e.value) return false;
    const response = await apiConfig
      .post(
        "/API/SYSTEM/USERS/GET_AD_USERS_LIST",
        {
          AD_Search_Value: this.state.searchContent,
          AD_Search_Type: this.state.searchType,
        },
        {
          headers: {
            Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
          },
        }
      )
      .catch((error) => {
        return error.response;
      });

    if (response.data.Status) {
      this.setState({
        searchResults: response.data?.Data,
      });
    }
  };

  selectedValue = (e) => {
    if (e.selectedItem === null) {
      this.setState({
        activeDir: null,
      });
    } else {
      this.setState({
        activeDir: e.selectedItem,
        initVal: {
          loginName: e.selectedItem?.LoginName,
          mail: e.selectedItem?.Email,
          arUserName: e.selectedItem?.FullName,
          enUserName: e.selectedItem?.FullName,
          userStatus: true,
          group: this.props.initValues ? this.props.initValues.group : "",
          department: this.props.initValues
            ? this.props.initValues.department
            : "",
          job: this.props.initValues ? this.props.initValues.job : "",
          lang: this.props.initValues ? this.props.initValues.lang : "",
          jobSignature: this.props.initValues
            ? this.props.initValues.jobSignature
            : "",
        },
      });
    }
  };

  getUserDetails = async () => {
    if (this.props.userId) {
      let at = this.props.currentUser.Data.access_token,
        url = "/API/SYSTEM/USERS/GET_USER_DETAILS";
      this.props.setLoading(true, "dark");
      const response = await apiConfig.post(
        url,
        {
          User_Id: this.props.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      );

      if (response.data.Status) {
        if (response.data.Data.User_Signature_File !== null) {
          this.setState({
            filesData: [response.data.Data.User_Signature_File],
          });
        }
      }
      this.props.setLoading(false);
    }
  };

  deleteAttachment = async ({ File_Code }) => {
    let at = this.props.currentUser.Data.access_token,
      url = "/API/SYSTEM/USERS/DEL_USER_SIGNATURE_IMAGE";

    const response = await apiConfig.post(
      url,
      {
        User_Account_Id: this.props.initValues.userAccountId,
        User_Signature_File_Code: File_Code,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    if (response.data.Status) {
      this.getUserDetails();
    }

    return response;
  };

  handleSelectType = (e) => {
    this.setState({
      searchType: e.target.value,
    });
    let searchText = this.state.searchContent;
    this.handleValueChange({ value: searchText });
  };

  resetPassword = async () => {
    const at = this.props.currentUser.Data.access_token,
      url = "/API/SYSTEM/USERS/RESET_USER_PASSWORD",
      bodyData = {
        User_Account_Id: this.props.initValues.userAccountId,
      };

    this.props.setLoading(true, "dark", this.props.editMood ? 1501 : 900);

    const response = await apiConfig
      .post(url, bodyData, {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      })
      .then((res) => {
        this.props.triggerNotification(true, res.data);
        this.props.setLoading(false);
      })
      .catch((err) => {
        this.props.triggerNotification(true, err);
        this.props.setLoading(false);
      });
  };

  componentDidMount() {
    let { initValues, viewMood, editMood } = this.props;

    this.getDepartments();
    this.getUsersGroupList();
    this.getLang();
    this.getUserDetails();

    if (viewMood) {
      this.getDepartmentJobs(initValues.department);
      this.setState({
        initVal: initValues,
      });
    }
    if (initValues && (editMood || viewMood)) {
      this.setState({
        initVal: initValues,
        activeDir: {
          ADSid: initValues.AD_Ref,
        },
      });
    }
    if ((editMood || viewMood) && initValues.AD_Ref) {
      this.setState({
        searchContent: initValues.loginName,
      });
    } else {
      this.setState({
        connect: false,
      });
    }
    if (editMood || viewMood) {
      this.setState({
        openProfile: false,
      });
    }
  }

  render() {
    let { initValues } = this.props;

    const validate = (values) => {
      const errors = {};

      if (!this.state.activeDir) {
        if (!values.arUserName) {
          errors.arUserName = `${this.props.t("error.field_required")}`;
        }
        if (!values.enUserName) {
          errors.enUserName = `${this.props.t("error.field_required")}`;
        }
        if (!values.loginName) {
          errors.loginName = `${this.props.t("error.field_required")}`;
        }
        if (!values.phone) {
          errors.phone = `${this.props.t("error.field_required")}`;
        }
        if (!values.mail) {
          errors.mail = `${this.props.t("error.field_required")}`;
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mail)
        ) {
          errors.mail = `${this.props.t("error.invalid_email")}`;
        }
      }
      if (!this.props.editMood) {
        if (!values.group) {
          errors.group = `${this.props.t("error.field_required")}`;
        }
        // if (!values.department) {
        // 	errors.department = `${this.props.t('error.field_required')}`;
        // }
        // if (!values.job) {
        // 	errors.job = `${this.props.t('error.field_required')}`;
        // }
      }
      if (!values.lang) {
        errors.lang = `${this.props.t("error.field_required")}`;
      }

      return errors;
    };
    const validateProfile = (values) => {
      const errors = {};
      if (!values.group) {
        errors.group = `${this.props.t("error.field_required")}`;
      }
      // if (!values.department) {
      // 	errors.department = `${this.props.t('error.field_required')}`;
      // }
      // if (!values.job) {
      // 	errors.job = `${this.props.t('error.field_required')}`;
      // }
      return errors;
    };

    return (
      <>
        {!this.props.editMood && !this.props.viewMood && (
          <div className="d-flex justify-content-between align-items-center">
            <h3>{this.props.t("manageUsers")}</h3>
            <ButtonPrimary
              variant={!this.state.showForm ? "contained" : "outlined"}
              onClick={() => {
                this.setState({ showForm: !this.state.showForm });
              }}
            >
              {!this.state.showForm && this.props.t("addUser")}
              {this.state.showForm && this.props.t("cancel")}
            </ButtonPrimary>
          </div>
        )}

        {this.state.showForm || this.props.editMood || this.props.viewMood ? (
          <Form
            onSubmit={this.onUserSubmit}
            onReset={this.onUserReset}
            initialValues={this.state.initVal}
            enableReinitialize={true}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              handleReset,
            }) => {
              if (
                this.props.editMood &&
                values.department &&
                values.department !== "" &&
                values.departmentJobs !== "" &&
                this.state.departmentJobs.length === 0
              ) {
                this.getDepartmentJobs(values.department);
              }
              return (
                <form
                  id="userform"
                  className={`row ${
                    !this.state.showForm &&
                    !this.props.editMood &&
                    !this.props.viewMood
                      ? "d-none"
                      : ""
                  }`}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  noValidate
                >
                  <Col xs={12} md={9}>
                    <Row>
                      <Col
                        xs={12}
                        md={4}
                        className={`${this.props.viewMood ? "read-only" : ""}`}
                      >
                        <Autocomplete
                          value={this.state.searchContent}
                          dataSource={this.state.searchResults}
                          valueExpr={
                            this.state.searchType === "1"
                              ? "LoginName"
                              : this.state.searchType === "2"
                              ? "Email"
                              : this.state.searchType === "3"
                              ? "FullName"
                              : false
                          }
                          onValueChanged={this.handleValueChange}
                          onSelectionChanged={this.selectedValue}
                          placeholder={this.props.t("search")}
                          disabled={this.props.viewMood}
                          rtlEnabled={
                            this.props.i18n.language === "ar" ? true : false
                          }
                          minSearchLength={2}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        className={`${this.props.viewMood ? "read-only" : ""}`}
                      >
                        <Switches
                          disabled={this.props.viewMood}
                          color="primary"
                          checked={this.state.connect}
                          onChange={() => {
                            this.setState({ connect: !this.state.connect });
                          }}
                          name="connect"
                          data={{
                            label: `${this.props.t("connect")}`,
                            value: `${this.props.t("connect")}`,
                          }}
                        />
                      </Col>

                      <Col xs={12} md={8}>
                        <FormControl className="mt-3">
                          <label>{this.props.t("searchType")}</label>
                          <RadioGroup
                            disabled={this.props.viewMood}
                            row
                            name="searchType"
                            value={this.state.searchType}
                            onChange={this.handleSelectType}
                          >
                            <FormControlLabel
                              value="1"
                              disabled={this.props.viewMood}
                              control={<Radio />}
                              label={this.props.t("loginName")}
                            />
                            <FormControlLabel
                              value="2"
                              disabled={this.props.viewMood}
                              control={<Radio />}
                              label={this.props.t("email")}
                            />
                            <FormControlLabel
                              value="3"
                              disabled={this.props.viewMood}
                              control={<Radio />}
                              label={this.props.t("name")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={9}>
                    <Row>
                      <Col xs={12} md={4}>
                        <TextField
                          disabled={
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                          }
                          label={this.props.t("userNameAR")}
                          className={`${
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                              ? "read-only"
                              : ""
                          } mb-2`}
                          name="arUserName"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          disabled={
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                          }
                          label={this.props.t("userNameEN")}
                          className={`${
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                              ? "read-only"
                              : ""
                          } mb-2`}
                          name="enUserName"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col
                        xs={12}
                        md={4}
                        className={`${
                          this.props.viewMood ||
                          (this.state.connect && this.state.activeDir) ||
                          initValues?.AD_Ref
                            ? "read-only"
                            : ""
                        }`}
                      >
                        <Switches
                          disabled={this.props.viewMood}
                          color="primary"
                          name="userStatus"
                          data={{
                            label: `${this.props.t("userStatus")}`,
                            value: `${this.props.t("userStatus")}`,
                          }}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          disabled={
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                          }
                          label={this.props.t("loginName")}
                          className={`${
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                              ? "read-only"
                              : ""
                          } mb-2`}
                          name="loginName"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          disabled={this.props.viewMood}
                          label={this.props.t("phone")}
                          className={`${
                            this.props.viewMood ? "read-only" : ""
                          } mb-2`}
                          name="phone"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col xs={12} md={4}>
                        <TextField
                          disabled={
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                          }
                          label={this.props.t("mail")}
                          className={`${
                            this.props.viewMood ||
                            (this.state.connect && this.state.activeDir) ||
                            (initValues?.AD_Ref && this.state.connect)
                              ? "read-only"
                              : ""
                          } mb-2`}
                          name="mail"
                          required={true}
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      {!this.props.editMood && (
                        <>
                          <Col xs={12} md={4}>
                            <Select
                              disabled={this.props.viewMood}
                              label={this.props.t("userPermissionsGroup")}
                              className={`${
                                this.props.viewMood ? "read-only" : ""
                              } mb-2`}
                              required={true}
                              name="group"
                              data={this.state.usersGroupList}
                              showError={() => {
                                let fieldStatus = form.getFieldState("group");
                                if (fieldStatus) {
                                  return (
                                    fieldStatus.submitFailed &&
                                    fieldStatus.invalid
                                  );
                                }
                              }}
                            ></Select>
                          </Col>

                          <Col xs={12} md={4}>
                            <Select
                              disabled={this.props.viewMood}
                              label={this.props.t("departmentName")}
                              className={`${
                                this.props.viewMood ? "read-only" : ""
                              } mb-2`}
                              name="department"
                              data={this.state.departments}
                              showError={() => {
                                let fieldStatus =
                                  form.getFieldState("department");
                                if (fieldStatus) {
                                  return (
                                    fieldStatus.submitFailed &&
                                    fieldStatus.invalid
                                  );
                                }
                              }}
                            ></Select>
                          </Col>

                          <OnChange name="department">
                            {(value) => {
                              this.getDepartmentJobs(value);
                            }}
                          </OnChange>

                          <Col xs={12} md={4}>
                            <Select
                              disabled={this.props.viewMood}
                              label={this.props.t("departmentJob")}
                              className={`${
                                this.props.viewMood ? "read-only" : ""
                              } mb-2`}
                              name="job"
                              data={this.state.departmentJobs}
                              showError={() => {
                                let fieldStatus =
                                  form.getFieldState("departmentJobType");
                                if (fieldStatus) {
                                  return (
                                    fieldStatus.submitFailed &&
                                    fieldStatus.invalid
                                  );
                                }
                              }}
                            ></Select>
                          </Col>

                          <Col xs={12} md={4}>
                            <TextField
                              disabled={
                                this.props.viewMood ||
                                (this.state.connect && this.state.activeDir) ||
                                (initValues?.AD_Ref && this.state.connect)
                              }
                              label={this.props.t("jobSignature")}
                              className={`${
                                this.props.viewMood ||
                                (this.state.connect && this.state.activeDir) ||
                                (initValues?.AD_Ref && this.state.connect)
                                  ? "read-only"
                                  : ""
                              } mb-2`}
                              name="jobSignature"
                              required={false}
                              showError={({
                                meta: { submitFailed, invalid },
                              }) => {
                                return submitFailed && invalid;
                              }}
                            />
                          </Col>
                        </>
                      )}

                      <Col xs={12} md={4}>
                        <Select
                          disabled={this.props.viewMood}
                          label={this.props.t("language")}
                          className={`${
                            this.props.viewMood ? "read-only" : ""
                          } mb-2`}
                          name="lang"
                          data={this.state.langList}
                          showError={() => {
                            let fieldStatus = form.getFieldState("lang");
                            if (fieldStatus) {
                              return (
                                fieldStatus.submitFailed && fieldStatus.invalid
                              );
                            }
                          }}
                        ></Select>
                      </Col>

                      {(this.props.editMood || this.props.viewMood) && (
                        <Col xs={12} md={12} className="mb-2">
                          {!this.props.viewMood && (
                            <h5 className="my-4 text-start">
                              {this.props.t("employeeSignatureImage")}
                            </h5>
                          )}

                          {this.props.viewMood &&
                            this.state.filesData.length > 0 && (
                              <h5 className="my-4 text-start">
                                {this.props.t("employeeSignatureImage")}
                              </h5>
                            )}

                          <FilesUpload
                            at={this.props.currentUser.Data.access_token}
                            endPoint="API/SYSTEM/USERS/UPD_USER_SIGNATURE_IMAGE"
                            params={{
                              User_Account_Id:
                                this.props.initValues?.userAccountId,
                            }}
                            filesData={this.state.filesData}
                            fileApiKey="User_Signature_Image_File"
                            single={true}
                            // deleteCB={deleteAttachment}
                            deleteCB={this.deleteAttachment}
                            // deleteParams={{
                            // 	User_Account_Id: initValues?.userAccountId,
                            // 	User_Signature_File_Code: this.state.filesData.File_Code
                            // }}
                            deleteParams={{}}
                            downloadCB={downloadAttachment}
                            handleSetFilesData={this.getUserDetails}
                            view={this.props.viewMood}
                          />

                          {this.props.editMood && (
                            <div className="my-3 text-danger d-flex align-items-start flex-column">
                              <p>{this.props.t("attachNotes")}:</p>
                              <div>{this.props.t("imgType")}</div>
                              <div>{this.props.t("imgBg")}</div>
                              <div>{this.props.t("imgSize")}</div>
                            </div>
                          )}
                        </Col>
                      )}
                    </Row>
                  </Col>

                  <Col
                    xs={12}
                    md={3}
                    className="d-flex justify-content-start align-items-center flex-column"
                  >
                    <Field name="slider">
                      {({ input: { value, onChange, ...input }, meta }) => (
                        <>
                          <div
                            className={[
                              "upload-avatar mx-3  mb-5",
                              !this.props.viewMood &&
                              meta.submitFailed &&
                              meta.invalid
                                ? "error-avatar"
                                : null,
                            ].join(" ")}
                          >
                            <Avatar
                              src={
                                this.state.imgUrl
                                  ? this.state.imgUrl
                                  : this.state.initVal?.imgUrl
                                  ? this.state.initVal?.imgUrl
                                  : "/broken-image.jpg"
                              }
                            />
                          </div>
                          {!this.props.viewMood && (
                            <ButtonSecondary
                              color="primary"
                              variant="contained"
                              endIcon={<CloudUploadIcon />}
                            >
                              <span className="mx-3">
                                {this.props.t("userAvatarButton")}
                              </span>
                              <input
                                {...input}
                                type="file"
                                className="buttonFile"
                                onChange={({ target }) => {
                                  onChange(target.files);
                                  this.handleUploadClick(target.files);
                                }}
                              />
                            </ButtonSecondary>
                          )}
                        </>
                      )}
                    </Field>
                  </Col>

                  <Col className="my-4" xs={12}>
                    <Row className="justify-content-end">
                      {this.props.editMood && (
                        <ButtonPrimary
                          variant={"contained"}
                          onClick={() => this.resetPassword()}
                        >
                          {this.props.t("resetPassword")}
                        </ButtonPrimary>
                      )}

                      {!this.props.viewMood && (
                        <ButtonPrimary
                          color="primary"
                          variant="contained"
                          disabled={submitting || this.props.viewMood}
                          type="submit"
                        >
                          {this.props.t("save")}
                        </ButtonPrimary>
                      )}
                    </Row>
                  </Col>

                  {this.props.editMood && (
                    <Col xs={12} md={4} className="mt-2">
                      <ButtonPrimary
                        variant={
                          this.state.openProfile ? "outlined" : "contained"
                        }
                        className="d-flex"
                        onClick={() => {
                          this.setState({
                            openProfile: !this.state.openProfile,
                          });
                        }}
                      >
                        {!this.state.openProfile
                          ? this.props.t("addNewProfile")
                          : this.props.t("cancel")}
                      </ButtonPrimary>
                    </Col>
                  )}
                </form>
              );
            }}
          />
        ) : (
          <></>
        )}
        {this.state.openProfile && (
          <Form
            onSubmit={this.addProfile}
            initialValues={{
              department: "",
              departmentJobs: "",
              group: "",
              jobSignature: "",
            }}
            enableReinitialize={true}
            validate={validateProfile}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              handleReset,
            }) => {
              if (
                this.props.editMood &&
                values.department &&
                values.department !== "" &&
                values.departmentJobs !== "" &&
                this.state.departmentJobs.length === 0
              ) {
                this.getDepartmentJobs(values.department);
              }
              return (
                <form
                  id="userProfile"
                  className={`row ${!this.state.openProfile ? "d-none" : ""}`}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  noValidate
                >
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} md={3}>
                        <Select
                          disabled={this.props.viewMood}
                          label={this.props.t("userPermissionsGroup")}
                          className={`${
                            this.props.viewMood ? "read-only" : ""
                          } mb-2`}
                          required={true}
                          name="group"
                          data={this.state.usersGroupList}
                          showError={() => {
                            let fieldStatus = form.getFieldState("group");
                            if (fieldStatus) {
                              return (
                                fieldStatus.submitFailed && fieldStatus.invalid
                              );
                            }
                          }}
                        ></Select>
                      </Col>

                      <Col xs={12} md={3}>
                        <Select
                          disabled={this.props.viewMood}
                          label={this.props.t("departmentName")}
                          className={`${
                            this.props.viewMood ? "read-only" : ""
                          } mb-2`}
                          name="department"
                          data={this.state.departments}
                          showError={() => {
                            let fieldStatus = form.getFieldState("department");
                            if (fieldStatus) {
                              return (
                                fieldStatus.submitFailed && fieldStatus.invalid
                              );
                            }
                          }}
                        ></Select>
                      </Col>

                      <OnChange name="department">
                        {(value) => {
                          this.getDepartmentJobs(value);
                        }}
                      </OnChange>

                      <Col xs={12} md={3}>
                        <Select
                          disabled={this.props.viewMood}
                          label={this.props.t("departmentJob")}
                          className={`${
                            this.props.viewMood ? "read-only" : ""
                          } mb-2`}
                          name="job"
                          data={this.state.departmentJobs}
                          showError={() => {
                            let fieldStatus =
                              form.getFieldState("departmentJobType");
                            if (fieldStatus) {
                              return (
                                fieldStatus.submitFailed && fieldStatus.invalid
                              );
                            }
                          }}
                        ></Select>
                      </Col>

                      <Col xs={12} md={3}>
                        <TextField
                          disabled={this.props.viewMood}
                          label={this.props.t("jobSignature")}
                          className={`${
                            this.props.viewMood ? "read-only" : ""
                          } mb-2`}
                          name="jobSignature"
                          showError={({ meta: { submitFailed, invalid } }) => {
                            return submitFailed && invalid;
                          }}
                        />
                      </Col>

                      <Col className="mt-2">
                        {!this.props.viewMood && (
                          <ButtonPrimary
                            color="primary"
                            variant="contained"
                            disabled={submitting || this.props.viewMood}
                            type="submit"
                          >
                            {this.props.t("save")}
                          </ButtonPrimary>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </form>
              );
            }}
          />
        )}
        {this.props.editMood && (
          <AccountProfilesList
            userAccId={this.props.initValues.userAccountId}
            triggerView={this.props.triggerView}
          />
        )}
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, {
  triggerNotification,
  setLoading,
  triggerDialogForm,
})(translationHook(UserForm));
