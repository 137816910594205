import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../translationHook'
import apiConfig from '../../apis/apiConfig'
import { ButtonPrimary, ButtonSecondary } from './../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form, Field } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Avatar from '@material-ui/core/Avatar'
import { triggerNotification, setLoading, triggerDialogForm } from '../../actions'


class SliderForm extends Component {

    state = {
        imgUrl: '',
        fileType: '',
        file: '',
        showForm: false,
    }

    getInitialValues = async () => {
        const at = this.props.currentUser.Data.access_token
        const response = await apiConfig.post(
            '/API/ADMINISTRATION/PORTAL/SLIDERS_MANAGEMENT/Get_Slider_info',
            {
                Portal_Slider_Id: this.props.sliderId
            },
            {
                headers: {
                    Authorization: `Bearer ${at}`
                }
            }
        )

        if (response.data.Status) {
            this.setState({
                imgUrl: response.data.Data.Portal_Slider_Media_Url,
                fileType: response.data.Data.Portal_Slider_Type
            })
        }
    }

    onSliderSubmit = async (values, form) => {
        const bodyFormData = new FormData(),
        at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/PORTAL/SLIDERS_MANAGEMENT/ADD_Slider' : '/API/ADMINISTRATION/PORTAL/SLIDERS_MANAGEMENT/Upd_Slider',
        bodyData = {
            Active_Status_Id: values.sliderStatus ? 1 : 0,
            Portal_Slider_Content_AR: values.arContent,
            Portal_Slider_Content_EN: values.enContent,
            Portal_Slider_Title_EN: values.enTitle,
            Portal_Slider_Title_AR: values.arTitle,
            Portal_Slider_Type: this.state.fileType,
            Portal_Slider_Action: values.actionStatus ? 1 : 0,
            Portal_Slider_Action_Title_AR: values.actionARTitle,
            Portal_Slider_Action_Title_EN: values.actionENTitle,
            Portal_Slider_Action_Url: values.actionURL
        }

        // if new slider add slider file
        if (!this.props.editMood && !this.props.viewMood) {
            bodyFormData.append('Portal_Slider_Media_Url', this.state.file)
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        // if editMood update slide info and slide file if changed in two requests
        if (this.props.editMood) {
            // update slider info with slide id
            bodyData.Portal_Slider_Id = this.props.sliderId

            // handle update slider media if user changed file input
            if (this.state.file) {
                const mediaBodyFormData = new FormData()

                mediaBodyFormData.append('Data', JSON.stringify({
                    Portal_Slider_Id: this.props.sliderId,
                    Portal_Slider_Type: this.state.fileType
                }))
                mediaBodyFormData.append('Portal_Slider_Media_Url', this.state.file)

                const mediaResponse = await apiConfig.post(
                    '/API/ADMINISTRATION/PORTAL/SLIDERS_MANAGEMENT/upd_Slider_Media',
                    mediaBodyFormData,
                    {
                        headers: {
                            Authorization: `Bearer ${at}`
                        },
                    }
                )
            }
            // end handle update slider media
        }

        bodyFormData.append('Data', JSON.stringify(bodyData))


        const response = await apiConfig.post(
            url,
            bodyFormData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        )

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)

        this.props.triggerDialogForm(false)

        if (response.data.Status) {
            form.restart()
        }

        this.setState({
            imgUrl: '',
            fileType: '',
            file: ''
        })

        // reset parent somponent Slider.js editMood and viewMood states after automatically closing the edit modal via triggerDialogForm
        if ( this.props.editMood ) {
            this.props.triggerView(false, false)
        }
    }

    handleUploadClick =  files => {
        let file = files[0]
        const reader = new FileReader()
        let url = reader.readAsDataURL(file)
        reader.onload = (e) => {
            let type = reader.result.split(':')[1].split(' ')[0].split('/')[0]

            this.setState({
                imgUrl: reader.result,
                fileType: type === 'image' ? 1 : 2,
                file
            })
        }
    }

    componentDidMount() {
        if (this.props.initValues) {
            this.setState({
                fileType: this.props.initValues.fileType,
                imgUrl: this.props.initValues.slider
            })
        }
    }

    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}

            if (!values.slider) {
                errors.slider = `${this.props.t('error.field_required')}`
            }

            if (!values.arTitle) {
                errors.arTitle = `${this.props.t('error.field_required')}`;
            }

            if (!values.enTitle) {
                errors.enTitle = `${this.props.t('error.field_required')}`;
            }

            if (this.state.fileType === 1) {

                if (!values.arContent) {
                    errors.arContent = `${this.props.t('error.field_required')}`;
                }
                
                if (!values.enContent) {
                    errors.enContent = `${this.props.t('error.field_required')}`;
                }

                if (values.actionStatus) {
                    if (!values.actionARTitle) {
                        errors.actionARTitle = `${this.props.t('error.field_required')}`;
                    }
        
                    if (!values.actionENTitle) {
                        errors.actionENTitle = `${this.props.t('error.field_required')}`;
                    }
                }
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-start align-items-center pb-5">
                    <h3>{this.props.t('manageSlider')}</h3>
                    </div>
                }
                {!this.props.editMood && !this.props.viewMood &&
                <div className="d-flex justify-content-end align-items-center">
                    <ButtonPrimary
                        variant={!this.state.showForm ? 'contained' : 'outlined'}
                        onClick={() => this.setState({showForm: !this.state.showForm}) }
                    >
                        {!this.state.showForm && this.props.t('addSlide')}
                        {this.state.showForm && this.props.t('cancel')}
                    </ButtonPrimary>
                </div>
            }
                <Form
                    onSubmit={this.onSliderSubmit}
                    initialValues={initValues ? initValues : {
                        sliderStatus: true,
                        arContent:'',
                        enContent:'',
                        enTitle:'',
                        arTitle:'',
                        actionStatus:'',
                        actionARTitle:'',
                        actionENTitle:'',
                        actionURL:'',
                        slider:'',
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={9}>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <TextField
                                            disabled={this.props.viewMood}
                                            label={this.props.t('slideArTitle')}
                                            className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                            name="arTitle"
                                            required={true}
                                            showError={({ meta: { submitFailed, invalid } }) => {
                                                return submitFailed && invalid
                                            }}
                                        />
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <TextField
                                            disabled={this.props.viewMood}
                                            label={this.props.t('slideEnTitle')}
                                            className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                            name="enTitle"
                                            required={true}
                                            showError={({ meta: { submitFailed, invalid } }) => {
                                                return submitFailed && invalid
                                            }}
                                        />
                                    </Col>

                                    <Col xs={12} md={4} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                        <Switches
                                            disabled={this.props.viewMood}
                                            color="primary"
                                            name="sliderStatus"
                                            data={{ label: `${this.props.t('slideStatus')}`, value: `${this.props.t('slideStatus')}` }}
                                        />
                                    </Col>

                                    {this.state.fileType === 1 &&
                                        <>
                                            <Col xs={12} md={6}>
                                                <TextField
                                                    disabled={this.props.viewMood}
                                                    label={this.props.t('slideArContent')}
                                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                    name="arContent"
                                                    multiline
                                                    required={true}
                                                    showError={({ meta: { submitFailed, invalid } }) => {
                                                        return submitFailed && invalid
                                                    }}
                                                />
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <TextField
                                                    disabled={this.props.viewMood}
                                                    label={this.props.t('slideEnContent')}
                                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                    name="enContent"
                                                    multiline
                                                    required={true}
                                                    showError={({ meta: { submitFailed, invalid } }) => {
                                                        return submitFailed && invalid
                                                    }}
                                                />
                                            </Col>
                                
                                            <Col xs={12} md={4} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                                <Switches
                                                    disabled={this.props.viewMood}
                                                    color="primary"
                                                    name="actionStatus"
                                                    data={{ label: `${this.props.t('slideActionBtnStatus')}`, value: `${this.props.t('slideActionBtnStatus')}` }}
                                                />
                                            </Col>
                                        </>
                                    }
                                </Row>

                                {values.actionStatus &&
                                    <>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <TextField
                                                    disabled={this.props.viewMood}
                                                    label={this.props.t('slideActionBtnARTitle')}
                                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                    name="actionARTitle"
                                                    required={true}
                                                    showError={({ meta: { submitFailed, invalid } }) => {
                                                        return submitFailed && invalid
                                                    }}
                                                />
                                            </Col>

                                            <Col xs={12} md={4}>
                                                <TextField
                                                    disabled={this.props.viewMood}
                                                    label={this.props.t('slideActionBtnENTitle')}
                                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                    name="actionENTitle"
                                                    required={true}
                                                    showError={({ meta: { submitFailed, invalid } }) => {
                                                        return submitFailed && invalid
                                                    }}
                                                />
                                            </Col>

                                            <Col xs={12} md={4}>
                                                <TextField
                                                    disabled={this.props.viewMood}
                                                    label={this.props.t('slideActionBtnURL')}
                                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                                    name="actionURL"
                                                    showError={({ meta: { submitFailed, invalid } }) => {
                                                        return submitFailed && invalid
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Col>

                            <Col xs={12} md={3} className="d-flex justify-content-start align-items-center ServiceAvatarContainer">
                                <Field name="slider">
                                {
                                    ({ input: { value, onChange, ...input }, meta }) => (
                                        <>
                                        <div className={[
                                            "upload-avatar mx-3",
                                            !this.props.viewMood && meta.submitFailed && meta.invalid ?  "error-avatar" : null
                                        ].join(' ')}>
                                            <Avatar src={this.state.imgUrl ? this.state.imgUrl : '/broken-image.jpg'} />
                                        </div>
                                        {  !this.props.viewMood &&
                                            <ButtonSecondary
                                                color="primary"
                                                variant="contained"
                                                endIcon={<CloudUploadIcon />}
                                            >
                                                {this.props.t('slideAvatarButton')}
                                                <input 
                                                    {...input}
                                                    type="file" 
                                                    className="buttonFile" 
                                                    onChange={({ target }) => {
                                                        onChange(target.files)
                                                        this.handleUploadClick(target.files)
                                                    }}
                                                    // onChange={this.handleUploadClick} 
                                                />
                                            </ButtonSecondary>
                                        }
                                        </>
                                    )
                                }
                                
                            </Field>
                            </Col>    
                            
                            <Col className='my-4' xs={12}>
                                <Row className='align-items-center'>
                                    <Col className='mt-2'>
                                    { !this.props.viewMood && 
                                        <ButtonPrimary
                                            color="primary"
                                            variant="contained"
                                            disabled={submitting || this.props.viewMood}
                                            type="submit"
                                        >
                                            {this.props.t('save')}
                                        </ButtonPrimary>
                                    }
                                    </Col>
                                </Row>
                            </Col>
                       
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(SliderForm))
