import React from "react"
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Divider from '@material-ui/core/Divider'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'
import { setLoading, triggerNotification } from '../../../actions'

const FilesList = (props) => {

    const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        maxWidth: props.deleteCB ? 'auto' : 360,
        backgroundColor: theme.palette.background.paper,
        '& .MuiListItemText-root': {
          textAlign: 'start'
        },
        '& .MuiListItem-secondaryAction': {
          paddingRight: props.i18n.language === 'ar' ? '16px' : '48px',
          paddingLeft: props.i18n.language === 'ar' ? '48px' : '16px',
        },
        '& .MuiListItemSecondaryAction-root': {
          right: props.i18n.language === 'ar' ? 'auto' : '16px',
          left: props.i18n.language === 'ar' ? '16px' : 'auto'
        },
        '& .MuiListItemAvatar-root': {
          minWidth: 'initial',
          marginLeft: props.i18n.language === 'ar' ? '10px' : '0',
          marginRight: props.i18n.language === 'ar' ? '0' : '10px',
        }
      },
      uploadInput: {
        marginBottom: '6px',
        '& .MuiButtonBase-root': {
          position: 'relative',
          minWidth: '100px',
          display: 'inline-flex',
          justifyContent: 'space-between',
          '& .MuiButton-endIcon': {
            marginLeft: props.i18n.language === 'ar' ? '-4px' : '8px',
            marginRight: props.i18n.language === 'ar' ? '8px' : '-4px',
          }
        },
        '& input': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
          opacity: 0,
          fontSize: 0,
          cursor: 'pointer'
        }
      },
      progressBar: {
        backgroundColor: '#2e7d32'
      }
    }))

    const classes = useStyles()
  
  return (
    <div className="text-start">
      { props.filesList.length > 0 &&
        <div className={classes.root}>
          <List component="nav" disablePadding={true}>
            {
              props.filesList.map((file, index) => (
                <>
                <ListItem key={index}>
                  
                  <ListItemAvatar>
                      <CropOriginalIcon />
                  </ListItemAvatar>

                  <ListItemText primary={file.name} secondary={file.size} />

                </ListItem>
                <Divider />
                </>
              ))
            }
          </List>
        </div>
      }

    </div>
  )
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser }
}


export default connect(mapStateToProps, { setLoading, triggerNotification })(translationHook(FilesList))