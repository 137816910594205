import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import apiConfig from "../../apis/apiConfig";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {
  DataGrid,
  Column,
  SearchPanel,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Export,
  Grouping,
  GroupPanel,
  Selection,
} from "devextreme-react/data-grid";

import { ExcelJS, Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { getServiceTemplatesList } from "../../apis/services/getServiceTemplatesList";
import { deleteService } from "../../apis/services/deleteService";
import { addServiceTemplate } from "../../apis/services/addServiceTemplate";
import { deleteServiceTemplate } from "../../apis/services/deleteServiceTemplate";
import {
  triggerDialog,
  setLoading,
  reload,
  triggerNotification,
} from "../../actions";
import { translationHook } from "../translationHook";
import { ButtonPrimary } from "./../UI/Buttons";

class ServiceTemplatesList extends Component {
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);
  }
  handleDelete = (data) => {
    let at = this.props.currentUser.Data.access_token,
      serviceid = data.Service_Id,
      serviceName =
        this.props.i18n.language === "ar"
          ? data.Service_Name_AR
          : data.Service_Name_EN,
      dialogTitle = `${this.props.t("confirmDeleteTitle")} ${serviceName}`,
      dialogMsg = this.props.t("confirmDeleteMsg");

    this.props.triggerDialog(true, {
      title: dialogTitle,
      message: dialogMsg,
      action: {
        action: deleteService,
        params: [at, serviceid],
        text: this.props.t("deleteService"),
      },
    });
  };

  setOrderUp = async (serviceId) => {
    let at = this.props.currentUser.Data.access_token;

    this.props.setLoading(true, "dark");

    const response = await apiConfig.post(
      "/API/ADMINISTRATION/SERVICES/SET_SERVICE_ORDER_UP",
      {
        Service_Id: serviceId,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    this.props.setLoading(false);
    this.props.reload();
  };

  setOrderDown = async (serviceId) => {
    let at = this.props.currentUser.Data.access_token;

    this.props.setLoading(true, "dark");

    const response = await apiConfig.post(
      "/API/ADMINISTRATION/SERVICES/SET_SERVICE_ORDER_DOWN",
      {
        Service_Id: serviceId,
      },
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    this.props.setLoading(false);
    this.props.reload();
  };

  renderOrderButtons = ({ data }) => {
    return (
      <div className="d-flex flex-column align-items-center order-btns">
        <IconButton onClick={() => this.setOrderUp(data.Service_Id)}>
          <KeyboardArrowUpIcon />
        </IconButton>
        <IconButton onClick={() => this.setOrderDown(data.Service_Id)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
    );
  };

  customizeCellCheckbox = ({ data }) => {
    let label = "";
    return (
      <FormControlLabel
        control={
          <Switch
            checked={data.Selected}
            name={`template_${data.Template_Id}`}
            onChange={async () => {
              let at = this.props.currentUser.Data.access_token,
                response;

              this.props.setLoading(true, "dark");

              if (!data.Selected) {
                response = await addServiceTemplate(
                  at,
                  data.Service_Id,
                  data.Template_Id
                );
              } else {
                response = await deleteServiceTemplate(
                  at,
                  data.Service_Id,
                  data.Template_Id
                );
              }

              this.props.triggerNotification(true, response);

              this.props.setLoading(false);
            }}
          />
        }
        label={label}
      />
    );
  };

  render() {
    let columnAlign = "left",
      at = this.props.currentUser.Data.access_token;

    if (this.props.i18n.language === "ar") {
      columnAlign = "right";
    }

    const jsonDataSource = new CustomStore({
      key: "Template_Id",
      load: () => {
        return getServiceTemplatesList(at, this.props.serviceid);
      },
    });

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>{this.props.t("manageServices")}</h3>
          <ButtonPrimary component={Link} to="add-service" size="large">
            {this.props.t("addService")}
          </ButtonPrimary>
        </div>

        <DataGrid
          className={[
            this.props.i18n.language === "ar" ? "dx-grid-ar" : "dx-grid-en",
            "globalBox",
          ].join(" ")}
          id="dataGrid"
          width={"100%"}
          dataSource={jsonDataSource}
          showBorders={false}
          showColumnLines={false}
          showRowLines={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          allowColumnReordering={true}
          rowAlternationEnabled={false}
          focusedRowEnabled={true}
          onExporting={this.onExporting}
          columnWidth={"auto"}
          wordWrapEnabled={true}
          rtlEnabled={this.props.i18n.language === "ar" ? true : false}
        >
          <ColumnChooser
            enabled={true}
            allowSearch={true}
            search={"fff"}
            title={this.props.t("dxColumnChooserTitle")}
            emptyPanelText={this.props.t("dxColumnChooserPanelText")}
          />

          <SearchPanel
            visible={true}
            width={240}
            placeholder={this.props.t("search")}
          />

          <FilterRow visible={true} />

          <HeaderFilter visible={true} />

          <Export enabled={true} allowExportSelectedData={true} />

          <Grouping contextMenuEnabled={true} expandMode="rowClick" />
          <GroupPanel
            visible={true}
            emptyPanelText={this.props.t("dxGroupPanelText")}
          />

          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />

          <Column
            dataField={
              this.props.i18n.language === "en"
                ? "Template_Name_EN"
                : "Template_Name_AR"
            }
            caption={this.props.t("templateName")}
            alignment={columnAlign}
          ></Column>

          <Column
            cssClass="outlinedColumn"
            dataField="Template_has_Invoices"
            dataType="boolean"
            caption={this.props.t("hasInvoices")}
            alignment={"center"}
          ></Column>

          <Column
            dataField={
              this.props.i18n.language === "en"
                ? "Template_API_Name_EN"
                : "Template_API_Name_AR"
            }
            caption={this.props.t("templateApiName")}
            alignment={columnAlign}
          ></Column>

          <Column
            cssClass="outlinedColumn"
            dataField="Template_Skip_API_Errors"
            dataType="boolean"
            caption={this.props.t("apiInfoMandatory")}
            alignment={"center"}
          ></Column>

          <Column
            dataField={"Selected"}
            caption={this.props.t("select")}
            cellRender={this.customizeCellCheckbox}
          ></Column>
        </DataGrid>
      </>
    );
  }

  onExporting(e) {
    let sheetTitle = this.props.t("manageServices");
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    exportDataGridToExcel({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
      topLeftCell: { row: 2, column: 2 },
      topRightCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        // excelCell.value = gridCell.value;
        excelCell.font = {
          family: 2,
          name: "Cairo",
          size: 10,
          color: { argb: "151713" },
        };
        excelCell.alignment = {
          indent: 1,
          wrapText: true,
          vertical: "middle",
        };
        if (gridCell.rowType === "group") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0b166" },
          };
          excelCell.alignment = { indent: 2 };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 10,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
        if (gridCell.rowType === "header") {
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "58743a" },
          };
          excelCell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          excelCell.font = {
            family: 2,
            name: "Cairo",
            size: 12,
            color: { argb: "ffffff" },
            bold: true,
          };
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `${sheetTitle}.xlsx`
        );
      });
    });
    e.cancel = true;
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    reload: state.reload,
  };
};

export default connect(mapStateToProps, {
  triggerDialog,
  setLoading,
  reload,
  triggerNotification,
})(translationHook(ServiceTemplatesList));
