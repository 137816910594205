import React from 'react'

import Notes from '../Notes'
import { saveRequestInspectionTime, saveRequestInspectionReport, saveRequestApprovingInspectionReport, saveRequestInspectionVisit } from '../../../../apis/requests/processTask'

import TimeForm from './TimeForm'
import Report from './Report'
import ReportApproving from './ReportApproving'
import Visit from './Visit'

export const InspectionTime = (props) => {
    
    return (
        <>
        <TimeForm requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} task={props.task} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestInspectionTime} />
        
        </>
    )
}

export const InspectionReport = (props) => {
    
    return (
        <>
        <Report requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} task={props.task} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestInspectionReport} />
        
        </>
    )
}

export const InspectionReportApproving = (props) => {
    
    return (
        <>
        <ReportApproving requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestApprovingInspectionReport} />
        
        </>
    )
}

export const InspectionVisit = (props) => {
    
    return (
        <>
        <Visit requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} task={props.task} onTaskSubmit={saveRequestInspectionVisit} />
        
        </>
    )
}

