import apiConfig from "../apiConfig";

export const getSuspensionsList = async (at) => {
  const response = await apiConfig.post(
    "/API/ADMINISTRATION/VIOLATIONS/GET_VIOLATION_LIST",
    {
      pageCount: 10000,
      PageIndex: 1,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  if (response.data.Status) {
    return response.data.Data.Data;
  }

  return [];
};

export const getAllSuspensionsList = async (at) => {
  const response = await apiConfig.post(
    "/API/ADMINISTRATION/VIOLATIONS/GET_VIOLATION_ALL_LIST",
    {
      pageCount: 10000,
      PageIndex: 1,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  if (response.data.Status) {
    return response.data.Data.Data;
  }

  return [];
};

export const deleteSuspensionAttachment = async (at, attachmentId) => {
  const response = await apiConfig.post(
    "/API/ADMINISTRATION/VIOLATIONS/DEL_VIOLATION_ATTACHMENT",
    {
      Violation_Attach_Id: attachmentId,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  return response;
};
export const downloadSuspensionAttachment = async ({ at, File_Code }) => {
  const response = await apiConfig.post(
    "/API/ADMINISTRATION/VIOLATIONS/DOWNLOAD_VIOLATION_ATTACHMENT_FILE",
    {
      File_Code: File_Code,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  return response;
};

export const cancelSusbension = async (at, violationId) => {
  const response = await apiConfig.post(
    "/API/ADMINISTRATION/VIOLATIONS/CANCEL_VIOLATION",
    {
      Violation_Id: violationId,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  return response;
};
