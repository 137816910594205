import apiConfig from '../../apiConfig'
export const getFaqList = async (at) => {
	const response = await apiConfig.post(
		'/API/ADMINISTRATION/PORTAL/QUESTIONS/GET_QUESTION_LIST',
		{
			pageCount: 10000,
			PageIndex: 1
		},
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);
	return response.data.Data.Data;
};
export const deleteQous = async (at, govEntId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/PORTAL/QUESTIONS/DEL_QUESTION',
        {
            Question_Id: govEntId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    if (response.data.Status) {
        return response.data
    }

    return []
}