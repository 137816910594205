import apiConfig from '../../apiConfig';

export const getUsersGroupList = async (at) => {
	const response = await apiConfig.post(
		'/API/SYSTEM/USERS/GROUPS/GET_USERS_GROUPS_LIST',{}, {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        },
	);

	if (response.data.Status) {
		for (let i in response.data.Data) {
			if (response.data.Data[i].Active_Status_Id === 1) {
				response.data.Data[i].Active_Status_Id = true;
			} else {
				response.data.Data[i].Active_Status_Id = false;
			}
		}
		return response.data.Data;
	}

	return [];
};
