import apiConfig from "../../apiConfig";

export const addUserArea = async (at, userId, areaId) => {
  const response = await apiConfig.post(
    "API/BASIC/SETTINGS/USERS_AREAS/ADD_USER_AREA",
    {
      User_Id: userId,
      Area_Id: areaId,
    },
    {
      headers: {
        Authorization: `Bearer ${at}`,
      },
    }
  );

  if (response.data.Status) {
    return response.data;
  }

  return [];
};
