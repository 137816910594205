import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary , ButtonSecondary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'
import GoogleMap from '../../UI/google-map/googleMap'



class DepartmentSectionFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            detctedLocation: false,
        }
    }
    // position: {
    //     lat:  25.3198732,
    //     lng: 55.4061779,
    // }
    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.sharjaPosition);
        }
    }

    sharjaPosition = (position) => {
        this.setState({
            position: {
                lat:  position.coords.latitude,
                lng: position.coords.longitude,
            }
        })
        this.getLatLng(this.state.position)
    }
    detectLocationFunc = () => {
        this.getLocation()
        this.setState({
            detctedLocation: true
        })
    }
    componentDidMount() {
        const { initValues } = this.props

        if(initValues) {
            console.log(initValues);
            this.setState({
                position: {
                    lat: initValues.lat,
                    lng: initValues.lng,
                }
            })
        } else {
            this.getLocation()
        }
    }


    onSectionSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? 
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/ADD_DEPARTMENT_CENTER' : 
        '/API/ADMINISTRATION/LOOKUPS/DEPARTMENTS/UPD_DEPARTMENT_CENTER',
        bodyData = {
            Department_Service_Center_Name_AR: values.centerNameAR,
            Department_Service_Center_Name_EN: values.centerNameEN,
            Department_Service_Center_Address_AR: values.centerAddAR,
            Department_Service_Center_Address_EN: values.centerAddEN,
            Department_Service_Center_Telephone: values.telephone,
            Department_Service_Center_Email: values.email,
            Department_Service_Center_latitudes: this.state.position.lat,
            Department_Service_Center_longitudes: this.state.position.lng,
            Active_Status_Id: values.centerStatus ? 1 : 0
        }

        if (this.props.editMood) {
            bodyData.Department_Service_Center_Id = this.props.departmentCenterId
        } else {
            bodyData.Department_Id = this.props.currentUser.Data.Department_Id
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)
        

    }
    getLatLng = (position) => {
        this.setState({
            position
        })
    }

    
    onLoad = () => {
        let { initValues } = this.props
        initValues  ? 
        this.setState({
            position:  {lat: initValues.lat, lng: initValues.lng}
        })
        : 
        this.setState({
            position:  this.sharjaPosition
        })
    }

    render() {
        
        let { initValues } = this.props;

        const validate = values => {
            const errors = {}

            if (!values.centerNameAR) {
                errors.centerNameAR = `${this.props.t('error.field_required')}`
            }

            if (!values.centerNameEN) {
                errors.centerNameEN = `${this.props.t('error.field_required')}`
            }

            if (!values.centerAddAR) {
                errors.centerAddAR = `${this.props.t('error.field_required')}`
            }

            if (!values.centerAddEN) {
                errors.centerAddEN = `${this.props.t('error.field_required')}`
            }
            if (!values.telephone) {
                errors.telephone = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('manageCenters')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addCenter')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }
                
                <Form
                    onSubmit={this.onSectionSubmit}
                    initialValues={initValues ? initValues : {
                        centerStatus: true,
                        centerNameAR: '',
                        centerNameEN: '',
                        centerAddAR: '',
                        centerAddEN: '',
                        telephone: '',
                        email: ''
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled
                                    label={this.props.t('centerNo')}
                                    className="mb-2"
                                    name="centerNo"
                                />
                            </Col>

                            <Col xs={12} md={6} className={`${this.props.viewMood ? 'read-only' : ''}`}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="centerStatus"
                                    data={{ label: `${this.props.t('centerStatus')}`, value: `${this.props.t('centerStatus')}` }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('centerNameAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="centerNameAR"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('centerNameEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="centerNameEN"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('centeAddAR')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="centerAddAR"
                                    multiline
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('centerAddEN')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    required={true}
                                    name="centerAddEN"
                                    multiline
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid 
                                    } }
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('telephone')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="telephone"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('mail')}
                                    className={`${this.props.viewMood ? 'read-only' : ''} mb-2`}
                                    name="email"
                                    required={true}
                                    autoComplete="off"
                                    showError={({ meta: { submitFailed, invalid } }) => {
                                        return submitFailed && invalid
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={10} className="m-auto">
                                {this.state.showForm ? 
                                <GoogleMap 
                                position={this.state.position}
                                latLng={this.getLatLng} 
                                />
                                : false
                                }
                                {initValues ? 
                                <>
                                <ButtonSecondary 
                                className="mb-3"
                                variant='outlined' 
                                onClick={() => {
                                this.detectLocationFunc()
                            }}>
                                    {this.props.t('knowMyLocation')}
                                </ButtonSecondary>
                                    <GoogleMap 
                                        position=
                                        {this.state.position}
                                        latLng={this.getLatLng} 
                                    />
                                </>
                                : false }

                            </Col>

                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(DepartmentSectionFrom))
