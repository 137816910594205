import apiConfig from '../apiConfig'

export const getRequestsList = async (at, data) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/REQUESTS/LIST/GET_REQUESTS_LIST',
        data,
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )

    return response.data.Data
}
