import React, { Component } from 'react';
import { connect } from 'react-redux';
import apiConfig from '../../../apis/apiConfig';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { ButtonPrimary, ButtonSecondary , ButtonDanger } from './../../UI/Buttons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import ListItemText from '@material-ui/core/ListItemText';

import { translationHook } from '../../translationHook';
import { triggerNotification, setLoading , triggerDialog } from '../../../actions';
import ColorBox from 'devextreme-react/color-box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import style from '../../requests/processing/Processing.module.scss';
import AddTempColor from './AddTempColor';
import { deleteTemp } from '../../../apis/system/housing'
class ModuleSecondInfo extends Component {
	state = {
		colors: [],
		file: '',
		urls:[]
	};
	// fileName: '',

	uploadNewFile = async (index) => {
		const bodyFormData = new FormData(),
			at = this.props.currentUser.Data.access_token,
			url =
				'API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/UPD_APPROVING_CHART_TEMPLATE_FILE',
			bodyData = {
				Approving_Chart_Template_Id:
					this.state.initValues[index].Approving_Chart_Template_Id,
			};

		bodyFormData.append('PARAMETERS', JSON.stringify(bodyData));

		bodyFormData.append('Approving_Chart_Template_Image', this.state.file);

		this.props.setLoading(true, 'dark');

		const response = await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.triggerNotification(true, response.data);

		this.props.setLoading(false);

		if (response.data.Status) {
			this.setState({
				file: null,
			});
		}
	};

	onSubmit = async (values, form) => {
		const bodyFormData = new FormData(),
			at = this.props.currentUser.Data.access_token,
			url = '/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/UPD_APPROVING_CHART_TEMPLATE',
			bodyData = {
				approving_Chart_Id: this.props.moduleID,
				approving_Chart_Template_Name_AR:
					values.Approving_Chart_Template_Name_AR,
				approving_Chart_Template_Name_EN:
					values.Approving_Chart_Template_Name_EN,
				approving_Chart_Template_Color_Name:
					values.Approving_Chart_Template_Color_Name,
				approving_Chart_Template_Color: this.state.colors[values.index],
				Approving_Chart_Template_Id: values.Approving_Chart_Template_Id,
				Active_Status_Id: 1
			};

		bodyFormData.append('PARAMETERS', JSON.stringify(bodyData));
		bodyFormData.append('Approving_Chart_Template_Image', this.state.file);
		this.props.setLoading(true, 'dark');

		const response = await apiConfig
			.post(url, bodyFormData, {
				headers: {
					Authorization: `Bearer ${at}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);

		if (response.data.Status) {
			form.restart();
			this.secondInfo();
		}
	};
	handleColorChange = ({ value } , index) => {
		this.state.colors[index] = value;
		let colors = [...this.state.colors];
		let color = {...colors[index]};
		color = value;
		colors[index] = color;
		this.setState({colors})
	};
	validate = (values) => {
		const errors = {};
		if (!values.Approving_Chart_Template_Name_AR) {
			errors.Approving_Chart_Template_Name_AR = `${this.props.t(
				'error.field_required'
			)}`;
		}
		if (!values.Approving_Chart_Template_Name_EN) {
			errors.Approving_Chart_Template_Name_EN = `${this.props.t(
				'error.field_required'
			)}`;
		}
		if (!values.Approving_Chart_Template_Color_Name) {
			errors.Approving_Chart_Template_Color_Name = `${this.props.t(
				'error.field_required'
			)}`;
		}
		return errors;
	};
	handleAddFiles = (e , index) => {

		this.state.urls[index] = window.URL.createObjectURL(e.target.files[0]);
		let urls = [...this.state.urls];
		let url = {...urls[index]};
		url = window.URL.createObjectURL(e.target.files[0]);
		urls[index] =  url;
		this.setState({
			urls,
			file: e.target.files[0],
			fileName: e.target.files[0].name
		})

	};

	componentDidMount() {
		this.secondInfo();
	}
	secondInfo = async () => {
		let at = this.props.currentUser.Data.access_token,
			url =
				'API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/GET_APPROVING_CHART_TEMPLATE_LIST';
		const response = await apiConfig
			.post(
				url,
				{ Approving_Chart_Id: this.props.moduleID },
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error.response;
			});
		const rees = response.data.Data.forEach((item , index) => item.index = index);

		this.setState({
			initValues: 
			[...response.data.Data]
		});
		if (this.state.initValues) {
			this.state.initValues.map(({Approving_Chart_Template_Color , Approving_Chart_Template_Image_Url}) => {
				this.state.colors.push(Approving_Chart_Template_Color)
				this.state.urls.push(Approving_Chart_Template_Image_Url)
			})
		}
	};

	handleDelete = (data) => {
		let at = this.props.currentUser.Data.access_token,
			id = data.Approving_Chart_Template_Id,
			tempName =
				this.props.i18n.language === 'ar'
					? data.Approving_Chart_Template_Name_AR
					: data.Approving_Chart_Template_Name_EN,
			dialogTitle = `${this.props.t('confirmDeleteTitle')} ${tempName}`,
			dialogMsg = this.props.t('confirmDeleteMsg');

		this.props.triggerDialog(true, {
			title: dialogTitle,
			message: dialogMsg,
			action: {
				action: this.deletTemplate,
				params: [
					at,
					id
				],
				text: this.props.t('delete'),
			},
		});
	}
	deletTemplate = async (at , data) => {
		const response = await apiConfig.post(
			'/API/ADMINISTRATION/PORTAL/APPROVINGCHARTS/DEL_APPROVING_CHART_TEMPLATE',
			{
				Approving_Chart_Template_Id: data
			},
			{
				headers: {
					Authorization: `Bearer ${at}`
				}
			}
		)
		if (response.data.Status) {
			this.secondInfo();
			return response.data
		}
		return []
	}
	

	render() {
		return (
			<>
			{!this.props.viewMood && (
				<AddTempColor  moduleID={this.props.moduleID} addDone={this.secondInfo} />
			)}
			{this.state.initValues?.map((item, index) => (
				<Accordion
					className={`${style.accordionWrap} py-4 mb-3`}
					key={`task_${item.Approving_Chart_Template_Id}`}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`task_${index}`}
						id={`task_${index}`}>
							<h3>
							{this.props.i18n.language === 'ar' ? item.Approving_Chart_Template_Name_AR :  item.Approving_Chart_Template_Name_EN}
							</h3>
						</AccordionSummary>

					<AccordionDetails className={style.accordionDetail}>
						<Form
							onSubmit={this.onSubmit}
							initialValues={item}
							validate={this.validate}
							render={({
								handleSubmit,
								form,
								submitting,
								pristine,
								values,
							}) => (
								<form onSubmit={handleSubmit} noValidate>
									<Col xs={12} md={9}>
										<Row>
											<Col xs={12} md={6}>
												<TextField
													disabled={this.props.viewMood}
													label={this.props.t('ModuleNameAR')}
													className={`${
														this.props.viewMood ? 'read-only mb-2' : 'mb-2'
													}`}
													name='Approving_Chart_Template_Name_AR'
												/>
											</Col>
											<Col xs={12} md={6}>
												<TextField
													disabled={this.props.viewMood}
													label={this.props.t('ModuleNameEN')}
													className={`${
														this.props.viewMood ? 'read-only mb-2' : 'mb-2'
													}`}
													name='Approving_Chart_Template_Name_EN'
												/>
											</Col>
											<Col md={6}>
												<div className='text-left MuiFormControl-root MuiTextField-root mb-2 MuiFormControl-fullWidth'>
													<label className='text-start MuiFormLabel-root MuiInputLabel-root mb-0 MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled'>
														{this.props.t('colorCode')}
													</label>
													<ColorBox
														disabled={this.props.viewMood}
														className='custom-colorPicker'
														value={this.state.colors[index]}
														applyValueMode='instantly'
														onValueChanged={(e) => this.handleColorChange(e , index)}
													/>
												</div>
											</Col>
											<Col xs={12} md={6}>
												<TextField
													disabled={this.props.viewMood}
													label={this.props.t('colorName')}
													className={`${
														this.props.viewMood ? 'read-only mb-2' : 'mb-2'
													}`}
													name='Approving_Chart_Template_Color_Name'
												/>
											</Col>
											{!this.props.viewMood && (
												<Col
													className='my-4 d-flex ml-auto my-4'
													xs={12}
													md={12}>
													<ButtonSecondary
														color='primary'
														variant='contained'
														endIcon={<CloudUploadIcon />}>
														{this.props.t('mainImage')}
														<input
															type='file'
															name='Approving_Chart_File'
															className='buttonFile'
															accept='image/*'
															onChange={(e) => this.handleAddFiles(e, index)}
														/>
													</ButtonSecondary>
												</Col>
											)}
											{this.state.urls[index] && (
												<Col xs={12} md={6}>
													<ListItem className='shadow-sm'>
														<ListItemAvatar>
															<CropOriginalIcon />
														</ListItemAvatar>
														<ListItemText
															primary={this.props.t('mainImage')}
														/>
														<IconButton
															edge='start'
															aria-label='download'
															onClick={() => {
																window.open(this.state.urls[index], '_blank');
															}}>
															<GetAppIcon />
														</IconButton>
													</ListItem>
												</Col>
											)}
											{this.state.file && this.props.editMood && (
												<Col xs={12} md={6}>
													<ButtonPrimary
														color='primary'
														variant='contained'
														type='button'
														className='ml-auto d-flex align-items-center'
														onClick={(e) => this.uploadNewFile(index)}>
														{this.props.t('uploadnewFile')}
													</ButtonPrimary>
												</Col>
											)}
											{!this.props.viewMood && (
												<Col className='my-4 d-flex ml-auto my-4' xs={12}>
													<ButtonPrimary
														color='primary'
														variant='contained'
														disabled={submitting || this.props.viewMood}
														type='submit'>
														{this.props.t('save')}
													</ButtonPrimary>
													<ButtonDanger
														color='primary'
														variant='contained'
														type='button'
														onClick={
															(e) => this.handleDelete(item , index) 
														}
														>
														{this.props.t('delete')}
													</ButtonDanger>
												</Col>
											)}
										</Row>
									</Col>
								</form>
							)}
						/>
					</AccordionDetails>
				</Accordion>
			))}
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, { triggerNotification, setLoading , triggerDialog })(
	translationHook(ModuleSecondInfo)
);
