import apiConfig from '../../apiConfig';
export const saveDepartmentVersionSelectedStep = async (at, versionId, selectedStepList) => {

    const response = await apiConfig.post(
        '/API/ADMINISTRATION/DEPARTMENTS/SERVICES/SAVE_DEPARTMENT_VERSION_SELECTED_STEPS',
        {
            Department_Service_Version_Id: versionId,
            Service_Step_Id_List: selectedStepList
        }, 
        {
            headers: {
                Authorization: `Bearer ${at}`,
            },
        }
    );

    return response;


}
