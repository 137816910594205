import apiConfig from '../apiConfig'

export const deleteDocument = async (at, documentId) => {
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/TYPES/Del_Document_Type',
        {
            Document_Type_Id: documentId
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    )


    if (response.data.Status) {
        return response.data
    }

    return []
}
