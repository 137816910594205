import apiConfig from '../apiConfig'

export const ForgetPass = async (data) => {
    const response = await apiConfig.post('API/SYSTEM/LOGIN/FORGET_PASSWORD', data )
    return response.data
}
export const ResetForgetPass = async (data) => {
    const response = await apiConfig.post('API/SYSTEM/LOGIN/RESET_FORGET_PASSWORD', data )
    return response.data
}
