import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { triggerNotification, setLoading } from '../../../actions';
import { ButtonPrimary, ButtonSecondary, ButtonDanger } from '../../UI/Buttons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from '@reach/router';
import { TextField } from 'mui-rff';
import moment from 'moment';
import FilesUpload from '../../UI/FileUpload/UploadFile';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { navigate } from '@reach/router'
import {downloadRequestAttachment} from '../../../apis/requests/info/downloadRequestAttachment';
class InvoicesEditForm extends Component {
	state = { data: {}, changeNotes: '' };

	getInvoiceChangeInfo = async () => {
		const { Invoice_Id } =
			this.props.location.state.invoiceChangeData;
		console.log(this.props.location.state.invoiceChangeData);
		console.log(this.props.location)

		const at = this.props.currentUser.Data.access_token,
			body = {
				Invoice_Id: Invoice_Id,
				Invoice_Change_Id: this.props.location.state.invoiceChangeData?.Invoice_Change_Id ?  this.props.location.state.invoiceChangeData.Invoice_Change_Id : 0,
			},
			response = await apiConfig
				.post(
					'API/ADMINISTRATION/PAYMENTS/INVOICES/GET_INVOICE_CHANGES_INFO',
					body,
					{
						headers: {
							Authorization: `Bearer ${at}`,
						},
					}
				)
				.then((res) => {
					this.setState({
						data: res.data.Data,
						filesData: res.data.Data.Invoice_Changes_Att_Data.map(
							(item) => {
								item.Can_Delete = !res.data.Data.Invoice_Changes_Info_Data
									.Invoice_Change_Status_Id;
								return item;
							}
						),
						changeNotes:
							res.data.Data.Invoice_Changes_Info_Data
								.Invoice_Change_Notes,
					});
				})
				.catch((err) => {
					console.log(err);
				});
	};

	deleteAttachment = async (file) => {
		let at = this.props.currentUser.Data.access_token,
			url =
				'/API/ADMINISTRATION/PAYMENTS/INVOICES/DELETE_INVOICE_CHANGES_ATTACHMENT';

		const response = await apiConfig
			.post(
				url,
				{
					Invoice_Id:
						this.state.data.Invoice_Changes_Info_Data.Invoice_Id,
					Invoice_Change_Id:
						this.state.data.Invoice_Changes_Info_Data
							.Invoice_Change_Id,
					File_Code: file.File_Code,
				},
				{
					headers: {
						Authorization: `Bearer ${at}`,
					},
				}
			)
			.catch((error) => {
				return error;
			});

		this.props.triggerNotification(true, response);
		return response;
	};

	componentDidMount() {
		this.getInvoiceChangeInfo();
	}

	deleteDraft = async () => {
		this.props.setLoading(true, 'dark');

		let url =
			'/API/ADMINISTRATION/PAYMENTS/INVOICES/DELETE_INVOICE_CHANGES_DRAFT';

		const response = await apiConfig
			.post(
				url,
				{
					Invoice_Id:
						this.state.data?.Invoice_Changes_Info_Data?.Invoice_Id,
					Invoice_Change_Id:
						this.state.data?.Invoice_Changes_Info_Data
							?.Invoice_Change_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
					},
				}
			)
			.then((res) => {
				navigate('/invoices-list')
				this.props.setLoading(false);
				return res;
			})
			.catch((error) => {
				this.props.setLoading(false);
				return error;
			});
		this.props.triggerNotification(true, response.data);
	};

	approveChanges = async () => {
		this.props.setLoading(true, 'dark');

		let url =
			'/API/ADMINISTRATION/PAYMENTS/INVOICES/APPROVING_INVOICE_CHANGES_DATA';

		const response = await apiConfig
			.post(
				url,
				{
					Invoice_Id:
						this.state.data?.Invoice_Changes_Info_Data?.Invoice_Id,
					Invoice_Change_Id:
						this.state.data?.Invoice_Changes_Info_Data
							?.Invoice_Change_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
					},
				}
			)
			.then((res) => {
				if(res.data.Status) {
					navigate('/invoices-list')
				}
				this.props.setLoading(false);
				return res;
			})
			.catch((error) => {
				this.props.setLoading(false);
				return error;
			});
		this.props.triggerNotification(true, response.data);
		
	};

	handleNoteChange = async (e) => {
		let url =
			'/API/ADMINISTRATION/PAYMENTS/INVOICES/SAVE_INVOICE_CHANGES_NOTES';

		const response = await apiConfig
			.post(
				url,
				{
					Invoice_Id:
						this.state.data?.Invoice_Changes_Info_Data?.Invoice_Id,
					Invoice_Change_Id:
						this.state.data?.Invoice_Changes_Info_Data
							?.Invoice_Change_Id,
					Invoice_Change_Notes: this.state.changeNotes,
				},
				{
					headers: {
						Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
					},
				}
			)
			.then((res) => res)
			.catch((error) => error);

		this.props.triggerNotification(true, response.data);
	};

	debounce = (callBack, delay) => {
		let timer;

		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				callBack(...args);
			}, delay);
		};
	};
	deboncedText = this.debounce(this.handleNoteChange, 2000);
	

	handleChangeValueType = async (e) => {
		let url =
			'/API/ADMINISTRATION/PAYMENTS/INVOICES/SAVE_INVOICE_CHANGES_VALUE';

		const response = await apiConfig
			.post(
				url,
				{
					Invoice_Id:
						this.state.data?.Invoice_Changes_Info_Data?.Invoice_Id,
					Invoice_Change_Id:
						this.state.data?.Invoice_Changes_Info_Data
							?.Invoice_Change_Id,
					Invoice_Change_Value_Type_Id: e.target.value,
					Invoice_Change_Value: this.state.data.Invoice_Changes_Info_Data.Invoice_Change_Value,
				},
				{
					headers: {
						Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
					},
				}
			)
			.then((res) => {
				this.setState({
					data: res.data.Data,
				});

				return res;
			})
			.catch((error) => error);

		this.props.triggerNotification(true, response.data);
	};
	handleValueChange = async (e) => {
		
		let url =
			'/API/ADMINISTRATION/PAYMENTS/INVOICES/SAVE_INVOICE_CHANGES_VALUE';

		const response = await apiConfig
			.post(
				url,
				{
					Invoice_Id:
						this.state.data?.Invoice_Changes_Info_Data?.Invoice_Id,
					Invoice_Change_Id:
						this.state.data?.Invoice_Changes_Info_Data
							?.Invoice_Change_Id,
					Invoice_Change_Value_Type_Id: this.state.data?.Invoice_Changes_Info_Data.Invoice_Change_Value_Type_Id,
					Invoice_Change_Value:  e.target.value,
				},
				{
					headers: {
						Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
					},
				}
			)
			.then((res) => {
				this.setState({
					data: res.data.Data,
				});

				return res;
			})
			.catch((error) => error);

		this.props.triggerNotification(true, response.data);
	};
	deboncedChangeValue = this.debounce(this.handleValueChange, 2000);

	onSubmit = async (values) => {};

	render() {
		return (
			<>
				<div className="d-flex justify-content-between align-items-center mb-4">
					<h3>{this.props.t('editInvoice')}</h3>
					<ButtonSecondary
						variant="outlined"
						endIcon={<ExitToAppIcon />}
						component={Link}
						to={`/invoices-list`}
						size="large"
					>
						{this.props.t('exitToInvoiceList')}
					</ButtonSecondary>
				</div>
				<Form
					onSubmit={this.onSubmit}
					initialValues={this.state.data.Invoice_Changes_Info_Data}
					render={({
						handleSubmit,
						form,
						submitting,
						pristine,
						values,
					}) => (
						<form
							onSubmit={handleSubmit}
							noValidate
							className="mt-4 mx-3 departmentFormClass"
						>
							<Row>
								<Col md={3}>
									<TextField
										className={'read-only mb-2'}
										name="Invoice_Id"
										disabled
										label={this.props.t('invoiceNo')}
									/>
								</Col>
								<Col md={3}>
									<TextField
										className={'read-only mb-2'}
										name="Invoice_Date"
										disabled
										label={this.props.t('invoiceDate')}
										value={moment(
											this.state.data?.Invoice_Date
										).format('HH:mm YYYY-MM-DD')}
									/>
								</Col>
								<Col md={3}>
									<TextField
										className={'read-only mb-2'}
										name="Request_No"
										disabled
										label={this.props.t('requestServiceNo')}
									/>
								</Col>
								<Col md={3}>
									<TextField
										className={'read-only mb-2'}
										name={
											this.props.i18n.language === 'ar'
												? 'Client_Name_AR'
												: 'Client_Name_EN'
										}
										disabled
										label={this.props.t('clientName')}
									/>
								</Col>
								<Col md={4}>
									<TextField
										className={'read-only mb-2'}
										name="Invoice_Value"
										disabled
										label={this.props.t('invoiceValue')}
									/>
								</Col>
								<Col md={4}>
									<FormControl>
										<RadioGroup
											row
											name="Invoice_Change_Value_Type_Id"
											value={Number(
												this.state.data
													?.Invoice_Changes_Info_Data
													?.Invoice_Change_Value_Type_Id
											)}
											disabled={this.state.data?.Invoice_Changes_Info_Data
												?.Invoice_Change_Status_Id === 1 ? true : false}
											onChange={(e) =>
												this.handleChangeValueType(e)
											}
										>
											<label className="text-green w-100">
												{this.props.t('discountType')}
											</label>
											<FormControlLabel
												value={1}
												control={<Radio />}
												label={this.props.t('value')}
												disabled={this.state.data?.Invoice_Changes_Info_Data
													?.Invoice_Change_Status_Id === 1 ? true : false}
											/>
											<FormControlLabel
												value={2}
												control={<Radio />}
												label={this.props.t(
													'perentage'
												)}
												disabled={this.state.data?.Invoice_Changes_Info_Data
													?.Invoice_Change_Status_Id === 1 ? true : false}
											/>
										</RadioGroup>
									</FormControl>
								</Col>
								<Col md={4}>
									<TextField
										className={'read-only mb-2'}
										name="Invoice_Change_Value"
										onChange={(e) => {
											this.setState({
												data: {
													...this.state.data,
													Invoice_Changes_Info_Data: {
														...this.state.data.Invoice_Changes_Info_Data,
														Invoice_Change_Value: e.target.value
													}
												},
											});

											this.deboncedChangeValue(e)}
										}
										label={this.props.t('discountValue')}
										disabled={this.state.data?.Invoice_Changes_Info_Data
											?.Invoice_Change_Status_Id === 1 ? true : false}
									/>
								</Col>
								<Col md={4}>
									<TextField
										className={'read-only mb-2'}
										disabled
										name="Invoice_Discount_Value"
										label={this.props.t(
											'discountValueafterChange'
										)}
									/>
								</Col>
								<Col md={4}>
									<TextField
										className={'read-only mb-2'}
										disabled
										name="Invoice_Value_After_Change"
										label={this.props.t(
											'invoiceVAlueafterChange'
										)}
									/>
								</Col>
								<Col xs={6} md={12}>
									<TextField
										className={'read-only mb-2'}
										name={'Invoice_Change_Notes'}
										multiline
										rows={4}
										label={this.props.t(
											'invoiceChangeNotes'
										)}
										disabled={this.state.data?.Invoice_Changes_Info_Data
											?.Invoice_Change_Status_Id === 1 ? true : false}
										value={this.state.changeNotes}
										onChange={(e) => {
											this.setState({
												changeNotes: e.target.value,
											});
											this.deboncedText(e.target.value);
										}}
									/>
								</Col>
								<Col xs={6} md={6}>
									<h4 className="text-start mb-3 text-green w-100 d-block">
										{this.props.t(
											'discountReasonAttachments'
										)}
									</h4>
									<FilesUpload
										at={
											this.props.currentUser.Data
												.access_token
										}
										endPoint="API/ADMINISTRATION/PAYMENTS/INVOICES/SAVE_INVOICE_CHANGES_ATTACHMENT"
										params={{
											Invoice_Id:
												this.state.data
													.Invoice_Changes_Info_Data
													?.Invoice_Id,
											Invoice_Change_Id:
												this.state.data
													.Invoice_Changes_Info_Data
													?.Invoice_Change_Id,
										}}
										deleteCB={this.deleteAttachment}
										downloadCB={downloadRequestAttachment}
										fileApiKey="Attached_File"
										filesData={this.state.filesData}
										view={this.state.data?.Invoice_Changes_Info_Data
											?.Invoice_Change_Status_Id === 1 ? true : false}
									/>
								</Col>
								<Col
									sm={12}
									className="d-flex justify-content-end"
								>
									{this.state.data?.Invoice_Changes_Info_Data
										?.Invoice_Change_Status_Id !== 1 && (
											<ButtonPrimary
											variant="contained"
											onClick={this.approveChanges}
										>
											{this.props.t('approveDiscount')}
										</ButtonPrimary>
									)}
									
									{this.state.data?.Invoice_Changes_Info_Data
										?.Invoice_Change_Status_Id === 0 && (
										<ButtonDanger
											variant="contained"
											onClick={this.deleteDraft}
										>
											{this.props.t('deleteDraft')}
										</ButtonDanger>
									)}
									<ButtonSecondary 
										variant="contained"
										component={Link}
										to={`/invoices-list`}>
										{this.props.t('close')}
									</ButtonSecondary>
								</Col>
							</Row>
						</form>
					)}
				/>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { triggerNotification, setLoading })(
	translationHook(InvoicesEditForm)
);
