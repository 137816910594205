import React, { Component } from 'react'
import { connect } from 'react-redux'
import DateBox from 'devextreme-react/date-box';
import apiConfig from '../../../../apis/apiConfig'
import { translationHook } from '../../../translationHook'
import { Row, Col } from 'react-bootstrap'
import { Form, Field } from 'react-final-form'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../../actions'


class TimeForm extends Component {

    onTimeSubmit = async (values, form) => {
        const at = this.props.currentUser.Data.access_token,
        url = '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/SAVE_REQUEST_TASK_INSPECTION_TIME',
        body = {
            Inspection_Time: values.inspectionTime,
            Request_Id: this.props.requestId,
            Request_Task_Id: this.props.requestTaskId,
            Temp_Action_Id: this.props.tempId
        }

        this.props.setLoading(true, 'dark')

        const response = await apiConfig.post(
            url,
            body,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)


    }


    render() {
        

        const validate = values => {
            const errors = {}
            
            if (!values.inspectionTime) {
                errors.inspectionTime = `${this.props.t('error.field_required')}`
            }

            return errors
        }
     

        return (
            <>
                <Form
                onSubmit={this.onTimeSubmit}
                initialValues={{inspectionTime: null}}
                validate={validate}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form
                        id="inspectionTimeFrom"
                        className="row"
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <Col xs={12} md={8}>
                            <Row>

                                <Col xs={12} md={6}>
                                    <Field
                                    name="inspectionTime" 
                                    subscription={{error:true, initial: true}}
                                    >
                                        {
                                            fieldProps => (
                                                <DateBox
                                                acceptCustomValue={false}
                                                defaultValue={null}
                                                dateSerializationFormat="yyyy-MM-dd HH:mm"
                                                displayFormat="yyyy-MM-dd hh:mm a"
                                                onValueChanged={(e) => {  fieldProps.input.onChange(e.value); form.submit(); }}
                                                min={new Date()}
                                                type="datetime"
                                                isValid={() => {
                                                    return !fieldProps.meta?.invalid && !fieldProps.meta?.submitFailed
                                                }}
                                                />
                                            )
                                        }
                                    </Field>
                                    
                                </Col>

                            </Row>
                        </Col>

                    </form>
                )}
                />
                
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(TimeForm))
