import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie';
import {I18nextProvider} from 'react-i18next'
import i18next from 'i18next'
import common_ar from './translations/ar/common.json'
import common_en from './translations/en/common.json'
import auth_ar from './translations/ar/auth.json'
import auth_en from './translations/en/auth.json'

import store from './store'
import App from './components/App'
import './style.scss'


i18next.init({
    interpolation: { escapeValue: false },  
    lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : 'ar',
    // lng: 'en',  default                          
    resources: {
      en: {
        auth: auth_en,
        common: common_en               
      },
      ar: {
        auth: auth_ar,
        common: common_ar
      },
    }
  })

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>
    </CookiesProvider>,
    document.getElementById('root')
)