import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Link } from "@reach/router";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { ButtonSecondary } from "../../UI/Buttons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import style from "../../services/Service.module.scss";
import ModuleInfo from "./ModuleInfo";
import ModuleDetail from "./ModuleDetail";
import ServiceTemplatesList from "./ServiceTemplatesList";
import { translationHook } from "../../translationHook";
import { getTemplateInfo } from "../../../apis/template/templates";

class AddModule extends Component {
  state = {
    key: 0,
    next: 1,
    prev: -1,
    moduleId: null,
    first: false,
    second: true,
    third: true,
    edit: false,
    view: false,
    serviceInfoInit: {
      moduleStatus: true,
      showInvoices: true,
      skipWhenError: true,
    },
  };

  handleNext = () => {
    this.setState({
      key: this.state.next,
      next: this.state.next + 1,
      prev: this.state.prev + 1,
    });
  };

  handlePrev = () => {
    this.setState({
      key: this.state.prev,
      next: this.state.next - 1,
      prev: this.state.prev - 1,
    });
  };

  handleFirstStepDone = (sID, values) => {
    this.setState({
      moduleId: sID,
      second: false,
      third: false,
      edit: true,
      serviceInfoInit: values,
    });
    this.handleNext();
  };

  renderChevs = () => {
    let rtl = this.props.i18n.language === "ar" ? true : false,
      iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
      iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

    return (
      <>
        <IconButton
          aria-label="prev"
          color="primary"
          onClick={this.handlePrev}
          disabled={this.state.key > 0 ? false : true}
        >
          {iconPrev}
        </IconButton>
        <IconButton
          aria-label="next"
          color="primary"
          onClick={this.handleNext}
          disabled={this.state.key < 1 && !this.state.second ? false : true}
        >
          {iconNext}
        </IconButton>
      </>
    );
  };

  componentDidMount() {
    // if edit service and there is dropdownId prop in the url
    if (this.props.moduleId) {
      (async () => {
        let at = this.props.currentUser.Data.access_token,
          moduleId = this.props.moduleId,
          values = {};

        const response = await getTemplateInfo(at, moduleId);

        if (response) {
          values.moduleId = response.Template_Id;
          values.ModuleNameAR = response.Template_Name_AR;
          values.ModuleNameEN = response.Template_Name_EN;
          values.moduleStatus = response.Active_Status_Id == 1 ? true : false;
          values.showInvoices =
            response.Template_has_Invoices == 1 ? true : false;
          values.templateApisId = response.Template_API_Id;
          values.skipWhenError = response.Template_Skip_API_Errors;
          this.setState({
            moduleId: this.props.moduleId,
            second: false,
            third: false,
            edit: this.props.location.hash === "#view" ? false : true,
            view: this.props.location.hash === "#view" ? true : false,
            serviceInfoInit: values,
          });
        }
      })();
    }
  }

  render() {
    let mystyle;
    if (this.props.i18n.language === "ar") {
      mystyle = "ServiceTabsAr";
    } else {
      mystyle = "ServiceTabsEn";
    }

    return (
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={0}
        activeKey={this.state.key}
        onSelect={(k) =>
          this.setState({
            key: parseInt(k),
            next: parseInt(k) + 1,
            prev: parseInt(k) - 1,
          })
        }
        unmountOnExit="true"
      >
        <Row>
          <Col
            sm={12}
            className="d-flex justify-content-between align-items-center"
          >
            <div>{this.renderChevs()}</div>
            <Nav variant="pills" className={style.stepsNav}>
              <Nav.Item className={mystyle}>
                <Nav.Link
                  className={`${style.navLink} ${
                    this.state.key === 0 ? style.active : ""
                  }`}
                  eventKey="0"
                  disabled={this.state.first}
                >
                  {this.props.t("moduleInfo")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={mystyle}>
                <Nav.Link
                  className={`${style.navLink} ${
                    this.state.key === 1 ? style.active : ""
                  }`}
                  eventKey="1"
                  disabled={this.state.second}
                >
                  {this.props.t("moduleValues")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={mystyle}>
                <Nav.Link
                  className={`${style.navLink} ${
                    this.state.key === 2 ? style.active : ""
                  }`}
                  eventKey="2"
                  disabled={this.state.third}
                >
                  {this.props.t("templateFiles")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <ButtonSecondary
              variant="outlined"
              endIcon={<ExitToAppIcon />}
              component={Link}
              to="/manage-modules"
              size="large"
            >
              {this.props.t("exitModules")}
            </ButtonSecondary>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey={0} disabled={this.state.first}>
                <ModuleInfo
                  moduleId={this.state.moduleId}
                  active={!this.state.first}
                  initValues={this.state.serviceInfoInit}
                  viewMood={this.state.view}
                  onStepDone={this.handleFirstStepDone}
                  editMood={this.state.edit}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey={1}
                disabled={this.state.second}
                unmountOnExit="true"
              >
                <ModuleDetail
                  moduleId={this.state.moduleId}
                  active={this.state.second}
                  editMood={this.state.edit}
                  viewMood={this.state.view}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey={2}
                disabled={this.state.third}
                unmountOnExit="true"
              >
                <ServiceTemplatesList
                  moduleId={this.state.moduleId}
                  active={this.state.third}
                  editMood={this.state.edit}
                  viewMood={this.state.view}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(translationHook(AddModule));
