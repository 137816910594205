import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import ListAltIcon from "@material-ui/icons/ListAlt";

import DashboardCard from "../../UI/dashboardCard/index";

import NewReq from "../../../images/dashboard-icons/shared/newRequests.svg";
import Approval from "../../../images/dashboard-icons/GM/approval.svg";
import Mercy from "../../../images/dashboard-icons/shared/mercy.png";
import Transferred from "../../../images/dashboard-icons/GM/transferred.png";
import Munc from "../../../images/dashboard-icons/GM/munc.svg";

import OrdersList from "./OrdersList";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { GMdashboardStatistics } from "../../../apis/dashboards/GMDashboard";

const GMDashboard = (props) => {
  const { t, i18n } = useTranslation("common");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [cards, setCards] = useState([]);
  const [cardElements, setCardElements] = useState([]);
  const [tabsNumbers, settabsNumbers] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [activeCard, setActiveCard] = useState("");
  const [reloadGrid, triggerReloadGrid] = useState(false);
  const [intervalCB, setIntervalCB] = useState();
  const at = props.currentUser.Data.access_token;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    (async () => {
      await GMdashboardStatistics(at).then((data) => {
        settabsNumbers(...data);
        setActiveTab("Approval_Requests_Count");
      });
    })();
  }, []);

  const intervalCBF = async () => {
    await GMdashboardStatistics(at)
      .then((data) => {
        settabsNumbers(...data);
      })
      .catch((error) => {
        if (
          error.response.status === 401 ||
          error.response.statusText === "Unauthorized"
        ) {
          clearInterval(intervalCB);
        }
      });
  };

  useEffect(() => {
    clearInterval(intervalCB);
    let statInterval = setInterval(
      intervalCBF,
      props.currentUser.Data.Refresh_Time
        ? props.currentUser.Data.Refresh_Time
        : 30000
    );
    setIntervalCB(statInterval);
  }, [props.currentUser]);

  useEffect(() => {
    return () => {
      clearInterval(intervalCB);
    };
  }, [intervalCB]);

  useEffect(() => {
    setCards(() => {
      let eles = [];

      for (const key in tabsNumbers) {
        if (Object.hasOwnProperty.call(tabsNumbers, key)) {
          const count = tabsNumbers[key],
            cardData = {},
            icon =
              key === "New_Requests_Count"
                ? NewReq
                : key === "Approval_Requests_Count"
                ? Approval
                : key === "Assigned_Requests_Count"
                ? Transferred
                : key === "Mercy_Requests_Count"
                ? Mercy
                : key === "Department_Requests_Count"
                ? Munc
                : "";

          cardData["key"] = key;
          cardData["card"] = (
            <DashboardCard
              title={t(key)}
              key={key}
              className="globalBox"
              count={count}
              icon={<img src={icon} className="img-fluid" alt={t(key)} />}
              disabled={count === 0 ? true : false}
              onClick={() => {
                setActiveTab(key);
              }}
              active={activeTab === key ? true : false}
              reload={() => {
                triggerReloadGrid(true);
              }}
            />
          );

          eles.push(cardData);
        }
      }

      return eles;
    });
  }, [tabsNumbers]);

  useEffect(() => {
    if (cards && cards.length > 0) {
      const active = cards.filter((cardData) => {
        return cardData.key === activeTab;
      });

      const remainingCards = cards.filter((cardData) => {
        return (
          cardData.key !== "Assigned_Requests_Count" &&
          cardData.key !== "Mercy_Requests_Count" &&
          cardData.key !== "New_Requests_Count"
        );
      });

      setCardElements(remainingCards);
      setActiveCard(active[0]?.card);
    }
  }, [cards, activeTab]);

  return (
    <>
      <div className="dashboardOverlay"></div>
      <Row className="mb-5 dashboardCardsCon">
        <div className="col-xl-2 offset-xl-1 col-12 d-flex justify-content-center align-items-center mb-xl-3">
          <h3>{t("dashboard")}</h3>
        </div>
        {/* <div className="container">
        <div className="row align-items-center">
                <div className="col-md-1">
                    {t('fromDate')}
                </div>
                    <div className="col-md-3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify='space-around'>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant='inline'
                                    format='MM/dd/yyyy'
                                    margin='normal'
                                    id='start-date'
                                    label={t('fromDate')}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-1">
                        {t('toDate')}
                    </div>
                    <div className="col-md-3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify='space-around'>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant='inline'
                                    format='MM/dd/yyyy'
                                    margin='normal'
                                    id='end-date'
                                    label={t('toDate')}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div> */}
        <Col xl={8} md={9} xs={12} className="m-auto m-xl-0">
          <Row className="dashboardCards">
            <Col lg={6} className="active-card p-2">
              {activeCard}
            </Col>
            <Col lg={6}>
              <Row>
                {cardElements.map((cardData) => {
                  return (
                    <Col lg={6} className="p-2" key={cardData.key}>
                      {cardData.card}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <OrdersList
        className="w-100"
        dataField={activeTab}
        reloadGrid={reloadGrid}
        dataLoadedCB={triggerReloadGrid}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser };
};

export default connect(mapStateToProps)(GMDashboard);
