import React, { useState } from 'react'

import Notes from '../Notes'
import { saveRequestTemplateIssuance, saveRequestApprovingTemplateFile } from '../../../../apis/requests/processTask'

import Template from './Template'

export const TemplateIssuance = (props) => {

    const [showNotes, setShowNotes] = useState(true)

    const handleNotesState = state => {
        setShowNotes(state)
    }

    return (
        <>
            <Template 
                requestId={props.requestId} 
                requestTaskId={props.requestTaskId} 
                tempId={props.tempId} 
                task={props.task} 
                requestNo={props.requestNo}  
                handleNotesState={handleNotesState}
            />

            {showNotes ? 
                <Notes 
                    required={false} 
                    requestId={props.requestId} 
                    requestTaskId={props.requestTaskId} 
                    tempId={props.tempId} 
                    task={props.task}
                    onTaskSubmit={saveRequestTemplateIssuance} 
                />
            : null}
        
        </>
    )
}

export const TemplateApproval = (props) => {
    
    return (
        <>
        <Template requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} task={props.task} />

        <Notes required={false} requestId={props.requestId} requestTaskId={props.requestTaskId} tempId={props.tempId} onTaskSubmit={saveRequestApprovingTemplateFile} />
        
        </>
    )
}

