import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../translationHook';
import apiConfig from '../../apis/apiConfig';
import { ButtonPrimary, ButtonSecondary, ButtonDanger } from '../UI/Buttons';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { TextField, Switches, Select } from 'mui-rff';
import { triggerNotification, setLoading } from '../../actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import style from '../services/Service.module.scss';
import { Link } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getDepUsersList } from '../../apis/tasks/assignTasks';
import TaskAttachments from './TaskAttachments';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { cancelMission } from '../../apis/tasks/assignTasks'
class AssignForm extends Component {
	state = {
		initialValues: {},
		showForm: false,
		usersList: [],
        key: 0,
        next: 1,
        prev: -1,
        missionID: null,
        first: false,
        second: true,
        
	};

	componentDidMount() {
        this.getDepartmentUserList();
        const missionInfo  = this.props.location.state?.missionInfo;
        const disabled = this.props.location.state?.disabled;
		console.log(disabled)
        if(missionInfo) {
            this.setState({
                missionID: missionInfo.Mission_Id,
                second: false,
				searchContent: this.props.i18n.language === 'ar' ? missionInfo.Mission_to_User_Name_AR : missionInfo.Mission_to_User_Name_EN,
				disabledFileds: disabled
            })
        }

    }
    handleNext = () => {
        this.setState({
            key: this.state.next,
            next: this.state.next + 1,
            prev: this.state.prev + 1
        })
    }

    handlePrev = () => {
        this.setState({
            key: this.state.prev,
            next: this.state.next - 1,
            prev: this.state.prev - 1
        })
    }

	onSubmit = async (values) => {
		const auth = this.props.currentUser.Data.access_token,
			url = this.props.location.state?.missionInfo ? 'API/ADMINISTRATION/MISSIONS/UPD_MISSION' 
			: '/API/ADMINISTRATION/MISSIONS/ADD_MISSION';


		this.props.setLoading(true, 'dark');

		if(this.props.location.state?.missionInfo) {
			values.Mission_Id = this.props.location.state?.missionInfo?.Mission_Id
		}

		const response = await apiConfig
			.post(url, values, {
				headers: {
					Authorization: `Bearer ${auth}`,
				},
			})
			.catch((error) => {
				return error.response;
			});

		this.props.setLoading(false);

		this.props.triggerNotification(true, response.data);

        if(response.data.Status && !this.props.location.state?.missionInfo) {
            this.handleFirstStepDone(response.data.Data)
        }

	};
    handleFirstStepDone = (values) => {
        this.setState({
            missionID: values.Mission_Id, key: 1, second: false, third: false, fourth: false, edit: true, 
            initValues: values
        })
    }
	handleUploadClick = (e) => {
		let file = e.target.files[0],
			url = window.URL.createObjectURL(file);
		this.setState({
			file,
			url,
		});
	};
	renderChevs = () => {
		let rtl = this.props.i18n.language === 'ar' ? true : false,
			iconPrev = rtl ? <ChevronRightIcon /> : <ChevronLeftIcon />,
			iconNext = rtl ? <ChevronLeftIcon /> : <ChevronRightIcon />;

		return (
			<>
				<IconButton
					aria-label='prev'
					color='primary'
					onClick={this.handlePrev}
					disabled={this.state.key > 0 ? false : true}>
					{iconPrev}
				</IconButton>
				<IconButton
					aria-label='next'
					color='primary'
					onClick={this.handleNext}
					disabled={this.state.key < 3 && !this.state.second ? false : true}>
					{iconNext}
				</IconButton>
			</>
		);
	};
	getDepartmentUserList = async () => {
        let auth = this.props.currentUser.Data.access_token;

		const response = await getDepUsersList(auth);

		if (response.Status) {
			this.setState({ usersList: response.Data });
		}
	};
	handleValueChange = (e) => {
		this.setState({
			searchContent: e.value,
		});
	}
	cancelTask = async () => {
        const {missionInfo} = this.props.location.state;

        
		const response = await cancelMission(missionInfo?.Mission_Id, this.props.currentUser.Data.access_token)
		this.props.triggerNotification(true, response.data);
		this.props.navigate(`/assigned-tasks`)


	}

	render() {
		let mystyle;
		if (this.props.i18n.language === 'ar') {
			mystyle = 'ServiceTabsAr';
		} else {
			mystyle = 'ServiceTabsEn';
		}
		// console.log(this.props.location.state)
        const missionInfo = this.props.location.state?.missionInfo;
        const disabled = this.props.location?.state?.disabled;

		return (
			<>
				<Tab.Container
					id='left-tabs-example'
					defaultActiveKey={0}
					activeKey={this.state.key}
					onSelect={(k) =>
						this.setState({
							key: parseInt(k),
							next: parseInt(k) + 1,
							prev: parseInt(k) - 1,
						})
					}
					unmountOnExit='true'>
					{' '}
					<Row>
						<Col
							sm={12}
							className='d-flex justify-content-between align-items-center'>
							{' '}
							<div> {this.renderChevs()} </div>{' '}
							<Nav variant='pills' className={style.stepsNav}>
								<Nav.Item className={mystyle}>
									<Nav.Link
										className={`${style.navLink} ${
											this.state.key === 0 ? style.active : ''
										}`}
										eventKey='0'
										disabled={this.state.first}>
										{this.props.t('mainTaskDetails')}
									</Nav.Link>
								</Nav.Item>
								<Nav.Item className={mystyle}>
									<Nav.Link
										className={`${style.navLink} ${
											this.state.key === 1 ? style.active : ''
										}`}
										eventKey='1'
										disabled={this.state.second}>
										{this.props.t('taskAttachments')}
									</Nav.Link>
								</Nav.Item>
							</Nav>
							<ButtonSecondary
								variant='outlined'
								endIcon={<ExitToAppIcon />}
								component={Link}
								to={this.props.location.state?.from ? this.props.location.state.from : '/assigned-tasks'}
								size='large'>
								{this.props.t('exitToTasksList')}
							</ButtonSecondary>
						</Col>
						<Col sm={12}>
							<Tab.Content>
								<Tab.Pane eventKey={0} disabled={this.state.first}>
									<Form
										subscription={{ submitting: true, initialValues: true }}
										onSubmit={this.onSubmit}
										initialValues={missionInfo ? missionInfo : {
                                            Mission_to_User_Id: '',
                                            Mission_Title: '',
                                            Mission_Description: ''
                                        }}
										render={({
											handleSubmit,
											form,
											submitting,
											pristine,
											values,
										}) => {
											return (
												<form
													onSubmit={handleSubmit}
													className='row mt-4 mx-3'
													noValidate>
													<Col xs={12} md={12}>
														<label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required" data-shrink="true">
															{this.props.t('missionToUser')}
															<span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk"> *</span></label>
														<Autocomplete
															value={this.state.searchContent}
															dataSource={this.state.usersList}
															className="mb-3"
															onValueChanged={this.handleValueChange}	
															onSelectionChanged={(e) => {
																form.change("Mission_to_User_Id", e.selectedItem?.User_Id)
															}}												
															valueExpr={this.props.i18n.language === 'ar' ? 'User_Name_AR' : 'User_Name_EN'}
															placeholder={this.props.t('search about empolyee')}
															disabled={this.state.disabledFileds}
															rtlEnabled={
																this.props.i18n.language === 'ar' ? true : false
															}
														/>
													</Col>
													<Col xs={12} md={12}>
                                                        <TextField
														disabled={this.state.disabledFileds}
                                                        label={this.props.t('missionTitle')}
														className={`${this.state.disabledFileds ? 'read-only': 'mb-2'}`}
                                                        name="Mission_Title"
                                                        required={true}
                                                        showError={({ meta: { submitFailed, invalid } }) => {
                                                            return submitFailed && invalid
                                                        }}
                                                    />
													</Col>
													<Col xs={12} md={12}>
                                                        <TextField
														disabled={this.state.disabledFileds}
                                                        label={this.props.t('missionDesc')}
                                                        className={`${this.state.disabledFileds ? 'read-only': 'mb-2'}`}
                                                        name="Mission_Description"
                                                        multiline
                                                        rows={4}
                                                        required={true}
                                                        showError={({ meta: { submitFailed, invalid } }) => {
                                                            return submitFailed && invalid
                                                        }}
                                                    />
													</Col>
													{!disabled && (
													<Col className='mt-4 mb-4' xs={12}>
														<ButtonPrimary
															color='primary'
															variant='contained'
															disabled={submitting}
															type='submit'>
															{this.props.t('save')}
														</ButtonPrimary>
														{missionInfo?.Mission_Type_Id === 1 && (
															<ButtonDanger
															color='primary'
															variant='contained'
															type='Button'
															onClick={this.cancelTask}
															>
															{this.props.t('cancelTask')}
														</ButtonDanger>
														)}
													</Col>
													)}
												</form>
											);
										}}
									/>
								</Tab.Pane>
								<Tab.Pane
									eventKey={1}
									disabled={this.state.second}
									unmountOnExit='true'>
                                    <TaskAttachments missionID={this.state.missionID} 
                                    active={!this.state.second}
									view={this.state.disabledFileds}
									/>

								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToprops, { triggerNotification, setLoading })(
	translationHook(AssignForm)
);