import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import style from "./NotificationsDropdown.module.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Badge from "@material-ui/core/Badge";
import { useTranslation } from "react-i18next";
import {
	checkWebNotifications,
	getUnreadWebNotification,
	getNotificationDetails,
	deleteNotification,
	getAllWebNotification,
	deleteAllNotifications,
	deleteReadedNotifications,
} from "../../../../../apis/system/notification";
import NotificationBadge from "../../../../../images/NotificationIcon";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import { triggerNotification } from "../../../../../actions";

const NotificationsDropdown = (props) => {
	const { t, i18n } = useTranslation("common");
	const [anchorEl, setAnchorEl] = useState(null);
	const [nofCount, setNofCount] = useState({
		New_Notifications: 0,
		Unread_Notifications: 0,
		All_Notifications: 0,
	});
	const [nofDetils, setNofDetils] = useState([]);
	const [noficationDetails, setNoficationDetails] = useState({});
	const [intervalCB, setIntervalCB] = useState();
	const open = Boolean(anchorEl);

	let tranStyle;
	if (i18n.language === "en") {
		tranStyle = {
			textAlign: "row",
		};
	} else {
		tranStyle = {
			flexDirection: "row-reverse",
		};
	}
	const checkNotification = async (auth) => {
		// let auth = props.currentUser.Data.access_token;
		const resp = await checkWebNotifications(auth)
			.then((res) => {
				setNofCount(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const getWebNotifications = async (auth) => {
		// let auth = props.currentUser.Data.access_token;
		const resp = await getAllWebNotification(auth)
			.then((res) => {
				if (JSON.stringify(res) === JSON.stringify(nofDetils)) {
					return false;
				} else {
					setNofDetils(res);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const getUnReadNotif = async () => {
		let auth = props.currentUser.Data.access_token;
		const res = await getUnreadWebNotification(auth)
			.then((res) => {
				const arr = [
					{
						Notification_Web_Id: 880,
						Notification_Title_AR: "اشعار تجربة لمستخدم 21",
						Notification_Title_EN: "Test Notification For User 22",
						Notification_Time: "2021-03-03T17:04:16.91",
						Is_Read: 0,
					},
					{
						Notification_Web_Id: 12,
						Notification_Title_AR: "اشعار تجربة لمستخدم 22",
						Notification_Title_EN: "Test Notification For User 22",
						Notification_Time: "2021-03-03T17:04:16.91",
						Is_Read: 1,
					},
				];
				nofDetils.map((notifi) => {
					res.forEach((item) => {
						if (
							item.Notification_Web_Id !==
							notifi.Notification_Web_Id
						) {
							// setNofDetils
							nofDetils.unshift(item);
						}
					});
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const getNotifDetails = async (id) => {
		let auth = props.currentUser.Data.access_token;
		const resp = await getNotificationDetails(id, auth)
			.then((res) => {
				setNoficationDetails(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const intervalCBF = async () => {
		let auth = props.currentUser.Data.access_token;
		await getWebNotifications(auth);
		await checkNotification(auth);
	};

	useEffect(() => {
		if (intervalCB) {
			clearInterval(intervalCB);
		}

		let notificationInterval = setInterval(
			intervalCBF,
			props.currentUser.Data.Refresh_Time
				? props.currentUser.Data.Refresh_Time
				: 30000
		);
		setIntervalCB(notificationInterval);
	}, [props.currentUser]);

	useEffect(() => {
		return () => {
			clearInterval(intervalCB);
		};
	}, [intervalCB]);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (id) => {
		if (!id) {
			setAnchorEl(null);
		} else {
			getNotifDetails(id);
		}
	};
	const deleteNot = async (id) => {
		await deleteNotification(id, props.currentUser.Data.access_token).then(
			(res) => {
				props.triggerNotification(true, res);
			}
		);
	};
	const deleteAllNotifi = async () => {
		await deleteAllNotifications(props.currentUser.Data.access_token).then(
			(res) => {
				props.triggerNotification(true, res.data);
			}
		);
	};
	const deleteReaded = async (id) => {
		await deleteReadedNotifications(
			props.currentUser.Data.access_token
		).then((res) => {
			props.triggerNotification(true, res.data);
		});
	};

	return (
		<div>
			<button
				className={style.dropdownBtn}
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleMenu}
			>
				{nofCount.New_Notifications &&
				nofCount.New_Notifications !== 0 ? (
					<Badge
						badgeContent={nofCount.New_Notifications}
						className={style.notificationBadge}
					>
						<span
							className={[style.notificationSvg, "svg-lg"].join(
								" "
							)}
						>
							<NotificationBadge />
						</span>
					</Badge>
				) : (
					<div className={style.pluse}>
						<span
							className={[style.notificationSvg, "svg-lg"].join(
								" "
							)}
						>
							<NotificationBadge />
						</span>
						<span className={style.pulseRing}></span>
					</div>
				)}
			</button>

			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				keepMounted
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				onClose={() => handleClose()}
				className={[style.dropdown, "mt-2"].join(" ")}
			>
				<MenuItem
					className={[style.menuItem, style.userNotification].join(
						" "
					)}
					style={tranStyle}
				>
					{t("yourNotifications")}
				</MenuItem>
				{nofDetils.map((oneNotif) => (
					<MenuItem
						key={oneNotif.Notification_Web_Id}
						className={[
							style.menuItem,
							oneNotif.Is_Read === 0
								? style.unReadNotification
								: style.newNotification,
							"flex-column",
							"align-items-end",
						].join(" ")}
						style={tranStyle}
						onClick={() =>
							handleClose(oneNotif.Notification_Web_Id)
						}
					>
						<p>
							{i18n.language === "ar"
								? oneNotif.Notification_Title_AR
								: oneNotif.Notification_Title_EN}
						</p>
						<p className="w-100 d-flex justify-content-between">
							<span>
								{moment(oneNotif.Notification_Time)
									.lang("en")
									.format("YYYY-MM-DD") + " "}
							</span>
							<span>
								{moment(oneNotif.Notification_Time)
									.lang("en")
									.format("hh:mm")}
							</span>
						</p>
						{noficationDetails &&
							oneNotif.Notification_Web_Id ===
								noficationDetails.Notification_Web_Id && (
								<>
									<p>
										{i18n.language === "ar"
											? noficationDetails.Notification_Body_AR
											: noficationDetails.Notification_Body_EN}
									</p>
									<button
										className="btn mr-auto d-flex"
										onClick={() =>
											deleteNot(
												oneNotif.Notification_Web_Id
											)
										}
									>
										<DeleteIcon className="text-danger" />
									</button>
								</>
							)}
					</MenuItem>
				))}
				<MenuItem
					className={[
						style.menuItem,
						style.newNotification,
						"position-absolute",
						"justify-content-between",
						"text-danger",
						"w-100",
					].join(" ")}
					style={tranStyle}
				>
					<button className="btn" onClick={() => deleteReaded()}>
						<DeleteIcon className="text-danger" />
						{t("RemoveReaded")}
					</button>
					<button className="btn" onClick={() => deleteAllNotifi()}>
						<DeleteIcon className="text-danger" />
						{t("RemoveAll")}
					</button>
				</MenuItem>
			</Menu>
		</div>
	);
};
const mapStateToProps = (state) => {
	return { currentUser: state.currentUser };
};

export default connect(mapStateToProps, { triggerNotification })(
	NotificationsDropdown
);
