import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from '@reach/router'
import apiConfig from '../../../apis/apiConfig';
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';

import { ExcelJS , Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from 'devextreme/data/custom_store'
import DeleteIcon from '@material-ui/icons/Delete';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';


import { getChartsList , setHosuingModuleStatus , deleteModule } from '../../../apis/system/housing/index'
import { triggerDialog, setLoading , reload, triggerNotification, triggerDialogForm } from '../../../actions'
import { translationHook } from '../../translationHook'
import { ButtonPrimary , ButtonDanger, ButtonInfo, ButtonWarning } from './../../UI/Buttons'
import Switch from '@material-ui/core/Switch';
import HouseModuleForm from './HouseModuleForm';


class HousingDepartmentList extends PureComponent {
    constructor(props){
		super(props);
		this.onExporting = this.onExporting.bind(this);
        this.state = {
            gridPaused: false
        }
	}
    pauseGrid = (state = true) => {
        console.log('here')
		this.setState({ gridPaused: state })
	}
    handleDelete = (data) => {
        let at = this.props.currentUser.Data.access_token,
        id = data.Approving_Chart_Id,
        dialogTitle = `${this.props.t('confirmDeleteTitle')} ${data.Approving_Chart_Name}`,
        dialogMsg = this.props.t('confirmDeleteMsg')
        this.props.triggerDialog(true, {
            title: dialogTitle,
            message: dialogMsg,
            action: {
                action: async () => {
                    const res = await deleteModule(at , id)
                    if(res.Status) {
                        this.pauseGrid(true)
                    }
                    this.pauseGrid(false)
                    return res
                },
                params: [
                    at,
                    id
                ],
                text: this.props.t('delete')
            }
        })
    }
  

    customizeCellCheckbox = ({data}) => {
        return (
            <Switch
				checked={data.Active_Status_Id}
				name={`chartState_${data.Approving_Chart_Id}`}
				onChange={async () => {

                    let at = this.props.currentUser.Data.access_token,
                    id =  data.Approving_Chart_Id,
                    state = data.Active_Status_Id ? 0 : 1;

					this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

                    const response = await setHosuingModuleStatus(at, state, id)
                    
					this.props.triggerNotification(true, response)
					this.props.setLoading(false)

				}}
			/>
        )
    }
    renderEditForm = (...params) => {
        let viewMood = params[2] === 'view' ? true : false,
		editMood = params[2] === 'edit' ? true : false;
        return <HouseModuleForm editMood={editMood} viewMood={viewMood} moduleID={params[0]} 
        initValues={params[1]} />
    }
    handleEdit = (data, mood) => {
            let moduleId = data.Approving_Chart_Id,
            dialogTitle = `${this.props.viewMood ? this.props.t('viewServiceDetailTitle') : this.props.t('editServiceDetailTitle')} ${data.Approving_Chart_Name}`
    
            this.props.triggerDialogForm(true, {
                title: dialogTitle,
                renderForm: this.renderEditForm,
                params: [
                    moduleId,
                    data,
                    mood
                ],
                dismissAction: mood === 'edit' ? {
                    action: () => {
                        this.pauseGrid()
                        setTimeout(() => {
                            this.pauseGrid(false)
                        }, 1);
                    },
                    params: [
                        false
                    ]
                } : false
            })
    
    }
    render() {
        let columnAlign = 'left',
        at = this.props.currentUser.Data.access_token

        if  (this.props.i18n.language === 'ar') {
            columnAlign = 'right'
        }
        
        const jsonDataSource = new CustomStore({
            key: 'Approving_Chart_Id',
            load: () => {
				if (!this.state.gridPaused) {
                    return getChartsList(at)
				}
				return []
			},
        });
        
        return(
            <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>{this.props.t('manageHousingDepartments')}</h3>
                    <ButtonPrimary
                        component={Link}
                        to="add-module"
                        size="large"
                    >
                        {this.props.t('addHousingModule')}
                    </ButtonPrimary>
            </div>
            <DataGrid
                className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
                id="dataGrid"
                width={'100%'}
                dataSource={jsonDataSource}
                showBorders={false}
                showColumnLines= {false}
                showRowLines= {true}
                allowColumnResizing={true}
                columnResizingMode={'widget'}
                allowColumnReordering={true}
                rowAlternationEnabled={false}
                focusedRowEnabled={true}
                onExporting={this.onExporting}
                columnWidth={'auto'}
                wordWrapEnabled={true}
                rtlEnabled={this.props.i18n.language === 'ar' ? true : false}
				>
                <ColumnChooser enabled={true} allowSearch={true} search={'fff'}
                    title={this.props.t('dxColumnChooserTitle')}
                    emptyPanelText={this.props.t('dxColumnChooserPanelText')}
                />
                <SearchPanel 
                    visible={true}
                    width={240}
                    placeholder={this.props.t('search')}
                />

                <FilterRow visible={true} />

                <HeaderFilter visible={true} />

                <Export enabled={true} allowExportSelectedData={true}/>

                <Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                <GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

                <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'always'}
                />
                <Column dataField="Approving_Chart_Id" caption={this.props.t('ModuleNo')} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Name" caption={this.props.t('moduleName')} alignment={'center'}>
                </Column>
                <Column dataField={'Approving_Chart_Consultant_Name_AR'} caption={this.props.t('consultantNameAR')} alignment={'center'} width={'120px'}>
                </Column>
                <Column dataField={'Approving_Chart_Consultant_Name_EN'} caption={this.props.t('consultantNameEN')} alignment={'center'} width={'120px'}>
                </Column>
                <Column dataField="Approving_Chart_Floors_Description_AR" caption={this.props.t('floorDescAR')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Floors_Description_EN" caption={this.props.t('floorDescEN')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Active_Status_Id" 
                    caption={this.props.t('serviceStatus')} 
                    allowFiltering={false}
                    cellRender={this.customizeCellCheckbox}
                    alignment={'center'}
                ></Column>
                <Column 
                dataField="Approving_Chart_Bedroom_No" caption={this.props.t('bedRoomNumbers')} alignment='center' alignment={'center'} width={'120px'} visible={false}>
                </Column>
                <Column dataField={'Approving_Chart_Floors_No'}  caption={this.props.t('floorNumbers')} alignment={'center'}>
                </Column>
                <Column dataField="Approving_Chart_Area"  caption={this.props.t('Area')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
               
                <Column dataField="Approving_Chart_Width" visible={false}  caption={this.props.t('width')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Length" caption={this.props.t('length')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Height" visible={false}  caption={this.props.t('height')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Special_Advantages_AR" caption={this.props.t('specialAdvAR')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Special_Advantages_EN" caption={this.props.t('specialAdvEN')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_AC_Type_AR" visible={false} caption={this.props.t('acTypeAR')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_AC_Type_EN" visible={false} caption={this.props.t('acTypeEN')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Notes_AR"  visible={false} caption={this.props.t('notesAR')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Notes_EN" visible={false} caption={this.props.t('notesEN')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_File_Url" visible={false} caption={this.props.t('photos')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                <Column dataField="Approving_Chart_Cost" visible={false} caption={this.props.t('price')} allowFiltering={false} alignment={'center'} width={'100px'}>
                </Column>
                
                <Column cssClass="outlinedColumn nowrapColumn"
						type='buttons'
						caption={this.props.t('details')}
						fixed={true}
						fixedPosition={this.props.i18n.language === 'ar' ? 'left' : 'right'}
					>
                    <Button
                        render={(record) => {
                            return (
                                <ButtonWarning
                                variant='circleIcon'
                                startIcon={<EditIcon />}
                                onClick={() => {
                                    this.handleEdit(record.data, 'edit')
                                }}
                                >
                                    {/* {this.props.t('edit')} */}
                                </ButtonWarning>
                            );
                        }}
                    />
                    <Button
                        render={(record) => {
                            return (
                                
                                <ButtonInfo
                                variant="circleIcon"
                                startIcon={<VisibilityIcon />}
                                onClick={() => {
                                    this.handleEdit(record.data , 'view')
                                }}
                                >
                                </ButtonInfo>
                            )
                        }}
                    />
                    <Button
                        render={(record) => {
                            return (
                                <ButtonDanger
                                    variant='circleIcon'
                                    startIcon={<DeleteIcon />}
                                    onClick={() => this.handleDelete(record.data)}>
                                </ButtonDanger>
                            );
                        }}
						/>
                </Column>

                
            </DataGrid>
            </>
        )
    }

	onExporting(e) {
		let sheetTitle = this.props.t('manageServices')
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
            customizeCell: ({gridCell, excelCell}) => {
                // excelCell.value = gridCell.value;
                excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "151713"} };
				excelCell.alignment = { indent: 1, wrapText: true, vertical: 'middle' };
				if(gridCell.rowType === 'group') {
					excelCell.fill = {
						type: 'pattern',
						pattern:'solid',
						fgColor:{argb:'d0b166'}
					  };
					excelCell.alignment = { indent: 2 };
					excelCell.font = { family: 2, name: 'Cairo', size: 10, color: {argb: "ffffff"}, bold: true };
				}
				if(gridCell.rowType === 'header') {
				excelCell.fill = {
					type: 'pattern',
					pattern:'solid',
					fgColor:{argb:'58743a'}
					};
				excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
				excelCell.font = { family: 2, name: 'Cairo', size: 12, color: {argb: "ffffff"}, bold: true };
				}
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${sheetTitle}.xlsx`);
                });
        });
        e.cancel = true;
    }
}


const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser
    }
}

export default connect(mapStateToProps, { triggerDialog,reload, setLoading, triggerNotification, triggerDialogForm })(translationHook(HousingDepartmentList))