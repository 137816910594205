import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translationHook } from '../../translationHook';
import apiConfig from '../../../apis/apiConfig';
import { ButtonPrimary, ButtonSecondary } from './../../UI/Buttons';
import { Row, Col } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { TextField } from 'mui-rff';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';
import {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	reload
} from '../../../actions';
// import FilesUpload from '../../UI/FileUpload/UploadFile'
// import { downloadAttachment } from '../../../apis/system/downloadAttachment';
// import { deleteAttachment } from '../../../apis/system/deleteAttachment';
// import { OnChange } from 'react-final-form-listeners';
// import { Autocomplete } from 'devextreme-react/autocomplete';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import AccountProfilesList from '../AccountProfilesList';
import { updateCardAttachment } from '../../../apis/system/updateCardAttachment';

class CardForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imgUrl: '',
			// file: '',
			// showForm: false,
			// openProfile: false,
			// departments: [],
			// departmentJobs: [],
			// usersGroupList: [],
			// langList: [],
			// connect: true,
			// searchResults: [''],
			// searchContent: '',
			// searchType: '1',
			// initVal: {
			// 	User_Name_AR: '',
			// 	User_Name_EN: '',
			// 	User_Phone: '',
			// 	User_Mail: '',
			// 	Login_Name: '',
			// 	User_Group_Id: '',
			// 	Lang_Id: '',
			// 	Department_Id: '',
			// 	Department_Job_Id: '',
			// 	lang: '',
			// 	userStatus: true,
			// 	User_Account_Id:  ''
			// },
			// activeDir: null,
			// filesData: []
		};
		// this.handleValueChange = this.handleValueChange.bind(this);
		// this.handleSelectType = this.handleSelectType.bind(this);
		// this.resetPassword = this.resetPassword.bind(this);
	}

	// getDepartments = async () => {
	// 	let departmentArr = [];
	// 	const response = await apiConfig.post('/API/SYSTEM/DDL/GET_DEPARTMENTS');
	// 	if (response.data.Status) {
	// 		response.data.Data.map((item) => {
	// 			let label =
	// 				this.props.i18n.language === 'ar'
	// 					? item.Record_Name_AR
	// 					: item.Record_Name_EN;
	// 			departmentArr.push({ value: item.Record_Id, label });
	// 		});
	// 		departmentArr.unshift({ value: '', label: this.props.t('withoutDep') });
            
            
	// 		this.setState({ departments: departmentArr });
	// 	}
	// };

	// getDepartmentJobs = async (departmentId) => {
	// 	let departmentJobsArr = [],
	// 		at = this.props.currentUser.Data.access_token;
	// 	const response = await apiConfig.post(
	// 		'/API/SYSTEM/DDL/GET_ALL_DEPARTMENT_JOBS',
	// 		{
	// 			Department_Id: departmentId,
	// 		},
	// 		{
	// 			headers: {
	// 				Authorization: `Bearer ${at}`,
	// 			},
	// 		}
	// 	);

	// 	if (response.data.Status) {
	// 		response.data.Data.map((item) => {
	// 			let label =
	// 				this.props.i18n.language === 'ar'
	// 					? item.Record_Name_AR
	// 					: item.Record_Name_EN;
	// 			departmentJobsArr.push({ value: item.Record_Id, label });
	// 		});
	// 		departmentJobsArr.unshift({ value: 0, label: this.props.t('withoutJob') });

	// 		this.setState({ departmentJobs: departmentJobsArr });
	// 	}
	// };

	// getUsersGroupList = async () => {
	// 	let usersGroupListArr = [],
	// 		at = this.props.currentUser.Data.access_token;
	// 	const response = await apiConfig.post(
	// 		'/API/SYSTEM/USERS/GROUPS/GET_USERS_GROUPS_LIST',
	// 		{},
	// 		{
	// 			headers: {
	// 				Authorization: `Bearer ${at}`,
	// 			},
	// 		}
	// 	);

	// 	if (response.data.Status) {
	// 		response.data.Data.map((item) => {
	// 			let label =
	// 				this.props.i18n.language === 'ar'
	// 					? item.User_Group_Name_AR
	// 					: item.User_Group_Name_EN;
	// 			usersGroupListArr.push({ value: item.User_Group_Id, label });
	// 		});
	// 		usersGroupListArr.unshift({ value: '', label: this.props.t('delete') });

	// 		this.setState({ usersGroupList: usersGroupListArr });
	// 	}
	// };

	// getLang = async () => {
	// 	let getLangUsersArr = [],
	// 		at = this.props.currentUser.Data.access_token;
	// 	const response = await apiConfig.post(
	// 		'/API/SYSTEM//DDL/GET_LANGUAGUES',
	// 		{},
	// 		{
	// 			headers: {
	// 				Authorization: `Bearer ${at}`,
	// 			},
	// 		}
	// 	);

	// 	if (response.data.Status) {
	// 		response.data.Data.map((item) => {
	// 			let label =
	// 				this.props.i18n.language === 'ar'
	// 					? item.Record_Name_AR
	// 					: item.Record_Name_EN;
	// 			getLangUsersArr.push({ value: item.Record_Id, label });
	// 		});
	// 		getLangUsersArr.unshift({ value: '', label: this.props.t('delete') });

	// 		this.setState({ langList: getLangUsersArr });
	// 	}
	// };

	onCardSubmit = async (values, form) => {
		let	at = this.props.currentUser.Data.access_token,
		url = '/API/BASIC/SETTINGS/DASHBOARD_CARDS/UPD_DASHBOARD_CARD_DATA',
		bodyData = {
				Dashboard_Card_Id: this.props.cardId,
				Dashboard_Card_Name_AR: values.cardNameAR,
				Dashboard_Card_Name_EN: values.cardNameEN,
				Dashboard_Card_Description_AR: values.cardDescAR,
				Dashboard_Card_Description_EN: values.cardDescEN,
				Dashboard_Card_Active_Status_Id: values.cardStatus
		};

		this.props.setLoading(true, 'dark');


		const response = await apiConfig.post(url, bodyData, {
			headers: {
				Authorization: `Bearer ${at}`,
			},
		}).catch(err => {
			this.props.triggerNotification(true, err);
			this.props.setLoading(false);
		});

		this.props.triggerNotification(true, response.data);
		this.props.setLoading(false);
		this.props.triggerDialogForm(false);

		if (response.data.Status) {
			form.restart();
			this.props.triggerView(false, false);

		}
	};

    // addProfile = async (values, form) => {
    //     const at = this.props.currentUser.Data.access_token,
    //     url = '/API/SYSTEM/USERS/ADD_USER_PROFILE_DATA',
    //     bodyData = {
    //         User_Account_Id: this.props.initValues.userAccountId,
    //         Department_Id: values.department,
    //         Department_Job_Id: values.job,
    //         User_Group_Id: values.group,
	// 		User_Signature_Text: values.jobSignature
    //     }
     
    //     this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)


    //     const response = await apiConfig.post(
    //         url,
    //         bodyData,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${at}`
    //             },
    //         }
    //     ).then(res => {
    //         this.props.triggerNotification(true, res.data)
    //         if (res.data.Status && !this.props.editMood) {
    //             form.restart()
    //         }

    //     }).catch(err => {
    //         this.props.setLoading(false)

    //     })


    //     this.props.setLoading(false)

    //     // this.props.triggerDialogForm(false)

        

    // }

	handleUploadClick = async (files) => {
		let file = files[0]

		if (file) {
			let at = this.props.currentUser.Data.access_token;

			this.props.setLoading(true, "dark");

			const response = await updateCardAttachment(
				at,
				this.props.cardId,
				file
			);

			this.props.triggerNotification(true, response.data);

			this.props.setLoading(false);

			if (response.data.Status) {
				this.setState({ imgUrl: response.data.Data })
				this.props.reload();
			}
		}
	};

	// handleValueChange = async (e) => {
	// 	this.setState({
	// 		searchContent: e.value,
	// 	});
	// 	if (!e.value) return false;
	// 	const response = await apiConfig
	// 		.post(
	// 			'/API/SYSTEM/USERS/GET_AD_USERS_LIST',
	// 			{
	// 				AD_Search_Value: this.state.searchContent,
	// 				AD_Search_Type: this.state.searchType,
	// 			},
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
	// 				},
	// 			}
	// 		)
	// 		.catch((error) => {
	// 			return error.response;
	// 		});

	// 	if (response.data.Status) {
	// 		this.setState({
	// 			searchResults: response.data?.Data,
	// 		});
	// 	}
	// };

	// selectedValue = (e) => {
	// 	if (e.selectedItem === null) {
	// 		this.setState({
	// 			activeDir: null,
	// 		});
	// 	} else {
	// 		this.setState({
	// 			activeDir: e.selectedItem,
	// 			initVal: {
	// 				loginName: e.selectedItem?.LoginName,
	// 				mail: e.selectedItem?.Email,
	// 				arUserName: e.selectedItem?.FullName,
	// 				enUserName: e.selectedItem?.FullName,
	// 				userStatus: true,
	// 				group: this.props.initValues ? this.props.initValues.group : '',
	// 				department: this.props.initValues
	// 					? this.props.initValues.department
	// 					: '',
	// 				job: this.props.initValues ? this.props.initValues.job : '',
	// 				lang: this.props.initValues ? this.props.initValues.lang : '',
	// 				jobSignature: this.props.initValues ? this.props.initValues.jobSignature : '',
	// 			},
	// 		});
	// 	}
	// };

	// getUserDetails = async () => {
	// 	if (this.props.userId) {
	// 		let at = this.props.currentUser.Data.access_token,
	// 		url = '/API/SYSTEM/USERS/GET_USER_DETAILS'
	// 		this.props.setLoading(true, 'dark')
	// 		const response = await apiConfig.post(url,
	// 			{
	// 				User_Id: this.props.userId
	// 			},
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${at}`
	// 				}
	// 			}
	// 		)

	// 		if (response.data.Status) {
	// 			this.setState({ filesData: [response.data.Data.User_Signature_File] })
	// 		}
	// 		this.props.setLoading(false)
	// 	}
	// }

	// deleteAttachment = async ({ File_Code }) => {
	// 	let at = this.props.currentUser.Data.access_token,
	// 	url = '/API/SYSTEM/USERS/DEL_USER_SIGNATURE_IMAGE'

	// 	const response = await apiConfig.post(url,
	// 		{
	// 			User_Account_Id: this.props.initValues.userAccountId,
	// 			User_Signature_File_Code: File_Code
	// 		},
	// 		{
	// 			headers: {
	// 				Authorization: `Bearer ${at}`
	// 			}
	// 		}
	// 	)

	// 	if (response.data.Status) {
	// 		this.getUserDetails()
	// 	}

	// 	return response
	// }

	// handleSelectType = (e) => {
	// 	this.setState({
	// 		searchType: e.target.value,
	// 	});
	// 	let searchText = this.state.searchContent;
	// 	this.handleValueChange({ value: searchText });
	// };

	// resetPassword = async () => {
	// 	const at = this.props.currentUser.Data.access_token,
	// 		url = '/API/SYSTEM/USERS/RESET_USER_PASSWORD',
	// 		bodyData = {
	// 			User_Account_Id: this.props.initValues.userAccountId,
	// 		};

	// 	this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900);

	// 	const response = await apiConfig
	// 		.post(url, bodyData, {
	// 			headers: {
	// 				Authorization: `Bearer ${at}`,
	// 			},
	// 		})
	// 		.then((res) => {
	// 			this.props.triggerNotification(true, res.data);
	// 			this.props.setLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			this.props.triggerNotification(true, err);
	// 			this.props.setLoading(false);
	// 		});
	// };

	// componentDidMount() {
	// 	let { initValues, viewMood, editMood } = this.props;

	// 	this.getDepartments();
	// 	this.getUsersGroupList();
	// 	this.getLang();
	// 	this.getUserDetails();

	// 	if (viewMood) {
	// 		this.getDepartmentJobs(initValues.department);
	// 		this.setState({
	// 			initVal: initValues,
	// 		});
	// 	}
	// 	if (initValues && (editMood || viewMood)) {
	// 		this.setState({
	// 			initVal: initValues,
	// 			activeDir: {
	// 				ADSid: initValues.AD_Ref,
	// 			},
	// 		});
	// 	}
	// 	if ((editMood || viewMood) && initValues.AD_Ref) {
	// 		this.setState({
	// 			searchContent: initValues.loginName,
	// 		});
	// 	} else {
	// 		this.setState({
	// 			connect: false,
	// 		});
	// 	}
	// 	if (editMood || viewMood) {
	// 		this.setState({
	// 			openProfile: false,
	// 		});
	// 	}
	// }

	render() {
		let { initValues } = this.props

		console.log(initValues)

		const validate = (values) => {
			const errors = {};

			if (!values.cardNameAR) {
				errors.cardNameAR = `${this.props.t('error.field_required')}`;
			}
			if (!values.cardNameEN) {
				errors.cardNameEN = `${this.props.t('error.field_required')}`;
			}
			if (!values.cardDescAR) {
				errors.cardDescAR = `${this.props.t('error.field_required')}`;
			}
			if (!values.cardDescEN) {
				errors.cardDescEN = `${this.props.t('error.field_required')}`;
			}

			return errors;
		};

		return (
			<>
				{/* {!this.props.editMood && !this.props.viewMood && (
					<div className='d-flex justify-content-between align-items-center'>
						<h3>{this.props.t('manageUsers')}</h3>
						<ButtonPrimary
							variant={!this.state.showForm ? 'contained' : 'outlined'}
							onClick={() => {
								this.setState({ showForm: !this.state.showForm });
							}}>
							{!this.state.showForm && this.props.t('addUser')}
							{this.state.showForm && this.props.t('cancel')}
						</ButtonPrimary>
					</div>
				)} */}

                    <Form
						onSubmit={this.onCardSubmit}
						// onReset={this.onUserReset}
						initialValues={this.props.initValues}
						// enableReinitialize={true}
						validate={validate}
						render={({ handleSubmit, form, submitting, pristine, values, handleReset }) => {
							// if (
							// 	this.props.editMood &&
							// 	values.department &&
							// 	values.department !== '' &&
							// 	values.departmentJobs !== '' &&
							// 	this.state.departmentJobs.length === 0
							// ) {
							// 	this.getDepartmentJobs(values.department);
							// }
							return (
								<form
								id="serviceStepForm"
									className={`row`}
									// onSubmit={handleSubmit}
									// onReset={handleReset}
									noValidate
								>
									{/* <Col xs={12} md={9}>
										<Row>
											<Col
												xs={12}
												md={4}
												className={`${this.props.viewMood ? 'read-only' : ''}`}>
												<Autocomplete
													value={this.state.searchContent}
													dataSource={this.state.searchResults}
													valueExpr={
														this.state.searchType === '1'
															? 'LoginName'
															: this.state.searchType === '2'
															? 'Email'
															: this.state.searchType === '3'
															? 'FullName'
															: false
													}
													onValueChanged={this.handleValueChange}
													onSelectionChanged={this.selectedValue}
													placeholder={this.props.t('search')}
													disabled={this.props.viewMood}
													rtlEnabled={
														this.props.i18n.language === 'ar' ? true : false
													}
													minSearchLength={2}
												/>
											</Col>

											<Col
												xs={12}
												md={4}
												className={`${this.props.viewMood ? 'read-only' : ''}`}>
												<Switches
													disabled={this.props.viewMood}
													color='primary'
													checked={this.state.connect}
													onChange={() => {
														this.setState({ connect: !this.state.connect });
													}}
													name='connect'
													data={{
														label: `${this.props.t('connect')}`,
														value: `${this.props.t('connect')}`,
													}}
												/>
											</Col>

											<Col xs={12} md={8}>
												<FormControl className='mt-3'>
													<label>{this.props.t('searchType')}</label>
													<RadioGroup
														disabled={this.props.viewMood}
														row
														name='searchType'
														value={this.state.searchType}
														onChange={this.handleSelectType}>
														<FormControlLabel
															value='1'
															disabled={this.props.viewMood}
															control={<Radio />}
															label={this.props.t('loginName')}
														/>
														<FormControlLabel
															value='2'
															disabled={this.props.viewMood}
															control={<Radio />}
															label={this.props.t('email')}
														/>
														<FormControlLabel
															value='3'
															disabled={this.props.viewMood}
															control={<Radio />}
															label={this.props.t('name')}
														/>
													</RadioGroup>
												</FormControl>
											</Col>
										</Row>
									</Col> */}

									<Col xs={12} md={6}>
										<Row>
											<Col xs={12} md={6}>
												<TextField
													label={this.props.t('cardNameAR')}
													className={`mb-2`}
													name='cardNameAR'
													required={true}
													showError={({ meta: { submitFailed, invalid } }) => {
														return submitFailed && invalid;
													}}
												/>
											</Col>

											<Col xs={12} md={6}>
												<TextField
													label={this.props.t('cardNameEN')}
													className={`mb-2`}
													name='cardNameEN'
													required={true}
													showError={({ meta: { submitFailed, invalid } }) => {
														return submitFailed && invalid;
													}}
												/>
											</Col>

											<Col xs={12} md={6}>
                                                <TextField
                                                    label={this.props.t('cardDescAR')}
                                                    required={true}
                                                    name="cardDescAR"
                                                    multiline
                                                    rows={4}
                                                    autoComplete="off"
                                                    showError={({ meta: { submitFailed, invalid } }) => {
                                                        return submitFailed && invalid 
                                                    } }
                                                />
											</Col>

                                            <Col xs={12} md={6}>
                                                <TextField
                                                    label={this.props.t('cardDescAR')}
                                                    required={true}
                                                    name="cardDescEN"
                                                    multiline
                                                    rows={4}
                                                    autoComplete="off"
                                                    showError={({ meta: { submitFailed, invalid } }) => {
                                                        return submitFailed && invalid 
                                                    } }
                                                />
											</Col>

											{/* <Col xs={12} md={4}>
												<TextField
													disabled={this.props.viewMood}
													label={this.props.t('phone')}
													className={`${
														this.props.viewMood ? 'read-only' : ''
													} mb-2`}
													name='phone'
													required={true}
													showError={({ meta: { submitFailed, invalid } }) => {
														return submitFailed && invalid;
													}}
												/>
											</Col>

											<Col xs={12} md={4}>
												<TextField
													disabled={
														this.props.viewMood ||
														(this.state.connect && this.state.activeDir) ||
														(initValues?.AD_Ref && this.state.connect)
													}
													label={this.props.t('mail')}
													className={`${
														this.props.viewMood ||
														(this.state.connect && this.state.activeDir) ||
														(initValues?.AD_Ref && this.state.connect)
															? 'read-only'
															: ''
													} mb-2`}
													name='mail'
													required={true}
													showError={({ meta: { submitFailed, invalid } }) => {
														return submitFailed && invalid;
													}}
												/>
											</Col> */}

											{/* {!this.props.editMood && (
												<>
													<Col xs={12} md={4}>
														<Select
															disabled={this.props.viewMood}
															label={this.props.t('userPermissionsGroup')}
															className={`${
																this.props.viewMood ? 'read-only' : ''
															} mb-2`}
															required={true}
															name='group'
															data={this.state.usersGroupList}
															showError={() => {
																let fieldStatus = form.getFieldState('group');
																if (fieldStatus) {
																	return (
																		fieldStatus.submitFailed &&
																		fieldStatus.invalid
																	);
																}
															}}></Select>
													</Col>

													<Col xs={12} md={4}>
												<Select
													disabled={this.props.viewMood}
													label={this.props.t('departmentName')}
													className={`${
														this.props.viewMood ? 'read-only' : ''
													} mb-2`}
													name='department'
													data={this.state.departments}
													showError={() => {
														let fieldStatus =
															form.getFieldState('department');
														if (fieldStatus) {
															return (
																fieldStatus.submitFailed &&
																fieldStatus.invalid
															);
														}
													}}></Select>
													</Col>

													<OnChange name='department'>
														{(value) => {
															this.getDepartmentJobs(value);
														}}
													</OnChange>

													<Col xs={12} md={4}>
														<Select
															disabled={this.props.viewMood}
															label={this.props.t('departmentJob')}
															className={`${
																this.props.viewMood ? 'read-only' : ''
															} mb-2`}
															name='job'
															data={this.state.departmentJobs}
															showError={() => {
																let fieldStatus =
																	form.getFieldState('departmentJobType');
																if (fieldStatus) {
																	return (
																		fieldStatus.submitFailed &&
																		fieldStatus.invalid
																	);
																}
															}}></Select>
													</Col>

													<Col xs={12} md={4}>
														<TextField
															disabled={
																this.props.viewMood ||
																(this.state.connect && this.state.activeDir) ||
																(initValues?.AD_Ref && this.state.connect)
															}
															label={this.props.t('jobSignature')}
															className={`${
																this.props.viewMood ||
																(this.state.connect && this.state.activeDir) ||
																(initValues?.AD_Ref && this.state.connect)
																	? 'read-only'
																	: ''
															} mb-2`}
															name='jobSignature'
															required={false}
															showError={({ meta: { submitFailed, invalid } }) => {
																return submitFailed && invalid;
															}}
														/>
													</Col>
												</>
											)}

											<Col xs={12} md={4}>
												<Select
													disabled={this.props.viewMood}
													label={this.props.t('language')}
													className={`${
														this.props.viewMood ? 'read-only' : ''
													} mb-2`}
													name='lang'
													data={this.state.langList}
													showError={() => {
														let fieldStatus = form.getFieldState('lang');
														if (fieldStatus) {
															return (
																fieldStatus.submitFailed && fieldStatus.invalid
															);
														}
													}}></Select>
											</Col> */}

											{/* <Col xs={12} md={8} className='mb-2'>
												{!this.props.viewMood && (
													<h5 className="my-4 text-start">{this.props.t('attachments')}</h5>
												)}

												{this.props.viewMood && this.state.filesData.length > 0 && (
													<h5 className="my-4 text-start">{this.props.t('attachments')}</h5>
												)} 

												<FilesUpload 
													at={this.props.currentUser.Data.access_token} 
													endPoint="API/SYSTEM/USERS/UPD_USER_SIGNATURE_IMAGE" 
													params={{User_Account_Id: this.props.initValues?.userAccountId}} 
													filesData={this.state.filesData} 
													fileApiKey="User_Signature_Image_File" 
													single={true} 
													// deleteCB={deleteAttachment} 
													deleteCB={this.deleteAttachment} 
													// deleteParams={{
													// 	User_Account_Id: initValues?.userAccountId, 
													// 	User_Signature_File_Code: this.state.filesData.File_Code
													// }}
													deleteParams={{}}
													downloadCB={downloadAttachment} 
													handleSetFilesData={this.getUserDetails}
													view={this.props.viewMood} 
												/>

												<div className='my-3 text-danger d-flex flex-column'>
													<p>{this.props.t('attachNotes')}:</p>
													<div>{this.props.t('imgType')}</div>
													<div>{this.props.t('imgBg')}</div>
													<div>{this.props.t('imgSize')}</div>
												</div>
											</Col> */}
										</Row>
									</Col>

									<Col
										xs={12}
										md={6}
										className='d-flex justify-content-start align-items-start flex-column'>
										<Field name='slider'>
											{({ input: { value, onChange, ...input }, meta }) => (
												<>
													<div
														className={[
															'upload-avatar mx-3 text-center',
															!this.props.viewMood &&
															meta.submitFailed &&
															meta.invalid
																? 'error-avatar'
																: null,
														].join(' ')}>
														<Avatar
                                                            className='mb-3'
															variant="square"
															style={{
																objectFit: 'contain'
															}}
															
															src={
																this.state.imgUrl ? this.state.imgUrl : this.props.initValues.cardImageURL ? this.props.initValues.cardImageURL 
																	: '/broken-image.jpg'
															}
														>
															<CloudUploadIcon />
														</Avatar>
                                                        {!this.props.viewMood && (
														<ButtonSecondary
															color='primary'
															variant='contained'
															>
															{this.props.t('cardImage')}
															<input
																{...input}
																type='file'
																className='buttonFile'
																onChange={({ target }) => {
																	onChange(target.files);
																	this.handleUploadClick(target.files);
																}}
															/>
														</ButtonSecondary>
													)}
													</div>
													
												</>
											)}
										</Field>
									</Col>

									<Col className='my-4' xs={12}>
										<Row className='justify-content-start'>
                                            
                                            <ButtonPrimary
                                                color='primary'
                                                variant='contained'
                                                disabled={submitting || this.props.viewMood}
                                                onClick={handleSubmit}>
                                                {this.props.t('save')}
                                            </ButtonPrimary>
											<ButtonPrimary
                                                variant={'outlined'}
                                                onClick={() => this.props.triggerDialogForm(false)}>
                                                {this.props.t('cancel')}
                                            </ButtonPrimary>
										</Row>
									</Col>

									{/* {this.props.editMood && (
										<Col xs={12} md={4} className='mt-2'>
											<ButtonPrimary
												variant={
													this.state.openProfile ? 'outlined' : 'contained'
												}
												className='d-flex'
												onClick={() => {
													this.setState({
														openProfile: !this.state.openProfile,
													});
												}}>
												{!this.state.openProfile
													? this.props.t('addNewProfile')
													: this.props.t('cancel')}
											</ButtonPrimary>
										</Col>
									)} */}
								</form>
							);
						}}
					/>
				
			</>
		);
	}
}

const mapStateToprops = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToprops, {
	triggerNotification,
	setLoading,
	triggerDialogForm,
	reload
})(translationHook(CardForm));