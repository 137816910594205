import apiConfig from '../../apiConfig'

export const deleteRequestAdditionalAttachment = async ( { at, File_Code, Request_Id } ) => {
    console.log(at);
    const response = await apiConfig.post(
        '/API/ADMINISTRATION/REQUESTS/MANAGEMENT/DELETE_REQUEST_ADDITIONAL_ATTACHMENT',
        {
            File_Code,
            Request_Id
        },
        {
            headers: {
                Authorization: `Bearer ${at}`
            }
        }
    ).catch((error) => {
        return error.response
    })

    return response
}
