import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translationHook } from '../../translationHook'
import apiConfig from '../../../apis/apiConfig'
import { ButtonPrimary } from '../../UI/Buttons'
import { Row, Col } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { TextField, Switches, Select } from 'mui-rff'
import { triggerNotification, setLoading, triggerDialogForm } from '../../../actions'


class TypesOfDocumentsForm extends Component {

    state = {
        showForm: false,
        docCategorise: []
    }

    getDocumentCategories = async() => {
        const response = await apiConfig.post(
			'/API/SYSTEM/DDL/GET_DOCUMENT_CATEGORIES',
			{},
			{
				headers: {
					Authorization: `Bearer ${this.props.currentUser.Data.access_token}`,
				},
			}
		)
        if (response.data.Status) {
            let docCategoryArr = [];
			response.data.Data.map((item) => {
				let label =
					this.props.i18n.language === "ar"
						? item.Document_Category_Name_AR
						: item.Document_Category_Name_EN;

                docCategoryArr.push({ value: item.Document_Category_Id, label });
			});
			this.setState({ docCategorise: docCategoryArr });
		}
    }

    componentDidMount() {
        this.getDocumentCategories()
    }

    onSubmit = async (values, form) => {
        let at = this.props.currentUser.Data.access_token,
        url = !this.props.editMood ? '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/TYPES/Add_Document_Type' : '/API/ADMINISTRATION/LOOKUPS/DOCUMENTS/TYPES/Upd_Document_Type',
        bodyData = {
            Document_Type_Name_AR: values.Document_Type_Name_AR,
            Document_Type_Name_EN: values.Document_Type_Name_EN,
            Active_Status_Id: values.Active_Status_Id == true ? 1 : 0,
            Document_Category_Id: values.Document_Category_Id,
        }

        if (this.props.editMood) {
            bodyData.Document_Type_Id = this.props.documentTypeId
        } else {
            bodyData.Document_Type_Id = this.props.currentUser.Data.documentTypeId
        }

        this.props.setLoading(true, 'dark', this.props.editMood ? 1501 : 900)

        const response = await apiConfig.post(
            url,
            bodyData,
            {
                headers: {
                    Authorization: `Bearer ${at}`
                },
            }
        ).catch((error) => {
            return error.response
        })
        
        if (response.data.Status) {
            form.restart()
            this.setState({ showForm: false })
        }

        if (this.props.editMood) {
            this.props.triggerDialogForm(false)
        }

        this.props.triggerNotification(true, response.data)

        this.props.setLoading(false)
    }

    render() {
        let { initValues } = this.props

        const validate = values => {
            const errors = {}
            if (!values.Document_Type_Name_AR) {
                errors.Document_Type_Name_AR = `${this.props.t('error.field_required')}`
            }

            if (!values.Document_Type_Name_EN) {
                errors.Document_Type_Name_EN = `${this.props.t('error.field_required')}`
            }

            if (!values.Document_Category_Id) {
                errors.Document_Category_Id = `${this.props.t('error.field_required')}`
            }

            return errors
        }

        return (
            <>
                {!this.props.editMood && !this.props.viewMood &&
                    <div className="d-flex justify-content-between align-items-center pb-5">
                        <h3>{this.props.t('typesOfDocuments')}</h3>
                        <ButtonPrimary
                            variant={!this.state.showForm ? 'contained' : 'outlined'}
                            onClick={() => this.setState({showForm: !this.state.showForm}) }
                        >
                            {!this.state.showForm && this.props.t('addDoc')}
                            {this.state.showForm && this.props.t('cancel')}
                        </ButtonPrimary>
                    </div>
                }
                
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={initValues ? initValues : {
                        Document_Type_Name_AR: '',
                        Document_Type_Name_EN: '',
                        Document_Category_Id: '',
                        Active_Status_Id: ''
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form
                            id="sliderform"
                            className={`row ${!this.state.showForm && !this.props.editMood && !this.props.viewMood ? 'd-none' : ''}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('docNameAR')}
                                    className="mb-2"
                                    name="Document_Type_Name_AR"
                                    required={true}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <TextField
                                    disabled={this.props.viewMood}
                                    label={this.props.t('docNameEN')}
                                    className="mb-2"
                                    name="Document_Type_Name_EN"
                                    required={true}
                                />
                            </Col>

                            <Col xs={12} md={6}>
                                <Select
                                    disabled={this.props.viewMood}
                                    label={this.props.t("documentCategory")}
                                    name="Document_Category_Id"
                                    data={this.state.docCategorise}
                                    onChange={(e) => {
                                        form.change("Document_Category_Id", e.target.value);
                                    }}
                                ></Select>
                            </Col>

                            <Col xs={12} md={6}>
                                <Switches
                                    disabled={this.props.viewMood}
                                    color="primary"
                                    name="Active_Status_Id"
                                    data={{ label: `${this.props.t('serviceStatus')}`, value: `${this.props.t('serviceStatus')}` }}
                                />
                            </Col>

                            <Col className='my-4 justify-content-start d-flex' xs={12}>
                                { !this.props.viewMood && 
                                    <ButtonPrimary
                                        color="primary"
                                        variant="contained"
                                        disabled={submitting || this.props.viewMood}
                                        type="submit"
                                    >
                                        {this.props.t('save')}
                                    </ButtonPrimary>
                                }
                            </Col>
                        </form>
                    )}
                />
            </>
        )
    }
}

const mapStateToprops = (state) => {
    return { currentUser: state.currentUser }
}

export default connect(mapStateToprops, { triggerNotification, setLoading, triggerDialogForm })(translationHook(TypesOfDocumentsForm))
