import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { triggerDialog } from '../../../actions';
import { translationHook } from '../../translationHook';
import { StandaloneSearchBox } from '@react-google-maps/api';

const stylesArr = [
	{
		"featureType": "all",
		"elementType": "labels.text.fill",
		"stylers": [{"saturation": 36},{"color": "#101111"},{"lightness": 40}]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.stroke",
		"stylers": [{"visibility": "on"},{"color": "#101111"},{"lightness": 16}]
	},
	{
		"featureType": "all",
		"elementType": "labels.icon",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "administrative",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#101111"},{"lightness": 20}]
	},
	{
		"featureType": "administrative",
		"elementType": "geometry.stroke",
		"stylers": [{"color": "#101111"},{"lightness": 17},{"weight": 1.2}]
	},
	{
		"featureType": "administrative",
		"elementType": "labels",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "administrative.country",
		"elementType": "all",
		"stylers": [{"visibility": "simplified"}]
	},
	{
		"featureType": "administrative.country",
		"elementType": "geometry",
		"stylers": [{"visibility": "simplified"}]
	},
	{
		"featureType": "administrative.country",
		"elementType": "labels.text",
		"stylers": [{"visibility": "simplified"}]
	},
	{
		"featureType": "administrative.province",
		"elementType": "all",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "administrative.locality",
		"elementType": "all",
		"stylers": [{"visibility": "simplified"},{"saturation": "-100"},{"lightness": "30"}]
	},
	{
		"featureType": "administrative.neighborhood",
		"elementType": "all",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "administrative.land_parcel",
		"elementType": "all",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "landscape",
		"elementType": "all",
		"stylers": [{"visibility": "simplified"},{"gamma": "0.00"},{"lightness": "74"}]
	},
	{
		"featureType": "landscape",
		"elementType": "geometry",
		"stylers": [{"color": "#101111"},{"lightness": 20}]
	},
	{
		"featureType": "landscape.man_made",
		"elementType": "all",
		"stylers": [{"lightness": "3"}]
	},
	{
		"featureType": "poi",
		"elementType": "all",
		"stylers": [{"visibility": "off"}]
	},
	{
		"featureType": "poi",
		"elementType": "geometry",
		"stylers": [{"color": "#101111"},{"lightness": 21}]
	},
	{
		"featureType": "road",
		"elementType": "geometry",
		"stylers": [{"visibility": "simplified"}]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [{"color": "#101111"},{"lightness": 17}]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [{"color": "#101111"},{"lightness": 29},{"weight": 0.2}]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry",
		"stylers": [{"color": "#101111"},{"lightness": 18}]
	},
	{
		"featureType": "road.local",
		"elementType": "geometry",
		"stylers": [{"color": "#101111"},{"lightness": 16}]
	},
	{
		"featureType": "transit",
		"elementType": "geometry",
		"stylers": [{"color": "#101111"},{"lightness": 19}]
	},
	{
		"featureType": "water",
		"elementType": "geometry",
		"stylers": [{"color": "#101111"},{"lightness": 17}]
	}
]

const svgMarker = {
    path: "M30.5,11.4c-1.4-4.6-5.1-8.3-9.7-9.7C16,0.2,10.9,1.1,7,3.9c-3.9,2.9-6.2,7.5-6.2,12.3c0,3.3,1.1,6.5,3,9.1 L16,41l12.2-15.6C31.2,21.4,32,16.3,30.5,11.4z M16,24.4c-4.5,0-8.2-3.7-8.2-8.2S11.5,8,16,8s8.2,3.7,8.2,8.2S20.5,24.4,16,24.4z",
    fillColor: "#28A745",
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: "#fdfdfd",
    rotation: 0,
    scale: 1,
  }

class googleMap extends React.PureComponent {
	constructor(props) {
		super(props);
		if (this.props.markers) {
			this.state = {
				position: this.props.markers[0],
				center: this.props.markers[0],
			};
		} else {
			this.state = {
				position: this.props.position,
				center: this.props.position,
			};
		}
	}
	// componentDidMount() {
	// 	this.setState({
	// 		center: this.props.position
	// 	})
	// }

	componentWillReceiveProps(nextProps) {
		if (nextProps.markers) {
			this.setState = ({
				position: nextProps.markers[0],
				center: nextProps.markers[0],
			});
		} else {
			this.setState({
				center: nextProps.position,
				position: nextProps.position
			})
		}

		

	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	// if(JSON.stringify(nextProps.position) != JSON.stringify(this.state.center)){
	// 	// 	console.log(nextProps);
	// 	// 	console.log(nextState);
	// 	// 	this.setState({
	// 	// 		position: nextProps.position,
	// 	// 		center: nextProps.position,
	// 	// 	})
	// 	// }
	// 	if(true){
	// 		this.setState({
	// 			position: nextProps.position,
	// 			center: this.state.position,
	// 		})
	// 		return true;
	// 	}
	// }

	containerStyle = {
		width: '100%',
		height: '400px',
		borderRadius: '12px'
	};

	API_KEY = 'AIzaSyCBx-BN5uLwmFezDw0NeQs9xp_UcplHIzs';

	onLoad = () => {
		this.setState({
			styles:this.props.styles 
		});

		if (this.props.markers) {
			this.setState({
				position: this.props.markers[0],
				center: this.props.markers[0],
			});
		} else {
			this.setState({
				position: this.props.position,
				center: this.props.position,
			});
		}
	};

	onDragEnd = (marker) => {
		if (!this.props.markers) {
			this.setState(
				{
					position: {
						lat: marker.latLng.lat(),
						lng: marker.latLng.lng(),
					},
				},
				this.newValue
			);
		}
	};

	onClick = (e) => {
		if (!this.props.markers) {
			this.setState(
				{
					position: {
						lat: e.latLng.lat(),
						lng: e.latLng.lng(),
					},
				},
				this.newValue
			);
		}
	};
	// fires after new mark selected and pass it to the form to send with the requests

	newValue = () => { 
		this.props.latLng(this.state.position);
	};
	unmount = () => {
		// console.log('here');
	};
	onLoadScript = (e) => {
		// console.log('onLoadScripts');
	};

	onLoadSearch = ref => this.searchBox = ref;

	onPlacesChanged = () => {
		this.setState({
			center: {
				lat: this.searchBox.getPlaces()[0].geometry.location.lat(),
				lng: this.searchBox.getPlaces()[0].geometry.location.lng()
			},
			position: {
				lat: this.searchBox.getPlaces()[0].geometry.location.lat(),
				lng: this.searchBox.getPlaces()[0].geometry.location.lng()
			}
		})
		this.newValue()

	};

	renderMarkers = () => {
		let markers = []

		if (this.props.markers) {
			let labels = []

			if (this.props.labels) {
				labels = this.props.labels
			}
			this.props.markers.map((marker, index) => {
				markers.push(
					<Marker
						draggable={false}
						position={marker}
						icon={svgMarker}
						label={{color: '#fff', fontSize:"16px", text: labels.length > 0 ? labels[index] : "", className: "marker-label"}}
					/>
				)
			})
		} else {
			markers.push(
				<Marker
					draggable
					onLoad={this.onLoad}
					onDragEnd={this.onDragEnd}
					position={this.state.position}
					icon={svgMarker}
				/>
			)
		}

		return markers
	} 

	lib = ['places']
	zoom = 12

	render() {
		return (
			<LoadScript
				googleMapsApiKey={this.API_KEY}
				onUnmount={this.unmount}
				onLoad={this.onLoadScript}
				libraries={this.lib}
				>
						{/* bounds= */}
				<GoogleMap
					mapContainerStyle={this.containerStyle}
					center={this.state.center}
					zoom={this.zoom}
					onClick={(e) => this.onClick(e)}
					defaultOptions={{ styles: stylesArr}}
					options= {{ styles: stylesArr}}
					>
						{!this.props.markers && 
							<StandaloneSearchBox
							onLoad={this.onLoadSearch}
							onPlacesChanged={this.onPlacesChanged}
							>
							<input
								type="text"
								placeholder={this.props.t('search')}
								style={{
								boxSizing: `border-box`,
								marginTop: '10px',
								border: `1px solid #fff`,
								borderRadius: '4px',
								width: `240px`,
								height: `40px`,
								padding: `0 12px`,
								backgroundColor: 'rgba(255,255,255,0.9)',
								boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
								fontSize: `14px`,
								outline: `none`,
								textOverflow: `ellipses`,
								position: "absolute",
								left: "50%",
								marginLeft: "-120px"
								}}
							/>
							</StandaloneSearchBox>
						}
					
					{this.renderMarkers()}
				</GoogleMap>
			</LoadScript>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currentUser: state.currentUser,
		reload: state.reload,
	};
};

export default connect(mapStateToProps, { triggerDialog })(
	translationHook(googleMap)
);
