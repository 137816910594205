import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import { translationHook } from "../../translationHook";
import { triggerDialog, setLoading, triggerNotification, reload } from "../../../actions";
import CustomStore from 'devextreme/data/custom_store';

import {
	DataGrid,
    Column,
    Button,
    SearchPanel,
    FilterRow,
    HeaderFilter,
	Export,
	Grouping,
	GroupPanel,
	Selection
} from 'devextreme-react/data-grid';
import { getServiceActionsListVersions } from "../../../apis/services/getServiceActionsListVersions";


class ServiceVersionActions extends Component {
	constructor(props){
		super(props)
		this.onExporting = this.onExporting.bind(this);
	}


	state = {
		
		reload: false
	};

	

	render() {

		let columnAlign = 'right',
        ServiceEventNameKey = 'RAction_Event_Name_AR',
        ServiceNameKey = 'RAction_Name_AR',
        ServiceLevelKey = 'RAction_Event_Level_Name_AR',
        ServiceStepNameKey = 'Department_Service_Step_Name_AR'



		if (this.props.i18n.language === 'en') {
			columnAlign = 'left'
			ServiceEventNameKey = 'RAction_Event_Name_EN'
			ServiceNameKey = 'RAction_Name_EN'
			ServiceLevelKey = 'RAction_Event_Level_Name_EN'
			ServiceStepNameKey = 'Department_Service_Step_Name_EN'
		}

		const jsonDataSource = new CustomStore({
			key: 'Department_Service_Action_Id',
			load: async () => {
				return getServiceActionsListVersions(this.props.currentUser.Data.access_token, this.props.versionid)
			}
		});
		 
		return (
			<Row>
                <Col md={12}>
                    <h5 className="w-40 my-4">
                        {this.props.t("servicesListOfEvents")}
                    </h5>

                    <DataGrid
                    className={[this.props.i18n.language === 'ar' ? 'dx-grid-ar' : 'dx-grid-en' , 'globalBox'].join(' ')}
                    id="dataGrid"
                    width={'100%'}
                    dataSource={jsonDataSource}
                    showBorders={false}
                    showColumnLines= {false}
                    showRowLines= {true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    allowColumnReordering={true}
                    rowAlternationEnabled={false}
                    focusedRowEnabled={true}
                    onExporting={this.onExporting}
                    // columnWidth={'auto'}
                    wordWrapEnabled={true}
                    rtlEnabled = {this.props.i18n.language === 'ar' ? true : false}
                    >

                    {/* <ColumnChooser enabled={true} allowSearch={true} search={'fff'}
                    title={this.props.t('dxColumnChooserTitle')}
                    emptyPanelText={this.props.t('dxColumnChooserPanelText')}
                    /> */}

                    <SearchPanel 
                    visible={true}
                    width={240}
                    placeholder={this.props.t('search')}
                    />

                    <FilterRow visible={true} />

                    <HeaderFilter visible={true} />

                    <Export enabled={true} allowExportSelectedData={true}/>

                    <Grouping contextMenuEnabled={true} expandMode="rowClick"/>
                    <GroupPanel visible={true} emptyPanelText={this.props.t('dxGroupPanelText')}/>

                    <Selection
                    mode="multiple"
                    selectAllMode={'allPages'}
                    showCheckBoxesMode={'always'}
                    />

                    {/* <Column cssClass="outlinedColumn" fixed={true} fixedPosition={this.props.i18n.language === 'ar' ? 'right' : 'left'} alignment="center" width={'70px'} cellRender={this.renderOrderButtons}>
                    </Column> */}
					

                    <Column dataField={ServiceEventNameKey} caption={this.props.t('servicesEventExecution')} alignment={columnAlign}>
                    </Column>

					<Column dataField={ServiceLevelKey} caption={this.props.t('serviceActionLevel')} alignment={columnAlign}>
                    </Column>

                    <Column dataField={ServiceNameKey} caption={this.props.t('servicesExecutionOrder')} alignment={columnAlign}>
                    </Column>

					<Column dataField={ServiceStepNameKey} caption={this.props.t('stepName')} alignment="center">
                    </Column>

                    </DataGrid>
                    </Col>
            </Row>
		);
	}

    onExporting(e) {
		let sheetTitle = this.props.t("servicesListOfEvents");
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("Main sheet");
		exportDataGridToExcel({
			component: e.component,
			worksheet: worksheet,
			autoFilterEnabled: true,
			topLeftCell: { row: 2, column: 2 },
			topRightCell: { row: 2, column: 2 },
			customizeCell: ({ gridCell, excelCell }) => {
				// excelCell.value = gridCell.value;
				excelCell.font = {
					family: 2,
					name: "Cairo",
					size: 10,
					color: { argb: "151713" },
				};
				excelCell.alignment = {
					indent: 1,
					wrapText: true,
					vertical: "middle",
				};
				if (gridCell.rowType === "group") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "d0b166" },
					};
					excelCell.alignment = { indent: 2 };
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 10,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
				if (gridCell.rowType === "header") {
					excelCell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "58743a" },
					};
					excelCell.alignment = {
						vertical: "middle",
						horizontal: "center",
					};
					excelCell.font = {
						family: 2,
						name: "Cairo",
						size: 12,
						color: { argb: "ffffff" },
						bold: true,
					};
				}
			},
		}).then(function () {
			workbook.xlsx.writeBuffer().then(function (buffer) {
				saveAs(
					new Blob([buffer], { type: "application/octet-stream" }),
					`${sheetTitle}.xlsx`
				);
			});
		});
		e.cancel = true;
	}
}

const mapStateToProps = (state) => {
	return { currentUser: state.currentUser, reload: state.reload };
};

export default connect(mapStateToProps, { triggerDialog, setLoading, triggerNotification, reload })(
	translationHook(ServiceVersionActions)
);