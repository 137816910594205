export const LOGIN_USER = "LOGIN_USER";
export const RESET_PASS = "RESET_PASS";
export const VERIFY_MAIL = "VERIFY_MAIL";
export const VERIFY_PHONE = "VERIFY_PHONE";

export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const TRIGGER_NOTIFICATION = "TRIGGER_NOTIFICATION";
export const TOGGLE_FORM = "TOGGLE_FORM";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const TRIGGER_DIALOG = "TRIGGER_DIALOG";
export const RELOAD = "RELOAD";
export const TRIGGER_LOADING = "TRIGGER_LOADING";
export const TRIGGER_DIALOG_FORM = "TRIGGER_DIALOG_FORM";
export const GET_DIALOG_DATA = "GET_DIALOG_DATA";
export const CLEAR_DIALOG_DATA = "CLEAR_DIALOG_DATA";
